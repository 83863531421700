import { useEffect, useState } from 'react';
import { cardLogoResolver } from '@wayste/sour-components';
import { RadioButton, Tooltip } from '@wayste/sour-ui';
import { toTitleCase } from '@wayste/utils';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import type Stripe from 'stripe';

type SelectCardProps = {
    stripeCustomer: Stripe.Customer | null;
    onCardSelected: (sourceID: string) => void;
    selectedCard?: string;
    disabled?: boolean;
    disableAddCard?: boolean;
};

const SelectCard = ({ stripeCustomer, onCardSelected, selectedCard, disabled, disableAddCard }: SelectCardProps) => {
    const [selectedSource, setSelectedSource] = useState<Stripe.Source | null>(null);
    const defaultCardId = stripeCustomer?.default_source;

    useEffect(() => {
        const card = stripeCustomer?.sources?.data?.find((item) => item.id === selectedCard);
        setSelectedSource(card as Stripe.Source);
    }, [selectedCard]);

    return (
        <div className="w-full rounded-md border">
            {!disableAddCard && (
                <div className="flex w-full items-center px-2 py-1">
                    <div>
                        <RadioButton
                            inputProps={{
                                checked: null === selectedSource,
                                onChange: () => {
                                    setSelectedSource(null);
                                    onCardSelected('');
                                },
                                disabled: disabled,
                            }}
                            options={[{ value: undefined, label: '' }]}
                        />
                    </div>
                    <div className="text-xs">Add new card</div>
                </div>
            )}
            {stripeCustomer?.sources?.data?.map((item, index) => {
                if (item.object !== 'source' || item.type !== 'card' || !item.card || !item.owner) return null;
                const cardIsDisabled = disabled || item.card.funding === 'prepaid';

                return (
                    <div
                        key={index + 'Stripe-Customer'}
                        className={`flex items-center border-t px-2 py-1 ${cardIsDisabled ? 'bg-gray-100' : ''}`}
                    >
                        <RadioButton
                            inputProps={{
                                checked: item.id === selectedSource?.id,
                                onChange: () => {
                                    setSelectedSource(item);
                                    onCardSelected(item.id);
                                },
                                disabled: cardIsDisabled,
                            }}
                            options={[{ value: item.id, label: '' }]}
                        />
                        <div className={'flex w-full flex-row items-center space-x-3 text-xs leading-none'}>
                            {item.status !== 'chargeable' || item.card.funding === 'prepaid' ? (
                                <Tooltip
                                    variant="light"
                                    text={
                                        item.card.funding === 'prepaid'
                                            ? 'Prepaid cards cannot be charged through SAP. Please speak with your administrator to charge the card in Stripe directly.'
                                            : 'This card is not chargeable.'
                                    }
                                >
                                    <ExclamationTriangleIcon className="h-5 w-5 fill-red-500" />
                                </Tooltip>
                            ) : (
                                <div className="h-5 w-5" />
                            )}
                            <span className={`${item.status !== 'chargeable' ? 'text-error' : ''}`}>{item.owner.name}</span>
                            <span>
                                {item.card.exp_month && item.card.exp_year && `Expires ${item.card.exp_month}/${item.card.exp_year % 100}`}
                            </span>
                            {/* <span>{item.card.funding === 'prepaid' ? 'Prepaid' : ''}</span> */}
                            <div>
                                {defaultCardId === item.id && (
                                    <span
                                        className={
                                            'text-info-dark bg-info-light border-info-light ml-1 inline-block min-w-[4rem]  rounded-full px-3 py-0.5'
                                        }
                                    >
                                        Default
                                    </span>
                                )}
                            </div>
                            <div className="!ml-auto flex items-center justify-end gap-1">
                                <span>{item.card.brand && cardLogoResolver(item.card.brand)}</span>
                                {item.card.funding && <div className="text-xs">{toTitleCase(item.card.funding + ' ')}</div>}
                                <span>{'•• ' + item.card.last4}</span>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default SelectCard;
