import { UniversalService } from '@alliance-disposal/transport-types';
import { DataGrid, DataGridProps } from '@wayste/sour-ui';
import { stringify } from 'qs';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../utils';
import { type SiteServiceProject } from '../account-manager-project-board';

const breakDownStatuses = (serviceOrders: UniversalService.ServiceOrder[]) => {
    const statuses = serviceOrders.reduce(
        (acc, order) => {
            if (!acc[order.status]) {
                acc[order.status] = 1;
            } else {
                acc[order.status] += 1;
            }
            return acc;
        },
        {} as { [key: string]: number },
    );

    return Object.entries(statuses)
        .map(
            ([status, count]) =>
                `${UniversalService.ServiceOrderStatusLabels[status as keyof typeof UniversalService.ServiceOrderStatusLabels]}: ${count}`,
        )
        .sort((a, b) => a.split(':')[0].localeCompare(b.split(':')[0]))
        .join(' | ');
};

const breakDownFamilies = (serviceOrders: UniversalService.ServiceOrder[]) => {
    const families = serviceOrders.reduce(
        (acc, order) => {
            if (!acc[order.serviceType.family.name]) {
                acc[order.serviceType.family.name] = 1;
            } else {
                acc[order.serviceType.family.name] += 1;
            }
            return acc;
        },
        {} as { [key: string]: number },
    );

    return Object.entries(families)
        .map(([family, count]) => `${family}: ${count}`)
        .sort((a, b) => a.split(':')[0].localeCompare(b.split(':')[0]))
        .join(' | ');
};

const SiteServiceBoard = ({ siteServiceProjects, loading }: { siteServiceProjects: SiteServiceProject[]; loading: boolean }) => {
    const history = useHistory();

    const handleRowClick = (row: SiteServiceProject) => {
        const queryString = stringify(
            {
                query: {
                    query: `"${row.serviceOrders[0].serviceLocation.address.addressLine1}"`,
                },
                type: 'sourgum-service-grouping',
                'sour-search': true,
                filters: {
                    status: {
                        field: 'status',
                        type: 'STRING',
                        operator: 'IS_NOT_IN',
                        value: ['COMPLETED', 'CANCELLED'],
                    },
                },
                sort: {
                    field: 'expectedDeliveryDate',
                    direction: 'DESC',
                },
            },
            { encode: false },
        );
        history.push(routes.universalServices.list + '?' + queryString);
    };

    const columns: DataGridProps<SiteServiceProject>['columns'] = [
        {
            key: 'serviceAddress',
            name: 'Address',
        },
        {
            key: 'customer',
            name: 'Customer',
            formatter: ({ row }) => row.customerDisplayName,
        },
        {
            key: 'activeOrders',
            name: 'Active Orders',
            formatter: ({ row }) => row.serviceOrders.length,
        },
        {
            key: 'serviceFamilies',
            name: 'Service Families',
            formatter: ({ row }) => breakDownFamilies(row.serviceOrders),
        },
        {
            key: 'statusBreakdown',
            name: 'Status Breakdown',
            formatter: ({ row }) => breakDownStatuses(row.serviceOrders),
        },
    ];

    return <DataGrid rows={siteServiceProjects} columns={columns} onRowClick={handleRowClick} loading={loading} />;
};

export default SiteServiceBoard;
