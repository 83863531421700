import { useState } from 'react';
import { Invoice, Order, UniversalService } from '@alliance-disposal/transport-types';
import { CurrencyTextField, DatePicker, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { formatUSD, moneyFormatter, round } from '@wayste/utils';
import { CheckCircleIcon, CheckIcon } from '@heroicons/react/24/solid';
import { NumericFormat } from 'react-number-format';
import { haulerBillPaymentMethods } from '../../../../utils';

const tableHeaders = [
    {
        heading: 'checkIcon',
        headingValue: <CheckIcon className="size-4" />,
        className: 'px-2.5 max-w-[18px]',
    },
    {
        heading: 'Ready',
        className: 'px-2.5 max-w-[31px]',
    },
    { heading: 'Order' },
    { heading: 'Type' },
    { heading: 'Vendor Inv #' },
    { heading: 'Orig. Amt.' },
    { heading: 'Amt. Due' },
    { heading: 'Payment Amt.' },
];

export interface PaymentProp {
    orderId: string;
    orderNumber: string | number;
    billObj: Invoice.PayableTransport;
    orderObj: Order.AllianceOrderTransport | UniversalService.ServiceOrder;
    invoiceNumber: string;
    invoiceID: string;
    readyForPayment: boolean;
    total: number;
    paid?: number;
    value: string;
    orderType: 'roll-off' | 'universal-service';
}

interface Props {
    onSave: (cleanedPayments: Invoice.PaymentCreateTransport[]) => void;
    onCancel: () => void;
    totalPayment: number;
    onUpdatePayment: (value: '' | number, index: number) => void;
    billTotals: { total: number; totalDue: number };
    onUpdateCheckAmount: (paymentAmount: string) => void;
    paymentMethod: string;
    onSetPaymentMethod: (value: string) => void;
    payments: PaymentProp[];
    onOrderSelect: (payment: PaymentProp) => void;
}

const BillPayment = ({
    onSave,
    onCancel,
    totalPayment,
    onUpdatePayment,
    billTotals,
    onUpdateCheckAmount,
    paymentMethod,
    onSetPaymentMethod,
    payments,
    onOrderSelect,
}: Props) => {
    const [paymentDate, setPaymentDate] = useState<Date | '' | undefined>('');
    const [paymentAmount, setPaymentAmount] = useState('');
    const [transactionId, setTransactionId] = useState('');

    const handleSave = () => {
        if (round(Number(paymentAmount)) > round(totalPayment)) {
            alert('Error - Check amount is larger than total due');
            return;
        }
        if (round(Number(paymentAmount)) < round(totalPayment)) {
            alert('Total Payments cannot be greater than Check Amount');
            return;
        }
        const cleanedPayments: Invoice.PaymentCreateTransport[] = [];
        payments.forEach((payment) => {
            if (payment.value && paymentDate) {
                const newPayment: Invoice.PaymentCreateTransport = {
                    paymentIdentifier: transactionId,
                    paymentSentDate: new Date(paymentDate).toISOString(),
                    amountDollars: Number(payment.value),
                    paymentMethod: paymentMethod,
                    invoiceID: payment.billObj.invoiceDetails.id,
                };

                return cleanedPayments.push(newPayment);
            }
        });
        onSave(cleanedPayments);
    };

    const handleUpdatePayment = (value: '' | number, index: number, payment: PaymentProp) => {
        if (value !== '' && value > payment.total - (payment.paid || 0) / 100) {
            alert('Cannot enter Payment greater than Due');
        } else {
            onUpdatePayment(value, index);
        }
    };

    return (
        <div className="flex flex-1 flex-col py-5">
            <div className="mb-4 flex justify-between">
                <div className="flex flex-1 gap-7">
                    <div className="max-w-[200px]">
                        <DatePicker label="Payment date" value={paymentDate} onChange={setPaymentDate} />
                    </div>
                    <div className="max-w-[200px]">
                        <Select
                            value={paymentMethod}
                            label="Payment Method"
                            onSelect={(value) => {
                                onSetPaymentMethod(value);
                            }}
                            inputProps={{
                                id: 'paymentMethod',
                            }}
                        >
                            {Object.entries(haulerBillPaymentMethods).map((e) => (
                                <SelectOption key={e[0]} value={e[0]}>
                                    {e[1]}
                                </SelectOption>
                            ))}
                        </Select>
                    </div>
                    <div className="w-[200px]">
                        <TextField
                            label="Transaction ID"
                            inputProps={{
                                value: transactionId,
                                onChange: (e) => setTransactionId(e.target.value),
                            }}
                        />
                    </div>
                    <div className="w-[200px]">
                        <CurrencyTextField
                            value={paymentAmount}
                            onChange={(value) => setPaymentAmount(String(value))}
                            label="Payment amount"
                            inputProps={{
                                onBlur: () => onUpdateCheckAmount(paymentAmount),
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="w-full overflow-x-auto">
                <table className="w-full border-collapse border-spacing-0 text-sm">
                    <thead>
                        <tr className="align-middle [&>*]:px-4 [&>*]:py-1.5">
                            {tableHeaders.map((heading) => (
                                <td key={heading.heading} className={heading.className || ''}>
                                    {heading.headingValue || heading.heading}
                                </td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((payment, index) => (
                            <tr
                                key={payment.orderNumber + '-' + payment.billObj.id}
                                className="[&>*]:border [&>*]:py-1.5 [&>*]:pl-1.5 [&>*]:pr-2.5"
                                style={{
                                    backgroundColor: index % 2 ? '#F5F5F5' : '#FFFFFF',
                                }}
                            >
                                <td
                                    className="max-w-[18px] px-2.5 text-center"
                                    onClick={() => {
                                        if (payment.total - (payment.paid || 0) / 100 !== 0) {
                                            payment.value
                                                ? handleUpdatePayment('', index, payment)
                                                : handleUpdatePayment(payment.total - (payment.paid || 0) / 100, index, payment);
                                        }
                                    }}
                                >
                                    {payment.value ? <CheckIcon className="size-4" /> : null}
                                </td>
                                <td className="max-w-[31px] px-2.5 text-center">
                                    {payment.readyForPayment && round(payment.total - (payment.paid || 0) / 100) !== 0 ? (
                                        <CheckCircleIcon className="text-success size-4" />
                                    ) : null}
                                </td>
                                <td className="!text-wayste-blue-400 !pr-4 underline" onClick={() => onOrderSelect(payment)}>
                                    {payment.orderNumber}
                                </td>
                                <td>{payment.orderType === 'roll-off' ? 'Roll Off' : 'Universal Service'}</td>
                                <td className="!pr-4">{payment.invoiceNumber}</td>
                                <td className="max-w-40">
                                    <div className="flex justify-between">
                                        <div>$</div>
                                        {formatUSD(payment.total, true)}
                                    </div>
                                </td>
                                <td className="max-w-40">
                                    <div className="flex justify-between">
                                        <div>$</div>
                                        {formatUSD(payment.total - (payment.paid || 0) / 100, true)}
                                    </div>
                                </td>
                                <td className="max-w-40">
                                    <div className="flex justify-between">
                                        <div>$</div>
                                        <NumericFormat
                                            value={payment.value}
                                            thousandSeparator={true}
                                            onValueChange={(values) => {
                                                const { value } = values;
                                                handleUpdatePayment(+value, index, payment);
                                            }}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            className="w-full bg-transparent text-right outline-none"
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                        <tr className="[&>*]:py-1.5 [&>*]:pl-1.5 [&>*]:pr-2.5">
                            <td className="text-right" colSpan={3}>
                                Totals
                            </td>
                            <td className="max-w-40">
                                <div className="flex justify-between">
                                    <div>$</div>
                                    {formatUSD(billTotals.total, true)}
                                </div>
                            </td>
                            <td className="max-w-40">
                                <div className="flex justify-between">
                                    <div>$</div>
                                    {moneyFormatter(billTotals.totalDue)}
                                </div>
                            </td>
                            <td className="max-w-40">
                                <div className="flex justify-between">
                                    <div>$</div>
                                    {formatUSD(totalPayment, true)}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="mt-auto text-right">
                <button className="btn-dark-grey-outlined mr-5" onClick={onCancel}>
                    cancel
                </button>
                <button
                    className="btn-primary"
                    onClick={handleSave}
                    disabled={!paymentDate || totalPayment === 0 || !transactionId || !paymentAmount}
                >
                    save
                </button>
            </div>
        </div>
    );
};

export default BillPayment;
