import { useContext } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button, Dialog } from '@wayste/sour-ui';
import { UIContext } from '../../../../contexts/UIContext';

interface ChangeServiceTypeStatusProps {
    serviceType: UniversalService.ServiceTypeRecursiveUpdate;
    serviceFamilyID: string;
    show: boolean;
    setShow: (show: boolean) => void;
    onModified?: () => void;
}

export const ChangeServiceTypeStatusModal = ({ serviceType, serviceFamilyID, show, setShow, onModified }: ChangeServiceTypeStatusProps) => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);

    const handleChange = async () => {
        if (serviceType.id === undefined) return;
        try {
            const updatePayload: UniversalService.ServiceTypeUpdate = {
                active: !serviceType?.active,
            };
            await client.universalService().serviceType.update(serviceFamilyID, serviceType.id, updatePayload);
            showFlash('Service Type Status Successfully Changed', 'success');
            if (onModified) onModified();
        } catch {
            showFlash('An Error Occurred', 'warning');
        } finally {
            setShow(false);
        }
    };

    return (
        <Dialog
            variant={serviceType.active ? 'delete' : undefined}
            open={show}
            onClose={() => setShow(false)}
            styledTitle={`${serviceType.active ? 'Deactivate' : 'Reactivate'} Service Type`}
            className="max-w-2xl"
        >
            <div className="my-5 flex flex-col items-start justify-start">
                Are you sure you want to {serviceType.active ? 'deactivate' : 'reactivate'} {serviceType.name}
            </div>

            <div className="flex justify-end gap-4">
                <Button className="btn-dark-grey-outlined mr-5" onClick={() => setShow(false)} type="button">
                    Cancel
                </Button>
                <Button
                    className={serviceType.active ? 'btn-delete' : 'btn-primary'}
                    onClick={() => {
                        handleChange();
                    }}
                >
                    {serviceType?.active ? 'Deactivate' : 'Reactivate'}
                </Button>
            </div>
        </Dialog>
    );
};
