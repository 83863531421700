import { Customer, Material, MaterialLabels, ServiceLocationTransport } from '@alliance-disposal/transport-types';
import { moneyFormatter } from '@wayste/utils';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useFormContext } from 'react-hook-form';
import { priceTypesEnums } from '../../utils/shared-types';
import type { ROQuoteFormType } from '../LeadForms/utils/prepare-lead-form';
import PricingCalc, { type OnAddDetailsValues } from '../PricingCalc';

const quoteHeadings = ['Size', 'Material', 'Ton Limit', 'Overage Fee', 'Rental Period', 'Price Quoted', ''];

/**
 *
 * @param {[Object]} quotes Array of Lead Quote objects
 * @param {Function} onAddOrder Passes up quote to be sent to OrderCreate
 */

export interface ROQuoteSubFormProps {
    lead?: Customer.AllianceLeadTransport;
    onAddOrder: (quote: Customer.AllianceLeadQuoteCreateTransport) => void;
}

const ROQuoteSubForm = ({ lead, onAddOrder }: ROQuoteSubFormProps) => {
    const { watch, setValue } = useFormContext();
    const watchQuotes: ROQuoteFormType['quotes'] = watch('quotes');
    const watchRequestedSize = watch('requestedSize');

    const handleAddQuoteClicked = (values: OnAddDetailsValues) => {
        const newQuotes: ROQuoteFormType['quotes'] = [...watchQuotes];
        if (values.price === 0) {
            setValue('requestedSize', {
                size: +values.expectedSize,
                type: '',
            });
        } else {
            newQuotes.push({
                ...values,
                dumpRate: values.dumpRate,
                price: values.price,
                overage: values.overage,
                size: {
                    size: +values.expectedSize,
                    // TODO this has to be changed from OPEN_TOP to variable once we start handling other types
                    type: 'OPEN_TOP',
                },
                rentPeriod: {
                    value: values.rentPeriod ?? 0,
                    unit: 'DAYS',
                },
                taxable: values.tax,
                tonLimit: values.weightLimit,
                rentExtensionFee: values.rentExtensionFee,
            });
        }
        setValue('quotes', newQuotes);
    };

    const handleRemove = (index: number) => {
        const newQuotes = watchQuotes.filter((_, itemIndex) => itemIndex !== index);
        setValue('quotes', newQuotes);
    };

    const NoQuotes = () => (
        <h6 className="px-4 text-xl text-gray-400">
            {lead?.requestedSize?.size
                ? `Requested ${lead.requestedSize.size} YD for ${MaterialLabels[lead.requestedMaterial as Material]}`
                : watchRequestedSize?.size
                  ? `Requested ${watchRequestedSize.size} YD`
                  : 'Not Quoted'}
        </h6>
    );

    const QuoteTable = () => (
        <div className="w-full overflow-x-auto">
            <table className="w-full border-collapse border-spacing-0 text-sm">
                <thead>
                    <tr>
                        {quoteHeadings.map((item) => (
                            <td key={item} className="whitespace-nowrap border-b bg-slate-100 px-4 py-1.5 text-left font-normal">
                                {item}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {watchQuotes.map((item, index) => (
                        <tr key={item.size.size + item.material} className="border-b [&>*]:px-4 [&>*]:py-1.5">
                            <td>
                                {item.size.size}
                                {' YD'}
                            </td>
                            <td>{MaterialLabels[item.material as Material]}</td>
                            <td>{item.priceType === priceTypesEnums.ton ? `${item.tonLimit} tons` : 'N/A'}</td>
                            <td>{item.priceType === priceTypesEnums.ton ? moneyFormatter(item.overage) : 'N/A'}</td>
                            <td>
                                {item.rentPeriod?.value}
                                {' Days '}
                                {item.rentExtensionFee ? moneyFormatter(item?.rentExtensionFee) : 0}
                                {' / extra day'}
                            </td>
                            <td>{moneyFormatter(item.price)}</td>
                            <td>
                                <button
                                    className="btn-primary-text-only"
                                    onClick={() => onAddOrder(item)}
                                    disabled={!item.price}
                                    type="button"
                                >
                                    <PlusIcon className="mr-1 size-4" />
                                    Add Order
                                </button>
                                {!('id' in item) && (
                                    <button className="btn-base text-delete ml-1" onClick={() => handleRemove(index)} type="button">
                                        <XMarkIcon className="mr-1 size-4" />
                                        Remove
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <div>
            {lead?.requestedSize && (
                <div className="p-3">
                    <h6 className="text-xl">
                        Requested a {lead.requestedSize.size} YD for {MaterialLabels[lead.requestedMaterial as Material]}
                    </h6>
                </div>
            )}
            <div style={{ margin: '20px 0' }}>
                <PricingCalc
                    onAddDetails={handleAddQuoteClicked}
                    forLead
                    onGoogleSelect={(value: ServiceLocationTransport) => setValue('serviceLocation', value)}
                    startingValues={
                        lead
                            ? {
                                  address: {
                                      addressLine1: lead.serviceLocation?.address.addressLine1 || '',
                                      addressLine2: lead.serviceLocation?.address.addressLine2 || '',
                                      city: lead.serviceLocation?.address.city || '',
                                      state: lead.serviceLocation?.address.state || '',
                                      zip: lead.serviceLocation?.address.zip || '',
                                      country: lead.serviceLocation?.address.country || '',
                                  },
                                  coordinates: lead.serviceLocation?.coordinates || undefined,
                                  county: lead.serviceLocation?.county || undefined,
                              }
                            : undefined
                    }
                />
            </div>
            <div className="shadow-dark mb-5 rounded bg-white py-4">
                <h6 className="px-4 pb-2 text-xl">Quote Details</h6>
                {watchQuotes.length === 0 ? <NoQuotes /> : <QuoteTable />}
            </div>
        </div>
    );
};

export default ROQuoteSubForm;
