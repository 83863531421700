import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Invoice, S3ItemReference, UniversalService } from '@alliance-disposal/transport-types';
import { Loading } from '@wayste/sour-ui';
import { formatServiceAddress, moneyFormatter, toTitleCase } from '@wayste/utils';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import FileHandler from '../../../../components/FileHandler/FileHandler';
import OrderImageHandler from '../../../../components/OrderImageHandler';
import { orderImageTypes } from '../../../../utils';
import { BillingContext } from '../../context';
import BillPayment from '../BillPayment';
import InvoiceSend from '../InvoiceSend';
import ServiceOrderInvoiceSend from '../ServiceOrderInvoiceSend';
import TransactionCreate from '../TransactionCreate';
import BillItemDialog from './BillItemDialog';
import DetailsBar from './DetailsBar';
import EditModal from './EditModal';
import EditPayableModal from './EditPayableModal';
import LineItemModal from './LineItemModal';
import PayableCard from './PayableCard';
import ReceivableCard from './ReceivableCard';
import RefundDialog from './RefundDialog';
import TopBar from './TopBar';

const InvoiceUpdate = () => {
    const client = useWaysteClient();
    const { hasChanges, receivables, payables, selectedOrder, isLoadingOrder, view, refetchOrder, customer } = useContext(BillingContext);
    // MODAL STATE
    const [showOrderImages, setShowOrderImages] = useState<boolean>(false);
    const [showEditInvoice, setShowEditInvoice] = useState<boolean>(false);
    const [showAddEditItem, setShowAddEditItem] = useState<boolean>(false);
    const [showRefundDialog, setShowRefundDialog] = useState<boolean>(false);
    const [showEditPayableModal, setShowEditPayableModal] = useState<boolean>(false);
    const [showBillItem, setShowBillItem] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    // RECEIVABLE STATE
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice.ReceivableTransport | undefined>(undefined);
    const [selectedInvoiceIndex, setSelectedInvoiceIndex] = useState<number | undefined>(undefined);
    const [showCreateInvoice, setShowCreateInvoice] = useState(false);
    const [showTransactionCreate, setShowTransactionCreate] = useState(false);

    // PAYABLE STATE
    const [selectedBill, setSelectedBill] = useState<Invoice.PayableTransport | undefined>(undefined);
    const [selectedBillIndex, setSelectedBillIndex] = useState<number | undefined>(undefined);
    const [selectedHauler, setSelectedHauler] = useState<Hauler.HaulerWithAapTransport | null>(null);
    const [showBillPayment, setShowBillPayment] = useState(false);

    const [selectedItem, setSelectedItem] = useState<{ item: Partial<Invoice.LineItemTransport>; index: number } | null>(null);
    const [selectedBillItem, setSelectedBillItem] = useState<{
        item: Partial<Invoice.LineItemTransport>;
        index: number;
    } | null>(null);

    ///////////////////////////////////////
    // MODAL HANDLERS
    ///////////////////////////////////////

    const closeAllModals = () => {
        setShowRefundDialog(false);
        setShowTransactionCreate(false);
        setShowBillPayment(false);
        setShowCreateInvoice(false);
        setShowEditInvoice(false);
        setShowAddEditItem(false);
        setShowEditPayableModal(false);
        setShowBillItem(false);
        setSelectedItem(null);
        setSelectedInvoiceIndex(undefined);
        setSelectedInvoice(undefined);
        setSelectedBill(undefined);
        setSelectedBillIndex(undefined);
        setSelectedBillItem(null);
        setSelectedHauler(null);
    };

    const handleRefundButtonClick = (invoiceNumber: number) => {
        if (!selectedOrder) return;
        setSelectedInvoice(receivables[invoiceNumber]);
        if (hasChanges) {
            alert('Save your current changes before issuing a refund');
            return;
        }
        setShowRefundDialog(true);
    };

    const handleCreateNewCard = () => {
        if (view === 'bill') setShowEditPayableModal(true);
    };

    const handleBillEditClick = (index: number) => {
        if (!selectedOrder) return;
        setSelectedBill(payables[index]);
        setSelectedBillIndex(index);
        setShowEditPayableModal(true);
    };

    const handleLineItemClicked = (item: { item: Partial<Invoice.LineItemTransport>; index: number } | null, key: number) => {
        if (!selectedOrder) return;
        if (view === 'invoice') {
            setSelectedItem(item);
            setSelectedInvoice(receivables[key]);
            setShowAddEditItem(true);
            setSelectedInvoiceIndex(key);
        }
        if (view === 'bill') {
            setSelectedBillItem(item);
            setSelectedBill(payables[key]);
            setShowBillItem(true);
            setSelectedBillIndex(key);
        }
    };

    const handleAddPayment = async (view: 'invoice' | 'bill', haulerID?: string) => {
        if (view === 'invoice') {
            setShowTransactionCreate(true);
        } else if (view === 'bill') {
            if (!haulerID) {
                alert('Missing hauler id');
                return;
            }
            setIsLoading(true);
            const haulerResponse = await client.vendorService().adminPortal.fetch(haulerID);
            setSelectedHauler(haulerResponse);
            setShowBillPayment(true);
            setIsLoading(false);
        }
    };

    const handleTransactionSave = () => {
        closeAllModals();
        refetchOrder();
    };

    const getView = () => {
        if (!selectedOrder) return <div />;
        if (view === 'invoice') {
            return receivables.map((receivable, index) => {
                if (receivable.invoiceDetails.void && !receivable.invoiceDetails.issueDate) return null;
                return (
                    <ReceivableCard
                        key={receivable.invoiceDetails.invoiceNumber + '-' + index}
                        receivableIndex={index}
                        receivable={receivable}
                        onCreateReceipt={() => {
                            setSelectedInvoice(receivables[index]);
                            setShowCreateInvoice(true);
                        }}
                        onOrderImageClicked={() => setShowOrderImages(true)}
                        onEditInvoiceClicked={() => {
                            setSelectedInvoice(receivable);
                            setShowEditInvoice(true);
                            setSelectedInvoiceIndex(index);
                        }}
                        onLineItemClicked={(item) => handleLineItemClicked(item, index)}
                        onRefundClick={() => {
                            handleRefundButtonClick(index);
                        }}
                    />
                );
            });
        }
        if (view === 'bill') {
            return (
                <>
                    {selectedOrder?.order?.haulerPricingSnapshot && (
                        <div className="border-wayste-blue-400 w-full rounded-md border-2 border-dashed bg-white p-3 shadow-md">
                            <div className="flex items-center justify-between">
                                <div className="flex justify-start gap-2">
                                    <span className="font-semibold">Expected Costs</span>
                                    <span className="font-light">|</span>
                                    <span>{selectedOrder.vendorName}</span>
                                    <span className="font-light">|</span>
                                    <span>{formatServiceAddress(selectedOrder.serviceLocation?.address)}</span>
                                </div>
                                <button
                                    className="btn-primary gap-2"
                                    type="button"
                                    onClick={() => {
                                        setShowEditPayableModal(true);
                                        setSelectedBill(undefined);
                                        setSelectedBillIndex(undefined);
                                    }}
                                >
                                    <ClipboardDocumentListIcon className="h-5 w-5" />
                                    <span>Create Bill</span>
                                </button>
                            </div>
                            <div className="grid w-full grid-cols-5 px-4">
                                <span className="font-semibold">Item</span>
                                <span className="font-semibold">Description</span>
                                <span className="font-semibold">Unit Cost</span>
                                <span className="font-semibold">Quantity</span>
                                <span className="font-semibold">Total</span>
                                <span>Haul</span>
                                <span></span>
                                <span>{moneyFormatter(selectedOrder.order.haulerPricingSnapshot.haul)}</span>
                                <span>{1}</span>
                                <span>{moneyFormatter(selectedOrder.order.haulerPricingSnapshot.haul)}</span>
                                <span>Dump</span>
                                <span></span>
                                <span>
                                    {moneyFormatter(
                                        selectedOrder.order.haulerPricingSnapshot.dump /
                                            (selectedOrder.order.haulerPricingSnapshot?.tonLimit.value || 1),
                                    )}
                                </span>
                                <span>{selectedOrder.order.haulerPricingSnapshot?.tonLimit?.value || 0}</span>
                                <span>{moneyFormatter(selectedOrder.order.haulerPricingSnapshot.dump)}</span>
                            </div>
                            <div className="grid w-full grid-cols-5 border-t px-4">
                                <span className="col-start-4">Total:</span>
                                <span className="col-start-5">
                                    {moneyFormatter(
                                        selectedOrder.order.haulerPricingSnapshot.haul + selectedOrder.order.haulerPricingSnapshot.dump,
                                    )}
                                </span>
                            </div>
                        </div>
                    )}
                    {selectedOrder?.serviceOrder?.events.some((event) => event.unitCost) && (
                        <div className="border-wayste-blue-400 w-full rounded-md border-2 border-dashed bg-white p-3 shadow-md">
                            <div className="flex items-center justify-between">
                                <div className="flex justify-start gap-2">
                                    <span className="font-semibold">Expected Costs</span>
                                    <span className="font-light">|</span>
                                    <span>{selectedOrder.vendorName}</span>
                                    <span className="font-light">|</span>
                                    <span>{formatServiceAddress(selectedOrder.serviceLocation?.address)}</span>
                                </div>
                                <button
                                    className="btn-primary gap-2"
                                    type="button"
                                    onClick={() => {
                                        setShowEditPayableModal(true);
                                        setSelectedBill(undefined);
                                        setSelectedBillIndex(undefined);
                                    }}
                                >
                                    <ClipboardDocumentListIcon className="h-5 w-5" />
                                    <span>Create Bill</span>
                                </button>
                            </div>
                            <div className="grid w-full grid-cols-5 px-4">
                                <span className="font-semibold">Item</span>
                                <span className="font-semibold">Description</span>
                                <span className="font-semibold">Unit Cost</span>
                                <span className="font-semibold">Quantity</span>
                                <span className="font-semibold">Total</span>
                                {selectedOrder.serviceOrder.events
                                    .filter((event) => event.unitCost)
                                    .map((event) => {
                                        return (
                                            <>
                                                <span>{toTitleCase(event.lineItemType.description)}</span>
                                                <span> {event.description}</span>
                                                <span>{moneyFormatter(event.unitCost)}</span>
                                                <span>{event.costQuantity}</span>
                                                <span>{moneyFormatter(event.unitCost * event.costQuantity)}</span>
                                            </>
                                        );
                                    })}
                            </div>
                            <div className="grid w-full grid-cols-5 border-t px-4">
                                <span className="col-start-4">Total:</span>
                                <span className="col-start-5">
                                    {moneyFormatter(
                                        selectedOrder.serviceOrder.events.reduce(
                                            (acc, event) => acc + event.unitCost * event.costQuantity,
                                            0,
                                        ),
                                    )}
                                </span>
                            </div>
                        </div>
                    )}
                    {payables.map((payable, index) => {
                        if (payable.invoiceDetails.void) return null;
                        return (
                            <PayableCard
                                key={payable.invoiceDetails.invoiceNumber + '-' + index}
                                payableIndex={index}
                                payable={payables[index]}
                                onOrderImageClicked={() => setShowOrderImages(true)}
                                onEditBillClicked={() => handleBillEditClick(index)}
                                onLineItemClicked={(item) => handleLineItemClicked(item, index)}
                            />
                        );
                    })}
                </>
            );
        }
        return <div />;
    };

    //////////////////////////////////////////////////////////
    // ONlY FOR US SERVICE ORDER
    const onFileUpload = async (file: S3ItemReference) => {
        if (!selectedOrder?.serviceOrder) return;
        const orderUpdate: UniversalService.ServiceOrderUpdate = {
            images: [...(selectedOrder.images || []), file],
        };
        await client
            .universalService()
            .serviceGrouping.serviceOrder.update(selectedOrder.serviceOrder.serviceGroupingID, selectedOrder.id, orderUpdate);
        selectedOrder.images = orderUpdate.images || [];
    };

    const onFilesEdit = async (files: S3ItemReference[]) => {
        if (!selectedOrder?.serviceOrder) return;
        const orderUpdate: UniversalService.ServiceOrderUpdate = {
            images: files,
        };
        await client
            .universalService()
            .serviceGrouping.serviceOrder.update(selectedOrder.serviceOrder.serviceGroupingID, selectedOrder.id, orderUpdate);
        selectedOrder.images = orderUpdate.images || [];
    };
    //////////////////////////////////////////////////////////

    return (
        <div className="flex h-full max-h-screen flex-1 overflow-y-hidden">
            <div className="relative inset-0 flex w-full flex-col overflow-auto bg-gray-100">
                {(isLoading || isLoadingOrder) && <Loading fullScreen />}
                <TopBar onAddPayment={(haulerID) => handleAddPayment(view, haulerID)} onCreateNewCard={handleCreateNewCard} />
                <div className="flex flex-grow flex-col p-2 pb-5">{getView()}</div>
                <DetailsBar receivables={receivables} payables={payables} />
                {/* Dialogs */}
                {showRefundDialog && selectedInvoice && (
                    <RefundDialog open={showRefundDialog} onClose={closeAllModals} invoice={selectedInvoice} />
                )}
                {showOrderImages && selectedOrder?.order && (
                    <OrderImageHandler
                        order={selectedOrder.order}
                        open={showOrderImages}
                        onCancel={() => setShowOrderImages(false)}
                        showUploadDropZones
                    />
                )}
                {selectedOrder?.serviceOrder && (
                    <FileHandler
                        files={selectedOrder.images || []}
                        folderName={`order${selectedOrder.orderNumber ?? ''}/`}
                        types={orderImageTypes}
                        open={showOrderImages}
                        onCancel={() => setShowOrderImages(false)}
                        onFileUpload={onFileUpload}
                        onFilesEdit={onFilesEdit}
                        showUploadDropZones
                    />
                )}
                <EditModal open={showEditInvoice} invoice={selectedInvoice || null} onCancel={closeAllModals} />
                {selectedOrder && (
                    <LineItemModal
                        open={Boolean(showAddEditItem && selectedInvoice)}
                        lineItem={showAddEditItem && selectedItem ? selectedItem.item : null}
                        selectedInvoice={showAddEditItem ? selectedInvoice : null}
                        receivableIndex={selectedInvoiceIndex}
                        lineItemIndex={selectedItem?.index}
                        onCancel={closeAllModals}
                        serviceOrder={selectedOrder?.serviceOrder}
                    />
                )}
                <EditPayableModal
                    open={showEditPayableModal}
                    payable={selectedBill}
                    payableIndex={selectedBillIndex}
                    onClose={closeAllModals}
                />
                {selectedOrder && (
                    <BillItemDialog
                        open={Boolean(showBillItem && selectedBill)}
                        lineItem={selectedBillItem ? selectedBillItem.item : null}
                        payableIndex={selectedBillIndex}
                        lineItemIndex={selectedBillItem?.index}
                        onCancel={closeAllModals}
                        serviceOrder={selectedOrder?.serviceOrder}
                    />
                )}
                <BillPayment
                    open={showBillPayment}
                    hauler={selectedHauler}
                    onCancel={closeAllModals}
                    onSave={() => handleTransactionSave()}
                />
                {showCreateInvoice && selectedOrder?.order && selectedInvoice && customer && (
                    <InvoiceSend
                        open={showCreateInvoice}
                        customer={customer}
                        onCancel={closeAllModals}
                        onSend={() => handleTransactionSave()}
                        order={selectedOrder.order}
                        receivable={selectedInvoice}
                        receivables={receivables}
                    />
                )}
                {showCreateInvoice && selectedInvoice && customer && selectedOrder?.serviceOrder && (
                    <ServiceOrderInvoiceSend
                        open={showCreateInvoice}
                        customer={customer}
                        onCancel={closeAllModals}
                        onSend={() => handleTransactionSave()}
                        order={selectedOrder.serviceOrder}
                        receivable={selectedInvoice}
                    />
                )}
                {showTransactionCreate && customer && (
                    <TransactionCreate
                        open={showTransactionCreate}
                        onCancel={closeAllModals}
                        customer={customer}
                        onSave={() => handleTransactionSave()}
                    />
                )}
            </div>
        </div>
    );
};

export default InvoiceUpdate;
