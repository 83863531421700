import { useEffect, useState } from 'react';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Checkbox, Loading } from '@wayste/sour-ui';
import { Button, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { Tooltip } from '@wayste/sour-ui';
import {
    StatusType,
    createCommunicationTriggers,
    nullableFields,
    parseCommunicationTriggers,
    updateCommunicationTriggers,
} from '@wayste/utils';
import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid';
import { Controller, FormProvider, SubmitHandler, get, useFieldArray, useForm } from 'react-hook-form';
import { ChangeLineItemTypeStatusModal } from '../../pages/ServiceUpdate/ChangeLineItemTypeStatusModal';
import { ChangeServiceTypeStatusModal } from '../../pages/ServiceUpdate/ChangeServiceTypeStatusModel';
import SortableList from './StatusesDragAndDrop';

export interface ServiceForm extends UniversalService.ServiceTypeFamilyUpsert {
    statuses: StatusType[];
    preStatuses: StatusType[];
    postStatuses: StatusType[];
}

///////////////////////////////////////////////
// DEFAULT FORM VALUES
///////////////////////////////////////////////

const blankServiceType: UniversalService.ServiceTypeRecursiveUpdate = {
    name: '',
    notes: '',
};

const blankLineItemType: UniversalService.ServiceLineItemTypeUpsert = {
    description: '',
    internalNotes: '',
    active: true,
    baseCost: 0,
    basePrice: 0,
    minQuantity: 0,
    taxable: true,
    unitCost: 0,
    unitPrice: 0,
    unitType: 'each',
    displayName: '',
    rfpHelperText: '',
    rfpAlwaysGetPrice: false,
};

const defaultForm: ServiceForm = {
    serviceTypes: [
        {
            name: '',
            notes: '',
        },
    ],
    isSubscription: false,
    preStatuses: [
        {
            status: 'UNASSIGNED',
        },
        {
            status: 'ASSIGNED',
        },
    ],
    statuses: [],
    postStatuses: [
        {
            status: 'COMPLETED',
        },
    ],
    shortCode: '',
    name: '',
    allowedStatuses: [],
    initialStatus: 'UNASSIGNED',
    communicationTriggers: [],
    lineItemTypes: [],
};

type ServicesFormProps = {
    serviceFamily?: UniversalService.ServiceTypeFamily;
    serviceFamilyID?: string;
    onSubmit: (data: UniversalService.ServiceTypeFamilyUpsert, id?: string) => void;
    onCancel: () => void;
    isLoading: boolean;
    onModified?: () => void;
};

export const ServicesForm = ({ onSubmit, serviceFamily, serviceFamilyID, onCancel, isLoading, onModified }: ServicesFormProps) => {
    //LOCAL STATE
    const [mode, setMode] = useState<'create' | 'edit'>('create');
    const [activeStatuses, setActiveStatuses] = useState<string[]>([]);

    // DIALOG
    const [changeServiceTypeStatusDialogOpen, setChangeServiceTypeStatusDialogOpen] = useState<boolean>(false);
    const [changeServiceType, setChangeServiceType] = useState<UniversalService.ServiceTypeRecursiveUpdate | undefined>(undefined);
    const [changeLineItemTypeStatusDialogOpen, setChangeLineItemTypeStatusDialogOpen] = useState<boolean>(false);
    const [changeLineItemType, setChangeLineItemType] = useState<UniversalService.ServiceLineItemTypeUpsert | undefined>(undefined);

    //FORM CONTEXT
    const methods = useForm<ServiceForm>();

    const {
        handleSubmit,
        control,
        getValues,
        reset,
        formState: { errors },
    } = methods;

    //FIELD ARRAYS USED FOR Service Type
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'serviceTypes',
    });

    const {
        fields: lineItemFields,
        append: appendLineItem,
        remove: removeLineItem,
    } = useFieldArray({
        control,
        name: 'lineItemTypes',
    });

    ///////////////////////////////////////////////
    // FUNCTIONS
    ///////////////////////////////////////////////

    const handleAddServiceType = () => {
        append({ ...blankServiceType });
    };

    const handleAddLineItemType = () => {
        appendLineItem({ ...blankLineItemType });
    };

    const handleRemoveServiceType = (index: number) => {
        remove(index);
    };

    const handleRemoveLineItemType = (index: number) => {
        removeLineItem(index);
    };

    const isDefaultLineItemType = (description: string) => {
        return UniversalService.DefaultLineItemTypes.includes(description as (typeof UniversalService.DefaultLineItemTypes)[number]);
    };

    // FORM SUBMISSION HANDLER
    const submitForm: SubmitHandler<ServiceForm> = (data: ServiceForm) => {
        const newData: UniversalService.ServiceTypeFamilyUpsert = {
            ...data,
            communicationTriggers:
                mode === 'edit'
                    ? updateCommunicationTriggers([...data.preStatuses, ...data.statuses, ...data.postStatuses])
                    : createCommunicationTriggers([...data.preStatuses, ...data.statuses, ...data.postStatuses]),
            allowedStatuses: [...data.preStatuses, ...data.statuses, ...data.postStatuses].map((status: StatusType) => {
                return status.status;
            }),
        };

        onSubmit(nullableFields(newData, 'initialStatus'), serviceFamilyID);
    };

    const parseForm = (data: UniversalService.ServiceTypeFamily) => {
        data.lineItemTypes.sort((a, b) => {
            const isDefaultA = isDefaultLineItemType(a.description);
            const isDefaultB = isDefaultLineItemType(b.description);
            if (isDefaultA !== isDefaultB) {
                // Sort by isDefaultLineItemType
                return isDefaultA ? -1 : 1;
            } else {
                // If both have the same isDefaultLineItemType status, sort alphabetically
                return a.description.localeCompare(b.description);
            }
        });
        data.serviceTypes.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        const formData: ServiceForm = {
            ...data,
            preStatuses: [
                {
                    status: 'UNASSIGNED',
                },
                ...(parseCommunicationTriggers(data.communicationTriggers, data.allowedStatuses).filter(
                    (status: StatusType) => status.status === 'ASSIGNED',
                ) as StatusType[]),
            ],
            postStatuses: [
                {
                    status: 'COMPLETED',
                },
            ],
            statuses: parseCommunicationTriggers(data.communicationTriggers, data.allowedStatuses).filter(
                (status: StatusType) => status.status !== 'UNASSIGNED' && status.status !== 'ASSIGNED' && status.status !== 'COMPLETED',
            ) as StatusType[],
        };
        setActiveStatuses(data.allowedStatuses);
        return formData;
    };

    ///////////////////////////////////////////////
    // HOOKS SECTION
    ///////////////////////////////////////////////

    // SET DEFAULT VALUES WHEN EDITING A SERVICE
    useEffect(() => {
        const newData = defaultForm;
        if (serviceFamily) {
            setMode('edit');
            reset({
                ...(parseForm(serviceFamily) as ServiceForm),
            });
            // SET DEFAULT VALUES FOR FORM
        } else {
            setMode('create');
            reset({ ...(newData as ServiceForm) });
        }
    }, [serviceFamily]);

    ///////////////////////////////////////////////
    // RENDER SECTION
    ///////////////////////////////////////////////

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submitForm)}>
                {serviceFamilyID && changeServiceType && (
                    <ChangeServiceTypeStatusModal
                        serviceType={changeServiceType}
                        serviceFamilyID={serviceFamilyID}
                        show={changeServiceTypeStatusDialogOpen}
                        setShow={(show: boolean) => {
                            setChangeServiceTypeStatusDialogOpen(show);
                        }}
                        onModified={onModified}
                    />
                )}
                {serviceFamilyID && changeLineItemType && (
                    <ChangeLineItemTypeStatusModal
                        lineItemType={changeLineItemType}
                        serviceFamilyID={serviceFamilyID}
                        show={changeLineItemTypeStatusDialogOpen}
                        setShow={(show: boolean) => {
                            setChangeLineItemTypeStatusDialogOpen(show);
                        }}
                        onModified={onModified}
                    />
                )}

                <div className="flex flex-col space-y-6 p-2 ">
                    {/* Service Type Family NAME FIELD  */}
                    <div className="w-1/3 pr-2">
                        <Controller
                            control={control}
                            name={'name'}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Service Type Family name is required',
                                },
                            }}
                            defaultValue={''}
                            render={({ field }) => (
                                <TextField
                                    type={'string'}
                                    label="Service Type Family name"
                                    required
                                    error={get(errors, 'name')}
                                    inputProps={{
                                        ...field,
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="w-1/3 pr-2">
                        <Controller
                            control={control}
                            name={'shortCode'}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Service shortCode name is required',
                                },
                                maxLength: {
                                    value: 2,
                                    message: 'Service shortCode must be 2 characters',
                                },
                            }}
                            defaultValue={''}
                            render={({ field }) => (
                                <TextField
                                    type={'string'}
                                    label="Service short code"
                                    required
                                    error={get(errors, 'shortCode')}
                                    inputProps={{
                                        ...field,
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="flex flex-col space-y-4  md:flex-row md:space-x-4 md:space-y-0 ">
                        {/* SENDGRID FOR CUSTOMER CONFIRMATION FIELD */}
                        <Controller
                            control={control}
                            name={'customerConfirmation'}
                            defaultValue={''}
                            render={({ field }) => (
                                <TextField
                                    type={'string'}
                                    label="Sendgrid ID for customer confirmation"
                                    error={get(errors, 'customerConfirmation')}
                                    inputProps={{
                                        ...field,
                                    }}
                                />
                            )}
                        />
                        {/* SENDGRID FOR CUSTOMER CONFIRMATION FIELD */}
                        <Controller
                            control={control}
                            name={'customerOrderChanges'}
                            defaultValue={''}
                            render={({ field }) => (
                                <TextField
                                    type={'string'}
                                    label="Sendgrid ID for customer order changes"
                                    error={get(errors, 'customerOrderChanges')}
                                    inputProps={{
                                        ...field,
                                    }}
                                />
                            )}
                        />
                        {/* SENDGRID FOR CUSTOMER CONFIRMATION FIELD */}
                        <Controller
                            control={control}
                            name={'vendorOrderChanges'}
                            defaultValue={''}
                            render={({ field }) => (
                                <TextField
                                    type={'string'}
                                    label="Sendgrid ID for vendor order changes"
                                    error={get(errors, 'vendorOrderChanges')}
                                    inputProps={{
                                        ...field,
                                    }}
                                />
                            )}
                        />
                    </div>

                    {/* SERVICE STATUSES FIELDS */}
                    <div className="text-xl">Service Statuses</div>

                    <SortableList serviceFamilyID={serviceFamilyID} mode={mode} activeStatuses={activeStatuses} />

                    {/* SERVICE STATUSES FIELDS */}
                    <div className="text-xl">Service Type</div>

                    {fields.map((item, index) => {
                        return (
                            <div className="a flex flex-row items-center space-x-6" key={item.id}>
                                <Controller
                                    control={control}
                                    name={`serviceTypes.${index}.name`}
                                    defaultValue={''}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Service Type name is required',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            type={'string'}
                                            label="Service Type name"
                                            required
                                            error={get(errors, 'serviceTypes.${index}.name')}
                                            inputProps={{
                                                ...field,
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name={`serviceTypes.${index}.setupType`}
                                    defaultValue="FULL"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Service Type setup type is required',
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Select
                                            error={fieldState.error}
                                            label="Setup Type"
                                            onSelect={field.onChange}
                                            defaultValue={'FULL'}
                                            value={field.value}
                                            helperText="Denotes what FE fields are shown"
                                        >
                                            {[...UniversalService.SetupTypes].map((option: string) => (
                                                <SelectOption key={option} value={option}>
                                                    {option}
                                                </SelectOption>
                                            ))}
                                        </Select>
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name={`serviceTypes.${index}.notes`}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <TextField
                                            type={'string'}
                                            label="notes"
                                            error={get(errors, 'serviceTypes.${index}.internalNotes')}
                                            inputProps={{
                                                ...field,
                                            }}
                                        />
                                    )}
                                />
                                {mode === 'create' || getValues(`serviceTypes.${index}`).id === undefined ? (
                                    <Button
                                        className="w-[240px]"
                                        onClick={() => handleRemoveServiceType(index)}
                                        endIcon={<TrashIcon className="h-5 w-5 cursor-pointer text-red-600" />}
                                    >
                                        {null}
                                    </Button>
                                ) : (
                                    <>
                                        {item.active ? (
                                            <Button
                                                className="btn-delete w-[200px]"
                                                onClick={() => {
                                                    setChangeServiceTypeStatusDialogOpen(true);
                                                    setChangeServiceType(getValues(`serviceTypes.${index}`));
                                                }}
                                                disabled={isLoading}
                                                type="button"
                                            >
                                                Deactivate
                                            </Button>
                                        ) : (
                                            <Button
                                                className="btn-primary-outlined w-[200px]"
                                                onClick={() => {
                                                    setChangeServiceTypeStatusDialogOpen(true);
                                                    setChangeServiceType(getValues(`serviceTypes.${index}`));
                                                }}
                                                disabled={isLoading}
                                                type="button"
                                            >
                                                Reactivate
                                            </Button>
                                        )}
                                    </>
                                )}
                            </div>
                        );
                    })}
                    <div className="cursor-pointer py-2" onClick={handleAddServiceType}>
                        <div className="text-sourgum-green-400 flex flex-row items-center space-x-2">
                            <PlusIcon className="h-5 w-5" />
                            <span> Add another Service Type</span>
                        </div>
                    </div>

                    {/* SERVICE A SUBSCRIPTION */}
                    <div className="text-xl">When a Subscription</div>

                    <div className="flex flex-row space-x-6 ">
                        <Controller
                            name={'initialStatus'}
                            control={control}
                            render={({ field }) => (
                                <div className="flex flex-row space-x-6 ">
                                    <Checkbox
                                        inputProps={{
                                            value: 'type',
                                            checked: Boolean(field.value),
                                            onChange: (e) => field.onChange(e.target.checked ? 'UNASSIGNED' : ''),
                                        }}
                                        label="Reset status for every new Service Order"
                                    />
                                    {field.value && (
                                        <Select
                                            error={get(errors, 'initialStatus')}
                                            label={'Reset status to'}
                                            onSelect={field.onChange}
                                            defaultValue={''}
                                            value={field.value}
                                        >
                                            {[...UniversalService.ServiceOrderStatuses].map((option: string, index: number) => (
                                                <SelectOption key={index} value={option}>
                                                    {option}
                                                </SelectOption>
                                            ))}
                                        </Select>
                                    )}
                                </div>
                            )}
                        />
                    </div>
                    {/* SERVICE LINE ITEMS */}
                    {mode === 'edit' && (
                        <>
                            <div className="text-xl">Service Line Items</div>
                            {lineItemFields.map((item, index) => {
                                return (
                                    <div key={item.id} className="flex flex-col gap-y-4 border-b px-1 py-3 odd:bg-slate-50">
                                        {/* General Info Row */}
                                        <div className="flex flex-row items-center space-x-6">
                                            <div className="whitespace-nowrap text-sm">General Details:</div>
                                            <Controller
                                                control={control}
                                                name={`lineItemTypes.${index}.description`}
                                                defaultValue={''}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: 'Line Item Type description is required',
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        type={'string'}
                                                        label="System name"
                                                        required
                                                        error={get(errors, `lineItemTypes.${index}.description`)}
                                                        inputProps={{
                                                            ...field,
                                                            disabled: isDefaultLineItemType(item.description),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Controller
                                                control={control}
                                                name={`lineItemTypes.${index}.displayName`}
                                                defaultValue={''}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: 'Line Item Type description is required',
                                                    },
                                                }}
                                                render={({ field, fieldState }) => (
                                                    <TextField
                                                        label="Display name"
                                                        required
                                                        error={fieldState.error}
                                                        inputProps={{
                                                            ...field,
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Controller
                                                control={control}
                                                name={`lineItemTypes.${index}.internalNotes`}
                                                defaultValue={''}
                                                render={({ field }) => (
                                                    <TextField
                                                        type={'string'}
                                                        label="Internal Notes"
                                                        error={get(errors, `lineItemTypes.${index}.internalNotes`)}
                                                        inputProps={{
                                                            ...field,
                                                            disabled: isDefaultLineItemType(item.description),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Controller
                                                control={control}
                                                name={`lineItemTypes.${index}.minQuantity`}
                                                defaultValue={0}
                                                render={({ field }) => (
                                                    <div className="w-full">
                                                        <Tooltip text="Set to >=1 if multiple of these line items can be ordered in the same order. Otherwise set to 0.">
                                                            <TextField
                                                                type={'number'}
                                                                label="Min Quantity"
                                                                error={get(errors, 'lineItemTypes.${index}.minQuantity')}
                                                                inputProps={{
                                                                    ...field,
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                control={control}
                                                name={`lineItemTypes.${index}.taxable`}
                                                defaultValue={false}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        inputProps={{
                                                            value: 'type',
                                                            checked: field.value,
                                                            onChange: field.onChange,
                                                            disabled: isDefaultLineItemType(item.description),
                                                        }}
                                                        label="Taxable"
                                                    />
                                                )}
                                            />
                                            {getValues(`lineItemTypes.${index}`).id === undefined ? (
                                                <Button
                                                    className="w-[480px]"
                                                    onClick={() => handleRemoveLineItemType(index)}
                                                    endIcon={<TrashIcon className="h-5 w-5 cursor-pointer text-red-600" />}
                                                >
                                                    {null}
                                                </Button>
                                            ) : (
                                                <>
                                                    {item.active ? (
                                                        <Button
                                                            className="btn-delete w-[400px]"
                                                            onClick={() => {
                                                                setChangeLineItemTypeStatusDialogOpen(true);
                                                                setChangeLineItemType(getValues(`lineItemTypes.${index}`));
                                                            }}
                                                            disabled={isLoading || isDefaultLineItemType(item.description)}
                                                            type="button"
                                                        >
                                                            Deactivate
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            className="btn-primary-outlined w-[400px]"
                                                            onClick={() => {
                                                                setChangeLineItemTypeStatusDialogOpen(true);
                                                                setChangeLineItemType(getValues(`lineItemTypes.${index}`));
                                                            }}
                                                            disabled={isLoading || isDefaultLineItemType(item.description)}
                                                            type="button"
                                                        >
                                                            Reactivate
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        {/* RFP Row */}
                                        <div className="flex flex-row items-center space-x-6">
                                            <div className="whitespace-nowrap text-sm">RFP Only Details:</div>
                                            <Controller
                                                control={control}
                                                name={`lineItemTypes.${index}.rfpHelperText`}
                                                render={({ field }) => (
                                                    <div className="w-full">
                                                        <TextField
                                                            label="RFP Helper Text"
                                                            inputProps={{
                                                                ...field,
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                control={control}
                                                name={`lineItemTypes.${index}.rfpAlwaysGetPrice`}
                                                defaultValue={false}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        inputProps={{
                                                            value: 'type',
                                                            checked: field.value,
                                                            onChange: field.onChange,
                                                            disabled: isDefaultLineItemType(item.description),
                                                        }}
                                                        label="Always Require RFP Price"
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                            <div className="cursor-pointer py-2" onClick={handleAddLineItemType}>
                                <div className="text-sourgum-green-400 flex flex-row items-center space-x-2">
                                    <PlusIcon className="h-5 w-5" />
                                    <span> Add another Line Item Type</span>
                                </div>
                            </div>
                        </>
                    )}

                    <div className="flex justify-end gap-4">
                        <Button className="btn-dark-grey-outlined" onClick={onCancel} disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button className="btn-primary" type="submit" disabled={isLoading}>
                            {serviceFamily ? 'Update' : 'Create'}
                            {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
                        </Button>
                    </div>
                </div>
                <br />
                <br />
            </form>
        </FormProvider>
    );
};
