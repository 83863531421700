import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, InvoiceReviewPill, Tooltip } from '@wayste/sour-ui';
import { FormPayable } from '../../../../components/OrderStatusChanger/OrderEnterTicket/OrderEnterTicket';
import RejectInvoiceModal from './reject-invoice-modal';

interface ReviewStatusChangerProps {
    orderID: string;
    payable: Invoice.PayableTransport | FormPayable;
    onChange: (newStatus: Invoice.ReviewStatus) => void;
}

const ReviewStatusChanger = ({ orderID, payable, onChange }: ReviewStatusChangerProps) => {
    const client = useWaysteClient();
    const { setShowToast, useConfirmationDialog, setIsLoading } = useSourContext();
    const { getConfirmation } = useConfirmationDialog();
    const [rejectInvoiceModalOpen, setRejectInvoiceModalOpen] = useState(false);

    const getPayableID = (payable: Invoice.PayableTransport | FormPayable): string => {
        return (payable as FormPayable).payableID ?? (payable as Invoice.PayableTransport).id;
    };

    const handleApproveWaysteInvoice = async () => {
        const confirmed = await getConfirmation({
            message: 'Are you sure you want to approve this invoice?',
            title: 'Approve Invoice',
        });
        if (confirmed) {
            try {
                setIsLoading(true);
                await client.invoice().adminPortal.payable.review(getPayableID(payable), {
                    action: 'APPROVE',
                });

                onChange('REVIEW_APPROVED');
            } catch (error) {
                console.error('Failed to approve invoice', error);
                setShowToast({
                    severity: 'error',
                    message: 'Failed to approve invoice',
                });
            } finally {
                setIsLoading(false);
            }
        }
        return false;
    };

    const handleResolveWaysteInvoice = async () => {
        const confirmed = await getConfirmation({
            message: 'Are you sure you want to resolve this invoice?',
            title: 'Resolve Invoice',
        });
        if (confirmed) {
            try {
                setIsLoading(true);
                await client.invoice().adminPortal.payable.review(getPayableID(payable), {
                    action: 'APPROVE',
                });
                const user = await client.user().fetchCurrentUserProfile();
                if (user.authorities.some((authority) => authority.roles.includes('VENDOR_RELATIONS'))) {
                    await client.internalTicket().adminPortal.create({
                        entityID: orderID,
                        entityType: 'sourgum-order',
                        assignedTeam: 'BILLING',
                        note: 'Vendor Relations has resolved the invoice dispute',
                        status: 'OPEN',
                        // The tag ID for Billing is hardcoded here, no good way to fetch, same for staging/production
                        tags: ['bcda4327-7854-487a-b51c-9d207353df8d'],
                    });
                }
                onChange('REVIEW_APPROVED');
            } catch (error) {
                console.error('Failed to approve invoice', error);
                setShowToast({
                    severity: 'error',
                    message: 'Failed to approve invoice',
                });
            } finally {
                setIsLoading(false);
            }
        }
        return false;
    };

    const handleRejectInvoiceModalClose = async (submitted: boolean) => {
        if (submitted) {
            onChange('REVIEW_REJECTED');
        }
        setRejectInvoiceModalOpen(false);
    };

    const renderReviewStatusContent = () => {
        switch (payable.invoiceDetails.reviewStatus) {
            case 'REVIEW_PENDING':
                return (
                    <div className="mx-4 flex items-center rounded border">
                        <Button className="btn-primary-text-only font-bold" onClick={handleApproveWaysteInvoice}>
                            Approve
                        </Button>
                        <div className="h-6 border-r" />
                        <Button className="btn-error-text-only font-bold" onClick={() => setRejectInvoiceModalOpen(true)}>
                            Reject
                        </Button>
                    </div>
                );
            case 'REVIEW_APPROVED':
                return <InvoiceReviewPill reviewStatus="REVIEW_APPROVED" />;
            case 'REVIEW_REJECTED':
                return (
                    <div className="mx-4 flex items-center gap-4">
                        <InvoiceReviewPill reviewStatus="REVIEW_REJECTED" />
                        <Tooltip text={'Click this if the invoice has been corrected and is ready for payment'}>
                            <Button className="btn-primary" onClick={handleResolveWaysteInvoice}>
                                Resolve
                            </Button>
                        </Tooltip>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {renderReviewStatusContent()}
            <RejectInvoiceModal open={rejectInvoiceModalOpen} onClose={handleRejectInvoiceModalClose} payableID={getPayableID(payable)} />
        </>
    );
};

export default ReviewStatusChanger;
