import { WaysteClient } from '@alliance-disposal/client';

export const getOrderPricing = async (
    client: WaysteClient,
    location: {
        lat: number;
        lng: number;
        zip?: string;
        state: string;
    },
    customerID?: string,
) => {
    try {
        const results = await client.pricing().adminPortal.location.query(location, customerID);

        if (!results) {
            console.error('handleGetPricing Error: ', results);
            return;
        }

        const publicPricing = results.filter((item) => item.type === 'PUBLIC');
        if (publicPricing.length === 1) {
            return publicPricing[0];
        } else if (publicPricing.length > 1) {
            console.error('Pricing Overlap Error: ', publicPricing);
            return;
        } else {
            return null;
        }
    } catch (error) {
        console.error('handleGetPricing Error: ', error);
        return;
    }
};
