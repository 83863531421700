import React from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { AddressSubForm } from '@wayste/sour-components';
import { DatePicker, RHFRules, Select, SelectOption, TextField, phoneNumberMasker } from '@wayste/sour-ui';
import { formatE164ToUSPhoneNumber, formatUSPhoneNumberToE164 } from '@wayste/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { channelTypesEnums, commercialLocationTypes } from '../../utils';

export interface LeadFormProps {
    lead?: Customer.AllianceLeadTransport;
}

const USLeadSubForm = ({ lead }: LeadFormProps) => {
    const { control, watch, trigger } = useFormContext();
    const watchEmail = watch('email');
    const watchPhoneNumber = watch('phoneNumber');
    const watchChannel = lead ? lead.channel : watch('channel');

    return (
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3">
            <Controller
                name="firstName"
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'First name is required',
                    },
                }}
                render={({ field, fieldState }) => (
                    <TextField
                        label="First name"
                        required
                        error={fieldState.error}
                        inputProps={{
                            ...field,
                            autoComplete: 'no',
                        }}
                    />
                )}
            />
            <Controller
                name="lastName"
                control={control}
                render={({ field, fieldState }) => (
                    <TextField
                        label="Last name"
                        error={fieldState.error}
                        inputProps={{
                            ...field,
                            autoComplete: 'no',
                        }}
                    />
                )}
            />
            <Controller
                name="company"
                control={control}
                render={({ field, fieldState }) => (
                    <TextField
                        label="Company"
                        error={fieldState.error}
                        inputProps={{
                            ...field,
                            autoComplete: 'no',
                        }}
                    />
                )}
            />
            <Controller
                name="phoneNumber"
                control={control}
                rules={{
                    required: {
                        value:
                            watchChannel !== channelTypesEnums.yelp &&
                            watchChannel !== channelTypesEnums.thumbtack &&
                            watchChannel !== channelTypesEnums.sourgumcomChatWidget &&
                            watchChannel !== channelTypesEnums.gmb &&
                            !watchEmail?.trim(),
                        message: 'Phone number is required',
                    },
                    ...RHFRules.phoneNumber,
                }}
                render={({ field, fieldState }) => (
                    <TextField
                        startAdornment="+1"
                        type={'string'}
                        label="Phone number"
                        error={fieldState.error}
                        required={
                            watchChannel !== channelTypesEnums.yelp &&
                            watchChannel !== channelTypesEnums.thumbtack &&
                            watchChannel !== channelTypesEnums.sourgumcomChatWidget &&
                            watchChannel !== channelTypesEnums.gmb &&
                            !watchEmail?.trim()
                        }
                        inputProps={{
                            ...field,
                            value: formatE164ToUSPhoneNumber(field.value),
                            onChange: (e) => {
                                field.onChange(formatUSPhoneNumberToE164(phoneNumberMasker(e.target.value)));
                            },
                            onBlur: () => {
                                field.onBlur();
                                trigger('email');
                            },
                            autoComplete: 'no',
                        }}
                    />
                )}
            />
            <Controller
                name="email"
                control={control}
                rules={{
                    required: {
                        value:
                            watchChannel !== channelTypesEnums.yelp &&
                            watchChannel !== channelTypesEnums.thumbtack &&
                            watchChannel !== channelTypesEnums.sourgumcomChatWidget &&
                            watchChannel !== channelTypesEnums.gmb &&
                            !watchPhoneNumber?.trim(),
                        message: 'Email is required',
                    },
                    ...RHFRules.email,
                }}
                render={({ field, fieldState }) => (
                    <TextField
                        label="Email"
                        required={
                            watchChannel !== channelTypesEnums.yelp &&
                            watchChannel !== channelTypesEnums.thumbtack &&
                            watchChannel !== channelTypesEnums.sourgumcomChatWidget &&
                            watchChannel !== channelTypesEnums.gmb &&
                            !watchPhoneNumber?.trim()
                        }
                        error={fieldState.error}
                        inputProps={{
                            ...field,
                            autoComplete: 'no',
                            onBlur: () => {
                                field.onBlur();
                                trigger('phoneNumber');
                            },
                        }}
                    />
                )}
            />
            <div className="hidden md:block" />
            <Controller
                name="requestedStartDate"
                control={control}
                render={({ field, fieldState }) => (
                    <DatePicker
                        label="Requested start date"
                        error={fieldState.error}
                        value={field.value ? new Date(field.value) : undefined}
                        onChange={(value) => field.onChange((value as Date).toISOString())}
                        closeOnSelect
                    />
                )}
            />
            <Controller
                name="followUpDate"
                control={control}
                render={({ field, fieldState }) => (
                    <DatePicker
                        label="Follow up date"
                        error={fieldState.error}
                        value={field.value ? new Date(field.value) : undefined}
                        onChange={(value) => field.onChange((value as Date).toISOString())}
                        closeOnSelect
                        clearable
                    />
                )}
            />
            <div className="hidden md:block" />
            <Controller
                name="locationType"
                control={control}
                render={({ field, fieldState }) => (
                    <Select label="Location type" onSelect={(value) => field.onChange(value)} value={field.value} error={fieldState.error}>
                        {Object.entries(commercialLocationTypes).map((item) => (
                            <SelectOption key={`location-type-${item[0]}`} value={item[0]}>
                                {item[1]}
                            </SelectOption>
                        ))}
                    </Select>
                )}
            />
            <Controller
                name="currentHauler"
                control={control}
                render={({ field, fieldState }) => (
                    <TextField
                        label="Current hauler"
                        error={fieldState.error}
                        inputProps={{
                            ...field,
                            autoComplete: 'no',
                        }}
                    />
                )}
            />
            <div className="hidden md:block" />
            <div className="md:col-span-3">
                <Controller
                    name="customerNotes"
                    control={control}
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Customer notes"
                            error={fieldState.error}
                            inputProps={{
                                ...field,
                                autoComplete: 'no',
                            }}
                        />
                    )}
                />
            </div>
            {!lead && (
                <div className="md:col-span-3">
                    <Controller
                        name="notes"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="Internal notes"
                                error={fieldState.error}
                                inputProps={{
                                    ...field,
                                    autoComplete: 'no',
                                }}
                            />
                        )}
                    />
                </div>
            )}
            <h6 className="pt-2 text-xl text-gray-400 md:col-span-3">Address</h6>
            <div className="md:col-span-3">
                <AddressSubForm
                    fieldName="serviceLocation"
                    type="location"
                    apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
                    partial
                    partialRequiredFields={['state', 'city']}
                />
            </div>
        </div>
    );
};

export default USLeadSubForm;
