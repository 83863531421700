import { useState } from 'react';
import { Material, MaterialLabels, Pricing } from '@alliance-disposal/transport-types';
import { Button, Dialog, Tooltip } from '@wayste/sour-ui';
import { moneyFormatter, sortByKey } from '@wayste/utils';
import { DocumentDuplicateIcon } from '@heroicons/react/20/solid';
import { PlusIcon } from '@heroicons/react/24/outline';
import { PencilIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import AuditLogTable from '../../../components/AuditLogTable';
import MaterialPricingDialog from '../../../components/MaterialPricingDialog';

const materialHeadings = ['Material', 'Price Type', 'Haul', 'Dump', 'Sizes', 'Order Online', 'Last Updated', ''];

export const MaterialPricingDetails = ({ pricing, onUpdated }: { pricing: Pricing.PricingTransport; onUpdated: () => void }) => {
    const [showPricingHistory, setShowPricingHistory] = useState(false);
    const [showMaterialPricingDialog, setShowMaterialPricingDialog] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState<Pricing.PricingDataTransport | undefined>(undefined);

    const handleEditClick = (material: Pricing.PricingDataTransport | undefined) => {
        setSelectedMaterial(material);
        setShowMaterialPricingDialog(true);
    };

    const handleCopyClick = (material: Pricing.PricingDataTransport | undefined) => {
        setSelectedMaterial({ ...material, id: undefined } as Pricing.PricingDataTransport);
        setShowMaterialPricingDialog(true);
    };

    const getDumpRateDisplay = (material: Pricing.PricingDataTransport) => {
        if (material.type === 'flat' || material.sizes.every((size) => !size.dump)) {
            return '';
        }
        if (material.sizes.every((size) => size.dump === material.sizes[0]?.dump)) {
            return moneyFormatter(material.sizes[0]?.dump || 0);
        }
        return Object.values(material.sizes).map((item) => (
            <div key={item.size}>
                <span className="text-gray-500">{item.size}:</span> {moneyFormatter(item.dump)}
                {', '}
            </div>
        ));
    };

    const handleMaterialPricingClose = (status?: 'success' | 'error') => {
        setShowMaterialPricingDialog(false);
        setSelectedMaterial(undefined);
        if (status === 'success') {
            onUpdated();
        }
    };

    return (
        <div className="flex flex-col gap-y-3">
            <div className="flex items-end justify-between px-4">
                <div className="text-xl font-semibold leading-7 tracking-tight text-gray-800">Standard Material Pricing</div>
                <div className="flex items-center gap-x-4">
                    <Button onClick={() => setShowPricingHistory(true)} className="btn-dark-grey-outlined">
                        View Change History
                    </Button>
                    <Button
                        endIcon={<PlusIcon className="size-5" />}
                        onClick={() => setShowMaterialPricingDialog(true)}
                        className="btn-primary"
                    >
                        Add New Material
                    </Button>
                </div>
            </div>
            <div className="flex flex-col gap-y-4 overflow-auto rounded-lg border border-gray-300 bg-[#fcfcfd] text-sm shadow">
                <div className="w-full overflow-x-auto">
                    <table className="w-full border-collapse border-spacing-0 text-sm">
                        <thead>
                            <tr className="border-b">
                                {materialHeadings.map((heading) => (
                                    <td key={heading} className="whitespace-nowrap bg-slate-50 px-4 py-1.5">
                                        {heading}
                                    </td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {sortByKey('material', pricing.pricingData).map((material) => (
                                <tr key={material.material}>
                                    <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm">
                                        {MaterialLabels[material.material as Material]}
                                    </td>
                                    <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm">
                                        {Pricing.TypesOfPricingLabels[material.type as keyof typeof Pricing.TypesOfPricingLabels]}
                                        {/* {priceTypes[material.type as keyof typeof priceTypes]} */}
                                    </td>
                                    <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm">
                                        {material.type !== 'flat' && material.sizes.every((size) => size.haul === material.sizes[0]?.haul)
                                            ? `${moneyFormatter(material.sizes[0]?.haul)}`
                                            : Object.values(material.sizes).map((item) => (
                                                  <div key={item.size}>
                                                      <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}>{item.size}:</span>{' '}
                                                      {moneyFormatter(item.haul)}
                                                      {material.type === 'ton' &&
                                                      material.sizes.some((size) => size.haul !== material.sizes[0]?.haul) ? (
                                                          <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}> TL: {item.tonLimit}</span>
                                                      ) : null}
                                                      {', '}
                                                  </div>
                                              ))}
                                    </td>
                                    <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm">
                                        {getDumpRateDisplay(material)}
                                    </td>
                                    {material.type !== 'flat' ? (
                                        <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm">
                                            {material.sizes.map((size, index) => (
                                                <span key={size.size}>
                                                    {size.size}
                                                    {index === material.sizes.length - 1 ? '' : ', '}
                                                </span>
                                            ))}
                                        </td>
                                    ) : (
                                        <td />
                                    )}
                                    <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm">
                                        {material.allowOnlineCheckout ? 'Yes' : 'No'}
                                    </td>
                                    <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm">
                                        {material.lastUpdatedAt ? format(new Date(material.lastUpdatedAt), 'MM/dd/yy') : ''}
                                    </td>
                                    <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm">
                                        <div className="flex justify-end align-middle">
                                            <Button className="btn-icon" onClick={() => handleEditClick(material)}>
                                                <Tooltip text="Edit Material Pricing">
                                                    <PencilIcon className="text-edit size-5" />
                                                </Tooltip>
                                            </Button>
                                            <Button className="btn-icon" onClick={() => handleCopyClick(material)}>
                                                <Tooltip text="Copy Material Pricing">
                                                    <DocumentDuplicateIcon className="text-secondary-400 size-5" />
                                                </Tooltip>
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Dialogs */}
            <MaterialPricingDialog
                open={showMaterialPricingDialog}
                material={selectedMaterial}
                zone={pricing}
                onBackButtonClick={handleMaterialPricingClose}
                isCopying={selectedMaterial && selectedMaterial.id === undefined}
            />
            <Dialog
                styledTitle="Pricing Change Log"
                className="max-w-2xl overflow-auto"
                open={showPricingHistory}
                onClose={() => setShowPricingHistory(false)}
            >
                {showPricingHistory && <AuditLogTable entityID={pricing.id} entityType="Pricing" />}
                <div className="flex justify-end pt-2">
                    <Button className="btn-secondary-text-only" onClick={() => setShowPricingHistory(false)}>
                        Close
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};
