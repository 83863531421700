import { Pricing } from '@alliance-disposal/transport-types';

export const calculateCustomDiscount = (
    discountType: Pricing.CustomerDiscountType,
    originalValue: number | undefined,
    newValue: number | undefined | '',
) => {
    if (!newValue && newValue !== 0) {
        return undefined;
    }
    if (discountType === 'FIXED') {
        return newValue || newValue === 0 ? newValue : originalValue || undefined;
    }
    return (originalValue || 0) - (newValue || 0);
};
