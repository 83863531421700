import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { InternalTicket } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';

type InternalTicketContextType = {
    fetchInternalTickets: () => void;
    setEntityID: (entityID: string) => void;
    setEntityType: (entityType: InternalTicket.InternalTicketRelatedEntity) => void;
    setFilterStatuses: (status: (InternalTicket.InternalTicketStatus | 'IMAGES')[]) => void;
    setFilterTagIDs: (tagID: string[]) => void;
    setHideNoMatchFilterReplies: (hide: boolean) => void;
    setActiveTab: (tab: 'ACTIVE' | 'ARCHIVED') => void;
    handleArchive: (ticketID: string, archive: boolean) => void;
    tickets: InternalTicket.InternalTicketTransport[];
    isLoading: boolean;
    entityID: string;
    entityType: InternalTicket.InternalTicketRelatedEntity | undefined;
    ticketStatusCounts: { OPEN: number; IN_PROGRESS: number; SOLVED: number; IMAGES: number };
    filterStatuses: (InternalTicket.InternalTicketStatus | 'IMAGES')[];
    filterTagIDs: string[];
    hideNoMatchFilterReplies: boolean;
    allTagsOnTickets: { tag: InternalTicket.TagTransport; count: number }[];
    activeTab: 'ACTIVE' | 'ARCHIVED';
    showReplies: { [key: string]: boolean };
    handleShowReplies: (ticketID: string) => void;
};

const initialValue: InternalTicketContextType = {
    fetchInternalTickets: () => null,
    setEntityID: () => null,
    setEntityType: () => null,
    setFilterStatuses: () => null,
    setFilterTagIDs: () => null,
    setHideNoMatchFilterReplies: () => null,
    setActiveTab: () => null,
    handleArchive: () => null,
    handleShowReplies: () => null,
    tickets: [],
    isLoading: false,
    entityID: '',
    entityType: undefined,
    ticketStatusCounts: { OPEN: 0, IN_PROGRESS: 0, SOLVED: 0, IMAGES: 0 },
    filterStatuses: [],
    filterTagIDs: [],
    hideNoMatchFilterReplies: false,
    allTagsOnTickets: [],
    activeTab: 'ACTIVE',
    showReplies: {},
};

export const InternalTicketContext = createContext(initialValue);

export const InternalTicketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [tickets, setTickets] = useState<InternalTicket.InternalTicketTransport[]>([]);
    const [allTickets, setAllTickets] = useState<InternalTicket.InternalTicketTransport[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [entityID, setEntityID] = useState('');
    const [entityType, setEntityType] = useState<InternalTicket.InternalTicketRelatedEntity | undefined>(undefined);
    const [filterStatuses, setFilterStatuses] = useState<(InternalTicket.InternalTicketStatus | 'IMAGES')[]>([]);
    const [filterTagIDs, setFilterTagIDs] = useState<string[]>([]);
    const [hideNoMatchFilterReplies, setHideNoMatchFilterReplies] = useState(false); // if true hides replies that don't match the filter
    const [allTagsOnTickets, setAllTagsOnTickets] = useState<{ tag: InternalTicket.TagTransport; count: number }[]>([]); // all tags on tickets for this entity
    const [ticketStatusCounts, setTicketStatusCounts] = useState({ OPEN: 0, IN_PROGRESS: 0, SOLVED: 0, IMAGES: 0 });
    const [activeTab, setActiveTab] = useState<'ACTIVE' | 'ARCHIVED'>('ACTIVE');
    const [showReplies, setShowReplies] = useState<{ [key: string]: boolean }>({});

    const fetchInternalTickets = async () => {
        if (!entityID) {
            alert('Missing entity ID');
            return;
        }
        setIsLoading(true);
        const response = await client.internalTicket().adminPortal.query({ entityID });
        setTicketStatusCounts({
            OPEN: response.results.filter((ticket) => ticket.status === 'OPEN').length,
            IN_PROGRESS: response.results.filter((ticket) => ticket.status === 'IN_PROGRESS').length,
            SOLVED: response.results.filter((ticket) => ticket.status === 'SOLVED').length,
            IMAGES: response.results.filter((ticket) => ticket.images?.length > 0).length,
        });
        const tagsWithCount = response.results
            .flatMap((ticket) => ticket.tags)
            .reduce(
                (acc, tag) => {
                    const found = acc.find((t) => t.tag.id === tag.id);
                    if (found) {
                        found.count++;
                    } else {
                        acc.push({ tag, count: 1 });
                    }
                    return acc;
                },
                [] as { tag: InternalTicket.TagTransport; count: number }[],
            );
        setAllTagsOnTickets(tagsWithCount);
        setAllTickets(response.results.filter((ticket) => !ticket.replyToID)); // hold all tickets for filtering
        setIsLoading(false);
    };

    useEffect(() => {
        if (!entityID) {
            return;
        }
        setIsLoading(true);

        const sub = client.internalTicket().adminPortal.subscription.query({ entityID });

        sub.receive.subscribe({
            next: (response) => {
                setTicketStatusCounts({
                    OPEN: response.results.filter((ticket) => ticket.status === 'OPEN').length,
                    IN_PROGRESS: response.results.filter((ticket) => ticket.status === 'IN_PROGRESS').length,
                    SOLVED: response.results.filter((ticket) => ticket.status === 'SOLVED').length,
                    IMAGES: response.results.filter((ticket) => ticket.images?.length > 0).length,
                });
                const tagsWithCount = response.results
                    .flatMap((ticket) => ticket.tags)
                    .reduce(
                        (acc, tag) => {
                            const found = acc.find((t) => t.tag.id === tag.id);
                            if (found) {
                                found.count++;
                            } else {
                                acc.push({ tag, count: 1 });
                            }
                            return acc;
                        },
                        [] as { tag: InternalTicket.TagTransport; count: number }[],
                    );

                setAllTagsOnTickets(tagsWithCount);
                setAllTickets(response.results.filter((ticket) => !ticket.replyToID)); // hold all tickets for filtering
                setIsLoading(false);
            },
            error: (error) => {
                console.error(error);
                // showFlash(error.message || 'Something went wrong getting internal tickets', 'error');
            },
        });

        return () => {
            sub?.unsubscribe();
        };
    }, [entityID]);

    useEffect(() => {
        let newArray = [...allTickets];
        if (filterStatuses.length > 0) {
            newArray = newArray.filter(
                (ticket) =>
                    (ticket.status && filterStatuses.includes(ticket.status)) ||
                    (filterStatuses.includes('IMAGES') && ticket.images?.length > 0),
            );
            if (hideNoMatchFilterReplies) {
                newArray = newArray.map((ticket) => ({
                    ...ticket,
                    replies: ticket.replies.filter(
                        (reply) =>
                            (reply.status && filterStatuses.includes(reply.status)) ||
                            (filterStatuses.includes('IMAGES') && reply.images?.length > 0),
                    ),
                }));
            }
        }
        if (filterTagIDs.length > 0) {
            newArray = newArray.filter((ticket) => ticket.tags?.some((tag) => filterTagIDs.includes(tag.id)));
            if (hideNoMatchFilterReplies) {
                newArray = newArray.map((ticket) => ({
                    ...ticket,
                    replies: ticket.replies.filter((reply) => reply.tags?.some((tag) => filterTagIDs.includes(tag.id))),
                }));
            }
        }
        if (activeTab === 'ACTIVE') {
            newArray = newArray.filter((ticket) => !ticket.archivedDate);
        } else if (activeTab === 'ARCHIVED') {
            newArray = newArray.filter((ticket) => ticket.archivedDate);
        }
        setShowReplies(newArray.map((ticket) => ticket.id).reduce((acc, id) => ({ ...acc, [id]: true }), {}));
        setTickets(newArray);
    }, [filterStatuses, filterTagIDs, activeTab, allTickets]);

    const handleArchive = async (ticketID: string, archive: boolean) => {
        try {
            await client.internalTicket().adminPortal.update(ticketID, { archivedDate: archive ? new Date().toISOString() : null });
        } catch (error) {
            console.warn(error);
            setShowToast({ message: 'An error occurred archiving ticket', severity: 'error' });
        }
    };

    const handleShowReplies = (ticketID: string) => {
        if (ticketID === 'all') {
            const all = tickets.reduce(
                (acc, ticket) => {
                    acc[ticket.id] = Object.values(showReplies).every((val) => val) ? false : true;
                    return acc;
                },
                {} as { [key: string]: boolean },
            );
            setShowReplies(all);
        } else {
            setShowReplies((prev) => {
                return { ...prev, [ticketID]: !prev[ticketID] };
            });
        }
    };

    const values = useMemo(
        () => ({
            fetchInternalTickets,
            setEntityID,
            setEntityType,
            setFilterStatuses,
            setFilterTagIDs,
            setHideNoMatchFilterReplies,
            setActiveTab,
            handleArchive,
            handleShowReplies,
            entityID,
            entityType,
            tickets,
            isLoading,
            ticketStatusCounts,
            filterStatuses,
            filterTagIDs,
            hideNoMatchFilterReplies,
            allTagsOnTickets,
            activeTab,
            showReplies,
        }),
        [
            tickets,
            isLoading,
            entityID,
            entityType,
            ticketStatusCounts,
            filterStatuses,
            filterTagIDs,
            hideNoMatchFilterReplies,
            allTagsOnTickets,
            activeTab,
            showReplies,
        ],
    );

    return <InternalTicketContext.Provider value={values}>{children}</InternalTicketContext.Provider>;
};
