import { useEffect, useState } from 'react';
import { AddressTransport, Customer, ServiceLocationTransport } from '@alliance-disposal/transport-types';
import { AddressSubForm } from '@wayste/sour-components';
import { RadioGroup } from '@wayste/sour-ui';
import { formatServiceAddress } from '@wayste/utils';
import { useFormContext } from 'react-hook-form';
import GoogleStreetView from '../GoogleStreetView';

const trimmedAddressListLength = 5;

type AddressOption = {
    value: string;
    label: string;
    addressData?: Customer.AllianceCustomerTransport['serviceLocations'][0];
};

type Props = {
    fieldName: string;
    handleAddressChange: (serviceLocation: ServiceLocationTransport) => void;
    customer?: Customer.AllianceCustomerTransport;
    disabled?: boolean;
};

const ServiceAddressSubForm = ({ handleAddressChange, fieldName, customer, disabled }: Props) => {
    const [streetViewAddress, setStreetViewAddress] = useState<AddressTransport | undefined>(undefined);
    const [addressRadio, setAddressRadio] = useState<string>('new');
    const [allAddressOptions, setAllAddressOptions] = useState<AddressOption[]>([{ value: 'new', label: 'Enter address' }]); // master list to handle showing all
    const [displayAddressOptions, setDisplayAddressOptions] = useState<AddressOption[]>([{ value: 'new', label: 'Enter address' }]);

    const { watch } = useFormContext();

    const watchServiceLocationAddress = watch(`${fieldName}.address`);

    useEffect(() => {
        if (customer?.serviceLocations) {
            const newAddressOptions: AddressOption[] = [];
            const selectedAddress = formatServiceAddress(watchServiceLocationAddress);
            customer.serviceLocations.forEach((item) => {
                const addressString = formatServiceAddress(item.address);
                if (selectedAddress === addressString) {
                    setAddressRadio(addressString);
                }
                newAddressOptions.push({
                    value: addressString,
                    label: addressString,
                    addressData: item,
                });
            });
            const sortedAddresses = newAddressOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

            const allOptions = [{ value: 'new', label: 'Enter address' }, ...sortedAddresses];

            setAllAddressOptions(allOptions);
            if (sortedAddresses.length <= trimmedAddressListLength) {
                setDisplayAddressOptions(allOptions);
            } else {
                setDisplayAddressOptions(allOptions.filter((_, index) => index <= trimmedAddressListLength));
            }
        } else {
            setAllAddressOptions([]);
        }
    }, [customer?.serviceLocations]);

    const handleRadioAddressSelect = (value: string) => {
        if (value === 'new') {
            handleAddressChange({
                address: {
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: 'US',
                },
                coordinates: { lat: 0, lng: 0 },
                county: '',
            });
        } else {
            const selectedAddress = allAddressOptions.find((item) => item.value === value);
            if (selectedAddress) {
                handleAddressChange(selectedAddress.addressData as ServiceLocationTransport);
            }
        }
        setAddressRadio(value);
    };

    const handleServiceAddressLength = () => {
        if (displayAddressOptions.length === allAddressOptions.length) {
            setDisplayAddressOptions(allAddressOptions.filter((_: unknown, index: number) => index <= trimmedAddressListLength));
        } else {
            setDisplayAddressOptions(allAddressOptions);
        }
    };

    return (
        <div className="flex flex-col gap-y-6">
            <div className="flex flex-col gap-1">
                <RadioGroup
                    options={displayAddressOptions}
                    value={addressRadio}
                    onChange={handleRadioAddressSelect}
                    name=""
                    className="flex flex-col gap-1"
                />
                {allAddressOptions.length > trimmedAddressListLength + 1 && (
                    <div>
                        <button className="btn-secondary-text-only" onClick={handleServiceAddressLength} type="button">
                            Show {displayAddressOptions.length === allAddressOptions.length ? 'Fewer' : 'All'}
                            {' Addresses'}
                        </button>
                    </div>
                )}
            </div>
            {addressRadio === 'new' && (
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <AddressSubForm
                        apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
                        fieldName={fieldName}
                        type="location"
                        required
                        disabled={disabled}
                        handleSelect={(value) => {
                            handleAddressChange(value);
                            setStreetViewAddress(value.address);
                        }}
                        showCounty
                    />
                    <div>
                        <GoogleStreetView address={streetViewAddress || undefined} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ServiceAddressSubForm;
