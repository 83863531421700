import { useEffect, useState } from 'react';
import { UniversalService } from '@alliance-disposal/transport-types';
import { DataGrid, DataGridProps, Tooltip } from '@wayste/sour-ui';
import { formatServiceAddress, getGroupingServiceDateRange } from '@wayste/utils';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import UniversalServicesStatusPopper from '../components/UniversalServicesStatusPopper';

type Order = {
    serviceGrouping: UniversalService.ServiceGrouping;
    status?: string;
    serviceRange?: string;
    subscription?: string;
    serviceSKU?: string;
    address?: string;
    orderNumber?: string;
    customer?: string;
    vendor?: string;
    filler?: undefined;
    cancellationDetails?: UniversalService.CancellationDetails;
};

const getRowFromServiceGrouping = (grouping: UniversalService.ServiceGrouping) => {
    const status = grouping.serviceOrders.every((serviceOrder) => serviceOrder.status) ? grouping.serviceOrders[0].status : 'MIXED';

    const order: Order = {
        serviceGrouping: grouping,
        status: status,
        serviceRange: getGroupingServiceDateRange(grouping).string,
        subscription: grouping.type !== 'single' ? 'Subscription' : 'One Time',
        serviceSKU: [...new Set(grouping.serviceOrders.map((serviceOrder) => serviceOrder.serviceType.family.name))].join(', '),
        address: grouping.serviceOrders.every((serviceOrder) => serviceOrder.serviceLocation)
            ? formatServiceAddress(grouping.serviceOrders[0].serviceLocation?.address)
            : 'Multiple',
        orderNumber: grouping.orderNumber,
        customer: grouping.customerCompanyName || grouping.customerName,
        vendor: grouping.serviceOrders.every((serviceOrder) => !serviceOrder.vendorName)
            ? ''
            : grouping.serviceOrders.every((serviceOrder) => serviceOrder.vendorName)
              ? grouping.serviceOrders[0].vendorName
              : 'Multiple',
        cancellationDetails: grouping.serviceOrders.every((serviceOrder) => serviceOrder.cancellationDetails)
            ? grouping.serviceOrders[0].cancellationDetails
            : undefined,
    };
    return order;
};

interface UniversalServicesListTableProps {
    page: number;
    size: number;
    total: number;
    serviceGrouping: UniversalService.ServiceGrouping[];
    onRowClicked: (order: UniversalService.ServiceGrouping) => void;
    loading?: boolean;
    setPage: (page: number) => void;
    setPageSize: (size: number) => void;
}

const UniversalServicesListTable = ({
    serviceGrouping,
    onRowClicked,
    setPage,
    setPageSize,
    loading,
    page,
    size,
    total,
}: UniversalServicesListTableProps) => {
    const [data, setData] = useState<Order[]>(serviceGrouping.map((group) => getRowFromServiceGrouping(group)) ?? []);

    useEffect(() => {
        setData(serviceGrouping.map((serviceOrder) => getRowFromServiceGrouping(serviceOrder)));
    }, [serviceGrouping]);

    const columns: DataGridProps<Order>['columns'] = [
        {
            key: 'orderNumber',
            name: 'Order #',
            width: 75,
            formatter: ({ value, row }) => (
                <div className="whitespace-nowra2 flex items-center gap-1">
                    {value as string}
                    {row.serviceGrouping.autopayEnabled && (
                        <Tooltip text="Autopay Enabled">
                            <ArrowPathIcon className="inline-block size-4 align-middle" />
                        </Tooltip>
                    )}
                </div>
            ),
        },
        {
            key: 'status',
            name: 'Status',
            width: 130,
            formatter: ({ row }) => (
                <UniversalServicesStatusPopper
                    display="label"
                    serviceOrder={
                        {
                            status: row.status,
                            cancellationDetails: row.cancellationDetails,
                        } as UniversalService.ServiceOrder
                    }
                />
            ),
        },
        {
            key: 'serviceRange',
            name: 'Service Range',
            formatter: ({ value }) => <div className="whitespace-nowrap">{value as string}</div>,
        },
        {
            key: 'subscription',
            name: 'Subscription',
            formatter: ({ value }) => <div className="whitespace-nowrap">{value as string}</div>,
        },
        {
            key: 'serviceSKU',
            name: 'Service',
            formatter: ({ value }) => <div className="whitespace-nowrap">{value as string}</div>,
        },
        {
            key: 'address',
            name: 'Address',
            formatter: ({ value }) => <div className="whitespace-nowrap">{value as string}</div>,
        },
        {
            key: 'customer',
            name: 'Customer',
            formatter: ({ value }) => <div className="whitespace-nowrap">{value as string}</div>,
        },
        {
            key: 'vendor',
            name: 'Vendor',
            formatter: ({ value }) => <div className="whitespace-nowrap">{value as string}</div>,
        },
    ];

    return (
        <div className="flex h-0 w-full flex-1 grow basis-0 rounded shadow">
            <DataGrid
                rows={data}
                columns={columns}
                loading={loading}
                pagination={{
                    onRowsPerPageChange: (newPageSize) => {
                        setPageSize(newPageSize);
                    },
                    onPageChange: (newPage) => {
                        setPage(newPage);
                    },
                    page: page,
                    rowsPerPage: size,
                    rowsPerPageOptions: [25, 50, 100],
                    itemCount: total,
                }}
                onRowClick={(row) => {
                    onRowClicked(row.serviceGrouping);
                }}
            />
        </div>
    );
};

export default UniversalServicesListTable;
