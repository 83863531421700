import { useState } from 'react';
import { Pricing } from '@alliance-disposal/transport-types';
import { MapEditorSubmit } from '@wayste/sour-components';
import { Dialog } from '@wayste/sour-ui';
import AuditLogTable from '../AuditLogTable';
import Loading from '../Loading';
import ApprovePricingChangeDialog from './ApprovePricingChangeDialog';
import HaulerPricingCard from './hauler-pricing-card';

type Props = {
    isLoading: boolean;
    pricing: Pricing.PricingTransport[];
    onEditClicked: (pricing: Pricing.PricingTransport) => void;
    onDeleteClicked: (pricing: Pricing.PricingTransport) => void;
    onAddMaterialClicked: (pricing: Pricing.PricingTransport) => void;
    onEditMaterialClicked: (pricing: Pricing.PricingTransport, material: Pricing.PricingDataTransport) => void;
    onCopyMaterialClicked: (pricing: Pricing.PricingTransport, material: Pricing.PricingDataTransport) => void;
    onServiceAreaSubmit: (data: MapEditorSubmit[], zone: Pricing.PricingTransport) => void;
    onRefreshPricing: () => void;
    isActiveOnWayste: boolean;
};

const HaulerPricingList = ({
    pricing,
    isLoading,
    onEditClicked,
    onDeleteClicked,
    onAddMaterialClicked,
    onEditMaterialClicked,
    onCopyMaterialClicked,
    onServiceAreaSubmit,
    onRefreshPricing,
    isActiveOnWayste,
}: Props) => {
    const [showPricingChangesID, setShowPricingChangesID] = useState<string | null>(null);
    const [showPricingChangeDialog, setShowPricingChangeDialog] = useState<{
        zoneID: string;
        materialID: string;
    }>({ zoneID: '', materialID: '' });

    const handleViewPricingChanges = async (id: string) => {
        setShowPricingChangesID(id);
    };

    if (isLoading) return <Loading />;

    return (
        <>
            {/* Page Content */}
            {pricing.map((zone) => (
                <HaulerPricingCard
                    key={zone.id}
                    zone={zone}
                    zones={pricing}
                    onEditClicked={onEditClicked}
                    onDeleteClicked={onDeleteClicked}
                    onAddMaterialClicked={onAddMaterialClicked}
                    onEditMaterialClicked={onEditMaterialClicked}
                    onCopyMaterialClicked={onCopyMaterialClicked}
                    onServiceAreaSubmit={onServiceAreaSubmit}
                    onViewPricingChanges={handleViewPricingChanges}
                    onViewAcceptPricingChange={setShowPricingChangeDialog}
                    isActiveOnWayste={isActiveOnWayste}
                />
            ))}
            <Dialog
                styledTitle="Pricing Change Log"
                open={Boolean(showPricingChangesID)}
                className="max-w-screen-2xl"
                onClose={() => setShowPricingChangesID(null)}
                showX
            >
                {showPricingChangesID ? <AuditLogTable entityID={showPricingChangesID} entityType="Pricing" /> : null}
            </Dialog>
            <ApprovePricingChangeDialog
                open={Boolean(showPricingChangeDialog.zoneID)}
                onClose={() => setShowPricingChangeDialog({ zoneID: '', materialID: '' })}
                onSuccess={() => {
                    setShowPricingChangeDialog({ zoneID: '', materialID: '' });
                    onRefreshPricing();
                }}
                zoneID={showPricingChangeDialog.zoneID}
                materialID={showPricingChangeDialog.materialID}
            />
        </>
    );
};

export default HaulerPricingList;
