import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { TextField } from '@wayste/sour-ui';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { FullLogoWhite } from '../../assets/icons/logos';
import Loading from '../Loading';
import PasswordRequirements from './PasswordRequirements';

const Login = () => {
    const client = useWaysteClient();
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [activeView, setActiveView] = useState('login');
    const [showRequirements, setShowRequirements] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [cognitoUser, setCognitoUser] = useState<any>(null);
    const [verificationCode, setVerificationCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [awsResponse, setAWSResponse] = useState<any>(null);

    useEffect(() => {
        // Subscribe to auth loading state.
        client.auth().loading.subscribe((loading) => {
            setIsLoading(loading);
        });
    });

    const handleLoginSubmit = async (email: string, password: string) => {
        setIsLoading(true);
        try {
            const response = await client.auth().login({ email, password });
            if (response?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setAWSResponse(response);
                setActiveView('NEW_PASSWORD_REQUIRED');
                setIsLoading(false);
                return;
            }
        } catch (e) {
            setErrorMessage('Invalid username or password');
        }
        setIsLoading(false);
    };

    const handleForcePasswordResetSubmit = async () => {
        setErrorMessage('');
        setIsLoading(true);
        await client.auth().newPasswordChallenge({ newPassword: password });
        handleLoginSubmit(cognitoUser.username, newPassword);
    };

    const handlePasswordResetSubmit = async () => {
        setErrorMessage('');
        setIsLoading(true);
        const response = await client.auth().forgotPassword({ email: username });
        setCognitoUser(response);
        setActiveView('RESET_PASSWORD');
    };

    const handleNewPasswordResetSubmit = async () => {
        setErrorMessage('');
        setIsLoading(true);
        await client.auth().verifyForgotPasswordCode({
            userName: username,
            code: verificationCode,
            password: newPassword,
        });
        setActiveView('login');
    };

    if (isLoading) {
        return <Loading />;
    }

    const loginView = (
        <>
            <h1 className="my-4 text-center text-2xl">Admin Sign In</h1>
            <div className="mb-7">
                <TextField
                    label="Username"
                    inputProps={{
                        value: username,
                        onChange: (event) => setUsername(event.target.value),
                    }}
                />
            </div>
            <div className="mb-7">
                <TextField
                    label="Password"
                    inputProps={{
                        value: password,
                        onChange: (event) => setPassword(event.target.value),
                    }}
                    type="password"
                />
            </div>
            <div>
                <button
                    className="btn-primary mb-2 w-full"
                    onClick={() => handleLoginSubmit(username, password)}
                    disabled={!username || !password}
                >
                    Sign In
                </button>
                <button className="btn-primary-text-only w-full" onClick={() => setActiveView('RESET')}>
                    Reset Password
                </button>
            </div>
        </>
    );

    const forcePasswordResetView = (
        <>
            <h1 className="my-4 text-center text-2xl">New Password Required</h1>
            <p className="mb-4 text-center">Your password needs to be updated. Create a new password below.</p>
            <TextField
                label="New password"
                type="password"
                inputProps={{
                    value: newPassword,
                    onChange: (event) => setNewPassword(event.target.value),
                    autoComplete: 'new-password',
                    onFocus: () => setShowRequirements(true),
                    onBlur: () => setShowRequirements(false),
                }}
            />
            {showRequirements ? (
                <div className="mb-4 mt-2">
                    <PasswordRequirements password={newPassword} setValid={setIsValid} />
                </div>
            ) : null}
            <TextField
                label="Confirm password"
                type="password"
                error={confirmPassword !== newPassword ? { type: 'validate', message: "Passwords don't match" } : undefined}
                inputProps={{
                    value: confirmPassword,
                    onChange: (event) => setConfirmPassword(event.target.value),
                    autoComplete: 'no',
                }}
            />
            <button onClick={handleForcePasswordResetSubmit} disabled={!isValid || confirmPassword !== newPassword} color={'primary'}>
                Update Password
            </button>
        </>
    );

    const passwordResetView = (
        <>
            <h1 className="my-4 text-center text-2xl">Find your Sourgum Account</h1>
            <p className="mb-4 text-center">Enter the username associated with your account.</p>
            <div className="mb-7">
                <TextField
                    label="Username"
                    inputProps={{
                        value: username,
                        onChange: (event) => setUsername(event.target.value),
                    }}
                />
            </div>
            <button className="btn-primary" onClick={handlePasswordResetSubmit} disabled={!username} color={'primary'}>
                Next
            </button>
        </>
    );

    const newPasswordResetView = (
        <>
            <h1 className="my-4 text-center text-2xl">Reset Password</h1>
            <p className="mb-4 text-center">A verification code should have been sent to the email associated with your account.</p>
            <div className="mt-2">
                <TextField
                    label="New password"
                    type="password"
                    inputProps={{
                        value: newPassword,
                        onChange: (event) => setNewPassword(event.target.value),
                        autoComplete: 'new-password',
                        onFocus: () => setShowRequirements(true),
                        onBlur: () => setShowRequirements(false),
                    }}
                />
            </div>
            {showRequirements ? (
                <div className="mb-4 mt-2">
                    <PasswordRequirements password={newPassword} setValid={setIsValid} />
                </div>
            ) : null}
            <div className="mb-2 mt-2">
                <TextField
                    label="Confirm password"
                    type="password"
                    error={confirmPassword !== newPassword ? { type: 'validate', message: "Passwords don't match" } : undefined}
                    inputProps={{
                        value: confirmPassword,
                        onChange: (event) => setConfirmPassword(event.target.value),
                        autoComplete: 'no',
                    }}
                />
            </div>
            <div className="mb-2 mt-2">
                <TextField
                    label="Verification Code"
                    type="verification"
                    inputProps={{
                        value: verificationCode,
                        onChange: (event) => setVerificationCode(event.target.value),
                        autoComplete: 'no',
                    }}
                />
            </div>
            <button
                className="btn-primary mb-4 mt-2"
                onClick={handleNewPasswordResetSubmit}
                disabled={!isValid || confirmPassword !== newPassword || !verificationCode}
                color={'primary'}
            >
                Update Password
            </button>
        </>
    );

    const getView = () => {
        switch (activeView) {
            case 'login':
                return loginView;
            case 'RESET':
                return passwordResetView;
            case 'RESET_PASSWORD':
                return newPasswordResetView;
            case 'NEW_PASSWORD_REQUIRED':
                return forcePasswordResetView;
            default:
                return loginView;
        }
    };

    return (
        <div className="flex min-h-screen flex-col items-center justify-center bg-white">
            {errorMessage ? (
                <div className="bg-error-light text-error-dark flex items-center px-4 py-1.5">
                    <ExclamationCircleIcon className="mr-1 h-5 w-5" />
                    {errorMessage}
                </div>
            ) : null}
            <div className="w-full max-w-[300px] rounded bg-white shadow-md">
                <div className="bg-sourgum-greyblue-900 rounded-t p-2.5">
                    <div className="m-auto px-5 py-2.5 text-center">
                        <FullLogoWhite size={200} />
                    </div>
                </div>
                <div className="flex flex-col p-5 pt-0">{getView()}</div>
            </div>
        </div>
    );
};

export default Login;
