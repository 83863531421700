import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Button, TextField } from '@wayste/sour-ui';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { CreateSourceData } from '@stripe/stripe-js';
import type { Stripe } from 'stripe';
import { UIContext } from '../../contexts';

export type StripeElementProps = {
    stripeCustomer: Stripe.Customer;
    onCompletion: (status: string) => void;
};

const StripeElement = ({ stripeCustomer, onCompletion }: StripeElementProps) => {
    const client = useWaysteClient();
    const [cardholderName, setCardholderName] = useState('');

    const [cardComplete, setCardComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const { showFlash } = useContext(UIContext);

    const handleAddCard = async () => {
        if (!stripe || !elements || !stripeCustomer) {
            throw new Error('Stripe / Stripe Customer is not loaded.');
        }
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            throw new Error('Card Element is not loaded.');
        }
        try {
            setIsLoading(true);
            const tokenData: CreateSourceData = {
                type: 'card',
                usage: 'reusable',
                owner: {
                    name: cardholderName,
                },
            };

            // Create the card on Stripe
            const sourceCreated = await stripe.createSource(cardElement, tokenData);
            const sourceId = sourceCreated?.source?.id;
            if (!sourceId) {
                throw new Error('Source ID is not available.');
            }
            // If it's prepaid throw an error
            if (sourceCreated?.source?.card?.funding === 'prepaid') {
                throw new Error('Prepaid cards are not accepted.');
            }
            // Add the card to the customer
            const source = await client.stripe().adminPortal.customer.sources.create(stripeCustomer.id, { sourceId });
            if (source.id !== sourceId) {
                throw new Error('Duplicate card information, please delete the old one and try again.');
            }
            showFlash('Card Added', 'success');
            onCompletion('success');
        } catch (error) {
            console.error('Add Card Error: ', error);
            showFlash(`${error}`, 'error');
            onCompletion('error');
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <div className="flex flex-col gap-4 rounded bg-white px-3 py-2 shadow shadow-gray-300">
            <TextField
                label="Cardholder Name"
                inputProps={{
                    value: cardholderName,
                    onChange: (e) => {
                        setCardholderName(e.target.value);
                    },
                }}
            />
            <CardElement
                onChange={(event) => {
                    setCardComplete(event.complete);
                }}
            />
            <Button
                className="btn-primary mt-4"
                disabled={isLoading || !cardComplete || !cardholderName}
                onClick={handleAddCard}
                loading={isLoading}
            >
                Submit
            </Button>
        </div>
    );
};
export default StripeElement;
