import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Checkbox, Dialog, RHFRules, TextField, Textarea, Tooltip, phoneNumberMasker } from '@wayste/sour-ui';
import { formatE164ToUSPhoneNumber, formatUSPhoneNumberToE164 } from '@wayste/utils';
import { Controller, useForm } from 'react-hook-form';

type Props = {
    open: boolean;
    onClose: () => void;
    onSubmitted: () => void;
    haulerId: string;
    onDelete?: (contact: Hauler.HaulerSapContactTransport) => void;
    contact?: Hauler.HaulerSapContactTransport;
    allContacts?: Hauler.HaulerSapContactTransport[];
};

type FormProps = {
    firstName: string;
    lastName: string;
    email: string;
    notes: string;
    phoneNumber: string;
    primaryContact: boolean;
    sendDispatchEmails: boolean;
    sendBillingEmails: boolean;
    sendRFPEmails: boolean;
    sendMarketingEmails: boolean;
};

const HaulerContactDialog = ({ open, onClose, contact, haulerId, onSubmitted, onDelete, allContacts }: Props) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [isLoading, setIsLoading] = useState(false);
    const [meetsMinimumContactRequirements, setMeetsMinimumContactRequirements] = useState({ billing: 0, dispatch: 0 });

    const { control, trigger, reset, handleSubmit } = useForm<FormProps>();

    useEffect(() => {
        if (open && contact) {
            reset({
                firstName: contact.firstName,
                lastName: contact.lastName,
                email: contact.email,
                phoneNumber: contact.phoneNumber,
                sendDispatchEmails: contact.sendDispatchEmails,
                sendBillingEmails: contact.sendBillingEmails,
                sendRFPEmails: contact.sendRFPEmails,
                notes: contact.notes,
                sendMarketingEmails: contact.sendMarketingEmails,
                primaryContact: contact.primaryContact,
            });
        } else {
            reset({
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                sendDispatchEmails: false,
                sendBillingEmails: false,
                sendRFPEmails: false,
                sendMarketingEmails: false,
                primaryContact: false,
                notes: '',
            });
        }
    }, [open, contact]);

    useEffect(() => {
        if (open && allContacts) {
            setMeetsMinimumContactRequirements({
                billing: allContacts.filter((c) => c.sendBillingEmails).length,
                dispatch: allContacts.filter((c) => c.sendDispatchEmails).length,
            });
        }
    }, [allContacts, open]);

    const onSubmit = async (data: FormProps) => {
        setIsLoading(true);
        try {
            if (contact) {
                await client.vendorService().adminPortal.contacts.update(contact.id, data);
            } else {
                await client.vendorService().adminPortal.contacts.create({
                    ...data,
                    haulerId,
                });
            }
            onSubmitted();
        } catch (error) {
            console.error(error);
            setShowToast({
                severity: 'error',
                message: 'There was an error saving the contact',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const onError = () => {
        setShowToast({
            severity: 'error',
            message: 'Please make sure all fields are valid',
        });
    };

    return (
        <Dialog open={open} onClose={() => (isLoading ? null : onClose())} variant="skinny-pullout" dialogBodyClassName="overflow-auto">
            <div className="flex h-full flex-col gap-4 px-4">
                <div className="border-b py-2 text-left text-xl font-semibold leading-normal tracking-wide text-zinc-700">
                    {contact ? 'Update' : 'Add'} contact
                </div>
                <form className="flex flex-1 flex-col gap-y-4" onSubmit={handleSubmit(onSubmit, onError)}>
                    <Controller
                        name="firstName"
                        control={control}
                        rules={{ required: 'First name is required' }}
                        render={({ field, fieldState }) => (
                            <TextField label="First Name" error={fieldState.error} inputProps={{ ...field }} required />
                        )}
                    />
                    <Controller
                        name="lastName"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField label="Last Name" error={fieldState.error} inputProps={{ ...field }} />
                        )}
                    />
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            ...RHFRules.email,
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="Email"
                                error={fieldState.error}
                                inputProps={{
                                    ...field,
                                    autoComplete: 'no',
                                    onBlur: () => {
                                        field.onBlur();
                                        trigger('phoneNumber');
                                    },
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{
                            ...RHFRules.phoneNumber,
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                startAdornment="+1"
                                type={'string'}
                                label="Phone number"
                                error={fieldState.error}
                                inputProps={{
                                    ...field,
                                    value: formatE164ToUSPhoneNumber(field.value),
                                    onChange: (e) => {
                                        field.onChange(formatUSPhoneNumberToE164(phoneNumberMasker(e.target.value)));
                                    },
                                    onBlur: () => {
                                        field.onBlur();
                                        trigger('email');
                                    },
                                    autoComplete: 'no',
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="notes"
                        control={control}
                        render={({ field }) => (
                            <Textarea
                                label="Notes"
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="primaryContact"
                        control={control}
                        render={({ field }) => (
                            <Checkbox
                                label="Primary Contact"
                                inputProps={{
                                    checked: field.value,
                                    onChange: (value) => field.onChange(value),
                                }}
                            />
                        )}
                    />
                    <div className="px-4 pt-2 text-lg leading-7 tracking-tight text-gray-900">Receive Notifications</div>
                    <Controller
                        name="sendDispatchEmails"
                        control={control}
                        render={({ field }) => (
                            <Checkbox
                                label="Dispatch Notifications"
                                inputProps={{
                                    checked: field.value,
                                    onChange: (value) => field.onChange(value),
                                    disabled:
                                        contact &&
                                        contact.sendDispatchEmails &&
                                        meetsMinimumContactRequirements.dispatch <= 1 &&
                                        field.value,
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="sendBillingEmails"
                        control={control}
                        render={({ field }) => (
                            <Checkbox
                                label="Billing Notifications"
                                inputProps={{
                                    checked: field.value,
                                    onChange: (value) => field.onChange(value),
                                    disabled:
                                        contact && contact.sendBillingEmails && meetsMinimumContactRequirements.billing <= 1 && field.value,
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="sendRFPEmails"
                        control={control}
                        render={({ field }) => (
                            <Checkbox
                                label="RFP Notifications"
                                inputProps={{
                                    checked: field.value,
                                    onChange: (value) => field.onChange(value),
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="sendMarketingEmails"
                        control={control}
                        render={({ field }) => (
                            <Checkbox
                                label="Marketing Notifications"
                                inputProps={{
                                    checked: field.value,
                                    onChange: (value) => field.onChange(value),
                                }}
                            />
                        )}
                    />
                    <div className="mt-auto flex flex-col gap-y-4 py-6">
                        <Button className="btn-primary px-5 py-2.5 text-base" type="submit" loading={isLoading}>
                            Save
                        </Button>
                        <Button className="btn-dark-grey-outlined w-full px-5 py-2.5 text-base" onClick={onClose} disabled={isLoading}>
                            Cancel
                        </Button>
                        {contact && !contact.deletedAt && onDelete && (
                            <div className="flex justify-center">
                                <Tooltip
                                    text={
                                        contact.primaryContact &&
                                        "You cannot delete the primary contact. Make another contact the primary first if you'd like to delete this one"
                                    }
                                >
                                    <Button
                                        className="btn-error-text-only"
                                        onClick={() => onDelete(contact)}
                                        disabled={contact.primaryContact}
                                    >
                                        Delete
                                    </Button>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </Dialog>
    );
};

export default HaulerContactDialog;
