import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { useHotkeys } from 'react-hotkeys-hook';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../contexts';
import { routes } from '../utils';
// import MobileToolbar from './MobileToolbar';
// import ReleaseNotes from './ReleaseNotes';
import Routes from './Routes';
import DesktopTopNavBar from './desktop-toolbar/desktop-navbar';
import DesktopToolbar from './desktop-toolbar/desktop-toolbar';

const Layout = ({ onSignOut }: { onSignOut: () => void }) => {
    const { isDesktop } = useContext(UIContext);
    const client = useWaysteClient();
    const history = useHistory();
    const [toolBarOpen, setToolBarOpen] = useState(isDesktop);
    const [isAppAdmin, setIsAppAdmin] = useState(false);

    // Start of hotkeys //////////////////////////////////////////
    useHotkeys('mod+k', () => history.push(routes.search));
    useHotkeys('mod+\\', () => setToolBarOpen(!toolBarOpen));
    // End of hotkeys //////////////////////////////////////////

    useEffect(() => {
        const sub = client.auth().appAdmin.subscribe((admin) => setIsAppAdmin(Boolean(admin)));
        return () => sub.unsubscribe();
    }, []);

    // const [releaseNotesOpen, setReleaseNotesOpen] = useState(false);

    // const handleReleaseNotesClose = () => {
    //   setReleaseNotesOpen(false);
    //   // Untested as release notes are never opened.
    //   client.user().updateCurrentUserProfile({ lastReleaseNotes: pjson.version });
    // };

    return (
        <div className="flex max-h-screen min-h-screen flex-grow flex-col overflow-auto">
            <DesktopTopNavBar
                onLogout={onSignOut}
                toolBarOpen={toolBarOpen}
                setToolBarOpen={() => setToolBarOpen(!toolBarOpen)}
                isDesktop={isDesktop}
                navigateToSearch={() => history.push(routes.search)}
            />
            <div className="relative flex flex-1 flex-grow flex-row overflow-auto">
                <DesktopToolbar desktopToolBarOpen={toolBarOpen} isDesktop={isDesktop} toggleOpen={() => setToolBarOpen(!toolBarOpen)} />
                <main className="flex flex-1 flex-grow flex-col overflow-auto">
                    <Routes isAppAdmin={isAppAdmin} />
                </main>
            </div>
        </div>
    );
};

export default Layout;
