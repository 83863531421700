import { useHistory } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import routes from '../../utils/routes';
import ExpandingBlock, { classItemBlock } from './components/expanding-block';

/**
 * Side navigation bar on desktop ( > 1024px) displays
 */
const DesktopToolbar = ({
    desktopToolBarOpen: forceToolbarOpen,
    isDesktop,
    toggleOpen,
}: {
    isDesktop: boolean;
    desktopToolBarOpen: boolean;
    toggleOpen?: () => void;
}) => {
    const desktopToolBarOpen = forceToolbarOpen;
    const history = useHistory();

    const navigateSideEffect = () => {
        if (!isDesktop) {
            toggleOpen?.();
        }
    };

    return (
        <nav
            className={`${
                desktopToolBarOpen ? 'w-full md:w-[10.25rem]' : 'w-0 md:mr-4 md:w-2'
            } group/nav relative flex flex-none flex-col overflow-visible transition-all duration-200 ease-in`}
        >
            {/* collapse button */}
            <div
                className={twMerge(
                    'absolute bottom-0 right-0 top-0 hidden bg-slate-200 transition-all duration-200 ease-in md:block',
                    desktopToolBarOpen ? 'ml-2 w-0' : '-right-2 w-0.5',
                )}
            >
                <div
                    className={twMerge(
                        'hover:bg-primary-400 group absolute right-1/2 top-20 z-50 hidden size-6 translate-x-1/2 cursor-pointer items-center justify-center rounded-full bg-white shadow-[0_0_0_1px_rgba(9,30,66,0.08),0_2px_4px_1px_rgba(9,30,66,0.08)] transition-all duration-200 ease-in-out hover:size-7 group-hover/nav:opacity-100 md:flex',
                        forceToolbarOpen ? '-rotate-90 md:opacity-0' : 'rotate-90',
                    )}
                    onClick={toggleOpen}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-6 transform text-zinc-700 transition-all duration-200 ease-in-out hover:scale-110 group-hover:text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                    </svg>
                </div>
            </div>

            <div
                className={`w-full flex-1 flex-col gap-y-8 overflow-hidden overflow-y-auto whitespace-nowrap bg-[#EFF4F7] py-8 ${
                    desktopToolBarOpen ? 'flex' : 'hidden'
                }`}
            >
                <div
                    className={classItemBlock}
                    onClick={() => {
                        navigateSideEffect();
                        history.push(routes.leads.create, { modal: true });
                    }}
                >
                    Create Lead
                </div>

                <ExpandingBlock
                    initiallyExpanded={true}
                    onNavigate={navigateSideEffect}
                    heading="operations"
                    routeList={[
                        {
                            text: 'Sales Funnel',
                            path: routes.leads.list,
                        },
                        {
                            text: 'Work Flow',
                            path: routes.internalTickets.list,
                        },
                        {
                            text: 'Billing',
                            path: routes.billing.list,
                        },
                        {
                            text: 'Roll-off Orders',
                            path: routes.orders.list,
                        },
                        {
                            text: 'Site Service Orders',
                            path: routes.universalServices.list,
                        },
                        {
                            text: 'AM Project Board',
                            path: routes.accountManagerViews.projectBoard,
                        },
                    ]}
                />

                <ExpandingBlock
                    initiallyExpanded={true}
                    heading="vendor relations"
                    routeList={[
                        {
                            text: 'Vendors',
                            path: routes.haulers.list,
                        },
                        {
                            text: 'Pricing',
                            path: routes.pricing.list,
                        },
                        {
                            text: 'RFPs',
                            path: routes.requestForProposal.list,
                        },
                    ]}
                />

                <div className={classItemBlock} onClick={() => history.push(routes.reports)}>
                    Reports
                </div>
            </div>
        </nav>
    );
};

export default DesktopToolbar;
