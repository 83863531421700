import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { InternalTicket } from '@alliance-disposal/transport-types';
import { Button, Dialog } from '@wayste/sour-ui';
import { UIContext } from '../../../../contexts';

type DeleteDialogProps = {
    open: boolean;
    onClose: () => void;
    tag?: InternalTicket.TagTransport;
    onSuccess: () => void;
};

const DeleteDialog = ({ open, onClose, tag, onSuccess }: DeleteDialogProps) => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const [isLoading, setIsLoading] = useState(false);

    if (!tag) return;

    const handleDelete = async (tagID: string) => {
        setIsLoading(true);
        try {
            await client.internalTicket().adminPortal.tag.delete(tagID);
            onSuccess();
            showFlash('Tag deleted successfully', 'success');
        } catch (error) {
            console.warn('Tag delete error: ', error);
            showFlash('Error deleting tag', 'error');
        }
        onClose();
        setIsLoading(false);
    };

    return (
        <Dialog open={open} onClose={onClose} styledTitle="Delete Tag" variant="delete">
            <p>
                Are you sure that you want to delete the tag <b>{tag.name}</b>?
            </p>
            <p>Deleting this tag won't remove it from existing tickets, but it will not be able to be added to new tickets.</p>
            <div className="mt-3 flex items-center justify-end gap-4 border-t border-t-gray-200 pt-3">
                <Button className="btn-dark-grey-outlined" onClick={() => onClose()} loading={isLoading}>
                    Cancel
                </Button>
                <Button className="btn-delete" onClick={() => handleDelete(tag.id)} loading={isLoading}>
                    Delete
                </Button>
            </div>
        </Dialog>
    );
};

export default DeleteDialog;
