import React, { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Notification } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, DatePicker, TextField, Textarea, TimePicker } from '@wayste/sour-ui';
import { startOfToday, startOfTomorrow } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';

const MAX_GSM_LENGTH = 160; // Maximum length for a single SMS in GSM-7 encoding
const MAX_UCS2_LENGTH = 70; // Maximum length for a single SMS in UCS-2 encoding

interface TextBlastProps {
    // Define your component props here
}

type TextBlastForm = {
    phoneNumbers: string;
    message: string;
    sendAfter: Date;
    confirmationText: string;
};

const TextBlast: React.FC<TextBlastProps> = () => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
    const [percentCompleted, setPercentCompleted] = useState(0);
    const { handleSubmit, control, watch, formState } = useForm<TextBlastForm>({
        defaultValues: {
            phoneNumbers: '',
            message: '',
            sendAfter: startOfTomorrow(),
            confirmationText: '',
        },
        mode: 'all',
    });

    // Helper function to check if the message contains non-GSM characters
    const isGsm7 = (message: string) => {
        const gsm7Pattern = /^[\u0020-\u007E\u00A1-\u00BF\u00C0-\u00FF\u20AC]+$/;
        return gsm7Pattern.test(message);
    };

    const validateMessageLength = (message: string) => {
        const maxLength = isGsm7(message) ? MAX_GSM_LENGTH : MAX_UCS2_LENGTH;
        return (
            message.length <= maxLength ||
            `Message exceeds ${maxLength} characters limit and will be split into multiple messages by Twilio.`
        );
    };

    const onSubmit = async (data: TextBlastForm) => {
        try {
            const phoneNumbers = [...new Set(data.phoneNumbers.split(','))];

            const payloads: Notification.ScheduleMessage[] = phoneNumbers.map((phoneNumber) => ({
                phoneNumber,
                message: data.message,
                sendAfter: data.sendAfter.toISOString(),
                body: data.message,
                destination: phoneNumber,
                handler: 'sms',
                topic: 'text-blast',
            }));

            // batch in groups of 100

            while (payloads.length > 0) {
                const batch = payloads.splice(0, 100);
                await Promise.all(batch.map((payload) => client.notification().adminPortal.createScheduledNotification(payload)));
                setPercentCompleted(((phoneNumbers.length - payloads.length) / phoneNumbers.length) * 100);
            }

            setHasBeenSubmitted(true);
            setShowToast({
                message: 'Message scheduled successfully. It will be sent at the specified time.',
                severity: 'success',
            });
        } catch (error) {
            setShowToast({
                message: (error as any).message || 'An error occurred. Please try again.',
                severity: 'error',
            });
        }
    };

    // Add your component logic here

    return (
        <div className="my-8 flex size-full flex-col px-8">
            <div className="mb-2 flex items-center justify-between">
                <h1 className="mb-1 text-2xl font-semibold">Text Blast</h1>
            </div>
            <hr />

            <div className="mt-4 flex flex-col gap-4">
                <Controller
                    control={control}
                    name="phoneNumbers"
                    rules={{
                        required: 'Phone numbers are required',
                        pattern: {
                            value: /^\+1\d{10}(,\+1\d{10})*$/,
                            message: 'Invalid phone numbers',
                        },
                    }}
                    render={({ field, fieldState }) => (
                        <Textarea
                            {...field}
                            placeholder="Enter phone numbers in format +11231231234,+11231231234,..."
                            error={fieldState.error}
                            inputProps={{
                                ...field,
                                onPaste: (e) => {
                                    // remove all new lines and replace with commas
                                    const clipboardData = e.clipboardData;

                                    const pastedData = clipboardData.getData('Text');

                                    e.preventDefault();

                                    if (pastedData.includes(',')) {
                                        field.onChange(pastedData.replaceAll(' ', '').replaceAll('\n', ''));
                                        return false;
                                    }

                                    field.onChange(pastedData.replaceAll('\n', ','));

                                    return false;
                                },
                                onChange: (e) => {
                                    // remove whitespace and newlines
                                    field.onChange(e.target.value.replaceAll(' ', '').replaceAll('\n', ''));
                                },
                            }}
                            helperText={`Enter phone numbers separated by "," (${watch('phoneNumbers') ? watch('phoneNumbers').split(',').length : 0} Recipients)`}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="message"
                    rules={{
                        required: 'Message is required',
                    }}
                    render={({ field, fieldState }) => (
                        <Textarea
                            {...field}
                            placeholder="Enter message"
                            error={fieldState.error}
                            inputProps={{
                                ...field,
                            }}
                            helperText={
                                validateMessageLength(field.value) !== true
                                    ? validateMessageLength(field.value).toString() // Convert the boolean value to a string
                                    : `${field.value.length} / ${isGsm7(field.value) ? MAX_GSM_LENGTH : MAX_UCS2_LENGTH} characters`
                            }
                        />
                    )}
                />

                <hr />

                <div className="grid grid-cols-2 gap-2">
                    <Controller
                        control={control}
                        name="sendAfter"
                        render={({ field }) => (
                            <DatePicker
                                label="Send After"
                                minDate={startOfToday()}
                                onChange={(date) => {
                                    field.onChange(date);
                                }}
                                value={field.value}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="sendAfter"
                        render={({ field }) => (
                            <TimePicker
                                label="Time"
                                helperText=""
                                onChange={(date) => {
                                    if (date === 'error') return;
                                    field.onChange(date);
                                }}
                                value={field.value}
                            />
                        )}
                    />
                    <p className="col-span-2 text-xs text-gray-500">
                        All message will be sent only after the specified time. Time shown is in your local timezone (i.e if you are in New
                        York, it will show EST time).
                    </p>
                </div>

                <hr />
                {/* Confirmation Text Field */}

                <p className="col-span-2 text-xs text-gray-500">
                    Enter <code className="rounded-md bg-gray-200 p-1 font-mono text-sm">CONFIRMED</code> to send the message.
                </p>

                <Controller
                    control={control}
                    name="confirmationText"
                    rules={{
                        required: 'Confirmation text is required',
                        pattern: {
                            value: /^CONFIRMED$/,
                            message: 'Confirmation text must be CONFIRMED',
                        },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            placeholder="Enter confirmation text"
                            error={fieldState.error}
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />

                <Button
                    loading={formState.isSubmitting}
                    className="btn-primary"
                    onClick={handleSubmit(onSubmit)}
                    disabled={hasBeenSubmitted || !formState.isValid}
                >
                    {formState.isSubmitting
                        ? `Sending... (${percentCompleted}% Complete)`
                        : hasBeenSubmitted
                          ? 'Message Sent'
                          : 'Send Message'}
                </Button>
            </div>
        </div>
    );
};

export default TextBlast;
