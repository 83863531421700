import React, { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';

const QuickBooksCallback = () => {
    const client = useWaysteClient();
    const location = useLocation();
    const [showError, setShowError] = useState(false);

    const handleComplete = () => {
        const baseURL = import.meta.env.DEV ? 'http://localhost:3000' : 'https://admin.sourgum.com/';
        window.opener.postMessage('quickbooks-connection-successful', baseURL);
    };

    const handleValidateConnection = async (searchParams: string) => {
        try {
            const response = await client.quickBooks().quickBooksCallback(searchParams);
            console.log(response);
            if (response?.status === 200) {
                handleComplete();
            } else {
                setShowError(true);
            }
        } catch (error) {
            console.warn('error: ', error);
            setShowError(true);
        }
    };

    useEffect(() => {
        console.log(location.search);
        if (location.search) {
            handleValidateConnection(location.search);
        } else {
            setShowError(true);
        }
    }, []);

    if (showError) {
        return (
            <div className="fixed bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center gap-4 bg-white p-6 text-center">
                <h1 className="text-3xl">An Error Occurred Please Close this Window and Try Again</h1>
                <button className="btn-primary text-lg" onClick={() => window.close()}>
                    Close
                </button>
            </div>
        );
    }

    return (
        <div className="fixed bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center gap-4 bg-white p-6 text-center">
            <CheckCircleIcon className="text-success h-20 w-20" />
            <h1 className="text-4xl">Successfully Connected to QuickBooks</h1>
            <p className="text-xl">You may now close this window</p>
            <button className="btn-primary text-lg" onClick={() => window.close()}>
                Go Back
            </button>
        </div>
    );
};

export default QuickBooksCallback;
