import { useContext } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Button, Dialog } from '@wayste/sour-ui';
import { isAxiosError } from 'axios';
import { UIContext } from '../../contexts';

export const RedactTicketDialog = ({ ticketID, onClose }: { ticketID?: string; onClose: () => void }) => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);

    const handleRedact = async () => {
        if (!ticketID) return;
        try {
            await client.internalTicket().adminPortal.delete(ticketID);
            onClose();
        } catch (error) {
            console.warn('Redact ticket error: ', error);
            if (isAxiosError(error)) {
                showFlash(error.response?.data.message, 'error');
            } else {
                showFlash('An error occurred', 'error');
            }
        }
    };

    return (
        <Dialog open={Boolean(ticketID)} onClose={onClose} styledTitle="Redact Ticket" variant="delete">
            <p>Are you sure that you want to redact this ticket?</p>
            <div className="mt-4 flex justify-end gap-4">
                <Button onClick={onClose} className="btn-dark-grey-outlined">
                    Cancel
                </Button>
                <Button onClick={handleRedact} className="btn-delete">
                    Delete
                </Button>
            </div>
        </Dialog>
    );
};
