import { useEffect, useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { formatE164ToUSPhoneNumber, formatServiceAddress } from '@wayste/utils';
import { PencilIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { commercialLocationTypes, leadReasonsLost, leadTypesEnums } from '../../utils';
import CardInfoList from '../CardInfoList';
import LeadUpdate from '../LeadUpdate';
import ImageDialogButton from './ImageDialogButton';
import InternalNotes from './InternalNotes';
import StatusChangeButton from './StatusChangeButton';

export interface LeadUSDetailsProps {
    rawLead: Customer.AllianceLeadTransport;
}

const LeadUSDetails = ({ rawLead }: LeadUSDetailsProps) => {
    const [lead, setLead] = useState<Customer.AllianceLeadTransport>(rawLead);
    const [showUpdateLead, setShowUpdateLead] = useState(false);

    useEffect(() => {
        if (!rawLead) return;
        setLead(rawLead);
    }, [rawLead]);

    //////////////////////////////////////////////
    // RENDER SECTION
    //////////////////////////////////////////////

    const leadColOne = [
        { label: 'Name', value: `${lead.firstName || ''} ${lead.lastName || ''}` },
        { label: 'Company', value: lead.company },
        { label: 'Email', value: lead.email },
        {
            label: 'Phone',
            value: lead.phoneNumber ? formatE164ToUSPhoneNumber(lead.phoneNumber) : null,
        },
    ];

    const leadColTwo = [
        {
            label: 'Location',
            value: lead?.serviceLocation?.address ? formatServiceAddress(lead?.serviceLocation?.address) : null,
        },
        { label: 'County', value: lead?.serviceLocation?.county },
        {
            label: 'Location Type',
            value: commercialLocationTypes[lead.locationType as keyof typeof commercialLocationTypes],
        },
        { label: 'Current Hauler', value: lead.currentHauler || '' },
    ];

    const leadColThree = [
        {
            label: 'Lead Type',
            value: leadTypesEnums[lead.type],
        },
        {
            label: 'Start Date',
            value: lead.requestedStartDate ? format(new Date(lead.requestedStartDate), 'MM/dd/yy') : '',
        },
        {
            label: 'Follow Up Date',
            value: lead.followUpDate ? format(new Date(lead.followUpDate), 'MM/dd/yy') : '',
        },
        { label: 'Cus Notes', value: lead.customerNotes },
        {
            label: 'Reason Lost',
            value: lead.reasonLost ? leadReasonsLost[lead.reasonLost as keyof typeof leadReasonsLost] : '',
        },
    ];

    return (
        <div className="w-full">
            <div className="shadow-dark rounded bg-white px-4 py-1.5">
                <div className="flex items-center justify-between">
                    <div className="text-xl">Lead Details</div>
                    <div className="flex gap-4">
                        <ImageDialogButton lead={lead} />
                        <StatusChangeButton lead={lead} />
                        <Button
                            className="btn-primary"
                            onClick={() => setShowUpdateLead(true)}
                            startIcon={<PencilIcon className="size-4" />}
                        >
                            Edit
                        </Button>
                    </div>
                </div>
                <hr className="-mx-4 my-2" />
                <div className={`grid grid-cols-1 gap-4 lg:grid-cols-3`}>
                    <CardInfoList data={leadColOne} containerSize={4} border />
                    <CardInfoList data={leadColTwo} containerSize={4} border />
                    <CardInfoList data={leadColThree} containerSize={4} />
                    <div className="flex border-t pt-4 lg:col-span-3">
                        <div className="mr-5 text-gray-500">Notes</div>
                        <div>
                            <InternalNotes notesInternal={lead.notesInternal} />
                        </div>
                    </div>
                </div>
            </div>
            {/* Dialogs */}
            <LeadUpdate open={showUpdateLead} onClose={() => setShowUpdateLead(false)} lead={lead} />
        </div>
    );
};

export default LeadUSDetails;
