import React, { useContext, useState } from 'react';
import { Button, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { removeContactFromList } from '../../axios/sendgrid';
import { UIContext } from '../../contexts';

const SendGridRemoveFromList = () => {
    const [email, setEmail] = useState('');
    const [listId, setListId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { showFlash } = useContext(UIContext);

    const availableLists = [
        { name: 'Commercial Prospecting', id: 'ff677a2f-ef8a-485a-a8a0-ce1259400a30' },
        { name: 'Rolloff Prospecting', id: 'de5973eb-afa5-444c-8561-ccce5145cc39' },
    ];

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const response = await removeContactFromList(email, listId);
            console.log('response: ', response);
            if (response?.data?.status === 'error') {
                alert('Error Removing Contact, message: ' + response?.data?.message);
            } else {
                showFlash('Contact Successfully Removed', 'success');
                setEmail('');
                setListId('');
            }
        } catch (error) {
            console.log('error: ', error);
            showFlash('Error Removing Contact', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-3">
            <TextField
                label="Email"
                required
                inputProps={{
                    value: email,
                    onChange: (e) => setEmail(e.target.value),
                }}
            />
            <Select label="SendGrid List" required onSelect={(value) => setListId(value)} value={listId}>
                {availableLists.map((list) => (
                    <SelectOption key={list.id} value={list.id}>
                        {list.name}
                    </SelectOption>
                ))}
            </Select>
            <Button loading={isLoading} disabled={!email || !listId} onClick={handleSubmit} className="btn-primary">
                Submit
            </Button>
        </div>
    );
};

export default SendGridRemoveFromList;
