import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { useSourContext } from '@wayste/sour-context';
import { Button, Dialog, TextField } from '@wayste/sour-ui';
import { Controller, useForm } from 'react-hook-form';
import { BillingContext } from '../../context';

interface RejectInvoiceFormProps {
    haulerNote: string;
    internalNote: string;
}

interface RejectInvoiceModalProps {
    open: boolean;
    onClose: (submitted: boolean) => void;
    payableID: string;
}

const RejectInvoiceModal = ({ open, onClose, payableID }: RejectInvoiceModalProps) => {
    const client = useWaysteClient();
    const { selectedOrder } = useContext(BillingContext);
    const { setShowToast } = useSourContext();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: RejectInvoiceFormProps) => {
        if (!payableID || !selectedOrder) return;
        setIsLoading(true);
        try {
            await client.invoice().adminPortal.payable.review(payableID, {
                action: 'REJECT',
                noteForHauler: data.haulerNote,
                noteForVendorRelations: data.internalNote,
            });
            onClose(true);
        } catch (error) {
            console.error(error);
            setShowToast({ severity: 'error', message: 'Failed to reject payable' });
        } finally {
            setIsLoading(false);
        }
    };

    const {
        control,
        handleSubmit,
        formState: { isDirty, isValid },
        reset,
    } = useForm<RejectInvoiceFormProps>({
        mode: 'all',
        defaultValues: {
            haulerNote: '',
            internalNote: '',
        },
    });

    useEffect(() => {
        reset({
            haulerNote: '',
            internalNote: '',
        });
    }, [payableID, selectedOrder]);

    return (
        <Dialog open={open} onClose={() => onClose(false)} styledTitle="Reject Invoice" disableCloseOnClickOutside>
            <form className="flex flex-col gap-4 overflow-y-auto pt-2.5" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="internalNote"
                    control={control}
                    rules={{
                        required: 'An internal note is required',
                    }}
                    render={({ field }) => (
                        <TextField
                            label="Note for vendor relations"
                            required
                            inputProps={{ ...field }}
                            helperText="Why are you rejecting this invoice?"
                        />
                    )}
                />
                <Controller
                    name="haulerNote"
                    control={control}
                    render={({ field }) => <TextField label="Note for hauler" inputProps={{ ...field }} />}
                />
                <div className="flex justify-end gap-4 pt-4">
                    <Button className="btn-dark-grey-outlined" onClick={() => onClose(false)} type="button">
                        Cancel
                    </Button>
                    <Button className="btn-primary" type="submit" disabled={!isValid || !isDirty} loading={isLoading}>
                        Submit Rejection
                    </Button>
                </div>
            </form>
        </Dialog>
    );
};

export default RejectInvoiceModal;
