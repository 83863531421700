import axios from 'axios';

interface SEND_EMAIL_SIMPLE {
    toAddress: string;
    htmlMessage: string;
    textMessage: string;
    subject: string;
    sender: string;
    ccAddresses?: string[];
    replyToAddress?: string;
    bcc?: string;
}

export interface SEND_VENDOR_PAYOUT_SUMMARY {
    toAddress: string;
    htmlMessage: string;
    textMessage?: string;
    subject: string;
    sender?: string;
    fileName: string;
    attachmentBase64: string;
    ccAddresses?: string[];
}

/**
 * @param HAULER_DELIVERY_CONFIRMATION - email (email address), address (service address), date (delivery date), instructions (delivery instructions), size (dumpster size)
 */
export const endPoints = {
    // Data - email, address, date, instructions, size
    HAULER_DELIVERY_CONFIRMATION: 'alliance-admin-hauler-delivery-confirmation',
    SEND_EMAIL_SIMPLE: 'send-email',
};

/**
 * Triggers AWS API Gateway to trigger Lambda function to send preformated email via SES
 * @param {string} endPoint - an AWS end point to trigger API Gateway. Select from endPoints
 * @param {*} data - Data to match that in endPoint
 */
export const sendEmail = async (endPoint: 'send-email' | 'alliance-admin-hauler-delivery-confirmation', data: any | SEND_EMAIL_SIMPLE) => {
    const url = import.meta.env.VITE_AWS_SES_URL + endPoint;
    try {
        const res = await axios({
            method: 'POST',
            url: url,
            data: JSON.stringify(data),
        });
        if (res.status !== 200) {
            console.warn('sendEmail !== 200 status error: ', res);
            alert('There was an error sending the email');
        }
    } catch (error) {
        console.warn('axios sendEmail Error: ', error);
        alert('There was an error sending the email');
    }
};

export const sendVendorDailyPayoutSummary = async (data: SEND_VENDOR_PAYOUT_SUMMARY) => {
    const url = import.meta.env.VITE_AWS_SES_URL + 'send-email';
    try {
        const res = await axios({
            method: 'POST',
            url: url,
            data: JSON.stringify(data),
        });
        if (res.status !== 200) {
            console.warn('sendEmail !== 200 status error: ', res);
            alert('There was an error sending the email');
        }
    } catch (error) {
        console.warn('axios sendEmail Error: ', error);
        alert('There was an error sending the email');
    }
};
