import React from 'react';
import { Order } from '@alliance-disposal/transport-types';
import { Disclosure, Transition } from '@headlessui/react';
import { ArrowTopRightOnSquareIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { routes } from '../../utils';
import { timeAgo } from './ChangeRequestResponse';

const ChangeTableRow = ({ request }: { request: Order.ChangeRequestTransport }) => {
    const history = useHistory();

    const minutesAgo = Math.floor((new Date().getTime() - new Date(request.metadata.createdAt).getTime()) / 60000);

    const fieldNameMap: Record<Order.ChangeTransport['field'], string> = {
        expectedDeliveryDate: 'Delivery Date',
        expectedPickupDate: 'Pickup Date',
        sharedPickupNotes: 'Pickup Notes',
        sharedDeliveryNotes: 'Delivery Notes',
        expectedSize: 'Size',
        material: 'Material',
        status: 'Status',
    };

    function renderChangeValues(change: Order.ChangeTransport) {
        if (change.field === 'expectedDeliveryDate' || change.field === 'expectedPickupDate') {
            return (
                <>
                    <td>{change.oldValue ? format(new Date(change.oldValue?.replace(/-/g, '/')), 'MM/dd/yyyy') : 'None'}</td>
                    <td>{format(new Date(change.newValue?.replace(/-/g, '/')), 'MM/dd/yyyy')}</td>
                </>
            );
        }

        return (
            <>
                <td>{change.oldValue}</td>
                <td>{change.newValue}</td>
            </>
        );
    }

    return (
        <Disclosure>
            {({ open }) => (
                <>
                    <tr key={request.id} className="border-b [&>*]:px-4 [&>*]:py-1.5">
                        <td>
                            <Disclosure.Button className="btn-icon" aria-label="expand row">
                                <ChevronDownIcon
                                    className={`h-6 w-6 transform transition-transform duration-200 ease-out ${open ? 'rotate-180' : ''}`}
                                />
                            </Disclosure.Button>
                        </td>
                        <td>{new Intl.RelativeTimeFormat('en').format(-minutesAgo, 'minute')}</td>
                        <td>Order Change</td>
                        <td>{request.allianceOrder?.orderNumber || 'N/A'}</td>
                        <td>
                            {request.allianceOrder?.serviceLocation.address.addressLine1}
                            {request.allianceOrder?.serviceLocation.address.city}, {request.allianceOrder?.serviceLocation.address.state},{' '}
                            {request.allianceOrder?.serviceLocation.address.zip} ({request.allianceOrder?.serviceLocation.county})
                        </td>
                        <td>
                            <button
                                className="btn-icon"
                                onClick={() => {
                                    request.allianceOrder
                                        ? history.push(routes.orders.details(request.allianceOrder.id))
                                        : console.log('No order');
                                }}
                            >
                                <ArrowTopRightOnSquareIcon className="h-6 w-6" />
                            </button>
                        </td>
                        {/* Collapsible section that shows all changes */}
                    </tr>
                    <tr key={request.id + '_COLLAPSE'}>
                        <td colSpan={7} className="py-0">
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                            >
                                <Disclosure.Panel>
                                    <table className="tex-sm w-full border-collapse border-spacing-0 bg-slate-50">
                                        <thead>
                                            <tr className="border-b [&>*]:px-4 [&>*]:py-1.5">
                                                <th />
                                                <th>Change</th>
                                                <th>Current</th>
                                                <th>Requested</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {request.changes.map((change) => (
                                                <tr key={change.id} className="border-b [&>*]:px-4 [&>*]:py-1.5">
                                                    <td />
                                                    <td>{fieldNameMap[change.field]}</td>
                                                    {renderChangeValues(change)}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Disclosure.Panel>
                            </Transition>
                        </td>
                    </tr>
                </>
            )}
        </Disclosure>
    );
};

type ChangeTableRowProps = {
    requests: Order.ChangeRequestTransport[];
};

const ChangeRequestTable = ({ requests }: ChangeTableRowProps) => {
    const history = useHistory();

    return (
        <div className="w-full overflow-x-auto">
            <table className="w-full border-collapse border-spacing-0 text-sm">
                <thead>
                    <tr className="border-b [&>*]:sticky [&>*]:top-0 [&>*]:z-[2] [&>*]:bg-white [&>*]:px-4 [&>*]:py-1.5 [&>*]:text-left [&>*]:font-normal">
                        <th />
                        <th>Requested</th>
                        <th>Type</th>
                        <th>Order Number</th>
                        <th>Service Address</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {requests.length === 0 ? (
                        <tr className="border-b [&>*]:px-4 [&>*]:py-1.5">
                            <td className="text-center" colSpan={6}>
                                Nothing to see here! 👏🏼
                            </td>
                        </tr>
                    ) : (
                        requests.map((request) =>
                            request.changes.some((c) => c.field === 'status') ? (
                                /* Pickup Request */
                                <tr key={request.id} className="border-b [&>*]:px-4 [&>*]:py-1.5">
                                    <td />
                                    <td>{timeAgo(new Date(request.metadata.createdAt))}</td>
                                    <td>Pick Up Request</td>
                                    <td>{request.allianceOrder?.orderNumber || ''}</td>
                                    <td>
                                        {request.allianceOrder?.serviceLocation.address.addressLine1}
                                        {request.allianceOrder?.serviceLocation.address.city},{' '}
                                        {request.allianceOrder?.serviceLocation.address.state},{' '}
                                        {request.allianceOrder?.serviceLocation.address.zip} (
                                        {request.allianceOrder?.serviceLocation.county})
                                    </td>
                                    <td>
                                        <button
                                            className="btn-icon"
                                            onClick={() => {
                                                request.allianceOrder
                                                    ? history.push(routes.orders.details(request.allianceOrder.id))
                                                    : console.log('No order');
                                            }}
                                        >
                                            <ArrowTopRightOnSquareIcon className="h-6 w-6" />
                                        </button>
                                    </td>
                                </tr>
                            ) : (
                                /* Change Request */
                                <ChangeTableRow request={request} key={request.id + '_PARENT'} />
                            ),
                        )
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ChangeRequestTable;
