import { Customer } from '@alliance-disposal/transport-types/';
import { Select, SelectOption, TextField } from '@wayste/sour-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { accountExecutives, channelTypes, channelTypesEnums } from '../../utils/shared-types';

const MarketingSubForm = () => {
    const { setValue, control, trigger } = useFormContext();

    const handleChannelChange = (value: string) => {
        let formUrl: string | undefined = undefined;
        if (value === channelTypesEnums.yelp) {
            formUrl = '?utm_source=YelpAds&utm_campaign=yelpads';
        }
        if (value === channelTypesEnums.thumbtack) {
            formUrl = '?utm_source=Thumbtack&utm_campaign=thumbtack';
        }
        if (value === channelTypesEnums.angiLeads) {
            formUrl = '?utm_source=AngiLeads&utm_campaign=angileads';
        }
        if (value === channelTypesEnums.craftJack) {
            formUrl = '?utm_source=CraftJack&utm_campaign=craftjack';
        }
        setValue('channel', value);
        trigger('phoneNumber');
        trigger('email');
        if (formUrl) setValue('formUrl', formUrl);
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
                <Controller
                    name="channel"
                    control={control}
                    defaultValue={''}
                    render={({ field, fieldState }) => (
                        <Select
                            label="Marketing Channel"
                            onSelect={(value) => handleChannelChange(value)}
                            value={field.value}
                            error={fieldState.error}
                        >
                            {Object.entries(channelTypes).map((item, index) => (
                                <SelectOption key={`channel-${item[0]}-${index}`} value={item[0]}>
                                    {item[1]}
                                </SelectOption>
                            ))}
                        </Select>
                    )}
                />
                <Controller
                    name="customerType"
                    control={control}
                    defaultValue={''}
                    render={({ field, fieldState }) => (
                        <Select
                            label="Customer Type"
                            onSelect={(value) => setValue('customerType', value)}
                            value={field.value || ''}
                            error={fieldState.error}
                        >
                            {Customer.customerTypes.map((item) => (
                                <SelectOption key={`customerType-${item}`} value={item}>
                                    {Customer.CustomerTypeLabels[item]}
                                </SelectOption>
                            ))}
                        </Select>
                    )}
                />
                <div className="lg:col-span-2">
                    <Controller
                        name="searchParams"
                        control={control}
                        defaultValue={''}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="Search Params"
                                inputProps={{
                                    ...field,
                                }}
                                error={fieldState.error}
                            />
                        )}
                    />
                </div>
            </div>
            <Controller
                name="formUrl"
                control={control}
                defaultValue={''}
                render={({ field, fieldState }) => (
                    <TextField
                        label="Form Url"
                        inputProps={{
                            ...field,
                        }}
                        error={fieldState.error}
                    />
                )}
            />
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
                <Controller
                    name="accountRepID"
                    control={control}
                    render={({ field, fieldState }) => (
                        <Select
                            error={fieldState.error}
                            label={'Account Executive'}
                            onSelect={(value) => {
                                field.onChange(value);
                                if (value) {
                                    const profile = accountExecutives.find((profile) => profile.id === value);
                                    if (profile) {
                                        setValue('accountRepName', profile.firstName + ' ' + profile.lastName);
                                    }
                                } else {
                                    setValue('accountRepName', '');
                                }
                            }}
                            value={field.value}
                            renderLabel={(value) => {
                                // if the value is blank, and not dirty, then return null
                                if (!value && !fieldState.isTouched) return null;

                                const profile = accountExecutives.find((profile) => profile.id === value);
                                return profile ? profile.firstName + ' ' + profile.lastName : 'None';
                            }}
                        >
                            <SelectOption key={'accountRepID-blank'} value={''}>
                                None
                            </SelectOption>
                            {accountExecutives.map((profile) => (
                                <SelectOption key={'accountRepID' + profile.id} value={profile.id}>
                                    {profile.firstName + ' ' + profile.lastName}
                                </SelectOption>
                            ))}
                        </Select>
                    )}
                />
            </div>
        </div>
    );
};

export default MarketingSubForm;
