import { Button, Dialog } from '@wayste/sour-ui';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useHistory, useParams } from 'react-router-dom';
import HaulerDetails from '../../components/HaulerDetails';

export interface HaulerDetailsProps {
    isModal?: boolean;
}

const VendorDetailsPage = ({ isModal }: HaulerDetailsProps) => {
    const history = useHistory();
    const { id }: { id: string } = useParams();

    const handleBackButtonClick = () => {
        history.goBack();
    };

    if (isModal)
        return (
            <Dialog open={true} onClose={handleBackButtonClick} styledTitle="Vendor Details" className="bg-[#F9F9F9]" fullScreen>
                <div className="container mx-auto">
                    <HaulerDetails id={id} withHaulerPricingDetails />
                </div>
            </Dialog>
        );

    return (
        <div className="flex flex-1 flex-col gap-y-6 bg-[#F9F9F9]">
            <div className="bg-main-sidebar flex items-center gap-4 px-5 pb-4 pt-8">
                <Button className="btn-dark-grey-outlined" onClick={handleBackButtonClick} startIcon={<ChevronLeftIcon />}>
                    Back
                </Button>
                <h6 className="text-2xl font-semibold leading-normal tracking-wide text-zinc-700">Vendor Details</h6>
            </div>
            <div className="px-5 pb-12">
                <HaulerDetails id={id} withHaulerPricingDetails />
            </div>
        </div>
    );
};

export default VendorDetailsPage;
