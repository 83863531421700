import { useEffect, useState } from 'react';
import { Button, Menu, MenuItem } from '@wayste/sour-ui';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

type Props = {
    menuItems: { label: string; value: string | number | undefined }[];
    onSelect: (value: any) => void;
    value?: string | number;
    wrapperClass?: string;
    btnClassName?: string;
    label?: string;
    noneOption?: boolean;
    disabled?: boolean;
};

const ButtonSelect = ({ label, value, menuItems, onSelect, noneOption, wrapperClass, btnClassName, disabled }: Props) => {
    const [btnLabel, setBtnLabel] = useState(label);

    useEffect(() => {
        if (value || !label) {
            setBtnLabel(menuItems.find((item) => item.value === value)?.label);
        } else {
            setBtnLabel(label);
        }
    }, [value, label, menuItems]);

    const handleMenuItemClick = (value: string | number | undefined) => {
        onSelect(value);
    };

    return (
        <div className={wrapperClass}>
            <Menu
                button={
                    <Button
                        className={`btn-primary-outlined relative whitespace-nowrap ${btnClassName}`}
                        disabled={Boolean(disabled)}
                        endIcon={<ChevronDownIcon className="size-5" />}
                    >
                        {btnLabel}
                    </Button>
                }
            >
                {(noneOption ? [{ label: 'None', value: '' }, ...menuItems] : menuItems).map((item) => (
                    <MenuItem key={item.value}>
                        <span
                            className={`flex items-center pr-5 ${item.value === value ? 'pl-0' : 'pl-5'}`}
                            onClick={() => handleMenuItemClick(item.value === value && noneOption ? '' : item.value)}
                        >
                            {item.value === value ? <CheckIcon className="text-sourgum-green-400 mr-2 size-3.5" /> : null}
                            {item.label}
                        </span>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default ButtonSelect;
