import { useEffect } from 'react';
import { Hauler, Pricing } from '@alliance-disposal/transport-types';
import { Button, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { Controller, useForm } from 'react-hook-form';
import { CreateUniversalPricingFormProps } from '../HaulerPricingCreate';

export interface HaulerPricingFormProps {
    hauler: Hauler.HaulerWithAapTransport;
    rollOffPricingZones?: Pricing.PricingTransport[];
    pricing?: Pricing.ProductPricingZoneTransport;
    onSubmit: (data: CreateUniversalPricingFormProps) => void;
    onCancel: (data: { status: string | number }) => void;
    isLoading: boolean;
}

export interface FormProps {
    state: string;
    zoneName: string;
    notes: string;
    copyRollOffZoneID?: string;
}

export const HaulerPricingFormUniversal = ({
    hauler,
    rollOffPricingZones,
    pricing,
    onSubmit,
    onCancel,
    isLoading,
}: HaulerPricingFormProps) => {
    // FORM STATE
    const { handleSubmit, reset, control, watch, setValue } = useForm<FormProps>({
        defaultValues: {
            state: '',
            zoneName: '',
            notes: '',
            copyRollOffZoneID: '',
        },
    });
    const copyRollOffZoneID = watch('copyRollOffZoneID');
    /////////////////////////////////////////////
    // COMPONENT FUNCTIONS
    /////////////////////////////////////////////

    const handleFormSubmit = async (data: FormProps) => {
        const newData: CreateUniversalPricingFormProps = {
            copyRollOffZoneID: data.copyRollOffZoneID,
            state: data.state as Pricing.UsStateAbbreviation,
            haulerID: hauler.id,
            haulerName: hauler.name,
            zoneName: data.zoneName,
            notes: data.notes,
            id: pricing?.id,
        };

        onSubmit(newData);
    };

    useEffect(() => {
        if (pricing) {
            reset({
                zoneName: pricing.zoneName ? pricing.zoneName : '',
                state: pricing.state,
                notes: pricing.notes ? pricing.notes : '',
                copyRollOffZoneID: '',
            });
        }
    }, [pricing]);

    ////////////////////////////////////////////
    // RENDER SECTION
    ////////////////////////////////////////////

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="space-y-4">
                <div className="w-full">
                    <Controller
                        name="zoneName"
                        control={control}
                        render={({ field }) => (
                            <TextField label="Zone name" required inputProps={{ ...field, disabled: !!copyRollOffZoneID }} />
                        )}
                    />
                </div>

                <div className="w-full md:w-1/2">
                    <Controller
                        name="state"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label="State"
                                required
                                onSelect={(value) => {
                                    field.onChange(value);
                                }}
                                value={field.value}
                                disabled={!!copyRollOffZoneID}
                            >
                                {Pricing.USStateAbbreviations.map((item) => (
                                    <SelectOption key={`state-${item}`} value={item}>
                                        {item}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                </div>

                <div className="w-full">
                    <h2 className="mb-4 pl-2 text-xl">Zone Pricing</h2>
                </div>

                <div className="w-full">
                    <Controller
                        name="notes"
                        control={control}
                        render={({ field }) => <TextField label="Notes" inputProps={{ ...field }} />}
                    />
                </div>
            </div>
            <div>
                {rollOffPricingZones && rollOffPricingZones.length > 0 && (
                    <>
                        <div className="my-4 flex items-center justify-center">
                            <div className="flex-grow border-t border-gray-300"></div>
                            <span className="mx-4 text-sm text-gray-600">OR</span>
                            <div className="flex-grow border-t border-gray-300"></div>
                        </div>
                        <Controller
                            control={control}
                            name="copyRollOffZoneID"
                            render={({ field }) => (
                                <Select
                                    label="Copy roll off zone map"
                                    value={field.value}
                                    onSelect={(value) => {
                                        field.onChange(value ?? '');
                                        const zone = rollOffPricingZones?.find((item) => item.id === value);
                                        setValue('zoneName', zone?.zoneName ?? '');
                                        setValue('state', zone?.state ?? '');
                                        setValue('notes', zone?.notes ?? '');
                                    }}
                                    renderLabel={(value) => {
                                        return rollOffPricingZones?.find((item) => item.id === value)?.zoneName ?? '';
                                    }}
                                >
                                    {rollOffPricingZones
                                        ?.filter((item) => item.serviceAreas.length > 0)
                                        .map((item) => (
                                            <SelectOption key={`zone-${item.zoneName}`} value={item.id}>
                                                {item.zoneName}
                                            </SelectOption>
                                        ))}
                                </Select>
                            )}
                        />
                    </>
                )}
            </div>
            <div className="flex justify-end gap-4 pt-4">
                <Button
                    className="btn-dark-grey-outlined"
                    onClick={() => onCancel({ status: 'cancel' })}
                    type="button"
                    disabled={isLoading}
                >
                    cancel
                </Button>

                <Button className="btn-primary" type="submit" loading={isLoading}>
                    {pricing ? 'Update' : 'Create'}
                </Button>
            </div>
        </form>
    );
};
