import { useContext, useState } from 'react';
import { useSourContext } from '@wayste/sour-context';
import { ButtonGroup, Menu, MenuItem } from '@wayste/sour-ui';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import PayablesMakePayment from '../../components/PayablesMakePayment';
import PayablesReview from '../../components/PayablesReview';
import { useAuthToken } from '../../hooks/authhook';
import { routes } from '../../utils';
import BillingListPayables from './components/BillingListPayables';
import BillingListReceivables from './components/BillingListReceivables';
import BillingListRolloff from './components/BillingListRolloff';
import BillingListUniversal from './components/BillingListUniversal';
import BulkInvoiceModal from './components/BulkInvoiceModal';
import InvoicesDetails from './components/InvoiceDetails';
import InvoiceSummaryModal from './components/InvoiceSummaryModal';
import PayoutSummaryModal from './components/PayoutSummaryModal';
import RemittanceSummaryModal from './components/RemittanceSummaryModal';
import ServiceGroupingInvoiceList from './components/ServiceGroupingInvoiceList';
import { BillingContext, BillingProvider } from './context';

const BillingPageContent = () => {
    const { token } = useAuthToken();
    const history = useHistory();
    const { showInvoiceDrawer, setListView, listView, showSubscriptionInvoicesDrawer } = useContext(BillingContext);
    const { setShowToast } = useSourContext();
    const [openBulkInvoice, setOpenBulkInvoice] = useState(false);
    const [openInvoiceSummary, setOpenInvoiceSummary] = useState(false);
    const [showPayablesPay, setShowPayablesPay] = useState(false);
    const [showPayablesReview, setShowPayablesReview] = useState(false);
    const [showPayoutSummary, setShowPayoutSummary] = useState(false);
    const [showRemittanceSummary, setShowRemittanceSummary] = useState(false);

    const handleGetDelInvoices = () => {
        axios
            .get(`${import.meta.env.VITE_BASE_API_URL}/reports/invoice/delinquent?output=CSV`, {
                headers: {
                    Authorization: token,
                },
                responseType: 'blob', // important
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'delinquent-invoices.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((e: Error) => {
                setShowToast({
                    severity: 'error',
                    message: `Something went wrong. ${e.message}`,
                });
            });
        setShowToast({
            severity: 'info',
            message: 'This may take a minute, you can keep using SAP in the meantime.',
        });
    };

    const getView = () => {
        switch (listView) {
            case 'roll-off':
                return <BillingListRolloff />;
            case 'universal-service':
                return <BillingListUniversal />;
            case 'receivables':
                return <BillingListReceivables />;
            case 'payables':
                return <BillingListPayables />;
            default:
                return <div>Missing List View</div>;
        }
    };

    return (
        <div className="shadow-dark m-2 flex flex-1 rounded bg-white">
            <BulkInvoiceModal open={openBulkInvoice} handleClose={() => setOpenBulkInvoice(false)} />
            <InvoiceSummaryModal open={openInvoiceSummary} handleClose={() => setOpenInvoiceSummary(false)} />
            <PayoutSummaryModal open={showPayoutSummary} onClose={() => setShowPayoutSummary(false)} />
            <RemittanceSummaryModal open={showRemittanceSummary} onClose={() => setShowRemittanceSummary(false)} />
            <PayablesReview open={showPayablesReview} onClose={() => setShowPayablesReview(false)} />
            <PayablesMakePayment open={showPayablesPay} onClose={() => setShowPayablesPay(false)} />
            <div className="container mx-auto flex w-full flex-1 flex-col p-2">
                <div className="flex items-end justify-between pb-4">
                    <h1 className="text-2xl">Billing</h1>
                    <div className="flex items-center gap-6">
                        <ButtonGroup
                            activeBtn={listView}
                            onClick={(value) => {
                                setListView(value as 'roll-off' | 'universal-service' | 'receivables' | 'payables');
                                history.push({ pathname: routes.billing.list });
                            }}
                            buttons={[
                                {
                                    label: 'Rolloff',
                                    value: 'roll-off',
                                },
                                {
                                    label: 'Universal Service',
                                    value: 'universal-service',
                                },
                                {
                                    label: 'Receivables',
                                    value: 'receivables',
                                },
                                {
                                    label: 'Payables',
                                    value: 'payables',
                                },
                            ]}
                        />
                        <Menu className="btn-icon size-6 !p-0" button={<EllipsisHorizontalIcon className="size-6" />}>
                            <MenuItem onClick={handleGetDelInvoices}>Delinquent Invoices Report</MenuItem>
                            <MenuItem onClick={() => setOpenBulkInvoice(true)}>Bulk Rolloff Invoices Download</MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setOpenInvoiceSummary(true);
                                }}
                            >
                                Outstanding Invoices Summary
                            </MenuItem>
                            <MenuItem onClick={() => setShowPayoutSummary(true)}>Vendor Payout Summary</MenuItem>
                            <MenuItem onClick={() => setShowRemittanceSummary(true)}>Customer Remittance Summary</MenuItem>
                            <MenuItem onClick={() => setShowPayablesReview(true)}>Review All Bills</MenuItem>
                            <MenuItem onClick={() => setShowPayablesPay(true)}>Pay All Bills</MenuItem>
                        </Menu>
                    </div>
                </div>
                {(listView === 'receivables' || listView === 'payables') && (
                    <div className="italic text-red-500">This view does not currently update when other people make changes.</div>
                )}
                {getView()}
            </div>
            {/* Overlay */}
            <div
                className={`fixed inset-0 z-40 bg-black opacity-50 ${
                    showInvoiceDrawer || showSubscriptionInvoicesDrawer ? 'block' : 'hidden'
                }`}
                onClick={() => {
                    history.push({ pathname: routes.billing.list });
                }}
            />
            {/* Drawers */}
            <div
                className={`fixed right-0 top-0 z-50 h-full w-11/12 transform bg-slate-50 transition-transform ${
                    showSubscriptionInvoicesDrawer ? 'translate-x-0' : 'translate-x-full'
                } overflow-auto`}
            >
                <ServiceGroupingInvoiceList />
            </div>
            <div
                className={`fixed right-0 top-0 z-50 h-full w-11/12 transform bg-slate-50 transition-transform ${
                    showInvoiceDrawer ? 'translate-x-0' : 'translate-x-full'
                } overflow-auto`}
            >
                <InvoicesDetails />
            </div>
        </div>
    );
};

export const BillingPage = () => (
    <BillingProvider>
        <BillingPageContent />
    </BillingProvider>
);
