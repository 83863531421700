import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { differenceInBusinessDays, endOfYesterday, getDay, lastDayOfMonth, previousFriday, startOfToday, startOfYesterday } from 'date-fns';

const Dashboard = () => {
    const client = useWaysteClient();
    const [yesterdayOrders, setYesterdayOrders] = useState<number>(0);
    const [isMonSunSat, setIsMonSunSat] = useState(false);
    const [todaysOrders, setTodaysOrders] = useState<number>(0);
    const [lastOrderNumber, setLastOrderNumber] = useState<number>(0);

    const currentGoal = (() => {
        const today = new Date();
        const goals = [1, 2, 28352 + 1083, 29288 + 1315, 30604 + 1460, 1_625, 7, 8, 9, 10, 11, 12];

        return goals[today.getMonth()];
    })();

    const handleGetYesterdayOrders = async () => {
        const isNotWeekDay = getDay(new Date()) === 0 || getDay(new Date()) === 6 || getDay(new Date()) === 1;
        setIsMonSunSat(isNotWeekDay);
        const startDate = new Date(
            isNotWeekDay ? previousFriday(new Date(new Date().setHours(0, 0, 0, 0))) : startOfYesterday(),
        ).toISOString();
        const endDate = new Date(isNotWeekDay && getDay(new Date()) !== 1 ? new Date() : endOfYesterday()).toISOString();

        const yesterdayOrdersArray = await client.order().adminPortal.query({
            createdBefore: endDate,
            createdAfter: startDate,
        });

        setYesterdayOrders(yesterdayOrdersArray?.results?.length);
        const todayStartDate = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
        const todayEndDate = new Date(new Date().setHours(24, 0, 0, 0)).toISOString();
        const todaysOrdersArray = await client.order().adminPortal.query({ createdBefore: todayEndDate, createdAfter: todayStartDate });
        setTodaysOrders(todaysOrdersArray?.results?.length);
    };

    const handleGetLastOrder = async () => {
        const response = await client.order().adminPortal.query({
            sortBy: 'orderNumber',
            sortDescending: true,
            limit: 1,
        });

        setLastOrderNumber(response?.results[0]?.orderNumber || 0);
    };

    useEffect(() => {
        handleGetLastOrder();
        handleGetYesterdayOrders();
    }, []);

    const rows: { heading: string; value: number; headingSize: string }[] = [
        {
            heading: 'Total Trees Planted',
            value: lastOrderNumber,
            headingSize: 'text-5xl',
        },
        {
            heading: 'This Months Goal',
            value: currentGoal,
            headingSize: 'text-4xl',
        },
        {
            heading: 'Remainder',
            value: Math.ceil(currentGoal - lastOrderNumber),
            headingSize: 'text-4xl',
        },
        {
            heading: 'Avg Orders Needed Per Day',
            value: Math.ceil(
                (currentGoal - lastOrderNumber + (todaysOrders || 0)) /
                    differenceInBusinessDays(lastDayOfMonth(new Date()), startOfToday()),
            ),
            headingSize: 'text-4xl',
        },
        {
            heading: 'Business Days Remaining',
            value: differenceInBusinessDays(lastDayOfMonth(new Date()), startOfToday()),
            headingSize: 'text-4xl',
        },
        {
            heading: 'Orders Placed Today',
            value: todaysOrders,
            headingSize: 'text-5xl',
        },
        {
            heading: `Orders Placed ${isMonSunSat ? 'Fri - Sun' : 'Yesterday'}`,
            value: yesterdayOrders,
            headingSize: 'text-5xl',
        },
    ];

    return (
        <div className="mx-auto flex w-full max-w-screen-xl flex-1 flex-col items-center justify-center px-6 py-3">
            <div className="w-full">
                <table className="w-full">
                    <tbody>
                        {rows.map((row) => (
                            <tr key={row.heading}>
                                <td className={`p-4 font-light ${row.headingSize}`}>{row.heading}</td>
                                <td className={`p-4 text-right ${row.headingSize}`}>{row.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Dashboard;
