import React, { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Checkbox, Dialog, Loading } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { groupBy } from 'lodash';
import { routes } from '../../utils';
import type { FEObject } from './PayablesMakePayment';

type PayableBreakdownDialogProps = {
    open: boolean;
    onClose: () => void;
    feObject: FEObject | null;
    onCheckboxClick: (id: string, checked: boolean) => void;
    onSelectDeselectAllClick: (action: 'selectAll' | 'deselectAll') => void;
};

const PayableBreakdownDialog = ({ open, onClose, feObject, onCheckboxClick, onSelectDeselectAllClick }: PayableBreakdownDialogProps) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [loading, setLoading] = useState(false);
    const [groupedReceivablesByOrder, setGroupedReceivablesByOrder] = useState<{
        [orderID: string]: Invoice.ReceivableTransport[];
    }>({});

    const loadReceivable = async (orderIDs: string[], serviceOrderIDs: string[]) => {
        if (orderIDs.length === 0 && serviceOrderIDs.length === 0) return;

        setLoading(true);
        try {
            const receivablesFromRolloffOrders =
                orderIDs.length > 0
                    ? (
                          await client.invoice().adminPortal.receivable.query({
                              orderID: orderIDs.join(','),
                              void: false,
                              limit: 200,
                          })
                      ).results
                    : [];

            const receivablesFromServiceOrders =
                serviceOrderIDs.length > 0
                    ? (
                          await client.invoice().adminPortal.receivable.query({
                              receivableWithLineItemWithServiceOrderID: serviceOrderIDs.join(','),
                              void: false,
                              limit: 200,
                          })
                      ).results
                    : [];

            const groupedForRollOff: { [orderID: string]: Invoice.ReceivableTransport[] } = groupBy(
                receivablesFromRolloffOrders,
                'invoiceDetails.orderID',
            );

            const groupedForServiceOrders = receivablesFromServiceOrders.reduce(
                (acc, receivable) => {
                    receivable.invoiceDetails.lineItems?.forEach((lineItem) => {
                        const serviceOrderID = lineItem.serviceOrderID;
                        if (serviceOrderID) {
                            if (!acc[serviceOrderID]) {
                                acc[serviceOrderID] = [];
                            }
                            acc[serviceOrderID].push(receivable);
                        }
                    });
                    return acc;
                },
                {} as { [serviceOrderID: string]: Invoice.ReceivableTransport[] },
            );

            setGroupedReceivablesByOrder({ ...groupedForRollOff, ...groupedForServiceOrders });

            setShowToast({
                message: 'Receivables loaded successfully',
                severity: 'success',
            });
        } catch (error) {
            setShowToast({
                message: 'Error loading receivables',
                severity: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (feObject) {
            const orderIDs = feObject.payables.reduce((acc: string[], curr) => {
                if (curr.invoiceDetails.orderID) {
                    return [...acc, curr.invoiceDetails.orderID];
                }
                return acc;
            }, []);

            const serviceOrderIDs = feObject.payables.reduce((acc: string[], curr) => {
                if (curr.invoiceDetails.serviceOrderID) {
                    return [...acc, curr.invoiceDetails.serviceOrderID];
                }
                return acc;
            }, []);

            loadReceivable(orderIDs, serviceOrderIDs);
        }
    }, [feObject]);

    const onOrderNumberClick = (orderID?: string) => {
        if (!orderID) return;
        window.open(window.location.origin + routes.billing.details(orderID), '_blank');
    };

    const handleSelectDeselectAllClick = () => {
        if (feObject?.payables?.length === feObject?.feOnlyPayablesBeingPaid?.length) {
            onSelectDeselectAllClick('deselectAll');
        } else {
            onSelectDeselectAllClick('selectAll');
        }
    };

    const getReceivablePaymentStatus = (payable: Invoice.PayableTransport) => {
        // Get the appropriate ID (either orderID or serviceOrderID)
        const id = payable.invoiceDetails.orderID || payable.invoiceDetails.serviceOrderID;
        if (!id) return '';

        const receivables = groupedReceivablesByOrder[id];

        if (!receivables) return 'no id';

        const amountPaid = Math.round(receivables.reduce((acc, curr) => acc + curr.invoiceDetails.remainingBalance, 0));
        const totalDue = Math.round(receivables.reduce((acc, curr) => acc + curr.invoiceDetails.total, 0));

        if (totalDue === 0) return 'No Invoices';

        if (receivables.every((receivable) => receivable.invoiceDetails.paidInFull)) {
            return (
                <div className="flex items-center gap-2">
                    <CheckCircleIcon className="text-success size-6" />
                    <span>Fully Paid</span>
                </div>
            );
        }

        return `${moneyFormatter(totalDue - amountPaid)} / ${moneyFormatter(totalDue)}`;
    };

    return (
        <Dialog open={open} onClose={onClose} showX styledTitle={feObject?.vendorName} variant="underlined-header" className="max-w-4xl">
            <button className="btn-secondary-text-only" type="button" onClick={handleSelectDeselectAllClick}>
                {feObject?.payables?.length === feObject?.feOnlyPayablesBeingPaid?.length ? 'Deselect All' : 'Select All'}
            </button>
            <div className="grid grid-cols-7 gap-4">
                <div>Pay</div>
                <div>Date Created</div>
                <div>Inv Number</div>
                <div>Amount</div>
                <div>SAP Order</div>
                <div className="col-span-2">Receivable Payment</div>
                {feObject?.payables
                    .sort((a, b) => ((a.invoiceDetails.metadata?.createdAt || 0) > (b.invoiceDetails.metadata?.createdAt || 0) ? 1 : -1))
                    .map((payable) => (
                        <React.Fragment key={payable.id}>
                            <div>
                                <Checkbox
                                    inputProps={{
                                        checked: Boolean(feObject.feOnlyPayablesBeingPaid.find((item) => item.id === payable.id)),
                                        onChange: (e) => onCheckboxClick(payable.id, e.target.checked),
                                    }}
                                />
                            </div>
                            <div>
                                {payable.invoiceDetails.metadata?.createdAt
                                    ? format(new Date(payable.invoiceDetails.metadata.createdAt), 'MM/dd/yy')
                                    : null}
                            </div>
                            <div>{payable.invoiceDetails.invoiceNumber}</div>
                            <div>{moneyFormatter(payable.invoiceDetails.remainingBalance)}</div>
                            <div
                                className={payable.invoiceDetails.orderID ? 'text-primary-400 cursor-pointer underline' : ''}
                                onClick={
                                    payable.invoiceDetails.orderID ? () => onOrderNumberClick(payable.invoiceDetails.orderID) : undefined
                                }
                            >
                                {payable.invoiceDetails.orderID
                                    ? `RO ${payable.invoiceDetails.orderNumber ?? ''}`
                                    : `US ${payable.invoiceDetails.orderNumber || ''}`}
                            </div>
                            <div className="col-span-2">{loading ? <Loading /> : getReceivablePaymentStatus(payable)}</div>
                        </React.Fragment>
                    ))}
            </div>
        </Dialog>
    );
};

export default PayableBreakdownDialog;
