import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, ServiceLocationTransport, UniversalService } from '@alliance-disposal/transport-types';
import { AddressSubForm } from '@wayste/sour-components';
import { Button, Checkbox, Select, SelectOption } from '@wayste/sour-ui';
import { Controller, FormProvider, get, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CustomerForm2Fields } from '../../../components/CustomerForm/CustomerForm2';
import ServiceAddressSubForm from '../../../components/ServiceAddressSubForm';
import ChargeCard2 from '../../../components/card-payment/ChargeCard2';
import { UIContext } from '../../../contexts';
import { routes } from '../../../utils';
import { paymentMethods, paymentMethodsEnums, paymentTerms, paymentTermsEnums } from '../../../utils/shared-types';
import AutoPaySelection from '../components/AutoPaySelection';
import OneTimeServiceOrderSubForm from '../components/OneTimeServiceOrderSubForm';
import SubscriptionDetailsSubForm from '../components/SubscriptionDetailsSubForm';
import SubscriptionServiceOrderAddSubForm from '../components/SubscriptionServiceOrderAddSubForm';
import {
    defaultServiceGrouping,
    defaultServiceOrderWithSubscriptionDetails,
    defaultServiceSubscriptionDetails,
} from '../components/site-service-defaults';
import SelectCustomerSubForm from './SelectCustomerSubForm';
import TotalTable from './TotalTable';
import { customerStartData } from './service-create-form-props';
import { createServiceGrouping } from './submit-create-site-service';

const orderTypes = {
    oneTime: 'One Time',
    subscription: 'Subscription',
};

export type UniversalServiceFormData = {
    customer: Customer.AllianceCustomerTransport;
    serviceGrouping: UniversalService.ServiceGroupingRecursiveCreate;
    subscriptionDetails: UniversalService.SubscriptionDetailsCreate;
    prorate: boolean;
    quantity: number[]; // Matched to serviceOrders
    serviceLocation: ServiceLocationTransport;
    overrideCustomerBillingAddress: boolean;
};

// onDump does not apply to non-roll-off services. Making onDump switch to default to before delivery
const paymentTermOptions = Object.entries(paymentTerms).filter((term) => term[0] !== paymentTermsEnums.onDump);

const UniversalServiceCreate: React.FC = () => {
    const history = useHistory();
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const [orderType, setOrderType] = useState<'' | 'subscription' | 'oneTime'>('');
    const [total, setTotal] = useState<number>(0);
    const [cardSuccessful, setCardSuccessful] = useState(false);
    const [chargeOption, setChargeOption] = useState<string>('');
    const [stripeChargeID, setStripeChargeID] = useState<string>('');
    const [last4, setLast4] = useState<string>('');
    const [refetchKey, setRefetchKey] = useState(0);

    const methods = useForm<UniversalServiceFormData>({
        mode: 'all',
    });

    const {
        control,
        handleSubmit,
        watch,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = methods;

    const formData = watch();

    useEffect(() => {
        reset({
            ...formData,
            serviceGrouping: { ...defaultServiceGrouping },
            customer: { ...customerStartData },
            overrideCustomerBillingAddress: false,
        });
    }, []);

    const onOrderTypeSelect = (value: string) => {
        setOrderType(value as keyof typeof orderTypes);
        if (value === 'oneTime') {
            reset({
                ...formData,
                serviceGrouping: { ...defaultServiceGrouping },
            });
        } else {
            reset({
                ...formData,
                serviceGrouping: {
                    ...defaultServiceGrouping,
                    type: 'open-ended',
                    contractDetails: {
                        active: true,
                    },
                    serviceOrders: [{ ...defaultServiceOrderWithSubscriptionDetails }],
                },
                subscriptionDetails: defaultServiceSubscriptionDetails,
            });
        }
    };

    const onSubmit = async (data: UniversalServiceFormData) => {
        try {
            await createServiceGrouping(data, {
                cardSuccessful,
                customer: formData.customer,
                last4,
                stripeChargeID,
                total,
                showFlash,
                client,
                onCompleted: (grouping) => history.push(routes.universalServices.serviceGrouping.details(grouping.id)),
            });
        } catch (error) {
            console.error('Error creating service grouping', error);
            showFlash('Something has gone very very wrong. This should be impossible.', 'warning');
        }
    };

    const handleAutoPay = (autopayEnabled: boolean, sourceID?: string) => {
        if (autopayEnabled && sourceID) {
            setValue('serviceGrouping.autopayEnabled', true);
            setValue('serviceGrouping.autopayPaymentMethod', sourceID);
        } else {
            setValue('serviceGrouping.autopayEnabled', false);
            setValue('serviceGrouping.autopayPaymentMethod', undefined);
        }
    };

    const handleChargeSuccessful = (stripeChargeID: string, last4: string, stripeId: string) => {
        setCardSuccessful(true);
        setStripeChargeID(stripeChargeID);
        setLast4(last4);
        setValue('customer.stripeId', stripeId);
    };

    const handleSaveSuccessful = (stripeId: string) => {
        setCardSuccessful(true);
        setValue('customer.stripeId', stripeId);
        setRefetchKey((prevKey) => prevKey + 1); // Increment refetchKey
    };

    return (
        <div className="mx-auto flex w-full max-w-5xl flex-1 flex-col p-6">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-6 pb-2 text-3xl">Create Service Grouping</div>
                    <div className="mb-6 border-b border-gray-300 pb-2 text-2xl">Customer Info</div>
                    <SelectCustomerSubForm />
                    <div className="my-6">
                        <CustomerForm2Fields
                            fieldName={'customer.'}
                            customer={formData.customer?.id ? formData.customer : undefined}
                            // disabled={Boolean(formData.customer?.id)}
                            subForm
                            onUseExistingCustomer={(customer) => {
                                if (customer) setValue('customer', customer);
                            }}
                        />
                    </div>

                    <div className="mb-6 mt-10 border-b border-gray-300 pb-2 text-2xl">Service Info</div>
                    <div className="space-y-6">
                        <div className="text-l mb-6 border-b border-gray-300 pb-2">Service Address</div>
                        <ServiceAddressSubForm
                            fieldName={`serviceLocation`}
                            customer={formData.customer?.id ? formData.customer : undefined}
                            handleAddressChange={(value) => setValue('serviceLocation', value)}
                        />
                        <div className="text-l mb-6 border-b border-gray-300 pb-2">One time or Subscription</div>
                        <div className="w-1/2">
                            <div className="mb-6">
                                <Select
                                    label={'One time or Subscription'}
                                    onSelect={onOrderTypeSelect}
                                    defaultValue={''}
                                    required
                                    value={orderType}
                                >
                                    {Object.entries(orderTypes).map((option: [string, string], index: number) => (
                                        <SelectOption key={'orderType' + index} value={option[0]}>
                                            {option[1]}
                                        </SelectOption>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        {orderType === 'subscription' && (
                            <>
                                <SubscriptionDetailsSubForm />
                                <SubscriptionServiceOrderAddSubForm methods={methods} />
                            </>
                        )}
                        {orderType === 'oneTime' && <OneTimeServiceOrderSubForm mode="create" />}
                    </div>
                    <div className="text-l my-2 border-b border-gray-300 pb-2">Order Pricing Summary</div>
                    <TotalTable formData={formData} customer={formData.customer} total={total} setTotal={setTotal} />
                    <div className="space-y-6">
                        <div className="mb-6 mt-10 border-b border-gray-300 pb-2 text-2xl">Payment Info</div>

                        <Controller
                            name="overrideCustomerBillingAddress"
                            control={control}
                            render={({ field }) => (
                                <Checkbox
                                    label="Override Customer Billing Address"
                                    inputProps={{
                                        onChange: (e) => {
                                            field.onChange(e.target.checked);
                                        },
                                        checked: field.value,
                                    }}
                                />
                            )}
                        />
                        {watch('overrideCustomerBillingAddress') && (
                            <>
                                {' '}
                                <div className="text-lg">Billing Address</div>
                                <span className="text-red-400">
                                    This will only be used for this service grouping. The customer's billing address will not be changed.
                                </span>
                                <AddressSubForm
                                    apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
                                    fieldName={'serviceGrouping.billingAddress'}
                                    type="address"
                                    required
                                    disabled={!watch('overrideCustomerBillingAddress')}
                                    handleSelect={(value) => {
                                        setValue('serviceGrouping.billingAddress', value.address);
                                    }}
                                />
                            </>
                        )}
                        <hr className="my-6" />

                        <div className="flex space-x-2">
                            <div className="w-1/2">
                                <Controller
                                    name="serviceGrouping.paymentTerm"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            error={get(errors, 'serviceGrouping.paymentTerm')}
                                            label={'Payment Term'}
                                            onSelect={field.onChange}
                                            defaultValue={''}
                                            required
                                            value={field.value}
                                        >
                                            {paymentTermOptions.map((option: [string, string], index: number) => (
                                                <SelectOption key={'paymentTerm' + index} value={option[0]}>
                                                    {option[1]}
                                                </SelectOption>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </div>
                            <div className="w-1/2">
                                <Controller
                                    name={'serviceGrouping.paymentMethod'}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            error={get(errors, 'serviceGrouping.paymentMethod')}
                                            label={'Payment Method'}
                                            onSelect={field.onChange}
                                            defaultValue={''}
                                            required
                                            value={field.value}
                                        >
                                            {Object.entries(paymentMethods).map((option: [string, string], index: number) => (
                                                <SelectOption key={'paymentMethods' + index} value={option[0]}>
                                                    {option[1]}
                                                </SelectOption>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </div>
                        </div>
                        {watch('serviceGrouping.paymentMethod') === paymentMethodsEnums.creditCard && (
                            <>
                                <ChargeCard2
                                    total={total}
                                    showChargeOption
                                    customer={formData.customer}
                                    onChargeSuccessful={handleChargeSuccessful}
                                    onSaveSuccessful={handleSaveSuccessful}
                                    onStripeCustomerFetched={(stripeCustomer) => {
                                        if (stripeCustomer) setValue('customer.stripeId', stripeCustomer.id);
                                    }}
                                    onChargeOptionChange={setChargeOption}
                                />
                                {orderType === 'subscription' && (
                                    <AutoPaySelection
                                        stripeCustomerID={formData.customer.stripeId || ''}
                                        onSelection={handleAutoPay}
                                        subscriptionInfo={formData.subscriptionDetails as UniversalService.SubscriptionDetails}
                                        refetchKey={refetchKey}
                                    />
                                )}
                            </>
                        )}
                        <div className="float-right mt-2">
                            <Button className="btn-dark-grey-outlined mr-4" onClick={() => history.goBack()}>
                                Cancel
                            </Button>
                            <Button
                                className="btn-primary"
                                type="submit"
                                disabled={
                                    (getValues('serviceGrouping.paymentMethod') === paymentMethodsEnums.creditCard &&
                                        getValues('serviceGrouping.paymentTerm') === paymentTermsEnums.onCharge &&
                                        chargeOption !== 'none' &&
                                        !cardSuccessful) ||
                                    (chargeOption === 'now' && !cardSuccessful)
                                }
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default UniversalServiceCreate;
