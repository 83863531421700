import React, { useContext } from 'react';
import { InternalTicket } from '@alliance-disposal/transport-types';
import { InternalTicketCard } from './InternalTicketCard';
import { InternalTicketContext } from './context';

type InternalTicketListProps = {
    onReplyClick: (ticketID: string) => void;
    onEditClick: (ticket: InternalTicket.InternalTicketTransport) => void;
    onRedactClick: (ticketID: string) => void;
    onShowImageClick: (ticket: InternalTicket.InternalTicketTransport) => void;
    readOnly?: boolean;
};

export const InternalTicketList = ({ onReplyClick, onEditClick, onRedactClick, readOnly, onShowImageClick }: InternalTicketListProps) => {
    const { tickets, showReplies } = useContext(InternalTicketContext);

    return (
        <div className="flex flex-col">
            {tickets
                .sort((a, b) => (a.metadata.createdAt > b.metadata.createdAt ? 1 : -1))
                .map((ticket) => {
                    return (
                        <div className="flex flex-col" key={ticket.id}>
                            <InternalTicketCard
                                ticket={ticket}
                                onReplyClick={onReplyClick}
                                onEditClick={onEditClick}
                                onRedactClick={onRedactClick}
                                onShowImageClick={onShowImageClick}
                                readOnly={readOnly}
                            />
                            {ticket.replies?.length > 0 && showReplies[ticket.id] && (
                                <div className="flex flex-col border-l-4 border-l-slate-200 pl-2.5">
                                    {ticket.replies
                                        .sort((a, b) => (a.metadata.createdAt > b.metadata.createdAt ? 1 : -1))
                                        .map((reply, index) => (
                                            <React.Fragment key={reply.id}>
                                                <InternalTicketCard
                                                    ticket={reply}
                                                    onReplyClick={() => {}}
                                                    isReply={true}
                                                    onEditClick={onEditClick}
                                                    onRedactClick={onRedactClick}
                                                    onShowImageClick={onShowImageClick}
                                                    readOnly={readOnly}
                                                />
                                                {index !== ticket.replies.length - 1 && <hr className="my-2.5" />}
                                            </React.Fragment>
                                        ))}
                                </div>
                            )}
                            <hr className="my-2.5" />
                        </div>
                    );
                })}
        </div>
    );
};
