import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Invoice } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { DataGrid, DataGridProps } from '@wayste/sour-ui';
import { getPrimaryCustomerContact, moneyFormatter } from '@wayste/utils';
import { differenceInCalendarDays, format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../utils';

type CustomerWithBalance = {
    id: string;
    displayName: string;
    balance: number;
    receivables: Invoice.ReceivableTransport[];
};
///////////// START HELPER FUNCTIONS /////////////
const getOldestDueDate = (receivables: Invoice.ReceivableTransport[]) => {
    if (!receivables || receivables.length === 0) {
        return null;
    }
    // Filter out invoices without a dueDate
    const invoicesWithDueDate = receivables.filter((invoice) => invoice.invoiceDetails.dueDate);
    if (invoicesWithDueDate.length === 0) {
        return null;
    }
    // Find the invoice with the oldest due date
    return invoicesWithDueDate.reduce((oldest, current) => {
        return new Date(current.invoiceDetails.dueDate as string) < new Date(oldest.invoiceDetails.dueDate as string) ? current : oldest;
    });
};

const countAndSumInvoicesByStatus = (receivables: Invoice.ReceivableTransport[], status: 'PAST_DUE' | 'DRAFT' | 'OPEN') => {
    if (!receivables || receivables.length === 0) {
        return { count: 0, amount: 0 };
    }

    // Filter invoices with status
    const invoicesWithStatus = receivables.filter((invoice) => {
        if (status === 'PAST_DUE') {
            return invoice.invoiceDetails.status === 'PAST_DUE';
        }
        if (status === 'DRAFT') {
            return invoice.invoiceDetails.status === 'DRAFT';
        }
        return (
            invoice.invoiceDetails.status === 'PENDING' ||
            invoice.invoiceDetails.status === 'PARTIALLY_PAID' ||
            invoice.invoiceDetails.status === 'PARTIALLY_VOID' ||
            invoice.invoiceDetails.status === 'PARTIALLY_REFUNDED'
        );
    });

    // Count the number of overdue invoices and sum their openBalance
    const count = invoicesWithStatus.length;
    const amount = invoicesWithStatus.reduce((sum, invoice) => sum + invoice.invoiceDetails.remainingBalance, 0);

    return { count, amount };
};
///////////// END HELPER FUNCTIONS /////////////

const CustomerBalanceBoard = ({ accountManagerID }: { accountManagerID?: string }) => {
    const client = useWaysteClient();
    const history = useHistory();
    const { setShowToast } = useSourContext();
    const [rows, setRows] = useState<CustomerWithBalance[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleCustomersWithBalance = async () => {
        setIsLoading(true);
        try {
            const customers = await client.customer().adminPortal.query({
                hasBalance: true,
                accountManagerID,
            });
            const customersWithBalance: CustomerWithBalance[] = [];
            // Helper function to fetch invoices in batches
            const fetchBatch = async (batch: Customer.AllianceCustomerTransport[]) => {
                const batchPromises = batch.map(async (customer) => {
                    const receivables = await client.invoice().adminPortal.receivable.query({
                        customerID: customer.id,
                        paidInFull: false,
                        void: false,
                        limit: 1000,
                    });

                    return {
                        id: customer.id,
                        displayName:
                            customer.companyName ||
                            getPrimaryCustomerContact(customer).firstName + ' ' + getPrimaryCustomerContact(customer).lastName,
                        balance: customer.balance || 0,
                        receivables: receivables.results,
                    };
                });

                const batchResults = await Promise.all(batchPromises);
                customersWithBalance.push(...batchResults);
            };
            // Process in batches
            for (let i = 0; i < customers.length; i += 50) {
                const batch = customers.slice(i, i + 50);
                await fetchBatch(batch);
            }
            setRows(customersWithBalance.sort((a, b) => a.displayName.localeCompare(b.displayName)));
        } catch (error) {
            console.error(error);
            setShowToast({ message: 'Failed to fetch customers with balance', severity: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (accountManagerID) handleCustomersWithBalance();
    }, [accountManagerID]);

    const handleRowClick = (row: CustomerWithBalance) => {
        history.push(routes.customers.details(row.id));
    };

    const columns: DataGridProps<CustomerWithBalance>['columns'] = [
        {
            key: 'customerName',
            name: 'Customer',
            formatter: ({ row }) => row.displayName,
        },
        {
            key: 'balance',
            name: 'Balance',
            formatter: ({ row }) => moneyFormatter(row.balance),
        },
        {
            key: 'maxOverDueBy',
            name: 'Max Overdue By',
            formatter: ({ row }) => {
                const oldest = getOldestDueDate(row.receivables);
                if (!oldest) {
                    return 'No Overdue Invoices';
                }
                const daysBetween = differenceInCalendarDays(new Date(), new Date(oldest.invoiceDetails.dueDate as string));
                return `${daysBetween} Days | Due on ${format(new Date(oldest.invoiceDetails.dueDate as string), 'MM/dd/yyyy')}`;
            },
        },
        {
            key: 'invoicesOverdue',
            name: 'Invoices Overdue',
            formatter: ({ row }) => {
                const invoices = countAndSumInvoicesByStatus(row.receivables, 'PAST_DUE');
                return `${invoices.count} | ${moneyFormatter(invoices.amount)}`;
            },
        },
        {
            key: 'invoicesOpen',
            name: 'Invoices Open',
            formatter: ({ row }) => {
                const invoices = countAndSumInvoicesByStatus(row.receivables, 'OPEN');
                return `${invoices.count} | ${moneyFormatter(invoices.amount)}`;
            },
        },
        {
            key: 'invoicesDraft',
            name: 'Invoices Draft',
            formatter: ({ row }) => {
                const invoices = countAndSumInvoicesByStatus(row.receivables, 'DRAFT');
                return `${invoices.count} | ${moneyFormatter(invoices.amount)}`;
            },
        },
    ];

    return <DataGrid rows={rows} columns={columns} onRowClick={handleRowClick} loading={isLoading} />;
};

export default CustomerBalanceBoard;
