import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { V1 as sourgumPricingFunctions } from '@alliance-disposal/pricing';
import {
    AddressTransport,
    Material,
    MaterialLabels,
    Materials,
    Pricing,
    ServiceLocationTransport,
} from '@alliance-disposal/transport-types';
import { GooglePlacesInput } from '@wayste/sour-components';
import { ButtonGroup, CurrencyTextField, RadioButton, Select, SelectOption, TextField, Toggle } from '@wayste/sour-ui';
import { determineWaysteActive, formatServiceAddress } from '@wayste/utils';
import { ArrowPathIcon, DocumentTextIcon } from '@heroicons/react/24/solid';
import { useFlash } from '../../hooks/useFlash';
import { ccRate } from '../../utils/pricing-utils';
import { priceTypesEnums } from '../../utils/shared-types';
import ButtonSelect from '../ButtonSelect';
import Loading from '../Loading';
import type { HaulerPricingWithHauler } from '../OrderStatusChanger/OrderAssignHauler';
import SpreadsTable from '../SpreadsTable/SpreadsTable';
import PricingTable from './PricingTable';
import ZoneNotes from './ZoneNotes';

export type OnAddDetailsValues = {
    county: string;
    material: string;
    priceType: 'ton' | 'flat' | 'yard';
    tax: boolean;
    taxRate: number;
    cc: boolean;
    ccRate: number;
    round: boolean;
    expectedSize: number;
    weightLimit: number | null;
    price: number;
    overage: number | null;
    dumpRate: number | null;
    rentPeriod: number | null;
    rentExtensionFee: number | null;
    pricingExists: boolean;
};

export type PricingCalcStartingValues = {
    address?: AddressTransport;
    county?: string;
    coordinates?: {
        lat: number;
        lng: number;
    };
};

interface Props {
    onAddDetails: (values: OnAddDetailsValues) => void;
    forLead?: boolean;
    onGoogleSelect: (values: ServiceLocationTransport) => void;
    startingValues?: PricingCalcStartingValues;
}

const materialOptions = Materials.map((item) => ({
    label: MaterialLabels[item],
    value: item,
}));

const initialSizes = [
    { size: 10, tonLimit: 2, haul: '', dump: '', over: '' },
    { size: 15, tonLimit: 2.5, haul: '', dump: '', over: '' },
    { size: 20, tonLimit: 3, haul: '', dump: '', over: '' },
    { size: 25, tonLimit: 3.5, haul: '', dump: '', over: '' },
    { size: 30, tonLimit: 4, haul: '', dump: '', over: '' },
    { size: 40, tonLimit: 5, haul: '', dump: '', over: '' },
];

const styles = {
    input: {
        margin: '0 5px 27px',
        maxWidth: 335,
        minWidth: 200,
        flex: 1,
    },
};

const startingPricing = {
    material: '',
    tax: true,
    type: priceTypesEnums.ton,
    sizes: [...initialSizes],
    lastUpdatedAt: '',
};

const startingRentalOptions: {
    rentalPeriod: '' | number;
    rentExtensionFee: '' | number;
} = {
    rentalPeriod: '',
    rentExtensionFee: '',
};

const PricingCalc = ({ onAddDetails, forLead, onGoogleSelect, startingValues }: Props) => {
    const client = useWaysteClient();
    const { showFlash } = useFlash();
    const [autoCompleteValue, setAutoCompleteValue] = useState('');
    const [taxRate, setTaxRate] = useState(0);
    const [material, setMaterial] = useState('');
    const [chargeCC, setChargeCC] = useState(true);
    const [roundTotal, setRoundTotal] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [pricingObject, setPricingObject] = useState<Pricing.PricingTransport | null>(null);
    const [selectedPricing, setSelectedPricing] = useState<any>(sourgumPricingFunctions.normalizeMaterialSize(startingPricing as any));
    const [haulRate, setHaulRate] = useState<number | ''>('');
    const [dumpRate, setDumpRate] = useState<number | ''>('');
    const [showHaulerPricing, setShowHaulerPricing] = useState(true);
    const [haulerPricing, setHaulerPricing] = useState<HaulerPricingWithHauler[]>([]);
    const [rentalOptions, setRentalOptions] = useState(startingRentalOptions);
    const [discount, setDiscount] = useState<number | ''>('');
    const [showHaulDump, setShowHaulDump] = useState(false);
    const [showNotes, setShowNotes] = useState(false);
    const [noService, setNoService] = useState(false);
    const [county, setCounty] = useState('');
    const [selectedSize, setSelectedSize] = useState(startingPricing.sizes[0].size);
    const [publicPricing, setPublicPricing] = useState<null | Pricing.PricingTransport>(null);
    const [tierPricingOptions, setTierPricingOptions] = useState<(Pricing.PricingTransport & { tierName?: string })[]>([]);
    const [tierPricing, setTierPricing] = useState<null | (Pricing.PricingTransport & { tierName?: string })>(null);

    const applyDiscountToPricingZone = (pricingTransport: Pricing.PricingTransport, discount: Pricing.CustomerDiscountTransport) => {
        const copiedPricing = { ...pricingTransport };
        if (copiedPricing.id === discount.pricingZone?.id && copiedPricing.type === 'PUBLIC') {
            let specialPricingApplied = false;
            const materialsApplied = [];
            // for each material in the discount find the corresponding material in the pricing and apply the discount
            copiedPricing.pricingData = copiedPricing.pricingData.map((pricingData) => {
                const copiedPricingData = { ...pricingData };
                const discountData = discount.discountData.find((discountData) => discountData.material === copiedPricingData.material);

                if (discountData) {
                    // apply the material level discounts
                    /// no longer exist moved to size
                    // apply the size level discounts
                    copiedPricingData.sizes = copiedPricingData.sizes.map((size) => {
                        const copiedSize = { ...size };
                        const discountSize = discountData.sizes.find((discountSize) => discountSize.size === copiedSize.size);

                        if (discountSize) {
                            if (discountSize.haul) {
                                copiedSize.haul = sourgumPricingFunctions.applyDiscount(
                                    copiedSize.haul || 0,
                                    discountSize.haul,
                                    discount.discountType,
                                );
                                copiedSize.haul = copiedSize.haul || 0;
                            }

                            if (discountSize.dump) {
                                copiedSize.dump = sourgumPricingFunctions.applyDiscount(
                                    Number(copiedSize.dump),
                                    discountSize.dump,
                                    discount.discountType,
                                );
                                copiedSize.dump = copiedSize.dump || 0;
                            }

                            if (discountSize.over) {
                                copiedSize.over = sourgumPricingFunctions.applyDiscount(
                                    Number(copiedSize.over),
                                    discountSize.over,
                                    discount.discountType,
                                );
                                copiedSize.over = copiedSize.over || 0;
                            }

                            if (discountSize.tonLimit) {
                                copiedSize.tonLimit =
                                    discount.discountType === 'FIXED'
                                        ? discountSize.tonLimit
                                        : (copiedSize.tonLimit || 0) + discountSize.tonLimit;
                            }
                        }
                        return copiedSize;
                    });

                    materialsApplied.push(copiedPricingData.material);
                    specialPricingApplied = true;
                }
                return copiedPricingData;
            });

            if (specialPricingApplied) {
                copiedPricing.pricingCalculationVersion = String(sourgumPricingFunctions.VERSION);
            }

            if (discount.extraRentalPeriod) {
                copiedPricing.rentalPeriod = {
                    value: sourgumPricingFunctions.applyDiscount(
                        pricingTransport.rentalPeriod?.value || 0,
                        discount.extraRentalPeriod,
                        discount.discountType,
                    ),
                    unit: 'DAYS',
                };
            }
            if (discount.rentExtensionFee) {
                copiedPricing.rentExtensionFee = sourgumPricingFunctions.applyDiscount(
                    pricingTransport.rentExtensionFee || 0,
                    discount.rentExtensionFee,
                    discount.discountType,
                );
            }

            return { ...copiedPricing, tierName: discount.tierName };
        }
        return null;
    };

    const getTierPricing = async (pricingZoneData: Pricing.PricingTransport) => {
        const response = await client
            .pricing()
            .adminPortal.customerDiscount.query({ tieredPricing: true, pricingZoneID: pricingZoneData.id });
        if (response.results.length === 0) {
            setTierPricingOptions([]);
            return;
        }
        const tierZones = response.results.map((item) => applyDiscountToPricingZone(pricingZoneData, item));
        setTierPricingOptions(
            tierZones
                .filter((item) => item !== null)
                .sort((a, b) => {
                    const indexA = Pricing.SourgumTierNames.findIndex((tier) => tier === a.tierName);
                    const indexB = Pricing.SourgumTierNames.findIndex((tier) => tier === b.tierName);
                    return indexA - indexB;
                }),
        );
    };

    const resetFields = () => {
        setChargeCC(true);
        setRoundTotal(true);
        setMaterial('');
        setSelectedPricing(sourgumPricingFunctions.normalizeMaterialSize(startingPricing as any));
        setRentalOptions(startingRentalOptions);
    };

    const handleFullReset = () => {
        setCounty('');
        setAutoCompleteValue('');
        setHaulerPricing([]);
        resetFields();
        setTierPricing(null);
    };

    const getAllPricing = async (location: { lat: number; lng: number; zip: string; state: string }) => {
        setIsLoading(true);
        resetFields();
        setPricingObject(null);
        setNoService(false);
        setHaulerPricing([]);
        const response = await client.pricing().adminPortal.location.query(location);

        const allData: Pricing.PricingTransport[] = response;
        const publicResults = allData.filter((item) => item.public);
        const rawPublicResults: Pricing.PricingTransport[] = response.filter((item: Pricing.PricingTransport) => item.public);

        if (publicResults[0]?.id) getTierPricing(rawPublicResults[0]);
        setPricingObject(publicResults[0] || null);
        setPublicPricing(publicResults[0] || null);
        setTierPricing(null);
        setRentalOptions({
            rentalPeriod: publicResults[0]?.rentalPeriod?.value || '',
            rentExtensionFee: publicResults[0]?.rentExtensionFee || '',
        });

        const newTaxRate = publicResults[0]?.actualTaxRate || 0;

        setTaxRate(newTaxRate);
        handleUpdateSelectedPricing('tax', newTaxRate > 0 ? true : false);
        if (!response || publicResults.length > 1) {
            if (publicResults.length > 1) {
                console.warn('Too many admin pricing results', publicResults);
                alert('Zip Code or City Spans Multiple Zones. Get an exact address for pricing');
            } else {
                alert('An error occurred. Get an AAP Dev');
            }
            handleFullReset();
            return;
        }
        if (publicResults.length === 0) {
            setNoService(true);
        }
        const haulerResults = allData.filter((item) => !item.public);

        const formattedHaulerResults: HaulerPricingWithHauler[] = [];

        const haulerPricingPromises = haulerResults.map(async (item) => {
            if (item.haulerID && !item.public) {
                try {
                    // for anyone who comes across this, this syntax is called destructuring assignment
                    // basically I'm first destructuring the array returned from the promise
                    // then I'm destructuring the results from the paginated result and renaming it to haulerWaysteActivity
                    const [hauler, { results: haulerWaysteActivity }] = await Promise.all([
                        client.vendorService().adminPortal.fetch(item.haulerID),
                        client.vendorService().adminPortal.activity.query({
                            haulerID: item.haulerID,
                        }),
                    ]);

                    if (hauler && hauler.active) {
                        formattedHaulerResults.push({
                            ...item,
                            hauler,
                            waysteUsage: determineWaysteActive(hauler, haulerWaysteActivity),
                        });
                    }
                } catch (error) {
                    console.warn('error: ', error);
                    showFlash('An error occurred fetching hauler pricing', 'warning');
                }
            }
            return null;
        });

        await Promise.all(haulerPricingPromises);

        setHaulerPricing(formattedHaulerResults.filter((item) => item.hauler?.active));
        setIsLoading(false);
    };

    useEffect(() => {
        if (startingValues?.coordinates) {
            setAutoCompleteValue(formatServiceAddress(startingValues.address));
            setCounty(startingValues.county || '');
            setTimeout(() => {
                if (startingValues?.coordinates && startingValues.address) {
                    getAllPricing({
                        ...startingValues.coordinates,
                        zip: startingValues.address.zip,
                        state: startingValues.address.state,
                    });
                }
            }, 50);
        }
    }, []);

    const handleUpdateSelectedPricing = (key: string, value: any) => {
        let updatedPricing = JSON.parse(JSON.stringify(selectedPricing));
        updatedPricing = {
            ...selectedPricing,
            [key]: value,
        };
        setSelectedPricing(updatedPricing);
    };

    const handleHaulChange = (newHaul: number) => {
        const updatedPricing = { ...selectedPricing };
        updatedPricing.sizes = updatedPricing.sizes.map((item: any) => ({
            ...item,
            haul: newHaul,
        }));
        setSelectedPricing(updatedPricing);
        setHaulRate(newHaul);
    };

    const handleDumpRateChange = (newDump: number) => {
        const updatedPricing = { ...selectedPricing };
        updatedPricing.sizes = updatedPricing.sizes.map((item: any) => ({
            ...item,
            dump: newDump,
        }));
        setSelectedPricing(updatedPricing);
        setDumpRate(newDump);
    };

    const handleUpdateRentalOptions = (key: string, value: number | '') => {
        setRentalOptions({
            ...rentalOptions,
            [key]: value,
        });
    };

    const googleAPIHandler = async (res: ServiceLocationTransport, coords: ServiceLocationTransport['coordinates']) => {
        if (!coords) {
            handleFullReset();
            alert('Missing coordinates get an AAP Dev');
            return;
        }
        const address = res.address;
        setAutoCompleteValue(formatServiceAddress(res.address, { partial: true }));
        const countyCleaned = res.county ? res.county.replace(' County', '') : '';
        setCounty(countyCleaned);
        if (onGoogleSelect) {
            onGoogleSelect({
                address: address,
                county: countyCleaned,
                coordinates: coords,
            });
        }
        getAllPricing({
            ...coords,
            zip: res.address?.zip || '',
            state: res.address.state,
        });
    };

    const handleToggleHaulerPricing = () => {
        setShowHaulerPricing(!showHaulerPricing);
    };

    const handleSetPricing = (material: string) => {
        let price: any = sourgumPricingFunctions.normalizeMaterialSize(startingPricing as any);
        if (pricingObject) {
            const exists = pricingObject.pricingData.find((item) => item.material === material);
            if (exists) price = exists;
        }
        let priceCopy = JSON.parse(JSON.stringify(price));
        setChargeCC(true);
        if (price.type === priceTypesEnums.flat) {
            priceCopy = {
                ...priceCopy,
                dump: 0,
            };
        }

        if (priceCopy.sizes?.every((item: any) => item.haul === priceCopy.sizes[0]?.haul)) {
            setHaulRate(priceCopy.sizes[0]?.haul || '');
        } else {
            setHaulRate('');
        }
        if (priceCopy.sizes?.every((item: any) => item.dump === priceCopy.sizes[0]?.dump)) {
            setDumpRate(priceCopy.sizes[0]?.dump || '');
        } else {
            setDumpRate('');
        }
        const normalizedPricing = sourgumPricingFunctions.normalizeMaterialSize(priceCopy);
        normalizedPricing.sizes = normalizedPricing.sizes.map((item) => {
            return {
                ...item,
                originalType: item.type,
            };
        });
        setSelectedPricing(normalizedPricing);
    };

    const handleMaterialChange = (value: string) => {
        if (!value) {
            resetFields();
        } else {
            setMaterial(value);
            handleSetPricing(value);
        }
    };

    const radioDisabled = (type: string) => {
        if (!pricingObject || !material) {
            return false;
        }
        const found = pricingObject.pricingData.find((item) => item.material === material);
        if (!found) return false;
        if (found.type !== type) return true;
        return false;
    };

    useEffect(() => {
        if (material) {
            handleSetPricing(material);
        }
    }, [tierPricing]);

    const handleTierSelect = (value: (Pricing.PricingTransport & { tierName?: string }) | null) => {
        if (value) {
            setPricingObject(value);
            setRentalOptions({
                rentalPeriod: value.rentalPeriod?.value || '',
                rentExtensionFee: value.rentExtensionFee || '',
            });
        } else {
            setPricingObject(publicPricing);
            setRentalOptions({
                rentalPeriod: publicPricing?.rentalPeriod?.value || '',
                rentExtensionFee: publicPricing?.rentExtensionFee || '',
            });
        }
        setTierPricing(value);
    };

    const handleAddDetails = (size: number, tonLimit: number, total: number, overage: number) => {
        const selectedSize = selectedPricing.sizes.find((item: any) => +item.size === +size);
        onAddDetails({
            county: county,
            material: material,
            priceType: selectedPricing.type as 'ton' | 'flat' | 'yard',
            tax: selectedPricing.tax,
            taxRate: selectedPricing.tax ? taxRate : 0,
            cc: chargeCC,
            ccRate: chargeCC ? ccRate : 0,
            round: roundTotal,
            expectedSize: size,
            weightLimit: selectedPricing.type === priceTypesEnums.ton ? Number(tonLimit) || 0 : null,
            price: total,
            overage: Number(overage) || null,
            dumpRate: Number(selectedSize?.dump) || null,
            rentPeriod: Number(rentalOptions.rentalPeriod) || 0,
            rentExtensionFee: Number(rentalOptions.rentExtensionFee) || 0,
            pricingExists: pricingObject && selectedPricing.allowOnlineCheckout ? true : false,
        });
    };

    if (isLoading) return <Loading />;

    return (
        <div>
            <div className="shadow-dark rounded bg-white p-2 md:p-3">
                <div style={{ maxWidth: 500, marginBottom: 17 }}>
                    <GooglePlacesInput
                        showFullAddressInInput
                        partial
                        apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
                        inputProps={{
                            label: 'Town or address',
                            queryValue: autoCompleteValue,
                        }}
                        onPlaceSelected={(place: ServiceLocationTransport) => {
                            googleAPIHandler(place, place.coordinates);
                        }}
                    />
                </div>
                <div className="mb-5 flex flex-wrap items-center">
                    <ButtonSelect
                        label="Material"
                        menuItems={materialOptions}
                        value={material}
                        onSelect={handleMaterialChange}
                        noneOption
                        wrapperClass="my-1.5"
                    />
                    <div style={{ padding: '0 12px 0 24px', opacity: 0.65 }}>
                        <div>
                            <b>State:</b> {pricingObject?.state}
                        </div>
                        <div>
                            <b>County:</b> {county}
                        </div>
                    </div>
                    <div>
                        <button onClick={handleFullReset} className="btn-icon" type="button">
                            <ArrowPathIcon className="text-wayste-blue-400 size-6" />
                        </button>
                    </div>
                    <div className="ml-2.5">
                        <Select
                            value={tierPricing?.tierName || null}
                            onSelect={() => {}}
                            disabled={tierPricingOptions.length === 0}
                            renderLabel={() => tierPricing?.tierName || 'Regular Pricing'}
                        >
                            <SelectOption value={null} onClick={() => handleTierSelect(null)}>
                                Regular Pricing
                            </SelectOption>
                            {tierPricingOptions.map((item) => (
                                <SelectOption key={item.tierName} value={item.tierName} onClick={() => handleTierSelect(item)}>
                                    {item.tierName}
                                </SelectOption>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="my-7 flex">
                    <div className="flex flex-col gap-3 md:flex-row">
                        <RadioButton
                            options={[
                                {
                                    label: 'By Ton',
                                    value: priceTypesEnums.ton,
                                    inputProps: {
                                        disabled: radioDisabled(priceTypesEnums.ton),
                                        checked: selectedPricing.type === priceTypesEnums.ton,
                                        onClick: () => handleUpdateSelectedPricing('type', priceTypesEnums.ton),
                                    },
                                },
                                {
                                    label: 'By Yard',
                                    value: priceTypesEnums.yard,
                                    inputProps: {
                                        disabled: radioDisabled(priceTypesEnums.yard),
                                        checked: selectedPricing.type === priceTypesEnums.yard,
                                        onClick: () => handleUpdateSelectedPricing('type', priceTypesEnums.yard),
                                    },
                                },
                                {
                                    label: 'Flat Rate',
                                    value: priceTypesEnums.flat,
                                    inputProps: {
                                        disabled: radioDisabled(priceTypesEnums.flat),
                                        checked: selectedPricing.type === priceTypesEnums.flat,
                                        onClick: () => handleUpdateSelectedPricing('type', priceTypesEnums.flat),
                                    },
                                },
                            ]}
                        />
                    </div>
                    <div className="ml-5 flex flex-col gap-3 md:flex-row">
                        <Toggle
                            value={taxRate === 0 ? false : selectedPricing.tax}
                            label="Sales Tax"
                            onChange={(value) => handleUpdateSelectedPricing('tax', value)}
                            disabled={taxRate === 0}
                        />
                        <Toggle value={chargeCC} label="CC Fee" onChange={setChargeCC} />
                        <Toggle value={roundTotal} label="Round Total" onChange={setRoundTotal} />
                    </div>
                </div>
                <div className="flex flex-wrap items-center">
                    <div style={{ ...styles.input, maxWidth: 150 }}>
                        <CurrencyTextField
                            label="Discount Amount"
                            value={discount || 0}
                            useCents
                            onChange={(value) => setDiscount(Number(value) || 0)}
                        />
                    </div>
                    <button onClick={() => setShowHaulDump(!showHaulDump)} className="btn-secondary-text-only mb-7" type="button">
                        {showHaulDump ? 'Hide' : 'Show'} Haul/Dump
                    </button>
                    <div style={{ marginBottom: 27, position: 'relative' }}>
                        <button onClick={() => setShowNotes(true)} disabled={!pricingObject} type="button" className="btn-icon">
                            <DocumentTextIcon className="text-wayste-blue-400 size-6" />
                        </button>
                        {showNotes ? <ZoneNotes onClose={() => setShowNotes(false)} pricing={pricingObject} /> : null}
                    </div>
                </div>
                {showHaulDump ? (
                    <div className="flex flex-wrap items-center">
                        <div style={{ ...styles.input, maxWidth: 150 }}>
                            <CurrencyTextField
                                label="Haul rate"
                                value={haulRate || 0}
                                useCents
                                onChange={(value) => handleHaulChange(Number(value) || 0)}
                            />
                        </div>
                        {selectedPricing.type === priceTypesEnums.flat ? null : (
                            <div style={{ ...styles.input, maxWidth: 150 }}>
                                <CurrencyTextField
                                    label="Dump rate"
                                    value={dumpRate || 0}
                                    useCents
                                    onChange={(value) => handleDumpRateChange(Number(value) || 0)}
                                />
                            </div>
                        )}
                    </div>
                ) : null}
                <div className="flex flex-wrap items-center">
                    <div style={{ ...styles.input, maxWidth: 150 }}>
                        <TextField
                            label="Rental Period"
                            type="number"
                            inputProps={{
                                value: rentalOptions.rentalPeriod,
                                onChange: (e) => handleUpdateRentalOptions('rentalPeriod', Number(e.target.value) || ''),
                                type: 'number',
                            }}
                            endAdornment={'/ days'}
                        />
                    </div>
                    <div style={{ ...styles.input, maxWidth: 150 }}>
                        <CurrencyTextField
                            label="Rental Extension Fee"
                            useCents
                            value={rentalOptions.rentExtensionFee}
                            onChange={(value) => handleUpdateRentalOptions('rentExtensionFee', Number(value) || 0)}
                        />
                    </div>
                </div>
                {noService && (
                    <div
                        style={{
                            fontSize: 24,
                            color: 'red',
                            marginTop: 15,
                            marginBottom: 15,
                        }}
                    >
                        WE DO NOT SERVICE THIS AREA
                    </div>
                )}
                <PricingTable
                    onUpdatePricing={handleUpdateSelectedPricing}
                    pricing={selectedPricing}
                    chargeCC={chargeCC}
                    taxRate={taxRate}
                    roundTotal={roundTotal}
                    onAddDetails={handleAddDetails}
                    forLead={forLead || false}
                    material={material}
                    discount={discount || 0}
                />
                <div style={{ display: 'flex' }}>
                    <button className="btn-secondary-text-only ml-auto" onClick={handleToggleHaulerPricing} type="button">
                        {showHaulerPricing ? 'Hide' : 'Show'} Hauler Pricing
                    </button>
                </div>
            </div>
            {showHaulerPricing ? (
                <div className="shadow-dark mt-3 rounded bg-white p-2 md:p-3">
                    <h5 className="text-2xl">Haulers Pricing</h5>
                    <div className="flex py-3">
                        <ButtonGroup
                            activeBtn={+selectedSize}
                            buttons={selectedPricing.sizes.map((size: any) => ({
                                label: `${size.size} YD`,
                                value: +size.size,
                            }))}
                            onClick={(value) => setSelectedSize(value as number)}
                        />
                    </div>
                    <SpreadsTable
                        haulerWithPricing={haulerPricing}
                        sourgumPricing={{
                            haul:
                                (Number(selectedPricing.sizes.find((size: any) => +size.size === +selectedSize)?.haul) || 0) -
                                (discount || 0),
                            dump: Number(selectedPricing.sizes.find((size: any) => +size.size === +selectedSize)?.dump) || 0,
                            tonLimit: +selectedPricing.sizes.find((size: any) => +size.size === +selectedSize)?.tonLimit,
                            overage: +selectedPricing.sizes.find((size: any) => +size.size === +selectedSize)?.overage,
                        }}
                        selectedMaterial={material as Material}
                        selectedSize={selectedSize}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default PricingCalc;
