import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Order } from '@alliance-disposal/transport-types';
import { SourSearchOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { Button, Checkbox, Dialog } from '@wayste/sour-ui';
import { getPrimaryCustomerContact } from '@wayste/utils';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { isAxiosError } from 'axios';
import { useConfirmationDialog } from '../../contexts';
import { useFlash } from '../../hooks/useFlash';

export type MergeCustomerDialogProps = {
    open: boolean;
    onClose: () => void;
    fromCustomer: Customer.AllianceCustomerTransport;
    moveOrder?: Order.AllianceOrderTransport;
};

export const MergeCustomerDialog = ({ open, onClose, fromCustomer, moveOrder }: MergeCustomerDialogProps) => {
    const client = useWaysteClient();
    const { showFlash } = useFlash();
    const { getConfirmation } = useConfirmationDialog();
    const [toCustomer, setToCustomer] = useState<Customer.AllianceCustomerTransport | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteFromCustomer, setDeleteFromCustomer] = useState(false);

    const handleSelect = (customer: Customer.AllianceCustomerTransport) => {
        if (customer.id === fromCustomer.id) {
            showFlash('Cannot merge customer with itself', 'warning');
            return;
        }
        setToCustomer(customer);
    };

    const handleSave = async () => {
        setIsLoading(true);

        if (!toCustomer) {
            showFlash('Please select a customer to merge to', 'warning');
            setIsLoading(false);
            return;
        }

        if (toCustomer.id === fromCustomer.id) {
            showFlash('Cannot merge customer with itself', 'warning');
            setIsLoading(false);
            return;
        }

        let payload: Customer.MergeSourgumCustomerInput;

        if (deleteFromCustomer) {
            payload = {
                fromCustomerId: fromCustomer.id,
                toCustomerId: toCustomer.id,
                deleteFromCustomer: true,
            };
        } else {
            if (!moveOrder) {
                showFlash('Please select an order to move', 'warning');
                setIsLoading(false);
                return;
            }

            payload = {
                fromCustomerId: fromCustomer.id,
                toCustomerId: toCustomer.id,
                ordersToMigrate: [moveOrder.id],
            };
        }

        const confirmedOnce = await getConfirmation({
            message: 'Are you sure you want to merge this customer? This cannot be undone.',
            title: 'Merge Customer',
        });

        if (!confirmedOnce) {
            setIsLoading(false);
            return;
        }

        // set a 1 second time out
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const confirmedTwice = await getConfirmation({
            message:
                'Are you sure you want to merge this customer? This will be your last chance to cancel. If you are merging a customer with a lot of orders, this may take a while.',
            title: 'Merge Customer',
        });

        if (!confirmedTwice) {
            setIsLoading(false);
            return;
        }

        try {
            await client.customer().adminPortal.merge(payload);
            showFlash(
                'Customer merged successfully. It may take a few seconds for the changes to reflect and it is likely that the frontend will through an error. Refreshing the window is recommended.',
                'success',
            );
            onClose();
        } catch (error) {
            let message = 'Error merging customer';
            console.error(error);
            if (isAxiosError(error)) {
                message = error?.response?.data?.message || 'Error merging customer';
            }
            showFlash(message, 'warning');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} showX={true} styledTitle="Merge Customer" className="overflow-visible">
            <SourSearchWrapperOld
                onNavigate={(res) => {
                    handleSelect(res);
                }}
                options={{
                    application: 'aap',
                    apiKey: import.meta.env.VITE_ELASTIC_KEY,
                    environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                }}
                highlight
                defaultFilters={{
                    query: {
                        type: 'sourgum-customer',
                    },
                }}
                size={10}
            >
                <div className="flex flex-col gap-1">
                    <h2 className="border-b text-lg font-bold">From Customer</h2>
                    <div className="mb-2 flex flex-col border-b">
                        <span>
                            Primary Contact: {getPrimaryCustomerContact(fromCustomer)?.firstName}{' '}
                            {getPrimaryCustomerContact(fromCustomer)?.lastName}
                        </span>
                        <span>Company Name: {fromCustomer.companyName}</span>
                        <span>Account Number: {fromCustomer.customerNumber}</span>
                    </div>

                    <h2 className="flex justify-between border-b text-lg font-bold">
                        To Customer
                        {toCustomer && (
                            <Button
                                onClick={() => {
                                    setToCustomer(null);
                                }}
                                disabled={isLoading}
                            >
                                <XMarkIcon className="h-4 w-4" />
                            </Button>
                        )}
                    </h2>

                    {toCustomer ? (
                        <div className="mb-2 flex flex-col border-b">
                            <span>
                                Primary Contact: {getPrimaryCustomerContact(toCustomer)?.firstName}{' '}
                                {getPrimaryCustomerContact(toCustomer)?.lastName}
                            </span>
                            <span>Company Name: {toCustomer.companyName}</span>
                            <span>Account Number: {toCustomer.customerNumber}</span>
                        </div>
                    ) : (
                        <div className="my-2">
                            <SourSearchOld
                                options={{
                                    placeholder: 'Search for a customer',
                                    searchPopoverFixed: false,
                                    showTips: false,
                                    showMessages: false,
                                    resultSize: 'w-[550px]',
                                    searchPositionFixed: true,
                                }}
                            />
                        </div>
                    )}

                    <Checkbox
                        label={`Delete Customer ${
                            fromCustomer.companyName ||
                            getPrimaryCustomerContact(fromCustomer)?.firstName + ' ' + getPrimaryCustomerContact(fromCustomer)?.lastName
                        } and migrate all orders.`}
                        inputProps={{
                            checked: deleteFromCustomer,
                            onChange: (e) => setDeleteFromCustomer(e.target.checked),
                        }}
                    />

                    {deleteFromCustomer ? (
                        <span className="text-red-500">
                            Warning: This will delete the customer from the system and move all orders to "
                            {toCustomer ? toCustomer.companyName || getPrimaryCustomerContact(toCustomer)?.firstName : 'to customer'}
                            ".
                        </span>
                    ) : (
                        <span className="text-red-500">
                            Warning: This will only move order #{moveOrder?.orderNumber} to the "
                            {toCustomer ? toCustomer.companyName || getPrimaryCustomerContact(toCustomer)?.firstName : 'to customer'}
                            ".
                        </span>
                    )}

                    <div className="flex w-full justify-end">
                        <Button className="btn-primary" onClick={handleSave} disabled={!toCustomer} loading={isLoading}>
                            Merge
                        </Button>
                    </div>
                </div>
            </SourSearchWrapperOld>
        </Dialog>
    );
};
