import { Hauler, Pricing } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';
import { CreateUniversalPricingFormProps } from '../HaulerPricingCreate';
import { HaulerPricingFormUniversal } from '../HaulerPricingForm/HaulerPricingFormUniversal';

interface HaulerPricingProductUpdateDialogProps {
    open: boolean;
    onBackButtonClick: (data: { status: string | number }) => void;
    isLoading: boolean;
    hauler: Hauler.HaulerWithAapTransport;
    pricing: Pricing.ProductPricingZoneTransport;
    onSubmit: (data: CreateUniversalPricingFormProps) => Promise<void>;
}

const HaulerPricingProductUpdate = ({
    pricing,
    open,
    onBackButtonClick,
    onSubmit,
    isLoading,
    hauler,
}: HaulerPricingProductUpdateDialogProps) => {
    return (
        <Dialog
            styledTitle="Update Vendor Pricing Zone (Universal)"
            onClose={() => onBackButtonClick({ status: 'cancel' })}
            open={open}
            className="max-w-xl"
        >
            <HaulerPricingFormUniversal
                pricing={pricing}
                onSubmit={onSubmit}
                onCancel={onBackButtonClick}
                isLoading={isLoading}
                hauler={hauler}
            />
        </Dialog>
    );
};

export default HaulerPricingProductUpdate;
