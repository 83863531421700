import React, { useEffect, useState } from 'react';
import { Route, RouteComponentProps, Switch, useLocation, withRouter } from 'react-router-dom';
import Four0Four from '../components/404';
import Dashboard from '../components/Dashboard';
import GlobalSearch from '../components/GlobalSearch/GlobalSearch';
import HaulerCreate from '../components/HaulerCreate';
import InternalTicketWorkflow from '../components/InternalTicketWorkflow';
import LeadCreate from '../components/LeadCreate';
import OrderCreate from '../components/OrderCreate';
import OrderList from '../components/OrderList';
import OrderReview from '../components/OrderReview';
import PricingCreate from '../components/PricingCreate';
import PricingList from '../components/PricingList';
import Reports from '../components/Reports';
import AccountManagerProjectBoard from '../pages/account-manager-project-board/account-manager-project-board';
import Settings from '../pages/account-settings/Settings';
import GoogleAuthCallback from '../pages/account-settings/pages/GoogleAdsCallback';
import GoogleAdsSettings from '../pages/account-settings/pages/GoogleAdsSettings';
import QuickBooksCallback from '../pages/account-settings/pages/QuickBooksCallback';
import QuickBooksMapping from '../pages/account-settings/pages/QuickBooksMapping';
import ServicesCreate from '../pages/account-settings/pages/ServiceCreate';
import ServicesUpdate from '../pages/account-settings/pages/ServiceUpdate';
import ServicesList from '../pages/account-settings/pages/ServicesList';
import TagManagement from '../pages/account-settings/pages/TagManagement';
import { UserList } from '../pages/account-settings/pages/UserManagement';
import Coupons from '../pages/account-settings/pages/coupons';
import { BillingPage } from '../pages/billing';
import CustomerDetails from '../pages/customers/customer-details';
import HaulerList from '../pages/hauler-list';
import LeadDetails from '../pages/leads/lead-details';
import LeadList from '../pages/leads/lead-list/Leads';
import MarketingTextBlast from '../pages/marketing/text-blast';
import OrderDetails from '../pages/orders/order-details';
import PricingDetails from '../pages/pricing-details';
import AwardRFP from '../pages/rfp/award-rfp';
import RFPCreate from '../pages/rfp/rfp-create';
import RFPDetails from '../pages/rfp/rfp-details';
import RFPList from '../pages/rfp/rfp-list';
import RFPUpdate from '../pages/rfp/rfp-update';
import RFPVendorSelect from '../pages/rfp/rfp-vendor-select';
import SubscriptionServiceOrderAdd from '../pages/site-services/AddServiceOrderToSubscription/SubscriptionServiceOrderAdd';
import ServiceGroupingDetails from '../pages/site-services/ServiceGroupingDetails/service-grouping-details';
import ServiceOrderDetails from '../pages/site-services/ServiceOrderDetails/ServiceOrderDetails';
import UniversalServiceCreate from '../pages/site-services/UniversalServiceCreate';
import UniversalServicesList from '../pages/site-services/UniversalServicesList/UniversalServicesList';
import OneTimeServiceOrderUpdate from '../pages/site-services/UpdateOneTimeServiceOrder/OneTimeServiceOrderUpdate';
import SubscriptionDetailsUpdate from '../pages/site-services/UpdateSubscription/SubscriptionDetailsUpdate';
import SubscriptionServiceOrderUpdate from '../pages/site-services/UpdateSubscriptionServiceOrder/SubscriptionServiceOrderUpdate';
import SubscriptionServiceOrderPricingUpdate from '../pages/site-services/UpdateSubscriptionServiceOrderPricing/SubscriptionServiceOrderPricingUpdate';
import HaulerDetails from '../pages/vendors/vendor-details';
import routes from '../utils/routes';

interface RoutesProps {
    isAppAdmin: boolean;
}

const Routes: React.FC<RoutesProps & RouteComponentProps> = ({ isAppAdmin }) => {
    const location = useLocation<{ modal?: boolean }>();
    const [previousLocation, setPreviousLocation] = useState(location);

    useEffect(() => {
        if (!(location.state && location.state.modal)) {
            setPreviousLocation(location);
        }
    }, [location]);

    const isModal = location.state && location.state.modal && previousLocation !== location;
    return (
        <>
            <Switch location={isModal ? previousLocation : location}>
                {/* Dashboard */}
                <Route exact path={routes.dashboard} component={Dashboard} />
                {/* Billing */}
                <Route exact path={routes.billing.list} component={BillingPage} />
                {/* Customers */}
                <Route exact path={routes.customers.details(':id')} component={CustomerDetails} />
                {/* Haulers */}
                <Route exact path={routes.haulers.list} component={HaulerList} />
                <Route exact path={routes.haulers.details(':id')} component={HaulerDetails} />
                <Route exact path={routes.haulers.new} component={HaulerCreate} />
                {/* Leads */}
                <Route exact path={routes.leads.list} component={LeadList} />
                <Route exact path={routes.leads.create} component={LeadCreate} />
                <Route exact path={routes.leads.details(':id')} component={LeadDetails} />
                {/* Orders */}
                <Route exact path={routes.orders.list} component={OrderList} />
                <Route exact path={routes.orders.new} component={OrderCreate} />
                <Route exact path={routes.orders.review(':id')} component={OrderReview} />
                <Route exact path={routes.orders.details(':id')} component={OrderDetails} />
                {/* Pricing */}
                <Route exact path={routes.pricing.list} component={PricingList} />
                <Route exact path={routes.pricing.details(':id')} component={PricingDetails} />
                <Route exact path={routes.pricing.new} component={PricingCreate} />
                {/* Reports */}
                <Route exact path={routes.reports} component={Reports} />
                {/* Search */}
                <Route exact path={routes.search} component={GlobalSearch} />
                {/* Universal Services */}
                <Route exact path={routes.universalServices.new} component={UniversalServiceCreate} />
                <Route exact path={routes.universalServices.list} component={UniversalServicesList} />
                {/* Universal Services/ServiceGrouping */}
                <Route
                    exact
                    path={routes.universalServices.serviceGrouping.details(':serviceGroupingId')}
                    component={ServiceGroupingDetails}
                />
                <Route
                    exact
                    path={routes.universalServices.serviceGrouping.addOrder(':serviceGroupingId')}
                    component={SubscriptionServiceOrderAdd}
                />
                {/* Universal Services/ServiceGrouping/Subscription */}
                <Route
                    exact
                    path={routes.universalServices.serviceGrouping.subscription.update(':serviceGroupingId', ':serviceOrderId')}
                    component={SubscriptionServiceOrderUpdate}
                />
                <Route
                    exact
                    path={routes.universalServices.serviceGrouping.subscription.pricing(':serviceGroupingId', ':serviceOrderId')}
                    component={SubscriptionServiceOrderPricingUpdate}
                />
                <Route
                    exact
                    path={routes.universalServices.serviceGrouping.subscription.subscriptionUpdate(':serviceGroupingId', ':serviceOrderId')}
                    component={SubscriptionDetailsUpdate}
                />
                {/* Universal Services/ServiceOrder */}
                <Route
                    exact
                    path={routes.universalServices.serviceGrouping.serviceOrder.update(':serviceGroupingId', ':serviceOrderId')}
                    component={OneTimeServiceOrderUpdate}
                />
                <Route
                    exact
                    path={routes.universalServices.serviceGrouping.serviceOrder.details(':serviceGroupingId', ':serviceOrderId')}
                    component={ServiceOrderDetails}
                />
                {/* Universal Services/Services */}
                {isAppAdmin && <Route exact path={routes.universalServices.services.list} component={ServicesList} />}
                {isAppAdmin && (
                    <Route exact path={routes.universalServices.services.details(':serviceFamilyId')} component={ServicesUpdate} />
                )}
                {isAppAdmin && <Route exact path={routes.universalServices.services.new} component={ServicesCreate} />}
                {/* Internal Tickets */}
                <Route exact path={routes.internalTickets.list} component={InternalTicketWorkflow} />
                {/* RFP */}
                <Route path={routes.requestForProposal.new} component={RFPCreate} />
                <Route exact path={routes.requestForProposal.details(':id')} component={RFPDetails} />
                <Route exact path={routes.requestForProposal.list} component={RFPList} />
                <Route exact path={routes.requestForProposal.update(':id')} component={RFPUpdate} />
                <Route exact path={routes.requestForProposal.vendorSelect(':id')} component={RFPVendorSelect} />
                <Route exact path={routes.requestForProposal.awardRFP(':id')} component={AwardRFP} />
                {/* Marketing */}
                <Route exact path={routes.marketing.textBlast} component={MarketingTextBlast} />
                {/* Account Manger Views */}
                <Route exact path={routes.accountManagerViews.projectBoard} component={AccountManagerProjectBoard} />
                {/* Settings */}
                <Route exact path={routes.accountSettings.list} component={Settings} />
                <Route exact path={routes.accountSettings.googleAds} component={GoogleAdsSettings} />
                <Route exact path={routes.accountSettings.googleAuthCallback} component={GoogleAuthCallback} />
                <Route exact path={routes.accountSettings.quickBooksMapping} component={QuickBooksMapping} />
                <Route exact path={routes.accountSettings.quickBooksCallback} component={QuickBooksCallback} />
                <Route exact path={routes.accountSettings.tagManagement} component={TagManagement} />
                <Route exact path={routes.accountSettings.coupons} component={Coupons} />
                <Route exact path={routes.accountSettings.users} component={UserList} />
                {/* Universal Services */}
                {/* <Route exact path={routes.csaReport} component={CSAReport} /> */}
                {/* 404 */}
                <Route component={Four0Four} />
            </Switch>
            {isModal ? (
                <>
                    <Route exact path={routes.orders.details(':id')}>
                        <OrderDetails isModal />
                    </Route>
                    <Route exact path={routes.leads.create}>
                        <LeadCreate isModal />
                    </Route>
                    <Route exact path={routes.leads.details(':id')}>
                        <LeadDetails isModal />
                    </Route>
                    <Route exact path={routes.haulers.details(':id')}>
                        <HaulerDetails isModal />
                    </Route>
                </>
            ) : null}
        </>
    );
};

export default withRouter(Routes);
