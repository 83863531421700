import React, { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Pricing } from '@alliance-disposal/transport-types';
import { MapEditorModal, MapEditorSubmit, ServiceAreasMap } from '@wayste/sour-components';
import { useSourContext } from '@wayste/sour-context';
import { Button } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import MaterialPricingDialog from '../../components/MaterialPricingDialog';
import PricingUpdate from '../../components/PricingUpdate';
import { growthStageTypes, onlineGuaranteeTypes, operatingDayTypes, routes } from '../../utils';
import { MaterialPricingDetails } from './components/material-pricing-details';
import TierPricingDetails from './components/tier-pricing-details';

const PricingDetails = () => {
    const history = useHistory();
    const client = useWaysteClient();
    const { id } = useParams() as { id?: string };
    const { godModeActive, setShowToast, useConfirmationDialog } = useSourContext();
    const { getConfirmation } = useConfirmationDialog();
    const [pricing, setPricing] = useState<Pricing.PricingTransport | null>(null);
    const [showUpdatePricing, setShowUpdatePricing] = useState(false);
    const [showMaterialPricingDialog, setShowMaterialPricingDialog] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState<Pricing.PricingTransport['pricingData'][0] | undefined>(undefined);
    const [editServiceArea, setEditServiceArea] = useState(false);

    const handleGetPricing = async () => {
        if (!id) return;
        const data = await client.pricing().adminPortal.pricing.fetch(id);
        setPricing(data);
    };

    useEffect(() => {
        if (id) {
            handleGetPricing();
        }
    }, [id]);

    const handleBackButtonClick = () => {
        const pathParam = pricing?.state ? `?state=${pricing.state}` : '';
        history.push(`${routes.pricing.list}${pathParam}`);
    };

    const handleMaterialPricingClose = (status?: 'success' | 'error') => {
        setShowMaterialPricingDialog(false);
        setSelectedMaterial(undefined);
        if (status === 'success') {
            handleGetPricing();
        }
    };

    const handlePricingUpdateClose = (status?: 'success' | 'error') => {
        setShowUpdatePricing(false);
        if (status === 'success') {
            handleGetPricing();
        }
    };

    const handleServiceAreaSubmit = async (data: MapEditorSubmit[]) => {
        //if (!data || !pricingZoneData?.id) return;
        const addServiceAreaPayload: Pricing.ServiceAreaMutate[] = data?.map((x) => {
            const serviceArea = {
                boundary: x.serviceArea,
                boundaryFormat: x.serviceAreaFormat,
                areaName: x.areaName,
                taxJurisdiction: x.taxJurisdiction,
            };
            return serviceArea;
        });
        if (pricing) {
            try {
                await client.pricing().adminPortal.serviceArea.update(pricing.id, addServiceAreaPayload);
                await handleGetPricing();
                setShowToast({ message: 'Service Area Updated', severity: 'success' });
            } catch (error) {
                setShowToast({ message: 'Error updating service area', severity: 'error' });
            }
        }
    };

    const handleDeletePricing = async () => {
        if (!godModeActive || !id) return;
        const confirmed = await getConfirmation({
            title: 'Delete Pricing Zone',
            message: 'Are you sure you want to delete this pricing zone?',
            confirmText: 'Confirm',
            cancelText: 'Cancel',
        });
        if (confirmed) {
            try {
                await client.pricing().adminPortal.pricing.delete(id);
                setShowToast({ message: 'Pricing Zone Deleted', severity: 'success' });
                history.push(routes.pricing.list);
            } catch (error) {
                setShowToast({ message: 'Error deleting pricing zone', severity: 'error' });
            }
        }
    };

    if (!pricing) return <Loading />;

    const allowOnlineCheckout = pricing.pricingData.some((material) => material.allowOnlineCheckout);

    const details = [
        { label: 'State', value: pricing.state },
        { label: 'Rental Period', value: pricing.rentalPeriod?.value },
        {
            label: 'Rental Extension',
            value: pricing.rentExtensionFee ? moneyFormatter(pricing.rentExtensionFee) : '',
        },
        { label: 'Max Rental Period', value: pricing.maxRentalDaysAllowed },
        {
            label: 'Trip Charge',
            value: pricing.tripCharge ? moneyFormatter(pricing.tripCharge) : '',
        },
        {
            label: 'Last Updated',
            value: format(new Date(pricing.metadata?.lastUpdatedAt), 'MM/dd/yy'),
        },
        {
            label: 'Growth Stage',
            value: growthStageTypes[pricing.currentGrowthStage as keyof typeof growthStageTypes],
        },
        { label: 'Metro', value: pricing.metro },
    ];
    const orderOnlineDetails = [
        // TODO allowOnlineCheckout needs to be moved to the material level
        {
            label: 'Order Materials Online',
            value: allowOnlineCheckout ? 'Yes' : 'No',
        },
        {
            label: 'Checkout Page Guarantee',
            value: onlineGuaranteeTypes[pricing.onlineCheckoutPageGuarantee as keyof typeof onlineGuaranteeTypes],
        },
        {
            label: 'Allowed to Pre-Purchase Rental Days',
            value: pricing.allowForPrePaidRentalDays ? 'Yes' : 'No',
        },
        {
            label: 'Pre-Purchase Rental Days Discount Rate',
            value: `${pricing?.prePaidRentalDaysDiscountRate ? pricing?.prePaidRentalDaysDiscountRate / 100 : 0}%`,
        },
    ];

    return (
        <div className="flex flex-1 flex-col gap-y-6 bg-[#F9F9F9]">
            <div className="bg-main-sidebar flex items-center gap-4 px-5 pb-4 pt-8">
                <Button className="btn-dark-grey-outlined" onClick={handleBackButtonClick} startIcon={<ChevronLeftIcon />}>
                    Back
                </Button>
                <h6 className="text-2xl font-semibold leading-normal tracking-wide text-zinc-700">{pricing.zoneName} Pricing</h6>
            </div>
            <div className="flex flex-col gap-y-6 px-5 pb-12">
                <ServiceAreasMap
                    pricingZones={[pricing]}
                    editButtonClicked={() => {
                        setEditServiceArea(true);
                    }}
                />

                <div className="flex flex-col gap-y-3">
                    <div className="flex items-center justify-between">
                        <div className="flex items-end gap-x-1.5 px-4 text-gray-800">
                            <div className="text-xl font-semibold leading-7 tracking-tight">{pricing.zoneName} Zone Details</div>
                        </div>
                        <div className="flex items-center gap-x-4">
                            {godModeActive && (
                                <Button endIcon={<TrashIcon className="size-4" />} onClick={handleDeletePricing} className="btn-delete">
                                    Delete Pricing Zone
                                </Button>
                            )}
                            <Button
                                endIcon={<HiOutlinePencilAlt className="size-4" />}
                                onClick={() => setShowUpdatePricing(true)}
                                className="btn-primary"
                            >
                                Edit Pricing
                            </Button>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-4 overflow-auto rounded-lg border border-gray-300 bg-[#fcfcfd] p-4 text-sm shadow">
                        <div className="grid grid-cols-1 gap-2 lg:grid-cols-3">
                            <div className="h-full lg:border-r lg:pr-4">
                                <div className="grid h-fit grid-cols-3 gap-2">
                                    {details.map((item) => (
                                        <React.Fragment key={item.label}>
                                            <div>
                                                <span className="text-gray-500">{item.label}:</span>
                                            </div>
                                            <div className="col-span-2">{item.value}</div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                            <div className="h-full lg:border-r lg:pr-4">
                                <div className="flex h-fit flex-col gap-2">
                                    <div className="font-semibold text-gray-800">Online Checkout Options</div>
                                    <div className="grid h-fit grid-cols-3 gap-2">
                                        {orderOnlineDetails.map((item) => (
                                            <React.Fragment key={item.label}>
                                                <div className="text-gray-500">{item.label}</div>
                                                <div className="col-span-2">{item.value}</div>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="flex h-full flex-col gap-y-4">
                                <div className="flex h-fit flex-col gap-2">
                                    <div className="font-semibold text-gray-800">Operating Days</div>
                                    <div className="flex flex-wrap">
                                        {Object.keys(pricing.days).map((key) => (
                                            <div key={key} className="mr-2.5">
                                                <div className="mb-0.5 capitalize text-gray-500">{key}</div>
                                                <div>
                                                    {operatingDayTypes[
                                                        pricing.days[key as keyof typeof pricing.days] as keyof typeof operatingDayTypes
                                                    ] || '?'}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex h-fit flex-col gap-2">
                                    <div className="font-semibold text-gray-800">Prohibited Items</div>
                                    <div className="flex flex-wrap">{pricing.prohibitedItems.join(', ')}</div>
                                </div>
                                <div className="flex h-fit flex-col gap-2">
                                    <div className="font-semibold text-gray-800">Zone Notes</div>
                                    <div className="flex flex-wrap">{pricing?.notes}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MaterialPricingDetails pricing={pricing} onUpdated={handleGetPricing} />
                <TierPricingDetails pricing={pricing} />
            </div>
            {/* Modals */}
            <MapEditorModal
                open={editServiceArea}
                googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
                serviceAreas={pricing?.serviceAreas}
                onClose={() => {
                    setEditServiceArea(false);
                }}
                onSubmit={handleServiceAreaSubmit}
            />
            <PricingUpdate open={showUpdatePricing} onBackButtonClick={handlePricingUpdateClose} pricing={pricing} />
            <MaterialPricingDialog
                open={showMaterialPricingDialog}
                material={selectedMaterial}
                zone={pricing}
                onBackButtonClick={handleMaterialPricingClose}
                isCopying={selectedMaterial && selectedMaterial.id === undefined}
            />
        </div>
    );
};

export default PricingDetails;
