import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { S3ItemReference, UniversalService } from '@alliance-disposal/transport-types';
import { formatISODateString, formatServiceAddress, formatTimestamp } from '@wayste/utils';
import { PencilIcon, PhotoIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import CardInfoList from '../../../../components/CardInfoList';
import DetailsCardWrapper from '../../../../components/DetailsCardWrapper';
import FileHandler from '../../../../components/FileHandler/FileHandler';
import { orderImageTypes, routes } from '../../../../utils';
import InternalNotes from '../../components/InternalNotes';
import UniversalServicesStatusPopper from '../../components/UniversalServicesStatusPopper';

interface SubscriptionServiceDetailsCardProps {
    serviceOrder: UniversalService.ServiceOrder;
    serviceGrouping: UniversalService.ServiceGrouping;
    lastUpdatedBy?: string;
    createdBy?: string;
    vendor?: string;
}

interface cardData {
    label: string;
    value: React.ReactNode;
}
// TODO: Add Issues.
const SubscriptionServiceDetailsCard = ({
    serviceOrder,
    serviceGrouping,
    lastUpdatedBy,
    createdBy,
    vendor,
}: SubscriptionServiceDetailsCardProps) => {
    const history = useHistory();
    const client = useWaysteClient();
    const [showImages, setShowImages] = useState<boolean>(false);
    const [images, setImages] = useState<S3ItemReference[] | undefined>(serviceOrder.images);
    const getServiceDays = (serviceDays: number[]) => {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return serviceDays.map((day) => days[day]).join(', ');
    };

    const handleEditServiceClick = () => {
        history.push(routes.universalServices.serviceGrouping.subscription.update(serviceOrder.serviceGroupingID, serviceOrder.id));
    };

    const onFileUpload = async (file: S3ItemReference) => {
        const orderUpdate: UniversalService.ServiceOrderUpdate = {
            images: [...(images || []), file],
        };
        await client.universalService().serviceGrouping.serviceOrder.update(serviceOrder.serviceGroupingID, serviceOrder.id, orderUpdate);
        setImages(orderUpdate.images);
    };

    const onFilesEdit = async (files: S3ItemReference[]) => {
        const orderUpdate: UniversalService.ServiceOrderUpdate = {
            images: files,
        };
        await client.universalService().serviceGrouping.serviceOrder.update(serviceOrder.serviceGroupingID, serviceOrder.id, orderUpdate);
        setImages(orderUpdate.images);
    };

    const colOne: cardData[] = [
        {
            label: 'SKU Name',
            value: serviceOrder.serviceType.name,
        },
        {
            label: 'PO Number',
            value: serviceOrder.poNumber,
        },
        {
            label: 'Service Address',
            value: formatServiceAddress(serviceOrder.serviceLocation?.address),
        },
        {
            label: 'County',
            value: serviceOrder.serviceLocation?.county,
        },
        {
            label: 'Subscription Frequency',
            value: serviceOrder.serviceFrequency
                ? serviceOrder.serviceFrequency + ' x per ' + serviceOrder.serviceFrequencyUnit
                : 'Not Applicable',
        },
        {
            label: 'Service Days',
            value: serviceOrder.serviceFrequency ? getServiceDays(serviceOrder.serviceDays) : 'Not Applicable',
        },
        {
            label: 'Vendor Contract Term',
            value: serviceGrouping.contractDetails?.contractTerm || '',
        },
        {
            label: 'Vendor Contract Start Date',
            value: formatISODateString(serviceGrouping.contractDetails?.contractStartDate, undefined, ''),
        },
        {
            label: 'Vendor Contract End Date',
            value: formatISODateString(serviceGrouping.contractDetails?.contractEndDate, undefined, ''),
        },
    ];
    const colTwo: cardData[] = [
        {
            label: 'Service Period Start Date',
            value: formatISODateString(serviceOrder.startDate, undefined, ''),
        },
        {
            label: 'Service Period End Date',
            value: formatISODateString(serviceOrder.endDate, undefined, ''),
        },
        {
            label: 'Delivery Date',
            value: formatISODateString(serviceOrder.deliveryDate, undefined, ''),
        },
        {
            label: 'Delivery Notes',
            value: serviceOrder.deliveryNotes,
        },
        {
            label: 'Removal Date',
            value: formatISODateString(serviceOrder.removalDate, undefined, ''),
        },
        {
            label: 'Removal Notes',
            value: serviceOrder.removalNotes,
        },
        {
            label: 'Vendor Only Notes',
            value: serviceOrder.vendorOnlyNotes,
        },
        {
            label: 'Internal Notes',
            value: <InternalNotes serviceGrouping={serviceGrouping} serviceOrderID={serviceOrder.id} />,
        },
    ];
    const colThree: cardData[] = [
        {
            label: 'Order #',
            value: serviceOrder.fullOrderNumber,
        },
        {
            label: 'Needs Attention',
            value: serviceOrder.needsAttention ? 'Yes' : 'No',
        },
        {
            label: 'Status',
            value: <UniversalServicesStatusPopper serviceOrder={serviceOrder} />,
        },
        {
            label: 'Vendor',
            value: serviceOrder.vendorName || vendor || '',
        },
        {
            label: 'Issues',
            value: '',
        },
        {
            label: 'Created By',
            value: createdBy,
        },
        {
            label: 'Created Date',
            value: formatTimestamp(serviceOrder.metadata.createdAt),
        },
        {
            label: 'Last Updated By',
            value: lastUpdatedBy,
        },
        {
            label: 'Last Updated Date',
            value: formatTimestamp(serviceOrder.metadata.lastUpdatedAt),
        },
    ];
    return (
        <DetailsCardWrapper
            heading="Service Details"
            buttons={[
                {
                    label: (
                        <>
                            <PencilIcon className="mr-1 size-5" /> Edit Service
                        </>
                    ),
                    onClick: handleEditServiceClick,
                },
                {
                    label: (
                        <>
                            <PhotoIcon className="mr-1 size-5" /> Service Image
                        </>
                    ),
                    onClick: () => setShowImages(true),
                },
            ]}
        >
            <FileHandler
                files={images || []}
                folderName={`order${serviceOrder.fullOrderNumber?.toString() ?? ''}/`}
                types={orderImageTypes}
                open={showImages}
                onCancel={() => setShowImages(false)}
                onFileUpload={onFileUpload}
                onFilesEdit={onFilesEdit}
            />
            <div className={'grid grid-cols-1 gap-4 lg:grid-cols-3'}>
                <CardInfoList data={colOne} border />
                <CardInfoList data={colTwo} border />
                <CardInfoList data={colThree} />
            </div>
        </DetailsCardWrapper>
    );
};

export default SubscriptionServiceDetailsCard;
