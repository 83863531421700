import { useEffect, useState } from 'react';
import { useIsAppAdmin, useWaysteClient } from '@alliance-disposal/client';
import { Profile } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Loading } from '@wayste/sour-ui';
import { formatE164ToUSPhoneNumber } from '@wayste/utils';
import { CheckBadgeIcon, XCircleIcon } from '@heroicons/react/24/solid';
import CreateUserModal from './create-user-modal';

const UserList = () => {
    const [addUserModalOpen, setAddUserModalOpen] = useState(false);
    const [roster, setRoster] = useState<Profile.ProfileTransport[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const client = useWaysteClient();
    const { isAdmin } = useIsAppAdmin();

    const { setShowToast } = useSourContext();
    const fetchRoster = async () => {
        try {
            setIsLoading(true);
            const response = await client.profile().adminPortal.fetchRoster();
            setRoster(response);
        } catch (error) {
            console.error(error);
            setShowToast({
                message: 'Failed to fetch user list',
                severity: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const toHumanReadableFormat = (str: string) => {
        return str
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    const handleUserModalClosed = (submitted: boolean) => {
        if (submitted) {
            fetchRoster();
        }
        setAddUserModalOpen(false);
    };

    useEffect(() => {
        fetchRoster();
    }, []);

    if (isLoading) {
        return <Loading fullScreen />;
    }

    return (
        <div className="flex flex-col p-8">
            <div className="mb-2 flex items-center justify-between">
                <h1 className="mb-1 text-2xl font-semibold">User List</h1>
                {isAdmin && (
                    <Button className="btn-primary" onClick={() => setAddUserModalOpen(true)}>
                        Add User
                    </Button>
                )}
            </div>
            <hr />
            <div className="my-4 flex flex-col gap-4">
                {roster
                    .sort((a, b) => {
                        if (a.active === b.active) {
                            if (a.firstName && b.firstName) {
                                return a.firstName.localeCompare(b.firstName);
                            } else if (a.firstName) {
                                return -1;
                            } else if (b.firstName) {
                                return 1;
                            }
                            return 0;
                        }
                        return a.active ? -1 : 1;
                    })
                    .map((profile) => (
                        <div key={profile.id} className="rounded-lg border bg-white p-4 shadow-md">
                            <div className="mb-2 flex items-center gap-2">
                                <h3 className="text-xl font-semibold">
                                    {profile.firstName} {profile.lastName}
                                </h3>
                                {profile.active ? (
                                    <CheckBadgeIcon className="size-5 text-green-500" />
                                ) : (
                                    <XCircleIcon className="size-5 text-red-500" />
                                )}
                            </div>

                            <div className="mt-2 grid grid-cols-[auto_1fr] gap-3">
                                <div className="col-span-2 grid grid-cols-subgrid items-center">
                                    <span className="font-medium">Email:</span> {profile.email || 'N/A'}
                                </div>
                                <div className="col-span-2 grid grid-cols-subgrid items-center">
                                    <span className="font-medium">Phone:</span>{' '}
                                    {profile.phoneNumber ? formatE164ToUSPhoneNumber(profile.phoneNumber) : 'N/A'}
                                </div>
                                <div className="col-span-2 grid grid-cols-subgrid items-center">
                                    <span className="font-medium">Roles:</span>
                                    <div className="flex flex-wrap gap-2">
                                        {profile.authorities
                                            ?.flatMap((authority) => authority.roles)
                                            .sort()
                                            .map((role) => (
                                                <span key={role} className="rounded-md bg-gray-200 px-2 py-1 text-sm">
                                                    {toHumanReadableFormat(role)}
                                                </span>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <CreateUserModal isOpen={addUserModalOpen} onClose={handleUserModalClosed} />
        </div>
    );
};

export default UserList;
