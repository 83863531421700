import { useEffect, useState } from 'react';
import { Hauler, Material, Order, Pricing } from '@alliance-disposal/transport-types';
import { WaysteActivity } from '@wayste/utils';
import { type StatusBucket, getVendorOrderStats } from '../../utils/';
import type { HaulerPricingWithHauler } from '../OrderStatusChanger/OrderAssignHauler';
import { OtherVendorDialog } from './OtherVendorDialog';
import { SpreadTableRow } from './SpreadTableRow';

export type VendorPricingRow = Pricing.PricingTransport & {
    hauler: Hauler.HaulerWithAapTransport;
    waysteUsage: WaysteActivity;
    orderCount?: {
        statusBreakdown: StatusBucket[];
        total: number;
    };
    lastOrder?: Order.AllianceOrderTransport;
    rowRates?: {
        materialData: Pricing.PricingDataTransport;
        sizeData: {
            dump?: number | null | undefined;
            over?: number | null | undefined;
            haul?: number | undefined;
            tonLimit?: number | null | undefined;
            size: string;
        };
        haul: number | undefined;
        dump: number;
        overage: number;
        estProfit: number;
    };
};

interface Props {
    haulerWithPricing: HaulerPricingWithHauler[];
    sourgumPricing: {
        haul: number;
        dump: number;
        tonLimit: number;
        overage: number;
    };
    selectedMaterial: Material;
    selectedSize?: string | number;
    onRowClick?: (haulerPricing: VendorPricingRow, pricingSnapshot?: Order.VendorPricingSnapshotTransportCreate) => void;
    selectedHauler?: string;
    orderID?: string;
}

const SpreadsTable = ({
    haulerWithPricing,
    sourgumPricing,
    selectedMaterial,
    selectedSize,
    onRowClick,
    selectedHauler,
    orderID,
}: Props) => {
    const [haulerList, setHaulerList] = useState<VendorPricingRow[]>([]);
    const [open, setOpen] = useState(false);
    const [selectedHaulerID, setSelectedHaulerID] = useState<string | undefined>(undefined);
    const [recommendedVendor, setRecommendedVendor] = useState<VendorPricingRow | undefined>(undefined);

    const fetchHaulerOrderStats = async (haulersData: VendorPricingRow[]) => {
        try {
            const allHaulerOrderStats = await getVendorOrderStats(haulersData.map((haulerPricing) => haulerPricing.hauler.id));
            return haulersData.map((haulerData) => ({
                ...haulerData,
                ...allHaulerOrderStats[haulerData.hauler.id],
            }));
        } catch (error) {
            console.error('Error fetching hauler order stats', error);
            return haulersData;
        }
    };

    const processPricingData = (): Order.VendorPricingRow[] => {
        const pricingData = haulerList.flatMap((vendorPricingRow) => {
            if (vendorPricingRow.rowRates && vendorPricingRow.rowRates.haul) {
                return {
                    vendorID: vendorPricingRow.id,
                    vendorName: vendorPricingRow.haulerName ? vendorPricingRow.haulerName : '',
                    priceType: vendorPricingRow?.rowRates?.materialData?.doesNotService
                        ? 'Does not service'
                        : vendorPricingRow?.rowRates?.materialData?.type,
                    haulRate: vendorPricingRow.rowRates.sizeData.haul || 0,
                    haulSpread: sourgumPricing?.haul - vendorPricingRow.rowRates.haul,
                    dumpRate: vendorPricingRow.rowRates.sizeData.dump ? vendorPricingRow.rowRates.sizeData.dump : 0,
                    dumpSpread: sourgumPricing?.dump - +vendorPricingRow.rowRates.dump,
                    overageSpread: sourgumPricing?.overage - vendorPricingRow.rowRates.overage,
                    usingWayste: Boolean(vendorPricingRow.waysteUsage),
                    waysteLite: vendorPricingRow.hauler.waysteLite,
                    activeOrderCount: vendorPricingRow.orderCount?.total || 0,
                    lastOrderDate: vendorPricingRow.lastOrder?.metadata.createdAt,
                    lastOrderID: vendorPricingRow.lastOrder?.id,
                    pricingZoneID: vendorPricingRow.id,
                    materialID: vendorPricingRow.rowRates.materialData.id,
                } satisfies Order.VendorPricingRow;
            }
            return [];
        });
        return pricingData;
    };

    const calculateEstimatedProfit = (sizeData?: HaulerPricingWithHauler['pricingData'][0]['sizes'][0]) => {
        if (!sizeData) return null;
        const haulSpread = sourgumPricing?.haul - (sizeData.haul || 0);
        const tonLimitDelta = sourgumPricing?.tonLimit - (sizeData.tonLimit || 0);
        const haulerAdjTonLimit = tonLimitDelta >= 0 ? sourgumPricing?.tonLimit : sizeData.tonLimit;
        const estProfit = haulSpread + (sourgumPricing.dump * sourgumPricing.tonLimit - (sizeData.dump || 0) * (haulerAdjTonLimit || 0));
        return estProfit;
    };

    const processHaulerPricing = (haulerPricingData: VendorPricingRow[], selectedMaterial?: Material, selectedSize?: string | number) => {
        let processedHaulerData: VendorPricingRow[] = [];

        if (!selectedMaterial || !selectedSize) {
            processedHaulerData = haulerPricingData.map((hauler) => {
                //eslint-disable-next-line @typescript-eslint/no-unused-vars -- removing rowRates from the hauler object
                const { rowRates, ...haulerWithoutRowRates } = hauler;
                return haulerWithoutRowRates;
            });
        } else {
            processedHaulerData = haulerPricingData.map((hauler) => {
                let rowRates = undefined;
                const materialData = hauler.pricingData.find((item) => item.material === selectedMaterial);
                if (materialData && !materialData.doesNotService) {
                    const sizeData = materialData.sizes.find((size) => +size.size === +selectedSize); // + is unary operator to convert to number
                    if (sizeData) {
                        const profit = calculateEstimatedProfit(sizeData);
                        if (profit !== null) {
                            rowRates = {
                                materialData,
                                sizeData,
                                haul: sizeData.haul,
                                dump: sizeData.dump || 0,
                                overage: sizeData.over || sizeData.dump || 0,
                                estProfit: profit,
                            };
                            // If they are using wayste and are profitable, add $10 to their profit
                            if (Boolean(hauler.waysteUsage) && rowRates.estProfit > 0) {
                                rowRates.estProfit += 1000;
                            }
                        }
                    }
                }
                // Combine original hauler data with calculated rates
                return { ...hauler, rowRates };
            });
        }
        // Sort haulers by estimated profit (descending order)
        const sortedList = processedHaulerData.sort((a, b) => {
            const estProfitA = a?.rowRates?.estProfit ?? -Infinity;
            const estProfitB = b?.rowRates?.estProfit ?? -Infinity;
            const haulerNameA = a.haulerName || '';
            const haulerNameB = b.haulerName || '';
            return estProfitA === estProfitB ? haulerNameA.localeCompare(haulerNameB) : estProfitB - estProfitA;
        });

        if (sortedList[0].rowRates?.estProfit) setRecommendedVendor(sortedList[0]);
        else setRecommendedVendor(undefined);
        return sortedList;
    };

    const handleRowClick = (haulerPricing: VendorPricingRow, vendorId: string) => {
        if (!onRowClick || !orderID) return;
        setSelectedHaulerID(vendorId);
        onRowClick(haulerPricing, {
            pricingData: processPricingData(),
            chosenVendorID: vendorId,
        });
        if (vendorId !== recommendedVendor?.id) {
            setOpen(true);
        }
    };

    useEffect(() => {
        if (haulerWithPricing.length === 0) return; // No need to process if hauler data is empty

        // Fetch hauler stats and set hauler list
        fetchHaulerOrderStats(haulerWithPricing).then((updatedHaulerData) => {
            const sortedHaulerList = updatedHaulerData.sort((a, b) => ((a.haulerName || '') > (b.haulerName || '') ? 1 : -1));
            if (selectedMaterial && selectedSize) {
                setHaulerList(processHaulerPricing(sortedHaulerList, selectedMaterial, selectedSize));
            } else {
                setHaulerList(sortedHaulerList);
            }
        });
    }, [haulerWithPricing]);

    useEffect(() => {
        if (haulerList.length === 0) {
            return;
        }
        // Process hauler pricing data and set as row data
        setHaulerList(processHaulerPricing(haulerList, selectedMaterial, selectedSize));
    }, [selectedMaterial, selectedSize, sourgumPricing]);

    const tableHeader = (
        <thead>
            <tr className="border-b text-left [&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal">
                <th className="!p-0 !pl-2" />
                <th>Vendor</th>
                <th>Active Order Count</th>
                <th>Last Order</th>
                <th>Price Type</th>
                <th>Haul Rate</th>
                <th>Dump Rate</th>
                <th>Haul Spread</th>
                <th>Dump Spread</th>
                <th>Overage Spread</th>
                <th>Est Profit</th>
            </tr>
        </thead>
    );

    return (
        <div className="flex w-full flex-col gap-5 overflow-x-auto">
            {onRowClick && orderID && selectedHaulerID && (
                <OtherVendorDialog
                    open={open}
                    setOpen={setOpen}
                    selectedHaulerID={selectedHaulerID} //needed to make sure that form resets per hauler
                    onSubmit={(data) => {
                        const selectedHaulerPricing = haulerList.find((haulerPricing) => haulerPricing.id === selectedHaulerID);
                        if (!selectedHaulerPricing) return;
                        onRowClick(selectedHaulerPricing, {
                            pricingData: processPricingData(),
                            chosenVendorID: selectedHaulerID,
                            ...data,
                        });
                        setOpen(false);
                    }}
                />
            )}
            {haulerList && haulerList.length > 0 && recommendedVendor && (
                <div className="rounded-md border p-4 shadow-sm">
                    <h2 className="text-lg font-bold">Recommended Vendor:</h2>
                    <table className="w-full border-collapse border-spacing-0 text-sm font-semibold">
                        {tableHeader}
                        <tbody>
                            <SpreadTableRow
                                key={recommendedVendor.id}
                                haulerPricing={recommendedVendor}
                                selectedMaterial={selectedMaterial}
                                sourgumPricing={sourgumPricing}
                                selectedSize={selectedSize}
                                onRowClick={onRowClick ? () => handleRowClick(recommendedVendor, recommendedVendor.id) : undefined}
                                selectedHauler={selectedHauler}
                            />
                        </tbody>
                    </table>
                </div>
            )}
            <div>
                Other Vendors:
                <table className="w-full border-collapse border-spacing-0 text-sm">
                    {tableHeader}
                    <tbody>
                        {haulerList
                            .filter((hauler) => hauler.id !== recommendedVendor?.id)
                            .map((item) => (
                                <SpreadTableRow
                                    key={item.id}
                                    haulerPricing={item}
                                    selectedMaterial={selectedMaterial}
                                    sourgumPricing={sourgumPricing}
                                    selectedSize={selectedSize}
                                    onRowClick={onRowClick ? () => handleRowClick(item, item.id) : undefined}
                                    selectedHauler={selectedHauler}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SpreadsTable;
