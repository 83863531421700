import { Material, Order } from '@alliance-disposal/transport-types';
import { WaysteIcon } from '@wayste/sour-components';
import { Popover, Tooltip } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { ArrowTopRightOnSquareIcon, CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { format } from 'date-fns';
import { twMerge } from 'tailwind-merge';
import { routes } from '../../utils';
import { VendorPricingRow } from './SpreadsTable';
import VendorTooltip from './VendorTooltip';

interface SpreadTableRowProps {
    haulerPricing: VendorPricingRow;
    sourgumPricing: {
        haul: number;
        dump: number;
        tonLimit: number;
        overage: number;
    };
    selectedMaterial: Material;
    selectedSize?: string | number;
    onRowClick?: (haulerPricing: VendorPricingRow) => void;
    selectedHauler?: string;
}

export const SpreadTableRow = ({
    haulerPricing,
    sourgumPricing,
    selectedMaterial,
    selectedSize,
    onRowClick,
    selectedHauler,
}: SpreadTableRowProps) => {
    const getHaulerHaulRateDisplay = () => {
        if (!haulerPricing?.rowRates) return '';
        if (haulerPricing.rowRates.materialData.sizes.some((size) => size.tonLimit)) {
            return (
                <span className="flex flex-col">
                    {moneyFormatter(haulerPricing.rowRates.sizeData.haul)}
                    <span className="text-black opacity-50">{` TL: ${haulerPricing.rowRates.sizeData.tonLimit} `}</span>
                    {haulerPricing?.rowRates?.sizeData?.tonLimit &&
                    haulerPricing?.rowRates?.sizeData?.tonLimit > sourgumPricing?.tonLimit ? (
                        <Tooltip text="Hauler has a higher ton limit than Sourgum">
                            <ExclamationTriangleIcon className="text-warning size-5" />
                        </Tooltip>
                    ) : null}
                </span>
            );
        }
        return moneyFormatter(haulerPricing.rowRates.haul);
    };

    const handleActivityClick = (vendorName: string) => {
        const query = `?query[query]=vendor:"${vendorName}"&type=sourgum-order&filters[status][field]=status&filters[status][type]=STRING&filters[status][operator]=IS_IN&filters[status][value][0]=ASSIGNED&filters[status][value][1]=DELIVERED&filters[status][value][2]=READY_FULL_PICKUP&filters[status][value][3]=READY_EMPTY_PICKUP&sort[field]=expectedDeliveryDate&sort[direction]=DESC&sour-search=true`;

        window.open(`/orders/${query}`, '_blank');
    };

    const getHaulSpreadDisplay = () => {
        if (!haulerPricing?.rowRates || !haulerPricing.rowRates.haul) return '';
        const spread = sourgumPricing?.haul - haulerPricing.rowRates.haul;
        return <span className={spread > 0 ? 'text-success' : 'text-red-500'}>{moneyFormatter(spread)}</span>;
    };

    const getDumpSpreadDisplay = () => {
        if (!haulerPricing?.rowRates) return '';
        const spread = sourgumPricing?.dump - +haulerPricing.rowRates.dump;
        return <span className={spread > 0 ? 'text-success' : 'text-red-500'}>{moneyFormatter(spread)}</span>;
    };

    const getOverageSpreadDisplay = () => {
        if (!haulerPricing?.rowRates) return '';
        const spread = sourgumPricing?.overage - haulerPricing.rowRates.dump;
        return <span className={spread > 0 ? 'text-success' : 'text-red-500'}>{moneyFormatter(spread)}</span>;
    };

    const getCalculatedProfitDisplay = () => {
        if (!haulerPricing?.rowRates) return '';
        const estProfit = haulerPricing.rowRates.estProfit;
        return <span className={estProfit > 0 ? 'text-success' : 'text-red-500'}>{moneyFormatter(estProfit)}</span>;
    };

    const getTableRowStyles = () => {
        let className = '';
        if (
            !haulerPricing?.hauler?.active ||
            haulerPricing?.rowRates?.materialData?.doesNotService ||
            (selectedSize && selectedMaterial && !haulerPricing?.rowRates?.sizeData)
        ) {
            className = 'bg-[#f8d7da]';
        }
        return className;
    };

    return (
        <tr
            onClick={() => onRowClick?.(haulerPricing)}
            className={twMerge(`border-b [&>*]:px-4 [&>*]:py-1.5`, 'cursor-pointer hover:bg-slate-50', getTableRowStyles())}
        >
            {/* Check Icon for selected hauler */}
            <td className="!pl-2 !pr-0">
                {selectedHauler && selectedHauler === haulerPricing.haulerID ? (
                    <CheckIcon className="text-success size-6" />
                ) : (
                    <div className="size-6"></div>
                )}
            </td>
            {/* Beginning of row content */}
            <td>
                <div className="flex items-center gap-2">
                    {/* Responsible for vendor name and tooltip */}
                    <VendorTooltip haulerPricing={haulerPricing} onRowClick={onRowClick} />{' '}
                    {(haulerPricing?.rowRates?.materialData || haulerPricing.waysteUsage) && (
                        <div className="flex flex-col items-center">
                            {((haulerPricing?.rowRates?.materialData && !haulerPricing?.rowRates?.materialData?.sourgumApproved) ||
                                !haulerPricing.sourgumApproved) && (
                                <Tooltip text="Pricing has not been approved by Sourgum">
                                    <ExclamationTriangleIcon className="text-warning size-6" />
                                </Tooltip>
                            )}
                            {haulerPricing.waysteUsage ? (
                                haulerPricing.waysteUsage === 'LITE' ? (
                                    <Tooltip text="Hauler has used Wayste Lite in the last 7 days">
                                        <div className="flex items-center justify-center gap-1">
                                            <WaysteIcon />
                                            <span className="text-sm font-light leading-none">Lite</span>
                                        </div>
                                    </Tooltip>
                                ) : (
                                    /* Wayste Standard */
                                    <Tooltip text="Hauler has used Wayste Standard in the last 7 days">
                                        <div>
                                            <WaysteIcon />
                                        </div>
                                    </Tooltip>
                                )
                            ) : null}
                        </div>
                    )}
                </div>
            </td>
            <td>
                <Popover
                    text={
                        haulerPricing.orderCount?.total ? (
                            <div className="flex flex-col gap-1">
                                {haulerPricing.orderCount?.statusBreakdown.map(({ key, doc_count }) => (
                                    <div className="cursor-pointer" key={key}>{`${Order.SourgumOrderStatusLabels[key]}: ${doc_count}`}</div>
                                ))}
                                <div
                                    onClick={() => {
                                        if (haulerPricing.haulerName) {
                                            handleActivityClick(haulerPricing.haulerName);
                                        }
                                    }}
                                    className="text-primary btn-primary-text-only flex cursor-pointer gap-2"
                                >
                                    View Activity
                                    <ArrowTopRightOnSquareIcon className="inline-block size-4" />
                                </div>
                            </div>
                        ) : (
                            <div>No orders</div>
                        )
                    }
                >
                    {haulerPricing.orderCount?.total || 0}
                </Popover>
            </td>
            <td>
                <Popover
                    text={
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={routes.orders.details(haulerPricing?.lastOrder?.id || '')}
                            className="flex items-center gap-2"
                        >
                            {`Order ${haulerPricing?.lastOrder?.orderNumber}`}
                            <ArrowTopRightOnSquareIcon className="inline-block size-4" />
                        </a>
                    }
                >
                    {haulerPricing?.lastOrder?.metadata.createdAt
                        ? format(new Date(haulerPricing?.lastOrder.metadata.createdAt), 'MM/dd/yy')
                        : ''}
                </Popover>
            </td>
            <td>
                {haulerPricing?.rowRates?.materialData?.doesNotService ? 'Does not service' : haulerPricing?.rowRates?.materialData?.type}
            </td>
            <td>{getHaulerHaulRateDisplay()}</td>
            <td>{haulerPricing?.rowRates?.sizeData?.dump ? moneyFormatter(haulerPricing.rowRates.sizeData.dump) : null}</td>
            <td>{getHaulSpreadDisplay()}</td>
            <td>{getDumpSpreadDisplay()}</td>
            <td>{getOverageSpreadDisplay()}</td>
            <td>{getCalculatedProfitDisplay()}</td>
        </tr>
    );
};
