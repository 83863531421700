import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Notification } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Dialog } from '@wayste/sour-ui';
import { formatE164ToUSPhoneNumber, formatEmailDestination } from '@wayste/utils';
import { PaperAirplaneIcon, PlusIcon } from '@heroicons/react/24/outline';
import HaulerContactDialog from './hauler-contact-dialog';

const additionalContactHeadings = [
    'Name',
    'Phone',
    'Email',
    'Note',
    'Primary',
    'Dispatch Notices',
    'Billing Notices',
    'Marketing Notices',
    'RFP Notices',
    '',
];

type Props = {
    hauler: Hauler.HaulerWithAapTransport;
    hasWaysteActivity: boolean;
    setHasWaysteActivity: (value: boolean) => void;
    onContactsUpdated: () => void;
};

const HaulerContactDetails = ({ hauler, hasWaysteActivity, setHasWaysteActivity, onContactsUpdated }: Props) => {
    const client = useWaysteClient();
    const { setShowToast, godModeActive } = useSourContext();
    const [showContactDialog, setShowContactDialog] = useState<{ open: boolean; contact: Hauler.HaulerSapContactTransport | undefined }>({
        open: false,
        contact: undefined,
    });
    const [showDeleteDialog, setShowDeleteDialog] = useState<Hauler.HaulerSapContactTransport | undefined>(undefined);

    const sendWaysteInvite = async (email: string) => {
        if (!hauler) return;
        const destination = formatEmailDestination(
            email,
            hauler.name || '',
            [],
            import.meta.env.MODE === 'production' ? ['luc@sourgum.com'] : [],
        );

        const emailData: Notification.SendGrid.SPPInvitation | Notification.SendGrid.WaysteStandardInvitation = {
            vendor_name: hauler?.name || '',
            sign_up_link:
                import.meta.env.MODE !== 'production'
                    ? `https://app-staging.wayste.com/sign-up?token=${hauler.id}`
                    : `https://app.wayste.com/sign-up?token=${hauler.id}`,
        };

        try {
            await client.notification().adminPortal.createInstantNotification({
                handler: 'sendgrid',
                topic: hauler?.isSourgumPartner ? 'spp-invitation' : 'wayste-standard-invitation',
                destination,
                body: JSON.stringify(emailData),
            });
            setShowToast({
                severity: 'success',
                message: hauler?.isSourgumPartner ? 'SPP Invitation Sent' : 'Wayste Standard Invitation Sent',
            });

            // just for now to prevent sending multiple invites
            setHasWaysteActivity(true);
        } catch {
            setShowToast({
                severity: 'error',
                message: 'Error Sending Wayste Lite Invitation',
            });
        }
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        setShowToast({
            message: 'Copied to clipboard',
            severity: 'success',
        });
    };

    return (
        <div className="flex flex-col gap-y-3">
            <div className="flex items-center justify-between px-4">
                <div className="text-xl font-semibold leading-7 tracking-tight text-gray-800">Contacts</div>
                <Button
                    className="btn-primary"
                    endIcon={<PlusIcon className="size-5" />}
                    onClick={() => setShowContactDialog({ open: true, contact: undefined })}
                >
                    Add Contact
                </Button>
            </div>
            <div className="flex flex-col gap-y-4 overflow-auto rounded-lg border border-gray-300 bg-[#fcfcfd] text-sm shadow">
                <div className="w-full overflow-x-auto">
                    <table className="w-full border-collapse border-spacing-0 text-sm">
                        <thead>
                            <tr>
                                {additionalContactHeadings.map((header) => (
                                    <th key={header} className="whitespace-nowrap bg-slate-100 px-4 py-1.5 text-left font-normal">
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {hauler.contacts
                                .sort((a, b) => (!a.deletedAt && b.deletedAt ? -1 : a.firstName > b.firstName ? 1 : -1))
                                .map((contact) => (
                                    <tr
                                        key={contact.id}
                                        className={`${contact.deletedAt && 'bg-red-100 hover:bg-red-100'} cursor-pointer hover:bg-slate-50 [&>*]:border-b [&>*]:px-4 [&>*]:py-1.5`}
                                        onClick={() => {
                                            if (contact.deletedAt) return;
                                            setShowContactDialog({ open: true, contact });
                                        }}
                                    >
                                        <td>{`${contact.firstName} ${contact.lastName}`}</td>
                                        <td
                                            className="whitespace-nowrap"
                                            onClick={(e) => {
                                                if (contact.phoneNumber) {
                                                    e.stopPropagation();
                                                    copyToClipboard(formatE164ToUSPhoneNumber(contact.phoneNumber));
                                                }
                                            }}
                                        >
                                            <span className="text-wayste-blue-400 no-underline">
                                                {formatE164ToUSPhoneNumber(contact?.phoneNumber ? contact.phoneNumber : '')}
                                            </span>
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                if (contact.email) {
                                                    e.stopPropagation();
                                                    copyToClipboard(contact.email);
                                                }
                                            }}
                                        >
                                            {contact.email}
                                        </td>
                                        <td className="min-w-52">{contact.notes}</td>
                                        <td>{contact.primaryContact ? 'Yes' : 'No'}</td>
                                        <td>{contact.sendDispatchEmails ? 'Yes' : 'No'}</td>
                                        <td>{contact.sendBillingEmails ? 'Yes' : 'No'}</td>
                                        <td>{contact.sendMarketingEmails ? 'Yes' : 'No'}</td>
                                        <td>{contact.sendRFPEmails ? 'Yes' : 'No'}</td>
                                        <td onClick={(e) => e.stopPropagation()} className="cursor-default">
                                            <Button
                                                disabled={(hasWaysteActivity || !contact.email) && !godModeActive}
                                                className="btn-secondary whitespace-nowrap"
                                                endIcon={<PaperAirplaneIcon className="size-5" />}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (contact.email) sendWaysteInvite(contact.email);
                                                }}
                                            >
                                                Send Wayste Invite
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Dialogs */}
            <HaulerContactDialog
                open={showContactDialog.open}
                onClose={() => setShowContactDialog({ open: false, contact: undefined })}
                haulerId={hauler.id}
                contact={showContactDialog.contact}
                onSubmitted={() => {
                    setShowContactDialog({ open: false, contact: undefined });
                    onContactsUpdated();
                }}
                onDelete={(contact) => {
                    setShowContactDialog({ open: false, contact: undefined });
                    setShowDeleteDialog(contact);
                }}
                allContacts={hauler.contacts}
            />
            <DeleteDialog
                open={!!showDeleteDialog}
                onClose={() => setShowDeleteDialog(undefined)}
                onDeleted={() => {
                    setShowDeleteDialog(undefined);
                    onContactsUpdated();
                }}
                contact={showDeleteDialog}
            />
        </div>
    );
};

export default HaulerContactDetails;

const DeleteDialog = ({
    open,
    onClose,
    onDeleted,
    contact,
}: {
    open: boolean;
    onClose: () => void;
    onDeleted: () => void;
    contact?: Hauler.HaulerSapContactTransportToWayste;
}) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = async () => {
        if (!contact) return;
        setIsLoading(true);
        try {
            await client.vendorService().adminPortal.contacts.delete(contact.id);
            onDeleted();
        } catch (error) {
            console.error('Error deleting contact:', error);
            setShowToast({
                message: 'An error occurred deleting the contact. Please try again.',
                severity: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    if (!contact) return null;
    return (
        <Dialog open={open} onClose={onClose} variant="delete" styledTitle="Delete Contact Shared with Sourgum">
            <div>Are you sure you want to delete this contact? They will no longer receive notifications from Sourgum.</div>
            <div className="mt-6 flex justify-end gap-x-4">
                <Button className="btn-dark-grey-outlined" disabled={isLoading} onClick={onClose}>
                    Cancel
                </Button>
                <Button className="btn-delete" onClick={handleDelete} loading={isLoading}>
                    Delete
                </Button>
            </div>
        </Dialog>
    );
};
