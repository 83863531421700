import { useEffect, useState } from 'react';
import { V1 as sourgumPricingFunctions } from '@alliance-disposal/pricing';
import { Customer, Invoice, Material, MaterialLabels, Materials, Pricing } from '@alliance-disposal/transport-types';
import { AdminSelectCoupon } from '@wayste/sour-components';
import { CurrencyTextField, RadioGroup, Select, SelectOption, TextField, Toggle } from '@wayste/sour-ui';
import { fromBasisPoint, moneyFormatter, round } from '@wayste/utils';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Controller, useFormContext } from 'react-hook-form';
import { useConfirmationDialog } from '../../contexts';
import { ccRate, salesTaxExemptMaterials } from '../../utils/pricing-utils';
import routes from '../../utils/routes';
import { priceTypesEnums, rollOffSizes } from '../../utils/shared-types';
import { ExtraItemDialog } from './ExtraItemDialog';

type NormalizeMaterialSizeReturnType = ReturnType<typeof sourgumPricingFunctions.normalizeMaterialSize>;

export type OrderPricingBlockFormProps = {
    material: Material | '';
    priceType: Pricing.TypesOfPricing;
    expectedSize: number | '';
    weightLimit: number | '';
    adjustedRentalPeriod: number | '';
    rentExtensionFee: number | '';
    tax: boolean;
    taxRate: number;
    cc: boolean;
    price: number | '';
    overage: number | '';
    otherLineItems: Invoice.LineItemInputTransport[];
    coupon: Invoice.CouponTransport | '';
};

export interface OrderPricingBlockProps {
    zonePricing: Pricing.PricingTransport | null;
    disabled?: boolean;
    existingCustomer: Customer.AllianceCustomerTransport | null;
    swapConstraints?: {
        material: Material[];
        size: Partial<Record<Material, number[]>>;
    };
    disableFields?: string[];
    showSwitchText?: boolean;
    fromNeedsReview?: boolean;
    passedSetOnce?: boolean;
    fieldName?: string;
}

const OrderPricingBlock = ({
    zonePricing,
    disabled,
    existingCustomer,
    passedSetOnce,
    disableFields,
    swapConstraints,
    showSwitchText,
    fromNeedsReview,
    fieldName = '',
}: OrderPricingBlockProps) => {
    const { getConfirmation } = useConfirmationDialog();
    const [tonLimitBlur, setTonLimitBlur] = useState(true);
    const [setOnce, setSetOnce] = useState(false);
    const [silence, setSilence] = useState(false);
    const [showLineItem, setShowLineItem] = useState(false);
    const [disableSalesTax, setDisableSalesTax] = useState(false);

    const { watch, control, setValue, trigger } = useFormContext();
    const [
        watchMaterial,
        watchPriceType,
        watchExpectedSize,
        watchWeightLimit,
        watchTax,
        watchTaxRate,
        watchCC,
        watchPrice,
        watchOverage,
        watchOtherLineItems,
        watchCoupon,
    ] = watch([
        `${fieldName}material`,
        `${fieldName}priceType`,
        `${fieldName}expectedSize`,
        `${fieldName}weightLimit`,
        `${fieldName}tax`,
        `${fieldName}taxRate`,
        `${fieldName}cc`,
        `${fieldName}price`,
        `${fieldName}overage`,
        `${fieldName}otherLineItems`,
        `${fieldName}coupon`,
    ]);

    useEffect(() => {
        setSilence(true);
        setSetOnce(false);
        setPricing(true);
        setTimeout(() => {
            setSilence(false);
        }, 500);
    }, [zonePricing, watchMaterial]);

    useEffect(() => {
        if (watchPrice) setSetOnce(true);
    }, []);

    useEffect(() => {
        if (existingCustomer?.taxExempt) {
            setValue(`${fieldName}tax`, false);
        }
    }, [existingCustomer?.taxExempt]);

    // Updates pricing based on all relevant fields
    useEffect(() => {
        setPricing();
    }, [watchTaxRate, watchTax, watchCC, tonLimitBlur, watchWeightLimit, watchPriceType, watchExpectedSize, ccRate, watchMaterial]);

    const setInitialPrice = (normalizedPricing: NormalizeMaterialSizeReturnType) => {
        const sizePricing = normalizedPricing.sizes.find((item) => item.size == watchExpectedSize);
        if (!sizePricing) {
            alert('No pricing for size');
            const noPricingValues = { tax: true };
            if (salesTaxExemptMaterials.includes(watchMaterial)) {
                noPricingValues.tax = false;
            }
            return { total: null, newValues: noPricingValues };
        }
        const breakdown = sourgumPricingFunctions.quotedPriceBuild(
            +sizePricing.multiplier,
            +sizePricing.dump,
            Number(sizePricing.haul),
            !sizePricing.tax || existingCustomer?.taxExempt ? 0 : watchTaxRate,
            !watchCC ? 0 : ccRate,
            true,
            +sizePricing.overage,
        );
        return {
            total: breakdown.total,
            newValues: {
                price: breakdown.total,
                dumpRate: +sizePricing.dump || null,
                overage: sizePricing.overage ? breakdown.overage : '',
                weightLimit: +sizePricing?.tonLimit || +sizePricing?.tonLimit === 0 ? +sizePricing?.tonLimit : '',
                priceType: sizePricing.type,
                tax: existingCustomer?.taxExempt ? false : sizePricing.tax,
                adjustedRentalPeriod: zonePricing?.rentalPeriod?.value || '',
                rentExtensionFee: zonePricing?.rentExtensionFee,
            },
        };
    };

    const setPricing = async (initialCheck?: boolean) => {
        // IF NEEDS REVIEW DON'T ALLOW PRICING CHANGES
        if (fromNeedsReview && !initialCheck && zonePricing && watchMaterial && watchExpectedSize) {
            alert('To change pricing for Needs Review Orders you must do so from the invoice screen.');
        }
        if (fromNeedsReview) return;
        if (tonLimitBlur && zonePricing && watchMaterial && watchExpectedSize) {
            const found = zonePricing.pricingData.find((item) => item.material === watchMaterial);
            if (!found) {
                alert('No pricing found');
                if (salesTaxExemptMaterials.includes(watchMaterial)) {
                    setDisableSalesTax(true);
                    setValue(`${fieldName}tax`, false);
                } else {
                    setDisableSalesTax(false);
                }
                return;
            }
            const normalizedPricing = sourgumPricingFunctions.normalizeMaterialSize(found);
            normalizedPricing.sizes = normalizedPricing.sizes.map((item) => {
                return {
                    ...item,
                    originalType: item.type,
                };
            });
            // TODO: Change to '===' once backend is fixed, server currently returning item.size as string instead of number
            const sizePricing = normalizedPricing.sizes.find((item) => item.size == watchExpectedSize);
            if (sizePricing) {
                let newValues = {};
                let total = null;

                if ((!setOnce || initialCheck) && !passedSetOnce) {
                    const initialPricing = setInitialPrice(normalizedPricing);
                    newValues = initialPricing.newValues;
                    total = initialPricing.total;
                } else {
                    if (silence) return;
                    if (!sizePricing.haul) return;
                    const breakdown = sourgumPricingFunctions.quotedPriceBuild(
                        watchPriceType === 'ton' ? +watchWeightLimit : sizePricing.tonLimit,
                        +sizePricing.dump,
                        +sizePricing.haul,
                        !watchTax || existingCustomer?.taxExempt ? 0 : watchTaxRate,
                        !watchCC ? 0 : ccRate,
                        true,
                        +sizePricing.overage,
                    );
                    total = breakdown.total;
                    newValues = {
                        price: breakdown.total,
                        dumpRate: sizePricing.dump,
                        weightLimit:
                            sizePricing.type === priceTypesEnums.ton
                                ? watchPriceType === priceTypesEnums.ton
                                    ? watchWeightLimit
                                    : sizePricing.tonLimit
                                : '',
                        overage:
                            sizePricing.type === priceTypesEnums.ton
                                ? watchPriceType === priceTypesEnums.ton
                                    ? watchOverage || breakdown.overage
                                    : breakdown.overage
                                : '',
                        priceType: sizePricing.type,
                    };
                }

                // this keeps percent line items in sync
                if (watchCoupon) {
                    // literally just to have type checking
                    const selectedCoupon: Invoice.CouponTransport = watchCoupon;

                    // filter out the discount line item
                    if (selectedCoupon.type === 'PERCENTAGE') {
                        const newArray = watchOtherLineItems.filter(
                            (item: Invoice.LineItemTransport) => !item.itemName.toLowerCase().includes('discount'),
                        );

                        // add a new one
                        newArray.push({
                            itemName: 'Discount',
                            description: `Discount for ${selectedCoupon.code}`,
                            quantity: 1,
                            taxable: false,
                            totalPrice: -Math.abs((total || 0) * fromBasisPoint(selectedCoupon.discountAmount, 'decimal')),
                        });
                        setValue(`${fieldName}otherLineItems`, newArray);
                    }
                }

                if (watchPrice && watchPrice !== total) {
                    const override = await getConfirmation({
                        title: 'Price Change',
                        message: `Change from current Price Quoted of ${moneyFormatter(
                            watchPrice || 0,
                        )}. To updated Price Quoted of ${moneyFormatter(total)}?`,
                    });
                    if (!override) return;
                }

                Object.entries(newValues).forEach((item) => {
                    setValue(fieldName + item[0], item[1], { shouldValidate: false });
                });
                // TODO REFACTOR THE BELOW TO PREVENT DOUBLE TRIGGER WHEN TAX CHANGES
                // setValue(`${fieldName}tax`, existingCustomer?.taxExempt ? false : sizePricing.tax, { shouldValidate: false });
                setValue(`${fieldName}maxRentalDaysAllowed`, zonePricing.maxRentalDaysAllowed, { shouldValidate: false });
                trigger([
                    `${fieldName}price`,
                    `${fieldName}dumpRate`,
                    `${fieldName}weightLimit`,
                    `${fieldName}overage`,
                    `${fieldName}priceType`,
                    `${fieldName}tax`,
                    `${fieldName}maxRentalDaysAllowed`,
                ]);
                setSetOnce(true);
            } else {
                alert('No pricing found');
            }
        }
    };

    const handleAddCoupon = (currentTotal: number, coupon: Invoice.CouponTransport) => {
        // filter out the discount line item
        const newArray = watchOtherLineItems.filter((item: Invoice.LineItemTransport) => !item.itemName.toLowerCase().includes('discount'));
        // add the discount line item
        if (coupon.type === 'FIXED') {
            newArray.push({
                itemName: 'Discount',
                description: `Discount for ${coupon.code}`,
                quantity: 1,
                taxable: false,
                totalPrice: -Math.abs(coupon.discountAmount),
            });
        } else if (coupon.type === 'PERCENTAGE') {
            newArray.push({
                itemName: 'Discount',
                description: `Discount for ${coupon.code}`,
                quantity: 1,
                taxable: false,
                totalPrice: -Math.abs((currentTotal || 0) * fromBasisPoint(coupon.discountAmount, 'decimal')),
            });
        }

        setValue(`${fieldName}otherLineItems`, newArray);
    };

    const handleMaterialSelect = (value: string) => {
        if ((value as Material) === 'transportation') {
            setValue(`${fieldName}priceType`, priceTypesEnums.flat);
        }
    };

    const handleLineItemAdd = (item: Invoice.LineItemInputTransport) => {
        const newArray = [
            ...watchOtherLineItems.filter((lineItem: Invoice.LineItemInputTransport) =>
                lineItem.itemName.toLowerCase().includes('discount'),
            ),
            item,
        ];
        setValue(`${fieldName}otherLineItems`, newArray);
        setShowLineItem(false);
    };

    const handleLineItemRemove = (index: number) => {
        const lineItem = watchOtherLineItems[index] as Invoice.LineItemInputTransport;
        const newArray = [...watchOtherLineItems].filter((_, itemIndex) => itemIndex !== index);

        if (watchCoupon && lineItem.description.includes(watchCoupon.code)) {
            setValue(`${fieldName}coupon`, '');
        }

        setValue(`${fieldName}otherLineItems`, newArray);
        setShowLineItem(false);
    };

    const getOtherLineItemsTotal = () => {
        let total = watchPrice;
        watchOtherLineItems.forEach((item: Invoice.LineItemTransport) => {
            total = total + item.totalPrice;
        });
        return round(total);
    };

    const handleSizeChange = async (value: string) => {
        const newSize = parseInt(value);
        const updateData = { expectedSize: newSize, weightLimit: '', overage: '' };
        if (watchExpectedSize && watchExpectedSize !== newSize) {
            const found = zonePricing?.pricingData.find((item) => item.material === watchMaterial);
            if (found) {
                const normalizedPricing = sourgumPricingFunctions.normalizeMaterialSize(found);
                const sizePricing = normalizedPricing.sizes.find((item) => {
                    return item.size == newSize;
                });
                if (sizePricing?.tonLimit && sizePricing?.tonLimit !== watchWeightLimit) {
                    const override = await getConfirmation({
                        title: 'Ton Limit Change',
                        message: `Do you want to update the ton limit to ${sizePricing.tonLimit} to match the correct ton limit for the ${newSize} YD pricing?`,
                    });
                    if (override) {
                        updateData.weightLimit = sizePricing.tonLimit.toString();
                    }
                }
            }
        }
        setValue(`${fieldName}expectedSize`, updateData.expectedSize);
        setValue(`${fieldName}weightLimit`, updateData.weightLimit);
        setValue(`${fieldName}overage`, updateData.overage);
    };

    return (
        <div className="grid grid-cols-1 items-center justify-center gap-4 md:grid-cols-2">
            <Controller
                name={`${fieldName}material`}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'Material is required',
                    },
                }}
                render={({ field, fieldState }) => (
                    <Select
                        label="Material"
                        required
                        value={field.value}
                        error={fieldState.error}
                        onSelect={(value) => {
                            field.onChange(value);
                            handleMaterialSelect(value);
                        }}
                        disabled={disabled || (disableFields && disableFields.includes('material'))}
                    >
                        {Materials.map((item) => (
                            <SelectOption
                                key={`material-${item}`}
                                value={item}
                                disabled={swapConstraints ? !Object.keys(swapConstraints.size)?.includes(item) : false}
                            >
                                {MaterialLabels[item]}
                            </SelectOption>
                        ))}
                    </Select>
                )}
            />
            <Controller
                control={control}
                name={`${fieldName}priceType`}
                rules={{
                    required: {
                        value: true,
                        message: 'A price type is required',
                    },
                }}
                render={({ field }) => (
                    <RadioGroup
                        name={`${fieldName}priceType`}
                        required
                        defaultValue={field.value || undefined}
                        value={field.value}
                        options={[
                            {
                                value: priceTypesEnums.ton,
                                label: 'By Ton',
                                disabled: disabled || (disableFields && disableFields.includes('priceType')),
                            },
                            {
                                value: priceTypesEnums.yard,
                                label: 'By Yard',
                                disabled: disabled || (disableFields && disableFields.includes('priceType')),
                            },
                            {
                                value: priceTypesEnums.flat,
                                label: 'Flat Rate',
                                disabled: disabled || (disableFields && disableFields.includes('priceType')),
                            },
                        ]}
                        onChange={(value) => {
                            field.onChange(value);
                            setValue(`${fieldName}weightLimit`, '');
                            setValue(`${fieldName}overage`, '');
                        }}
                        className="flex gap-2"
                    />
                )}
            />
            <div>
                <Controller
                    name={`${fieldName}expectedSize`}
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Size is required',
                        },
                    }}
                    render={({ field, fieldState }) => (
                        <Select
                            label="Box size"
                            required
                            value={field.value}
                            error={fieldState.error}
                            onSelect={(value) => handleSizeChange(value)}
                            disabled={disabled || (disableFields && disableFields.includes('expectedSize'))}
                        >
                            {Object.values(rollOffSizes).map((item) => (
                                <SelectOption
                                    key={`size-${item}`}
                                    value={Number(item)}
                                    disabled={
                                        swapConstraints ? !swapConstraints.size[watchMaterial as Material]?.includes(parseInt(item)) : false
                                    }
                                >
                                    {item}
                                </SelectOption>
                            ))}
                        </Select>
                    )}
                />
                {showSwitchText && (
                    <p className="pl-2 pt-0.5 text-xs text-gray-400">
                        Only sizes for the current hauler of this order are shown. If you need to service a different size, please make this
                        a final removal and issue a new order with a different hauler.
                    </p>
                )}
            </div>
            {watchPriceType === priceTypesEnums.ton ? (
                <div className="self-start">
                    <Controller
                        name={`${fieldName}weightLimit`}
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Weight limit is required',
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="Ton limit"
                                type="number"
                                required
                                error={fieldState.error}
                                inputProps={{
                                    ...field,
                                    onFocus: () => {
                                        setTonLimitBlur(false);
                                    },
                                    onBlur: () => {
                                        setTonLimitBlur(true);
                                        field.onBlur();
                                    },
                                    disabled: disabled || (disableFields && disableFields.includes('weightLimit')),
                                }}
                            />
                        )}
                    />
                </div>
            ) : (
                <div />
            )}
            <Controller
                name={`${fieldName}adjustedRentalPeriod`}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'Rental period is required',
                    },
                }}
                render={({ field, fieldState }) => (
                    <TextField
                        label="Rental period"
                        type="number"
                        error={fieldState.error}
                        endAdornment="/ days"
                        required
                        inputProps={{
                            ...field,
                            disabled: disabled || (disableFields && disableFields.includes('adjustedRentalPeriod')),
                        }}
                    />
                )}
            />
            <Controller
                name={`${fieldName}rentExtensionFee`}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'Rental extension fee is required',
                    },
                }}
                render={({ field, fieldState }) => (
                    <CurrencyTextField
                        label="Rental extension fee"
                        error={fieldState.error}
                        required
                        inputProps={{
                            disabled: disabled || (disableFields && disableFields.includes('rentExtensionFee')),
                        }}
                        value={field.value?.toString()}
                        onChange={(value) => field.onChange(value)}
                        useCents
                    />
                )}
            />
            <Controller
                name={`${fieldName}tax`}
                control={control}
                render={({ field }) => (
                    <Toggle
                        value={field.value}
                        label="Sales tax"
                        onChange={(value) => field.onChange(value)}
                        disabled={
                            disabled ||
                            (!watchTax && existingCustomer?.taxExempt) ||
                            disableSalesTax ||
                            (disableFields && disableFields.includes('tax')) ||
                            fromNeedsReview
                        }
                    />
                )}
            />
            <Controller
                name={`${fieldName}cc`}
                control={control}
                render={({ field }) => (
                    <Toggle
                        value={field.value}
                        label="CC fee"
                        onChange={(value) => field.onChange(value)}
                        disabled={disabled || (disableFields && disableFields.includes('cc')) || fromNeedsReview}
                    />
                )}
            />
            <div className="flex flex-col gap-4">
                <Controller
                    name={`${fieldName}price`}
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Price is required',
                        },
                    }}
                    render={({ field, fieldState }) => (
                        <CurrencyTextField
                            label="Price quoted"
                            error={fieldState.error}
                            required
                            inputProps={{
                                disabled: disabled || (disableFields && disableFields.includes('price')) || fromNeedsReview,
                            }}
                            value={field.value?.toString()}
                            onChange={(value) => {
                                field.onChange(value);
                                if (watchCoupon && value) {
                                    handleAddCoupon(value, watchCoupon);
                                }
                            }}
                            useCents
                        />
                    )}
                />
                {watchOtherLineItems.length > 0 && (
                    <div className="mb-2 w-full overflow-x-auto">
                        <table className="w-full border-collapse border-spacing-0 text-sm">
                            <tbody>
                                {watchOtherLineItems.map((item: Invoice.LineItemTransport, index: number) => (
                                    <tr className="border-b [&>*]:px-4 [&>*]:py-1.5" key={`${item.itemName}-${index}`}>
                                        <td>{item.itemName}</td>
                                        {item.totalPrice && <td>{moneyFormatter(item.totalPrice)}</td>}
                                        <td>
                                            <button className="btn-icon" onClick={() => handleLineItemRemove(index)}>
                                                <XMarkIcon className="text-delete size-5" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                <tr className="border-b [&>*]:px-4 [&>*]:py-1.5">
                                    <td>
                                        <b>Total</b>
                                    </td>
                                    <td>{moneyFormatter(getOtherLineItemsTotal())}</td>
                                    <td />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            {watchPriceType === priceTypesEnums.ton ? (
                <div className="self-start">
                    <Controller
                        name={`${fieldName}overage`}
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Overage is required',
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <CurrencyTextField
                                label="Overage fee"
                                error={fieldState.error}
                                inputProps={{
                                    disabled: disabled || (disableFields && disableFields.includes('overage')),
                                }}
                                required
                                value={field.value?.toString()}
                                onChange={(value) => field.onChange(value)}
                                useCents
                            />
                        )}
                    />
                </div>
            ) : (
                <div />
            )}

            <Controller
                name={`${fieldName}coupon`}
                control={control}
                render={({ field, fieldState }) => (
                    <AdminSelectCoupon
                        value={field.value?.id}
                        disabled={disabled}
                        fieldError={fieldState.error}
                        onSelect={(coupon) => {
                            field.onChange(coupon);

                            if (!coupon) {
                                setValue(
                                    `${fieldName}otherLineItems`,
                                    watchOtherLineItems.filter(
                                        (item: Invoice.LineItemTransport) => !item.itemName.toLowerCase().includes('discount'),
                                    ),
                                );
                                return;
                            }

                            handleAddCoupon(watchPrice, coupon);
                        }}
                    />
                )}
            />

            <div className="flex items-center gap-2">
                <span>or manually add a discount</span>
                <button
                    className="btn-primary-text-only self-end"
                    disabled={disabled || !watchPrice || fromNeedsReview}
                    onClick={() => setShowLineItem(true)}
                    type="button"
                >
                    Apply Discount
                </button>
            </div>

            {existingCustomer && (
                <div className="flex items-center gap-2">
                    {zonePricing?.customerID && (
                        <div className="text-info-dark bg-info-light mt-2 rounded p-1 text-xs">Customer Has Special Pricing</div>
                    )}
                    <button
                        className="btn-primary-text-only"
                        onClick={() => window.open(routes.customers.details(existingCustomer.id))}
                        type="button"
                    >
                        Open Customer Details
                    </button>
                </div>
            )}
            {showLineItem && <ExtraItemDialog open={showLineItem} onClose={() => setShowLineItem(false)} onSave={handleLineItemAdd} />}
        </div>
    );
};

export default OrderPricingBlock;
