import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Notification } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Dialog, Textarea } from '@wayste/sour-ui';
import { formatEmailDestination } from '@wayste/utils';

export interface EmailSendDialogProps {
    open: boolean;
    onClose: () => void;
    onEmailSent: () => void;
    message: string;
    preText?: React.ReactNode;
    toEmail?: string;
    ccEmails?: string[];
    bccEmails?: string[];
    emailData?: {
        topic: 'do-not-service-area' | 'lead-quote-ro' | 'lead-follow-up-ro';
        body: Notification.SendGrid.DoNotServiceArea | Notification.SendGrid.LeadQuoteRo | Notification.SendGrid.LeadFollowUpRo;
    };
}

const EmailSendDialog = ({
    open,
    onClose,
    onEmailSent,
    message,
    preText,
    emailData,
    toEmail,
    ccEmails,
    bccEmails,
}: EmailSendDialogProps) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [isLoading, setIsLoading] = useState(false);
    const [localMessage, setLocalMessage] = useState(message);

    const handleSubmit = async () => {
        setIsLoading(true);
        if (!emailData || !toEmail) return;
        emailData.body.custom_message = localMessage;
        const destination = formatEmailDestination(toEmail, emailData.body.first_name, ccEmails, bccEmails);
        try {
            await client.notification().adminPortal.createInstantNotification({
                handler: 'sendgrid',
                topic: emailData.topic,
                body: JSON.stringify(emailData.body),
                destination,
            });
            setShowToast({
                message: 'Email Sent!',
                severity: 'success',
            });
            onEmailSent();
            setLocalMessage('');
        } catch (error) {
            setShowToast({
                message: 'An error occurred, check the console',
                severity: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        onClose();
        setLocalMessage('');
    };

    return (
        <Dialog onClose={onClose} open={open}>
            <div className="mb-4 text-xl">Compose Email</div>
            <div style={{ minHeight: 400 }}>
                <div className="flex flex-col gap-4">
                    <div>
                        Send to: <b>{toEmail}</b>
                    </div>
                    {preText ? <div>{preText}</div> : null}
                    <div>
                        <Textarea
                            label="Custom message"
                            inputProps={{
                                value: localMessage,
                                onChange: (e) => setLocalMessage(e.target.value),
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-4">
                <Button className="btn-dark-grey-outlined" onClick={handleCancel} disabled={isLoading}>
                    Cancel
                </Button>
                <Button className="btn-primary" onClick={() => handleSubmit()} type="button" loading={isLoading} disabled={!toEmail}>
                    Send
                </Button>
            </div>
        </Dialog>
    );
};

export default EmailSendDialog;
