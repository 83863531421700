import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, S3ItemReference } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { PencilIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import routes from '../../utils/routes';
import { customerFileTypes } from '../../utils/shared-types';
import AuditLogTable from '../AuditLogTable';
import CustomerDetailsLists from '../CustomerDetailsLists';
import CustomerUpdate from '../CustomerUpdate';
import DetailsCardWrapper from '../DetailsCardWrapper';
import FileHandler from '../FileHandler/FileHandler';

interface CustomerDetailsCardProps {
    customer: Customer.AllianceCustomerTransport;
    edit?: boolean;
}
const CustomerDetailsCard = ({ customer, edit }: CustomerDetailsCardProps) => {
    const client = useWaysteClient();
    const history = useHistory();
    const [showEdit, setShowEdit] = useState(false);
    const [showCustomerChanges, setShowCustomerChanges] = useState(false);
    const [showFiles, setShowFiles] = useState(false);
    const [files, setFiles] = useState<S3ItemReference[] | undefined>(customer.files);

    const onFileUpload = async (file: S3ItemReference) => {
        const customerUpdate: Customer.AllianceCustomerInput = {
            ...customer,
            files: [...(customer.files || []), file],
        };
        await client.customer().adminPortal.update(customer.id, customerUpdate);
        setFiles(customerUpdate.files);
    };

    const onFilesEdit = async (files: S3ItemReference[]) => {
        const customerUpdate: Customer.AllianceCustomerInput = {
            ...customer,
            files: files,
        };
        await client.customer().adminPortal.update(customer.id, customerUpdate);
        setFiles(customerUpdate.files);
    };

    return (
        <DetailsCardWrapper
            heading="Customer Details"
            chips={
                customer.doNotService
                    ? [{ label: 'Do Not Service', primaryColor: 'error-light', textColor: 'error-dark', size: 'small' }]
                    : []
            }
            buttons={[
                {
                    label: (
                        <>
                            <PencilIcon className="mr-1 h-4 w-4" /> Edit Customer Details
                        </>
                    ),
                    onClick: () => setShowEdit(true),
                },
                {
                    label: (
                        <>
                            <ArrowTopRightOnSquareIcon className="mr-1 h-5 w-5" /> Customer Details
                        </>
                    ),
                    onClick: () => {
                        customer.id !== undefined ? history.push(routes.customers.details(customer.id)) : null;
                    },
                    hide: edit,
                },
                {
                    label: 'View Change History',
                    onClick: () => setShowCustomerChanges(true),
                },
                {
                    label: (
                        <>
                            <DocumentIcon className="mr-1 h-5 w-5" /> Add Files
                        </>
                    ),
                    onClick: () => setShowFiles(true),
                },
            ]}
        >
            <FileHandler
                files={files || []}
                folderName={`customer${customer.id?.toString() ?? ''}/`}
                types={customerFileTypes}
                open={showFiles}
                onCancel={() => setShowFiles(false)}
                onFileUpload={onFileUpload}
                onFilesEdit={onFilesEdit}
            />
            <CustomerDetailsLists customer={customer} />
            <CustomerUpdate open={showEdit} customer={customer} onBackButtonClick={() => setShowEdit(false)} />

            <Dialog
                styledTitle="Customer Change Log"
                open={showCustomerChanges}
                className="max-w-screen-2xl"
                onClose={() => setShowCustomerChanges(false)}
                showX
            >
                {showCustomerChanges ? <AuditLogTable entityID={customer.id || ''} entityType="AllianceCustomer" /> : null}
            </Dialog>
        </DetailsCardWrapper>
    );
};

export default CustomerDetailsCard;
