import React, { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Notification } from '@alliance-disposal/transport-types';
import { BellAlertIcon, BellIcon } from '@heroicons/react/24/outline';
import NotificationsDialog from './NotificationsDialog';

const InAppNotifications = () => {
    const client = useWaysteClient();
    const [notificationsDialogOpen, setNotificationsDialogOpen] = useState(false);
    const [inAppNotificationsCount, setInAppNotificationsCount] = useState(0);
    const [inAppNotifications, setInAppNotifications] = useState<Notification.InAppNotification[]>([]);

    const handleGetInAppNotifications = async () => {
        const response = await client.notification().adminPortal.inApp.fetch();
        console.log(response);
        setInAppNotificationsCount(response.total);
        setInAppNotifications(response.results);
    };

    useEffect(() => {
        handleGetInAppNotifications();
    }, []);

    const handleMarkAsRead = (id: string) => {
        // TODO mark as readAt not deletedAt once query supports proper filtering
        try {
            client.notification().adminPortal.inApp.update(id, { deletedAt: new Date().toISOString() });
        } catch (error) {
            console.log(error);
            handleGetInAppNotifications();
        }
        // Optimistic update
        setInAppNotificationsCount(inAppNotificationsCount - 1);
        setInAppNotifications(inAppNotifications.filter((n) => n.id !== id));
    };

    return (
        <>
            <div className="flex cursor-pointer items-center" onClick={() => setNotificationsDialogOpen(true)}>
                <div className="relative flex text-left">
                    {inAppNotificationsCount > 0 ? (
                        <>
                            <span className="h-5.5 w-5.5 z-1 absolute -right-2.5 -top-2 inline-flex items-center justify-center rounded-full bg-red-600 p-1 text-xs font-bold leading-none text-white lg:-top-1.5 lg:left-4">
                                {inAppNotificationsCount}
                            </span>
                            <BellAlertIcon className="text-sourgum-greyblue-900 h-6 w-6" />
                        </>
                    ) : (
                        <BellIcon className="text-sourgum-greyblue-900 h-6 w-6" />
                    )}
                </div>
            </div>
            {/* Dialogs */}
            <NotificationsDialog
                open={notificationsDialogOpen}
                onClose={() => {
                    setNotificationsDialogOpen(false);
                }}
                inAppNotifications={inAppNotifications}
                onMarkAsRead={handleMarkAsRead}
            />
        </>
    );
};

export default InAppNotifications;
