import React, { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Order } from '@alliance-disposal/transport-types';
import { DatePicker } from '@wayste/sour-ui';
// import { differenceInDays, format, subDays } from 'date-fns';
import { CSVLink } from 'react-csv';
import Loading from '../Loading';

// const average = (array) => array.reduce((a, b) => a + b) / array.length;

const QuickReports = () => {
    const client = useWaysteClient();
    const [isLoading, setIsLoading] = useState(false);
    const [treesFromDate, setTreesFromDate] = useState<'' | Date>('');
    const [treesToDate, setTreesToDate] = useState<'' | Date>('');
    const [treesPlanted, setTreesPlanted] = useState<number | null>(null);
    const [orderData, setOrderData] = useState<Order.AllianceOrderTransport[]>([]);

    const handleSetTreesFromDate = (event: Date | '') => {
        setTreesFromDate(event);
        setTreesToDate(event);
    };

    const handleGetTreesPlanted = async () => {
        setIsLoading(true);
        const start = new Date(new Date(treesFromDate).setHours(0, 0, 0, 0)).toISOString();
        const end = new Date(new Date(treesToDate).setHours(24, 0, 0, 0)).toISOString();
        const response = await client.order().adminPortal.query({
            createdAfter: start,
            createdBefore: end,
            limit: 10000,
        });
        setOrderData(response.results);
        setTreesPlanted(response.results.length);
        setIsLoading(false);
    };

    // const getActiveCustomers = async (orders) => {
    //   const aggregate = [];
    //   const months = {};
    //   const years = {};
    //   // const orders = await handleGetCollectionByDateRange('orders', 'created', startDate, endDate);
    //   orders.forEach((order) => {
    //     const createdMonth = format(new Date(order.created), 'MMMyy');
    //     const createdYear = format(new Date(order.created), 'yyyy');
    //     const id = order.customerID;
    //     if (!aggregate.includes(id)) {
    //       aggregate.push(id);
    //     }
    //     if (!months[createdMonth]) {
    //       months[createdMonth] = [id];
    //     } else if (!months[createdMonth].includes(id)) {
    //       months[createdMonth].push(id);
    //     }
    //     if (!years[createdYear]) {
    //       years[createdYear] = [id];
    //     } else if (!years[createdYear].includes(id)) {
    //       years[createdYear].push(id);
    //     }
    //   });
    //   Object.keys(months).forEach((key) => {
    //     console.log('Active Customers Month ', key, ': ', months[key].length);
    //   });
    //   Object.keys(years).forEach((key) => {
    //     console.log('Active Customers Year', key, ': ', years[key].length);
    //   });
    //   console.log('Active CustomersAggregate Total: ', aggregate.length);
    // };

    // const getOrdersWithDiscount = async (orders) => {
    //   let discounts = 0;
    //   orders.forEach((order) => {
    //     let has = false;
    //     Object.values(order.invoices).forEach((invoice) => {
    //       if (invoice.lineItems.some((item) => item.itemName.toLowerCase() === 'discount')) {
    //         has = true;
    //       }
    //     });
    //     if (has) discounts = discounts + 1;
    //   });
    //   console.log('Orders With Discounts: ', discounts);
    // };

    // const get90DayRepurchaseRate = async (endDate) => {
    //   const cleanedOrders = {};
    //   let oneOrder = 0;
    //   let twoPlusOrders = 0;
    //   const timeBetweenOrders = [];
    //   const startDate = subDays(new Date(endDate), 90);
    //   const start = new Date(new Date(startDate).setHours(0, 0, 0, 0)).toISOString();
    //   const end = new Date(new Date(endDate).setHours(24, 0, 0, 0)).toISOString();
    //   const response = await queryOrders({
    //     createdAfter: start,
    //     createdBefore: end,
    //   });
    //   const ninetyDayOrders = response.data;
    //   ninetyDayOrders.forEach((order) => {
    //     if (cleanedOrders[order.customerID]) {
    //       cleanedOrders[order.customerID].push(order);
    //     } else {
    //       cleanedOrders[order.customerID] = [order];
    //     }
    //   });

    //   Object.values(cleanedOrders).forEach((item) => {
    //     if (item.length === 1) {
    //       oneOrder = oneOrder + 1;
    //       return;
    //     } else {
    //       twoPlusOrders = twoPlusOrders + 1;
    //       const latestOrder = new Date(Math.max(...item.map((e) => new Date(e.created))));
    //       const newestOrder = new Date(Math.min(...item.map((e) => new Date(e.created))));
    //       const timeSinceLast = differenceInDays(latestOrder, newestOrder);
    //       timeBetweenOrders.push(timeSinceLast);
    //     }
    //   });

    //   console.log('90 Day Repurchase Total Orders: ', ninetyDayOrders.length);
    //   console.log('90 Day Repurchase One Order Count: ', oneOrder);
    //   console.log('90 Day Repurchase Two + Order Count: ', twoPlusOrders);
    //   console.log('90 Day Repurchase Avg time between orders: ', average(timeBetweenOrders));
    // };

    // const getCanceledOrdersByMonth = async () => {
    //   const months = {};
    //   const response = await queryOrders({ status: ['CANCELLED'] });
    //   const orders = response.data;

    //   orders.forEach((order) => {
    //     const createdMonth = format(new Date(order.created), 'MMMyy');
    //     if (!months[createdMonth]) {
    //       months[createdMonth] = [order];
    //     } else {
    //       months[createdMonth].push(order);
    //     }
    //   });

    //   Object.keys(months).forEach((key) => {
    //     console.log('Canceled Orders: ', key, ': ', months[key].length);
    //   });
    // };

    // const getWeKnowOurNumbersFigures = async () => {
    //   const start = new Date(new Date(treesFromDate).setHours(0, 0, 0, 0)).toISOString();
    //   const end = new Date(new Date(treesToDate).setHours(24, 0, 0, 0)).toISOString();
    //   const response = await queryOrders({
    //     createdAfter: start,
    //     createdBefore: end,
    //   });
    //   const orders = response.data;
    //   console.log('Orders Count: ', orders.length);
    //   getActiveCustomers(orders);
    //   getOrdersWithDiscount(orders);
    //   const newCustomersResponse = await queryCustomers({
    //     createdAt_gte: start,
    //     createdAt_lte: end,
    //   });
    //   console.log('New Customers', newCustomersResponse.data.length);
    //   get90DayRepurchaseRate(treesToDate);
    //   getCanceledOrdersByMonth();
    //   // DO I NEED TO KEEP RUNNING this? get365DayRepurchaseRate(endDate);
    // };

    // const get365DayRepurchaseRate = async (date) => {
    //   console.log("365 Started");
    //   const startDate = subYears(startOfMonth(new Date(date)), 1);
    //   let total = 0;
    //   let oneTimeCustomers = 0;
    //   let repeatCustomers = 0;
    //   const oneYearCustomers = await handleGetCollectionByDateRange(
    //     "customers",
    //     "created",
    //     startDate,
    //     date
    //   );
    //   await asyncForEach(oneYearCustomers, async (customer) => {
    //     const orderSnapshot = await firebase
    //       .firestore()
    //       .collection("orders")
    //       .where("customerId", "==", customer.id)
    //       .limit(2)
    //       .get();
    //     total = total + 1;
    //     if (orderSnapshot.docs.length > 1) {
    //       repeatCustomers = repeatCustomers + 1;
    //     } else {
    //       oneTimeCustomers = oneTimeCustomers + 1;
    //     }
    //   });
    //   console.log("365 Repurchase Total: ", total);
    //   console.log("365 Repurchase One time customers: ", oneTimeCustomers);
    //   console.log("365 Repurchase Repeat customers: ", repeatCustomers);
    // };

    if (isLoading) return <Loading />;

    return (
        <div className="container mx-auto px-4">
            <div className="mt-10 flex flex-col gap-4">
                <div>
                    <h4 className="pb-4 text-4xl">Order Data</h4>
                </div>
                <div className="flex flex-col gap-4 md:flex-row md:items-center">
                    <div className="max-w-[200]">
                        <DatePicker label="From date" value={treesFromDate} onChange={handleSetTreesFromDate} format="fullDate" />
                    </div>
                    <div className="max-w-[200]">
                        <DatePicker label="To date" value={treesToDate} onChange={setTreesToDate} format="fullDate" />
                    </div>
                    <button
                        className="btn-primary-text-only"
                        onClick={handleGetTreesPlanted}
                        disabled={!treesFromDate || !treesToDate}
                        type="button"
                    >
                        Get Trees Planted
                    </button>
                </div>
                <div>Trees Planted since date: {treesPlanted}</div>
                <div>
                    {orderData.length === 0 ? 'Get Order Data First to Dump It' : <CSVLink data={orderData}>Dump Order Data</CSVLink>}
                </div>
            </div>
        </div>
    );
};

export default QuickReports;
