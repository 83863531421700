import React from 'react';
import { Dialog } from '@wayste/sour-ui';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { useHistory, useParams } from 'react-router-dom';
import OrderDetailsContent from '../../components/OrderDetails';

interface OrderDetailsProps {
    isModal?: boolean;
}

const OrderDetails = ({ isModal }: OrderDetailsProps) => {
    const { id }: { id: string } = useParams();
    const history = useHistory();

    const handleBackButtonClick = () => {
        history.goBack();
    };

    if (isModal)
        return (
            <Dialog open={true} onClose={handleBackButtonClick} styledTitle="Order Details" fullScreen>
                <div className="h-full bg-[#F9F9F9]">
                    <OrderDetailsContent id={id} />
                </div>
            </Dialog>
        );

    return (
        <div className="flex-1 bg-[#F9F9F9]">
            <div className="container mx-auto p-6 pt-5">
                <div className="flex items-center">
                    <div>
                        <button className="btn-icon" onClick={handleBackButtonClick}>
                            <ChevronLeftIcon className="size-6" />
                        </button>
                    </div>
                    <div className="text-xl">Order Details</div>
                </div>
                <OrderDetailsContent id={id} />
            </div>
        </div>
    );
};

OrderDetails.defaultProps = {
    isModal: false,
};

export default OrderDetails;
