import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Material, MaterialDisclaimer, MaterialLabels, Notification } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { formatServiceAddress, moneyFormatter, tonLimitOverageToPerPound, tonLimitToPounds } from '@wayste/utils';
import { DevicePhoneMobileIcon, PaperAirplaneIcon, PencilIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import { onCreateOrderFromLead } from '../../pages/leads/utils/lead-to-order';
import { routes } from '../../utils';
import { leadStatusTypesEnums, mainSite, mediumTypesEnums, priceTypesEnums } from '../../utils/shared-types';
import { ROQuoteUpdate } from '../LeadUpdate';
import EmailSendDialog, { type EmailSendDialogProps } from './EmailSendDialog';
import SMSSendDialog from './SMSSendDialog';

const quoteHeadings = ['Size', 'Material', 'Ton Limit', 'Overage Fee', 'Rental Period', 'Price Quoted', ''];

const ROQuoteDetails = ({ rawLead }: { rawLead: Customer.AllianceLeadTransport }) => {
    const client = useWaysteClient();
    const userProfile = client.user().get();
    const history = useHistory();
    const { showFlash } = useContext(UIContext);
    const [lead, setLead] = React.useState<Customer.AllianceLeadTransport>(rawLead);
    const [showQuoteForm, setShowQuoteForm] = useState(false);
    const [sendingRejection, setSendingRejection] = useState<boolean>(false);
    const [emailSend, setEmailSend] = useState<{
        open: boolean;
        toEmail: string;
        ccEmails?: string[];
        bccEmails?: string[];
        message: string;
        preText: React.ReactNode;
        data?: EmailSendDialogProps['emailData'];
    }>({
        toEmail: '',
        open: false,
        message: '',
        preText: null,
        ccEmails: [],
        bccEmails: [],
    });
    const [smsSend, setSMSSend] = useState({
        open: false,
        toNumber: '',
        message: '',
    });

    useEffect(() => {
        if (!rawLead) return;
        setLead(rawLead);
    }, [rawLead]);

    const handleSendQuote = async (quote: Customer.AllianceLeadQuote) => {
        if (!lead.email) {
            alert('No email sent. Lead does not have an email');
            return;
        }

        let webSaleUrl = undefined;
        if (lead.quotes.every((item) => item.pricingExists)) {
            try {
                const accessTokenResponse = await client.customer().adminPortal.leads.generateToken(lead.id);
                webSaleUrl = accessTokenResponse?.key;
            } catch (error) {
                console.error(error);
            }
        }
        const materialString = MaterialLabels[quote.material as Material];
        const areaText =
            `${lead?.serviceLocation?.address.city}, ${lead?.serviceLocation?.address.state}` +
            (lead?.serviceLocation?.address.zip ? ` ${lead?.serviceLocation.address.zip}` : '');
        const specialMaterialString = MaterialDisclaimer[quote.material as Material];
        const body: Notification.SendGrid.LeadQuoteRo = {
            first_name: lead.firstName || '',
            csa_name: userProfile.firstName || '',
            dumpster_size: quote.size.size.toString(),
            material: materialString,
            service_location: areaText,
            quote_url: webSaleUrl || '',
            quote_total: moneyFormatter(quote.price as number, { hideDollarSign: true, undefinedBehavior: '' }),
            ton_limit: quote.tonLimit?.toString() || '',
            rental_period: quote.rentPeriod?.value ? quote.rentPeriod.value.toString() : '',
            overage_fee: quote.overage ? moneyFormatter(quote.overage, { hideDollarSign: true, undefinedBehavior: '' }) : '',
            rental_extension_fee: moneyFormatter(quote.rentExtensionFee, { hideDollarSign: true, undefinedBehavior: '' }),
            max_rental_days: quote.maxRentalDaysAllowed?.toString() || '30',
            special_material_string: specialMaterialString,
            custom_message: '',
            price_type: quote.priceType as 'ton' | 'flat' | 'yard',
            last_order_date: lead.allianceCustomer ? '2024-01-01' : '',
        };

        setEmailSend({
            ...emailSend,
            open: true,
            toEmail: lead.email,
            data: {
                topic: 'lead-quote-ro',
                body,
            },
            preText: (
                <span>
                    You can enter a custom message below. It is optional to add, you don't need to add one. If you enter a custom message it
                    will be inserted as below.
                    <br />
                    <br />
                    Hi {body.first_name},<br />
                    This is {body.csa_name} from Sourgum Waste. ENTER CUSTOM MESSAGE HERE. Here is the quote you requested:
                    <br />
                    ...quote details follow.
                </span>
            ),
        });
    };

    const handleSendSMSQuote = async (quote: Customer.AllianceLeadQuote) => {
        let webSaleUrl = undefined;
        if (lead.quotes.every((item) => item.pricingExists)) {
            try {
                const accessTokenResponse = await client.customer().adminPortal.leads.generateToken(lead.id);
                webSaleUrl = accessTokenResponse?.key;
            } catch (error) {
                console.error(error);
            }
        }
        const materialString = MaterialLabels[quote.material as Material];
        const areaText =
            `${lead?.serviceLocation?.address.city}, ${lead?.serviceLocation?.address.state}` +
            (lead?.serviceLocation?.address.zip ? ` ${lead?.serviceLocation.address.zip}` : '');
        setSMSSend({
            open: true,
            toNumber: lead.phoneNumber || '',
            message: `Hi ${lead.firstName}, your quote from Sourgum Waste is ready!\n\nDumpster size: ${
                quote.size.size
            } yard\nWaste material: ${materialString}\nService location: ${areaText}\nAll-inclusive price: ${
                quote.price && moneyFormatter(quote.price)
            }\n\nThis includes all taxes and fees,${quote.tonLimit ? ` a ${quote.tonLimit} ton weight limit` : ''} and a ${
                quote.rentPeriod?.value
            } day rental period. ${
                webSaleUrl ? `Order now: https://www.sourgum.com/checkout/?quote_id=${webSaleUrl}` : 'Call us at (855) 706-1176 to order.'
            }`,
        });
    };

    const handleSendFollowUp = async (quote: Customer.AllianceLeadQuote) => {
        if (!lead.email) {
            alert('No email sent. Lead does not have an email');
            return;
        }

        let webSaleUrl = undefined;
        if (lead.quotes.every((item) => item.pricingExists)) {
            try {
                const accessTokenResponse = await client.customer().adminPortal.leads.generateToken(lead.id);
                webSaleUrl = accessTokenResponse?.key;
            } catch (error) {
                console.error(error);
            }
        }

        const body: Notification.SendGrid.LeadFollowUpRo = {
            first_name: lead.firstName || '',
            csa_name: userProfile.firstName || '',
            dumpster_size: quote.size.size.toString(),
            material: MaterialLabels[quote.material as Material],
            quote_total: moneyFormatter(quote.price, { hideDollarSign: true, undefinedBehavior: '' }),
            quote_url: webSaleUrl || '',
            custom_message: '',
            last_order_date: lead.allianceCustomer ? '2024-01-01' : '',
            service_location: formatServiceAddress(lead.serviceLocation?.address),
            rental_period: quote.rentPeriod?.value ? quote.rentPeriod.value.toString() : '',
        };
        setEmailSend({
            ...emailSend,
            open: true,
            toEmail: lead.email,
            data: {
                topic: 'lead-follow-up-ro',
                body,
            },
            preText: (
                <span>
                    You can enter a custom message below. It is optional to add, you don't need to add one. If you enter a custom message it
                    will be inserted as below.
                    <br />
                    <br />
                    Hi {body.first_name},<br />
                    I hope all is well. We just wanted to check in and see if you were still interested in renting a dumpster?
                    <br />
                    <br />
                    We can offer a {body.dumpster_size} dumpster for ${body.quote_total}, all taxes and fees included.
                    <br />
                    <br />
                    ENTER CUSTOM MESSAGE HERE.
                    <br />
                    <br />
                    ...quote details follow.
                </span>
            ),
        });
    };

    const handleSendDoNotService = () => {
        if (!lead.email) {
            alert('No email sent. Lead does not have an email');
            return;
        }
        const areaText = lead?.serviceLocation?.address.city
            ? `${lead.serviceLocation.address.city}${lead.serviceLocation.address.state ? `, ${lead.serviceLocation.address.state}` : ''}`
            : 'your area';
        const body: Notification.SendGrid.DoNotServiceArea = {
            first_name: lead.firstName || '',
            csa_name: userProfile.firstName || '',
            service_location: areaText,
            service_type: 'roll-off dumpster rentals',
            custom_message: '',
            last_order_date: lead.allianceCustomer ? '2024-01-01' : '',
        };

        setEmailSend({
            ...emailSend,
            open: true,
            toEmail: lead.email,
            data: {
                body,
                topic: 'do-not-service-area',
            },
            preText: (
                <span>
                    You can enter a custom message below. It is optional to add, you don't need to add one. If you enter a custom message it
                    will be inserted as below.
                    <br />
                    <br />
                    Hi {body.first_name},<br />
                    Unfortunately, we do not currently service {body.service_location} with {body.service_type}, but it is definitely in our
                    growth road map!
                    <br />
                    <br />
                    ENTER CUSTOM MESSAGE HERE.
                    <br />
                    <br />
                    As <b>America's First Haulsourcing Platform</b>, we make booking dumpster rentals easier than easy. And we want to roll
                    it out for you sooner.
                    <br />
                    <br />
                    ...more stuff.
                </span>
            ),
        });
    };

    const handleCopyQuoteToClipboard = async (quote: Customer.AllianceLeadQuote) => {
        let webSaleUrl = 'You can always call us Monday to Sunday 7am to 7pm at (732) 366-9355.\n\n';

        if (lead.quotes.every((item) => item.pricingExists)) {
            try {
                const accessTokenResponse = await client.customer().adminPortal.leads.generateToken(lead.id);
                webSaleUrl = `You can also order online by going to https://${mainSite}/checkout/?quote_id=${accessTokenResponse?.key}\n\n`;
            } catch (error) {
                console.error(error);
            }
        }
        const materialString = MaterialLabels[quote.material as Material].toLowerCase();
        const specialMaterialString = MaterialDisclaimer[quote.material as Material];
        const areaText =
            `${lead?.serviceLocation?.address.city}, ${lead?.serviceLocation?.address.state}` +
            (lead?.serviceLocation?.address.zip ? ` ${lead?.serviceLocation?.address.zip}` : '');
        const message =
            `Hi ${lead.firstName},\n\n` +
            `This is ${userProfile.firstName} with Sourgum Waste. We can provide you with a ${quote.size.size} yard dumpster for ${materialString} delivered to ${areaText} for ${moneyFormatter(quote.price)}\n\n` +
            `That price includes all taxes and fees,${
                quote.priceType === priceTypesEnums.ton ? ` a ${quote.tonLimit} ton weight limit,` : ''
            } and up to a ${quote.rentPeriod?.value} day rental period.\n\n` +
            `${
                quote.priceType === priceTypesEnums.ton
                    ? `So long as you do not go over the weight limit, that is the final price you'll pay. If the weight of your debris does go over the weight limit there is an overage fee of ${moneyFormatter(quote.overage)} per additional ton, prorated to your exact overage weight.\n\n`
                    : ''
            }` +
            `You can keep the dumpster for the full ${
                quote.rentPeriod?.value
            } day rental period or call anytime before for an early pickup.${
                Number(quote.rentExtensionFee)
                    ? ` If you need the dumpster for longer you can extend for ${moneyFormatter(
                          quote.rentExtensionFee,
                      )} per additional day. Please note there is a ${
                          quote.maxRentalDaysAllowed || '30'
                      } day maximum on all rental extensions, ordering a dump and return resets this limit.`
                    : ''
            }\n\n` +
            `${specialMaterialString ? `${specialMaterialString}\n\n` : ''}` +
            `If you would like to schedule your dumpster rental, or if you have any questions, please let me know and I'll be happy to help you.\n\n` +
            webSaleUrl +
            `Thank you,\n\n` +
            `${userProfile.firstName}`;
        return navigator.clipboard.writeText(message);
    };

    const handleAddOrder = (quote: Customer.AllianceLeadQuote) => {
        history.push(routes.orders.new, onCreateOrderFromLead(lead, quote));
    };

    const handleRemoveQuote = async (quoteID: string) => {
        try {
            await client.customer().adminPortal.leads.quote.delete(lead.id, quoteID);
            showFlash('Quote Successfully Deleted', 'success');
        } catch (error) {
            console.error(error);
            showFlash('Error Deleting Quote', 'warning');
        }
    };

    const onDoNotServiceArea = () => {
        setSendingRejection(true);
        handleSendDoNotService();
    };

    const handleSendQuoteClose = () => {
        setEmailSend({
            open: false,
            toEmail: '',
            ccEmails: [],
            bccEmails: [],
            message: '',
            preText: null,
            data: undefined,
        });
    };

    const handleSendSMSClose = () => {
        setSMSSend({
            open: false,
            toNumber: '',
            message: '',
        });
    };

    const handleEmailSent = async () => {
        handleSendQuoteClose();

        if (!lead.email) {
            alert('No email sent. Lead does not have an email');
            setSendingRejection(false);
            return;
        }
        if (sendingRejection) {
            try {
                await client.customer().adminPortal.leads.update(lead.id, {
                    status: 'lost',
                    reasonLost: 'outOfArea',
                    reasonLostNote: '',
                });
                showFlash('Lead Successfully Updated', 'success');
            } catch (error) {
                showFlash('Error Updating Lead', 'warning');
            }
        }

        const event = {
            date: new Date().toISOString(),
            medium: mediumTypesEnums.email,
        };

        try {
            await client.customer().adminPortal.leads.contactEvent.create(lead.id, event);
            showFlash('Event Successfully Updated', 'success');
        } catch (error) {
            showFlash('Error Updating Event', 'warning');
        }

        setSendingRejection(false);
    };

    const handleSMSSent = async () => {
        handleSendSMSClose();

        if (!lead.phoneNumber) {
            alert('No SMS sent. Lead does not have a phone number');
            return;
        }

        const event = {
            date: new Date().toISOString(),
            medium: mediumTypesEnums.sms,
        };
        try {
            await client.customer().adminPortal.leads.contactEvent.create(lead.id, event);
            showFlash('Event Successfully Updated', 'success');
        } catch (error) {
            showFlash('Error Updating Event', 'warning');
        }
    };

    return (
        <div className="shadow-dark rounded bg-white py-1.5">
            <div className="px-4">
                <div className="flex items-center justify-between">
                    <h6 className="text-xl font-medium">Quote Details</h6>
                    <div className="flex flex-row gap-x-4">
                        <Button
                            className="btn-secondary-outlined"
                            onClick={() => history.push(routes.requestForProposal.new + `?forType=sourgum-lead&id=${lead.id}`)}
                        >
                            Create RFP
                        </Button>
                        <button className="btn-dark-grey-outlined ml-auto mr-5" onClick={() => onDoNotServiceArea()}>
                            Don't Service Area
                        </button>
                        <button className="btn-primary" onClick={() => setShowQuoteForm(true)}>
                            <PencilIcon className="mr-1 size-4" />
                            Edit
                        </button>
                    </div>
                </div>
            </div>
            <hr className="mt-2" />
            <div className="-mb-1.5 w-full overflow-x-auto">
                <table className="w-full border-collapse border-spacing-0 text-sm">
                    <thead>
                        <tr className="border-b bg-gray-100 [&>*]:whitespace-nowrap [&>*]:px-4 [&>*]:py-1.5">
                            {quoteHeadings.map((item) => (
                                <td key={item}>{item}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {lead.quotes.map((item) => (
                            <tr key={item.id} className={`border-b [&>*]:px-4 [&>*]:py-1.5 ${item.active ? '' : 'bg-disabled'}`}>
                                <td>{`${item.size.size} YD`}</td>
                                <td>{MaterialLabels[item.material as Material]}</td>
                                <td>
                                    {item.priceType === priceTypesEnums.ton ? (
                                        <div>
                                            <div>{item.tonLimit} tons</div>
                                            <div className="mt-0.5 text-sm text-gray-500">lbs: {tonLimitToPounds(item.tonLimit || 0)}</div>
                                        </div>
                                    ) : (
                                        'N/A'
                                    )}
                                </td>
                                <td>
                                    {item.priceType === priceTypesEnums.ton ? (
                                        <div>
                                            <div>{moneyFormatter(item.overage)}</div>
                                            <div className="mt-0.5 text-sm text-gray-500">
                                                per lbs: {tonLimitOverageToPerPound(item.overage || 0)}
                                            </div>
                                        </div>
                                    ) : (
                                        'N/A'
                                    )}
                                </td>
                                <td>
                                    {item.rentPeriod?.value}
                                    {' Days '}
                                    {moneyFormatter(item.rentExtensionFee)}
                                    {' / extra day'}
                                </td>
                                <td>{moneyFormatter(item.price)}</td>
                                {item.active ? (
                                    <td>
                                        <div>
                                            <button
                                                className="btn-primary-text-only mb-1 px-2 py-1"
                                                onClick={() => handleAddOrder(item)}
                                                type="button"
                                            >
                                                <PlusIcon className="mr-1 size-4" />
                                                Add Order
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="btn-secondary-text-only mb-1 px-2 py-1"
                                                onClick={() => handleSendQuote(item)}
                                                disabled={!lead.email}
                                                type="button"
                                            >
                                                <PaperAirplaneIcon className="mr-1 size-4" />
                                                Send Email Quote
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="btn-secondary-text-only mb-1 px-2 py-1"
                                                onClick={() => handleSendFollowUp(item)}
                                                disabled={lead.status !== leadStatusTypesEnums.open || !lead.email}
                                                type="button"
                                            >
                                                Send Email Follow Up
                                            </button>
                                        </div>
                                        <div>
                                            <Button
                                                className="btn-secondary-text-only mb-1 px-2 py-1"
                                                onClick={() => handleSendSMSQuote(item)}
                                                disabled={!lead.phoneNumber}
                                                startIcon={<DevicePhoneMobileIcon />}
                                            >
                                                Send SMS Quote
                                            </Button>
                                        </div>
                                        <div>
                                            <button
                                                className="btn-secondary-text-only mb-1 px-2 py-1"
                                                onClick={() => handleCopyQuoteToClipboard(item)}
                                                type="button"
                                            >
                                                Copy to Clipboard
                                            </button>
                                        </div>
                                        <button className="btn-base text-delete" onClick={() => handleRemoveQuote(item.id)} type="button">
                                            <XMarkIcon className="mr-1 size-4" />
                                            Remove
                                        </button>
                                    </td>
                                ) : (
                                    <td />
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* Dialogs */}
            <ROQuoteUpdate lead={lead} open={showQuoteForm} onClose={() => setShowQuoteForm(false)} />
            <EmailSendDialog
                open={emailSend.open}
                message={emailSend.message}
                onClose={handleSendQuoteClose}
                onEmailSent={handleEmailSent}
                preText={emailSend.preText}
                emailData={emailSend.data}
                toEmail={emailSend.toEmail}
            />
            <SMSSendDialog
                open={smsSend.open}
                message={smsSend.message}
                toNumber={smsSend.toNumber}
                onClose={handleSendSMSClose}
                onSMSSent={handleSMSSent}
            />
        </div>
    );
};

export default ROQuoteDetails;
