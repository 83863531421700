import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { DayOfWeek, UniversalService } from '@alliance-disposal/transport-types';
import { AddressSubForm } from '@wayste/sour-components';
import { AutoComplete, AutoCompleteOption, DatePicker, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { Controller, FieldPath, useFormContext } from 'react-hook-form';
import { UIContext } from '../../../contexts';
import { UniversalServiceFormData } from '../UniversalServiceCreate/UniversalServiceCreate';

interface SubscriptionServiceOrderSubFormProps {
    selectedServiceTypeFamily?: UniversalService.ServiceTypeFamily;
    setSelectedServiceTypeFamily?: React.Dispatch<React.SetStateAction<UniversalService.ServiceTypeFamily | undefined>>;
    index: number;
    mode: 'create' | 'update';
}

const defaultLineItemTypes = ['delivery', 'removal', 'cancellation', 'misc. fee', 'recurring'] as const;
type DefaultLineItemType = (typeof defaultLineItemTypes)[number];

const SubscriptionServiceOrderSubForm = ({
    index,
    mode,
    selectedServiceTypeFamily,
    setSelectedServiceTypeFamily,
}: SubscriptionServiceOrderSubFormProps) => {
    const client = useWaysteClient();
    const { showFlash, godModeActive } = useContext(UIContext);
    const [serviceTypeFamilies, setServiceTypeFamilies] = useState<UniversalService.ServiceTypeFamily[]>([]);
    const [selectedSku, setSelectedSku] = useState<UniversalService.ServiceType | undefined>(undefined);

    const { control, setValue, watch } = useFormContext<UniversalServiceFormData>();
    const watchSubscriptionStartDate = watch('subscriptionDetails.startDate');
    const watchStartDate = watch(`serviceGrouping.serviceOrders.${index}.startDate`);
    const watchPreviousStartDate = index > 0 ? watch(`serviceGrouping.serviceOrders.${index - 1}.startDate`) : undefined;

    useEffect(() => {
        if (!watchStartDate) {
            if (watchSubscriptionStartDate && !watchPreviousStartDate) {
                setValue(`serviceGrouping.serviceOrders.${index}.startDate`, watchSubscriptionStartDate);
            } else if (watchPreviousStartDate) {
                setValue(`serviceGrouping.serviceOrders.${index}.startDate`, watchPreviousStartDate);
            }
        }
    }, [watchSubscriptionStartDate, watchPreviousStartDate, watchStartDate]);

    // Define the fetch logic in a separate function
    async function fetchServiceTypeFamilies() {
        try {
            const data = await client.universalService().serviceFamily.query({ orderBy: 'name' });
            setServiceTypeFamilies(data.results);
        } catch (err) {
            console.error('error fetching service types', err);
            return [];
        }
    }

    // Use the function inside useEffect
    useEffect(() => {
        const fetchData = async () => {
            console.log('fetch hit');
            await fetchServiceTypeFamilies();
        };

        fetchData();
    }, []);

    const onServiceFamilySelect = async (id: string) => {
        await fetchServiceTypeFamilies();

        const family = serviceTypeFamilies.find((item) => item.id === id);

        const lineItemMap = new Map<string, UniversalService.ServiceLineItemType>();

        family?.lineItemTypes.forEach((item) => {
            if (defaultLineItemTypes.includes(item.description as DefaultLineItemType)) {
                lineItemMap.set(item.description, item);
            }
        });

        const recurring = lineItemMap.get('recurring');
        const delivery = lineItemMap.get('delivery');
        const removal = lineItemMap.get('removal');

        if (!recurring || !delivery || !removal) {
            showFlash('This Service type is not set up correctly and cannot be used. Please contact an administrator.', 'warning');
            if (setSelectedServiceTypeFamily) setSelectedServiceTypeFamily(undefined);
            return;
        }
        setValue(`serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${0}.lineItemTypeID`, recurring.id);

        if (recurring.minQuantity === 0) {
            setValue(`serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${0}.priceQuantity`, 1);
        } else if (recurring.minQuantity > 0) {
            setValue(`serviceGrouping.serviceOrders.${index}.subscriptionDetails.periodicEvents.${0}.priceQuantity`, recurring.minQuantity);
        }

        setValue(`serviceGrouping.serviceOrders.${index}.serviceEvents.${0}.lineItemTypeID`, delivery.id);
        setValue(`serviceGrouping.serviceOrders.${index}.serviceEvents.${1}.lineItemTypeID`, removal.id);

        if (setSelectedServiceTypeFamily) setSelectedServiceTypeFamily(family);
    };

    const handleSkuSelect = (id: string) => {
        const sku = selectedServiceTypeFamily?.serviceTypes.find((item) => item.id === id);
        setSelectedSku(sku || undefined);
    };

    return (
        <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2">
            {mode === 'update' && (
                <div className="flex flex-col gap-2 md:col-span-2">
                    <AddressSubForm
                        apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
                        fieldName={`serviceGrouping.serviceOrders.${index}.serviceLocation`}
                        showCounty
                        type="location"
                        required
                        handleSelect={(value) => {
                            setValue(`serviceGrouping.serviceOrders.${index}.serviceLocation`, value);
                        }}
                    />
                    <hr />
                </div>
            )}
            <Select
                label="Select Service Type"
                onSelect={onServiceFamilySelect}
                defaultValue={''}
                disabled={mode === 'update'}
                required
                value={selectedServiceTypeFamily?.id}
            >
                {serviceTypeFamilies.map((ServiceTypeFamily, index) => (
                    <SelectOption key={'serviceTypeFamily' + index} value={ServiceTypeFamily.id}>
                        {ServiceTypeFamily.name}
                    </SelectOption>
                ))}
            </Select>
            <Controller
                name={
                    mode === 'create'
                        ? `serviceGrouping.serviceOrders.${index}.serviceTypeID`
                        : (`serviceGrouping.serviceOrders.${index}.serviceType.id` as FieldPath<UniversalServiceFormData>)
                }
                control={control}
                render={({ field, fieldState }) => (
                    <AutoComplete
                        error={fieldState.error}
                        label="Select SKU"
                        onSelect={(value) => {
                            field.onChange(value);
                            handleSkuSelect(value);
                        }}
                        defaultValue={''}
                        disabled={!selectedServiceTypeFamily || (mode === 'update' && !godModeActive)}
                        required
                        value={(field.value as string) || ''}
                    >
                        {selectedServiceTypeFamily?.serviceTypes.map((serviceType) => (
                            <AutoCompleteOption key={serviceType.id} value={serviceType.id}>
                                {serviceType.name}
                            </AutoCompleteOption>
                        )) || [
                            <AutoCompleteOption key="default" value="">
                                Select an option
                            </AutoCompleteOption>,
                        ]}
                    </AutoComplete>
                )}
            />
            {selectedSku?.setupType === 'NO_ASSET_DELIVERED' ? (
                <>
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.startDate`}
                        rules={{
                            required: {
                                value: true,
                                message: 'Service Date is required',
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <DatePicker
                                value={field.value ? new Date(field.value) : ''}
                                onChange={(date) => field.onChange(date)}
                                error={fieldState.error}
                                // minDate={mode === 'update' ? undefined : startOfToday()}
                                required
                                label="Service Date"
                                closeOnSelect
                                clearable
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.deliveryNotes`}
                        defaultValue={''}
                        render={({ field, fieldState }) => (
                            <TextField
                                type={'string'}
                                label="Service Notes"
                                error={fieldState.error}
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                </>
            ) : (
                <>
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.startDate`}
                        render={({ field }) => (
                            <DatePicker
                                value={field.value ? new Date(field.value) : ''}
                                onChange={(date) => field.onChange(date)}
                                // minDate={mode === 'update' ? undefined : startOfToday()}
                                required
                                label="Start Date"
                                closeOnSelect
                                clearable
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.endDate`}
                        render={({ field }) => (
                            <DatePicker
                                value={field.value ? new Date(field.value) : ''}
                                onChange={(date) => field.onChange(date)}
                                // minDate={mode === 'update' ? undefined : startOfToday()}
                                label="End Date"
                                inputProps={{ disabled: mode !== 'update' }}
                                closeOnSelect
                                clearable
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.deliveryDate`}
                        render={({ field }) => (
                            <DatePicker
                                value={field.value ? new Date(field.value) : ''}
                                onChange={(date) => field.onChange(date)}
                                // minDate={mode === 'update' ? undefined : startOfToday()}
                                label="Delivery Date"
                                closeOnSelect
                                clearable
                                helperText="Only use delivery date if there is being a physical asset delivered. If the service is being performed on-site (i.e bulk pickup), leave this blank."
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.deliveryNotes`}
                        defaultValue={''}
                        render={({ field, fieldState }) => (
                            <TextField
                                type={'string'}
                                label="Delivery Notes"
                                error={fieldState.error}
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.removalDate`}
                        render={({ field }) => (
                            <DatePicker
                                value={field.value ? new Date(field.value) : ''}
                                onChange={(date) => field.onChange(date)}
                                /* minDate={
                  watch(`serviceGrouping.serviceOrders.${index}.deliveryDate`)
                    ? // @ts-expect-error - this is a date not a string and any other value it could be would be falsey
                      new Date(watch(`serviceGrouping.serviceOrders.${index}.deliveryDate`))
                    : undefined
                } */
                                label="Removal Date"
                                closeOnSelect
                                clearable
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`serviceGrouping.serviceOrders.${index}.removalNotes`}
                        defaultValue={''}
                        render={({ field, fieldState }) => (
                            <TextField
                                type={'string'}
                                label="Removal Notes"
                                error={fieldState.error}
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                </>
            )}
            <div className="md:col-span-2">
                <Controller
                    control={control}
                    name={`serviceGrouping.serviceOrders.${index}.vendorOnlyNotes`}
                    defaultValue={''}
                    render={({ field, fieldState }) => (
                        <TextField
                            type={'string'}
                            label="Vendor only Notes"
                            helperText="Notes only the Hauler can see"
                            error={fieldState.error}
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />
            </div>
            <Controller
                name={`serviceGrouping.serviceOrders.${index}.serviceFrequency`}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'Service Frequency is required',
                    },
                    min: {
                        value: 0,
                        message: 'Service Frequency must be at least 0',
                    },
                }}
                render={({ field, fieldState }) => (
                    <TextField
                        type={'number'}
                        label="Service Frequency"
                        error={fieldState.error}
                        required
                        inputProps={{
                            ...field,
                        }}
                    />
                )}
            />
            <Controller
                name={`serviceGrouping.serviceOrders.${index}.serviceFrequencyUnit`}
                rules={{ required: 'Service Frequency Unit is required' }}
                control={control}
                render={({ field, fieldState }) => (
                    <Select
                        error={fieldState.error}
                        label={'Service Frequency Unit'}
                        onSelect={field.onChange}
                        defaultValue={''}
                        required
                        value={field.value}
                    >
                        {UniversalService.ServiceFrequencyUnits.map((unit: string, index: number) => (
                            <SelectOption key={'serviceFrequencyUnit' + index} value={unit}>
                                {unit}
                            </SelectOption>
                        ))}
                    </Select>
                )}
            />
            <Controller
                name={`serviceGrouping.serviceOrders.${index}.serviceDays`}
                control={control}
                render={({ field, fieldState }) => (
                    <Select
                        multiple
                        error={fieldState.error}
                        label="Serviced on which day(s) of the week"
                        onSelect={field.onChange}
                        defaultValue={''}
                        value={field.value}
                    >
                        {Object.entries(DayOfWeek).map((option: [string, string], index: number) => (
                            <SelectOption key={'servicedDays' + index} value={option[0]}>
                                {option[1]}
                            </SelectOption>
                        ))}
                    </Select>
                )}
            />
            <div className="hidden md:block" />
            <Controller
                control={control}
                name={`serviceGrouping.serviceOrders.${index}.poNumber`}
                defaultValue={''}
                render={({ field, fieldState }) => (
                    <TextField
                        type={'string'}
                        label="PO Number"
                        helperText="If the customer has a Purchase Order (PO) number."
                        error={fieldState.error}
                        inputProps={{
                            ...field,
                        }}
                    />
                )}
            />
        </div>
    );
};

export default SubscriptionServiceOrderSubForm;
