import { Customer } from '@alliance-disposal/transport-types';

export const initialContact: Customer.AllianceCustomerContactTransport = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    department: 'primary',
    notes: '',
    sendBillingEmails: true,
    sendDispatchEmails: true,
    primaryContact: true,
    id: '',
};

export const customerStartData: Customer.AllianceCustomerCreateInput & {
    contacts: Customer.AllianceCustomerContactTransport[];
    sameAsService: boolean;
} = {
    billingAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
    },
    channel: '',
    companyName: '',
    contacts: [{ ...initialContact }],
    taxExempt: false,
    type: 'unknown',
    sameAsService: false,
    motivation: '',
    serviceLocations: [],
    stripeId: '',
    balanceLimit: undefined,
    credit: 0,
    leadID: '',
    accountManagerID: '',
    accountRepID: '',
    overallExperience: 'Positive',
    notes: '',
    doNotService: false,
    reviewed: false,
    inCollections: false,
};

export const initialValues: ServiceCreateFormProps = {
    customer: {
        ...customerStartData,
    },
};

export type ServiceCreateFormProps = {
    customer:
        | (Customer.AllianceCustomerTransport & { sameAsService: boolean })
        | (Customer.AllianceCustomerCreateInput & {
              contacts: Customer.AllianceCustomerContactTransport[];
              sameAsService: boolean;
          });
};

export type ServiceCreateFormStartingValues<T = ServiceCreateFormProps> = {
    [P in keyof T]?: T[P] extends (infer U)[]
        ? ServiceCreateFormStartingValues<U>[]
        : T[P] extends ReadonlyArray<infer U>
          ? ReadonlyArray<ServiceCreateFormStartingValues<U>>
          : ServiceCreateFormStartingValues<T[P]>;
};
