import React from 'react';
import { V1 } from '@alliance-disposal/pricing';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { Button, CurrencyTextField, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { PlusIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/solid';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import ReviewStatusChanger from '../../../pages/billing/components/ReviewStatusChanger/review-status-changer';
import { haulerChargeItems, priceTypesEnums } from '../../../utils/shared-types';
import { type FormPayable, blankHaulerCost } from './OrderEnterTicket';

interface PayableGroupProps {
    formPayable: FormPayable;
    formPayableIndex: number;
    order: Order.AllianceOrderTransport;
    watchTonsDumped?: number | '';
    onFormPayableChange: (updatedFormPayable: FormPayable) => void;
}

const HaulerCostMenuItems = Object.entries(haulerChargeItems).map((item) => (
    <SelectOption key={`billItems-${item[0]}`} value={item[0]}>
        {item[1]}
    </SelectOption>
));

export const PayableGroup = ({ formPayable, formPayableIndex, order, watchTonsDumped, onFormPayableChange }: PayableGroupProps) => {
    const { control, setValue } = useFormContext();
    const watchLineItems = useWatch({ name: `formPayables[${formPayableIndex}].invoiceDetails.lineItems`, control });
    const {
        fields: lineItemFields,
        append: appendLineItem,
        remove: removeLineItem,
    } = useFieldArray({
        control,
        name: `formPayables[${formPayableIndex}].invoiceDetails.lineItems`,
    });

    const getChargeItemHelperText = (itemName: string) => {
        if (order.haulerPricingSnapshot) {
            if (order.haulerPricingSnapshot?.tonLimit.value) {
                const subtotalValue = V1.quotedPriceBuild(
                    order.haulerPricingSnapshot?.priceType === priceTypesEnums.yard
                        ? order.haulerPricingSnapshot.size
                        : order.haulerPricingSnapshot?.tonLimit.value,
                    order.haulerPricingSnapshot.dump,
                    order.haulerPricingSnapshot.haul,
                    0,
                    0,
                    false,
                    0,
                ).total;
                if (itemName === 'haul') {
                    return `Should be ${moneyFormatter(subtotalValue)}`;
                }
                if (itemName === 'dump') {
                    if ((Number(watchTonsDumped) || 0) <= order.haulerPricingSnapshot?.tonLimit.value) {
                        return `Should be ${moneyFormatter(0)}`;
                    }
                    return `Should be ${moneyFormatter(
                        (order.haulerPricingSnapshot.dump ? order.haulerPricingSnapshot.dump : 0) *
                            ((Number(watchTonsDumped) || 0) - order.haulerPricingSnapshot?.tonLimit.value),
                    )}`;
                }
                return null;
            }
            if (itemName === 'haul') {
                return `Should be ${moneyFormatter(order.haulerPricingSnapshot.haul)}`;
            }
            if (itemName === 'dump') {
                return `Should be ${moneyFormatter(
                    (watchTonsDumped
                        ? (order.haulerPricingSnapshot.dump ? order.haulerPricingSnapshot.dump : 0) * +watchTonsDumped
                        : order.haulerPricingSnapshot.dump) || undefined,
                )}`;
            }
        }
        return null;
    };

    const getChargeItemError = (item: Invoice.LineItemInputTransport) => {
        if (!item || !order.haulerPricingSnapshot || !+Number(item?.totalPrice)) return null;
        if (item.itemName === 'haul') {
            const subtotalValue = V1.quotedPriceBuild(
                order.haulerPricingSnapshot?.priceType === priceTypesEnums.yard
                    ? order.haulerPricingSnapshot.size
                    : order.haulerPricingSnapshot?.tonLimit.value,
                order.haulerPricingSnapshot.dump,
                order.haulerPricingSnapshot.haul,
                0,
                0,
                false,
                0,
            ).total;
            if (
                order.haulerPricingSnapshot?.tonLimit.value
                    ? +Number(item.totalPrice) !== subtotalValue
                    : +Number(item.totalPrice) !== order.haulerPricingSnapshot.haul
            ) {
                return <div className="ml-3.5 mt-1 text-xs text-red-500">Haul does not match what was quoted.</div>;
            }
        }
        if (item.itemName === 'dump') {
            if (
                order.haulerPricingSnapshot?.tonLimit.value
                    ? moneyFormatter(+Number(item.totalPrice)) !==
                      moneyFormatter(
                          (order.haulerPricingSnapshot.dump ? order.haulerPricingSnapshot.dump : 0) *
                              ((Number(watchTonsDumped) || 0) - order.haulerPricingSnapshot?.tonLimit.value),
                      )
                    : moneyFormatter(Number(item.totalPrice)) !==
                      moneyFormatter(
                          (watchTonsDumped
                              ? (order.haulerPricingSnapshot.dump ? order.haulerPricingSnapshot.dump : 0) * +watchTonsDumped
                              : order.haulerPricingSnapshot.dump) || 0,
                      )
            ) {
                return <div className="ml-3.5 mt-1 text-xs text-red-500">Dump does not match what was quoted.</div>;
            }
        }
        return null;
    };

    const getPayableTotal = () => {
        return (watchLineItems as Invoice.LineItemInputTransport[]).reduce(
            (accumulator, currentValue) => accumulator + Number(currentValue.totalPrice),
            0,
        );
    };

    const handleReviewStatusChange = (newStatus: Invoice.ReviewStatus) => {
        setValue(`formPayables[${formPayableIndex}].invoiceDetails.reviewStatus`, newStatus, { shouldValidate: true });
        const updatedFormPayable = {
            ...formPayable,
            invoiceDetails: {
                ...formPayable.invoiceDetails,
                reviewStatus: newStatus,
            },
        };
        onFormPayableChange(updatedFormPayable);
    };

    return (
        <div className="grid w-full gap-4">
            {formPayable.haulerID && formPayable.haulerID !== order.haulerID ? (
                <div className="w-full">
                    <p>Bill from {formPayable.vendorName}</p>
                </div>
            ) : null}
            <div className="flex items-center gap-4">
                <div className="max-w-[250px]">
                    <Controller
                        name={`formPayables[${formPayableIndex}].invoiceDetails.invoiceNumber`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                label="Hauler invoice number"
                                required
                                error={error}
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                </div>

                {formPayable.invoiceDetails.reviewStatus && (
                    <ReviewStatusChanger orderID={order.id} payable={formPayable} onChange={handleReviewStatusChange} />
                )}
            </div>
            <div className="grid w-full grid-cols-3 gap-4">
                {lineItemFields.map((lineItem, lineItemIndex) => (
                    <React.Fragment key={lineItem.id}>
                        <div className="w-full">
                            <Controller
                                name={`formPayables[${formPayableIndex}].invoiceDetails.lineItems[${lineItemIndex}].itemName`}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label="Charge Item"
                                        onSelect={(e) => {
                                            field.onChange(e);
                                        }}
                                        value={field.value}
                                    >
                                        {HaulerCostMenuItems}
                                    </Select>
                                )}
                            />
                        </div>
                        <div className="w-full">
                            <Controller
                                name={`formPayables[${formPayableIndex}].invoiceDetails.lineItems[${lineItemIndex}].totalPrice`}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <CurrencyTextField
                                        label="Amount"
                                        required
                                        value={field.value}
                                        useCents
                                        onChange={(value) => field.onChange(value)}
                                        helperText={getChargeItemHelperText(watchLineItems[lineItemIndex]?.itemName) || undefined}
                                        error={error}
                                    />
                                )}
                            />
                            {getChargeItemError(watchLineItems[lineItemIndex])}
                        </div>
                        <div className="flex w-full">
                            <button className="btn-icon ml-0" onClick={() => removeLineItem(lineItemIndex)} type="button">
                                <TrashIcon className="text-delete h-6 w-6" />
                            </button>
                        </div>
                    </React.Fragment>
                ))}
                <div className="text-right font-bold">Payable total:</div>
                <div>{moneyFormatter(getPayableTotal())}</div>
            </div>
            <div className="w-full">
                <Button
                    className="btn-secondary-text-only whitespace-nowrap text-sm"
                    onClick={() => appendLineItem(blankHaulerCost)}
                    startIcon={<PlusIcon />}
                    disabled={Boolean(formPayable?.invoiceDetails?.payments?.length ?? 0 > 0)}
                >
                    Add another item to hauler bill
                </Button>
            </div>
        </div>
    );
};
