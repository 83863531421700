import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { formatDateToYYYYMMDD, sanitizeFromPayload } from '@wayste/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { UIContext } from '../../../contexts';
import { UniversalServiceFormData } from '../UniversalServiceCreate/UniversalServiceCreate';
import SubscriptionServiceOrderPricingSubForm from '../components/SubscriptionServiceOrderPricingSubForm';

const SubscriptionServiceOrderPricingUpdate = () => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const history = useHistory();
    const params: { serviceGroupingId: string; serviceOrderId: string } = useParams();
    const methods = useForm<UniversalServiceFormData>();
    const { handleSubmit, reset } = methods;
    const [serviceGrouping, setServiceGrouping] = useState<UniversalService.ServiceGrouping | undefined>(undefined);
    const [serviceOrder, setServiceOrder] = useState<UniversalService.ServiceOrder | undefined>(undefined);
    const [index, setIndex] = useState<number>(0);

    const fetchServiceGrouping = async () => {
        try {
            const serviceGrouping = await client.universalService().serviceGrouping.fetch(params.serviceGroupingId);
            setServiceGrouping(serviceGrouping);
            serviceGrouping.serviceOrders.map((order, index) => {
                if (order.id === params.serviceOrderId) {
                    setIndex(index);
                    setServiceOrder(order);
                }
            });
        } catch (error) {
            console.warn('fetchServiceOrder for ID: ', error);
            return { status: 'error', data: null };
        }
    };

    useEffect(() => {
        fetchServiceGrouping();
    }, []);

    useEffect(() => {
        if (!serviceGrouping) return;
        reset({
            serviceGrouping: serviceGrouping,
        });
    }, [serviceGrouping]);

    const onSubmit = async (data: UniversalServiceFormData) => {
        try {
            const newSubscriptionDetails = data.serviceGrouping.serviceOrders[index]
                .subscriptionDetails as UniversalService.SubscriptionDetails;
            if (newSubscriptionDetails?.endDate) {
                newSubscriptionDetails.endDate = formatDateToYYYYMMDD(new Date(newSubscriptionDetails.endDate));
            } else {
                newSubscriptionDetails.endDate = undefined;
            }
            // UPDATE SERVICE ORDER
            const serviceOrderUpdatePayload: UniversalService.ServiceOrderUpdate = {
                ...serviceOrder,
                subscriptionDetails: newSubscriptionDetails,
                // serviceDays: serviceOrder?.serviceDays.map((day) => Number(day)),
                vendorID: serviceOrder?.vendorID || undefined,
            };

            await client
                .universalService()
                .serviceGrouping.serviceOrder.update(
                    params.serviceGroupingId,
                    params.serviceOrderId,
                    sanitizeFromPayload(serviceOrderUpdatePayload, 'accountManagerID'),
                );

            showFlash('Service Order Successfully Updated', 'success');
            history.goBack();
        } catch (error) {
            console.error('Service Order Update error ', error);
            showFlash('Error Updating Service Order', 'warning');
        }
    };

    return (
        <div className="flex max-w-[960px] flex-1 flex-col p-16">
            <FormProvider {...methods}>
                <form>
                    <div className="mb-6 pb-2 text-2xl">Update Service Order Pricing</div>
                    <div className="space-y-6">
                        <SubscriptionServiceOrderPricingSubForm
                            methods={methods}
                            index={index}
                            selectedServiceTypeFamily={serviceOrder?.serviceType.family}
                            update
                        />
                    </div>
                    <div className="float-right mt-2">
                        <Button className="btn-dark-grey-outlined mr-4" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                        <Button className="btn-primary" onClick={handleSubmit(onSubmit)}>
                            Update
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};
export default SubscriptionServiceOrderPricingUpdate;
