import React, { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { Button, Dialog } from '@wayste/sour-ui';
import { PlusIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { UIContext } from '../../contexts';
import { mediumTypes } from '../../utils/shared-types';
import ContactedSubFrom from '../LeadForms/ContactedSubForm';

export interface ContactedDetailsProps {
    lead: Customer.AllianceLeadTransport;
}

const ContactedDetails = ({ lead }: ContactedDetailsProps) => {
    const client = useWaysteClient();
    const { idToProfileMap } = client.profile().adminPortal.getRosterFromMemory();
    const [open, setOpen] = useState(false);

    return (
        <div className="shadow-dark rounded bg-white py-1.5">
            <div className="px-4">
                <div className="flex items-center justify-between">
                    <h6 className="text-xl font-medium">Contacted Details</h6>
                    <button className="btn-primary" onClick={() => setOpen(true)}>
                        <PlusIcon className="mr-1.5 h-5 w-5" />
                        Add
                    </button>
                </div>
                Created at {format(new Date(lead.metadata.createdAt), 'MM/dd/yy hh:mm aaa')} by{' '}
                {idToProfileMap[lead.metadata.createdByUserID]?.firstName} {idToProfileMap[lead.metadata.createdByUserID]?.lastName}
            </div>
            <hr className="mt-2" />
            <div className="-mb-1.5 w-full">
                <table className="w-full border-collapse border-spacing-0 text-sm">
                    <thead>
                        <tr className="font-norma bg-gray-100 text-left [&>*]:px-4 [&>*]:py-1.5">
                            <th>Date</th>
                            <th>Medium</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lead.contactEvents.map((item: Customer.AllianceContactEvent) => (
                            <tr key={item.date} className="[&>*]:border-b [&>*]:px-4 [&>*]:py-1.5">
                                {item?.date && <td>{format(new Date(item.date), 'MM/dd/yy hh:mm aaa')}</td>}
                                <td>{mediumTypes[item?.medium as keyof typeof mediumTypes]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <ContactedDetailsUpdate lead={lead} open={open} onClose={() => setOpen(false)} />
        </div>
    );
};

export default ContactedDetails;

const ContactedDetailsUpdate = ({ lead, open, onClose }: { lead: Customer.AllianceLeadTransport; open: boolean; onClose: () => void }) => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<Customer.AllianceContactEventCreateTransport>({
        defaultValues: {
            date: undefined,
            medium: '',
        },
    });
    const {
        handleSubmit,
        formState: { isValid },
        reset,
    } = methods;

    const handleClose = () => {
        onClose();
        reset();
    };

    const submitForm = async (data: Customer.AllianceContactEventCreateTransport) => {
        setIsLoading(true);
        try {
            await client.customer().adminPortal.leads.contactEvent.create(lead.id, { ...data, date: data.date as string | undefined });
            showFlash('Lead Successfully Updated', 'success');
            handleClose();
        } catch (error) {
            console.log(error);
            showFlash('Error Updating Lead', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} styledTitle="Add Contacted Event" className="max-w-4xl">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <ContactedSubFrom />
                    <div className="mt-4 flex justify-end gap-4">
                        <Button className="btn-dark-grey-outlined" onClick={handleClose} disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button type="submit" className="btn-primary" loading={isLoading} disabled={!isValid}>
                            Save
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Dialog>
    );
};
