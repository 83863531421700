import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { cardLogoResolver } from '@wayste/sour-components';
import { useSourContext } from '@wayste/sour-context';
import { Button, Loading, Tooltip } from '@wayste/sour-ui';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import type { Stripe } from 'stripe';
import AddCardDialog from '../../../components/AddCardDialog/';
import DetailsCardWrapper from '../../../components/DetailsCardWrapper';

type CustomerCardsProps = {
    customer: Customer.AllianceCustomerTransport;
};

const CustomerCards = ({ customer }: CustomerCardsProps) => {
    const { setShowToast, useConfirmationDialog } = useSourContext();
    const [stripeCustomer, setStripeCustomer] = useState<Stripe.Customer>();
    const [stripeLoading, setStripeLoading] = useState(false);
    const [addCardDialogOpen, setAddCardDialogOpen] = useState(false);
    const { getConfirmation } = useConfirmationDialog();

    const client = useWaysteClient();
    useEffect(() => {
        if (customer.stripeId) getStripeData(customer.stripeId);
    }, []);

    const getStripeData = async (stripeID: string) => {
        setStripeLoading(true);
        try {
            const data = await client.stripe().adminPortal.customer.fetch(stripeID);
            setStripeCustomer(data);
            setStripeLoading(false);
        } catch (error) {
            console.error('Stripe Data Error: ', error);
            setShowToast({
                severity: 'error',
                message: 'An Error Occurred',
            });
            setStripeLoading(false);
        }
    };

    const handleCardAdded = () => {
        if (customer.stripeId) getStripeData(customer.stripeId);
        setAddCardDialogOpen(false);
    };

    const handleSetDefaultCard = (source: Stripe.Source) => async () => {
        if (!customer.stripeId || !source.id) {
            console.error('No Stripe ID or Source ID');
            return;
        }

        if (!source.card) {
            console.error('Not a card');
            return;
        }

        const card = source.card;
        const confirmed = await getConfirmation({
            title: 'Set Default Card',
            message: `Are you sure you want to set 
        ${card.brand} •••• ${card.last4} ${card.exp_month}/${card.exp_year?.toString().slice(-2)} as the new default?`,
        });
        if (!confirmed) return;

        try {
            setStripeLoading(true);
            await client.stripe().adminPortal.customer.update(customer.stripeId, { default_source: source.id });
            getStripeData(customer.stripeId);
            setShowToast({
                severity: 'success',
                message: 'Default Card Set',
            });
        } catch (error) {
            console.error('Set Default Card Error: ', error);
            setShowToast({
                severity: 'error',
                message: 'An Error Occurred',
            });
        } finally {
            setStripeLoading(false);
        }
    };

    const deleteCard = (source: Stripe.Source) => async () => {
        if (!customer.stripeId || !source.id) {
            console.error('No Stripe ID or Source ID');
            return;
        }
        if (!source.card) {
            console.error('Not a card');
            return;
        }

        const card = source.card;
        const confirmed = await getConfirmation({
            title: 'Delete Card',
            message: `Are you sure you want to delete
                ${card.brand} •••• ${card.last4} ${card.exp_month}/${card.exp_year?.toString().slice(-2)}?`,
        });
        if (!confirmed) return;

        try {
            setStripeLoading(true);
            await client.stripe().adminPortal.customer.sources.delete(customer.stripeId, source.id);
            getStripeData(customer.stripeId);
            setShowToast({
                severity: 'success',
                message: 'Card Deleted',
            });
        } catch (error) {
            console.error('Delete Card Error: ', error);
            setShowToast({
                severity: 'error',
                message: 'An Error Occurred',
            });
        } finally {
            setStripeLoading(false);
        }
    };

    return (
        <DetailsCardWrapper
            heading={`Cards (${(stripeCustomer?.sources as any)?.total_count ?? 0})`} //won't recognize total_count as a field
            buttons={[
                {
                    label: (
                        <>
                            <PlusIcon className="mr-1 size-5" /> Add Card
                        </>
                    ),
                    onClick: () => setAddCardDialogOpen(true),
                },
            ]}
        >
            {stripeLoading ? (
                <div className="flex w-full items-center justify-center">
                    <Loading />
                </div>
            ) : (
                <div>
                    {stripeCustomer?.sources?.data?.map((source) => {
                        let card = null;
                        if ('card' in source && source.type === 'card') card = source.card;
                        if (card)
                            return (
                                <div
                                    key={source.id}
                                    className={`my-2 flex items-center justify-between rounded-lg bg-gray-100 px-4 py-1 shadow`}
                                >
                                    <div className="flex items-center gap-4">
                                        <div className="text-lg font-semibold">
                                            {card.brand ? cardLogoResolver(card.brand) : card.brand}
                                        </div>
                                        <div className="text-gray-500">•••• {card.last4}</div>
                                        <div className="text-sm text-gray-400">
                                            Exp: {card.exp_month}/{card.exp_year?.toString().slice(-2)}
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-2">
                                        <div>
                                            {source.id === stripeCustomer.default_source ? (
                                                <span className="text-wayste-blue-500 bg-wayste-blue-100 rounded-md px-2 py-1 text-xs">
                                                    Default
                                                </span>
                                            ) : (
                                                <Button
                                                    className="text-wayste-blue-400 btn-secondary-text-only"
                                                    onClick={handleSetDefaultCard(source as Stripe.Source)}
                                                >
                                                    Set as default
                                                </Button>
                                            )}
                                        </div>
                                        <Tooltip
                                            text={
                                                stripeCustomer?.sources?.data.length === 1
                                                    ? 'Cannot delete last card.'
                                                    : source.id === stripeCustomer.default_source
                                                      ? 'Cannot delete default card. Please set another card as default first.'
                                                      : ''
                                            }
                                        >
                                            <Button
                                                className="text-delete btn-icon"
                                                disabled={
                                                    stripeCustomer?.sources?.data.length === 1 ||
                                                    source.id === stripeCustomer.default_source
                                                }
                                                onClick={deleteCard(source as Stripe.Source)}
                                            >
                                                <TrashIcon className="size-5" />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </div>
                            );
                        return null;
                    })}
                </div>
            )}
            <AddCardDialog
                stripeCustomer={stripeCustomer}
                open={addCardDialogOpen}
                onSuccess={handleCardAdded}
                onClose={() => setAddCardDialogOpen(false)}
            />
        </DetailsCardWrapper>
    );
};

export default CustomerCards;
