import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { moneyFormatter } from '@wayste/utils';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { UIContext } from '../../contexts';
import { commercialBillingFrequencies, commercialMaterials, commercialServiceFrequency } from '../../utils/shared-types';
import { USVendorQuoteUpdate } from '../LeadUpdate';

const quoteHeadings = ['Hauler', 'Sku', 'Service Frequency', 'Quantity', 'Billing Frequency', 'Price Quoted', ''];

const USVendorQuoteDetails = ({ rawLead }: { rawLead: Customer.AllianceLeadTransport }) => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const [lead, setLead] = useState<Customer.AllianceLeadTransport>(rawLead);
    const [showQuoteUpdate, setShowQuoteUpdate] = useState(false);
    const [serviceTypeMap, setServiceTypeMap] = useState<{ [key: string]: string }>({});

    const handleGetServiceTypes = async (quotes: Customer.AllianceLeadQuote[]) => {
        const newMap: { [key: string]: string } = {};
        for (const quote of quotes) {
            if (quote.serviceTypeID && quote.serviceFamilyID) {
                if (!newMap[quote.serviceTypeID]) {
                    const serviceResponse = await client.universalService().serviceType.fetch(quote.serviceFamilyID, quote.serviceTypeID);
                    newMap[quote.serviceTypeID] = serviceResponse.name;
                }
            }
        }
        setServiceTypeMap(newMap);
    };

    useEffect(() => {
        if (!rawLead) return;
        handleGetServiceTypes(rawLead.quotes);
        setLead(rawLead);
    }, [rawLead]);

    const handleRemoveHaulerQuote = async (quoteID: string) => {
        try {
            await client.customer().adminPortal.leads.haulerQuote.delete(lead.id, quoteID);
            showFlash('Quote Successfully Deleted', 'success');
        } catch (error) {
            console.log(error);
            showFlash('Error Deleting Quote', 'warning');
        }
    };

    return (
        <div className="shadow-dark rounded bg-white py-1.5">
            <div className="px-4">
                <div className="flex justify-between">
                    <div className="text-xl">Bid Details from Hauler</div>
                    <button className="btn-primary" type="button" onClick={() => setShowQuoteUpdate(true)}>
                        <PlusIcon className="mr-1.5 h-5 w-5" />
                        Add
                    </button>
                </div>
            </div>
            <hr className="mt-2" />
            <div className="-mb-1.5 w-full overflow-x-auto">
                <table className="w-full border-collapse border-spacing-0 text-sm">
                    <thead>
                        <tr className="bg-gray-100 text-left font-normal [&>*]:whitespace-nowrap [&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal">
                            <th>Hauler</th>
                            {quoteHeadings.map((item) => (
                                <th key={item}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {lead.haulerQuotes.map((item) => (
                            <tr key={item.id} className={`[&>*]:border-b [&>*]:px-4 [&>*]:py-1.5 ${item.active ? '' : 'bg-disabled'}`}>
                                <td>{item.haulerName}</td>
                                <td>
                                    {
                                        // This is the old US lead implementation
                                        item.size?.size && item.material
                                            ? `${item.size?.size} ${
                                                  Number(item.size.size) === 96 || Number(item.size.size) === 64 ? 'Gal' : 'YD'
                                              } - ${commercialMaterials[item.material as keyof typeof commercialMaterials]}`
                                            : // Thi is the new US lead implementation
                                              serviceTypeMap[item.serviceTypeID as string]
                                    }
                                </td>
                                <td>{commercialServiceFrequency[item.frequency as keyof typeof commercialServiceFrequency]}</td>
                                <td>{item.quantity}</td>
                                <td>{commercialBillingFrequencies[item.billingFrequency as keyof typeof commercialBillingFrequencies]}</td>
                                <td>{moneyFormatter(item.price)}</td>
                                <td>
                                    {item.active ? (
                                        <button type="button" className="btn-icon" onClick={() => handleRemoveHaulerQuote(item.id)}>
                                            <XMarkIcon className="text-delete h-6 w-6" />
                                        </button>
                                    ) : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* Dialogs */}
            <USVendorQuoteUpdate onClose={() => setShowQuoteUpdate(false)} open={showQuoteUpdate} lead={lead} />
        </div>
    );
};

export default USVendorQuoteDetails;
