import { memo } from 'react';
import { Invoice } from '@alliance-disposal/transport-types';

export interface GridCellStatusProps {
    value: Invoice.InvoiceStatus | 'NONE';
    width?: number;
}

export const GridCellStatus = memo(function GridCellStatus(props: GridCellStatusProps) {
    switch (props.value) {
        case 'DRAFT':
            return (
                <div className="inline-flex h-6 items-center rounded-full border-2 border-dashed border-[#e0e0e0] bg-white px-2.5 text-xs font-medium text-[#6b7280]">
                    Draft
                </div>
            );
        case 'PAST_DUE':
            return (
                <div className="bg-error-light text-error-dark inline-flex h-6 items-center rounded-full px-2.5 text-xs font-medium">
                    Past due
                </div>
            );
        case 'PAID':
            return (
                <div className="inline-flex h-6 items-center rounded-full bg-[#d7f7c2] px-2.5 text-xs font-medium text-[#05690d]">Paid</div>
            );
        case 'NONE':
            return (
                <div className="inline-flex h-6 items-center rounded-full border-2 border-[#e0e0e0] bg-white px-2.5 text-xs font-medium text-[#05690d]">
                    None
                </div>
            );
        case 'VOID':
            return (
                <div className="inline-flex h-6 items-center rounded-full bg-red-100 px-2.5 text-xs font-semibold text-red-900">VOID</div>
            );
        default:
            return (
                <div className="inline-flex h-6 items-center rounded-full bg-[#cff5f6] px-2.5 text-xs font-medium text-[#0055bc]">Open</div>
            );
    }
});
