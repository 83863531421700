import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Hauler } from '@alliance-disposal/transport-types';
import { SelectOption as SelectOptionSchemaType } from '@alliance-disposal/wql';
import { useSourContext } from '@wayste/sour-context';
import { Button, Checkbox, Select, SelectOption, TextField, Tooltip, phoneNumberMasker } from '@wayste/sour-ui';
import { formatE164ToUSPhoneNumber, formatUSPhoneNumberToE164 } from '@wayste/utils';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { departmentTypes as departments } from '../../utils';

interface ContactSubFormProps {
    fieldName?: string;
    hideButtons?: boolean;
    staticPrimaryDisable?: boolean;
    isEditing?: boolean;
    existingCustomer?: Customer.AllianceCustomerTransport;
    locationOptions?: SelectOptionSchemaType[];
    disabled?: boolean;
    /**
     * Pass checkForDuplicateEmail if you don't want to allow these contacts to have the same email as any other contact in the db.
     * If using for anything other than customer you'll need to update the types in handleEmailDuplicate
     */
    checkForDuplicateEmail?: 'customer';
    /**
     * Use duplicateEmailOnConfirm if you want to handle the duplicate email case yourself. Otherwise it leaves the email field blank.
     */
    duplicateEmailOnConfirm?: (contact: Customer.AllianceCustomerTransport) => void;
}

export const ContactSubForm = ({
    fieldName,
    hideButtons,
    staticPrimaryDisable,
    isEditing,
    locationOptions,
    checkForDuplicateEmail,
    // duplicateEmailOnConfirm,
    disabled,
    existingCustomer,
}: ContactSubFormProps) => {
    if (!fieldName) {
        fieldName = '';
    }
    const client = useWaysteClient();
    const { useConfirmationDialog, setShowAlert } = useSourContext();
    const { getConfirmation } = useConfirmationDialog();
    const [primaryContactIndex, setPrimaryContactIndex] = useState<number | undefined>(undefined);
    const [disableNonePrimary, setDisableNonePrimary] = useState<boolean>(true);
    const [originalData, setOriginalData] = useState<Array<Hauler.HaulerContact | Customer.AllianceCustomerContactTransport>>([]);

    ////////////////////////////////////////////////
    // CONSTANTS
    ////////////////////////////////////////////////

    const departmentTypes = departments;

    const blankContact = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        department: '',
        notes: '',
        sendBillingEmails: false,
        sendDispatchEmails: false,
        primaryContact: false,
        locationPreferences: [],
    };

    // HOOKS FORM CONTEXT
    const { setValue, control, getValues } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        name: `${fieldName}contacts`,
    });

    // WATCH CONTACTS FIELD ARRAY
    const data = useWatch({
        control,
        name: `${fieldName}contacts`,
    });

    // CONTROL PRIMARY CONTACT DISABLE
    useEffect(() => {
        if (data) {
            const shouldDisable = hasPrimaryContact(data);
            setDisableNonePrimary(shouldDisable);
        }
    }, [data]);

    //////////////////////////////////////////////////
    // FUNCTIONS
    //////////////////////////////////////////////////
    const handleAddContact = () => {
        append({ ...blankContact });
    };

    const handleRemoveContact = (index: number) => {
        remove(index);
    };

    const hasPrimaryContact = (data: Array<Hauler.HaulerContact | Customer.AllianceCustomerContactTransport>) => {
        let exists = false;
        data.forEach((item: Hauler.HaulerContact | Customer.AllianceCustomerContactTransport, index: number) => {
            if (item.primaryContact) {
                setPrimaryContactIndex(index);
                exists = true;
            }
        });
        return exists;
    };

    const disablePrimary = (index: number) => {
        if (originalData[index] && staticPrimaryDisable) {
            if (originalData[index].primaryContact === true) {
                return true;
            }
            return false;
        }
        return primaryContactIndex !== index && disableNonePrimary;
    };

    useEffect(() => {
        if (data) {
            hasPrimaryContact(data);
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            const clonedData = JSON.parse(JSON.stringify(data)); // Clone the data object
            setOriginalData(clonedData);
        }
    }, []);

    const handleEmailDuplicate = async (index: number) => {
        if (!checkForDuplicateEmail) return;
        const contacts: Customer.AllianceCustomerContactTransport[] = getValues(fieldName + 'contacts');
        if (!contacts) {
            return;
        }
        if (index === -1 || !contacts[index].email?.toLowerCase().trim()) {
            return;
        }
        const primaryEmail = contacts[index].email?.toLowerCase().trim();
        let response: Customer.AllianceCustomerTransport[] = [];
        if (checkForDuplicateEmail === 'customer') {
            response = await client.customer().adminPortal.query({ email: primaryEmail });
        }
        if (response.length === 0) {
            return;
        }
        await getConfirmation({
            title: 'Duplicate Customer',
            message: 'This is a duplicate email. Adding duplicate emails is not allowed.',
            confirmText: 'Ok',
            cancelText: 'Ok',
        });
        setValue(`${fieldName}contacts.${index}.email`, '');
        // if (confirmed) {
        //     if (duplicateEmailOnConfirm) {
        //         duplicateEmailOnConfirm(response[0]);
        //     } else {
        //         setValue(`${fieldName}contacts.${primaryContactIndex}.email`, '');
        //     }
        // } else {

        // }
    };

    const alertIfPrimaryIsGettingEmails = (index: number, value: boolean, field: 'sendDispatchEmails' | 'sendBillingEmails') => {
        if (
            isEditing &&
            existingCustomer &&
            value === false &&
            existingCustomer.contacts.filter((contact) => contact.id !== data[index].id).every((contact) => !contact[field])
        ) {
            setShowAlert({
                severity: 'warning',
                title: 'Primary Contact Will Receive Emails',
                message: `No contact has Send ${field === 'sendDispatchEmails' ? 'Dispatch' : 'Billing'} Emails selected. Meaning the Primary Contact will default to receiving them regardless if they have it selected or not.`,
            });
        }
    };

    return (
        <>
            {fields.map((item, index) => (
                <div className="space-y-6" key={item.id + index}>
                    <div className="flex flex-row space-x-2" key={item.id + index + 'name'}>
                        <Controller
                            key={item.id + index + 'firstName'}
                            control={control}
                            name={`${fieldName}contacts.${index}.firstName`}
                            defaultValue={''}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Contact name is required',
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    type={'string'}
                                    label="First name"
                                    error={fieldState.error}
                                    required
                                    inputProps={{
                                        ...field,
                                        disabled: disabled,
                                    }}
                                />
                            )}
                        />

                        <Controller
                            key={item.id + index + 'lastName'}
                            control={control}
                            name={`${fieldName}contacts.${index}.lastName`}
                            defaultValue={''}
                            render={({ field, fieldState }) => (
                                <TextField
                                    type={'string'}
                                    label="Last name"
                                    error={fieldState.error}
                                    inputProps={{
                                        ...field,
                                        disabled: disabled,
                                    }}
                                />
                            )}
                        />
                    </div>

                    <div className="flex flex-row space-x-2">
                        <Controller
                            key={item.id + index + 'email'}
                            control={control}
                            name={`${fieldName}contacts.${index}.email`}
                            rules={{
                                required: {
                                    value: primaryContactIndex === index ? true : false,
                                    message: 'Email is required',
                                },
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email address',
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    type={'string'}
                                    label="Email"
                                    required={primaryContactIndex === index ? true : false}
                                    error={fieldState.error}
                                    inputProps={{
                                        ...field,
                                        disabled: isEditing || disabled,
                                        onBlur: () => {
                                            handleEmailDuplicate(index);
                                            field.onBlur();
                                        },
                                    }}
                                />
                            )}
                        />

                        <Controller
                            key={item.id + index + 'phoneNumber'}
                            control={control}
                            name={`${fieldName}contacts.${index}.phoneNumber`}
                            defaultValue={''}
                            rules={{
                                required: {
                                    value: primaryContactIndex === index ? true : false,
                                    message: 'Phone number is required',
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    startAdornment="+1"
                                    type={'string'}
                                    label="Phone number"
                                    error={fieldState.error}
                                    required={primaryContactIndex === index ? true : false}
                                    inputProps={{
                                        ...field,
                                        disabled: disabled,
                                        value: formatE164ToUSPhoneNumber(field.value),
                                        onChange: (e) => {
                                            setValue(
                                                `${fieldName}contacts.${index}.phoneNumber`,
                                                formatUSPhoneNumberToE164(phoneNumberMasker(e.target.value)),
                                            );
                                        },
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="flex flex-row space-x-2">
                        <Controller
                            key={item.id + index + 'department'}
                            name={`${fieldName}contacts.${index}.department`}
                            control={control}
                            render={({ field, fieldState }) => (
                                <Select
                                    error={fieldState.error}
                                    label={'Department'}
                                    onSelect={field.onChange}
                                    defaultValue={''}
                                    value={field.value}
                                    disabled={disabled}
                                >
                                    {Object.entries(departmentTypes).map((option: [string, string], index: number) => (
                                        <SelectOption key={`contacts.${index}.department` + index} value={option[0]}>
                                            {option[1]}
                                        </SelectOption>
                                    ))}
                                </Select>
                            )}
                        />
                        {locationOptions ? (
                            <Controller
                                key={item.id + index + 'locationPreferences'}
                                name={`${fieldName}contacts.${index}.locationPreferences`}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Select
                                        multiple
                                        error={fieldState.error}
                                        label={'Notify Locations'}
                                        disabled={disabled}
                                        onSelect={(value: string[]) => {
                                            if (value.includes('All')) {
                                                setValue(`${fieldName}contacts.${index}.locationPreferences`, []);
                                            } else {
                                                setValue(`${fieldName}contacts.${index}.locationPreferences`, value);
                                            }
                                        }}
                                        defaultValue={''}
                                        value={field.value}
                                    >
                                        {locationOptions?.map((option: SelectOptionSchemaType, index: number) => (
                                            <SelectOption key={`contacts.${index}.locationPreferences` + index} value={option.label}>
                                                {option.value}
                                            </SelectOption>
                                        ))}
                                    </Select>
                                )}
                            />
                        ) : (
                            <></>
                        )}
                    </div>

                    <div className="ml-1 grid grid-cols-2">
                        <Controller
                            key={item.id + index + 'primaryContact'}
                            name={`${fieldName}contacts.${index}.primaryContact` as string}
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                                <Checkbox
                                    label={'Primary Contact'}
                                    inputProps={{
                                        disabled: disablePrimary(index) || disabled,
                                        value: 'type',
                                        checked: field.value,
                                        onChange: () => {
                                            field.onChange;
                                            const fieldDepartment = getValues(`${fieldName}contacts.${index}.department`);
                                            if (field.value === false) {
                                                setValue(`${fieldName}contacts.${index}.sendBillingEmails`, true);
                                                setValue(`${fieldName}contacts.${index}.sendDispatchEmails`, true);
                                                setValue(`${fieldName}contacts.${index}.primaryContact`, true);
                                                if (!fieldDepartment) {
                                                    setValue(`${fieldName}contacts.${index}.department`, 'primary');
                                                }
                                                setDisableNonePrimary(true);
                                            } else {
                                                setValue(`${fieldName}contacts.${index}.primaryContact`, false);
                                                if (fieldDepartment === 'primary') {
                                                    setValue(`${fieldName}contacts.${index}.department`, '');
                                                }
                                                setDisableNonePrimary(false);
                                            }
                                        },
                                    }}
                                />
                            )}
                        />
                        <div className="w-full">
                            <Tooltip
                                text={
                                    data && data[index]?.primaryContact
                                        ? 'If no other contact is selected for dispatch emails, the primary will get the emails.'
                                        : ''
                                }
                            >
                                <Controller
                                    key={item.id + index + 'sendDispatchEmails'}
                                    name={`${fieldName}contacts.${index}.sendDispatchEmails` as string}
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <Checkbox
                                            label={'Send Dispatch Emails'}
                                            inputProps={{
                                                value: 'type',
                                                checked: field.value,
                                                onChange: (e) => {
                                                    alertIfPrimaryIsGettingEmails(index, e.target.checked, 'sendDispatchEmails');
                                                    field.onChange(e);
                                                },
                                                disabled:
                                                    disabled ||
                                                    (isEditing && !data[index].email) ||
                                                    (existingCustomer &&
                                                        data[index].primaryContact &&
                                                        data[index].sendDispatchEmails === true &&
                                                        existingCustomer.contacts
                                                            .filter((contact) => !contact.primaryContact)
                                                            .every((contact) => !contact.sendDispatchEmails)),
                                            }}
                                        />
                                    )}
                                />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="ml-1 grid grid-cols-2">
                        <Tooltip
                            text={
                                data && data[index]?.primaryContact
                                    ? 'If no other contact is select for Billing, the primary will get the emails.'
                                    : ''
                            }
                        >
                            <Controller
                                key={item.id + index + 'sendBillingEmails'}
                                name={`${fieldName}contacts.${index}.sendBillingEmails` as string}
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <Checkbox
                                        label={'Send Billing Emails'}
                                        inputProps={{
                                            value: 'type',
                                            checked: field.value,
                                            onChange: (e) => {
                                                alertIfPrimaryIsGettingEmails(index, e.target.checked, 'sendBillingEmails');
                                                field.onChange(e);
                                            },
                                            disabled:
                                                disabled ||
                                                (isEditing && !data[index].email) ||
                                                (existingCustomer &&
                                                    data[index].primaryContact &&
                                                    data[index].sendBillingEmails === true &&
                                                    existingCustomer.contacts
                                                        .filter((contact) => !contact.primaryContact)
                                                        .every((contact) => !contact.sendBillingEmails)),
                                        }}
                                    />
                                )}
                            />
                        </Tooltip>
                    </div>
                    <div className="ml-1 flex flex-row space-x-2" />
                    <div className="flex flex-row space-x-2">
                        <Controller
                            key={item.id + index + 'notes'}
                            control={control}
                            name={`${fieldName}contacts.${index}.notes`}
                            defaultValue={''}
                            render={({ field, fieldState }) => (
                                <TextField
                                    type={'string'}
                                    label="Notes"
                                    error={fieldState.error}
                                    inputProps={{
                                        ...field,
                                    }}
                                />
                            )}
                        />
                    </div>
                    {fields.length !== 0 && !hideButtons && (
                        <div className="w-full text-right">
                            <Button
                                className="btn-delete-outlined"
                                onClick={() => handleRemoveContact(index)}
                                startIcon={<XMarkIcon />}
                                disabled={primaryContactIndex === index || disabled}
                            >
                                Remove
                            </Button>
                        </div>
                    )}
                    {index !== fields.length - 1 && <hr className="py-2" />}
                </div>
            ))}
            {!hideButtons && (
                <div className="flex flex-row items-center justify-center">
                    <hr className="w-full" />
                    <div className="flex flex-row items-center justify-center space-x-2 px-2">
                        <Button
                            className="btn-primary-text-only whitespace-nowrap"
                            onClick={handleAddContact}
                            startIcon={<PlusCircleIcon />}
                            disabled={disabled}
                        >
                            Add contact
                        </Button>
                    </div>
                    <hr className="w-full" />
                </div>
            )}
        </>
    );
};
