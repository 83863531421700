import { useEffect, useState } from 'react';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { moneyFormatter, round } from '@wayste/utils';
import { paymentMethods, paymentTerms } from '../../utils';
import CardInfoList from '../CardInfoList';

const calcOrderTotal = (receivables?: Invoice.ReceivableTransport[]) => {
    let total = 0;
    receivables?.forEach((invoice: Invoice.ReceivableTransport) => {
        if (!invoice.invoiceDetails.void) {
            total += invoice.invoiceDetails.total;
        }
    });
    return round(total);
};

const calcRemainingBalance = (receivables?: Invoice.ReceivableTransport[]) => {
    let total = 0;
    receivables?.forEach((invoice: Invoice.ReceivableTransport) => {
        if (!invoice.invoiceDetails.void) {
            total += invoice.invoiceDetails.remainingBalance;
        }
    });
    return round(total);
};

const calcPaidBalance = (receivables?: Invoice.ReceivableTransport[]) => {
    let total = 0;
    receivables?.forEach((invoice: Invoice.ReceivableTransport) => {
        if (!invoice.invoiceDetails.void) {
            total += invoice.invoiceDetails.total - invoice.invoiceDetails.remainingBalance;
        }
    });
    return round(total);
};

const payablesTotal = (payables?: Invoice.PayableTransport[]) => {
    return payables?.reduce((total, invoice) => {
        if (!invoice.invoiceDetails.void) {
            total += invoice.invoiceDetails.total;
        }
        return total;
    }, 0);
};

const getHaulDumpCosts = (order: Order.AllianceOrderTransport, payables?: Invoice.PayableTransport[]) => {
    let results = {
        haul: '',
        dump: '',
        perTon: '',
    };
    if (payables && payables?.length > 0) {
        let haul: any = null;
        let dump: any = null;
        payables.forEach((bill) => {
            if (bill.invoiceDetails.lineItems) {
                bill.invoiceDetails.lineItems.forEach((item) => {
                    if (item.itemName?.toLowerCase() === 'haul') {
                        haul += item.totalPrice ?? 0;
                    }
                    if (item.itemName?.toLowerCase() === 'dump') {
                        dump += item.totalPrice ?? 0;
                    }
                });
            }
        });
        results = {
            haul: haul !== null ? moneyFormatter(haul) : '',
            dump: dump !== null ? moneyFormatter(dump) : '',
            perTon:
                dump !== null && order?.actualWeightDumped?.value ? `${moneyFormatter(dump / order?.actualWeightDumped.value)} / ton` : '',
        };
    }
    return results;
};

/**
 * Displays bulk of Billing data in lists using CardInfoList
 * @param {*} order Order schema object
 * @param {Boolean} break3 Moves the 3rd column below the first 2
 */

export interface OrderBillingDetailsListsProps {
    order: Order.AllianceOrderTransport;
    receivables: Invoice.ReceivableTransport[];
    payables: Invoice.PayableTransport[];
    break3: boolean;
}
const OrderBillingDetailsLists = ({ order, receivables, payables, break3 }: OrderBillingDetailsListsProps) => {
    const [orderQuoted, setOrderQuoted] = useState(0);
    const [orderTotal, setOrderTotal] = useState(0);
    const [discountLineItems, setDiscountLineItems] = useState<Invoice.LineItemTransport[]>([]);

    useEffect(() => {
        if (!order || !receivables) {
            return;
        }
    }, [order, receivables, payables]);

    useEffect(() => {
        if (!order || !receivables) {
            return;
        }

        const quoteInvoice = receivables.find((invoice) =>
            invoice.invoiceDetails.lineItems.find((lineItem) => lineItem.itemName === 'QP-Haul'),
        );

        if (!quoteInvoice) {
            console.error('No quote invoice found');
            return;
        }

        setDiscountLineItems(
            quoteInvoice.invoiceDetails.lineItems.filter(
                (lineItem: Invoice.LineItemTransport) => lineItem?.itemName === 'Discount' || (lineItem.unitPrice || 1) < 0,
            ) || [],
        );

        setOrderTotal(calcOrderTotal(receivables));
        setOrderQuoted(quoteInvoice.invoiceDetails.total);
    }, [order, receivables]);

    const colOne = [
        {
            label: 'Pricing Type',
            value: <span style={{ textTransform: 'capitalize' }}>{order.priceType}</span>,
        },
        { label: 'Price Quoted', value: `${moneyFormatter(orderQuoted)}` },
        { label: 'Order Total', value: `${moneyFormatter(orderTotal)}` },
        { label: 'Paid', value: `${moneyFormatter(calcPaidBalance(receivables))}` },
        { label: 'Due', value: `${moneyFormatter(calcRemainingBalance(receivables))}` },
    ];

    discountLineItems.forEach((item) => {
        colOne.splice(2, 0, {
            label: item.itemName,
            value: `(${moneyFormatter(Math.ceil(-(item?.totalPrice ?? 0)))})`,
        });
    });

    const colTwo = [
        { label: 'Payment Terms', value: paymentTerms[order.paymentTerm as keyof typeof paymentTerms] },
        { label: 'Payment Method', value: paymentMethods[order.paymentMethod as keyof typeof paymentMethods] },
        {
            label: 'Expected Haul Rate',
            value: moneyFormatter(order.haulerPricingSnapshot?.haul),
        },
        {
            label: 'Expected Dump Rate',
            value: moneyFormatter(order.haulerPricingSnapshot?.dump),
        },
    ];

    const colThree = [
        { label: 'Actual Haul Total', value: getHaulDumpCosts(order, payables).haul },
        { label: 'Actual Dump Total', value: getHaulDumpCosts(order, payables).dump },
        { label: 'Actual Cost / Ton', value: getHaulDumpCosts(order, payables).perTon },
        { label: 'Actual Payables Total', value: moneyFormatter(payablesTotal(payables)) },
    ];

    return (
        <div className={`grid grid-cols-1 gap-4 ${break3 ? 'lg:grid-cols-2' : 'lg:grid-cols-3'}`}>
            <CardInfoList data={colOne} border />
            <CardInfoList data={colTwo} border={!break3} />
            {break3 ? (
                <div className="lg:col-span-2">
                    <hr />
                </div>
            ) : null}

            <CardInfoList data={colThree} />
        </div>
    );
};

export default OrderBillingDetailsLists;
