import { Order } from '@alliance-disposal/transport-types';
import { DataGrid, DataGridProps, Tooltip } from '@wayste/sour-ui';
import { formatServiceDate } from '@wayste/utils';
import { closestTo, differenceInCalendarDays, format, isAfter } from 'date-fns';
import { stringify } from 'qs';
import { HiOutlineExclamationTriangle } from 'react-icons/hi2';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../utils';
import { type Project } from '../account-manager-project-board';

const findClosestExpirationDate = (orders: Order.AllianceOrderTransport[]) => {
    const today = new Date();

    // Filter orders with a valid expirationDate that is today or in the future
    const validDates = orders
        .map((order) => order.expirationDate)
        .filter((date) => date && (isAfter(new Date(date), today) || date === formatServiceDate(today, 'string')));

    // Find the closest date to today
    const closestDate = validDates.length
        ? closestTo(
              today,
              validDates.map((date) => new Date(date as string)),
          )
        : null;

    return closestDate;
};

const breakDownStatuses = (orders: Order.AllianceOrderTransport[]) => {
    const sortOrder = [
        Order.SourgumOrderStatusLabels.NEEDS_REVIEW,
        Order.SourgumOrderStatusLabels.ON_HOLD,
        Order.SourgumOrderStatusLabels.UNASSIGNED,
        Order.SourgumOrderStatusLabels.ASSIGNED,
        Order.SourgumOrderStatusLabels.DELIVERED,
        Order.SourgumOrderStatusLabels.READY_FULL_PICKUP,
        Order.SourgumOrderStatusLabels.DUMPED,
    ];
    const statuses = orders.reduce(
        (acc, order) => {
            if (!acc[order.status]) {
                acc[order.status] = 1;
            } else {
                acc[order.status] += 1;
            }
            return acc;
        },
        {} as { [key: string]: number },
    );

    return Object.entries(statuses)
        .map(([status, count]) => `${Order.SourgumOrderStatusLabels[status as keyof typeof Order.SourgumOrderStatusLabels]}: ${count}`)
        .sort((a, b) => sortOrder.indexOf(a.split(':')[0]) - sortOrder.indexOf(b.split(':')[0]))
        .join(' | ');
};

const RollOffBoard = ({ projects, loading }: { projects: Project[]; loading: boolean }) => {
    const history = useHistory();

    const handleRowClick = (row: Project) => {
        const queryString = stringify(
            {
                query: {
                    query: `"${row.orders[0].serviceLocation.address.addressLine1}"`,
                },
                type: 'sourgum-order',
                'sour-search': true,
                filters: {
                    status: {
                        field: 'status',
                        type: 'STRING',
                        operator: 'IS_NOT_IN',
                        value: ['COMPLETED', 'CANCELLED'],
                    },
                },
                sort: {
                    field: 'expectedDeliveryDate',
                    direction: 'DESC',
                },
            },
            { encode: false },
        );
        history.push(routes.orders.list + '?' + queryString);
    };

    const columns: DataGridProps<Project>['columns'] = [
        {
            key: 'serviceAddress',
            name: 'Address',
        },
        {
            key: 'customer',
            name: 'Customer',
            formatter: ({ row }) => row.customerCompanyName || row.customerName,
        },
        {
            key: 'activeOrders',
            name: 'Active Orders',
            formatter: ({ row }) => row.orders.length,
        },
        {
            key: 'nextExpiration',
            name: 'Next Expiration',
            formatter: ({ row }) => {
                const nextExpiration = findClosestExpirationDate(row.orders);
                if (!nextExpiration) {
                    return 'No Expiration Date';
                } else {
                    return format(formatServiceDate(nextExpiration, 'date'), 'EEEE MM/dd/yyyy');
                }
            },
        },
        {
            key: 'billingAttention',
            name: 'Billing Attention',
            align: 'center',
            formatter: ({ row }) =>
                row.orders.some(
                    (order) => order.expectedPickupDate && differenceInCalendarDays(new Date(), new Date(order.expectedPickupDate)) > 14,
                ) ? (
                    <Tooltip text="It has been 14 days since the order was dumped and we still don't have the dump ticket.">
                        <HiOutlineExclamationTriangle className="text-warning size-5" />
                    </Tooltip>
                ) : null,
        },
        {
            key: 'statusBreakdown',
            name: 'Status Breakdown',
            formatter: ({ row }) => breakDownStatuses(row.orders),
        },
    ];

    return <DataGrid rows={projects} columns={columns} onRowClick={handleRowClick} loading={loading} />;
};

export default RollOffBoard;
