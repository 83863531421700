import { useContext } from 'react';
import { useCurrentUserProfile, useWaysteClient } from '@alliance-disposal/client';
import { InternalTicket, Profile } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Menu, MenuItem, Tooltip } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { Disclosure } from '@headlessui/react';
import {
    ArchiveBoxArrowDownIcon,
    ArrowUturnLeftIcon,
    InboxArrowDownIcon,
    PencilIcon,
    PhotoIcon,
    TrashIcon,
} from '@heroicons/react/20/solid';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { ManageTags } from './ManageTags';
import StatusMenu from './StatusMenu';
import { InternalTicketContext } from './context';

type InternalTicketCardProps = {
    ticket: InternalTicket.InternalTicketTransport;
    onReplyClick: (ticketID: string) => void;
    onEditClick: (ticket: InternalTicket.InternalTicketTransport) => void;
    onRedactClick: (ticketID: string) => void;
    onShowImageClick: (ticket: InternalTicket.InternalTicketTransport) => void;
    isReply?: boolean;
    readOnly?: boolean;
};

export const InternalTicketCard = ({
    ticket,
    onReplyClick,
    isReply,
    onEditClick,
    onRedactClick,
    readOnly,
    onShowImageClick,
}: InternalTicketCardProps) => {
    const client = useWaysteClient();
    const { profile } = useCurrentUserProfile();
    const { handleArchive, handleShowReplies, showReplies } = useContext(InternalTicketContext);
    const { setShowAlert } = useSourContext();
    const rosterMap = client.profile().adminPortal.getRosterFromMemory().idToProfileMap;

    const onArchiveClick = (archive: boolean) => {
        if (
            archive &&
            ((ticket.status && ticket.status !== 'SOLVED') || ticket.replies.some((reply) => reply.status && reply.status !== 'SOLVED'))
        ) {
            setShowAlert({
                severity: 'warning',
                title: 'Cannot Archive',
                message:
                    "This ticket or one of it's replies has an active status that is not Solved. Please resolve the status before archiving.",
            });
            return;
        }
        handleArchive(ticket.id, archive);
    };

    return (
        <Disclosure defaultOpen={!ticket.deletedAt}>
            {Boolean(ticket.deletedAt) && (
                <Disclosure.Button as="div" className="btn-secondary-text-only !justify-start !text-red-500">
                    Show Redacted Message
                </Disclosure.Button>
            )}
            <Disclosure.Panel>
                <div
                    className="flex flex-col gap-y-1.5"
                    style={{
                        color: ticket.deletedAt ? 'red' : undefined,
                    }}
                >
                    <div className="flex items-center gap-2">
                        <div>Created:</div>
                        <div>
                            {rosterMap[ticket.metadata.createdByUserID]?.firstName} {rosterMap[ticket.metadata.createdByUserID]?.lastName}
                        </div>
                        <div>-</div>
                        <div>{format(new Date(ticket.metadata.createdAt), 'EEE MM/dd/yy hh:mm a')}</div>
                    </div>
                    <div className="flex flex-col gap-2 md:flex-row">
                        {ticket.assignedToProfileID && (
                            <div className="flex size-6 items-center justify-center rounded-full bg-slate-600 text-xs uppercase text-white">
                                {rosterMap[ticket.assignedToProfileID]?.firstName?.charAt(0)}
                                {rosterMap[ticket.assignedToProfileID]?.lastName?.charAt(0)}
                            </div>
                        )}

                        <div className="flex w-full items-end justify-between">
                            <div
                                className="flex gap-2"
                                style={{
                                    background:
                                        ticket.assignedToProfileID && ticket.assignedToProfileID === profile?.id ? 'yellow' : 'white',
                                }}
                            >
                                <div>Assigned to:</div>
                                {/* assigned team */}
                                <div>
                                    {ticket.assignedTeam &&
                                        Profile.SourgumTeamLabels[ticket.assignedTeam as keyof typeof Profile.SourgumTeamLabels]}
                                </div>
                                <div>-</div>
                                <div>
                                    {/* assigned to profile */}
                                    {ticket.assignedToProfileID &&
                                        rosterMap[ticket.assignedToProfileID]?.firstName +
                                            ' ' +
                                            rosterMap[ticket.assignedToProfileID]?.lastName}
                                </div>
                            </div>
                            <div className={ticket.dueDate ? '' : 'hidden'}>
                                Due On: {ticket.dueDate ? format(new Date(ticket.dueDate), 'EEEE MM/dd/yy') : null}
                            </div>
                        </div>
                        {(ticket.issueCausedByProfileID || ticket.monetaryLoss) && (
                            <>
                                <div className="hidden md:block">|</div>
                                <div className="flex gap-2">
                                    <div>Issue caused by:</div>
                                    <div>
                                        {ticket.issueCausedByProfileID &&
                                            rosterMap[ticket.issueCausedByProfileID]?.firstName +
                                                ' ' +
                                                rosterMap[ticket.issueCausedByProfileID]?.lastName}
                                    </div>
                                    <div>{moneyFormatter(ticket.monetaryLoss || 0)}</div>
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        <ManageTags tags={ticket.tags} ticketID={ticket.id} disabled={Boolean(ticket.deletedAt) || readOnly} />
                    </div>
                    <div>{ticket.note}</div>
                    <div className="flex items-center justify-end gap-2">
                        {!isReply && ticket.replies.length > 0 && (
                            <Button
                                className="btn-secondary-text-only text-sm"
                                endIcon={showReplies[ticket.id] ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                onClick={() => handleShowReplies(ticket.id)}
                            >
                                Replies {ticket.replies.length}
                            </Button>
                        )}
                        <Menu
                            button={
                                <Button className="btn-icon" disabled={Boolean(ticket.deletedAt) || readOnly}>
                                    <EllipsisHorizontalIcon className="size-6" />
                                </Button>
                            }
                        >
                            {ticket.archivedDate ? (
                                <MenuItem
                                    className="flex items-center gap-1"
                                    onClick={() => onArchiveClick(false)}
                                    disabled={Boolean(ticket.replyToID)}
                                >
                                    <InboxArrowDownIcon className="size-5" /> <span>Make Active</span>
                                </MenuItem>
                            ) : (
                                <MenuItem
                                    className="flex items-center gap-1"
                                    onClick={() => onArchiveClick(true)}
                                    disabled={Boolean(ticket.replyToID)}
                                >
                                    <ArchiveBoxArrowDownIcon className="size-5" /> <span>Archive</span>
                                </MenuItem>
                            )}
                            <MenuItem className="[&>*]:text-edit flex items-center gap-1" onClick={() => onEditClick(ticket)}>
                                <PencilIcon className="size-5" /> <span>Edit</span>
                            </MenuItem>
                            <MenuItem className="[&>*]:text-delete flex items-center gap-1" onClick={() => onRedactClick(ticket.id)}>
                                <TrashIcon className="size-5" /> <span>Redact</span>
                            </MenuItem>
                        </Menu>
                        <Tooltip text="Images uploaded here are only accessible on this ticket. You cannot tag the image here." delay={100}>
                            <div className="relative">
                                {ticket.images.length > 0 && (
                                    <span className="z-1 absolute -top-1.5 left-3 inline-flex size-5 items-center justify-center rounded-full bg-red-600 p-1 text-xs font-bold leading-none text-white">
                                        {ticket.images.length}
                                    </span>
                                )}
                                <Button className="btn-icon-dense" onClick={() => onShowImageClick(ticket)} disabled={readOnly}>
                                    <PhotoIcon className="size-5" />
                                </Button>
                            </div>
                        </Tooltip>
                        {!isReply && (
                            <Button
                                startIcon={<ArrowUturnLeftIcon className="size-5" />}
                                className="btn-secondary-text-only"
                                onClick={() => onReplyClick(ticket.id)}
                                disabled={Boolean(ticket.deletedAt) || readOnly}
                            >
                                Reply
                            </Button>
                        )}
                        {/* status */}
                        <StatusMenu
                            status={ticket.status}
                            disabled={Boolean(ticket.deletedAt) || (!ticket.assignedToProfileID && !ticket.assignedTeam)}
                            ticketID={ticket.id}
                        />
                    </div>
                </div>
            </Disclosure.Panel>
        </Disclosure>
    );
};
