import React from 'react';
import { Pricing } from '@alliance-disposal/transport-types';
import { useHistory } from 'react-router-dom';
import { routes } from '../../utils';

const headers = ['Name'];

interface Props {
    pricing: Pricing.PricingTransport[];
}

const PricingList = ({ pricing }: Props) => {
    const history = useHistory();

    const handlePricingClicked = (id: string) => {
        history.push(routes.pricing.details(id));
    };

    return (
        <div className="w-full flex-1 overflow-x-auto">
            <table className="w-full border-collapse overflow-x-auto">
                <thead>
                    <tr>
                        {headers.map((heading) => (
                            <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm" key={heading}>
                                {heading}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {pricing.map((item) => (
                        <tr key={item.id} onClick={() => handlePricingClicked(item.id)} className="cursor-pointer hover:bg-slate-50">
                            <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm">{item.zoneName}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PricingList;
