import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { S3ItemReference, UniversalService } from '@alliance-disposal/transport-types';
import { ImageDialog } from '@wayste/sour-components';
import { useSourContext } from '@wayste/sour-context';
import { formatISODateString, formatServiceAddress, formatTimestamp } from '@wayste/utils';
import { PencilIcon, PhotoIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import CardInfoList from '../../../../components/CardInfoList';
import DetailsCardWrapper from '../../../../components/DetailsCardWrapper';
import { routes } from '../../../../utils';
import InternalNotes from '../../components/InternalNotes';
import UniversalServicesStatusPopper from '../../components/UniversalServicesStatusPopper';

interface OneTimeServiceDetailsCardProps {
    serviceOrder: UniversalService.ServiceOrder;
    serviceGrouping: UniversalService.ServiceGrouping;
    lastUpdatedBy?: string;
    createdBy?: string;
    vendor?: string;
}

interface cardData {
    label: string;
    value: React.ReactNode;
}

const OneTimeServiceDetailsCard = ({ serviceOrder, serviceGrouping, lastUpdatedBy, createdBy, vendor }: OneTimeServiceDetailsCardProps) => {
    const history = useHistory();
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [showImages, setShowImages] = useState<boolean>(false);
    const [files, setFiles] = useState<S3ItemReference[]>(serviceOrder.images || []);

    const handleEditServiceClick = () => {
        history.push(routes.universalServices.serviceGrouping.serviceOrder.update(serviceOrder.serviceGroupingID, serviceOrder.id));
    };

    const handleImagesChange = async (imageReferences: S3ItemReference[]) => {
        try {
            const response = await client
                .universalService()
                .serviceGrouping.serviceOrder.update(serviceOrder.serviceGroupingID, serviceOrder.id, { images: imageReferences });
            setFiles(response.images || []);
        } catch (error) {
            console.warn('Error changing service status: ', error);
            setShowToast({
                severity: 'error',
                message: 'Error occurred saving the image. Please try again.',
            });
        }
    };

    const colOne: cardData[] = [
        {
            label: 'Service',
            value: `${serviceOrder.serviceType.family.name} - ${serviceOrder.serviceType.name}`,
        },
        {
            label: 'PO Number',
            value: serviceOrder.poNumber,
        },
        {
            label: 'Service Address',
            value: formatServiceAddress(serviceOrder.serviceLocation?.address),
        },
        {
            label: 'County',
            value: serviceOrder.serviceLocation?.county,
        },
    ];
    const colTwo: cardData[] = [
        {
            label: 'Start Date',
            value: formatISODateString(serviceOrder.startDate, undefined, ''),
        },
        {
            label: 'End Date',
            value: formatISODateString(serviceOrder.endDate, undefined, ''),
        },
        {
            label: 'Delivery Date',
            value: formatISODateString(serviceOrder.deliveryDate, undefined, 'N/A - No Asset Delivered'),
        },
        {
            label: 'Delivery Notes',
            value: serviceOrder.deliveryNotes,
        },
        {
            label: 'Removal Date',
            value: formatISODateString(serviceOrder.removalDate, undefined, ''),
        },
        {
            label: 'Vendor only Notes',
            value: serviceOrder.vendorOnlyNotes,
        },
        {
            label: 'Internal Notes',
            value: <InternalNotes serviceGrouping={serviceGrouping} serviceOrderID={serviceOrder.id} />,
        },
    ];
    const colThree: cardData[] = [
        {
            label: 'Needs Attention',
            value: serviceOrder.needsAttention ? 'Yes' : 'No',
        },
        {
            label: 'Order #',
            value: serviceOrder.fullOrderNumber,
        },
        {
            label: 'Status',
            value: <UniversalServicesStatusPopper serviceOrder={serviceOrder} />,
        },
        {
            label: 'Vendor',
            value: vendor,
        },
        {
            label: 'Issues',
            value: '',
        },
        {
            label: 'Created By',
            value: createdBy,
        },
        {
            label: 'Created Date',
            value: formatTimestamp(serviceOrder.metadata.createdAt, ''),
        },
        {
            label: 'Last Updated By',
            value: lastUpdatedBy,
        },
        {
            label: 'Last Updated Date',
            value: formatTimestamp(serviceOrder.metadata.lastUpdatedAt, ''),
        },
    ];
    return (
        <DetailsCardWrapper
            heading="Service Details"
            buttons={[
                {
                    label: (
                        <>
                            <PencilIcon className="mr-1 size-5" /> Edit Service
                        </>
                    ),
                    onClick: handleEditServiceClick,
                },
                {
                    label: (
                        <>
                            <PhotoIcon className="mr-1 size-5" /> Service Image
                        </>
                    ),
                    onClick: () => setShowImages(true),
                },
            ]}
        >
            <ImageDialog
                open={showImages}
                onClose={() => setShowImages(false)}
                entityID={serviceOrder.id}
                folderName={`order${serviceOrder.fullOrderNumber?.toString() ?? ''}`}
                imageReferences={files}
                setImageReferences={handleImagesChange}
            />
            <div className={'grid grid-cols-1 gap-4 lg:grid-cols-3'}>
                <CardInfoList data={colOne} border />
                <CardInfoList data={colTwo} border />
                <CardInfoList data={colThree} />
            </div>
        </DetailsCardWrapper>
    );
};

export default OneTimeServiceDetailsCard;
