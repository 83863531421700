import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice, Material, Order } from '@alliance-disposal/transport-types';
import { Loading } from '@wayste/sour-ui';
import { determineWaysteActive, pricingBreakdownTotal } from '@wayste/utils';
import { useFlash } from '../../hooks/useFlash';
import { ccRate, quotedPriceItems } from '../../utils';
import DetailsCardWrapper from '../DetailsCardWrapper';
import type { HaulerPricingWithHauler } from '../OrderStatusChanger/OrderAssignHauler';
import SpreadsTable from '../SpreadsTable/SpreadsTable';

interface VendorListCardProps {
    order: Order.AllianceOrderTransport;
    receivables: Invoice.ReceivableTransport[];
}
const VendorListCard = ({ order, receivables }: VendorListCardProps) => {
    const { showFlash } = useFlash();
    const client = useWaysteClient();
    const [isLoading, setIsLoading] = useState(false);
    const [haulerID, setHaulerID] = useState('');
    const [pricing, setPricing] = useState<HaulerPricingWithHauler[]>([]);

    const handleGetPricing = async () => {
        setIsLoading(true);
        try {
            const pricingResults = await client.pricing().adminPortal.location.query({
                ...order.serviceLocation.coordinates,
                zip: order.serviceLocation.address.zip,
                state: order.serviceLocation.address.state,
            });
            const haulerPricing: HaulerPricingWithHauler[] = [];

            const haulerPricingPromises = pricingResults.map(async (item) => {
                if (item.haulerID && !item.public) {
                    try {
                        // for anyone who comes across this, this syntax is called destructuring assignment
                        // basically I'm first destructuring the array returned from the promise
                        // then I'm destructuring the results from the paginated result and renaming it to haulerWaysteActivity
                        const [hauler, { results: haulerWaysteActivity }] = await Promise.all([
                            client.vendorService().adminPortal.fetch(item.haulerID),
                            client.vendorService().adminPortal.activity.query({
                                haulerID: item.haulerID,
                            }),
                        ]);

                        if (hauler && hauler.active) {
                            haulerPricing.push({ ...item, hauler, waysteUsage: determineWaysteActive(hauler, haulerWaysteActivity) });
                        }
                    } catch (error) {
                        console.error('error: ', error);
                        showFlash('An error occurred fetching hauler pricing', 'warning');
                    }
                }
                return null;
            });

            await Promise.all(haulerPricingPromises);
            setPricing(haulerPricing);
        } catch (error) {
            showFlash('An error occurred fetching pricing', 'warning');
        }
        setIsLoading(false);
    };
    useEffect(() => {
        if (order.haulerID) setHaulerID(order.haulerID);
        handleGetPricing();
    }, [order.serviceLocation]);

    return (
        <DetailsCardWrapper heading="Vendors">
            {isLoading ? (
                <div className="flex w-full justify-center py-8">
                    <Loading />
                </div>
            ) : (
                <div className="mt-4">
                    <SpreadsTable
                        haulerWithPricing={pricing}
                        selectedMaterial={order.material as Material}
                        selectedHauler={haulerID}
                        selectedSize={order.expectedSize.size}
                        sourgumPricing={{
                            haul:
                                receivables
                                    .sort((a, b) => +(a.invoiceDetails.invoiceNumber || '') - +(b.invoiceDetails.invoiceNumber || ''))[0]
                                    ?.invoiceDetails.lineItems.find((item) => item.itemName === quotedPriceItems.haul)?.unitPrice || 0,
                            dump: order.dumpRate || 0,
                            tonLimit: order.weightLimit?.value || 0,
                            overage: pricingBreakdownTotal(
                                order.overage || 0,
                                1,
                                0,
                                receivables
                                    .sort((a, b) => +(a.invoiceDetails.invoiceNumber || '') - +(b.invoiceDetails.invoiceNumber || ''))[0]
                                    ?.invoiceDetails.lineItems.find((item) => item.itemName === 'CC Fee')
                                    ? ccRate
                                    : 0,
                            ).unitPriceDollars, // unitPriceDollars is actually cents
                        }}
                    />
                </div>
            )}{' '}
        </DetailsCardWrapper>
    );
};
export default VendorListCard;
