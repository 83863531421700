import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import {
    AddressTransport,
    Duration,
    DurationUnitLabels,
    ServiceLocationTransport,
    UniversalService,
} from '@alliance-disposal/transport-types';
import { AddressSubForm } from '@wayste/sour-components';
import { Button, Checkbox, CurrencyTextField, DatePicker, Select, SelectOption, TextField, Textarea, TimePicker } from '@wayste/sour-ui';
import { formatServiceAddress } from '@wayste/utils';
import { setHours, setMinutes, setSeconds } from 'date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import RFPSkuSubForm from './rfp-sku-sub-form';

export type SkuExtraPFormProps = {
    serviceLineItemTypeID: string;
    quantity: number;
    requiredInRfp: boolean;
    noteToVendor: string;
};

export type SkuFormProps = {
    serviceTypeID: string;
    quantity: number;
    serviceFrequency: Duration;
    serviceCycleLength: number;
    skuExtras: SkuExtraPFormProps[];
    note: string;
};

export type RFPFormFromProps = {
    serviceLocation: ServiceLocationTransport;
    businessType: string;
    serviceFamilyID: string;
    note: string;
    deadlineDate: Date | undefined | '';
    deadlineTime: Date | '' | 'error';
    billingFrequency: Duration;
    skus: SkuFormProps[];
    recurring: boolean;
    serviceStartDate?: Date | undefined | '';
    currentVendor?: string;
    rfpName: string;
    targetProposalAmount: number | '';
};

type RFPFormProps = {
    onSubmit: (data: UniversalService.RFPCreate | UniversalService.RFPUpdate) => void;
};

const RFPForm = ({ onSubmit }: RFPFormProps) => {
    const client = useWaysteClient();
    const [serviceSkusLoading, setServiceSkusLoading] = useState(false);
    const [serviceFamilyOptions, setServiceFamilyOptions] = useState<UniversalService.ServiceTypeFamily[]>([]);
    const [serviceTypeOptions, setServiceTypeOptions] = useState<UniversalService.ServiceType[]>([]);
    const [serviceLineItemTypeOptions, setServiceLineItemTypeOptions] = useState<UniversalService.ServiceLineItemType[]>([]);

    const methods = useForm<RFPFormFromProps>({
        mode: 'all',
        defaultValues: {
            serviceLocation: {
                address: {
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    zip: '',
                },
                coordinates: {
                    lat: 0,
                    lng: 0,
                },
                county: '',
            },
            businessType: '',
            serviceFamilyID: '',
            note: '',
            deadlineDate: undefined,
            deadlineTime: '',
            serviceStartDate: undefined,
            recurring: false,
            currentVendor: '',
            billingFrequency: { value: 1, unit: 'MONTHS' },
            rfpName: '',
            targetProposalAmount: '',
            skus: [
                {
                    serviceTypeID: '',
                    quantity: 1,
                    serviceFrequency: { value: 1, unit: 'WEEKS' },
                    serviceCycleLength: 1,
                    skuExtras: [],
                    note: '',
                },
            ],
        },
    });

    const { handleSubmit, control, watch, setValue, getValues, formState } = methods;
    const { isValid, isDirty } = formState;

    const watchServiceLocationAddress = watch('serviceLocation.address');
    const watchRecurring = watch('recurring');
    const watchSkus = watch('skus');

    const getAddressWithoutNumber = (address: AddressTransport) => {
        const formattedAddress = formatServiceAddress({ ...address });
        const match = formattedAddress.match(/\s*\d+\w*\s*(.*)/);
        return match ? match[1].trim() : '';
    };

    const handleGetServiceFamilies = async () => {
        setServiceSkusLoading(true);
        try {
            const response = await client.universalService().serviceFamily.query();
            setServiceFamilyOptions(response.results);
        } catch (error) {
            console.warn('error: ', error);
        } finally {
            setServiceSkusLoading(false);
        }
    };

    useEffect(() => {
        handleGetServiceFamilies();
    }, []);

    const handleSelectServiceFamily = (family: UniversalService.ServiceTypeFamily) => {
        setServiceTypeOptions(family.serviceTypes);
        setServiceLineItemTypeOptions(family.lineItemTypes);
    };

    const handleDeadlineDateChange = (date: Date | undefined | '') => {
        setValue('deadlineDate', date);
        const deadlineTime = getValues('deadlineTime');
        if (date && (deadlineTime === 'error' || !deadlineTime)) {
            setValue('deadlineTime', date);
        }
    };

    const onFormSubmit = (values: RFPFormFromProps) => {
        const combinedDeadline = setHours(
            setMinutes(setSeconds(values.deadlineDate as Date, 0), (values.deadlineTime as Date).getMinutes()),
            (values.deadlineTime as Date).getHours(),
        );
        const data: UniversalService.RFPCreate | UniversalService.RFPUpdate = {
            rfpName: values.rfpName,
            deadlineDate: combinedDeadline.toISOString(),
            businessType: values.businessType,
            familyID: values.serviceFamilyID,
            serviceLocation: values.serviceLocation,
            status: 'OPEN',
            noteToVendor: values.note,
            serviceStartDate: values.serviceStartDate ? values.serviceStartDate?.toISOString() : undefined,
            rfpVendorResponses: [],
            currentVendor: values.currentVendor,
            targetProposalAmount: values.targetProposalAmount || undefined,
            rfpSkus: values.skus.map((sku) => ({
                serviceTypeID: sku.serviceTypeID,
                quantity: sku.quantity,
                serviceFrequency: sku.serviceFrequency,
                noteToVendor: sku.note,
                billingFrequency: values.billingFrequency,
                serviceCycleLength: sku.serviceCycleLength,
                rfpSkuExtras: sku.skuExtras.map((extra) => ({
                    serviceLineItemTypeID: extra.serviceLineItemTypeID,
                    quantity: extra.quantity,
                    requiredInRfp: extra.requiredInRfp,
                    noteToVendor: extra.noteToVendor,
                })),
            })),
        };
        onSubmit(data);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onFormSubmit)} className="grid grid-cols-1 gap-x-4 gap-y-6">
                <Controller
                    control={control}
                    name="rfpName"
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Custom RFP Name"
                            error={fieldState.error}
                            helperText="Not visible to vendor"
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />
                <AddressSubForm apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY} fieldName="serviceLocation" type="location" required />
                <div className="italic">
                    Site Address Visible to Vendor:
                    <br />
                    {getAddressWithoutNumber(watchServiceLocationAddress)}
                </div>
                <Controller
                    control={control}
                    name="currentVendor"
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Current vendor"
                            error={fieldState.error}
                            helperText="Not visible to vendor"
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />
                <div>
                    <h2 className="text-lg">Everything from here down is visible to vendor</h2>
                    <hr />
                </div>
                <Controller
                    control={control}
                    name="targetProposalAmount"
                    render={({ field, fieldState }) => (
                        <CurrencyTextField
                            useCents
                            label="Target Proposal Rate"
                            error={fieldState.error}
                            helperText="If you want to give the hauler a rate to beat."
                            value={field.value}
                            onChange={(value) => field.onChange(value)}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="businessType"
                    rules={{
                        required: 'Business type is required',
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Type of Business"
                            error={fieldState.error}
                            required
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />
                <Controller
                    name="serviceFamilyID"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'A service family is required',
                        },
                    }}
                    render={({ field, fieldState }) => (
                        <Select
                            required
                            error={fieldState.error}
                            value={field.value}
                            onSelect={() => {}}
                            label="Service family"
                            loading={serviceSkusLoading}
                            helperText="Only one service family per RFP is allowed."
                        >
                            {serviceFamilyOptions
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((item) => (
                                    <SelectOption
                                        key={item.id}
                                        value={item.id}
                                        onClick={() => {
                                            field.onChange(item.id);
                                            handleSelectServiceFamily(item);
                                        }}
                                    >
                                        {item.name}
                                    </SelectOption>
                                ))}
                        </Select>
                    )}
                />
                <Controller
                    name="note"
                    control={control}
                    render={({ field }) => <Textarea inputProps={{ ...field }} label="Overall stop notes" />}
                />
                <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2">
                    <Controller
                        name="deadlineDate"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'A deadline is required',
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <DatePicker
                                label="Deadline"
                                value={field.value}
                                onChange={handleDeadlineDateChange}
                                minDate={new Date()}
                                error={fieldState.error}
                                required
                                closeOnSelect
                            />
                        )}
                    />
                    <Controller
                        name="deadlineTime"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'A deadline time is required',
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <TimePicker
                                label="Deadline time"
                                required
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                                error={fieldState.error}
                            />
                        )}
                    />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <Controller
                        name="serviceStartDate"
                        control={control}
                        render={({ field, fieldState }) => (
                            <DatePicker
                                label="Service start date"
                                value={field.value || ''}
                                onChange={field.onChange}
                                error={fieldState.error}
                                closeOnSelect
                            />
                        )}
                    />
                </div>
                <div>
                    <Controller
                        control={control}
                        name={'recurring'}
                        render={({ field }) => (
                            <Checkbox
                                label="Recurring service"
                                inputProps={{
                                    checked: field.value,
                                    onChange: field.onChange,
                                }}
                            />
                        )}
                    />
                </div>
                {watchRecurring && (
                    <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2">
                        <div>
                            <Controller
                                control={control}
                                name="billingFrequency.value"
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Billing frequency is required',
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        type="number"
                                        label="Billing frequency value"
                                        error={fieldState.error}
                                        required
                                        inputProps={{
                                            ...field,
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name="billingFrequency.unit"
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Billing frequency unit is required',
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <Select
                                        required
                                        error={fieldState.error}
                                        value={field.value}
                                        onSelect={(value) => field.onChange(value)}
                                        label="Billing frequency unit"
                                    >
                                        {Object.entries(DurationUnitLabels).map(([key, value]) => (
                                            <SelectOption key={key} value={key}>
                                                {value.singular}
                                            </SelectOption>
                                        ))}
                                    </Select>
                                )}
                            />
                        </div>
                    </div>
                )}

                {/* Sku section */}
                <div className="flex items-center justify-center gap-x-4">
                    <div className="h-0.5 w-full bg-black" />
                    <h2 className="text-xl font-bold">Skus</h2>
                    <div className="h-0.5 w-full bg-black" />
                </div>
                <RFPSkuSubForm serviceTypeOptions={serviceTypeOptions} serviceLineItemTypeOptions={serviceLineItemTypeOptions} />
                <div className="mt-6 flex justify-end">
                    <Button type="submit" className="btn-primary" disabled={!isValid || !isDirty || watchSkus.length < 1}>
                        Save & Select Vendors
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default RFPForm;
