import { useContext, useEffect, useState } from 'react';
import { Invoice, UniversalService } from '@alliance-disposal/transport-types';
import { CurrencyTextField, Dialog, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { moneyFormatter, round } from '@wayste/utils';
import { Controller, useForm } from 'react-hook-form';
import { haulerChargeItems } from '../../../../utils/shared-types';
import { BillingContext } from '../../context';

interface FormProps {
    itemName: string;
    description?: string;
    quantity: number | '';
    unitPrice: number | '';
}

interface Props {
    lineItem?: Partial<Invoice.LineItemTransport> | null;
    open: boolean;
    onCancel: () => void;
    payableIndex?: number;
    lineItemIndex?: number;
    serviceOrder?: UniversalService.ServiceOrder | undefined;
}

const BillItemDialog = ({ lineItem, open, onCancel, payableIndex, lineItemIndex, serviceOrder }: Props) => {
    const { handlePayableChanges, payables } = useContext(BillingContext);
    const [lineItemOptions, setLineItemOptions] = useState<string[]>([]);

    const getTotal = (quantity: number | '', unitPrice: number | '') => {
        return round(+quantity * +unitPrice);
    };

    const onSubmit = (data: FormProps) => {
        const cleanData = {
            itemName: data.itemName,
            description: data.description || '',
            quantity: +data.quantity,
            unitPrice: +data.unitPrice,
            totalPrice: getTotal(data.quantity, data.unitPrice),
            taxable: false,
        };
        if (payableIndex === undefined) throw new Error('Payable index is undefined');
        // get the payable
        const payable = payables[payableIndex];
        // this is an edit
        if (lineItemIndex || lineItemIndex === 0) {
            // We know this maybe be incomplete (i.e missing id) but that's ok because it could be a new line item
            payable.invoiceDetails.lineItems[lineItemIndex] = { ...cleanData } as Invoice.LineItemTransport;

            handlePayableChanges(payable, payableIndex);
        } else {
            // this is a new line item
            payable.invoiceDetails.lineItems.push(cleanData as Invoice.LineItemTransport);
            handlePayableChanges(payable, payableIndex);
        }
        reset();
        onCancel();
    };

    const {
        handleSubmit,
        register,
        control,
        formState: { isValid, isDirty, errors },
        reset,
        watch,
    } = useForm<FormProps>({
        mode: 'all',
        defaultValues: {
            itemName: '',
            description: '',
            quantity: 1,
            unitPrice: '',
        },
    });

    const watchQuantity = watch('quantity');
    const watchUnitPrice = watch('unitPrice');

    useEffect(() => {
        if (lineItem) {
            reset({
                itemName: lineItem.itemName,
                description: lineItem.description || '',
                quantity: lineItem.quantity,
                unitPrice: lineItem?.unitPrice || 0,
            });
        } else {
            reset({
                itemName: '',
                description: '',
                quantity: 1,
                unitPrice: '',
            });
        }
    }, [lineItem, reset]);

    useEffect(() => {
        if (!serviceOrder) {
            setLineItemOptions(Object.keys(haulerChargeItems));
        } else if (lineItemOptions.length === 0) {
            const lineItems = serviceOrder.serviceType.family.lineItemTypes.map((item) => item.displayName);
            setLineItemOptions(lineItems);
        }
    }, [serviceOrder]);

    const handleBillDeleteLineItem = () => {
        if (payableIndex === undefined || lineItemIndex === undefined) return;
        // get the payable
        const payable = payables[payableIndex];

        payable.invoiceDetails.lineItems.splice(lineItemIndex, 1);

        handlePayableChanges(payable, payableIndex);
        onCancel();
    };

    if (!open) return null;

    return (
        <Dialog onClose={onCancel} styledTitle={`${lineItem ? 'Edit' : 'Add'} Charge`} open={open}>
            <form className="flex flex-col gap-4">
                <div>
                    {lineItem?.itemName && !lineItemOptions.includes(lineItem.itemName) ? (
                        <p className="opacity-70">{lineItem.itemName}</p>
                    ) : (
                        <Controller
                            name="itemName"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Item name is required',
                                },
                            }}
                            render={({ field }) => (
                                <Select
                                    required={true}
                                    value={field.value}
                                    onSelect={(value) => field.onChange(value)}
                                    label="Item"
                                    error={errors.itemName}
                                >
                                    {lineItemOptions.sort().map((item) => (
                                        <SelectOption key={item} value={item}>
                                            {serviceOrder ? item : haulerChargeItems[item]}
                                        </SelectOption>
                                    ))}
                                </Select>
                            )}
                        />
                    )}
                </div>
                <div>
                    <TextField label="Description" inputProps={{ ...register('description') }} />
                </div>
                <div>
                    <TextField
                        label="Quantity"
                        inputProps={{ ...register('quantity', { required: 'Quantity is required' }), step: '0.01' }}
                        type="number"
                        required={true}
                        error={errors.quantity}
                    />
                </div>
                <div>
                    <Controller
                        name="unitPrice"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Rate is required',
                            },
                        }}
                        render={({ field }) => (
                            <CurrencyTextField
                                value={field.value}
                                useCents
                                onChange={(value) => field.onChange(value)}
                                label="Rate"
                                required={true}
                                error={errors.unitPrice}
                            />
                        )}
                    />
                </div>
                <div style={{ fontSize: 18 }}>Total: {moneyFormatter(getTotal(watchQuantity, watchUnitPrice))}</div>
            </form>
            <div className="flex justify-end gap-4 border-t pt-4">
                <button className="btn-delete mr-auto" onClick={handleBillDeleteLineItem} type="button">
                    delete
                </button>
                <button className="btn-dark-grey-outlined" onClick={onCancel} type="button">
                    Cancel
                </button>
                <button className="btn-primary" type="button" disabled={!isValid || !isDirty} onClick={handleSubmit(onSubmit)}>
                    {lineItem ? 'Update' : 'Add'}
                </button>
            </div>
        </Dialog>
    );
};

export default BillItemDialog;
