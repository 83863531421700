import { useContext, useEffect, useState } from 'react';
import { isBetaTester, useIsAppAdmin, useWaysteClient } from '@alliance-disposal/client';
import { SourProvider } from '@wayste/sour-context';
import { SourSearchProviderOld } from '@wayste/sour-search';
// import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';
import Auth from './components/Auth';
import FlashMessage from './components/FlashMessage';
import Loading from './components/Loading';
import { UIContext, UIProvider } from './contexts';
import Layout from './structure/Layout';
import { routes } from './utils';

const App = () => {
    const navigation = useHistory();
    return (
        <UIProvider>
            <SourSearchProviderOld
                onSeeAllClick={(q) => {
                    navigation.push(routes.search + `?query[query]=${q}&sort[direction]=DESC&sour-search=true`);
                }}
            >
                <MainView />
            </SourSearchProviderOld>
        </UIProvider>
    );
};

export default App;

const MainView = () => {
    const { flash } = useContext(UIContext);
    const client = useWaysteClient();
    const { isAdmin } = useIsAppAdmin();
    const userIsBetaTester = isBetaTester();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userIsLoading, setUserIsLoading] = useState(true);
    const [profileID, setProfileID] = useState<string | null>(null);
    const [subIsLoading, setSubIsLoading] = useState(true);

    const signOutUser = async () => {
        await client.auth().signOut();
        setIsLoggedIn(false);
        setUserIsLoading(false);
    };

    const fetchProfileAndRoster = async (profileID: string | null) => {
        setUserIsLoading(true);
        if (profileID) {
            // Sentry.setUser({ id: profileID });
            // Fetch user profile
            await client.user().fetchCurrentUserProfile();
            await client.profile().adminPortal.fetchRoster();
            setUserIsLoading(false);
            setIsLoggedIn(true);
        } else {
            setUserIsLoading(false);
            setIsLoggedIn(false);
        }
    };

    useEffect(() => {
        // Subscribe to auth changes.
        setSubIsLoading(true);
        const sub = client.auth().currentAuthority.subscribe((auth) => {
            setProfileID(auth?.profileID || null);
            setSubIsLoading(false);
        });
        return () => {
            sub.unsubscribe();
        };
    }, [client, userIsLoading]);

    useEffect(() => {
        if (!subIsLoading) fetchProfileAndRoster(profileID);
    }, [profileID, subIsLoading]);

    // if the user is beta tester, then redirect to the beta tester page
    useEffect(() => {
        if (isAdmin) {
            console.warn('User is admin, suppressing beta tester redirect');
            return;
        }

        if (window.location.href.includes('localhost')) {
            console.warn('User is on localhost, suppressing beta tester redirect');
            return;
        }

        // current location
        const currentLocation = window.location.href;
        // make a URL object
        const url = new URL(currentLocation);
        if (userIsBetaTester && !currentLocation.includes('v2')) {
            // change the hostname to v2
            url.hostname = 'admin.v2.sourgum.com';
            const newUrl = url.toString();

            // redirect to v2
            window.location.href = newUrl;
        } /* else if (!userIsBetaTester && currentLocation.includes('v2')) {
      // user is not beta tester, but the url contains v2
      // change the hostname to v1
      url.hostname = 'admin.sourgum.com';
      const newUrl = url.toString();

      // redirect to stable
      window.location.href = newUrl;
    } */
    }, [userIsBetaTester]);

    if (subIsLoading || (!isLoggedIn && userIsLoading)) return <Loading />;
    return (
        <SourProvider application="admin-portal" isAppAdmin={isAdmin}>
            <>
                {isLoggedIn ? (
                    <Layout
                        onSignOut={() => {
                            signOutUser();
                        }}
                    />
                ) : (
                    <Auth />
                )}
                <FlashMessage
                    showFlash={flash.showFlash}
                    flashMessageText={flash.flashMessageText}
                    flashStyle={flash.flashStyle || undefined}
                />
            </>
        </SourProvider>
    );
};
