/* eslint-disable @typescript-eslint/no-explicit-any */
import { Order } from '@alliance-disposal/transport-types';
import { useElasticSearchOld } from '@wayste/sour-search';
import { type estypes } from '@elastic/elasticsearch';

/**
 * Represents a bucket from ElasticSearch aggregated on order statuses, contains key and document count.
 */
export type StatusBucket = {
    key: Order.OrderStatus;
    doc_count: number;
};

export type VendorOrderStats = Record<
    string,
    {
        orderCount: {
            total: number;
            statusBreakdown: StatusBucket[];
        };
        lastOrder: Order.AllianceOrderTransport;
    }
>;
const { search } = useElasticSearchOld(import.meta.env.VITE_ELASTIC_KEY, import.meta.env.VITE_ELASTIC_ENVIRONMENT);

/**
 * Retrieves vendor order statistics (order count by status, total order count, last order) based on provided hauler IDs.
 * @param haulerIDs - An array of hauler IDs.
 * @returns An object containing vendor order statistics.
 */
export const getVendorOrderStats = async (haulerIDs: string[]) => {
    const query: estypes.SearchRequest = {
        size: 0,
        query: {
            bool: {
                filter: [
                    {
                        terms: {
                            'haulerID.keyword': haulerIDs,
                        },
                    },
                    {
                        terms: {
                            'status.keyword': ['ASSIGNED', 'DELIVERED', 'READY_FULL_PICKUP', 'READY_EMPTY_PICKUP'],
                        },
                    },
                ],
            },
        },
        aggs: {
            haulerIDAgg: {
                terms: {
                    field: 'haulerID.keyword',
                    size: haulerIDs.length,
                },
                aggs: {
                    statusAgg: {
                        terms: {
                            field: 'status.keyword',
                        },
                    },
                    lastOrder: {
                        top_hits: {
                            sort: [
                                {
                                    'metadata.createdAt': {
                                        order: 'desc',
                                    },
                                },
                            ],
                            size: 1,
                        },
                    },
                },
            },
        },
    };

    const rawResults = await search(['sourgum-order'], query);
    // this elastic type is not well defined. Not wasting 2 hours looking for it. Tried multiple options, all were wrong
    // Possibly AggregationsTermsAggregateBase?
    const results: VendorOrderStats = (rawResults.aggregations?.haulerIDAgg as any).buckets.reduce((hauler: any, bucket: any) => {
        const totalOrderCount = bucket.statusAgg.buckets.reduce((sum: number, bucket: { doc_count: number }) => sum + bucket.doc_count, 0);
        const statusBreakdown = bucket.statusAgg.buckets;
        const lastOrder = bucket.lastOrder.hits.hits[0]?._source;

        hauler[bucket.key] = {
            orderCount: { total: totalOrderCount, statusBreakdown },
            lastOrder,
        };

        return hauler;
    }, {} as VendorOrderStats);
    return results;
};
