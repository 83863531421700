import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { SourFiltersOld, SourSearchOld } from '@wayste/sour-search';
import { PlusIcon } from '@heroicons/react/24/solid';
import ChangeRequestDialog from '../ChangeRequestDialog';
import OrderRow from './OrderRow';

const tableHeaders = [
    { heading: 'Status' },
    { heading: 'Delivery' },
    { heading: 'Pick Up' },
    { heading: 'Expires' },
    { heading: 'Address' },
    { heading: 'Order #' },
    { heading: 'Customer' },
    { heading: 'Details' },
];
interface OrdersListProps {
    orders: (Order.AllianceOrderTransport & {
        receivables: Invoice.ReceivableTransport[];
        payables: Invoice.PayableTransport[];
    })[];
    usingSearchData: boolean;
    onCreateNewClicked: () => void;
    onSendHaulerCheckIns: () => void;
    onOrderRowClicked: (
        order: Order.AllianceOrderTransport & {
            receivables: Invoice.ReceivableTransport[];
            payables: Invoice.PayableTransport[];
        },
    ) => void;
    isDefaultFilter: boolean;
}

const OrdersList = ({ orders, usingSearchData, onCreateNewClicked, onOrderRowClicked, isDefaultFilter }: OrdersListProps) => {
    const client = useWaysteClient();
    const [changeRequestCount, setChangeRequestCount] = useState(0);
    const [changeRequestDialogOpen, setChangeRequestDialogOpen] = useState(false);

    useEffect(() => {
        const sub = client.order().adminPortal.changeRequest.subscription.query({ status: ['PENDING'] });

        const observe = sub.receive.subscribe({
            next: async (value) => {
                setChangeRequestCount(value.total);
            },
            error: (error) => {
                console.warn('error', error);
            },
        });

        return () => {
            if (sub) {
                observe.unsubscribe();
                sub.unsubscribe();
            }
        };
    }, []);

    return (
        <>
            <ChangeRequestDialog
                open={changeRequestDialogOpen}
                onClose={() => {
                    setChangeRequestDialogOpen(false);
                }}
            />
            <div className="item-center flex w-full flex-col-reverse gap-2 sm:mt-4 sm:flex-row sm:justify-between sm:p-2">
                <div className="flex w-full flex-row justify-between gap-2 px-2 sm:px-0">
                    <SourSearchOld
                        options={{
                            searchPopoverFixed: false,
                            showTips: true,
                            showMessages: !isDefaultFilter,
                            showResults: !isDefaultFilter,
                            placeholder: 'Search Orders',
                        }}
                    />
                    <div className="ml-auto h-10 sm:ml-0">
                        <SourFiltersOld className="h-10 border shadow-none" />
                    </div>
                </div>

                <div className="grid h-10 w-full min-w-fit flex-1 grid-cols-2 flex-row gap-2 p-2 sm:flex sm:py-0 sm:pl-0">
                    <button className="btn-primary h-full flex-shrink-0" onClick={() => onCreateNewClicked()}>
                        <PlusIcon className="h-5 w-5 pr-1" /> New
                    </button>

                    <button
                        className="btn-secondary-dark-grey relative flex-shrink-0"
                        onClick={() => {
                            setChangeRequestDialogOpen(true);
                        }}
                    >
                        Change Requests
                        <span className="z-1 absolute -left-2 -top-2.5 inline-flex items-center justify-center rounded-full bg-red-600 p-1 text-xs font-bold leading-none text-white">
                            {changeRequestCount}
                        </span>
                    </button>
                </div>
            </div>
            <div className="shadow-dark m-2 flex flex-1 flex-col overflow-auto rounded-md bg-white">
                <div className="w-full flex-1 overflow-x-auto">
                    <table className="w-full border-separate border-spacing-0">
                        <thead>
                            <tr>
                                {tableHeaders.map((heading) => (
                                    <td
                                        key={heading.heading}
                                        className="sticky top-0 z-[2] whitespace-nowrap border-b border-b-gray-200 bg-white p-4 text-sm"
                                    >
                                        {heading.heading}
                                    </td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order) => (
                                <OrderRow
                                    key={order.id}
                                    order={order}
                                    onOrderRowClicked={onOrderRowClicked}
                                    usingSearchData={usingSearchData}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default OrdersList;
