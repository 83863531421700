import { Order } from '@alliance-disposal/transport-types';

export type OrderSwitchTransportWithRelationship = Order.OrderSwitchTransport & { relationship: 'CHILD' | 'PARENT' };

export const getOrderCurrentSwitch = (order?: Order.AllianceOrderTransport | null): OrderSwitchTransportWithRelationship | null => {
    if (!order) return null;
    const { id, switches, status } = order;
    if (!switches || switches.length === 0) return null;
    const parentFound = switches.find((item) => item.fromOrder === id);
    if (parentFound) {
        return {
            ...parentFound,
            type: parentFound.type as string,
            relationship: 'PARENT',
        };
    }
    const childFound = switches.find((item) => item.toOrder === id);
    if (
        childFound &&
        (status === 'NEEDS_REVIEW' || status === 'UNASSIGNED' || status === 'ON_HOLD' || status === 'ASSIGNED' || status === 'DELIVERED')
    ) {
        return {
            ...childFound,
            type: childFound.type as string,
            relationship: 'CHILD',
        };
    }
    return null;
};
