import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { SourSearchResponseOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { SocketError } from '@wayste/sour-socket';
import { Pagination } from '@wayste/sour-ui';
import { getRouterPath } from '@wayste/utils';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import { routes } from '../../utils';
import Loading from '../Loading';
import OrderList from './OrdersList';

const defaultOrderFilters = {
    status: [
        'NEEDS_REVIEW',
        'UNASSIGNED',
        'ON_HOLD',
        'ASSIGNED',
        'DELIVERED',
        'READY_FULL_PICKUP',
        'READY_EMPTY_PICKUP',
        'PICKED_UP_FULL',
        'PICKED_UP_EMPTY',
        'DUMPED',
    ].join(','),
};

const OrderListContainer = () => {
    const history = useHistory();
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const searchPageSize = 25;
    const [isDefaultFilter, setIsDefaultFilter] = useState(true);
    const [dbData, setDBData] = useState<
        (Order.AllianceOrderTransport & {
            receivables: Invoice.ReceivableTransport[];
            payables: Invoice.PayableTransport[];
        })[]
    >([]);
    const [searchData, setSearchData] = useState<
        (Order.AllianceOrderTransport & {
            receivables: Invoice.ReceivableTransport[];
            payables: Invoice.PayableTransport[];
        })[]
    >([]);
    const [searchPage, setSearchPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    const [searchTotalOrderPages, setSearchTotalOrderPages] = useState(0);
    const [page, setPage] = useState(0);
    // PAGINATION LOGIC START
    const [totalOrderPages, setTotalOrderPages] = useState(0);

    ///////////////////////////////////////////////
    // NEW SEARCH
    ///////////////////////////////////////////////

    const onNavigate = (_: unknown, recordID: string, name: string) => {
        const path = getRouterPath(name, recordID, routes);
        history.push(path);
    };

    const onActiveSearch = (active: boolean) => {
        setSearchActive(active);
    };

    const onActiveFilter = (active: boolean) => {
        setFilterActive(active);
    };

    // THIS NEEDS TO BE SPEED UP
    const processHits = async (hits: SourSearchResponseOld['results']['hits']['hits']) => {
        const results: SourSearchResponseOld['results']['hits']['hits'][0]['_source'][] = [];
        hits.map((hit) => {
            results.push(hit._source);
        });
        return results as (Order.AllianceOrderTransport & {
            receivables: Invoice.ReceivableTransport[];
            payables: Invoice.PayableTransport[];
        })[];
    };

    // DEFINE ON RESULTS LOGIC
    const handleSearchResults = async (response: SourSearchResponseOld) => {
        if (searchPage !== response.page) setSearchPage(response.page);
        response.type === 'sourgum-order' ? setIsDefaultFilter(true) : setIsDefaultFilter(false);
        if (response.type === 'sourgum-order') {
            const res = await processHits(response.results.hits.hits);
            setSearchTotalOrderPages(response.totalPages);
            setSearchData(res);
        } else {
            setSearchData([]);
        }
    };

    const handleSearchPageChange = (value: number) => {
        setSearchPage(value);
    };

    ///////////////////////////////////////////////
    // END NEW SEARCH
    ///////////////////////////////////////////////

    const getOrdersWithInvoice = async (orderData: Order.AllianceOrderTransport[]) => {
        const orderIds = orderData.map((order) => order.id);

        if (orderIds.length === 0) return [];

        const receivables = await client.invoice().adminPortal.receivable.query({
            orderID: orderIds.join(','),
            limit: 200,
        });

        const payables = await client.invoice().adminPortal.payable.query({
            orderID: orderIds.join(','),
            limit: 200,
        });

        const mapped = orderData.flatMap((order) => {
            const orderReceivables = receivables.results.filter((receivable) => receivable.invoiceDetails.orderID === order.id);
            const orderPayables = payables.results.filter((payable) => payable.invoiceDetails.orderID === order.id);
            return {
                ...order,
                receivables: orderReceivables,
                payables: orderPayables,
            };
        });

        return mapped as (Order.AllianceOrderTransport & {
            receivables: Invoice.ReceivableTransport[];
            payables: Invoice.PayableTransport[];
        })[];
    };

    const handlePageChange = (value: number) => {
        setPage(value);
    };

    useEffect(() => {
        setIsLoading(true);
        const limit = 25;

        console.log('offset', (page || 0) * limit);

        let offset = (page || 0) * limit;

        if (offset > 0) {
            offset = offset - 1;
        }

        const sub = client.order().adminPortal.subscription.query({
            ...defaultOrderFilters,
            closed: false,
            limit,
            offset,
        });

        const observe = sub.receive.subscribe({
            next: async (value) => {
                setTotalOrderPages(Math.ceil(value.total / limit));

                const ordersWithInvoices = await getOrdersWithInvoice(value.results);
                setDBData(ordersWithInvoices);
                setIsLoading(false);
            },
            error: (error) => {
                showFlash((error as SocketError).additionalInfo || 'An Error Occurred Getting Orders', 'warning');
                setIsLoading(false);
            },
        });

        // (response: { status: string; data: Order.AllianceOrderTransport[]; count: number }) => {
        //   if (response.status === 'error') {
        //     showFlash('An Error Occurred Getting Orders', 'warning');
        //     setIsLoading(false);
        //   }
        //   setTotalOrderPages(Math.ceil(response.count / limit));
        //   console.log('response.data: ', response.data);

        //   getOrdersWithInvoice(response.data).then((data) => {
        //     console.log('data: ', data);
        //     setDBData(data);
        //     setIsLoading(false);
        //   });
        // },
        // );

        return () => {
            if (sub) {
                observe.unsubscribe();
                sub.unsubscribe();
            }
        };
    }, [page]);

    const handleOpenOrderDetails = (
        order: Order.AllianceOrderTransport & {
            receivables: Invoice.ReceivableTransport[];
            payables: Invoice.PayableTransport[];
        },
    ) => {
        history.push(routes.orders.details(order.id), { modal: true });
    };

    const handleCreateNewClicked = () => {
        history.push(routes.orders.new);
    };

    const handleSendHaulerCheckIns = async () => {
        alert('This needs to be hooked up AAP-690');
    };

    if (isLoading) return <Loading />;

    return (
        <>
            <SourSearchWrapperOld
                options={{
                    application: 'aap',
                    apiKey: import.meta.env.VITE_ELASTIC_KEY,
                    environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                }}
                onNavigate={onNavigate}
                onResults={handleSearchResults}
                onSearch={onActiveSearch}
                onFilter={onActiveFilter}
                createQueryParams={{ method: 'with_filter', removeOn: 'empty_string_inactive_filter' }}
                page={searchPage} // SourSearchOld is 0-indexed, we are 1-indexed
                size={searchPageSize}
                defaultFilters={{
                    activateOnInitial: false,
                    query: {
                        type: 'sourgum-order',
                        filters: {
                            status: {
                                field: 'status',
                                type: 'STRING',
                                operator: 'IS_NOT_IN',
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                value: ['COMPLETED', 'CANCELLED'],
                            },
                        },
                        sort: {
                            field: 'expectedDeliveryDate',
                            direction: 'DESC',
                        },
                    },
                }}
            >
                <OrderList
                    isDefaultFilter={isDefaultFilter}
                    usingSearchData={searchActive || filterActive ? true : false}
                    orders={searchActive || filterActive ? searchData : dbData}
                    onCreateNewClicked={handleCreateNewClicked}
                    onOrderRowClicked={handleOpenOrderDetails}
                    onSendHaulerCheckIns={handleSendHaulerCheckIns}
                />
                <div
                    style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingTop: '0.25rem',
                        paddingBottom: '0.25rem',
                    }}
                >
                    {searchActive || filterActive ? (
                        <Pagination
                            pageCount={searchTotalOrderPages}
                            page={searchPage}
                            onPageChange={(newPage) => handleSearchPageChange(newPage)}
                        />
                    ) : (
                        <Pagination pageCount={totalOrderPages} page={page} onPageChange={(newPage) => handlePageChange(newPage)} />
                    )}
                </div>
            </SourSearchWrapperOld>
        </>
    );
};

export default OrderListContainer;
