import React from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';

type Props = {
    showFlash: boolean;
    flashStyle?: 'warning' | 'success' | 'error' | 'info' | '';
    flashMessageText: string;
};

const defaultProps = {
    flashStyle: null,
};

const FlashMessage = ({ showFlash, flashStyle, flashMessageText }: Props) => {
    const getStyle = () => {
        switch (flashStyle) {
            case 'error':
            case 'warning':
                return (
                    <div className="bg-delete flex min-w-[288px] items-center rounded px-4 py-3.5 text-sm text-white shadow-xl">
                        <ExclamationTriangleIcon className="mr-2 h-6 w-6" />
                        {flashMessageText}
                    </div>
                );
            case 'success':
                return (
                    <div className="bg-success flex min-w-[288px] items-center rounded px-4 py-3.5 text-sm text-white shadow-xl">
                        <CheckCircleIcon className="mr-2 h-6 w-6" />
                        {flashMessageText}
                    </div>
                );
            default:
                return (
                    <div className="flex min-w-[288px] items-center rounded bg-black px-4 py-3.5 text-sm text-white shadow-xl">
                        {flashMessageText}
                    </div>
                );
        }
    };

    return (
        <Transition
            show={showFlash}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="fixed left-auto right-6 top-6 z-[1400] items-center justify-end">{getStyle()}</div>
        </Transition>
    );
};

FlashMessage.defaultProps = defaultProps;

export default FlashMessage;
