import { useWaysteClient } from '@alliance-disposal/client';
import { InternalTicket } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Dialog } from '@wayste/sour-ui';
import InternalTicketForm, { InternalTicketFormFormProps } from './InternalTicketForm';

type InternalTicketCreateUpdateDialogProps = {
    open: boolean;
    onClose: () => void;
    entityID: string;
    entityType: InternalTicket.InternalTicketRelatedEntity;
    replyToID?: string;
    ticket?: InternalTicket.InternalTicketTransport;
};

const InternalTicketCreateUpdateDialog = ({
    open,
    onClose,
    entityID,
    entityType,
    replyToID,
    ticket,
}: InternalTicketCreateUpdateDialogProps) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = async (data: InternalTicketFormFormProps) => {
        try {
            if (ticket) {
                const backendData: InternalTicket.InternalTicketUpdate = {
                    issueCausedByProfileID: data.issueCausedByProfileID,
                    monetaryLoss: data.monetaryLoss,
                    status: data.status,
                    assignedToProfileID: data.assignedToProfileID || null,
                    assignedTeam: data.assignedTeam || null,
                    tags: data.tags.map((tag) => tag.id),
                    dueDate: data.dueDate ? data.dueDate.toISOString() : null,
                };
                await client.internalTicket().adminPortal.update(ticket.id, backendData);
            } else {
                const backendData: InternalTicket.InternalTicketCreate = {
                    note: data.note,
                    issueCausedByProfileID: data.issueCausedByProfileID,
                    monetaryLoss: data.monetaryLoss,
                    status: data.status || undefined,
                    assignedToProfileID: data.assignedToProfileID || undefined,
                    assignedTeam: data.assignedTeam || undefined,
                    entityID,
                    entityType,
                    replyToID: replyToID || undefined,
                    tags: data.tags.map((tag) => tag.id),
                    dueDate: data.dueDate ? data.dueDate.toISOString() : undefined,
                };
                await client.internalTicket().adminPortal.create(backendData);
            }
            setShowToast({
                message: 'Successfully updated ticket',
                severity: 'success',
            });
            handleClose();
        } catch (error) {
            console.warn('Error creating internal ticket: ', error);
            setShowToast({
                message: 'Error updating ticket',
                severity: 'error',
            });
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} styledTitle="Create New Ticket" variant="underlined-header" showX className="max-w-2xl">
            <InternalTicketForm ticket={ticket} onSubmit={handleSubmit} />
            <div className="mt-6 flex justify-end gap-4">
                <Button onClick={handleClose} className="btn-dark-grey-outlined">
                    Cancel
                </Button>
                <Button type="submit" className="btn-primary" form="internal-ticket-form">
                    Save
                </Button>
            </div>
        </Dialog>
    );
};

export default InternalTicketCreateUpdateDialog;
