import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Pricing } from '@alliance-disposal/transport-types';
import { ButtonGroup } from '@wayste/sour-ui';
import { sortByKey } from '@wayste/utils';
import { PlusIcon } from '@heroicons/react/24/solid';
import { cloneDeep } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import routes from '../../utils/routes';
import ButtonSelect from '../ButtonSelect';
import Loading from '../Loading';
import CustomPricingList from './CustomPricingList';
import PricingList from './PricingList';

const stateOptions = Pricing.USStateAbbreviations.map((abb) => ({
    label: abb,
    value: abb,
}));

const PricingListContainer = () => {
    const client = useWaysteClient();
    const location = useLocation();
    const history = useHistory();
    const [state, setState] = useState<Pricing.UsStateAbbreviation>('NJ');
    const [pricing, setPricing] = useState<Pricing.PricingTransport[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [view, setView] = useState<'pricing' | 'custom'>('pricing');
    const [selectedPricingZoneID, setSelectedPricingZoneID] = useState<string | undefined>(undefined);
    const [customPricing, setCustomPricing] = useState<
        (Pricing.CustomerDiscountTransport & { customerData: Customer.AllianceCustomerTransport })[]
    >([]);

    const handleStateChange = async (newState: Pricing.UsStateAbbreviation) => {
        setIsLoading(true);
        setState(newState);

        const data = await client.pricing().adminPortal.pricing.query({ state: newState as any, public: true, noJoins: true });

        // TODO replace with a function that only fetches public pricing
        const sortedPricing = sortByKey('zoneName', data).filter((pricing) => pricing.public);
        setPricing(sortedPricing as Pricing.PricingTransport[]);
        setIsLoading(false);
        history.replace(`${routes.pricing.list}?state=${newState}`);
    };

    const handlePricingZoneChange = async (zoneID: string) => {
        setIsLoading(true);
        setSelectedPricingZoneID(zoneID);
        try {
            const customerDiscounts = await client
                .pricing()
                .adminPortal.customerDiscount.query({ contractor: false, pricingZoneID: zoneID, limit: 100 });
            if (customerDiscounts.results.length === 100) {
                alert('Get a SAP dev to put pagination on this page');
            }
            const deepClone = cloneDeep(customerDiscounts.results);
            const uniqueIdsSet = new Set();
            const customerIDs = deepClone
                .map((item) => {
                    // Check if the 'id' is already in the Set
                    if (!uniqueIdsSet.has(item.customerID)) {
                        // If not in the Set, add it and return the 'id' as a string
                        uniqueIdsSet.add(item.customerID);
                        return item.customerID;
                    }
                    // If the 'id' is already in the Set, return null to filter it out later
                    return null;
                })
                .filter((id) => id !== null);

            try {
                const promises = customerIDs.map(async (id) => {
                    if (id) {
                        const res = await client.customer().adminPortal.fetch(id);
                        return res;
                    }
                });
                const results = await Promise.all(promises);
                if (!results) return;
                const customerResults = results.filter((item) => item).map((item) => item);
                const discountsWithCustomers: (Pricing.CustomerDiscountTransport & {
                    customerData: Customer.AllianceCustomerTransport;
                })[] = [];
                for (const discount of customerDiscounts.results) {
                    const customerData = customerResults.find((item) => item?.id === discount.customerID);
                    if (customerData) {
                        discountsWithCustomers.push({
                            ...discount,
                            customerData,
                        });
                    }
                }
                setCustomPricing(discountsWithCustomers);
                setIsLoading(false);
            } catch (error) {
                console.warn('error: ', error);
                alert('Something went wrong get a SAP dev');
                return;
            }
        } catch (error) {
            console.warn('handleSetExpandedSection ERROR', error);
            alert('An error has occurred while fetching customer discounts.');
        }
    };

    useEffect(() => {
        if (location.search.includes('state=')) {
            const passedState = location.search.slice(location.search.indexOf('state=') + 6);
            handleStateChange(passedState.substring(0, 2) as Pricing.UsStateAbbreviation);
        } else {
            handleStateChange('NJ');
        }
    }, []);

    const handleCreateNewClick = () => {
        history.push(routes.pricing.new);
    };

    return (
        <div className="container mx-auto flex flex-1 flex-col px-6 pb-2 pt-5">
            {isLoading && <Loading fullScreen />}
            <div className="flex justify-between pb-5">
                <div className="flex gap-8">
                    <ButtonGroup
                        activeBtn={view}
                        onClick={(value) => setView(value as 'pricing' | 'custom')}
                        buttons={[
                            {
                                label: 'Pricing List',
                                value: 'pricing',
                            },
                            {
                                label: 'Customer Pricing',
                                value: 'custom',
                            },
                        ]}
                    />
                    <ButtonSelect label="State" menuItems={stateOptions} value={state} onSelect={handleStateChange} />
                    {view === 'custom' && (
                        <ButtonSelect
                            label="Pricing Zones"
                            menuItems={pricing.map((item) => ({ label: item.zoneName || 'MISSING NAME', value: item.id }))}
                            value={selectedPricingZoneID}
                            onSelect={handlePricingZoneChange}
                        />
                    )}
                </div>
                <button className="btn-primary ml-8" onClick={() => handleCreateNewClick()}>
                    <PlusIcon className="h-5 w-5 pr-1" /> New
                </button>
            </div>
            <div className="shadow-dark flex flex-1 flex-col overflow-auto rounded bg-white pt-2.5">
                {view === 'pricing' ? <PricingList pricing={pricing} /> : <CustomPricingList customPricing={customPricing} />}
            </div>
        </div>
    );
};

export default PricingListContainer;
