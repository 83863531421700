import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import type { InternalTicket, InternalTicket as InternalTicketTypes, S3ItemReference } from '@alliance-disposal/transport-types';
import { ImageDialog } from '@wayste/sour-components';
import { useSourContext } from '@wayste/sour-context';
import { Button, ButtonGroup } from '@wayste/sour-ui';
import { Disclosure } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/20/solid';
import Loading from '../Loading';
import InternalTicketCreateUpdateDialog from './InternalTicketCreateUpdateDialog';
import { InternalTicketList } from './InternalTicketList';
import { RedactTicketDialog } from './RedactDialog';
import { InternalTicketContext } from './context';

export const InternalTicketContent = ({ open, readOnly = false }: { open: boolean; readOnly?: boolean }) => {
    const {
        entityID,
        isLoading,
        ticketStatusCounts,
        filterStatuses,
        setFilterStatuses,
        filterTagIDs,
        setFilterTagIDs,
        allTagsOnTickets,
        entityType,
        activeTab,
        setActiveTab,
        showReplies,
        handleShowReplies,
    } = useContext(InternalTicketContext);
    const [showCreateUpdateTicket, setShowCreateUpdateTicket] = useState<{
        open: boolean;
        replyToID?: string;
        ticket?: InternalTicketTypes.InternalTicketTransport;
    }>({
        open: false,
        replyToID: undefined,
        ticket: undefined,
    });
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [showRedactDialog, setShowRedactDialog] = useState<string | undefined>(undefined);
    const [showImageDialog, setShowImageDialog] = useState<InternalTicket.InternalTicketTransport | undefined>(undefined);

    const handleReplyClick = (ticketID: string) => {
        setShowCreateUpdateTicket({ open: true, replyToID: ticketID, ticket: undefined });
    };

    const handleFilterStatusClick = (status: InternalTicketTypes.InternalTicketStatus | 'IMAGES') => {
        if (status) {
            if (filterStatuses.includes(status)) {
                setFilterStatuses(filterStatuses.filter((s) => s !== status));
            } else {
                setFilterStatuses([...filterStatuses, status]);
            }
        }
    };

    const handleFilterTagClick = (tagID: string) => {
        if (tagID) {
            if (filterTagIDs.includes(tagID)) {
                setFilterTagIDs(filterTagIDs.filter((t) => t !== tagID));
            } else {
                setFilterTagIDs([...filterTagIDs, tagID]);
            }
        }
    };

    const clearAllFilters = () => {
        setFilterStatuses([]);
        setFilterTagIDs([]);
    };

    const handleSaveImages = async (images: S3ItemReference[]) => {
        if (!showImageDialog) return;
        try {
            const updatedTicket = await client.internalTicket().adminPortal.update(showImageDialog.id, { images });
            setShowImageDialog(updatedTicket);
        } catch (error) {
            console.warn('error uploading S3 images to InternalTicket: ', error, images);
            setShowToast({
                severity: 'error',
                message: 'Error uploading image',
            });
        }
    };

    if (readOnly) {
        return (
            <div className="relative">
                {isLoading && (
                    <div className="absolute inset-0 z-10 bg-white">
                        <Loading />
                    </div>
                )}
                <div className="flex flex-col gap-2 pb-3">
                    <div className="flex gap-4">
                        <div className="inline-flex items-center">Internal Tickets</div>
                        <Disclosure.Button className="btn-secondary-text-only ml-auto py-1">{open ? 'Hide' : 'Show'}</Disclosure.Button>
                    </div>
                </div>
                <hr />
                <Disclosure.Panel>
                    <div className="pt-4">
                        <InternalTicketList
                            onReplyClick={handleReplyClick}
                            onEditClick={(ticket) => setShowCreateUpdateTicket({ open: true, replyToID: undefined, ticket })}
                            onRedactClick={(ticketID) => setShowRedactDialog(ticketID)}
                            onShowImageClick={setShowImageDialog}
                            readOnly
                        />
                    </div>
                </Disclosure.Panel>
            </div>
        );
    }

    return (
        <div className="shadow-dark relative rounded bg-white p-3">
            {isLoading && (
                <div className="absolute inset-0 z-10 bg-white">
                    <Loading />
                </div>
            )}
            <div className="flex flex-col gap-2 pb-3">
                <div className="flex gap-4">
                    <div className="inline-flex items-center text-xl">Internal Tickets</div>
                    <div className="flex items-center gap-2">
                        <div
                            className="cursor-pointer rounded bg-gray-600 px-2 py-1 text-sm text-white"
                            style={{ border: filterStatuses.includes('OPEN') ? 'solid 1px #00FFFF' : 'transparent' }}
                            onClick={() => handleFilterStatusClick('OPEN')}
                        >
                            Open: {ticketStatusCounts.OPEN}
                        </div>
                        <div
                            className="cursor-pointer rounded bg-blue-600 px-2 py-1 text-sm text-white"
                            style={{ border: filterStatuses.includes('IN_PROGRESS') ? 'solid 1px #00FFFF' : 'transparent' }}
                            onClick={() => handleFilterStatusClick('IN_PROGRESS')}
                        >
                            In Progress: {ticketStatusCounts.IN_PROGRESS}
                        </div>
                        <div
                            className="cursor-pointer rounded bg-green-600 px-2 py-1 text-sm text-white"
                            style={{ border: filterStatuses.includes('SOLVED') ? 'solid 1px #00FFFF' : 'transparent' }}
                            onClick={() => handleFilterStatusClick('SOLVED')}
                        >
                            Solved: {ticketStatusCounts.SOLVED}
                        </div>
                        <div
                            className="cursor-pointer rounded border border-gray-600 px-2 py-1 text-sm text-gray-600"
                            style={filterStatuses.includes('IMAGES') ? { border: 'solid 1px #00FFFF' } : {}}
                            onClick={() => handleFilterStatusClick('IMAGES')}
                        >
                            Images: {ticketStatusCounts.IMAGES}
                        </div>
                        <div className="ml-3 flex gap-x-2">
                            <ButtonGroup
                                buttons={[
                                    {
                                        label: 'Active',
                                        value: 'ACTIVE',
                                    },
                                    {
                                        label: 'Archived',
                                        value: 'ARCHIVED',
                                    },
                                ]}
                                activeBtn={activeTab}
                                onClick={(value) => setActiveTab(value as 'ACTIVE' | 'ARCHIVED')}
                            />
                        </div>
                    </div>
                    <div className="ml-auto flex gap-x-2">
                        <Button className="btn-secondary-text-only" onClick={() => handleShowReplies('all')}>
                            {Object.values(showReplies).every((val) => val) ? 'Hide' : 'Show'} Replies
                        </Button>
                        <Disclosure.Button className="btn-secondary-text-only">{open ? 'Hide' : 'Show'} All</Disclosure.Button>
                    </div>
                </div>
                <div className="flex items-center gap-4">
                    <div className="w-[137px]">
                        {(filterStatuses.length > 0 || filterTagIDs.length > 0) && (
                            <Button
                                className="btn-secondary-text-only"
                                onClick={clearAllFilters}
                                startIcon={<FunnelIcon className="size-4" />}
                            >
                                Clear
                            </Button>
                        )}
                    </div>
                    {allTagsOnTickets.map((tag) => (
                        <div
                            key={tag.tag.id}
                            className="cursor-pointer rounded px-2 py-1 text-xs"
                            style={{
                                backgroundColor: tag.tag.backgroundColor || 'white',
                                border: 'solid 1px black',
                                borderColor: filterTagIDs.includes(tag.tag.id) ? '#00FFFF' : 'black',
                                color: tag.tag.textColor || 'black',
                            }}
                            onClick={() => handleFilterTagClick(tag.tag.id)}
                        >
                            {tag.tag.name}: {tag.count}
                        </div>
                    ))}
                </div>
            </div>
            <hr />
            <Disclosure.Panel>
                <div className="pt-4">
                    <InternalTicketList
                        onReplyClick={handleReplyClick}
                        onEditClick={(ticket) => setShowCreateUpdateTicket({ open: true, replyToID: undefined, ticket })}
                        onRedactClick={(ticketID) => setShowRedactDialog(ticketID)}
                        onShowImageClick={(ticket) => setShowImageDialog(ticket)}
                    />
                </div>
            </Disclosure.Panel>
            <div className="mt-2 flex justify-end">
                <Button
                    onClick={() => setShowCreateUpdateTicket({ open: true, replyToID: undefined, ticket: undefined })}
                    className="btn-primary-text-only"
                >
                    Add New Ticket
                </Button>
            </div>
            {/* Dialogs */}
            <InternalTicketCreateUpdateDialog
                open={showCreateUpdateTicket.open}
                onClose={() => setShowCreateUpdateTicket({ open: false, replyToID: undefined, ticket: undefined })}
                entityID={entityID}
                entityType={entityType as InternalTicketTypes.InternalTicketRelatedEntity}
                replyToID={showCreateUpdateTicket.replyToID}
                ticket={showCreateUpdateTicket.ticket}
            />
            <RedactTicketDialog ticketID={showRedactDialog} onClose={() => setShowRedactDialog(undefined)} />
            {showImageDialog && (
                <ImageDialog
                    open={Boolean(showImageDialog)}
                    entityID={entityID}
                    imageReferences={showImageDialog.images}
                    onClose={() => setShowImageDialog(undefined)}
                    setImageReferences={handleSaveImages}
                    restrictions={{
                        create: Boolean(showImageDialog.archivedDate),
                        delete: Boolean(showImageDialog.archivedDate),
                        notes: Boolean(showImageDialog.archivedDate),
                        allowedTags: [],
                    }}
                />
            )}
        </div>
    );
};
