import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { type Invoice } from '@alliance-disposal/transport-types';
import { SourFiltersOld, SourSearchOld, SourSearchResponseOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { DataGrid, DataGridProps } from '@wayste/sour-ui';
import { formatISODateString, formatServiceAddress, formatServiceDate, getRouterPath } from '@wayste/utils';
import { format } from 'date-fns';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../../contexts';
import { routes } from '../../../utils';
import { GridCellStatus } from './renderCellReceivablesStatus';

const BillingListPayables = () => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const history = useHistory();
    const [openInvoicesLoading, setOpenInvoicesLoading] = useState(true);
    const [openInvoices, setOpenInvoices] = useState<Invoice.PayableTransport[]>([]);
    const [invoicesCount, setInvoicesCount] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(25);
    const [searchActive, setSearchActive] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    const [searchResults, setSearchResults] = useState<Invoice.PayableTransport[]>([]);
    const [searchResultsCount, setSearchResultsCount] = useState<number>(0);
    const [searchLoading, setSearchLoading] = useState(false);
    ///////////////////// FOR SOUR SEARCH /////////////////////
    const defaultFilter = 'sourgum-payable';
    const [isDefaultFilter, setIsDefaultFilter] = useState<boolean | undefined>(true);
    const [searchPage, setSearchPage] = useState<number>(0);
    const [searchPageSize, setSearchPageSize] = useState<number>(25);

    const onNavigate = (_: unknown, recordID: string, name: string) => {
        const path = getRouterPath(name, recordID, routes);
        history.push(path);
    };
    const processHits = async (hits: SourSearchResponseOld['results']['hits']['hits']) => {
        const results = hits.map((hit) => hit._source) as Invoice.PayableTransport[];
        return results;
    };
    // DEFINE ON RESULTS LOGIC
    const handleSearchResults = async (response: SourSearchResponseOld) => {
        setSearchLoading(true);
        if (searchPage !== response.page) setSearchPage(response.page);
        response.type === defaultFilter ? setIsDefaultFilter(true) : setIsDefaultFilter(false);
        const res = await processHits(response.results.hits.hits);
        setSearchResultsCount(response.totalHits);
        setSearchResults(res);
        setSearchLoading(false);
    };

    const onActiveSearch = (active: boolean) => {
        setSearchActive(active);
    };

    const onActiveFilter = (active: boolean) => {
        setFilterActive(active);
    };

    const handlePayableQuery = async () => {
        setOpenInvoicesLoading(true);
        try {
            const response = await client.invoice().adminPortal.payable.query({
                paidInFull: false,
                limit: pageSize,
                offset: (page || 0) * pageSize,
                // sortBy: 'invoiceDetails.dueDate',
                // sortDescending: true,
            });
            setInvoicesCount(response.total);
            setOpenInvoices(response.results);
        } catch (error) {
            console.warn('handlePayableQuery error: ', error);
            showFlash('Error Fetching Payables', 'warning');
        } finally {
            setOpenInvoicesLoading(false);
        }
    };

    ///////////////////////////////////////////////
    useEffect(() => {
        handlePayableQuery();
    }, [page, pageSize]);

    ///////////////////////////////////////////////
    // TABLE SCHEMA
    ///////////////////////////////////////////////
    const columns: DataGridProps<Invoice.PayableTransport>['columns'] = [
        {
            key: 'serviceType',
            name: 'Service Type',
            width: 200,
            formatter: ({ row }) => <span>{row.invoiceDetails.serviceGroupingID ? 'Universal Service' : 'Rolloff'}</span>,
        },
        {
            key: 'orderNumber',
            name: 'Invoice #',
            width: 100,
            formatter: (value) => (
                <span>
                    {value.row.invoiceDetails.orderNumber} - {value.row.invoiceDetails.invoiceNumber}
                </span>
            ),
        },
        {
            key: 'memo',
            name: 'Memo',
            width: 100,
            formatter: (value) => <span>{value.row.invoiceDetails.memo}</span>,
        },
        {
            key: 'payablesStatus',
            name: 'Status',
            headerAlign: 'center',
            align: 'center',
            width: 100,
            formatter: (value) => <GridCellStatus value={value.row.invoiceDetails.status} />,
        },
        {
            key: 'issueDate',
            name: 'SAP Created',
            width: 110,
            formatter: (value) => (
                <span>
                    {value.row.invoiceDetails.issueDate &&
                        formatISODateString(formatServiceDate(value.row.invoiceDetails.issueDate, 'string'))}
                </span>
            ),
        },
        {
            key: 'paidOn',
            name: 'Paid On',
            width: 100,
            formatter: (value) => (
                <span>
                    {value.row.invoiceDetails.payments &&
                        value.row.invoiceDetails.payments.map(
                            (payment, index) =>
                                format(new Date(payment.date), 'MM/dd/yy') +
                                (index < value.row.invoiceDetails.payments.length - 1 ? ', ' : ''),
                        )}
                </span>
            ),
        },
        {
            key: 'vendorName',
            name: 'Vendor',
            width: 200,
            formatter: (value) => <span>{value.row.vendorName}</span>,
        },
        {
            key: 'serviceAddress',
            name: 'Address',
            formatter: (value) => <span>{formatServiceAddress(value.row.invoiceDetails.orderServiceLocation?.address)}</span>,
        },
    ];
    ///////////////////////////////////////////////
    // RENDER
    ///////////////////////////////////////////////
    return (
        <>
            <div className="item-center flex w-full flex-row justify-between py-2">
                <SourSearchWrapperOld
                    options={{
                        application: 'aap',
                        apiKey: import.meta.env.VITE_ELASTIC_KEY as string,
                        environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                    }}
                    onNavigate={onNavigate}
                    onResults={handleSearchResults}
                    onSearch={onActiveSearch}
                    onFilter={onActiveFilter}
                    defaultFilters={{
                        query: {
                            type: defaultFilter,
                        },
                    }}
                    size={searchPageSize}
                    page={searchPage}
                    createQueryParams={{ method: 'with_filter', removeOn: 'empty_string_inactive_filter' }}
                >
                    <div className="flex w-full flex-row justify-between space-x-4">
                        <SourSearchOld
                            options={{
                                searchPopoverFixed: false,
                                showTips: !isDefaultFilter,
                                showMessages: !isDefaultFilter,
                                showResults: !isDefaultFilter,
                                placeholder: 'Search Payables',
                            }}
                        />
                        <SourFiltersOld />
                    </div>
                </SourSearchWrapperOld>
            </div>
            <div className="flex h-0 w-full flex-1 grow basis-0">
                <DataGrid
                    rows={searchActive || filterActive ? searchResults : openInvoices}
                    loading={searchActive || filterActive ? searchLoading : openInvoicesLoading}
                    columns={columns}
                    // rowHeight={60}
                    onRowClick={(row, column) => {
                        if (column.key === 'status') {
                            return;
                        }
                        let historyObj = {
                            pathname: routes.billing.list,
                            search: row.invoiceDetails.orderID ? `?id=${String(row.invoiceDetails.orderID)}&view=bill` : ``,
                        };
                        if (row.invoiceDetails.serviceGroupingID) {
                            if (row.invoiceDetails.lineItems.length >= 1) {
                                const serviceOrderID = row.invoiceDetails.lineItems[0].serviceOrderID;
                                historyObj = {
                                    pathname: routes.billingProduct.list,
                                    search: qs.stringify({
                                        serviceGroupingID: row.invoiceDetails.serviceGroupingID,
                                        serviceOrderID: serviceOrderID,
                                    }),
                                };
                            } else {
                                alert('Missing service order id. Get a SAP dev');
                            }
                        }
                        history.push(historyObj);
                    }}
                    pagination={{
                        itemCount: searchActive ? searchResultsCount : invoicesCount,
                        page: searchActive || filterActive ? searchPage : page,
                        rowsPerPage: searchActive || filterActive ? searchPageSize : pageSize,
                        rowsPerPageOptions: [25, 50, 100],
                        onPageChange: (newPage) => {
                            setPage(newPage);
                            setSearchPage(newPage);
                        },
                        onRowsPerPageChange: (rowsPerPage) => {
                            setPageSize(rowsPerPage);
                            setSearchPageSize(rowsPerPage);
                            setPage(0);
                        },
                    }}
                />
            </div>
        </>
    );
};
export default BillingListPayables;
