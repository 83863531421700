import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { V1 } from '@alliance-disposal/pricing';
import { Customer, UniversalService } from '@alliance-disposal/transport-types';
import { daysRemainingInBillingPeriod, moneyFormatter, toTitleCase } from '@wayste/utils';
import { UniversalServiceFormData } from './UniversalServiceCreate';

type Row = {
    type: string;
    sku: string;
    lineItem: string;
    unitPrice: number;
    prorated?: string;
    quantity: number;
    total: number;
    taxable: string;
};

interface TotalTableProps {
    formData: UniversalServiceFormData;
    total: number;
    setTotal: React.Dispatch<React.SetStateAction<number>>;
    customer?: Customer.AllianceCustomerTransport;
}

const TotalTable = ({ formData, total, setTotal, customer }: TotalTableProps) => {
    if (!formData.serviceGrouping || !formData.serviceGrouping.serviceOrders || formData.serviceGrouping.serviceOrders.length === 0) {
        return <></>;
    }

    const client = useWaysteClient();
    const [serviceTypeFamilies, setServiceTypeFamilies] = useState<UniversalService.ServiceTypeFamily[]>([]);
    const [subTotal, setSubTotal] = useState<number>(0);
    const [tax, setTax] = useState<number>(0);
    const [state, setState] = useState<string>('');

    useEffect(() => {
        client
            .universalService()
            .serviceFamily.query({ orderBy: 'name' })
            .then((response) => {
                setServiceTypeFamilies(response.results);
            });
    }, []);

    const getRowFromServiceEvent = (
        serviceEvent: UniversalService.ServiceEventCreate | UniversalService.ServiceEventTemplate,
        serviceTypeFamily: UniversalService.ServiceTypeFamily,
        serviceType: UniversalService.ServiceType,
        prorate?: string,
        prorateValue?: number,
    ) => {
        return {
            type: serviceTypeFamily?.name ?? '',
            sku: serviceType?.name ?? '',
            lineItem: serviceTypeFamily?.lineItemTypes.find((lineItem) => lineItem.id === serviceEvent.lineItemTypeID)?.description ?? '',
            unitPrice: serviceEvent.unitPrice,
            quantity: serviceEvent.priceQuantity,
            prorated: formData.prorate ? prorate : '',
            total: serviceEvent.unitPrice * serviceEvent.priceQuantity * (formData.prorate ? prorateValue ?? 1 : 1),
            taxable: serviceEvent.taxable ? 'Yes' : 'No',
        };
    };

    const getRowsFromFormData = (formData: UniversalServiceFormData) => {
        const rows: Row[] = [];
        formData.serviceGrouping.serviceOrders.forEach((serviceOrder, index) => {
            let serviceTypeFamily: UniversalService.ServiceTypeFamily | undefined = undefined;
            let serviceType: UniversalService.ServiceType | undefined = undefined;
            serviceTypeFamilies.forEach((family) => {
                family.serviceTypes.forEach((type) => {
                    if (type.id === serviceOrder.serviceTypeID) {
                        serviceTypeFamily = family;
                        serviceType = type;
                    }
                });
            });
            if (!serviceTypeFamily || !serviceType) {
                return;
            }
            if (!serviceOrder.serviceEvents || serviceOrder.serviceEvents.length === 0) {
                return;
            }
            // Add Recurring Service Events
            if (serviceOrder.subscriptionDetails && typeof serviceOrder.subscriptionDetails !== 'string') {
                if (!serviceOrder.subscriptionDetails?.periodicEvents || serviceOrder.subscriptionDetails?.periodicEvents.length === 0) {
                    return;
                }

                serviceOrder.subscriptionDetails?.periodicEvents.forEach((serviceEvent) => {
                    if (!serviceTypeFamily || !serviceType || serviceEvent.unitPrice === 0) {
                        return;
                    }
                    // this is needed so that the original service event is not mutated
                    const event = { ...serviceEvent };
                    event.priceQuantity *= formData.quantity[index];
                    if (formData.subscriptionDetails.billingFrequencyUnit !== 'month') {
                        rows.push(getRowFromServiceEvent(event, serviceTypeFamily, serviceType));
                        return;
                    }

                    const d = daysRemainingInBillingPeriod({
                        billingFrequency: formData.subscriptionDetails.billingFrequency,
                        billingFrequencyUnit: formData.subscriptionDetails.billingFrequencyUnit,
                        billingDay: formData.subscriptionDetails.billingDay,
                    });

                    rows.push(
                        getRowFromServiceEvent(
                            event,
                            serviceTypeFamily,
                            serviceType,
                            `${d.daysRemaining}/${d.totalDays}`,
                            d.daysRemaining / d.totalDays,
                        ),
                    );
                });
            }
            // Add One Time Service Events
            serviceOrder.serviceEvents?.forEach((serviceEvent) => {
                if (!serviceTypeFamily || !serviceType || serviceEvent.unitPrice === 0) {
                    return;
                }
                const event = { ...serviceEvent };
                event.priceQuantity *= formData.quantity[index];
                rows.push(getRowFromServiceEvent(event, serviceTypeFamily, serviceType));
            });
        });
        return rows;
    };

    const [data, setData] = useState<Row[]>(getRowsFromFormData(formData) ?? []);
    useEffect(() => {
        setData(getRowsFromFormData(formData) ?? []);
    }, [formData]);

    useEffect(() => {
        setSubTotal(data.map((row) => row.total).reduce((a, b) => a + b, 0));
        setState(formData.serviceGrouping.serviceOrders[0].serviceLocation?.address.state ?? ''); // All service orders should have the same address
        if (formData.customer?.taxExempt || customer?.taxExempt) {
            setTax(0);
        } else {
            setTax(
                data
                    .map((row) => {
                        if (row.taxable === 'Yes') {
                            return row.total * V1.findTaxRate(state);
                        } else {
                            return 0;
                        }
                    })
                    .reduce((a, b) => a + b, 0),
            );
        }
        setTotal(subTotal + tax);
    }, [data, subTotal]);

    return (
        <div className="w-full content-center overflow-auto rounded border">
            <table className="w-full overflow-y-auto text-sm">
                <thead>
                    <tr className="border-b bg-slate-50 [&>*]:px-4 [&>*]:py-1.5">
                        <td>Type</td>
                        <td>SKU</td>
                        <td>Line Item</td>
                        <td>Unit Price</td>
                        <td>Prorated</td>
                        <td>QTY</td>
                        <td>Taxable</td>
                        <td className="text-right">Total</td>
                    </tr>
                </thead>
                <tbody>
                    {data.length === 0 && (
                        <tr className="[&>*]:px-4 [&>*]:py-1.5">
                            <td colSpan={8} className="text-center italic">
                                No data to display
                            </td>
                        </tr>
                    )}
                    {data.map((row, index) => (
                        <tr key={index} className="border-b [&>*]:whitespace-nowrap [&>*]:px-4 [&>*]:py-1.5">
                            <td>{row.type}</td>
                            <td>{row.sku}</td>
                            <td>{toTitleCase(row.lineItem)}</td>
                            <td>{moneyFormatter(row.unitPrice)}</td>
                            <td>{row.prorated}</td>
                            <td>{row.quantity}</td>
                            <td>{row.taxable}</td>
                            <td className="text-right">{moneyFormatter(row.total)}</td>
                        </tr>
                    ))}
                    <tr className="text-right [&>*]:px-4 [&>*]:pb-1 [&>*]:pt-6">
                        <td colSpan={7}>Subtotal:</td>
                        <td>{moneyFormatter(subTotal)}</td>
                    </tr>
                    <tr className="text-right [&>*]:px-4 [&>*]:py-1">
                        <td colSpan={7}>{`Tax ${state} (${V1.findTaxRate(state) * 100}%):`}</td>
                        <td>{moneyFormatter(tax)}</td>
                    </tr>
                    <tr className="text-right [&>*]:px-4 [&>*]:py-1">
                        <td colSpan={7}>Total:</td>
                        <td>{moneyFormatter(total)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
export default TotalTable;
