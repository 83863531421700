import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { Button, Dialog } from '@wayste/sour-ui';
import { nullableFields } from '@wayste/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { UIContext } from '../../contexts';
import { CustomerForm2Fields } from '../CustomerForm/CustomerForm2';

interface CustomerUpdateContainerProps {
    customer: Customer.AllianceCustomerTransport;
    open: boolean;
    onBackButtonClick: () => void;
}

const CustomerUpdateContainer = ({ customer, open, onBackButtonClick }: CustomerUpdateContainerProps) => {
    const client = useWaysteClient();
    const { showFlash, godModeActive } = useContext(UIContext);
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<Customer.AllianceCustomerTransport>({
        defaultValues: customer,
    });
    const { handleSubmit } = methods;

    const submitHandler = async (data: Customer.AllianceCustomerTransport) => {
        setIsLoading(true);
        try {
            if (godModeActive) {
                await client
                    .customer()
                    .appAdmin.updateCustomer(
                        customer.id,
                        nullableFields(data, 'credit', 'balanceLimit', 'companyName', 'accountManagerID', 'accountRepID'),
                    );
            } else {
                await client
                    .customer()
                    .adminPortal.update(
                        customer.id,
                        nullableFields(data, 'credit', 'balanceLimit', 'companyName', 'accountManagerID', 'accountRepID'),
                    );
            }
            showFlash('Customer Successfully Updated', 'success');
            onBackButtonClick();
        } catch (error) {
            showFlash('An Error Occurred Updating Customer', 'warning');
            console.warn(error);
            return;
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        methods.reset(customer);
    }, [customer, open]);

    return (
        <Dialog open={open} onClose={onBackButtonClick} styledTitle="Update Customer" className="!max-w-2xl overflow-auto">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submitHandler)}>
                    <CustomerForm2Fields customer={customer} subForm={false} fieldName="" hideContactSubForm />
                    <div className="mt-6 flex justify-end gap-4">
                        <button className="btn-dark-grey-outlined px-10" onClick={onBackButtonClick} disabled={isLoading} type="button">
                            Cancel
                        </button>
                        <Button className="btn-primary -py-1 px-10" type="submit" loading={isLoading}>
                            Update
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default CustomerUpdateContainer;
