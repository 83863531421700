import { useEffect, useState } from 'react';
import { useIsAppAdmin, useWaysteClient } from '@alliance-disposal/client';
import { Customer, Order } from '@alliance-disposal/transport-types';
import { ImageDialog } from '@wayste/sour-components';
import { Dialog } from '@wayste/sour-ui';
import { ArrowsUpDownIcon, PencilIcon, PhotoIcon, WrenchIcon } from '@heroicons/react/24/solid';
import { getOrderCurrentSwitch } from '../../utils';
import AuditLogTable from '../AuditLogTable';
import { ChangeRequestResponse } from '../ChangeRequestDialog';
import DetailsCardWrapper from '../DetailsCardWrapper';
import { MergeCustomerDialog } from '../MergeCustomerDialog/MergeCustomerDialog';
import OrderDetailsLists from '../OrderDetailsLists';
import OrderUpdate from '../OrderUpdate';
import CreateManualSwitchModal from './components/CreateManualSwitchModal';
import ResendEmailModal from './components/ResendEmailModal';

interface Props {
    order: Order.AllianceOrderTransport;
    customer: Customer.AllianceCustomerTransport;
    onUpdate: () => void;
    onSplitSwitch: () => void;
}

const OrderDetailsCard = ({ order, onUpdate, customer, onSplitSwitch }: Props) => {
    const [showOrderImages, setShowOrderImages] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showOrderChanges, setShowOrderChanges] = useState(false);
    const [changeRequestCount, setChangeRequestCount] = useState(0);
    const [changeRequestDialogOpen, setChangeRequestDialogOpen] = useState(false);
    const [manualSwitchModalOpen, setManualSwitchModalOpen] = useState(false);
    const [resendEmailModalOpen, setResendEmailModalOpen] = useState(false);
    const [showMergeCustomerDialog, setShowMergeCustomerDialog] = useState(false);

    const client = useWaysteClient();
    const { isAdmin } = useIsAppAdmin();

    useEffect(() => {
        const sub = client.order().adminPortal.changeRequest.subscription.query({ status: ['PENDING'], orderID: order.id });

        const observe = sub.receive.subscribe({
            next: async (value) => {
                setChangeRequestCount(value.total);
            },
            error: (error) => {
                console.warn('error', error);
            },
        });

        return () => {
            if (sub) {
                observe.unsubscribe();
                sub.unsubscribe();
            }
        };
    }, []);

    return (
        <DetailsCardWrapper
            heading="Order Details"
            buttons={[
                {
                    label: (
                        <>
                            <PencilIcon className="mr-1 size-4" /> Edit Order
                        </>
                    ),
                    onClick: () => setShowEdit(true),
                },
                {
                    label: (
                        <>
                            <PhotoIcon className="mr-1 size-5" /> Order Images
                        </>
                    ),
                    onClick: () => setShowOrderImages(true),
                    badgeAmount: order.images?.length,
                },
                {
                    label: 'View History',
                    onClick: () => setShowOrderChanges(true),
                },
                {
                    label: 'Change Requests',
                    badgeAmount: changeRequestCount,
                    disabled: changeRequestCount === 0,
                    onClick: () => setChangeRequestDialogOpen(true),
                },
                {
                    label: (
                        <>
                            <ArrowsUpDownIcon className="mr-1 size-5" /> Split Switch
                        </>
                    ),
                    onClick: () => onSplitSwitch(),
                    hide: !getOrderCurrentSwitch(order),
                },
                {
                    label: 'Manual Switch',
                    // if theres two+ switches, this order already maxed out switches
                    // if its delivered or below we check if it has prev switch, if do, no manual cuz prev pickup will be overridden.
                    // if its ready pickup or above, we check if fromOrder is itself if not it can be chained.
                    // @ts-expect-error typescript does not like this
                    hide:
                        order?.switches?.length >= 2 ||
                        order.status === 'NEEDS_REVIEW' ||
                        (order.status === 'UNASSIGNED' || order.status === 'ASSIGNED' || order.status === 'DELIVERED'
                            ? order.switches?.find((x) => x.toOrder === order.id)
                            : // @ts-expect-error typescript does not like this
                              order.switches?.find((x) => x.fromOrder === order.id) || order?.status === 'DELIVERED'
                              ? // @ts-expect-error typescript does not like this
                                order?.switches?.toOrder === order.id
                              : // @ts-expect-error typescript does not like this
                                order?.switches?.fromOrder === order.id),
                    onClick: () => setManualSwitchModalOpen(true),
                },
                {
                    label: 'Resend Emails',
                    onClick: () => setResendEmailModalOpen(true),
                },
                {
                    label: (
                        <>
                            <WrenchIcon className="mr-1 size-4" />
                        </>
                    ),
                    onClick: () => setShowMergeCustomerDialog(true),
                    hide: !isAdmin,
                },
            ]}
        >
            <MergeCustomerDialog
                open={showMergeCustomerDialog}
                fromCustomer={customer}
                moveOrder={order}
                onClose={() => {
                    setShowMergeCustomerDialog(false);
                }}
            ></MergeCustomerDialog>
            <ResendEmailModal open={resendEmailModalOpen} selectedOrder={order} handleClose={() => setResendEmailModalOpen(false)} />
            <CreateManualSwitchModal
                open={manualSwitchModalOpen}
                selectedOrder={order}
                handleClose={() => setManualSwitchModalOpen(false)}
            />
            <ChangeRequestResponse
                open={changeRequestDialogOpen}
                onClose={() => setChangeRequestDialogOpen(false)}
                orderID={order.id}
                order={order}
                customer={customer}
            />
            <OrderDetailsLists order={order} />
            {showOrderImages && (
                <ImageDialog
                    open={showOrderImages}
                    entityID={order.id}
                    imageReferences={order.images}
                    onClose={() => setShowOrderImages(false)}
                    onAddImages={async (images) => {
                        for (const image of images) {
                            await client.order().adminPortal.image.create(order.id, image);
                        }
                    }}
                    onUpdateImage={async (image) => {
                        await client.order().adminPortal.image.update(order.id, image);
                    }}
                    onDeleteImage={async (image) => {
                        await client.order().adminPortal.image.delete(order.id, image);
                    }}
                />
            )}

            <OrderUpdate
                order={order}
                customer={customer}
                open={showEdit}
                onBackButtonClick={() => {
                    setShowEdit(false);
                    onUpdate();
                }}
            />
            <Dialog
                styledTitle="Order Change Log"
                open={showOrderChanges}
                className="max-w-screen-2xl"
                onClose={() => setShowOrderChanges(false)}
                showX={true}
            >
                {showOrderChanges ? <AuditLogTable entityID={order.id} entityType="AllianceOrder" /> : null}
            </Dialog>
        </DetailsCardWrapper>
    );
};

OrderDetailsCard.defaultProps = {
    onUpdate: () => undefined,
};

export default OrderDetailsCard;
