import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Pricing } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';
import { round } from '@wayste/utils';
import { UIContext } from '../../contexts';
import MaterialPricingForm, { type FormProps as MaterialPricingFormProps } from '../MaterialPricingForm';

export interface MaterialPricingDialogProps {
    open: boolean;
    zone: Pricing.PricingTransport;
    material: Pricing.PricingDataTransport | undefined;
    onBackButtonClick: (status?: 'success' | 'error') => void;
    isHauler?: boolean;
    isCopying?: boolean;
}
const MaterialPricingDialog = ({
    open,
    material,
    zone,
    onBackButtonClick,
    isHauler = false,
    isCopying = false,
}: MaterialPricingDialogProps) => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (data: MaterialPricingFormProps) => {
        setIsLoading(true);
        try {
            const promises = data.material.map(async (materialItem) => {
                const payload: Pricing.SGMutateMaterial = {
                    effectiveDate: new Date().toISOString(),
                    expirationDate: undefined,
                    details: {
                        id: material?.id || undefined,
                        allowOnlineCheckout: data.allowOnlineCheckout,
                        allowForLowerTonLimit: data.allowForLowerTonLimit,
                        doesNotService: data.doesNotService,
                        lastUpdatedAt: `${new Date()}`,
                        haulerApproved: undefined,
                        sourgumApproved: true,
                        effectiveDate: undefined,
                        expirationDate: undefined,
                        material: materialItem,
                        tax: data.tax,
                        type: data.type,
                        sizes: data.sizes.map((size) => {
                            return {
                                size: size.size,
                                tonLimit: +size.tonLimit,
                                haul: Math.round(round(size.haul ? +size.haul : 0)),
                                dump: Math.round(round(size.dump ? +size.dump : 0)),
                                over: Math.round(round(size.over ? +size.over : 0)),
                            };
                        }),
                    },
                };

                return client.pricing().adminPortal.material.update(zone.id, payload);
            });

            await Promise.all(promises);
            showFlash(
                `Material${data.material.length > 1 ? 's' : ''} ${material ? (isCopying ? 'Copied' : 'Updated') : 'Created'} Successfully`,
                'success',
            );
            onBackButtonClick('success');
        } catch (error) {
            console.warn('error: ', error);
            showFlash('An error occurred, get an AAP dev', 'warning');
            onBackButtonClick('error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog
            open={open}
            className="max-w-4xl"
            styledTitle={`${material ? (isCopying ? 'Copy' : 'Update') : 'Create'} Material Pricing`}
            onClose={() => onBackButtonClick()}
        >
            {zone ? (
                <MaterialPricingForm
                    zone={zone}
                    material={material as Pricing.PricingDataTransport}
                    onSubmit={handleSubmit}
                    onCancel={() => onBackButtonClick()}
                    isLoading={isLoading}
                    isHauler={isHauler}
                    isCopying={isCopying}
                />
            ) : (
                <div />
            )}
        </Dialog>
    );
};

export default MaterialPricingDialog;
