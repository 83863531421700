import { useState } from 'react';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button, TextField } from '@wayste/sour-ui';
import { DocumentDuplicateIcon, PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Controller, UseFormReturn, get, useFieldArray } from 'react-hook-form';
import { UniversalServiceFormData } from '../UniversalServiceCreate/UniversalServiceCreate';
import SubscriptionServiceOrderPricingSubForm from './SubscriptionServiceOrderPricingSubForm';
import SubscriptionServiceOrderSubForm from './SubscriptionServiceOrderSubForm';
import { defaultServiceOrderWithSubscriptionDetails } from './site-service-defaults';

interface SubscriptionServiceOrderAddSubFormProps {
    methods: UseFormReturn<UniversalServiceFormData, unknown>;
}

const SubscriptionServiceOrderAddSubForm = ({ methods }: SubscriptionServiceOrderAddSubFormProps) => {
    const { control } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'serviceGrouping.serviceOrders',
    });

    return (
        <>
            {fields.map((field, index) => (
                <div key={field.id}>
                    <SubscriptionServiceOrder index={index} methods={methods} />
                </div>
            ))}
            <div className="flex flex-row items-center justify-center">
                <hr className="w-full" />
                <div className="flex flex-row items-center justify-center space-x-2 px-2">
                    <Button
                        className="btn-primary-text-only whitespace-nowrap"
                        startIcon={<PlusCircleIcon />}
                        onClick={() => append(defaultServiceOrderWithSubscriptionDetails)}
                    >
                        Add Service Order
                    </Button>
                    |
                    <Button className="btn-secondary-text-only whitespace-nowrap" startIcon={<DocumentDuplicateIcon />} disabled>
                        Copy Service Order Above
                    </Button>
                    |
                    {fields.length > 1 && (
                        <Button
                            className="btn-error-text-only whitespace-nowrap"
                            startIcon={<XMarkIcon />}
                            onClick={() => remove(fields.length - 1)}
                        >
                            Remove Service Order
                        </Button>
                    )}
                </div>
                <hr className="w-full" />
            </div>
        </>
    );
};

export default SubscriptionServiceOrderAddSubForm;
interface SubscriptionServiceOrderProps {
    methods: UseFormReturn<UniversalServiceFormData, unknown>;
    index: number;
}

const SubscriptionServiceOrder = ({ methods, index }: SubscriptionServiceOrderProps) => {
    const [selectedServiceTypeFamily, setSelectedServiceTypeFamily] = useState<UniversalService.ServiceTypeFamily | undefined>(undefined);
    const {
        control,
        formState: { errors },
    } = methods;
    return (
        <div>
            <div className="text-l pb-2">Service Details ({index + 1})</div>
            <div className={`space-y-6 rounded border px-2 py-4 ${index % 2 ? 'bg-slate-50 bg-opacity-20' : 'bg-white'}`}>
                <SubscriptionServiceOrderSubForm
                    mode="create"
                    index={index}
                    selectedServiceTypeFamily={selectedServiceTypeFamily}
                    setSelectedServiceTypeFamily={setSelectedServiceTypeFamily}
                />
                <div className="text-l mb-6 border-b border-gray-300 pb-2">Pricing Info</div>
                {selectedServiceTypeFamily ? (
                    <>
                        <SubscriptionServiceOrderPricingSubForm
                            methods={methods}
                            index={index}
                            selectedServiceTypeFamily={selectedServiceTypeFamily}
                        />
                        <div className="w-1/2 pr-1 ">
                            <Controller
                                control={control}
                                name={`quantity.${index}`}
                                defaultValue={1}
                                render={({ field }) => (
                                    <TextField
                                        type={'number'}
                                        label="Quantity of this sku with these details"
                                        required
                                        error={get(errors, `quantity.${index}`)}
                                        inputProps={{
                                            ...field,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </>
                ) : (
                    <div className="italic text-gray-600">Select a Service Type First</div>
                )}
            </div>
        </div>
    );
};
