import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { prepareDateStringsForForm, sanitizeFromPayload } from '@wayste/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { UIContext } from '../../../contexts';
import { UniversalServiceFormData } from '../UniversalServiceCreate/UniversalServiceCreate';
import SubscriptionServiceOrderSubForm from '../components/SubscriptionServiceOrderSubForm';

const SubscriptionServiceOrderUpdate = () => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const history = useHistory();
    const params: { serviceGroupingId: string; serviceOrderId: string } = useParams();
    const methods = useForm<UniversalServiceFormData>();
    const { handleSubmit, reset } = methods;
    const [serviceGrouping, setServiceGrouping] = useState<UniversalService.ServiceGrouping | undefined>(undefined);
    const [serviceOrder, setServiceOrder] = useState<UniversalService.ServiceOrder | undefined>(undefined);
    const [index, setIndex] = useState<number>(0);

    const fetchServiceGrouping = async () => {
        try {
            const serviceGrouping = await client.universalService().serviceGrouping.fetch(params.serviceGroupingId);
            setServiceGrouping(serviceGrouping);
            serviceGrouping.serviceOrders.map((order, index) => {
                if (order.id === params.serviceOrderId) {
                    setIndex(index);
                    setServiceOrder(prepareDateStringsForForm(order, 'startDate', 'endDate', 'deliveryDate', 'removalDate'));
                }
            });
        } catch (error) {
            console.warn('fetchServiceOrder for ID: ', error);
            return { status: 'error', data: null };
        }
    };

    const onSubmit = async (data: UniversalServiceFormData) => {
        try {
            // UPDATE SERVICE GROUPING
            const serviceGroupingUpdatePayload: UniversalService.ServiceGroupingUpdate = {
                ...data.serviceGrouping,
            };

            await client.universalService().serviceGrouping.update(params.serviceGroupingId, serviceGroupingUpdatePayload);

            // UPDATE SERVICE ORDER
            const serviceOrderUpdatePayload: UniversalService.ServiceOrderUpdate = {
                ...data.serviceGrouping.serviceOrders[index],
                serviceDays: serviceOrder?.serviceDays.map((day) => Number(day)),
                vendorID: serviceOrder?.vendorID || undefined,
            };

            if (serviceOrderUpdatePayload.endDate === '') {
                serviceOrderUpdatePayload.endDate = undefined;
            }

            if (
                typeof serviceOrderUpdatePayload.subscriptionDetails !== 'string' &&
                serviceOrderUpdatePayload.subscriptionDetails?.endDate === ''
            ) {
                serviceOrderUpdatePayload.subscriptionDetails.endDate = undefined;
            }

            await client
                .universalService()
                .serviceGrouping.serviceOrder.update(
                    params.serviceGroupingId,
                    params.serviceOrderId,
                    sanitizeFromPayload(serviceOrderUpdatePayload, 'accountManagerID'),
                );

            showFlash('Service Order Successfully Updated', 'success');
            history.goBack();
        } catch (error) {
            console.error('Service Order Update error ', error);
            showFlash('Error Updating Service Order', 'warning');
        }
    };

    useEffect(() => {
        fetchServiceGrouping();
    }, []);

    useEffect(() => {
        if (!serviceGrouping) return;
        reset({
            serviceGrouping: serviceGrouping,
        });
    }, [serviceGrouping]);

    return (
        <div className="flex max-w-[960px] flex-1 flex-col p-16">
            <FormProvider {...methods}>
                <form>
                    <div className="mb-6 pb-2 text-2xl">Update Service Order</div>
                    <div className="space-y-6">
                        <SubscriptionServiceOrderSubForm
                            index={index}
                            mode="update"
                            selectedServiceTypeFamily={serviceOrder?.serviceType.family}
                        />
                    </div>
                    <div className="float-right mt-2">
                        <Button className="btn-dark-grey-outlined mr-4" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                        <Button className="btn-primary" onClick={handleSubmit(onSubmit)}>
                            Update
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};
export default SubscriptionServiceOrderUpdate;
