import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button } from '@wayste/sour-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { onCreateOrderFromLead } from '../../pages/leads/utils/lead-to-order';
import { routes } from '../../utils';
import ContactedSubForm from '../LeadForms/ContactedSubForm';
import MarketingForm from '../LeadForms/MarketingSubForm';
import ROLeadSubForm from '../LeadForms/ROLeadSubForm';
import ROQuoteSubForm from '../LeadForms/ROQuoteSubForm';
import {
    MarketingSubFormType,
    ROLeadFormType,
    ROQuoteFormType,
    prepareMarketingSubForm,
    prepareROLeadSubForm,
    prepareROQuoteSubForm,
} from '../LeadForms/utils/prepare-lead-form';

type ROLeadCreateType = ROLeadFormType &
    ROQuoteFormType &
    MarketingSubFormType & { medium: string; date: string; requestedSize: { size: number; type: string } };

const ROLeadCreate = ({ startingValues }: { startingValues?: { phoneNumber?: string } }) => {
    const client = useWaysteClient();
    const history = useHistory();
    const { setShowToast } = useSourContext();
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<ROLeadCreateType>({
        mode: 'all',
        defaultValues: {
            medium: 'phone',
            date: new Date().toISOString(),
            ...prepareROLeadSubForm(),
            ...prepareROQuoteSubForm(),
            ...prepareMarketingSubForm(),
            phoneNumber: startingValues?.phoneNumber || '',
        },
    });

    const {
        handleSubmit,
        formState: { isDirty, isValid },
        getValues,
        watch,
    } = methods;
    const watchSize0 = watch('quotes.0.size');
    const watchRequestedSize = watch('requestedSize');

    const submitForm = async (data: ROLeadCreateType) => {
        setIsLoading(true);
        const leadSubmitData: Customer.AllianceLeadCreateTransport = {
            ...data,
            type: 'rollOff',
            status: 'open',
            needsAttention: false,
            customerType: data.customerType || 'unknown',
            requestedSize: data.quotes[0]?.size
                ? {
                      size: Number(data.quotes[0].size),
                      type: '',
                  }
                : watchRequestedSize,
        };

        const quotes: Customer.AllianceLeadQuoteCreateTransport[] = data?.quotes ? [...data.quotes] : [];

        try {
            const response = await client.customer().adminPortal.leads.create(leadSubmitData);

            const leadID = response?.id;
            for (const quote of quotes) {
                try {
                    await client.customer().adminPortal.leads.quote.create(leadID, quote);
                } catch (error) {
                    setShowToast({
                        severity: 'error',
                        message: 'Quote Did Not Save',
                    });
                    if (leadID) history.push(routes.leads.details(leadID));
                    throw error;
                }
            }

            try {
                await client.customer().adminPortal.leads.contactEvent.create(leadID, {
                    medium: data.medium,
                    date: data.date,
                });
            } catch (error) {
                setShowToast({
                    severity: 'error',
                    message: 'Contact Event Did Not Save',
                });
                if (leadID) history.push(routes.leads.details(leadID));
                throw error;
            }

            if (data.notes) {
                await client.internalTicket().adminPortal.create({
                    entityType: 'sourgum-lead',
                    entityID: leadID,
                    note: data.notes,
                    tags: [],
                });
            }

            setShowToast({
                severity: 'success',
                message: 'Lead Successfully Created',
            });

            history.push(routes.leads.details(leadID));
        } catch (error) {
            setShowToast({
                severity: 'error',
                message: 'An Error Occurred',
            });
            console.warn('error creating lead: ', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOnAddOrder = (quote: Customer.AllianceLeadQuoteCreateTransport) => {
        const lead: Partial<Customer.AllianceLeadCreateTransport> = getValues();
        const data = onCreateOrderFromLead(lead, quote);
        history.push(routes.orders.new, data);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submitForm)} className="flex flex-col gap-4">
                <ROQuoteSubForm onAddOrder={handleOnAddOrder} />
                <div className="shadow-dark rounded bg-white p-3">
                    <h6 className="mb-3 text-xl">Lead Details</h6>
                    <ROLeadSubForm />
                </div>
                <div className="shadow-dark rounded bg-white p-3">
                    <h6 className="mb-3 text-xl">Contact Details</h6>
                    <ContactedSubForm />
                </div>
                <div className="shadow-dark rounded bg-white p-3">
                    <h6 className="mb-3 text-xl">Marketing Details</h6>
                    <MarketingForm />
                </div>
                <div className="flex justify-end gap-4">
                    <Button className="btn-dark-grey-outlined" disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        className="btn-primary"
                        loading={isLoading}
                        disabled={!isDirty || !isValid || (!watchSize0 && !watchRequestedSize?.size)}
                    >
                        Save
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default ROLeadCreate;
