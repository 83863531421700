import React, { useState } from 'react';
import { Invoice } from '@alliance-disposal/transport-types';
import { CurrencyTextField, Dialog, Select, SelectOption } from '@wayste/sour-ui';

interface ExtraItemDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (item: Invoice.LineItemInputTransport) => void;
}

export const ExtraItemDialog = ({ open, onClose, onSave }: ExtraItemDialogProps) => {
    const [discount, setDiscount] = useState('');
    const [amount, setAmount] = useState<number>(0);

    const discountOptions = [
        { name: 'Discount', amount: 2500 },
        { name: 'New Customer Promotion', amount: 2500 },
        { name: 'Military Discount', amount: 2500 },
        { name: 'Loyalty Discount', amount: 2500 },
        { name: 'New Customer - K1C29Y', amount: 5000 },
        { name: 'New Customer - 2SHJSR', amount: 2500 },
    ];

    const handleSave = () => {
        onSave({
            itemName: discount,
            description: '',
            quantity: 1,
            taxable: false,
            totalPrice: -Math.abs(amount),
        });
    };

    const handleDiscountChange = (value: string) => {
        if (!value) return;

        const selectedOption = discountOptions.find((item) => item.name === value);

        if (selectedOption) {
            const newAmount = selectedOption.amount;
            setDiscount(value);
            setAmount(newAmount);
        }
    };

    return (
        <Dialog open={open} className="max-w-xl" onClose={onClose} styledTitle="Apply Discount">
            <div className="grid grid-cols-1 gap-4 pt-4 md:grid-cols-2">
                <Select label="Discount item" required value={discount} onSelect={(value) => handleDiscountChange(value)}>
                    {discountOptions.map((item) => (
                        <SelectOption key={`discount-${item.name}`} value={item.name}>
                            {item.name}
                        </SelectOption>
                    ))}
                </Select>
                <CurrencyTextField
                    required
                    label="Discount amount"
                    value={amount}
                    onChange={(value) => setAmount(Number(value))}
                    useCents
                />
            </div>
            <div className="flex justify-end gap-4 pt-4">
                <button className="btn-dark-grey-outlined" onClick={onClose} type="button">
                    cancel
                </button>
                <button className="btn-primary" onClick={handleSave} disabled={!discount || !amount} type="button">
                    save
                </button>
            </div>
        </Dialog>
    );
};
