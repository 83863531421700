import { useEffect, useMemo, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Invoice, Notification, UniversalService } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Checkbox, Dialog, Loading, TextField, Textarea, Toggle } from '@wayste/sour-ui';
import { blobToBase64, fileToBase64, formatEmailDestination } from '@wayste/utils';
import { ChevronLeftIcon, ChevronRightIcon, PaperClipIcon, TrashIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { getCustomerCCAddresses, getCustomerToAndCCEmails } from '../../../utils/email-utils';

interface Props {
    order: UniversalService.ServiceOrder;
    customer: Customer.AllianceCustomerTransport;
    open: boolean;
    onCancel: () => void;
    receivable: Invoice.ReceivableTransport;
    onSend: (isInvoice: boolean) => void;
}

const InvoiceSend = ({ order, customer, onSend, open, onCancel, receivable }: Props) => {
    const invoiceContainer = document.getElementById('container');
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [containerHeight, setContainerHeight] = useState(0);
    const [images, setImages] = useState<File[]>([]);
    const [customerEmail, setCustomerEmail] = useState<string>();
    const [pdf, setPdf] = useState<Blob>();
    const [editEmail, setEditEmail] = useState(false);
    // const [askReview, setAskReview] = useState(false);
    const [consolidate, setConsolidate] = useState(true);
    const [emailText, setEmailText] = useState('');
    const [isInvoice, setIsInvoice] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [activeImageStep, setActiveImageStep] = useState(0);
    const [isValid, setIsValid] = useState(false);
    const [serviceGroupingNumber, setServiceGroupingNumber] = useState('');

    useEffect(() => {
        if (customer) {
            const contactEmail = getCustomerToAndCCEmails('billing', customer);
            setCustomerEmail(contactEmail.to);
        }
    }, [customer]);

    useEffect(() => {
        if (invoiceContainer) setContainerHeight(invoiceContainer.clientHeight);
    }, [invoiceContainer]);

    useEffect(() => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValid(emailPattern.test(customerEmail || ''));
    }, [customerEmail]);

    useEffect(() => {
        if (receivable) {
            setIsInvoice(!receivable.invoiceDetails.paidInFull);

            (async () => {
                const response = await client.invoice().adminPortal.pdf.fetch(receivable?.id || ' ', consolidate);
                const blob = new Blob([response], { type: 'application/pdf' });
                setPdf(blob);
            })();
        }
    }, [receivable, consolidate]);

    useEffect(() => {
        if (order) {
            setServiceGroupingNumber(order.fullOrderNumber.split('-')[0]);
        }
    }, [order]);

    const file = useMemo(() => {
        if (pdf) {
            return URL.createObjectURL(pdf);
        }
    }, [pdf]);

    // const verifyCustomerExperience = async () => {
    //   setTimeout(async () => {
    //     if (!isInvoice && customer.overallExperience === 'Positive' && !customer.reviewed) {
    //       const confirmed = await getConfirmation({
    //         title: 'Verify User Experience',
    //         message:
    //           'Are you sure the customer had a good experience? If so click CONFIRM otherwise click CANCEL and update the customer experience via Update Customer Details.',
    //       });
    //       if (!confirmed) onCancel();
    //       setAskReview(true);
    //     }
    //   }, 1000);
    // };

    // useEffect(() => {
    //   verifyCustomerExperience();
    // }, [isInvoice]);

    const handleNextImage = () => {
        setActiveImageStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBackImage = () => {
        setActiveImageStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleImageUpload = (file: File) => {
        const imagesCopy = [...images, file];
        setImages(imagesCopy);
    };

    const handleImageDelete = (index: number) => {
        const imagesCopy = [...images];
        setImages(imagesCopy.filter((item, i) => i !== index));
        setActiveImageStep(0);
    };

    const prepareImage = async (image: File) => {
        try {
            return await fileToBase64(image);
        } catch (error) {
            console.error('There was an error compressing the file', error);
            setShowToast({
                severity: 'warning',
                message: 'There was an error compressing the file',
            });
        }
    };

    const prepareAttachments = async () => {
        if (!pdf) {
            setShowToast({
                severity: 'warning',
                message: 'Something went wrong loading the PDF. Please try again.',
            });
            return;
        }
        const receiptInvoiceBlob = ((await blobToBase64(pdf)) as string).split(',')[1];

        const attachments = [
            {
                content: receiptInvoiceBlob,
                type: 'application/pdf',
                filename: `${!receivable.invoiceDetails.paidInFull ? 'Invoice' : 'Receipt'} ${`order.orderNumber`} - ${
                    receivable.invoiceDetails.invoiceNumber
                }.pdf`,
                disposition: 'attachment',
            },
        ];
        if (images.length > 0) {
            for (const image of images) {
                const attachment = await prepareImage(image);
                if (attachment) {
                    attachments.push({
                        content: attachment,
                        type: image.type,
                        filename: image.name,
                        disposition: 'attachment',
                    });
                } else {
                    setShowToast({
                        severity: 'warning',
                        message: 'Something went wrong sending images. Please try again.',
                    });
                    return;
                }
            }
        }

        return attachments;
    };

    const generatePaymentLink = async () => {
        if (!receivable.invoiceDetails.paidInFull) {
            try {
                const response = await client.invoice().adminPortal.receivable.customerAccessToken.create({
                    receivableID: receivable?.id,
                });
                return response.key;
            } catch (error) {
                console.error('InvoiceSend generatePaymentLink Error: ', error);
                setShowToast({
                    severity: 'warning',
                    message: 'An error occurred generating the payment link',
                });
            }
        }
        return null;
    };

    const handleSend = async () => {
        if (!customerEmail) {
            alert('Email not sent, please enter a valid email address');
            return;
        }
        let updatedReceivable = receivable;
        setIsLoading(true);

        if (!receivable.invoiceDetails.issueDate) {
            try {
                const issueDateResponse = await client.invoice().adminPortal.receivable.update(receivable.id, {
                    invoiceDetails: {
                        issueDate: new Date().toISOString(),
                    },
                });
                updatedReceivable = issueDateResponse;
            } catch (error) {
                console.warn('InvoiceSend Error: ', error);
                setShowToast({
                    severity: 'warning',
                    message: 'An error occurred issuing the invoice. Please try again.',
                });
                return;
            }
        }

        let body: Notification.SendGrid.ReceiptGeneric | Notification.SendGrid.InvoiceCommercial | Notification.SendGrid.InvoiceSiteService;
        let topic = '';

        const attachments = await prepareAttachments();

        const contactEmail = getCustomerToAndCCEmails('billing', customer);

        if (isInvoice) {
            const payment_link = await generatePaymentLink();
            // Check if service family is commercial
            topic = 'invoice-site-service';
            body = {
                first_name: contactEmail.toContact?.firstName || '',
                due_date: updatedReceivable.invoiceDetails.dueDate
                    ? format(new Date(updatedReceivable.invoiceDetails.dueDate), 'EEEE MM/dd/yyyy')
                    : '',
                invoice_number: `${serviceGroupingNumber} - ${receivable.invoiceDetails.invoiceNumber}`,
                payment_link: payment_link || '',
                allow_ach: customer.defaultPaymentSettings.allowedPaymentMethod?.includes('ach') || false,
                allow_check: customer.defaultPaymentSettings.allowedPaymentMethod?.includes('check') || false,
                custom_message: emailText || '',
            };
        } else {
            topic = 'generic-receipt';
            body = {
                first_name: contactEmail.toContact?.firstName || '',
                invoice_number: `${serviceGroupingNumber} - ${receivable.invoiceDetails.invoiceNumber}`,
                custom_message: emailText || '',
            };
        }

        const destination = formatEmailDestination(
            customerEmail,
            contactEmail.toContact?.firstName || '',
            getCustomerCCAddresses(customerEmail, customer, order.serviceLocation.address).billingCCAddresses.filter((email) => {
                return email !== customerEmail;
            }),
        );

        try {
            await client.notification().adminPortal.createInstantNotification({
                handler: 'sendgrid',
                topic,
                destination,
                body: JSON.stringify(body),
                directAttachments: attachments,
            });
            onSend(isInvoice);
        } catch (error) {
            alert('Something went wrong sending the email');
            throw new Error('Something went wrong sending the email');
        } finally {
            setIsLoading(false);
        }
    };

    const loadPdf = () => {
        const file = images[activeImageStep];
        const fileUrl = URL.createObjectURL(file);
        return fileUrl;
    };

    return (
        <Dialog open={open} onClose={onCancel} styledTitle="Send Invoice" fullScreen dialogBodyClassName="flex-1">
            <div
                style={{
                    display: 'flex',
                    height: 'calc(100% - 96px)',
                    justifyContent: 'space-between',
                }}
                id="container"
            >
                <div
                    style={{
                        paddingRight: 10,
                        borderRight: 'solid 1px #D8D8D8',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            fontSize: 16,
                            color: 'rgba(0, 0, 0, 0.68)',
                            marginBottom: 17,
                        }}
                    >
                        Email Details
                    </div>
                    <div className="mb-4 w-60">
                        <TextField
                            label="Email to"
                            inputProps={{ value: customerEmail, onChange: (e) => setCustomerEmail(e.target.value) }}
                        />
                    </div>
                    {/* <Checkbox
            label="Include review link in email"
            inputProps={{
              checked: askReview,
              onChange: () => setAskReview(!askReview),
            }}
          /> */}
                    <Checkbox
                        label="Consolidate Line Items"
                        inputProps={{
                            checked: consolidate,
                            onChange: () => setConsolidate(!consolidate),
                        }}
                    />
                    <div className="mt-4">
                        <Toggle label="Edit email" value={editEmail} onChange={setEditEmail} />
                    </div>
                    <div style={{ maxWidth: 240 }}>
                        {isInvoice ? (
                            <span>
                                We hope this message finds you well. As part of our commitment to providing seamless and sustainable
                                services, please see the attached invoice for your services {serviceGroupingNumber} -{' '}
                                {receivable.invoiceDetails.invoiceNumber}.
                            </span>
                        ) : (
                            <span>
                                We hope this message finds you well. Thank you for your recent payment for invoice {serviceGroupingNumber} -{' '}
                                {receivable.invoiceDetails.invoiceNumber}. Find it attached to this email, or log in to your customer
                                dashboard to access it at any time.
                            </span>
                        )}
                    </div>
                    <div className="mb-0 flex-1">
                        <Textarea
                            label="Optional custom message"
                            height="h-full"
                            inputProps={{
                                disabled: !editEmail,
                                value: emailText,
                                onChange: (e) => setEmailText(e.target.value),
                            }}
                        />
                    </div>
                    <div style={{ maxWidth: 240 }}>
                        {isInvoice ? (
                            <span>Payment Instructions...</span>
                        ) : (
                            <span>
                                Did you know? Your order planted one tree in a forest in need. Thanks for your help in making the world a
                                greener, cleaner place!
                            </span>
                        )}
                    </div>
                </div>

                <div
                    className="text-wayste-corral-400"
                    style={{
                        overflow: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 0.75,
                        position: 'relative',
                    }}
                >
                    {images.length > 0 ? (
                        <div style={{ position: 'relative' }}>
                            <TrashIcon
                                className="text-delete absolute right-5 top-10 size-6 cursor-pointer"
                                onClick={() => handleImageDelete(activeImageStep)}
                            />
                            {images[activeImageStep].type === 'application/pdf' ? (
                                <object
                                    data={loadPdf()}
                                    style={{
                                        height: containerHeight * 0.85,
                                        width: containerHeight * 0.77273,
                                        border: 'solid 1px #D8D8D8',
                                    }}
                                    type="application/pdf"
                                ></object>
                            ) : (
                                <img
                                    src={window.URL.createObjectURL(images[activeImageStep])}
                                    alt="Dump ticket"
                                    style={{
                                        height: containerHeight * 0.85,
                                        width: containerHeight * 0.77273,
                                        border: 'solid 1px #D8D8D8',
                                    }}
                                />
                            )}
                            <div className="flex items-center justify-between py-2">
                                <button
                                    className={`btn-primary-text-only px-2 py-1 ${
                                        activeImageStep === 0 ? 'cursor-not-allowed opacity-50' : ''
                                    }`}
                                    onClick={handleBackImage}
                                    disabled={activeImageStep === 0}
                                >
                                    <ChevronLeftIcon className="size-5" />
                                    Back
                                </button>
                                <p className="text-sm font-medium text-gray-500">{`${activeImageStep + 1} / ${images.length}`}</p>
                                <button
                                    className={`btn-primary-text-only px-2 py-1 ${
                                        activeImageStep === images.length - 1 ? 'cursor-not-allowed opacity-50' : ''
                                    }`}
                                    onClick={handleNextImage}
                                    disabled={activeImageStep === images.length - 1}
                                >
                                    Next
                                    <ChevronRightIcon className="size-5" />
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="relative flex flex-col items-center text-center">
                            <PaperClipIcon className="size-10" />
                            <div>Attach Dump Ticket</div>
                            <input
                                className="blankFileInput"
                                type="file"
                                onChange={(event) => {
                                    if (event?.target?.files?.[0]) {
                                        handleImageUpload(event?.target?.files?.[0]);
                                    } else {
                                        setShowToast({
                                            severity: 'warning',
                                            message: 'Please select a file to upload',
                                        });
                                    }
                                }}
                            />
                        </div>
                    )}
                </div>
                <div
                    style={{
                        height: '100%',
                        overflow: 'auto',
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {file ? (
                        <object data={file} type="application/pdf" width="100%" height="100%">
                            <param name="width" value="100%" />

                            <p>
                                It appears your Web browser is not configured to display PDF files. No worries, just
                                <a className="text-primary-400" href={file}>
                                    {' '}
                                    click here{' '}
                                </a>{' '}
                                to download the PDF file.
                            </p>
                        </object>
                    ) : (
                        <div className="flex size-full flex-row items-center justify-center">
                            <Loading />
                        </div>
                    )}
                </div>
            </div>
            <div className="flex gap-4 pt-6">
                <button type="button" className="btn-dark-grey-outlined" onClick={onCancel} disabled={isLoading}>
                    Cancel
                </button>
                <button type="button" className="btn-primary" disabled={isLoading || !isValid || !file} onClick={() => handleSend()}>
                    Email Invoice
                    {isLoading && <Loading className="text-sourgum-greyblue-900 ml-1" size="h-4 w-4" />}
                </button>
                {images.length > 0 && (
                    <button className="btn-secondary-white relative" disabled={isLoading} type="button">
                        Upload Another Image
                        <input
                            className="blankFileInput"
                            type="file"
                            onChange={(event) => {
                                if (event?.target?.files?.[0]) {
                                    handleImageUpload(event?.target?.files?.[0]);
                                } else {
                                    setShowToast({
                                        severity: 'warning',
                                        message: 'Please select a file to upload',
                                    });
                                }
                            }}
                        />
                    </button>
                )}
            </div>
        </Dialog>
    );
};
export default InvoiceSend;
