import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Dialog, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { leadReasonsLost, leadReasonsLostEnums, leadStatusTypes, leadStatusTypesEnums } from '../../utils';
import ButtonSelect from '../ButtonSelect';

const StatusChangeButton = ({ lead }: { lead: Customer.AllianceLeadTransport }) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState(leadReasonsLostEnums.noResponse);
    const [note, setNote] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleLeadLostSave = async (reason: string, note: string) => {
        setIsLoading(true);
        try {
            await client.customer().adminPortal.leads.update(lead?.id, { reasonLost: reason, reasonLostNote: note });
            setShowToast({
                severity: 'success',
                message: 'Lead Successfully Updated',
            });
            setOpen(false);
        } catch (error) {
            console.warn(error);
            setShowToast({
                severity: 'error',
                message: 'Error Updating Lead',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleLeadStatusChange = async (id: string, status: string) => {
        try {
            const response = await client.customer().adminPortal.leads.update(id, { status });
            if (response.status === leadStatusTypesEnums.lost) setOpen(true);
            setShowToast({
                severity: 'success',
                message: 'Lead Successfully Updated',
            });
        } catch (error) {
            setShowToast({
                severity: 'error',
                message: 'Error Updating Lead',
            });
        }
    };

    return (
        <>
            <ButtonSelect
                menuItems={Object.keys(leadStatusTypes).map((key) => ({
                    label: leadStatusTypes[key as keyof typeof leadStatusTypes],
                    value: key,
                }))}
                value={lead.status}
                onSelect={(value) => handleLeadStatusChange(lead.id, value)}
            />
            {/* Lead Lost Dialog */}
            <Dialog open={open} onClose={() => setOpen(false)}>
                <div className="flex flex-col gap-4">
                    <h6 className="text-xl">Reason We Lost Them</h6>
                    <Select label="Reason we lost lead" value={reason} onSelect={(value) => setReason(value)}>
                        {Object.entries(leadReasonsLost).map((item) => (
                            <SelectOption key={`reason-${item[0]}`} value={item[0]}>
                                {item[1]}
                            </SelectOption>
                        ))}
                    </Select>
                    <TextField
                        label="Notes"
                        inputProps={{
                            value: note,
                            onChange: (event) => setNote(event.target.value),
                        }}
                    />
                </div>
                <div className="mt-6 flex items-center justify-end gap-4">
                    <Button className="btn-dark-grey-outlined" onClick={() => setOpen(false)} disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button className="btn-primary ml-2" onClick={() => handleLeadLostSave(reason, note)} loading={isLoading}>
                        Save
                    </Button>
                </div>
            </Dialog>
        </>
    );
};

export default StatusChangeButton;
