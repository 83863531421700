import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Material, Notification, Order, Profile } from '@alliance-disposal/transport-types';
import { Pricing } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';
import { calculateOrderTotal, formatEmailDestination, formatServiceAddress, getDateFormat, round } from '@wayste/utils';
import { addDays, format, isSameDay, isTomorrow } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { UIContext } from '../../contexts';
import {
    createOrderConfirmationEmailSendGrid,
    createSourgumPricingSnapshot,
    getCustomerToAndCCEmails,
    getOrderPricing,
    paymentMethodsEnums,
    paymentTermsEnums,
    routes,
} from '../../utils';
import Loading from '../Loading';
import { OrderCreateFormProps, initialValues } from '../OrderCreate/order-create-form-props';
import OrderReview from './OrderReview';

const OrderReviewContainer = () => {
    const { id }: { id: string } = useParams();
    const { showFlash } = useContext(UIContext);
    const client = useWaysteClient();
    const userProfile: Profile.ProfileTransport = client.user().get();
    const history = useHistory();
    const [zonePricing, setZonePricing] = useState<Pricing.PricingTransport | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [existingCustomer, setExistingCustomer] = useState<Customer.AllianceCustomerTransport | null>(null);
    const [currentOrder, setCurrentOrder] = useState<Order.AllianceOrderTransport | null>(null);

    const methods = useForm<OrderCreateFormProps>({
        mode: 'all',
        defaultValues: initialValues,
    });
    const { handleSubmit, watch, reset, setValue } = methods;
    const watchPrice = watch('pricingSubForm.price');
    const watchOtherLineItems = watch('pricingSubForm.otherLineItems');

    const getOrdersWithInvoice = async (order: Order.AllianceOrderTransport) => {
        const receivables = await client.invoice().adminPortal.receivable.query({
            orderID: order.id,
        });

        return calculateOrderTotal(receivables.results);
    };

    const prepareForm = async (customer: Customer.AllianceCustomerTransport, order: Order.AllianceOrderTransport, price: number) => {
        reset({
            customerSubForm: {
                ...customer,
            },
            pricingSubForm: {
                serviceLocation: order.serviceLocation,
                material: order.material as Material,
                priceType: order.priceType as Pricing.TypesOfPricing,
                price,
                finalPrice: price,
                expectedSize: order.expectedSize.size,
                weightLimit: order.weightLimit?.value || '',
                otherLineItems: [],
                adjustedRentalPeriod: order.adjustedRentalPeriod.value,
                rentExtensionFee: order.rentExtensionFee || '',
                tax: order.taxRate ? true : false,
                taxRate: order.taxRate || 0,
                overage: order.overage || '',
                dumpRate: order.dumpRate,
                // maxRentalDaysAllowed: order.maxRentalDaysAllowed,
                cc: !customer?.defaultPaymentSettings?.paymentMethod || customer?.defaultPaymentSettings?.paymentMethod === 'creditCard',
            },
            dispatchSubForm: {
                expectedDeliveryDate: getDateFormat(order.expectedDeliveryDate) || '',
                expectedPickupDate: getDateFormat(order.expectedPickupDate) || '',
                recurringOnCall: order.recurringOnCall || false,
                poNumber: order.poNumber || '',
                noteOrder: '',
                sharedDeliveryNotes: order.sharedDeliveryNotes || '',
                sharedPickupNotes: order.sharedPickupNotes || '',
                sharedDeliveryNotesPrivate: '',
            },
            paymentSubForm: {
                ...initialValues.paymentSubForm,
                paymentMethod: (customer?.defaultPaymentSettings?.paymentMethod as keyof typeof paymentMethodsEnums) || 'creditCard',
                paymentTerm: (customer?.defaultPaymentSettings?.paymentTerm as keyof typeof paymentTermsEnums) || 'onCharge',
            },
        });
    };

    const handleGetPricing = async (
        location: {
            lat: number;
            lng: number;
            zip?: string;
            state: string;
        },
        customerID?: string,
    ) => {
        const results = await getOrderPricing(client, location, customerID);
        if (results === undefined) {
            //only check for undefined, null means no results, undefined means error
            alert('An error has occurred. Get a SAP Dev');
        } else {
            setZonePricing(results);
        }
    };

    const handleGetOrder = async (id: string) => {
        const response = await client.order().adminPortal.fetch(id);
        if (response?.status !== 'NEEDS_REVIEW') {
            history.push(routes.orders.details(id));
        }
        const customerResponse = await client.customer().adminPortal.fetch(response.allianceCustomerID);
        if (customerResponse) {
            const price = await getOrdersWithInvoice(response);
            setExistingCustomer(customerResponse);
            setCurrentOrder(response);
            prepareForm(customerResponse, response, price);
            handleGetPricing(
                {
                    ...response.serviceLocation.coordinates,
                    zip: response.serviceLocation.address.zip,
                    state: response.serviceLocation.address.state,
                },
                customerResponse.id,
            );
        } else {
            alert('An error occurred get an AAP dev');
            return;
        }
    };

    useEffect(() => {
        handleGetOrder(id);
    }, [id]);

    const watchPaymentInfo = watch('paymentSubForm.paymentInfo');

    const handleBackButtonClick = (xClicked?: boolean) => {
        if (!xClicked || watchPaymentInfo) return;
        history.goBack();
    };

    const getOrderTotal = () => {
        let total = watchPrice || 0;
        watchOtherLineItems.forEach((item) => {
            total = total + Number(item.totalPrice);
        });
        setValue('pricingSubForm.finalPrice', round(total));
    };

    useEffect(() => {
        getOrderTotal();
    }, [watchPrice, watchOtherLineItems]);

    const handleSendEmail = async (values: OrderCreateFormProps, order: Order.AllianceOrderTransport): Promise<void> => {
        let contactEmails;
        try {
            contactEmails = getCustomerToAndCCEmails('dispatch', values.customerSubForm as Customer.AllianceCustomerTransport);
        } catch (error) {
            alert('No dispatch contact found, email not sent, get an SAP dev');
            return;
        }
        const emailData = createOrderConfirmationEmailSendGrid(
            {
                contact: contactEmails.toContact,
                expectedPickupDate: order.expectedPickupDate || undefined,
                emailMessage: values.paymentSubForm.emailMessage || undefined,
                serviceLocation: order.serviceLocation,
                expectedDeliveryDate: order.requestedDeliveryDate,
                sharedDeliveryNotes: order.sharedDeliveryNotes || '',
                expectedSize: order.requestedSize.size,
                adjustedRentalPeriod: order.originalRentalPeriod.value,
                rentExtensionFee: Number(order.rentExtensionFee),
                overage: order.overage || undefined,
                material: order.material as Material,
                weightLimit: order.weightLimit?.value || '',
            },
            values.pricingSubForm.finalPrice,
            userProfile,
            zonePricing?.prohibitedItems ? zonePricing.prohibitedItems.join(', ') : '',
            order.orderNumber?.toString() || '',
        );
        const destination = formatEmailDestination(contactEmails.to, contactEmails.toContact.firstName || '', contactEmails.cc);
        try {
            await client.notification().adminPortal.createInstantNotification({
                handler: 'sendgrid',
                topic: 'order-confirmation-ro',
                destination,
                body: JSON.stringify(emailData),
            });
        } catch (error) {
            console.warn('handleSendEmail Error: ', error);
            showFlash('Confirmation email did not send', 'error');
            alert('An error occurred, get an SAP dev, touch nothing');
        }
        if (
            isSameDay(new Date(), getDateFormat(values.dispatchSubForm.expectedDeliveryDate)) ||
            (isTomorrow(getDateFormat(values.dispatchSubForm.expectedDeliveryDate)) && new Date().getHours() >= 9)
        ) {
            const deliveryTomorrowEmailData: Notification.SendGrid.RoDeliveryTomorrow = {
                first_name: contactEmails.toContact.firstName || '',
                delivery_date: format(new Date(values.dispatchSubForm.expectedDeliveryDate), 'EEEE MM/dd/yy'),
                service_location: formatServiceAddress(values.pricingSubForm.serviceLocation.address),
                prohibited_items_string: zonePricing?.prohibitedItems ? zonePricing.prohibitedItems.join(', ') : '',
            };
            try {
                await client.notification().adminPortal.createInstantNotification({
                    destination: formatEmailDestination(contactEmails.to, contactEmails.toContact.firstName || '', contactEmails.cc || []),
                    topic: 'ro-delivery-tomorrow',
                    handler: 'sendgrid',
                    body: JSON.stringify(deliveryTomorrowEmailData),
                });
            } catch (error) {
                console.warn('handleSendEmail Error: ', error);
                showFlash('Next day email did not send', 'error');
                alert('An error occurred, get an SAP dev, touch nothing');
            }
        }
        return;
    };

    const handleSubmitForm = async (values: OrderCreateFormProps) => {
        if (!values.pricingSubForm.material) {
            showFlash('Material is required', 'warning');
            return null;
        }

        setIsLoading(true);
        const orderObj: Order.AllianceOrderUpdateInput = {
            ...currentOrder,
            adjustedRentalPeriod: {
                value: Number(values.pricingSubForm.adjustedRentalPeriod),
                unit: 'DAYS',
            },
            serviceLocation: values.pricingSubForm.serviceLocation,
            material: values.pricingSubForm.material,
            expectedSize: {
                size: Number(values.pricingSubForm.expectedSize),
                type: 'OPEN_TOP',
            },
            expirationDate:
                values.dispatchSubForm.expectedDeliveryDate && values.pricingSubForm.adjustedRentalPeriod
                    ? addDays(
                          new Date(values.dispatchSubForm.expectedDeliveryDate),
                          values.pricingSubForm.adjustedRentalPeriod,
                      ).toISOString()
                    : undefined,
            expectedDeliveryDate: getDateFormat(values.dispatchSubForm.expectedDeliveryDate),
            sharedDeliveryNotes: values.dispatchSubForm.sharedDeliveryNotes,
            expectedPickupDate: values.dispatchSubForm.expectedPickupDate
                ? getDateFormat(values.dispatchSubForm.expectedPickupDate)
                : undefined,
            sharedPickupNotes: values.dispatchSubForm.sharedPickupNotes,
            poNumber: values.dispatchSubForm.poNumber,
            sharedDeliveryNotesPrivate: values.dispatchSubForm.sharedDeliveryNotesPrivate,
            recurringOnCall: values.dispatchSubForm.recurringOnCall,
            status: 'UNASSIGNED',
            sourgumPricingSnapshot: createSourgumPricingSnapshot(values.pricingSubForm, zonePricing),
        };
        let orderResponse;

        try {
            if (!currentOrder?.id) throw new Error('No Order ID');
            orderResponse = await client.order().adminPortal.update(currentOrder?.id, orderObj);
        } catch (orderError) {
            console.warn('create Order orderError: ', orderError);
            showFlash('An Error Occurred Creating Order', 'warning');
            alert('An error occurred get an SAP dev');
            setIsLoading(false);
            return;
        }
        if (values.dispatchSubForm.noteOrder)
            await client.internalTicket().adminPortal.create({
                entityID: currentOrder?.id as string,
                entityType: 'sourgum-order',
                note: values.dispatchSubForm.noteOrder,
                tags: [],
            });
        if (values.paymentSubForm.sendEmail) {
            await handleSendEmail(values, orderResponse);
        }
        showFlash('Order Successfully Updated', 'success');
        history.goBack();
    };

    if (!currentOrder) return <Loading />;

    return (
        <Dialog open={true} onClose={handleBackButtonClick} styledTitle="Review New Order" fullScreen>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                    {existingCustomer ? (
                        <OrderReview
                            user={userProfile}
                            zonePricing={zonePricing}
                            onGetPricing={handleGetPricing}
                            isLoading={isLoading}
                            existingCustomer={existingCustomer}
                        />
                    ) : (
                        <div> something went wrong </div>
                    )}
                </form>
            </FormProvider>
        </Dialog>
    );
};
export default OrderReviewContainer;
