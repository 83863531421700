import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Draggable from 'react-draggable';

interface Props {
    onClose: () => void;
    pricing: any;
}

const ZoneNotes = ({ onClose, pricing }: Props) => {
    return (
        <Draggable cancel=".no-drag">
            <div className="shadow-dark absolute z-[99] w-screen max-w-[500px] rounded bg-white p-3">
                <div className="flex items-center justify-between">
                    <h6 className="text-xl font-medium">Zone Notes</h6>
                    <button className="btn-icon no-drag" onClick={onClose} type="button">
                        <XMarkIcon className="h-6 w-6" />
                    </button>
                </div>
                <hr className="mb-3" />
                {pricing?.days ? (
                    <div>
                        <div className="mb-1 text-black opacity-60">Operating Days</div>
                        <div className="flex flex-wrap">
                            {Object.keys(pricing?.days).map((key) => (
                                <div>
                                    <div className="mb-1 mr-2.5 capitalize">
                                        <b>{key}</b>
                                    </div>
                                    <div>{pricing?.days[key] || '?'}</div>
                                </div>
                            ))}
                        </div>
                        <hr className="mb-3 mt-1" />
                    </div>
                ) : null}
                {pricing?.prohibitedItems ? (
                    <div
                        onClick={() => {
                            // copy to clipboard
                            navigator.clipboard.writeText(pricing.prohibitedItems.join(', '));
                        }}
                    >
                        <div className="mb-1 text-black opacity-60">
                            Prohibited Items <span className="text-xs">(click to copy)</span>
                        </div>
                        <div className="flex cursor-pointer flex-wrap">{pricing.prohibitedItems.join(', ')}</div>
                        <hr className="mb-3 mt-1" />
                    </div>
                ) : null}
                {pricing?.notes || 'No Notes for this Zone'}
            </div>
        </Draggable>
    );
};

export default ZoneNotes;
