import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { prepareDateStringsForForm, sanitizeFromPayload } from '@wayste/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { UIContext } from '../../../contexts';
import { UniversalServiceFormData } from '../UniversalServiceCreate/UniversalServiceCreate';
import OneTimeServiceOrderSubForm from '../components/OneTimeServiceOrderSubForm';

const OneTimeServiceOrderUpdate = () => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const history = useHistory();
    const params: { serviceGroupingId: string; serviceOrderId: string } = useParams();
    const methods = useForm<UniversalServiceFormData>();
    const { handleSubmit, reset } = methods;
    const [serviceGrouping, setServiceGrouping] = useState<UniversalService.ServiceGrouping | undefined>(undefined);
    const [serviceOrder, setServiceOrder] = useState<UniversalService.ServiceOrder | undefined>(undefined);

    const fetchServiceGrouping = async () => {
        try {
            const serviceGrouping = await client.universalService().serviceGrouping.fetch(params.serviceGroupingId);
            setServiceGrouping(serviceGrouping);
            const fetchedServiceOrder = serviceGrouping.serviceOrders.find((order) => order.id === params.serviceOrderId);

            if (fetchedServiceOrder) {
                // convert any keys that include date to date
                prepareDateStringsForForm(fetchedServiceOrder, 'startDate', 'endDate', 'deliveryDate', 'endDate');
            }

            setServiceOrder(fetchedServiceOrder);
        } catch (error) {
            console.warn('fetchServiceOrder error for ID: ', error);
            return { status: 'error', data: null };
        }
    };

    const onSubmit = async (data: UniversalServiceFormData) => {
        try {
            // UPDATE SERVICE GROUPING
            const serviceGroupingUpdatePayload: UniversalService.ServiceGroupingUpdate = {
                ...data.serviceGrouping,
            };
            await client.universalService().serviceGrouping.update(params.serviceGroupingId, serviceGroupingUpdatePayload);

            // UPDATE SERVICE ORDER
            const newOrder = (data.serviceGrouping.serviceOrders as unknown as UniversalService.ServiceOrder[]).find(
                (order) => order?.id === params.serviceOrderId,
            );

            const serviceOrderUpdatePayload: UniversalService.ServiceOrderUpdate = {
                ...newOrder,
                serviceDays: serviceOrder?.serviceDays.map((day) => Number(day)),
                vendorID: serviceOrder?.vendorID || undefined,
                serviceTypeID: serviceOrder?.serviceType.id !== newOrder?.serviceType.id ? newOrder?.serviceType.id : undefined,
            };

            await client
                .universalService()
                .serviceGrouping.serviceOrder.update(
                    params.serviceGroupingId,
                    params.serviceOrderId,
                    sanitizeFromPayload(serviceOrderUpdatePayload, 'accountManagerID'),
                );

            showFlash('Service Order Successfully Updated', 'success');
            history.goBack();
        } catch (error) {
            console.error('Service Order Update error ', error);
            showFlash('Error Updating Service Order', 'warning');
        }
    };

    useEffect(() => {
        fetchServiceGrouping();
    }, []);

    useEffect(() => {
        if (!serviceGrouping) return;
        reset({
            serviceGrouping: serviceGrouping,
        });
    }, [serviceGrouping]);

    return (
        <div className="flex max-w-[960px] flex-1 flex-col p-16">
            <FormProvider {...methods}>
                <form>
                    <div className="mb-6 pb-2 text-2xl">Update Service Order</div>
                    <div className="space-y-6">
                        <OneTimeServiceOrderSubForm serviceFamily={serviceOrder?.serviceType.family} mode="update" />
                    </div>
                    <div className="float-right mt-2">
                        <Button className="btn-dark-grey-outlined mr-4" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                        <Button className="btn-primary" onClick={handleSubmit(onSubmit)}>
                            Update
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};
export default OneTimeServiceOrderUpdate;
