import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { SourSearchResponseOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { SourFiltersOld, SourSearchOld } from '@wayste/sour-search';
import { getRouterPath } from '@wayste/utils';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { UIContext } from '../../../contexts';
import { routes } from '../../../utils';
import UniversalServicesListTable from './UniversalServicesListTable';

const UniversalServicesList = () => {
    const client = useWaysteClient();
    const history = useHistory();
    const { showFlash } = useContext(UIContext);
    const [searchData, setSearchData] = useState<UniversalService.ServiceGrouping[]>([]);
    const [dbData, setDBData] = useState<UniversalService.ServiceGrouping[]>([]);
    const [searchPage, setSearchPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    const [isDefaultFilter, setIsDefaultFilter] = useState(true);
    const [searchTotalRecords, setSearchTotalRecords] = useState(0);

    ///////////////////////////////////////////////
    // Query DB
    ///////////////////////////////////////////////

    const [page, setPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    useEffect(() => {
        fetchServiceGrouping();
    }, [page, pageSize]);

    const fetchServiceGrouping = async () => {
        setIsLoading(true);
        try {
            const query: UniversalService.ServiceGroupingQuery = {
                offset: page * pageSize,
                limit: pageSize,
                reverseSort: true,
            };
            const response = await client.universalService().serviceGrouping.query(query);
            setDBData(response.results);
            setTotalRecords(response.total);
        } catch (error) {
            console.error(error);
            showFlash('An Error Occurred loading service groupings.', 'warning');
        } finally {
            setIsLoading(false);
        }
    };

    ///////////////////////////////////////////////
    // NEW SEARCH
    ///////////////////////////////////////////////

    const onNavigate = (_: unknown, recordID: string, name: string) => {
        const path = getRouterPath(name, recordID, routes);
        history.push(path);
    };

    const onActiveSearch = (active: boolean) => {
        setSearchActive(active);
    };

    const onActiveFilter = (active: boolean) => {
        setFilterActive(active);
    };

    // THIS NEEDS TO BE SPEED UP
    const processHits = async (hits: SourSearchResponseOld['results']['hits']['hits']) => {
        const results: SourSearchResponseOld['results']['hits']['hits'][0]['_source'][] = [];
        hits.map((hit) => {
            results.push(hit._source);
        });
        return results as UniversalService.ServiceGrouping[];
    };

    // DEFINE ON RESULTS LOGIC
    const handleSearchResults = async (response: SourSearchResponseOld) => {
        // if (searchPage !== response.page) setSearchPage(response.page + 1); // Adjust if necessary
        response.type === 'sourgum-service-grouping' ? setIsDefaultFilter(true) : setIsDefaultFilter(false);
        if (response.type === 'sourgum-service-grouping') {
            const res = await processHits(response.results.hits.hits);
            setSearchData(res);
            setSearchTotalRecords(response.totalHits);
        } else {
            setSearchData([]);
        }
    };

    // Uncomment and use this function if needed for pagination
    const handleSearchPageChange = (value: number) => {
        setSearchPage(value);
    };

    ///////////////////////////////////////////////
    // END NEW SEARCH
    ///////////////////////////////////////////////

    const onCreateNewClicked = () => {
        history.push(routes.universalServices.new);
    };

    const handleOpenRowDetails = (group: UniversalService.ServiceGrouping) => {
        // if there is more than one service order, go to the subscription details
        history.push(routes.universalServices.serviceGrouping.details(group.id));
    };

    if (isLoading) return <Loading />;

    return (
        <div className="flex w-full grow flex-col p-5">
            <div className="pl-2 text-2xl">Orders</div>
            <SourSearchWrapperOld
                options={{
                    application: 'aap',
                    apiKey: import.meta.env.VITE_ELASTIC_KEY,
                    environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                }}
                defaultFilters={{
                    activateOnInitial: false,
                    query: {
                        type: 'sourgum-service-grouping',
                    },
                }}
                onNavigate={onNavigate}
                onResults={handleSearchResults}
                onSearch={onActiveSearch}
                onFilter={onActiveFilter}
                createQueryParams={{ method: 'with_filter', removeOn: 'empty_string_inactive_filter' }}
                page={searchPage - 1} // SourSearchOld is 0-indexed, we are 1-indexed
                size={pageSize}
            >
                <div className="mt-4 flex w-full flex-row py-2">
                    <div className="item-center flex w-full flex-row justify-between">
                        <div className="flex w-full flex-row justify-between space-x-4 pr-5">
                            <SourSearchOld
                                options={{
                                    searchPopoverFixed: false,
                                    showTips: true,
                                    showMessages: !isDefaultFilter,
                                    showResults: !isDefaultFilter,
                                    placeholder: 'Search...',
                                }}
                            />
                        </div>
                        <div className="flex h-10 flex-1 flex-row space-x-2">
                            <SourFiltersOld />
                            <button className="btn-primary ml-4 h-full flex-shrink-0 px-4" onClick={onCreateNewClicked}>
                                <PlusIcon className="size-5 pr-1" /> New
                            </button>
                        </div>
                    </div>
                </div>
                <UniversalServicesListTable
                    page={searchActive || filterActive ? searchPage : page}
                    size={pageSize}
                    total={searchActive || filterActive ? searchTotalRecords : totalRecords}
                    setPage={searchActive || filterActive ? setSearchPage : setPage}
                    setPageSize={setPageSize}
                    serviceGrouping={searchActive || filterActive ? searchData : dbData}
                    onRowClicked={handleOpenRowDetails}
                    loading={isLoading}
                />
            </SourSearchWrapperOld>
        </div>
    );
};

export default UniversalServicesList;
