import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button, Dialog, Loading } from '@wayste/sour-ui';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useHistory, useParams } from 'react-router-dom';
import { InternalTicket } from '../../components/InternalTicket';
import { routes } from '../../utils';
import RFPDetailsCard from './components/rfp-details-card';
import RFPVendorResponseCard from './components/rfp-vendor-response-card';

const RFPDetails = () => {
    const client = useWaysteClient();
    const { id }: { id: string } = useParams();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [rfp, setRFP] = useState<UniversalService.RFPTransport | null>(null);
    const [showVoidDialog, setShowVoidDialog] = useState(false);

    const handleFetchRFP = async (id: string) => {
        setIsLoading(true);
        try {
            const rfpResponse = await client.universalService().adminPortal.rfp.fetch(id);
            setRFP(rfpResponse);
        } catch (error) {
            console.error('Error fetching RFP', error);
            alert('Error fetching RFP, get a SAP dev');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            handleFetchRFP(id);
        } else {
            setIsLoading(false);
        }
    }, [id]);

    if (isLoading) {
        <Loading fullScreen />;
    }

    if (!rfp) {
        return (
            <div className="flex w-full max-w-screen-xl flex-col items-center justify-center p-5">
                <div>Invalid RFP</div>
            </div>
        );
    }

    return (
        <div className="mx-auto flex w-full max-w-screen-xl flex-col gap-y-4 p-5">
            <div className="flex items-center gap-x-3">
                <Button
                    className="btn-dark-grey-outlined"
                    onClick={() => history.push(routes.requestForProposal.list)}
                    startIcon={<ChevronLeftIcon className="h-5 w-5" />}
                >
                    Back
                </Button>
                <h1 className="text-2xl">RFP #{rfp.proposalNumber} Details</h1>
            </div>
            <div className="flex justify-between gap-x-4">
                <div>
                    <Button
                        className="btn-secondary-outlined"
                        onClick={() => history.push(routes.requestForProposal.vendorSelect(rfp.id))}
                        disabled={rfp.status !== 'OPEN'}
                    >
                        Send to Additional Vendors
                    </Button>
                </div>
                <div className="flex gap-x-4">
                    <Button className="btn-delete" onClick={() => setShowVoidDialog(true)} disabled={rfp.status !== 'OPEN'}>
                        Void RFP
                    </Button>
                    <Button
                        onClick={() => history.push(routes.requestForProposal.awardRFP(rfp.id))}
                        className="btn-primary"
                        disabled={rfp.status !== 'OPEN'}
                    >
                        Award RFP
                    </Button>
                </div>
            </div>
            <RFPDetailsCard rfp={rfp} />
            <InternalTicket entityID={rfp.id} entityType="sourgum-rfp" />
            {rfp.rfpVendorResponses.map((response) => (
                <RFPVendorResponseCard
                    key={response.id}
                    rfpVendorResponse={response}
                    rfp={rfp}
                    onRefetch={() => {
                        if (id) handleFetchRFP(id);
                    }}
                />
            ))}
            {/* Dialogs */}
            <DeleteDialog
                open={showVoidDialog}
                id={id}
                onClose={() => setShowVoidDialog(false)}
                onVoid={() => {
                    setShowVoidDialog(false);
                    handleFetchRFP(id);
                }}
            />
        </div>
    );
};

export default RFPDetails;

const DeleteDialog = ({ open, onClose, id, onVoid }: { open: boolean; id: string; onClose: () => void; onVoid: () => void }) => {
    const client = useWaysteClient();
    const [isVoiding, setIsVoiding] = useState(false);

    const handleVoidRFP = async () => {
        setIsVoiding(true);
        try {
            await client.universalService().adminPortal.rfp.update(id, { status: 'VOID' });
            onVoid();
        } catch (error) {
            console.error('Error voiding RFP', error);
            alert('Error voiding RFP, get a SAP dev');
        } finally {
            setIsVoiding(false);
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} variant="delete" styledTitle="Void RFP" disableCloseOnClickOutside={isVoiding}>
            <div className="flex flex-col gap-y-6">
                <p>Are you sure you want to void this RFP?</p>
                <p>Doing so will not allow vendors to submit additional bids and you won't be able to Award this RFP.</p>
                <div className="flex justify-end gap-x-4">
                    <Button onClick={onClose} className="btn-dark-grey-outlined" disabled={isVoiding}>
                        Cancel
                    </Button>
                    <Button onClick={handleVoidRFP} className="btn-delete" loading={isVoiding}>
                        Void RFP
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
