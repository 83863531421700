import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { Button, Dialog } from '@wayste/sour-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import { onCreateOrderFromLead } from '../../pages/leads/utils/lead-to-order';
import { routes } from '../../utils';
import ROQuoteSubForm from '../LeadForms/ROQuoteSubForm';
import { type ROQuoteFormType, prepareROQuoteSubForm } from '../LeadForms/utils/prepare-lead-form';

export const ROQuoteUpdate = ({ open, onClose, lead }: { open: boolean; onClose: () => void; lead: Customer.AllianceLeadTransport }) => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<ROQuoteFormType>({
        mode: 'all',
        defaultValues: prepareROQuoteSubForm(lead),
    });

    const { handleSubmit, reset } = methods;

    useEffect(() => {
        reset(prepareROQuoteSubForm(lead));
    }, [open]);

    const submitForm = async (data: ROQuoteFormType) => {
        setIsLoading(true);
        for (const quote of data.quotes) {
            if (!quote.hasOwnProperty('id')) {
                try {
                    await client.customer().adminPortal.leads.quote.create(lead.id, quote);
                    showFlash('Lead Successfully Updated', 'success');
                } catch (error) {
                    showFlash('Error Updating Lead', 'warning');
                }
            }
        }
        onClose();
        setIsLoading(false);
    };

    const handleOnAddOrder = (quote: Customer.AllianceLeadQuote | Customer.AllianceLeadQuoteCreateTransport) => {
        const data = onCreateOrderFromLead(lead, quote);
        history.push(routes.orders.new, data);
    };

    return (
        <Dialog open={open} className="max-w-6xl" styledTitle="Update Lead" onClose={onClose}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <ROQuoteSubForm lead={lead} onAddOrder={(quote) => handleOnAddOrder(quote)} />
                    <div className="flex justify-end gap-4 pt-6">
                        <Button onClick={onClose} className="btn-dark-grey-outlined" disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button className="btn-primary" type="submit" loading={isLoading}>
                            Save
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Dialog>
    );
};
