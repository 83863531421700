import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, type Material, MaterialLabels, Materials, Pricing } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { SourFiltersOld, SourSearchOld, SourSearchResponseOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { ButtonGroup, DataGrid, DataGridProps } from '@wayste/sour-ui';
import { formatE164ToUSPhoneNumber, getPrimaryContact, getRouterPath, sortByKey } from '@wayste/utils';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import ButtonSelect from '../../components/ButtonSelect';
import routes from '../../utils/routes';
import HaulersPricingTable from './HaulersPricingTable';

const stateOptions = Object.values(Pricing.USStateAbbreviations).map((item) => ({
    label: item,
    value: item,
}));

const materialOptions = Materials.map((item) => ({
    label: MaterialLabels[item],
    value: item,
}));

const defaultFilter = 'sourgum-vendor';

const HaulerListContainer = () => {
    const client = useWaysteClient();
    const history = useHistory();
    const { setIsLoading: setSourUiLoading, setShowToast } = useSourContext();
    const [data, setData] = useState<Hauler.HaulerWithAapTransport[]>([]);
    const [searchData, setSearchData] = useState<Hauler.HaulerWithAapTransport[]>([]);
    const [view, setView] = useState(0);
    const [pricingData, setPricingData] = useState<Pricing.PricingTransport[]>([]);
    const [allHaulers, setAllHaulers] = useState<Hauler.HaulerWithAapTransport[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDefaultFilter, setIsDefaultFilter] = useState<boolean | undefined>(true);
    const [searchActive, setSearchActive] = useState<boolean>(false);
    const [filterActive, setFilterActive] = useState<boolean>(false);
    const [state, setState] = useState('');
    const [material, setMaterial] = useState<Material | ''>('');

    const getAllHaulers = async () => {
        setIsLoading(true);
        // const haulerResponse = await waysteClient.vendorService().fetch();
        const haulerResponse = await client.vendorService().adminPortal.query();
        // limit: pageSize,
        //         offset: (page || 0) * pageSize,
        if (!haulerResponse) return;
        setAllHaulers(haulerResponse.sort((a, b) => ((a?.name || '') > (b?.name || '') ? 1 : -1)));
        setIsLoading(false);
    };

    useEffect(() => {
        // TODO does this need to be put back to a subscription???
        getAllHaulers();
    }, []);

    useEffect(() => {
        if (!searchActive && !filterActive) {
            setData(allHaulers);
        }
    }, [searchActive, filterActive]);

    useEffect(() => {
        if (view === 0) setData(allHaulers);
    }, [allHaulers, view]);

    const onActiveSearch = (active: boolean) => {
        setSearchActive(active);
    };

    const onFilterActive = (active: boolean) => {
        setFilterActive(active);
    };

    const handleToggleClick = (option: any) => {
        setView(option);
    };

    const handleGetPricing = async (stateAbb: string) => {
        setSourUiLoading(true);
        try {
            const response = await client.pricing().adminPortal.pricing.query({ state: stateAbb as any });
            const haulerPricing = response.filter((item: any) => !item.public);
            const pricing = haulerPricing.map((item: any) => ({
                ...item,
                hauler: allHaulers.find((hauler: any) => hauler.id === item.haulerID),
            }));
            const sortedData = sortByKey('zoneName', pricing);
            setPricingData(sortedData);
        } catch (error) {
            console.error(error);
            setShowToast({ message: 'Error fetching pricing', severity: 'error' });
        } finally {
            setSourUiLoading(false);
        }
    };

    const handleHaulerClicked = (id: string) => {
        const selectedHauler = allHaulers.find((hauler) => hauler.id === id);
        if (selectedHauler) history.push(routes.haulers.details(selectedHauler.id), { modal: true });
    };

    const handleCreateNewClick = () => {
        history.push(routes.haulers.new);
    };

    const onNavigate = (_: unknown, recordID: string, name: string) => {
        const path = getRouterPath(name, recordID, routes);
        history.push(path);
    };

    // DEFINE ON RESULTS LOGIC
    const onResults = async (response: SourSearchResponseOld) => {
        response.type === defaultFilter ? setIsDefaultFilter(true) : setIsDefaultFilter(false);
        if (response.type === defaultFilter) {
            const filtered = response.results.hits.hits.map((item) => item._source);
            setSearchData(filtered as Hauler.HaulerWithAapTransport[]);
        }
    };

    const handleStateChange = (newState: string) => {
        handleGetPricing(newState);
        setState(newState);
    };

    const columns: DataGridProps<Hauler.HaulerWithAapTransport>['columns'] = [
        {
            key: 'name',
            name: 'Name',
        },
        {
            key: 'officePhone',
            name: 'Primary Phone',
            formatter: ({ row }) => {
                const primaryContact = getPrimaryContact(row);
                return primaryContact?.phoneNumber ? formatE164ToUSPhoneNumber(primaryContact?.phoneNumber) : '';
            },
        },
        {
            key: 'officeEmail',
            name: 'Primary Email',
            formatter: ({ row }) => {
                const primaryContact = getPrimaryContact(row);
                return primaryContact?.email || '';
            },
        },
    ];

    // if (isLoading) return <Loading />;

    return (
        <SourSearchWrapperOld
            onNavigate={onNavigate}
            onSearch={onActiveSearch}
            onFilter={onFilterActive}
            onResults={onResults}
            defaultFilters={{
                query: {
                    type: defaultFilter,
                },
            }}
            options={{
                application: 'aap',
                apiKey: import.meta.env.VITE_ELASTIC_KEY as string,
                environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
            }}
        >
            <div className="container mx-auto flex flex-1 flex-col px-2 pb-2 pt-5 md:px-6">
                <div className="grid grid-cols-1 items-center justify-center gap-2 pb-5 md:flex md:gap-0">
                    <div className="flex w-full items-end justify-end">
                        <div className={`w-full flex-shrink-0 ${view === 0 ? 'flex' : 'hidden'}`}>
                            <div className="item-center flex w-full flex-row justify-between">
                                <div className="mr-4 flex w-full flex-row">
                                    <div className="flex w-full flex-row space-x-4">
                                        <SourSearchOld
                                            options={{
                                                searchPopoverFixed: true,
                                                showTips: !isDefaultFilter,
                                                showMessages: !isDefaultFilter,
                                                showResults: !isDefaultFilter,
                                                placeholder: 'Search Haulers',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="flex">
                                    <SourFiltersOld />
                                    <button className="btn-primary ml-4 mr-2 h-10" onClick={() => handleCreateNewClick()}>
                                        <PlusIcon className="size-5 pr-1" /> New
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={`h-10 flex-1 ${view !== 0 ? 'flex' : 'hidden'}`}>
                            <ButtonSelect
                                label="State"
                                menuItems={stateOptions}
                                value={state}
                                onSelect={handleStateChange}
                                wrapperClass="mr-3"
                            />
                            <ButtonSelect label="Material" menuItems={materialOptions} value={material} onSelect={setMaterial} noneOption />
                        </div>
                    </div>

                    <div className="flex h-10">
                        <ButtonGroup
                            buttons={[{ label: 'By Hauler' }, { label: 'By Zone' }]}
                            activeBtn={view}
                            onClick={handleToggleClick}
                        />
                    </div>
                </div>

                <div className="shadow-dark flex flex-1 flex-col overflow-auto rounded-md bg-white">
                    {view === 0 ? (
                        <DataGrid
                            columns={columns}
                            rows={searchActive ? searchData : data}
                            loading={isLoading}
                            onRowClick={(row) => handleHaulerClicked(row.id)}
                            makeRow={(rowData) => ({
                                className: !rowData.active ? 'bg-red-300' : undefined,
                            })}
                        />
                    ) : (
                        <HaulersPricingTable
                            haulersPricing={pricingData}
                            material={material}
                            onRowClick={(pricing) => handleHaulerClicked(pricing.haulerID)}
                            showZoneName={true}
                        />
                    )}
                </div>
            </div>
        </SourSearchWrapperOld>
    );
};

export default HaulerListContainer;
