const routes = {
    dashboard: '/',
    accountSettings: {
        list: '/account-settings/',
        googleAds: '/account-settings/google-ads/',
        googleAuthCallback: '/account-settings/google-ads/callback/',
        quickBooksMapping: '/account-settings/quickbooks-mapping/',
        quickBooksCallback: '/account-settings/quickbooks-mapping/callback/',
        tagManagement: '/account-settings/tag-management',
        coupons: '/account-settings/coupons',
        users: '/account-settings/users',
    },
    billing: {
        list: '/billing/',
        details: (id: string, view?: 'invoice' | 'bill') => `/billing/?id=${id}&view=${view || 'bill'}`,
        new: '/billing/new/',
    },

    serviceGrouping: {
        searchRouteForEntity: 'service-grouping', // added this to make the getRouterPath util work better rather than just trying to make it match on the path itself
        details: (id: string) => `/universal-services/service-grouping/details/${id}/`,
    },

    billingProduct: {
        list: '/billing/',
        details: (serviceGroupingID: string, serviceOrderID?: string) =>
            `/billing/?serviceGroupingID=${serviceGroupingID}${serviceOrderID ? `&serviceOrderID=${serviceOrderID}` : ''}`,
        new: '/billing/new/',
    },
    haulers: {
        list: '/haulers/',
        details: (id: string) => `/haulers/details/${id}/`,
        new: '/haulers/new/',
    },
    leads: {
        list: '/leads/',
        create: '/leads/new/',
        details: (id: string) => `/leads/details/${id}/`,
    },
    orders: {
        list: '/orders/',
        new: '/orders/new/',
        details: (id: string) => `/orders/details/${id}/`,
        review: (id: string) => `/orders/review/${id}/`,
    },
    pricing: {
        list: '/pricing/',
        new: '/pricing/new/',
        details: (id: string) => `/pricing/details/${id}/`,
    },
    reports: '/reports/',
    customers: {
        details: (id: string) => `/customers/details/${id}/`,
    },

    universalServices: {
        new: '/universal-services/new/',
        list: '/universal-services/',
        serviceGrouping: {
            details: (serviceGroupingId: string) => `/universal-services/service-grouping/details/${serviceGroupingId}/`,
            addOrder: (serviceGroupingId: string) => `/universal-services/service-grouping/${serviceGroupingId}/add-order/`,
            serviceOrder: {
                details: (serviceGroupingId: string, serviceOrderId: string) =>
                    `/universal-services/service-grouping/${serviceGroupingId}/service-order/details/${serviceOrderId}/`,
                update: (serviceGroupingId: string, serviceOrderId: string) =>
                    `/universal-services/service-grouping/${serviceGroupingId}/service-order/update/${serviceOrderId}/`,
            },
            subscription: {
                pricing: (serviceGroupingId: string, serviceOrderId: string) =>
                    `/universal-services/service-grouping/${serviceGroupingId}/service-order/subscription/pricing/${serviceOrderId}/`,
                update: (serviceGroupingId: string, serviceOrderId: string) =>
                    `/universal-services/service-grouping/${serviceGroupingId}/service-order/subscription/update/${serviceOrderId}/`,
                subscriptionUpdate: (serviceGroupingId: string, serviceOrderId: string) =>
                    `/universal-services/service-grouping/${serviceGroupingId}/service-order/subscription/subscription-update/${serviceOrderId}/`,
                details: (serviceGroupingId: string, serviceOrderId: string) =>
                    `/universal-services/service-grouping/${serviceGroupingId}/service-order/subscription/details/${serviceOrderId}/`,
            },
        },

        services: {
            list: '/universal-services/services/list/',
            new: '/universal-services/services/new/',
            details: (id: string) => `/universal-services/services/details/${id}/`,
        },
    },
    internalTickets: {
        list: '/internal-tickets/list/',
    },
    requestForProposal: {
        list: '/request-for-proposal/',
        /**
         * You can pass "forType" as sourgum-customer | sourgum-lead as well as "id" via the query params to associate the RFP to a customer or lead
         * E.g routes.requestForProposal.new + `?forType=sourgum-lead&id=${lead.id}`
         */
        new: `/request-for-proposal/new/`,
        details: (id: string) => `/request-for-proposal/details/${id}/`,
        update: (id: string) => `/request-for-proposal/update/${id}/`,
        vendorSelect: (id: string) => `/request-for-proposal/vendor-select/${id}/`,
        awardRFP: (id: string) => `/request-for-proposal/award/${id}/`,
    },
    accountManagerViews: {
        projectBoard: '/account-manager-project-board/',
    },
    search: '/search/',
    lab: '/development-lab/',
    csaReport: '/csa-report/',
    marketing: {
        // list: '/marketing/',
        textBlast: '/marketing/text-blast/',
    },
};

export default routes;
