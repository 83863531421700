import React from 'react';
import { DatePicker, Select, SelectOption } from '@wayste/sour-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { mediumTypes } from '../../utils/shared-types';

const ContactedSubForm = () => {
    const { control } = useFormContext();
    return (
        <div className="flex flex-col gap-4 md:flex-row">
            <Controller
                name={`medium`}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'A medium is required',
                    },
                }}
                render={({ field, fieldState }) => (
                    <Select
                        label="Medium"
                        error={fieldState.error}
                        value={field.value}
                        onSelect={(value) => field.onChange(value)}
                        required
                    >
                        {Object.entries(mediumTypes).map((item) => (
                            <SelectOption key={`medium-${item[0]}`} value={item[0]}>
                                {item[1]}
                            </SelectOption>
                        ))}
                    </Select>
                )}
            />
            <Controller
                name={`date`}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'A date is required',
                    },
                }}
                render={({ field, fieldState }) => (
                    <DatePicker
                        label="Date"
                        error={fieldState.error}
                        value={field.value ? new Date(field.value) : undefined}
                        onChange={(value) => field.onChange((value as Date).toISOString())}
                        closeOnSelect
                        required
                    />
                )}
            />
        </div>
    );
};

export default ContactedSubForm;
