import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { InternalTicket } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Menu, MenuItem, TextField, Tooltip } from '@wayste/sour-ui';
import { ExclamationTriangleIcon, PlusCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { InternalTicketContext } from './context';

type ManageTagsProps = {
    tags: InternalTicket.TagTransport[];
    /**
     * Passing in a ticketID will allow for tags to be added and removed from the ticket via Wayste Client
     */
    ticketID?: string;
    onAddTag?: (tag: InternalTicket.TagTransport) => void;
    onRemoveTag?: (tag: InternalTicket.TagTransport) => void;
    readOnly?: boolean;
    disabled?: boolean;
};

export const ManageTags = ({ tags, ticketID, onAddTag, onRemoveTag, readOnly, disabled }: ManageTagsProps) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const { fetchInternalTickets } = useContext(InternalTicketContext);
    const [allTags, setAllTags] = useState<InternalTicket.TagTransport[]>([]);
    const [filteredTags, setFilteredTags] = useState<InternalTicket.TagTransport[]>([]);

    const handleGetTags = async () => {
        try {
            const response = await client.internalTicket().adminPortal.tag.query({ deletedAt: false });
            setAllTags(response.results);
            setFilteredTags(response.results.filter((tag) => !InternalTicket.SourgumCustomerPortalTagIds?.includes(tag.id)));
        } catch (error) {
            console.warn('Error fetching tags: ', error);
            setShowToast({ message: 'Error fetching tags', severity: 'error' });
        }
    };

    useEffect(() => {
        if (!readOnly && !disabled) {
            handleGetTags();
        }
    }, []);

    const handleAdd = async (tag: InternalTicket.TagTransport) => {
        // Don't allow for duplicate tags to be added.
        if (tags.find((existingTag) => existingTag.id === tag.id)) {
            return;
        }
        if (ticketID) {
            const updatedTags = [...tags, tag].map((tagItem) => tagItem.id);
            try {
                await client.internalTicket().adminPortal.update(ticketID, { tags: updatedTags });
                fetchInternalTickets();
            } catch (error) {
                console.warn('Error adding tag: ', error);
                setShowToast({ message: 'Error adding tag', severity: 'error' });
            }
        }
        if (onAddTag) {
            onAddTag(tag);
        }
    };

    const handleRemove = async (tag: InternalTicket.TagTransport) => {
        if (ticketID) {
            const updatedTags = tags.filter((existingTag) => existingTag.id !== tag.id).map((tagItem) => tagItem.id);
            try {
                await client.internalTicket().adminPortal.update(ticketID, { tags: updatedTags });
                fetchInternalTickets();
            } catch (error) {
                console.warn('Error removing tag: ', error);
                setShowToast({ message: 'Error removing tag', severity: 'error' });
            }
            fetchInternalTickets();
        }
        if (onRemoveTag) {
            onRemoveTag(tag);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value.toLowerCase();
        const newFilteredObjects = allTags.filter((tag) => tag.name.toLowerCase().includes(searchText));
        setFilteredTags(newFilteredObjects);
    };

    return (
        <div className="flex items-center gap-1">
            {tags.map((tag) => (
                <Tooltip text={tag.description} key={tag.id}>
                    <div
                        className="flex items-center gap-1 rounded border border-black px-2 py-1 text-xs"
                        style={{ backgroundColor: tag.backgroundColor || 'white', color: tag.textColor || 'black' }}
                    >
                        {tag.showToCustomer && <ExclamationTriangleIcon className="size-5" />}
                        {tag.name}
                        {!readOnly && (
                            <XMarkIcon
                                className="size-5 cursor-pointer"
                                onClick={() => {
                                    if (!disabled || InternalTicket.SourgumCustomerPortalTagIds.includes(tag.id)) handleRemove(tag);
                                }}
                            />
                        )}
                    </div>
                </Tooltip>
            ))}
            {!readOnly && (
                <Menu
                    button={
                        <Button endIcon={<PlusCircleIcon className="size-5" />} className="btn-secondary-text-only" disabled={disabled}>
                            Tag
                        </Button>
                    }
                    onClose={() => setFilteredTags(allTags)}
                >
                    <MenuItem onClick={(e) => e.preventDefault()}>
                        <TextField
                            inputProps={{
                                onChange: (e) => handleInputChange(e),
                            }}
                        />
                    </MenuItem>
                    {filteredTags.map((tagOption) => (
                        <MenuItem key={tagOption.id} onClick={() => handleAdd(tagOption)} className="flex items-center justify-between">
                            <div className="flex gap-2">
                                {tagOption.showToCustomer && (
                                    <Tooltip text="WARNING A CUSTOMER WILL SEE THIS TICKET" delay={50}>
                                        <ExclamationTriangleIcon className="size-5 text-amber-600" />
                                    </Tooltip>
                                )}
                                {tagOption.name}
                            </div>
                            <Tooltip text={tagOption.description}>
                                <InformationCircleIcon className="size-4" />
                            </Tooltip>
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </div>
    );
};
