import React from 'react';
import { Checkbox, DatePicker, TextField } from '@wayste/sour-ui';
import { subDays } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';

export interface DispatchFormProps {
    disabled?: boolean;
    needsReviewStatus?: boolean;
}

const DispatchSubForm = ({ disabled, needsReviewStatus }: DispatchFormProps) => {
    const { control } = useFormContext();

    return (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <Controller
                name="dispatchSubForm.expectedDeliveryDate"
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'Delivery date is required',
                    },
                }}
                render={({ field, fieldState }) => (
                    <DatePicker
                        label="Delivery date"
                        required
                        value={field.value}
                        minDate={subDays(new Date(), 1)}
                        closeOnSelect
                        error={fieldState.error}
                        onChange={(value) => field.onChange(value)}
                        inputProps={{
                            required: true,
                            disabled: disabled,
                        }}
                    />
                )}
            />
            <Controller
                name="dispatchSubForm.sharedDeliveryNotes"
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'Delivery notes is required',
                    },
                }}
                render={({ field }) => (
                    <TextField
                        label="Delivery notes"
                        required
                        helperText={
                            needsReviewStatus
                                ? 'Remove --- waste info and everything after it. Use this info to verify the material.'
                                : undefined
                        }
                        inputProps={{
                            ...field,
                            disabled: disabled,
                        }}
                    />
                )}
            />
            <Controller
                name="dispatchSubForm.expectedPickupDate"
                control={control}
                render={({ field }) => (
                    <DatePicker
                        label="Pickup date"
                        value={field.value}
                        minDate={subDays(new Date(), 1)}
                        clearable
                        closeOnSelect
                        onChange={(value) => field.onChange(value)}
                        inputProps={{
                            disabled: disabled,
                        }}
                    />
                )}
            />
            <Controller
                name="dispatchSubForm.sharedPickupNotes"
                control={control}
                render={({ field }) => (
                    <TextField
                        label="Pickup notes"
                        inputProps={{
                            ...field,
                            disabled: disabled,
                        }}
                    />
                )}
            />
            <div className="md:col-span-2">
                <Controller
                    name="dispatchSubForm.sharedDeliveryNotesPrivate"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            label="Hauler only notes"
                            helperText="Notes only the hauler will see when we send emails"
                            inputProps={{
                                ...field,
                                disabled: disabled,
                            }}
                        />
                    )}
                />
            </div>
            <div className="md:col-span-2">
                <Controller
                    name="dispatchSubForm.noteOrder"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            label="Internal notes"
                            helperText="Notes only Sourgum team members will see"
                            inputProps={{
                                ...field,
                                disabled: disabled,
                            }}
                        />
                    )}
                />
            </div>
            <div className="md:col-span-2">
                <Controller
                    name="dispatchSubForm.recurringOnCall"
                    control={control}
                    render={({ field }) => (
                        <Checkbox
                            label="Recurring service (on call)"
                            inputProps={{
                                checked: field.value,
                                onChange: (e) => field.onChange(e.target.checked),
                                disabled: disabled,
                            }}
                        />
                    )}
                />
            </div>
            <div className="md:col-span-2">
                <Controller
                    name="dispatchSubForm.poNumber"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            label="PO Number"
                            helperText="If the customer has a Purchase Order (PO) Number"
                            inputProps={{
                                ...field,
                                disabled: disabled,
                            }}
                        />
                    )}
                />
            </div>
        </div>
    );
};

export default DispatchSubForm;
