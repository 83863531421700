import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Invoice, Material, Order } from '@alliance-disposal/transport-types';
import { Notification, Pricing } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';
import {
    formatEmailDestination,
    formatServiceAddress,
    formatUSPhoneNumberToE164,
    getDateFormat,
    getPrimaryCustomerContact,
    nullableFields,
} from '@wayste/utils';
import { addDays, format, isSameDay, isTomorrow } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { getPublicLinkShortCode } from '../../axios/wayste-backend';
import { UIContext } from '../../contexts';
import { useAuthToken } from '../../hooks/authhook';
import {
    ccRate,
    channelTypesEnums,
    createOrderConfirmationEmailSendGrid,
    createSourgumPricingSnapshot,
    generateOrderFirstInvoice,
    getCustomerToAndCCEmails,
    getOrderPricing,
    leadStatusTypesEnums,
    paymentMethodsEnums,
    paymentTermsEnums,
    routes,
} from '../../utils';
import { accountExecutives, accountManagers } from '../../utils/shared-types';
import OrderCreateForm from './OrderCreateForm';
import { OrderCreateFormProps, OrderCreateFormStartingValues, initialValues } from './order-create-form-props';

type OrderCreateLocationProps = {
    startingValues: OrderCreateFormStartingValues;
    fromLead: string;
};

const OrderCreateContainer = () => {
    const client = useWaysteClient();
    const userProfile = client.user().get();
    const { showFlash } = useContext(UIContext);
    const history = useHistory();
    const location = useLocation<OrderCreateLocationProps>();
    const [zonePricing, setZonePricing] = useState<Pricing.PricingTransport | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [startingValues, setStartingValues] = useState<OrderCreateFormStartingValues | null>(null);
    const [fromLead, setFromLead] = useState<string | null>(null);
    const [oldCustomer, setOldCustomer] = useState<Customer.AllianceCustomerTransport | null>(null);
    const { token } = useAuthToken();
    const methods = useForm<OrderCreateFormProps>({
        mode: 'all',
        defaultValues: initialValues,
        shouldFocusError: true,
    });
    const { handleSubmit, getValues, watch, trigger, setValue } = methods;
    const watchFinalPrice = watch('pricingSubForm.finalPrice');
    const watchPaymentInfo = watch('paymentSubForm.paymentInfo');

    useEffect(() => {
        if (location.state) {
            if (location?.state?.startingValues) setStartingValues(location.state.startingValues);
            if (location.state.fromLead) {
                setFromLead(location.state.fromLead);
            }
        }
    }, []);

    const handleResetLead = () => {
        if (fromLead === 'create') setFromLead(null);
    };

    const handleCreateLead = async (values: OrderCreateFormProps, customerID?: string, confirmedChannel?: string) => {
        const dataCopy = JSON.parse(JSON.stringify(values));
        const leadObj = {
            firstName: dataCopy.contacts[0].firstName,
            email: dataCopy.contacts[0].email,
            phoneNumber: dataCopy.contacts[0].phoneNumber ? formatUSPhoneNumberToE164(dataCopy.contacts[0].phoneNumber) : null,
            customerNotes: '',
            requestedSize: {
                size: Number(values.pricingSubForm.expectedSize),
                type: '',
            },
            requestedMaterial: values.pricingSubForm.material,
            type: 'rollOff',
            searchParams: dataCopy.searchParams || startingValues?.paymentSubForm?.searchParams || '',
            referringUrl: '',
            formUrl: '',
            channel: confirmedChannel || startingValues?.customerSubForm?.channel || '',
            allianceCustomerID: customerID || null,
            status: customerID ? leadStatusTypesEnums.won : leadStatusTypesEnums.open,
            needsAttention: false,
            serviceLocation: values.pricingSubForm.serviceLocation.address.city
                ? values.pricingSubForm.serviceLocation
                : startingValues?.pricingSubForm?.serviceLocation,
            requestedDeliveryDate: null,
        };

        const leadPayload = { ...leadObj };
        const leadResponse = await client.customer().adminPortal.leads.create(leadPayload);

        if (leadResponse) {
            const leadID = leadResponse?.id;
            const payload: Customer.AllianceLeadQuoteCreateTransport = {
                material: values.pricingSubForm.material,
                size: {
                    size: Number(values.pricingSubForm.expectedSize),
                    type: '',
                },
                cc: false,
                taxable: values.pricingSubForm.tax,
                taxRate: values.pricingSubForm.taxRate,
                tonLimit: Number(values.pricingSubForm.weightLimit),
                ccRate: ccRate,
                overage: Number(values.pricingSubForm.overage),
                price: Number(values.pricingSubForm.price),
                priceType: values.pricingSubForm.priceType,
                rentPeriod: { value: Number(values.pricingSubForm.adjustedRentalPeriod), unit: 'DAYS' },
                rentExtensionFee: Number(values.pricingSubForm.rentExtensionFee),
                pricingZoneID: zonePricing?.id,
            };

            await client.customer().adminPortal.leads.quote.create(leadID, payload);

            await client.customer().adminPortal.leads.contactEvent.create(leadID, {
                date: new Date().toISOString(),
                medium: 'phone',
            });

            return;
        }
    };

    const handleUpdateLead = async (customerID: string, confirmedChannel: string, values: OrderCreateFormProps) => {
        if (fromLead === 'create') {
            return await handleCreateLead(values, customerID, confirmedChannel);
        }
        if (fromLead && fromLead !== 'create') {
            return await client.customer().adminPortal.leads.update(fromLead, {
                allianceCustomerID: customerID,
                status: leadStatusTypesEnums.won,
            });
        }
        const primaryContact = getPrimaryCustomerContact(values.customerSubForm as Customer.AllianceCustomerTransport);
        if (primaryContact?.email) {
            const emailQuery = await client.customer().adminPortal.leads.query({
                email: primaryContact.email.trim().toLowerCase(),
                status: 'open',
            });

            if (emailQuery.results.length >= 1) {
                for (const emailItem of emailQuery.results) {
                    await client.customer().adminPortal.leads.update(emailItem.id, {
                        status: leadStatusTypesEnums.won,
                        allianceCustomerID: customerID,
                        serviceLocation: values.pricingSubForm.serviceLocation,
                    });
                }
                return true;
            }
        }
        if (primaryContact?.phoneNumber) {
            const phoneNumber = formatUSPhoneNumberToE164(primaryContact.phoneNumber);
            const phoneQuery = await client.customer().adminPortal.leads.query({
                phoneNumber: phoneNumber,
                status: 'open',
            });

            if (phoneQuery.results.length >= 1) {
                for (const phoneItem of phoneQuery.results) {
                    await client.customer().adminPortal.leads.update(phoneItem.id, {
                        status: leadStatusTypesEnums.won,
                        allianceCustomerID: customerID,
                        serviceLocation: values.pricingSubForm.serviceLocation,
                    });
                }
                return true;
            }
        }
        return null;
    };

    const handleBackButtonClick = (xClicked?: boolean) => {
        if (!xClicked || Boolean(watchPaymentInfo)) return;
        if (fromLead) {
            const values = getValues();
            const primaryContact = getPrimaryCustomerContact(values.customerSubForm as Customer.AllianceCustomerTransport);
            if (
                fromLead === 'create' &&
                (values.pricingSubForm.serviceLocation?.address?.city || startingValues?.pricingSubForm?.serviceLocation?.address?.city) &&
                (primaryContact?.phoneNumber || primaryContact?.email)
            ) {
                handleCreateLead(values);
            }
            history.go(-2);
            return;
        }
        history.goBack();
    };

    const handleGetPricing = async (
        location: {
            lat: number;
            lng: number;
            zip?: string;
            state: string;
        },
        customerID?: string,
    ) => {
        const results = await getOrderPricing(client, location, customerID);
        if (results === undefined) {
            //only check for undefined, null means no results, undefined means error
            alert('An error has occurred. Get a SAP Dev');
        } else {
            setZonePricing(results);
            setValue('pricingSubForm.taxRate', results?.actualTaxRate || 0);
        }
    };

    const generatePaymentLink = async (values: OrderCreateFormProps, receivableID: string) => {
        if (!values.paymentSubForm.paymentInfo && values.paymentSubForm.paymentMethod === 'creditCard') {
            const response = await getPublicLinkShortCode(receivableID, token);
            if (response.status === 'error') return null;
            return response?.data?.key;
        }
        return null;
    };

    const pdfToBase64 = (blob: Blob): Promise<string | ArrayBuffer | null> => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                const base64data = reader.result;
                resolve(base64data);
            };
        });
    };

    const handleSendEmail = async (
        values: OrderCreateFormProps,
        order: Order.AllianceOrderTransport,
        customer: Customer.AllianceCustomerTransport,
        receivable: Invoice.ReceivableTransport,
    ): Promise<boolean> => {
        let contactEmailsDispatch;
        try {
            contactEmailsDispatch = getCustomerToAndCCEmails('dispatch', customer, order.serviceLocation.address);
        } catch (error) {
            alert('Emails not sent, missing dispatch contact. Get a SAP dev');
            return false;
        }

        const emailData = createOrderConfirmationEmailSendGrid(
            {
                contact: contactEmailsDispatch.toContact || undefined,
                expectedPickupDate: order.expectedPickupDate || undefined,
                emailMessage: values.paymentSubForm.emailMessage || undefined,
                serviceLocation: order.serviceLocation,
                expectedDeliveryDate: order.requestedDeliveryDate,
                sharedDeliveryNotes: order.sharedDeliveryNotes || '',
                expectedSize: order.requestedSize.size,
                adjustedRentalPeriod: order.originalRentalPeriod.value,
                rentExtensionFee: Number(order.rentExtensionFee),
                overage: order.overage || undefined,
                material: order.material as Material,
                weightLimit: order.weightLimit?.value || '',
            },
            watchFinalPrice,
            userProfile,
            zonePricing?.prohibitedItems ? zonePricing.prohibitedItems.join(', ') : '',
            order.orderNumber?.toString() || '',
        );
        const payment_link = await generatePaymentLink(values, receivable.id);
        emailData.payment_link = payment_link || '';
        const destination = formatEmailDestination(
            contactEmailsDispatch.to,
            contactEmailsDispatch.toContact.firstName || '',
            contactEmailsDispatch.cc,
        );
        try {
            await client.notification().adminPortal.createInstantNotification({
                handler: 'sendgrid',
                topic: 'order-confirmation-ro',
                destination,
                body: JSON.stringify(emailData),
            });
        } catch (error) {
            console.warn('emailData error: ', error);
            alert('An error occurred sending the Confirmation Email. Touch nothing and get a SAP dev');
        }
        if (
            values.paymentSubForm.paymentTerm !== 'onCharge' &&
            !values.paymentSubForm.paymentInfo &&
            values.paymentSubForm.issueInvoice === 'NOW'
        ) {
            try {
                const contactEmailsBilling = getCustomerToAndCCEmails('billing', customer, order.serviceLocation.address);
                const invoicePDFResponse = await client.invoice().adminPortal.pdf.fetch(receivable.id);
                const blob = new Blob([invoicePDFResponse], { type: 'application/pdf' });
                const invoiceBlob = await pdfToBase64(blob);
                if (!invoiceBlob) {
                    console.warn('handleSendEmail error !invoiceBlob: ', invoiceBlob, blob, invoicePDFResponse, receivable.id);
                    alert('An error occurred sending the Invoice. Touch nothing and get a SAP dev');
                } else {
                    const invoiceEmailData: Notification.SendGrid.InvoiceIntermediary = {
                        first_name: contactEmailsBilling.toContact.firstName || customer.companyName || '',
                        receipt_invoice: 'Invoice',
                        order_number: order.orderNumber?.toString() || '',
                        invoice_number: `${order.orderNumber} - 1`,
                        payment_link: payment_link || '',
                    };
                    if (invoiceBlob) {
                        try {
                            await client.notification().adminPortal.createInstantNotification({
                                handler: 'sendgrid',
                                topic: 'invoice-intermediary',
                                body: JSON.stringify(invoiceEmailData),
                                destination: formatEmailDestination(
                                    contactEmailsBilling.to,
                                    contactEmailsBilling.toContact.firstName || '',
                                    contactEmailsBilling.cc || [],
                                ),
                                directAttachments: [
                                    {
                                        content: (invoiceBlob as string).split(',')[1],
                                        type: 'application/pdf',
                                        filename: `Sourgum Invoice.pdf`,
                                        disposition: 'attachment',
                                    },
                                ],
                            });
                        } catch (error) {
                            console.warn('invoiceEmailData error: ', error);
                            alert('An error occurred sending the Invoice Email. Touch nothing and get a SAP dev');
                        }
                    } else {
                        console.warn('invoiceBlob did not create. Debug why');
                        alert('Touch nothing and get a SAP dev to inspect console');
                    }
                }
            } catch (error) {
                alert('Invoice email not sent, missing billing contact. Get a SAP dev');
            }
        }
        if (
            isSameDay(new Date(), values.dispatchSubForm.expectedDeliveryDate as Date) ||
            (isTomorrow(values.dispatchSubForm.expectedDeliveryDate as Date) && new Date().getHours() >= 9)
        ) {
            const deliveryTomorrowEmailData: Notification.SendGrid.RoDeliveryTomorrow = {
                first_name: contactEmailsDispatch.toContact.firstName || '',
                delivery_date: format(new Date(values.dispatchSubForm.expectedDeliveryDate), 'EEEE MM/dd/yy'),
                service_location: formatServiceAddress(order.serviceLocation.address),
                prohibited_items_string: zonePricing?.prohibitedItems ? zonePricing.prohibitedItems.join(', ') : '',
            };
            try {
                await client.notification().adminPortal.createInstantNotification({
                    destination: formatEmailDestination(
                        contactEmailsDispatch.to,
                        contactEmailsDispatch.toContact.firstName || '',
                        contactEmailsDispatch.cc || [],
                    ),
                    topic: 'ro-delivery-tomorrow',
                    handler: 'sendgrid',
                    body: JSON.stringify(deliveryTomorrowEmailData),
                });
            } catch (error) {
                console.warn('deliveryTomorrowEmailData error: ', error);
                alert('An error occurred sending the Delivery Tomorrow Email. Touch nothing and get a SAP dev');
            }
        }
        return true;
    };

    // Recursive update of contacts
    const updateAllContacts = async (
        contacts: Customer.AllianceCustomerContactTransport[],
        oldContacts: Customer.AllianceCustomerContactTransport[],
        customerID: string,
    ): Promise<'error' | 'success'> => {
        // categorizes contacts into add, remove, and update
        const getContactsToUpdate = (
            contacts: Customer.AllianceCustomerContactTransport[],
            oldContacts: Customer.AllianceCustomerContactTransport[],
        ) => {
            const addContacts = contacts.filter((contact) => !contact.id);
            const updateContacts = contacts.filter((contact) => {
                const oldContact = oldContacts.find(
                    (oldContact: Customer.AllianceCustomerContactTransport) => oldContact.id === contact.id,
                );
                return oldContact && JSON.stringify(oldContact) !== JSON.stringify(contact);
            });
            const removeContacts = oldContacts.filter(
                (oldContact: Customer.AllianceCustomerContactTransport) =>
                    !contacts.find((contact: Customer.AllianceCustomerContactTransport) => contact.id === oldContact.id),
            );
            return { addContacts, removeContacts, updateContacts };
        };
        const { addContacts, removeContacts, updateContacts } = getContactsToUpdate(contacts, oldContacts);

        const addContactsPromises = addContacts.map((contact) => {
            try {
                return client.customer().adminPortal.contact.create(customerID, contact);
            } catch (error) {
                console.error('error in: ', error);
            }
        });
        const updateContactsPromises = updateContacts.map((contact) => {
            try {
                return client.customer().adminPortal.contact.update(customerID, contact.id, contact);
            } catch (error) {
                console.error('error in: ', error);
            }
        });
        const removeContactsPromises = removeContacts.map((contact: Customer.AllianceCustomerContactTransport) =>
            client.customer().adminPortal.contact.delete(customerID, contact.id),
        );

        try {
            await Promise.all([addContactsPromises, updateContactsPromises, removeContactsPromises]);
            return 'success';
        } catch (error) {
            console.warn('updateAllContacts error: ', error);
            alert('An error occurred updating contacts. Touch nothing and get a SAP dev');
            return 'error';
        }
    };

    const handleUpdateCustomer = async (
        values: OrderCreateFormProps,
        confirmedChannel: string,
    ): Promise<Customer.AllianceCustomerTransport | null> => {
        // Update customer's serviceLocations array
        let serviceLocations = values.customerSubForm.serviceLocations
            ? values.customerSubForm.serviceLocations
            : [values.pricingSubForm.serviceLocation];
        if (
            values.customerSubForm.serviceLocations &&
            (values.customerSubForm.serviceLocations.length === 0 ||
                !values.customerSubForm.serviceLocations.find(
                    (item) => formatServiceAddress(item.address) === formatServiceAddress(values.pricingSubForm.serviceLocation.address),
                ))
        ) {
            serviceLocations = [...values.customerSubForm.serviceLocations, values.pricingSubForm.serviceLocation];
        }
        const customerObj = {
            ...values.customerSubForm,
            contacts: values.customerSubForm.contacts.map((contact) => ({
                ...contact,
                phoneNumber: contact.phoneNumber ? formatUSPhoneNumberToE164(contact?.phoneNumber) : null,
            })),
            serviceLocations,
        };

        // Set AE & AM names if they exist for denomalization
        if (customerObj.accountRepID && !customerObj.accountRepName) {
            const ae = accountExecutives.find((item) => item.id === customerObj.accountRepID);
            if (ae) customerObj.accountRepName = `${ae?.firstName} ${ae?.lastName}`;
        }
        if (customerObj.accountManagerID && !customerObj.accountManagerName) {
            const am = accountManagers.find((item) => item.id === customerObj.accountManagerID);
            if (am) customerObj.accountManagerName = `${am?.firstName} ${am?.lastName}`;
        }

        // Update existing customer
        if ('id' in customerObj && customerObj.id) {
            const payload = {
                ...customerObj,
            };
            const oldContacts = oldCustomer?.contacts;
            const contacts = customerObj.contacts;
            try {
                const response = await client.customer().adminPortal.update(customerObj.id, nullableFields(payload, 'balanceLimit'));
                if (oldContacts) {
                    const status = await updateAllContacts(contacts, oldContacts, response.id);
                    if (status === 'error') {
                        return null;
                    }
                }
                return response;
            } catch (error) {
                console.error('error: ', error);
                return null;
            }
            // Create new customer
        } else {
            customerObj.overallExperience = 'Positive';
            customerObj.channel = confirmedChannel;
            customerObj.defaultPaymentSettings = {
                paymentMethod: values.paymentSubForm.paymentMethod || paymentMethodsEnums.creditCard,
                paymentTerm: values.paymentSubForm.paymentTerm || paymentTermsEnums.onCharge,
                allowedPaymentMethod: [paymentMethodsEnums.creditCard],
            };

            const contacts = customerObj.contacts;

            const payload: Customer.AllianceCustomerCreateInput = {
                ...customerObj,
                notes: '',
                reviewed: false,
                doNotService: false,
            };

            try {
                const response = await client.customer().adminPortal.create(nullableFields(payload, 'balanceLimit'));
                const promises = contacts.map(async (contact) => {
                    return await client.customer().adminPortal.contact.create(response.id, contact);
                });

                const contactsResponse = await Promise.all(promises);

                // find the the customer with the most contacts
                const customerWithMostContacts = contactsResponse.reduce((accumulator, customer) => {
                    if (customer.contacts.length > accumulator.contacts.length) {
                        return customer;
                    }
                    return accumulator;
                }, contactsResponse[0]);
                try {
                    const customerResponseWithCustomerNumber = await client.customer().adminPortal.fetch(customerWithMostContacts.id);
                    return customerResponseWithCustomerNumber;
                } catch (error) {
                    console.warn('error: ', error);
                    return null;
                }
            } catch (error) {
                console.error('error: ', error);
                return null;
            }
        }
    };

    const handleCreateOrder = async (
        values: OrderCreateFormProps,
        customer: Customer.AllianceCustomerTransport,
    ): Promise<Order.AllianceOrderTransport | null> => {
        const primaryContact = getPrimaryCustomerContact(customer);
        // TS check
        if (!values.pricingSubForm.material) {
            showFlash('No Material Selected', 'warning');
            return null;
        }
        // Construct order for DB
        const orderObj: Order.AllianceOrderCreateInput = {
            customerCompanyName: customer.companyName || undefined,
            customerAccountNumber: customer.customerNumber.toString(),
            customerName: `${primaryContact.firstName} ${primaryContact.lastName}`,
            poNumber: values.dispatchSubForm.poNumber,
            status: 'UNASSIGNED',
            allianceCustomerID: customer.id,
            requestedDeliveryDate: getDateFormat(values.dispatchSubForm.expectedDeliveryDate).toISOString(),
            requestedPickupDate: values.dispatchSubForm.expectedPickupDate
                ? getDateFormat(values.dispatchSubForm.expectedPickupDate).toISOString()
                : undefined,
            originalRentalPeriod: {
                value: Number(values.pricingSubForm.adjustedRentalPeriod),
                unit: 'DAYS',
            },
            expirationDate: addDays(
                new Date(values.dispatchSubForm.expectedDeliveryDate),
                Number(values.pricingSubForm.adjustedRentalPeriod),
            ).toISOString(),
            sharedDeliveryNotes: values.dispatchSubForm.sharedDeliveryNotes,
            sharedPickupNotes: values.dispatchSubForm.sharedPickupNotes,
            sharedDeliveryNotesPrivate: values.dispatchSubForm.sharedDeliveryNotesPrivate,
            material: values.pricingSubForm.material,
            recurringOnCall: values.dispatchSubForm.recurringOnCall || false,
            ccRate: ccRate,
            overage: values.pricingSubForm.overage || undefined,
            dumpRate: values.pricingSubForm.dumpRate || 0,
            rentExtensionFee: Number(values.pricingSubForm.rentExtensionFee),
            taxRate: values.pricingSubForm.taxRate,
            priceType: values.pricingSubForm.priceType,
            paymentMethod: values.paymentSubForm.paymentMethod,
            paymentTerm: values.paymentSubForm.paymentTerm,
            serviceLocation: values.pricingSubForm.serviceLocation,
            requestedSize: { size: Number(values.pricingSubForm.expectedSize), type: 'OPEN_TOP' },
            weightLimit: values.pricingSubForm.weightLimit === '' ? null : { value: values.pricingSubForm.weightLimit, unit: 'TONS' },
            // TODO remove this when backend is updated
            haulerDumpRate: 0,
            haulerHaulRate: 0,
            checkoutType: 'AGENT',
            sourgumPricingSnapshot: createSourgumPricingSnapshot(values.pricingSubForm, zonePricing),
        };
        try {
            const orderResponse = await client.order().adminPortal.create(orderObj);
            // Have to refetch because backend isn't sending orderNumber back with creation
            const newOrderRefetchResponse = await client.order().adminPortal.fetch(orderResponse.id);
            return newOrderRefetchResponse;
        } catch (orderError) {
            console.warn('orderError: ', orderError);
            return null;
        }
    };

    const handleReceivableCreate = async (
        values: OrderCreateFormProps,
        order: Order.AllianceOrderTransport,
        customer: Customer.AllianceCustomerTransport,
    ): Promise<Invoice.ReceivableTransport | null> => {
        const invoiceObj = generateOrderFirstInvoice(
            Number(values.pricingSubForm.price),
            values.pricingSubForm.priceType,
            Number(values.pricingSubForm.weightLimit),
            Number(values.pricingSubForm.expectedSize),
            Number(values.pricingSubForm.dumpRate),
            values.pricingSubForm.tax,
            values.customerSubForm.taxExempt ? 0 : values.pricingSubForm.taxRate,
            values.pricingSubForm.cc,
            values.paymentSubForm.paymentTerm,
            values.pricingSubForm.material as Material,
            values.pricingSubForm.otherLineItems,
            values.paymentSubForm.issueInvoice,
        );
        const shouldIssueInvoiceNow =
            customer.invoiceIssueTerm === 'IMMEDIATE' || (customer.invoiceIssueTerm === 'MANUAL' && !values.paymentSubForm.paymentInfo);
        const primaryContact = getPrimaryCustomerContact(customer);
        const receivableObj: Invoice.ReceivableCreateTransport = {
            customerID: customer.id,
            customerName: `${primaryContact.firstName} ${primaryContact.lastName}`,
            customerCompanyName: customer.companyName || undefined,
            invoiceDetails: {
                ...invoiceObj,
                void: false,
                orderID: order.id,
                issueDate: shouldIssueInvoiceNow && invoiceObj.issueDate ? invoiceObj.issueDate.toISOString() : undefined,
                dueDate: shouldIssueInvoiceNow && invoiceObj.dueDate ? invoiceObj.dueDate.toISOString() : undefined,
                orderNumber: order.orderNumber?.toString(),
                orderServiceLocation: order.serviceLocation,
            },
        };

        try {
            const receivableResponse = await client.invoice().adminPortal.receivable.create(receivableObj);
            if (values.paymentSubForm.paymentInfo) {
                try {
                    await client.invoice().adminPortal.payment.create(receivableResponse?.invoiceDetails.id, {
                        ...values.paymentSubForm.paymentInfo,
                        paymentReceivedDate: values.paymentSubForm.paymentInfo.paymentReceivedDate.toISOString(),
                        paymentIdentifier: values.paymentSubForm.paymentInfo.paymentIdentifier || undefined,
                        stripeChargeID: values.paymentSubForm.paymentInfo.stripeChargeID || undefined,
                    });
                    return receivableResponse;
                } catch (error) {
                    console.warn('paymentResponse error: ', error);
                    showFlash('An Error Occurred Creating Payment', 'warning');
                }
            }
            return receivableResponse;
        } catch (error) {
            console.warn('receivableResponse error: ', error);
            return null;
        }
    };

    const handleFormSubmit = async (values: OrderCreateFormProps) => {
        setIsLoading(true);
        const formIsValid = await trigger();
        if (!formIsValid) {
            setIsLoading(false);
            showFlash('Form is not valid', 'warning');
            return;
        }
        // Set confirmedChannel for customer and lead
        let confirmedChannel = values.customerSubForm.channel;
        if (values.paymentSubForm.searchParams) {
            if (values.paymentSubForm.searchParams.includes('utm_medium=organic')) confirmedChannel = channelTypesEnums.internet;
            if (values.paymentSubForm.searchParams.includes('msclkid=')) confirmedChannel = channelTypesEnums.bingAds;
            if (values.paymentSubForm.searchParams.includes('gclid=')) confirmedChannel = channelTypesEnums.adwords;
        }

        // Create or update customer
        const customerResponse = await handleUpdateCustomer(values, confirmedChannel);
        if (!customerResponse?.id) {
            alert('Something went wrong. Touch Nothing. Get an SAP Dev');
            setIsLoading(false);
            return;
        }
        const customerID = customerResponse.id;

        // Create or update lead
        try {
            await handleUpdateLead(customerID, confirmedChannel, values);
        } catch (error) {
            showFlash('An Error Occurred Updating the Lead', 'warning');
        }

        const orderResponse = await handleCreateOrder(values, customerResponse);

        if (!orderResponse?.id) {
            alert('An error occurred creating the order. Touch nothing and get a SAP dev');
            setIsLoading(false);
            return;
        }

        if (values.dispatchSubForm.noteOrder) {
            try {
                await client.internalTicket().adminPortal.create({
                    entityID: orderResponse.id,
                    entityType: 'sourgum-order',
                    note: values.dispatchSubForm.noteOrder,
                    tags: [],
                });
            } catch (error) {
                console.warn('noteOrder error: ', error);
                showFlash('An Error Occurred Creating Internal Ticket', 'warning');
            }
        }

        const receivableResponse = await handleReceivableCreate(values, orderResponse, customerResponse);
        if (!receivableResponse?.id) {
            console.error('receivableResponse: ', receivableResponse);
            alert('An error occurred creating the invoice. Touch nothing and get a SAP dev');
            setIsLoading(false);
            return;
        }

        if (values.paymentSubForm.sendEmail) {
            await handleSendEmail(values, orderResponse, customerResponse, receivableResponse);
        }
        showFlash('Order Successfully Created', 'success');
        history.push(routes.orders.details(orderResponse.id), {});

        setIsLoading(false);
    };

    return (
        <Dialog open={true} onClose={handleBackButtonClick} styledTitle="Create Dispatch Ticket" fullScreen>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <OrderCreateForm
                        user={userProfile}
                        zonePricing={zonePricing}
                        onGetPricing={handleGetPricing}
                        isLoading={isLoading}
                        startingValues={startingValues}
                        onResetLead={handleResetLead}
                        setOldCustomer={setOldCustomer}
                    />
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default OrderCreateContainer;
