import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { getDispatchEmailsString } from '@wayste/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { sendEmail } from '../../axios/ses';
import { createHaulerOrderCancellationEmail, routes } from '../../utils';
import OrderAssignHauler from './OrderAssignHauler';
import OrderEnterTicket from './OrderEnterTicket';
import OrderReadyPickUp from './OrderReadyPickup';
import OrderStatusPopper from './OrderStatusPopper';

interface Props {
    order: Order.AllianceOrderTransport & {
        receivables?: Invoice.ReceivableTransport[];
        payables?: Invoice.PayableTransport[];
    };
    navigateOnly?: boolean;
    className?: string;
}

const OrderStatusChanger = ({ order, navigateOnly, className }: Props) => {
    const client = useWaysteClient();
    const history = useHistory();
    const location = useLocation();
    const { setShowToast, useConfirmationDialog, setShowAlert } = useSourContext();
    const { getConfirmation } = useConfirmationDialog();
    const [openModal, setOpenModal] = useState<Order.OrderStatus | null>(null);

    const fetchHauler = async (id: string) => {
        try {
            const response = await client.vendorService().fetchById(id);

            return { status: 'success', data: { ...response.data } };
        } catch (error) {
            console.warn('fetchHauler error for ID: ', id, error);
            throw error;
        }
    };

    const handleCloseModal = () => {
        setOpenModal(null);
    };

    const handleDumpedSubmitSuccessful = (newStatus: Order.OrderStatus) => {
        if (newStatus === 'COMPLETED' && !location.pathname.includes(routes.billing.list)) {
            history.push({ pathname: routes.billing.list, search: `?id=${order.id}` });
        } else {
            handleCloseModal();
            history.push({ pathname: routes.billing.list });
            setTimeout(() => {
                history.push({ pathname: routes.billing.list, search: `?id=${order.id}` });
            }, 100);
        }
    };

    const handleBackToOpen = async () => {
        const confirmed = await getConfirmation({
            title: 'Reset to Open?',
            message: 'Are you sure you want to reset this order to open?',
        });
        if (confirmed) {
            await client.order().adminPortal.update(order.id, {
                status: 'UNASSIGNED',
                haulerConfirmedDelivery: false,
                haulerDumpRate: null,
                haulerHaulRate: null,
                haulerID: null,
                haulerPricingSnapshot: null,
            });
            const sendEmailConfirmed = await getConfirmation({
                title: 'Send Email to Hauler?',
                message: 'Do you want to send a cancellation email to the hauler?',
                cancelText: 'No',
                confirmText: 'Yes',
            });
            if (sendEmailConfirmed) {
                const haulerResponse = await fetchHauler(order?.haulerID || '');
                if (haulerResponse.status === 'error') {
                    console.warn('handleChange haulerResponse error: ', haulerResponse);
                    alert('Something went wrong get AAP Dev');
                    return;
                }
                const hauler = haulerResponse.data;
                if (!hauler) return;
                const haulerEmailData = createHaulerOrderCancellationEmail(hauler, order);
                const dispatchEmailsString = getDispatchEmailsString(hauler?.contacts);
                if (dispatchEmailsString) {
                    try {
                        await sendEmail('send-email', haulerEmailData);
                    } catch (error) {
                        setShowToast({ message: 'Error Sending Hauler Email', severity: 'warning' });
                    }
                } else {
                    setShowAlert({ message: 'No hauler email exists' });
                }
            }
            setShowAlert({
                message: 'Order reset to open. Redirecting you to payables. Please void them.',
                handleOnClose: () => {
                    history.push(routes.billing.details(order.id, 'bill'));
                },
            });
            handleCloseModal();
        }
    };

    const handleChange = async (newStatus: Order.OrderStatus) => {
        switch (newStatus) {
            case 'UNASSIGNED':
                if (order.status === 'UNASSIGNED') break;
                handleBackToOpen();
                break;
            case 'ASSIGNED':
                setOpenModal('ASSIGNED');
                break;
            case 'DELIVERED':
                await client.order().adminPortal.update(order.id, {
                    haulerID: order.haulerID, // need this for accurate wayste lite info on backend
                    status: 'DELIVERED',
                });
                handleCloseModal();
                break;
            case 'READY_FULL_PICKUP':
                setOpenModal('READY_FULL_PICKUP');
                break;
            case 'DUMPED':
                if (order.status !== 'DUMPED') {
                    await client.order().adminPortal.update(order.id, {
                        status: 'DUMPED',
                    });
                }
                setOpenModal('DUMPED');
                break;
            default:
                alert('missing case');
                break;
        }
    };

    return (
        <>
            <OrderStatusPopper order={order} onChange={handleChange} navigateOnly={navigateOnly} className={className} />
            {openModal === 'ASSIGNED' && (
                <OrderAssignHauler open={true} onCancel={handleCloseModal} onSubmitSuccessful={handleCloseModal} order={order} />
            )}
            {openModal === 'READY_FULL_PICKUP' && <OrderReadyPickUp open={true} order={order} onCancel={handleCloseModal} />}
            {openModal === 'DUMPED' && (
                <OrderEnterTicket open={true} order={order} onCancel={handleCloseModal} onSubmitSuccessful={handleDumpedSubmitSuccessful} />
            )}
        </>
    );
};

export default OrderStatusChanger;
