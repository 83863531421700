import { Customer, ServiceLocationTransport } from '@alliance-disposal/transport-types';
import { paymentMethodsEnums, paymentTermsEnums } from '../../utils/shared-types';
import { type OrderPricingBlockFormProps } from '../OrderPricingBlock';

export const initialContact: Customer.AllianceCustomerContactTransport = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    department: 'primary',
    notes: '',
    sendBillingEmails: true,
    sendDispatchEmails: true,
    primaryContact: true,
    id: '',
};

export const customerStartData: Customer.AllianceCustomerCreateInput & {
    contacts: Customer.AllianceCustomerContactTransport[];
    sameAsService: boolean;
} = {
    billingAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
    },
    channel: '',
    companyName: '',
    contacts: [{ ...initialContact }],
    taxExempt: false,
    type: 'unknown',
    sameAsService: false,
    motivation: '',
    serviceLocations: [],
    stripeId: '',
    credit: 0,
    leadID: '',
    accountManagerID: '',
    accountRepID: '',
    overallExperience: 'Positive',
    notes: '',
    doNotService: false,
    inCollections: false,
    reviewed: false,
    defaultPaymentSettings: {
        paymentTerm: 'onCharge',
        paymentMethod: 'creditCard',
    },
};

export const initialValues: OrderCreateFormProps = {
    customerSubForm: {
        ...customerStartData,
    },
    pricingSubForm: {
        serviceLocation: {
            address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: '',
                country: 'US',
            },
            county: '',
            coordinates: { lat: 0, lng: 0 },
        },
        material: '',
        priceType: 'ton',
        expectedSize: '',
        weightLimit: '',
        adjustedRentalPeriod: '',
        rentExtensionFee: '',
        tax: true,
        taxRate: 0,
        cc: true,
        price: '',
        finalPrice: 0,
        otherLineItems: [],
        dumpRate: null,
        overage: '',
        maxRentalDaysAllowed: null,
        coupon: '',
    },
    dispatchSubForm: {
        expectedDeliveryDate: '',
        sharedDeliveryNotes: '',
        expectedPickupDate: '',
        sharedPickupNotes: '',
        sharedDeliveryNotesPrivate: '',
        noteOrder: '',
        recurringOnCall: false,
        poNumber: '',
    },
    paymentSubForm: {
        websiteSale: false,
        searchParams: '',
        paymentTerm: 'onCharge',
        paymentMethod: 'creditCard',
        issueInvoice: 'NOW',
        paymentInfo: null,
        sendEmail: false,
        emailMessage: '',
        emailStaticHtml: '',
        emailStaticText: '',
    },
};

export type OrderCreateFormProps = {
    customerSubForm:
        | (Customer.AllianceCustomerTransport & { sameAsService: boolean })
        | (Customer.AllianceCustomerCreateInput & {
              contacts: Customer.AllianceCustomerContactTransport[];
              sameAsService: boolean;
              id?: string;
              customerNumber?: number;
          });
    pricingSubForm: {
        serviceLocation: ServiceLocationTransport;
        dumpRate: number | null;
        maxRentalDaysAllowed: number | null;
        finalPrice: number;
    } & OrderPricingBlockFormProps;
    dispatchSubForm: {
        expectedDeliveryDate: Date | '';
        sharedDeliveryNotes: string;
        expectedPickupDate: Date | '';
        sharedPickupNotes: string;
        sharedDeliveryNotesPrivate: string;
        noteOrder: string;
        recurringOnCall: boolean;
        poNumber: string;
    };
    paymentSubForm: {
        websiteSale: boolean;
        searchParams: string;
        paymentTerm: keyof typeof paymentTermsEnums;
        paymentMethod: keyof typeof paymentMethodsEnums;
        issueInvoice: 'NOW' | 'DRAFT';
        paymentInfo: null | {
            paymentReceivedDate: Date;
            amount: number;
            paymentMethod: keyof typeof paymentMethodsEnums;
            paymentIdentifier: string | null;
            stripeChargeID: string | null;
        };
        sendEmail: boolean;
        emailMessage: string;
        emailStaticHtml: string;
        emailStaticText: string;
    };
};

export type OrderCreateFormStartingValues<T = OrderCreateFormProps> = {
    [P in keyof T]?: T[P] extends (infer U)[]
        ? OrderCreateFormStartingValues<U>[]
        : T[P] extends ReadonlyArray<infer U>
          ? ReadonlyArray<OrderCreateFormStartingValues<U>>
          : OrderCreateFormStartingValues<T[P]>;
};
