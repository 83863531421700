import { Customer } from '@alliance-disposal/transport-types';
import { DataGrid, DataGridProps } from '@wayste/sour-ui';
import { formatServiceAddress, getActualWeightDumped, getDateFormat, moneyFormatter, titleCase } from '@wayste/utils';
import { PlusIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import DetailsCardWrapper from '../../../components/DetailsCardWrapper';
import { OrderCreateFormStartingValues } from '../../../components/OrderCreate/order-create-form-props';
import { OrderStatus, OrderStatusText, routes } from '../../../utils';
import type { AllianceOrderWithReceivables } from './customer-details';

type CustomerOrdersProps = {
    customer: Customer.AllianceCustomerTransport;
    orders: AllianceOrderWithReceivables[];
};

const CustomerOrders = ({ customer, orders }: CustomerOrdersProps) => {
    const history = useHistory();
    const columns: DataGridProps<AllianceOrderWithReceivables>['columns'] = [
        {
            key: 'orderNumber',
            name: 'Order #',
        },
        {
            key: 'serviceLocation.address',
            name: 'Address',
            formatter: ({ row }) => formatServiceAddress(row.serviceLocation.address),
        },
        {
            key: 'expectedSize.size',
            name: 'Size',
            formatter: ({ row }) => row.expectedSize.size,
        },
        {
            key: 'material',
            name: 'Material',
        },
        {
            key: 'serviceLocation.county',
            name: 'County',
            formatter: ({ row }) => row.serviceLocation.county,
        },
        {
            key: 'vendorName',
            name: 'Hauler',
        },
        {
            key: 'servicePeriod',
            name: 'Serviced',
            formatter: ({ row }) =>
                `${format(new Date(getDateFormat(row.expectedDeliveryDate)), 'MM/dd/yy')} - ${
                    row.expectedPickupDate ? format(new Date(getDateFormat(row.expectedPickupDate)), 'MM/dd/yy') : ''
                }`,
        },
        {
            key: 'status',
            name: 'Status',
            formatter: ({ row }) => (
                <span
                    style={{
                        color: row.status === OrderStatus.CANCELLED ? 'red' : (undefined as string | undefined),
                    }}
                >
                    {titleCase(OrderStatusText[row.status as keyof typeof OrderStatusText])}
                </span>
            ),
        },
        {
            key: 'actualWeightDumped',
            name: 'Tons',
            formatter: ({ row }) => getActualWeightDumped(row),
        },
        {
            key: 'quoted',
            name: 'Quoted',
            formatter: ({ value }) => moneyFormatter(Number(value)),
        },
        {
            key: 'orderTotal',
            name: 'Total',
            formatter: ({ value }) => moneyFormatter(Number(value)),
        },
        {
            key: 'totalPaid',
            name: 'Paid',
            formatter: ({ row }) =>
                row.receivables.length > 0 &&
                row.receivables.every((orderInvoice) => orderInvoice.invoiceDetails.paidInFull || orderInvoice.invoiceDetails.void)
                    ? 'Yes'
                    : 'No',
        },
    ];

    return (
        <>
            <DetailsCardWrapper
                heading="Rolloff Orders"
                buttons={[
                    {
                        label: (
                            <>
                                <PlusIcon className="mr-1 size-5" /> Add Order
                            </>
                        ),
                        onClick: () => {
                            const startingValues: OrderCreateFormStartingValues = { customerSubForm: { ...customer } };
                            history.push(routes.orders.new, {
                                startingValues: startingValues,
                            });
                        },
                        disabled: customer.doNotService,
                    },
                ]}
            >
                <div style={{ margin: '-16px -20px -18px' }}>
                    <DataGrid rows={orders} columns={columns} onRowClick={(row) => history.push(routes.orders.details(row.id))} />
                </div>
            </DetailsCardWrapper>
        </>
    );
};

export default CustomerOrders;
