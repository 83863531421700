import { useEffect, useState } from 'react';
import { useCurrentAuthorityToken } from '@alliance-disposal/client';
import { Invoice, PaginatedResult } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Dialog } from '@wayste/sour-ui';
import axios from 'axios';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';

const JoesXXXLab = () => {
    const token = `Bearer ${useCurrentAuthorityToken()}`;
    const { godModeActive } = useSourContext();
    const [seconds, setSeconds] = useState(30);
    const [showAccessDenied, setShowAccessDenied] = useState(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [downloadableData, setDownloadableData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (godModeActive) {
            setShowAccessDenied(false);
        } else {
            setShowAccessDenied(true);
        }
    }, [godModeActive]);

    useEffect(() => {
        // Exit early if the countdown reaches zero
        if (seconds === 0) return;

        // Create a timeout that decrements the countdown every second
        const intervalId = setInterval(() => {
            setSeconds((seconds) => seconds - 1);
        }, 1000);

        // Clear the interval if the component is unmounted
        return () => clearInterval(intervalId);
    }, [seconds]); // Only re-run the effect if the seconds change

    const runDangerousFunction = async () => {
        setIsLoading(true);
        console.log('start');
        const exportData = [];
        const params: Invoice.GetPayablesQuery = {
            issuedAfter: '2024-04-01',
            issuedBefore: '2024-04-22',
            void: false,
            limit: 2600,
        };
        let counter = 1;
        try {
            const res: { data: PaginatedResult<Invoice.PayableTransport> } = await axios.get(
                `${import.meta.env.VITE_BASE_API_URL}/invoice/payable`,
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                    params,
                },
            );
            console.log('all data', res);
            for (const invoice of res.data.results) {
                for (const lineItem of invoice.invoiceDetails.lineItems) {
                    if (lineItem.totalPrice !== 0) {
                        const billDate = invoice.invoiceDetails.issueDate
                            ? format(new Date(invoice.invoiceDetails.issueDate), 'MM/dd/yyyy')
                            : '';
                        exportData.push({
                            'Bill No.': `${invoice.invoiceDetails.orderNumber} - ${invoice.invoiceDetails.invoiceNumber}`,
                            Vendor: invoice.vendorName,
                            'Bill Date': billDate,
                            Memo: invoice.invoiceDetails.orderNumber,
                            Amount: lineItem.totalPriceDollars,
                            'Category/Account': lineItem.itemName,
                            Type: 'Item Details',
                            Qty: 1,
                            Rate: lineItem.totalPriceDollars,
                            lineItemPairCode: counter,
                            paymentMethod: invoice.invoiceDetails.payments?.[0]?.paymentMethod || '',
                        });
                    }
                }
                counter = counter + 1;
            }
            console.log('exportedData: ', exportData);
            setDownloadableData(exportData);
        } catch (error) {
            console.warn('runDangerousFunction error: ', error);
        } finally {
            console.log('end');
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="flex h-full flex-col items-center justify-center">
                <div>
                    <Button className="btn-primary" onClick={runDangerousFunction} loading={isLoading}>
                        Never Push this button
                    </Button>
                </div>
                <div className="mt-6">
                    {downloadableData.length === 0 ? null : (
                        <CSVLink className="btn-secondary" data={downloadableData}>
                            Download Data
                        </CSVLink>
                    )}
                </div>
            </div>
            <Dialog open={showAccessDenied} onClose={() => null}>
                <div className="text-center">
                    <p>DELETING ALL ORDER DATA FOREVER IN</p>
                    <br />
                    <p className="pb-4">Only a SAP Dev can stop this now that is has begun...🏃</p>
                    {seconds > 0 ? (
                        <p className="text-red-500">Time remaining: {seconds} seconds</p>
                    ) : (
                        <p>
                            🎉 NOT 🎊 <br />
                            Don't touch things you're not supposed to 😡
                            <br />
                            Now get off of my page.
                        </p>
                    )}
                </div>
            </Dialog>
        </>
    );
};

export default JoesXXXLab;
