import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { SourFiltersOld, SourSearchOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { Loading } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { useFormContext } from 'react-hook-form';
import { UIContext } from '../../contexts';

export interface SelectCustomerSubForm {
    onSelect: (customer: Customer.AllianceCustomerTransport | null) => void;
}

const SelectCustomerSubForm = ({ onSelect }: SelectCustomerSubForm) => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const [isLoading, setIsLoading] = useState(false);
    const [customerPaymentStatuses, setCustomerPaymentStatuses] = useState<{
        openBalance: number | null;
        delinquentBalance: number | null;
        draftBalance: number | null;
    }>({ openBalance: null, delinquentBalance: null, draftBalance: null });

    const { watch } = useFormContext();
    const watchCustomerID = watch('customerSubForm.id');
    const watchFirstName = watch('customerSubForm.contacts.0.firstName');
    const watchLastName = watch('customerSubForm.contacts.0.lastName');
    const watchCompanyName = watch('customerSubForm.companyName');

    const handleGetDelInvoices = async (customerID: string) => {
        try {
            const response = await client
                .invoice()
                .adminPortal.receivable.query({ customerID, paidInFull: false, void: false, limit: 1000 });
            const results = response.results;
            const inDraft = results.filter((invoice) => invoice.invoiceDetails.status === 'DRAFT');
            const inDelinquent = results.filter(
                (invoice) => invoice.invoiceDetails.dueDate && new Date(invoice.invoiceDetails.dueDate) <= new Date(),
            );
            const openBalance = results.reduce((prevValue, currentValue) => {
                return prevValue + currentValue.invoiceDetails.remainingBalance;
            }, 0);
            const delinquentBalance = inDelinquent.reduce((prevValue, currentValue) => {
                return prevValue + currentValue.invoiceDetails.remainingBalance;
            }, 0);
            const draftBalance = inDraft.reduce((prevValue, currentValue) => {
                return prevValue + currentValue.invoiceDetails.remainingBalance;
            }, 0);
            setCustomerPaymentStatuses({ openBalance, delinquentBalance, draftBalance });
        } catch (error) {
            console.warn('error: ', error);
            showFlash('Error Fetching Customer Balances', 'warning');
        }
    };

    useEffect(() => {
        if (watchCustomerID) {
            handleGetDelInvoices(watchCustomerID);
        } else {
            setCustomerPaymentStatuses({ openBalance: null, delinquentBalance: null, draftBalance: null });
        }
    }, [watchCustomerID]);

    const handleSelect = async (customerID: string) => {
        setCustomerPaymentStatuses({ openBalance: null, delinquentBalance: null, draftBalance: null });
        setIsLoading(true);
        try {
            const customerResponse = await client.customer().adminPortal.fetch(customerID);
            onSelect(customerResponse);
        } catch (error) {
            console.warn(error);
            showFlash('Error Fetching Customer', 'warning');
        } finally {
            setIsLoading(false);
        }
    };

    // DEFINE ON NAVIGATE LOGIC
    const onNavigate = (_: unknown, recordID: string) => {
        handleSelect(recordID);
    };

    const handleSelectNewCustomer = () => {
        onSelect(null);
        setCustomerPaymentStatuses({ openBalance: null, delinquentBalance: null, draftBalance: null });
    };

    return (
        <div className="flex flex-col gap-6">
            <div className="flex flex-col items-end md:flex-row md:items-center">
                {watchCustomerID ? (
                    <div className="text-xl">
                        <span className="opacity-70">Customer: </span>
                        {watchCompanyName || `${watchFirstName} ${watchLastName}`}
                    </div>
                ) : isLoading ? (
                    <Loading />
                ) : (
                    <div>
                        <SourSearchWrapperOld
                            onNavigate={onNavigate}
                            defaultFilters={{
                                query: {
                                    type: 'sourgum-customer',
                                },
                            }}
                            options={{
                                application: 'aap',
                                apiKey: import.meta.env.VITE_ELASTIC_KEY,
                                environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                            }}
                        >
                            <div className="flex w-full flex-row justify-between space-x-4">
                                <SourSearchOld
                                    options={{
                                        searchPopoverFixed: true,
                                        showTips: false,
                                        showMessages: false,
                                        resultSize: 'w-[600px]',
                                        placeholder: 'Search for a customer',
                                    }}
                                />
                                <div className="hidden">
                                    <SourFiltersOld />
                                </div>
                            </div>
                        </SourSearchWrapperOld>
                    </div>
                )}
                <button className="btn-dark-grey-outlined mt-5 md:ml-7 md:mt-0" onClick={handleSelectNewCustomer} type="button">
                    Create New Customer
                </button>
            </div>
            {watchCustomerID && (
                <div className="grid grid-cols-3 gap-2 text-sm">
                    <div className="underline">Draft Balance</div>
                    <div className="underline">Open Balance</div>
                    <div className="underline">Delinquent Balance</div>
                    <div>{moneyFormatter(customerPaymentStatuses.draftBalance, { undefinedBehavior: 'Loading...' })}</div>
                    <div>{moneyFormatter(customerPaymentStatuses.openBalance, { undefinedBehavior: 'Loading...' })}</div>
                    <div>{moneyFormatter(customerPaymentStatuses.delinquentBalance, { undefinedBehavior: 'Loading...' })}</div>
                </div>
            )}
        </div>
    );
};

export default SelectCustomerSubForm;
