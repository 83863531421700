import { useEffect, useState } from 'react';
import { Customer, Pricing, Profile } from '@alliance-disposal/transport-types';
import { Button, Toggle } from '@wayste/sour-ui';
import { getPrimaryCustomerContact } from '@wayste/utils';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { createOrderConfirmationEmailPreview } from '../../utils/email-utils';
import { OrderCreateFormProps } from '../OrderCreate/order-create-form-props';

export interface PaymentSummaryProps {
    user: Profile.ProfileTransport;
    isLoading: boolean;
    disabled?: boolean;
    zonePricing: Pricing.PricingTransport | null;
}

const PaymentSummary = ({ user, isLoading, disabled, zonePricing }: PaymentSummaryProps) => {
    const [primaryContact, setPrimaryContact] = useState<Customer.AllianceCustomerContactTransport | null>(null);
    const {
        setValue,
        control,
        watch,
        formState: { isValid },
    } = useFormContext<OrderCreateFormProps>();
    const watchFinalPrice = watch('pricingSubForm.finalPrice');
    const watchCustomer = useWatch({ name: 'customerSubForm', control });
    const watchValues = useWatch({ name: 'paymentSubForm', control });
    const watchPricingSubForm = useWatch({ name: 'pricingSubForm', control });
    const watchDispatchSubForm = useWatch({ name: 'dispatchSubForm', control });

    useEffect(() => {
        const primaryContactFound = getPrimaryCustomerContact(watchCustomer as Customer.AllianceCustomerTransport);
        setPrimaryContact(primaryContactFound);
        setValue('paymentSubForm.sendEmail', primaryContactFound.email ? true : false);
    }, [watchCustomer.contacts]);

    return (
        <div className="flex flex-col gap-6">
            <h6 className="text-xl">Email Order Summary</h6>
            <Controller
                name="paymentSubForm.sendEmail"
                control={control}
                render={({ field }) => (
                    <Toggle
                        value={field.value}
                        label="Send email"
                        onChange={(value) => {
                            setValue('paymentSubForm.sendEmail', value);
                        }}
                        disabled={!primaryContact?.email}
                    />
                )}
            />
            <div className="flex w-full flex-col">
                {watchValues.sendEmail ? (
                    <div className="mb-2 opacity-70">Email preview</div>
                ) : (
                    <div className="mb-2 text-red-600">YOU MUST READ THE BELOW TO THE CUSTOMER OVER THE PHONE OR SEND THEM AN EMAIL</div>
                )}
                <div className="rounded border bg-white px-3.5 py-2.5 text-sm">
                    <div className="mb-2.5">
                        Hi {primaryContact?.firstName},
                        <br />
                        <br />
                        This is {user?.firstName} with Sourgum Waste confirming your roll-dumpster rental. If you need anything you can give
                        us a call at (732) 366-9355.
                    </div>
                    <textarea
                        rows={4}
                        className="w-full rounded border p-2 text-sm"
                        value={watchValues.emailMessage}
                        onChange={(event) => setValue('paymentSubForm.emailMessage', event.target.value)}
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: createOrderConfirmationEmailPreview(
                                {
                                    ...watchDispatchSubForm,
                                    ...watchPricingSubForm,
                                    emailMessage: watchValues.emailMessage,
                                },
                                watchFinalPrice,
                                user,
                                zonePricing?.prohibitedItems ? zonePricing.prohibitedItems.join(', ') : undefined,
                            ),
                        }}
                    />
                </div>
            </div>
            <div className="mb-2.5 flex justify-end">
                <Button className="btn-primary" disabled={!isValid || disabled} loading={isLoading} type="submit">
                    Verify Order
                </Button>
            </div>
        </div>
    );
};

export default PaymentSummary;
