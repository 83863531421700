import { useEffect, useState } from 'react';
import { Hauler } from '@alliance-disposal/transport-types';
import { AddressSubForm, OperatingHoursSubForm } from '@wayste/sour-components';
import {
    Checkbox,
    CurrencyTextField,
    RHFRules,
    Select,
    SelectOption,
    TextField,
    Textarea,
    Toggle,
    Tooltip,
    phoneNumberMasker,
} from '@wayste/sour-ui';
import { formatE164ToUSPhoneNumber, formatUSPhoneNumberToE164, removeTypeName } from '@wayste/utils';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { Controller, FormProvider, get, useForm } from 'react-hook-form';
import { durationUnitTypes, haulerBillPaymentMethods, paymentTerms } from '../../utils';
import StarRating from '../StarRating';

const rentUnitTypes = {
    ...durationUnitTypes,
};

export interface VendorFormProps {
    hauler?: Hauler.HaulerWithAapTransport;
    onSubmit: (data: Hauler.HaulerWithAapDataUpdate, primaryContact?: Hauler.HaulerSapContactCreate) => void;
}

interface FormProps {
    name: string;
    isSourgumPartner: boolean;
    notes?: string;
    rating?: number;
    defaultPaymentMethod?: string;
    defaultPaymentTerm?: keyof typeof paymentTerms | '';
    defaultRentPeriod: {
        unit: string;
        value: string;
    };
    rentExtensionFee?: number | '';
    billingAddress: {
        addressLine1: string;
        addressLine2?: string;
        city: string;
        state: string;
        zip: string;
        country: string;
    };
    primaryContact?: Hauler.HaulerSapContactCreate;
    hoursOfOperation: {
        mon: {
            start: Date | '';
            end: Date | '';
            open: boolean;
        };
        tue: {
            start: Date | '';
            end: Date | '';
            open: boolean;
        };
        wed: {
            start: Date | '';
            end: Date | '';
            open: boolean;
        };
        thu: {
            start: Date | '';
            end: Date | '';
            open: boolean;
        };
        fri: {
            start: Date | '';
            end: Date | '';
            open: boolean;
        };
        sat: {
            start: Date | '';
            end: Date | '';
            open: boolean;
        };
        sun: {
            start: Date | '';
            end: Date | '';
            open: boolean;
        };
    };
}

export const VendorForm = ({ onSubmit, hauler }: VendorFormProps) => {
    const [toggleServiceInfoSection, setToggleServiceInfoSection] = useState<boolean>(true);

    //FORM CONTEXT
    const methods = useForm<FormProps>({
        mode: 'all',
    });

    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { errors },
    } = methods;

    const submitForm = (data: FormProps) => {
        const copy = { ...data };
        const cleanHoursOfOperation: Hauler.HaulerWithAapDataUpdate['hoursOfOperation'] = {
            mon: {
                start: copy.hoursOfOperation.mon.start ? copy.hoursOfOperation.mon.start.toISOString() : null,
                end: copy.hoursOfOperation.mon.end ? copy.hoursOfOperation.mon.end.toISOString() : null,
                open: copy.hoursOfOperation.mon.open,
            },
            tue: {
                start: copy.hoursOfOperation.tue.start ? copy.hoursOfOperation.tue.start.toISOString() : null,
                end: copy.hoursOfOperation.tue.end ? copy.hoursOfOperation.tue.end.toISOString() : null,
                open: copy.hoursOfOperation.tue.open,
            },
            wed: {
                start: copy.hoursOfOperation.wed.start ? copy.hoursOfOperation.wed.start.toISOString() : null,
                end: copy.hoursOfOperation.wed.end ? copy.hoursOfOperation.wed.end.toISOString() : null,
                open: copy.hoursOfOperation.wed.open,
            },
            thu: {
                start: copy.hoursOfOperation.thu.start ? copy.hoursOfOperation.thu.start.toISOString() : null,
                end: copy.hoursOfOperation.thu.end ? copy.hoursOfOperation.thu.end.toISOString() : null,
                open: copy.hoursOfOperation.thu.open,
            },
            fri: {
                start: copy.hoursOfOperation.fri.start ? copy.hoursOfOperation.fri.start.toISOString() : null,
                end: copy.hoursOfOperation.fri.end ? copy.hoursOfOperation.fri.end.toISOString() : null,
                open: copy.hoursOfOperation.fri.open,
            },
            sat: {
                start: copy.hoursOfOperation.sat.start ? copy.hoursOfOperation.sat.start.toISOString() : null,
                end: copy.hoursOfOperation.sat.end ? copy.hoursOfOperation.sat.end.toISOString() : null,
                open: copy.hoursOfOperation.sat.open,
            },
            sun: {
                start: copy.hoursOfOperation.sun.start ? copy.hoursOfOperation.sun.start.toISOString() : null,
                end: copy.hoursOfOperation.sun.end ? copy.hoursOfOperation.sun.end.toISOString() : null,
                open: copy.hoursOfOperation.sun.open,
            },
        };

        if (hauler) {
            const submit: Hauler.HaulerWithAapDataUpdate = {
                ...copy,
                hoursOfOperation: cleanHoursOfOperation,
                defaultRentPeriod: {
                    unit: copy.defaultRentPeriod.unit,
                    value: Number(copy.defaultRentPeriod?.value),
                },
                rentExtensionFee: Number(copy.rentExtensionFee),
                billingAddress: copy.billingAddress,
            };
            onSubmit(submit);
        } else {
            const submit: Hauler.HaulerWithAapDataUpdate = {
                ...copy,
                hoursOfOperation: cleanHoursOfOperation,
                defaultRentPeriod: {
                    unit: copy.defaultRentPeriod.unit,
                    value: Number(copy.defaultRentPeriod?.value),
                },
                rentExtensionFee: Number(copy.rentExtensionFee),
                billingAddress: copy.billingAddress,
                yardAddress: copy.billingAddress,
                active: true,
            };

            if (toggleServiceInfoSection === false) {
                delete submit.defaultRentPeriod;
                delete submit.rentExtensionFee;
            }

            onSubmit(submit, copy.primaryContact);
        }
    };

    const contactsDefault = {
        notes: '',
        primaryContact: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            department: 'primary',
            notes: '',
            sendBillingEmails: true,
            sendDispatchEmails: true,
            primaryContact: true,
            sendMarketingEmails: true,
            sendRFPEmails: true,
        },
    };

    // SET DEFAULT VALUES WHEN CREATING A NEW VENDOR
    useEffect(() => {
        reset({
            ...contactsDefault,
            hoursOfOperation: {
                mon: {
                    start: '',
                    end: '',
                    open: false,
                },
                tue: {
                    start: '',
                    end: '',
                    open: false,
                },
                wed: {
                    start: '',
                    end: '',
                    open: false,
                },
                thu: {
                    start: '',
                    end: '',
                    open: false,
                },
                fri: {
                    start: '',
                    end: '',
                    open: false,
                },
                sat: {
                    start: '',
                    end: '',
                    open: false,
                },
                sun: {
                    start: '',
                    end: '',
                    open: false,
                },
            },
            isSourgumPartner: true,
        });
    }, []);

    // SET DEFAULT VALUES WHEN EDITING A VENDOR
    useEffect(() => {
        if (hauler) {
            const newData = {
                ...removeTypeName(hauler),
            };
            delete newData.metadata;
            delete newData.waysteHaulerId;

            if (!hauler.rentExtensionFee) {
                setToggleServiceInfoSection(false);
            }

            if (hauler.hoursOfOperation) {
                for (const day in newData.hoursOfOperation) {
                    if (newData.hoursOfOperation[day].start === null || newData.hoursOfOperation[day].start === '') {
                        newData.hoursOfOperation[day].start = '';
                    } else {
                        newData.hoursOfOperation[day].start = new Date(newData.hoursOfOperation[day].start);
                    }
                    if (newData.hoursOfOperation[day].end === null || newData.hoursOfOperation[day].end === '') {
                        newData.hoursOfOperation[day].end = '';
                    } else {
                        newData.hoursOfOperation[day].end = new Date(newData.hoursOfOperation[day].end);
                    }
                }
            }

            reset({
                ...newData,
            });
        }
    }, [hauler]);

    const isSourgumPartner = watch('isSourgumPartner');

    return (
        <FormProvider {...methods}>
            {/* To trigger from submit, put a type='submit' button in the parent with the prop form='VendorForm' */}
            <form onSubmit={handleSubmit(submitForm)} id="VendorForm">
                {/* Hidden Fields */}
                <div className="flex flex-col space-y-6">
                    <div className="text-xl">Company Information</div>
                    {/* VENDOR NAME FIELD  */}
                    <div className="flex flex-row items-center space-x-4">
                        <Controller
                            control={control}
                            name={`name`}
                            defaultValue={''}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Vendor name is required',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    type={'string'}
                                    label="Vendor name"
                                    error={get(errors, `name`)}
                                    required
                                    inputProps={{
                                        ...field,
                                    }}
                                />
                            )}
                        />
                        <Tooltip text="Turn this off if the vendor is not a Sourgum partner, for example they are only using Wayste">
                            <div className="w-max flex-shrink-0">
                                <Controller
                                    control={control}
                                    name={`isSourgumPartner`}
                                    defaultValue={true}
                                    render={({ field }) => (
                                        <Toggle
                                            label="Sourgum Partner"
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setToggleServiceInfoSection(e);
                                                // set all contacts to not send emails
                                                const contact = watch('primaryContact');

                                                const updatedContacts = {
                                                    ...contact,
                                                    sendBillingEmails: false,
                                                    sendDispatchEmails: false,
                                                    sendMarketingEmails: false,
                                                    sendRFPEmails: false,
                                                };

                                                reset({
                                                    ...watch(),
                                                    primaryContact: updatedContacts,
                                                });
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                            </div>
                        </Tooltip>
                    </div>
                    {/* VENDOR NOTES FIELD */}
                    <Controller
                        control={control}
                        name={`notes`}
                        defaultValue={''}
                        render={({ field }) => (
                            <Textarea
                                label="Vendor notes"
                                error={get(errors, `notes`)}
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                    {/* CONTACTS FIELD */}
                    {!hauler && (
                        <>
                            <div className="border-b border-gray-300 pb-2 text-xl">Primary Contact</div>
                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                                <Controller
                                    name="primaryContact.firstName"
                                    control={control}
                                    rules={{ required: 'First name is required' }}
                                    render={({ field, fieldState }) => (
                                        <TextField label="First Name" error={fieldState.error} inputProps={{ ...field }} required />
                                    )}
                                />
                                <Controller
                                    name="primaryContact.lastName"
                                    control={control}
                                    rules={{ required: 'Last name is required' }}
                                    render={({ field, fieldState }) => (
                                        <TextField label="Last Name" error={fieldState.error} required inputProps={{ ...field }} />
                                    )}
                                />
                                <Controller
                                    name="primaryContact.email"
                                    control={control}
                                    rules={{
                                        ...RHFRules.email,
                                        required: { value: true, message: 'Email is required' },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            label="Email"
                                            error={fieldState.error}
                                            required
                                            inputProps={{
                                                ...field,
                                                autoComplete: 'no',
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    name="primaryContact.phoneNumber"
                                    control={control}
                                    rules={{
                                        ...RHFRules.phoneNumber,
                                        required: { value: true, message: 'Phone number is required' },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            startAdornment="+1"
                                            type={'string'}
                                            label="Phone number"
                                            error={fieldState.error}
                                            required
                                            inputProps={{
                                                ...field,
                                                value: formatE164ToUSPhoneNumber(field.value || ''),
                                                onChange: (e) => {
                                                    field.onChange(formatUSPhoneNumberToE164(phoneNumberMasker(e.target.value)));
                                                },
                                                autoComplete: 'no',
                                            }}
                                        />
                                    )}
                                />
                                <div className="lg:col-span-2">
                                    <Controller
                                        name="primaryContact.notes"
                                        control={control}
                                        render={({ field }) => (
                                            <Textarea
                                                label="Notes"
                                                inputProps={{
                                                    ...field,
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <Controller
                                    name="primaryContact.primaryContact"
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            label="Primary Contact"
                                            inputProps={{
                                                checked: field.value,
                                                onChange: (value) => field.onChange(value),
                                                disabled: true,
                                            }}
                                        />
                                    )}
                                />
                                <div />
                                <Controller
                                    name="primaryContact.sendDispatchEmails"
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            label="Dispatch Notifications"
                                            inputProps={{
                                                checked: field.value,
                                                onChange: (value) => field.onChange(value),
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    name="primaryContact.sendBillingEmails"
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            label="Billing Notifications"
                                            inputProps={{
                                                checked: field.value,
                                                onChange: (value) => field.onChange(value),
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    name="primaryContact.sendRFPEmails"
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            label="RFP Notifications"
                                            inputProps={{
                                                checked: field.value,
                                                onChange: (value) => field.onChange(value),
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    name="primaryContact.sendMarketingEmails"
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            label="Marketing Notifications"
                                            inputProps={{
                                                checked: field.value,
                                                onChange: (value) => field.onChange(value),
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </>
                    )}

                    {/* RATING FIELD */}
                    <div className="text-xl">Rating</div>
                    <Controller
                        control={control}
                        name={`rating`}
                        render={({ field }) => (
                            <StarRating disabled={!isSourgumPartner} rating={field.value || 0} onChange={field.onChange} />
                        )}
                    />
                    <div className="text-xl">Billing Info</div>
                    {/* BILLING PAYMENt METHOD FIELD  */}
                    <div className="flex flex-col gap-6 sm:flex-row">
                        <Controller
                            control={control}
                            name={`defaultPaymentMethod`}
                            defaultValue={''}
                            render={({ field }) => (
                                <Select
                                    error={get(errors, `defaultPaymentMethod`)}
                                    label={'Default Payment Method'}
                                    disabled={!isSourgumPartner}
                                    onSelect={(value) => {
                                        field.onChange(value);
                                    }}
                                    defaultValue={''}
                                    value={field.value}
                                >
                                    {Object.keys(haulerBillPaymentMethods).map((option: string, index: number) => (
                                        <SelectOption key={index} value={option}>
                                            {haulerBillPaymentMethods[option as keyof typeof haulerBillPaymentMethods]}
                                        </SelectOption>
                                    ))}
                                </Select>
                            )}
                        />
                        <Controller
                            control={control}
                            name={`defaultPaymentTerm`}
                            defaultValue={''}
                            render={({ field }) => (
                                <Select
                                    error={get(errors, `defaultPaymentTerm`)}
                                    label={'Default Payment Term'}
                                    onSelect={(value) => {
                                        field.onChange(value);
                                    }}
                                    disabled={!isSourgumPartner}
                                    defaultValue={''}
                                    value={field.value}
                                >
                                    {Object.entries(paymentTerms).map((option) => (
                                        <SelectOption key={option[0]} value={option[0]}>
                                            {option[1]}
                                        </SelectOption>
                                    ))}
                                </Select>
                            )}
                        />
                    </div>
                    <AddressSubForm apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY} fieldName="billingAddress" required showCountry />
                    <Disclosure>
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="flex items-center justify-between border-b pb-1">
                                    <div className="text-xl">Hours of operation</div>
                                    <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} size-5`} />
                                </Disclosure.Button>
                                <Disclosure.Panel className="text-gray-500">
                                    <OperatingHoursSubForm />
                                    <hr className="mt-4" />
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <div className="text-xl">Service Info</div>
                    <Checkbox
                        label="Services roll-off"
                        inputProps={{
                            value: 'type',
                            disabled: !isSourgumPartner,
                            checked: toggleServiceInfoSection,
                            onChange: () => setToggleServiceInfoSection(!toggleServiceInfoSection),
                        }}
                    />
                    {toggleServiceInfoSection && (
                        <>
                            {/* SERVICE INFO FIELD */}
                            <div className="flex flex-row space-x-4">
                                <Controller
                                    control={control}
                                    name={`defaultRentPeriod.unit`}
                                    defaultValue={''}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Rental period units is required',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <Select
                                            error={get(errors, `defaultRentPeriod.unit`)}
                                            label={'Rental period units'}
                                            onSelect={(value) => {
                                                field.onChange(value);
                                            }}
                                            defaultValue={''}
                                            value={field.value}
                                            required
                                        >
                                            {Object.entries(rentUnitTypes).map((option: [string, string], index: number) => (
                                                <SelectOption key={index} value={option[0]}>
                                                    {option[1]}
                                                </SelectOption>
                                            ))}
                                        </Select>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name={`defaultRentPeriod.value`}
                                    defaultValue={''}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Rental period is required',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            type={'number'}
                                            label="Rental period"
                                            error={get(errors, `defaultRentPeriod.value`)}
                                            required
                                            inputProps={{
                                                ...field,
                                                value: field?.value ? parseInt(field.value) : '',
                                                onChange: (e) => {
                                                    if (parseInt(e.target.value) < 0) return;
                                                    field.onChange(parseInt(e.target.value));
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-1/2 pr-2">
                                <Controller
                                    control={control}
                                    name={`rentExtensionFee`}
                                    defaultValue={''}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Rental period is required',
                                        },
                                        min: {
                                            value: 0,
                                            message: 'Rental extension fee must be greater than 0',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <CurrencyTextField
                                            label="Rental extension fee"
                                            required
                                            error={get(errors, `rentExtensionFee`)}
                                            useCents
                                            value={field.value || ''}
                                            onChange={(value) => {
                                                if (+value < 0) {
                                                    field.onChange(0);
                                                    return;
                                                } else {
                                                    field.onChange(+value);
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </>
                    )}
                </div>
            </form>
        </FormProvider>
    );
};
