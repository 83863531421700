import { useContext } from 'react';
import { UIContext } from '../contexts';

/**
 * @deprecated use sourUIContext setShowToast instead
 * Hook that pulls in the UIContext and returns the flash message and setter
 */
export const useFlash = () => {
    const { showFlash } = useContext(UIContext);

    return { showFlash };
};
