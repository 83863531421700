import { Hauler, Pricing } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';
import { HaulerPricingForm, RollOffPricingFormProps } from '../HaulerPricingForm/HaulerPricingForm';

interface HaulerPricingUpdateDialogProps {
    open: boolean;
    onBackButtonClick: (data: { status: string | number }) => void;
    isLoading: boolean;
    hauler: Hauler.HaulerWithAapTransport;
    haulerPricingZones: Pricing.PricingTransport[];
    pricing: Pricing.PricingTransport;
    onSubmit: (data: RollOffPricingFormProps) => Promise<void>;
}

const HaulerPricingUpdate = ({
    pricing,
    open,
    onBackButtonClick,
    onSubmit,
    isLoading,
    hauler,
    haulerPricingZones,
}: HaulerPricingUpdateDialogProps) => {
    return (
        <Dialog
            styledTitle="Update Vendor Pricing Zone (Rolloff)"
            onClose={() => onBackButtonClick({ status: 'cancel' })}
            open={open}
            className="max-w-xl"
        >
            <HaulerPricingForm
                haulerPricingZones={haulerPricingZones}
                pricing={pricing}
                onSubmit={onSubmit as (data: RollOffPricingFormProps) => Promise<void>}
                onCancel={onBackButtonClick}
                isLoading={isLoading}
                hauler={hauler}
            />
        </Dialog>
    );
};

export default HaulerPricingUpdate;
