import { useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useHistory, useParams } from 'react-router-dom';
import LeadDetails from '../../components/LeadDetails';
import routes from '../../utils/routes';
import { leadTypes } from '../../utils/shared-types';

export interface LeadDetailsProps {
    isModal?: boolean;
}

const LeadDetailsPage = ({ isModal = false }: LeadDetailsProps) => {
    const history = useHistory();
    const { id }: { id: string } = useParams();

    // STATE
    const [lead, setLead] = useState<Customer.AllianceLeadTransport | null>(null);

    const handleBackButtonClick = () => {
        history.push(routes.leads.list, { pipeline: lead?.type || 'rollOff', leadStatus: lead?.status || 'open' });
    };

    const content = (
        <div className="flex flex-col">
            <LeadDetails id={id} onLeadFetched={setLead} />
        </div>
    );

    if (isModal)
        return (
            <Dialog
                open={true}
                onClose={() => history.goBack()}
                className="bg-[#FAFAFA]"
                fullScreen
                showX
                styledTitle={`${lead && leadTypes[lead.type as keyof typeof leadTypes]} Lead Details`}
            >
                <div className="size-full px-4 lg:px-24">{content}</div>
            </Dialog>
        );

    return (
        <div className="w-full max-w-7xl px-4 pb-5 lg:px-24 xl:mx-auto">
            <div className="flex items-center justify-between py-3">
                <div className="flex">
                    <button className="btn-dark-grey-outlined" onClick={() => handleBackButtonClick()}>
                        <ChevronLeftIcon className="mr-1 size-5" />
                        Leads
                    </button>
                    <h6 className="ml-7 text-xl font-medium">{lead && leadTypes[lead.type as keyof typeof leadTypes]} Lead Details</h6>
                </div>
            </div>
            {content}
        </div>
    );
};

export default LeadDetailsPage;
