import { UniversalService } from '@alliance-disposal/transport-types';
import { moneyFormatter } from '@wayste/utils';
import { ArrowTopRightOnSquareIcon, PencilIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import CardInfoList, { CardInfoListData } from '../../../../components/CardInfoList';
import DetailsCardWrapper from '../../../../components/DetailsCardWrapper';
import { routes } from '../../../../utils';

interface SubscriptionBillingDetailsCardProps {
    serviceOrder: UniversalService.ServiceOrder;
}

const SubscriptionBillingDetailsCard = ({ serviceOrder }: SubscriptionBillingDetailsCardProps) => {
    const history = useHistory();
    const thisPeriodRate = serviceOrder.events.find((event) => event.lineItemType.description === 'recurring' && event.unitPrice !== 0);
    const currentRecurringRate = serviceOrder.subscriptionDetails?.periodicEvents.reduce(
        (e, event) => (e += event.unitPrice * event.priceQuantity),
        0,
    );
    const delivery = serviceOrder.events.find((event) => event.lineItemType.description === 'delivery' && event.unitPrice !== 0);
    const removal = serviceOrder.events.find((event) => event.lineItemType.description === 'removal' && event.unitPrice !== 0);

    // const otherRecurringCharges = serviceOrder.subscriptionDetails?.periodicEvents
    //     .filter((event) => event.unitPrice !== 0 && event.description)
    //     .reduce((total, event) => total + event.unitPrice * event.priceQuantity, 0); //without first event
    const otherFirstTimeCharges = serviceOrder.events
        .filter(
            (event) =>
                !serviceOrder.subscriptionDetails?.periodicEvents.find(
                    (periodicEvent) =>
                        periodicEvent.lineItemTypeID === event.lineItemType.id &&
                        periodicEvent.priceQuantity === event.priceQuantity &&
                        periodicEvent.unitPrice === event.unitPrice,
                ) &&
                event.unitPrice !== 0 &&
                event.lineItemType.description !== 'delivery' &&
                event.lineItemType.description !== 'removal',
        )
        .reduce((total, event) => total + event.unitPrice * event.priceQuantity, 0);

    const colOneRowOne: CardInfoListData[] = [
        {
            label: 'This Period Rate',
            value: moneyFormatter((thisPeriodRate?.unitPrice || 0) * (thisPeriodRate?.priceQuantity || 1)) || '',
        },

        {
            label: 'Current Recurring Rate',
            value: moneyFormatter(currentRecurringRate) || '',
        },
        // {
        //     label: 'Current Other Recurring Charges',
        //     value: moneyFormatter(otherRecurringCharges) || '',
        // },
    ];
    const colTwoRowOne: CardInfoListData[] = [
        {
            label: 'Delivery Fee',
            value: moneyFormatter((delivery?.unitPrice || 0) * (delivery?.priceQuantity || 1)) || '',
        },
        {
            label: 'Removal Fee',
            value: moneyFormatter((removal?.unitPrice || 0) * (removal?.priceQuantity || 1)) || '',
        },
        {
            label: 'Other First Time Charges',
            value: moneyFormatter(otherFirstTimeCharges) || '',
        },
    ];
    const colThreeRowOne: CardInfoListData[] = [
        {
            label: 'Cancelation Fee',
            value: moneyFormatter(serviceOrder.cancellationDetails?.customerFee) || '',
        },
    ];

    // Row 2

    const colOneRowTwo: CardInfoListData[] = [
        {
            label: 'Vendor Recurring Rate',
            value: '',
        },
        {
            label: 'Vendor Recurring Charges',
            value: '',
        },
    ];
    const colTwoRowTwo: CardInfoListData[] = [
        {
            label: 'Vendor Delivery Fee',
            value: '',
        },
        {
            label: 'Vendor Removal Fee',
            value: '',
        },
        {
            label: 'Vendor Other First Time Charges',
            value: '',
        },
    ];
    const colThreeRowTwo: CardInfoListData[] = [
        {
            label: 'Vendor Cancelation Fee',
            value: '',
        },
    ];

    return (
        <DetailsCardWrapper
            heading="Order Billing Details"
            buttons={[
                {
                    label: (
                        <>
                            <PencilIcon className="mr-1 size-5" /> Edit Pricing
                        </>
                    ),
                    onClick: () =>
                        history.push(
                            routes.universalServices.serviceGrouping.subscription.pricing(serviceOrder.serviceGroupingID, serviceOrder.id),
                        ),
                },
                {
                    label: (
                        <>
                            <ArrowTopRightOnSquareIcon className="mr-1 size-5" /> Go to Invoice
                        </>
                    ),
                    onClick: () => history.push(routes.billingProduct.details(serviceOrder.serviceGroupingID, serviceOrder.id)),
                },
            ]}
        >
            <div className={'grid grid-cols-1 gap-3 lg:grid-cols-3'}>
                <CardInfoList data={colOneRowOne} border />
                <CardInfoList data={colTwoRowOne} border />
                <CardInfoList data={colThreeRowOne} border />
                <hr className="col-span-3" />
                <CardInfoList data={colOneRowTwo} border />
                <CardInfoList data={colTwoRowTwo} border />
                <CardInfoList data={colThreeRowTwo} border />
            </div>
        </DetailsCardWrapper>
    );
};

export default SubscriptionBillingDetailsCard;
