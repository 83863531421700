import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button, ButtonGroup, DataGrid, DataGridProps } from '@wayste/sour-ui';
import { formatServiceAddress } from '@wayste/utils';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { routes } from '../../utils';

const RFPList = () => {
    const client = useWaysteClient();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [rfps, setRFPs] = useState<UniversalService.RFPTransport[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<UniversalService.RFPStatus>('OPEN');
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(25);
    const [rfpTotalCount, setRfpTotalCount] = useState(0);

    const handleFetchRFPs = async (status: UniversalService.RFPStatus) => {
        setIsLoading(true);
        try {
            const rfpResponse = await client.universalService().adminPortal.rfp.query({ status, limit: pageSize, offset: page * pageSize });
            const sortedList = rfpResponse.results.sort((a, b) => (a.deadlineDate > b.deadlineDate ? -1 : 1));
            setRFPs(sortedList);
            setRfpTotalCount(rfpResponse.total);
        } catch (error) {
            console.error('Error fetching RFPs', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleFetchRFPs(selectedStatus);
    }, [selectedStatus, page, pageSize]);

    const columns: DataGridProps<UniversalService.RFPTransport>['columns'] = [
        {
            key: 'proposalNumber',
            name: 'Proposal #',
            width: 100,
            align: 'center',
        },
        {
            key: 'rfpName',
            name: 'Name',
        },
        {
            key: 'status',
            name: 'Status',
            align: 'center',
            headerAlign: 'center',
            formatter: (value) => UniversalService.RFPStatusLabels[value.row.status],
            width: 130,
        },
        {
            key: 'metadata.createdAt',
            name: 'Created',
            formatter: ({ row }) => format(new Date(row.metadata.createdAt), 'E MM/dd/yyyy h:mm aaa'),
            width: 200,
        },
        {
            key: 'deadlineDate',
            name: 'Deadline',
            formatter: ({ row }) => format(new Date(row.deadlineDate), 'E MM/dd/yyyy h:mm aaa'),
            width: 200,
        },
        {
            key: 'serviceLocation',
            name: 'Address',
            formatter: (value) => formatServiceAddress(value.row.serviceLocation.address),
        },
        {
            key: 'rfpVendorResponses',
            name: 'Submissions',
            formatter: (value) => {
                if (!value.row.rfpVendorResponses || value.row.rfpVendorResponses.length === 0) {
                    return 'Not sent to haulers';
                }
                const totalLength = value.row.rfpVendorResponses.length;
                let submittedLength = 0;
                if (value.row.status !== 'AWARD' && value.row.status !== 'VOID') {
                    submittedLength = value.row.rfpVendorResponses.filter((response) => response.status === 'SUBMITTED').length;
                } else {
                    submittedLength = value.row.rfpVendorResponses.filter((response) =>
                        response.rfpSkus.some((sku) => sku.unitPrice && sku.unitPrice > 0),
                    ).length;
                }
                return `${submittedLength} of ${totalLength} submitted`;
            },
        },
    ];

    return (
        <div className="container mx-auto flex w-full flex-1 flex-col p-2">
            <div className="flex items-center justify-between px-4 py-2">
                <h1 className="text-xl font-bold">RFP List</h1>
                <div className="flex items-center gap-x-4">
                    <Button className="btn-primary" onClick={() => history.push(routes.requestForProposal.new)}>
                        Create RFP
                    </Button>
                    <ButtonGroup
                        activeBtn={selectedStatus}
                        onClick={(value) => {
                            setSelectedStatus(value as UniversalService.RFPStatus);
                            setPage(0);
                        }}
                        buttons={[
                            {
                                label: 'Open',
                                value: 'OPEN',
                            },
                            {
                                label: 'Awarded',
                                value: 'AWARD',
                            },
                            {
                                label: 'Voided',
                                value: 'VOID',
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="flex h-0 w-full flex-1 grow basis-0">
                <DataGrid
                    rows={rfps}
                    loading={isLoading}
                    columns={columns}
                    // rowHeight={60}
                    onRowClick={(row) => {
                        history.push({
                            pathname: routes.requestForProposal.details(row.id),
                        });
                    }}
                    pagination={{
                        itemCount: rfpTotalCount,
                        page: page,
                        rowsPerPage: pageSize,
                        rowsPerPageOptions: [25, 50, 100],
                        onPageChange: (newPage) => {
                            setPage(newPage);
                        },
                        onRowsPerPageChange: (rowsPerPage) => {
                            setPageSize(rowsPerPage);
                            setPage(0);
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default RFPList;
