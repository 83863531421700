import { UniversalService } from '@alliance-disposal/transport-types';
import { CurrencyTextField, DatePicker, Dialog, TextField } from '@wayste/sour-ui';
import { formatDateToYYYYMMDD } from '@wayste/utils';
import { Controller, FormProvider, get, useForm } from 'react-hook-form';

interface UniversalServicesCancelDialogProps {
    type: 'oneTime' | 'subscription';
    cancelOpen: boolean;
    setCancelOpen: (open: boolean) => void;
    onCancel: (cancelServiceOrderPayload: UniversalService.CancellationDetails) => void;
    variant?: 'delete' | 'filled-header' | 'underlined-header' | undefined;
}
const UniversalServicesCancelDialog = ({
    variant = undefined,
    type,
    cancelOpen,
    onCancel,
    setCancelOpen,
}: UniversalServicesCancelDialogProps) => {
    const methods = useForm<UniversalService.CancellationDetails>();
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = methods;

    const submitForm = (data: UniversalService.CancellationDetails) => {
        data.date = formatDateToYYYYMMDD(new Date()) || '';
        onCancel(data);
    };

    const getButtonText = (type: string) => {
        switch (type) {
            case 'subscription':
                return 'End Subscription';
            case 'oneTime':
                return 'Cancel Service Order';
        }
    };

    const getConfirmText = (type: string) => {
        switch (type) {
            case 'subscription':
                return 'Subscription';
            case 'oneTime':
                return 'Service Order';
        }
    };

    return (
        <Dialog
            variant={variant}
            open={cancelOpen}
            onClose={() => setCancelOpen(false)}
            styledTitle={type === 'subscription' ? 'Cancel Subscription' : 'Cancel Service Order'}
            className="max-w-screen-md"
        >
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <div className="space-y-6">
                        <div>{`Are you sure you want to cancel this ${getConfirmText(type)}?`}</div>
                        {type !== 'oneTime' ? (
                            <Controller
                                control={control}
                                name={'date'}
                                render={({ field }) => (
                                    <DatePicker
                                        value={field.value ? new Date(field.value) : undefined}
                                        onChange={(date) => field.onChange(date)}
                                        minDate={new Date()}
                                        required
                                        label="Cancel on Date"
                                        closeOnSelect
                                        clearable
                                    />
                                )}
                            />
                        ) : (
                            <></>
                        )}
                        <div className="flex space-x-2">
                            <Controller
                                key={'customerFee'}
                                control={control}
                                name={`customerFee`}
                                defaultValue={undefined}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Vendor name is required',
                                    },
                                }}
                                render={({ field }) => (
                                    <CurrencyTextField
                                        type={'string'}
                                        currency="USD"
                                        value={field.value}
                                        onChange={field.onChange}
                                        label="Customer Cancelation Fee"
                                        error={get(errors, 'customerCancelationFee')}
                                        required
                                        inputProps={{
                                            ...field,
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                key={'vendorFee'}
                                control={control}
                                name={'vendorFee'}
                                defaultValue={undefined}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Vendor cancelation fee is required',
                                    },
                                }}
                                render={({ field }) => (
                                    <CurrencyTextField
                                        type={'string'}
                                        currency="USD"
                                        value={field.value}
                                        onChange={field.onChange}
                                        label="Vendor Cancelation Fee"
                                        error={get(errors, 'vendorCancelationFee')}
                                        required
                                        inputProps={{
                                            ...field,
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <Controller
                            key={'reason'}
                            control={control}
                            name={`reason`}
                            defaultValue={''}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Cancelation Reason is required',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    type={'string'}
                                    label="Cancelation Reason"
                                    error={get(errors, `cancelationReason`)}
                                    required
                                    inputProps={{
                                        ...field,
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="flex justify-end gap-4 pt-6">
                        <button className="btn-dark-grey-outlined" onClick={() => setCancelOpen(false)} type="button">
                            Cancel
                        </button>
                        <button className="btn-error" type="submit">
                            {getButtonText(type)}
                        </button>
                    </div>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default UniversalServicesCancelDialog;
