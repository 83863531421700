import axios from 'axios';

export const removeContactFromList = async (email: string, listId: string) => {
    const url = import.meta.env.VITE_SENDGRID_URL + 'remove-contact-from-list';
    return axios({
        method: 'DELETE',
        url: url,
        data: JSON.stringify({ email, listId }),
    });
};
