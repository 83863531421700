import { Customer, Material, Pricing } from '@alliance-disposal/transport-types';
import { formatE164ToUSPhoneNumber } from '@wayste/utils';
import { OrderCreateFormStartingValues } from '../../../components/OrderCreate/order-create-form-props';

export const onCreateOrderFromLead = (
    lead: Customer.AllianceLeadTransport | Partial<Customer.AllianceLeadCreateTransport>,
    quote: Customer.AllianceLeadQuote | Customer.AllianceLeadQuoteCreateTransport,
) => {
    const startingValues: OrderCreateFormStartingValues = {
        pricingSubForm: {
            ...quote,
            serviceLocation: {
                address: lead?.serviceLocation?.address,
                county: lead?.serviceLocation?.county,
                coordinates: lead?.serviceLocation?.coordinates || undefined,
            },
            price: quote.price || '',
            dumpRate: quote.dumpRate ? Number(quote.dumpRate) : null,
            rentExtensionFee: quote.rentExtensionFee || '',
            adjustedRentalPeriod: quote.rentPeriod?.value || '',
            weightLimit: quote.tonLimit || '',
            expectedSize: quote.size.size,
            overage: quote.overage || '',
            material: quote.material as Material,
            priceType: quote.priceType as Pricing.TypesOfPricing,
            taxRate: quote.taxRate || undefined,
        },
        customerSubForm: {
            channel: lead.channel || '',
            type: (lead.customerType as Customer.CustomerType) || '',
            accountRepID: lead.accountRepID || '',
            accountRepName: lead.accountRepName || '',
            contacts: [
                {
                    firstName: lead.firstName || '',
                    lastName: lead.lastName || '',
                    phoneNumber: lead.phoneNumber ? formatE164ToUSPhoneNumber(lead.phoneNumber) : '',
                    email: lead.email ? lead.email.trim() : '',
                    department: '',
                    primaryContact: true,
                    sendBillingEmails: true,
                    sendDispatchEmails: true,
                },
            ],
        },
    };
    return {
        startingValues,
        fromLead: 'id' in lead ? lead.id : undefined,
    };
};
