import { useState } from 'react';
import { useCurrentAuthorityToken, useWaysteClient } from '@alliance-disposal/client';
import { Invoice, PaginatedResult } from '@alliance-disposal/transport-types';
import { Button, DateRangePicker, Loading } from '@wayste/sour-ui';
import { centsToDollars } from '@wayste/utils';
import axios from 'axios';
import { addDays, format } from 'date-fns';
import { CSVLink } from 'react-csv';

const QBUpload = () => {
    const client = useWaysteClient();
    const token = `Bearer ${useCurrentAuthorityToken()}`;
    const [isLoading, setIsLoading] = useState(false);
    const [dateRange, setDateRange] = useState<{ from: Date | undefined; to: Date | undefined }>({ from: undefined, to: undefined });
    const [downloadableData, setDownloadableData] = useState<any[]>([]);

    const convertToQBNamingConventionBills = (item: any, type: 'rolloff' | 'commercial' | 'portableToilet' | 'other') => {
        let qbItem = item;
        if (type === 'commercial') {
            qbItem = 'Waste & Recycling Cost of Goods Sold:Commercial Recurring Service Expense:Monthly Service';
        } else if (type === 'portableToilet') {
            qbItem = 'Ancillary Service Cost of Goods Sold:Portable Toilets Expense';
        } else if (type === 'rolloff') {
            switch (item) {
                case 'dump':
                    qbItem = 'Waste & Recycling Cost of Goods Sold:Roll Off Service Expense:Dump';
                    break;
                case 'haul':
                    qbItem = 'Waste & Recycling Cost of Goods Sold:Roll Off Service Expense:Haul';
                    break;
                case 'rentalExtension':
                    qbItem = 'Waste & Recycling Cost of Goods Sold:Roll Off Service Expense:Rental Extension';
                    break;
                default:
                    qbItem = 'Waste & Recycling Cost of Goods Sold:Roll Off Service Expense:Additional Fee(s)';
                    break;
            }
        } else {
            qbItem = 'Ancillary Service Cost of Goods Sold';
        }
        return qbItem;
    };

    const namedFamilyIDs = [
        { id: 'a25e6b1b-d3dd-482c-a915-4b832ccada65', type: 'commercial' },
        { id: 'f2eaada1-7450-4e58-a261-844961a06254', type: 'commercial' },
        { id: '27b9f1ba-0534-4e95-9295-023048fde0ed', type: 'portableToilet' },
    ];

    const getType = (id?: string): 'rolloff' | 'commercial' | 'portableToilet' | 'other' => {
        const found = namedFamilyIDs.find((item) => item.id === id);
        if (found) {
            return found.type as 'rolloff' | 'commercial' | 'portableToilet' | 'other';
        }
        return 'other';
    };

    const createCSVSheet = async () => {
        setIsLoading(true);
        const exportData = [];

        if (!dateRange.from || !dateRange.to) {
            alert('Missing date range');
            return;
        }

        const params: Invoice.GetPayablesQuery = {
            issuedAfter: format(dateRange.from, 'yyyy-MM-dd'),
            issuedBefore: format(addDays(dateRange.to, 1), 'yyyy-MM-dd'),
            void: false,
            limit: 2600,
        };

        let counter = 1;
        const missingHaulerMap: { [key: string]: string } = {};

        try {
            const res: { data: PaginatedResult<Invoice.PayableTransport> } = await axios.get(
                `${import.meta.env.VITE_BASE_API_URL}/invoice/payable`,
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                    params,
                },
            );

            for (const invoice of res.data.results) {
                let subscriptionDetails;
                if (invoice.invoiceDetails.serviceOrderID) {
                    try {
                        subscriptionDetails = await client.universalService().serviceOrder.fetch(invoice.invoiceDetails.serviceOrderID);
                    } catch (error) {
                        console.warn('Error fetching service order: ', error);
                    }
                }
                let vendorName = invoice.vendorName;
                if (!invoice.vendorName) {
                    if (missingHaulerMap[invoice.haulerID]) {
                        vendorName = missingHaulerMap[invoice.haulerID];
                    } else {
                        const vendor = await client.vendorService().adminPortal.fetch(invoice.haulerID);
                        vendorName = vendor.name;
                        missingHaulerMap[invoice.haulerID] = vendorName;
                    }
                }
                let orderNumber = subscriptionDetails?.fullOrderNumber || invoice.invoiceDetails.orderNumber;
                if (!orderNumber && invoice.invoiceDetails.orderID) {
                    const order = await client.order().adminPortal.fetch(invoice.invoiceDetails.orderID);
                    orderNumber = order.orderNumber?.toString() || '';
                }
                for (const lineItem of invoice.invoiceDetails.lineItems) {
                    if (lineItem.totalPrice !== 0) {
                        const billDate = invoice.invoiceDetails.issueDate
                            ? format(new Date(invoice.invoiceDetails.issueDate), 'MM/dd/yyyy')
                            : '';
                        exportData.push({
                            'Bill No.': `${orderNumber} - ${invoice.invoiceDetails.invoiceNumber}`,
                            Vendor: vendorName,
                            'Bill Date': billDate,
                            Memo: orderNumber,
                            Amount: lineItem.totalPrice && centsToDollars(lineItem.totalPrice),
                            'Category/Account': convertToQBNamingConventionBills(
                                lineItem.itemName,
                                invoice.invoiceDetails.orderID ? 'rolloff' : getType(subscriptionDetails?.serviceType.familyID),
                            ),
                            Type: 'Item Details',
                            Qty: 1,
                            Rate: lineItem.totalPrice && centsToDollars(lineItem.totalPrice),
                            lineItemPairCode: counter,
                            paymentMethod: invoice.invoiceDetails.payments?.[0]?.paymentMethod || '',
                        });
                    }
                }
                counter = counter + 1;
            }
            setDownloadableData(exportData);
        } catch (error) {
            console.warn('runDangerousFunction error: ', error);
        } finally {
            setIsLoading(false);
        }
    };

    // const createPaymentCSVSheet = async () => {
    // setIsPaymentLoading(true);
    // const exportData = [];
    // if (!dateRange.from || !dateRange.to) {
    //     alert('Missing date range');
    //     return;
    // }
    // const params: Invoice.GetPayablesQuery = {
    //     paidAfter: format(dateRange.from, 'yyyy-MM-dd'),
    //     paidBefore: format(addDays(dateRange.to, 1), 'yyyy-MM-dd'),
    //     void: false,
    //     limit: 2600,
    // };
    // let counter = 1;
    // const missingHaulerMap: { [key: string]: string } = {};
    // try {
    //     const res: { data: PaginatedResult<Invoice.PayableTransport> } = await axios.get(
    //         `${import.meta.env.VITE_BASE_API_URL}/invoice/payable`,
    //         {
    //             headers: {
    //                 Authorization: `${token}`,
    //             },
    //             params,
    //         },
    //     );
    //     for (const invoice of res.data.results) {
    //         let subscriptionDetails;
    //         if (invoice.invoiceDetails.serviceOrderID) {
    //             try {
    //                 subscriptionDetails = await client.universalService().serviceOrder.fetch(invoice.invoiceDetails.serviceOrderID);
    //             } catch (error) {
    //                 console.warn('Error fetching service order: ', error);
    //             }
    //         }
    //         let vendorName = invoice.vendorName;
    //         if (!invoice.vendorName) {
    //             if (missingHaulerMap[invoice.haulerID]) {
    //                 vendorName = missingHaulerMap[invoice.haulerID];
    //             } else {
    //                 const vendor = await client.vendorService().adminPortal.fetch(invoice.haulerID);
    //                 vendorName = vendor.name;
    //                 missingHaulerMap[invoice.haulerID] = vendorName;
    //             }
    //         }
    //         let orderNumber = subscriptionDetails?.fullOrderNumber || invoice.invoiceDetails.orderNumber;
    //         if (!orderNumber && invoice.invoiceDetails.orderID) {
    //             const order = await client.order().adminPortal.fetch(invoice.invoiceDetails.orderID);
    //             orderNumber = order.orderNumber?.toString() || '';
    //         }
    //         for (const lineItem of invoice.invoiceDetails.lineItems) {
    //             if (lineItem.totalPrice !== 0) {
    //                 const billDate = invoice.invoiceDetails.issueDate
    //                     ? format(new Date(invoice.invoiceDetails.issueDate), 'MM/dd/yyyy')
    //                     : '';
    //                 exportData.push({
    //                     'Bill No.': `${orderNumber} - ${invoice.invoiceDetails.invoiceNumber}`,
    //                     Vendor: vendorName,
    //                     'Bill Date': billDate,
    //                     Memo: orderNumber,
    //                     Amount: lineItem.totalPrice && centsToDollars(lineItem.totalPrice),
    //                     'Category/Account': convertToQBNamingConventionBills(
    //                         lineItem.itemName,
    //                         invoice.invoiceDetails.orderID ? 'rolloff' : getType(subscriptionDetails?.serviceType.familyID),
    //                     ),
    //                     Type: 'Item Details',
    //                     Qty: 1,
    //                     Rate: lineItem.totalPrice && centsToDollars(lineItem.totalPrice),
    //                     lineItemPairCode: counter,
    //                     paymentMethod: invoice.invoiceDetails.payments?.[0]?.paymentMethod || '',
    //                 });
    //             }
    //         }
    //         counter = counter + 1;
    //     }
    //     setDownloadableData(exportData);
    // } catch (error) {
    //     console.warn('runDangerousFunction error: ', error);
    // } finally {
    //     setIsPaymentLoading(false);
    // }
    // };

    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    flex: 1,
                }}
            >
                <div>
                    <div style={{ marginBottom: 30 }}>
                        <Loading />
                    </div>
                    <div>
                        <h6 className="text-lg">Please Wait. Do Not Close this Tab</h6>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container mx-auto flex flex-col gap-y-6 p-4">
            {downloadableData.length === 0 ? (
                <>
                    <div className="w-60">
                        <DateRangePicker value={dateRange} onChange={setDateRange} />
                    </div>
                    <div>
                        <Button
                            className="btn-primary"
                            onClick={() => {
                                createCSVSheet();
                            }}
                        >
                            Get Data
                        </Button>
                    </div>
                    Get Data First to Generate Upload File
                </>
            ) : (
                <>
                    <div>
                        <CSVLink className="btn-secondary" data={downloadableData}>
                            Download QBO Bill Upload File
                        </CSVLink>
                    </div>
                    <div className="mt-10">
                        <Button className="btn-dark-grey-outlined" onClick={() => setDownloadableData([])}>
                            Get Different Data
                        </Button>
                    </div>
                </>
            )}
            <div>
                <b>IMPORTANT:</b> Make sure you do not select a date range that has already been uploaded. QuickBooks online will <b>NOT</b>{' '}
                prevent duplicate bills from being uploaded.
            </div>
        </div>
    );
};

export default QBUpload;
