import React, { useEffect, useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';
import { formatE164ToUSPhoneNumber } from '@wayste/utils';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { leadTypes, leadTypesEnums } from '../../utils/shared-types';
import ButtonSelect from '../ButtonSelect';
import ROLeadCreate from './ROLeadCreate';
import USLeadCreate from './USLeadCreate';

export interface LeadCreateProps {
    isModal?: boolean;
}

const LeadCreate = ({ isModal }: LeadCreateProps) => {
    const history = useHistory();
    const location = useLocation();
    const [leadType, setLeadType] = useState<Customer.AllianceLeadTransport['type']>('rollOff');
    const [leadPhone, setLeadPhone] = useState<string | undefined>(undefined);

    //////////////////////////////////////////////
    // HOOKS SECTION
    //////////////////////////////////////////////

    useEffect(() => {
        if (location.search.includes('lead_phone_query=')) {
            const searchObj = queryString.parse(location.search);
            const phoneNumber = searchObj.lead_phone_query;

            if (phoneNumber) {
                setLeadPhone(formatE164ToUSPhoneNumber(phoneNumber ? phoneNumber.toString() : ''));
            }
        }
    }, []);

    //////////////////////////////////////////////
    // FUNCTION SECTION
    //////////////////////////////////////////////

    const handleBackButtonClick = () => {
        history.goBack();
    };

    const handleLeadTypeChange = (value: keyof typeof leadTypes) => {
        setLeadType(value);
    };

    //////////////////////////////////////////////
    // RENDER SECTION
    //////////////////////////////////////////////

    const getContent = () => {
        switch (leadType) {
            case leadTypesEnums.rollOff:
                return <ROLeadCreate startingValues={{ phoneNumber: leadPhone }} />;
            case leadTypesEnums.commercial:
            case leadTypesEnums.residential:
            case leadTypesEnums.portableToilet:
                return <USLeadCreate leadType={leadType} startingValues={{ phoneNumber: leadPhone }} />;
            default:
                return <div>Lead type does not exist</div>;
        }
    };

    if (isModal)
        return (
            <Dialog fullScreen open={true} styledTitle="Create New Roll Off Lead" onClose={handleBackButtonClick} className="bg-gray-50">
                <div className="container mx-auto">
                    <div className="mb-4 flex">
                        <ButtonSelect
                            menuItems={Object.entries(leadTypes).map((item) => ({
                                value: item[0],
                                label: `${item[1]} Lead`,
                            }))}
                            value={leadType}
                            onSelect={handleLeadTypeChange}
                        />
                    </div>
                    {getContent()}
                </div>
            </Dialog>
        );

    return (
        <div className="container w-full max-w-7xl bg-gray-50 px-4 py-2 xl:mx-auto xl:px-6">
            <div className="flex items-center">
                <button type="button" className="btn-icon" onClick={handleBackButtonClick}>
                    <ChevronLeftIcon className="h-6 w-6" />
                </button>
                <h6 className="text-xl font-medium">Create New Lead</h6>
                <ButtonSelect
                    menuItems={Object.entries(leadTypes).map((item) => ({
                        value: item[0],
                        label: `${item[1]} Lead`,
                    }))}
                    value={leadType}
                    onSelect={handleLeadTypeChange}
                    wrapperClass="mx-3"
                />
            </div>
            {getContent()}
        </div>
    );
};

export default LeadCreate;
