import { ReactNode } from 'react';
import { Chip, ChipProps, Tooltip } from '@wayste/sour-ui';
import { Button } from '@wayste/sour-ui';
import { twMerge } from 'tailwind-merge';

type DetailsCardProps = {
    heading: string | ReactNode;
    subheading?: string;
    subheadingStatus?: string;
    chips?: ChipProps[];
    buttons?: DetailsCardWrapperButtonProps[];
    children?: React.ReactNode | React.ReactNode[];
    id?: string;
    className?: string;
};

type DetailsCardWrapperButtonProps = {
    label?: ReactNode;
    delete?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    hide?: boolean;
    badgeAmount?: number;
    props?: React.ButtonHTMLAttributes<HTMLButtonElement>;
    customButton?: React.ReactNode;
    tooltip?: string;
    loading?: boolean;
};

const DetailsCardWrapper = ({ heading, subheading, subheadingStatus, buttons, chips, children, id, className }: DetailsCardProps) => {
    return (
        <div className={twMerge('shadow-dark overflow-auto rounded-md bg-white p-5 transition-all', className || '')} id={id}>
            <div className="flex justify-between">
                <div className="flex">
                    <div className="inline-flex items-center text-xl">{heading}</div>
                    <div className="mx-4 inline-flex items-center text-sm text-slate-400">{subheading}</div>
                    <div className="text-l inline-flex items-center">{subheadingStatus}</div>
                    <div className="ml-2 inline-flex items-center">
                        {chips
                            ? chips.map((chipProps: ChipProps, index: number) => (
                                  <Chip
                                      key={'chip' + index + chipProps.label}
                                      label={chipProps.label}
                                      primaryColor={chipProps.primaryColor}
                                      textColor={chipProps.textColor}
                                      outlined={chipProps.outlined}
                                      size={chipProps.size}
                                  />
                              ))
                            : null}
                    </div>
                </div>
                <div className="float-right flex flex-row-reverse">
                    {buttons &&
                        buttons.map((props: DetailsCardWrapperButtonProps, index: number) => {
                            if (props.hide) return;
                            if (props.customButton) {
                                return props.customButton;
                            }
                            if (props.badgeAmount) {
                                return (
                                    <div key={'button' + index} className="relative flex">
                                        <span className="z-1 absolute -top-3 left-0.5 inline-flex size-6 items-center justify-center rounded-full bg-red-600 p-1 text-xs font-bold leading-none text-white">
                                            {props.badgeAmount}
                                        </span>
                                        <Button
                                            {...props.props}
                                            onClick={props.onClick}
                                            disabled={props.disabled}
                                            loading={props.loading}
                                            className={`${index > 0 ? 'mx-3' : 'ml-3'} ${
                                                props.delete ? 'btn-delete' : index === 0 ? 'btn-primary' : 'btn-dark-grey-outlined'
                                            } ${props?.props?.className || ''}`}
                                        >
                                            <div className="flex items-center">{props.label}</div>
                                        </Button>
                                    </div>
                                );
                            }
                            return (
                                <Tooltip text={props.tooltip}>
                                    <Button
                                        {...props.props}
                                        key={'button' + index}
                                        onClick={props.onClick}
                                        disabled={props.disabled}
                                        loading={props.loading}
                                        className={`${index > 0 ? 'mx-3' : 'ml-3'} ${
                                            props.delete ? 'btn-delete' : index === 0 ? 'btn-primary' : 'btn-dark-grey-outlined'
                                        } ${props?.props?.className || ''}`}
                                    >
                                        <div className="flex items-center">{props.label}</div>
                                    </Button>
                                </Tooltip>
                            );
                        })}
                </div>
            </div>
            {children ? <div className="w-[calc(100% + 40px)] -mx-5 my-4 h-px bg-[#D8D8D8]" /> : null}
            {children}
        </div>
    );
};

export default DetailsCardWrapper;
