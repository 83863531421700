import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice } from '@alliance-disposal/transport-types';
import { Button, CurrencyTextField, DataGrid, DataGridProps, DatePicker, Dialog, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { fromBasisPoint, moneyFormatter, toBasisPoint } from '@wayste/utils';
import { Controller, useForm } from 'react-hook-form';

const Coupons = () => {
    const client = useWaysteClient();
    const [coupons, setCoupons] = useState<Invoice.CouponTransport[]>([]);
    const [showCreateCouponDialog, setShowCreateCouponDialog] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState<Invoice.CouponTransport | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const handleFetchCoupons = async () => {
        setIsLoading(true);
        try {
            const response = await client.invoice().adminPortal.coupon.query({ limit: 500 });
            setCoupons(response.results);
        } catch (error) {
            console.warn('fetchCoupons error: ', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleFetchCoupons();
    }, []);

    const columns: DataGridProps<Invoice.CouponTransport>['columns'] = [
        {
            key: 'code',
            name: 'Coupon Code',
        },
        {
            key: 'type',
            name: 'Type',
        },
        {
            key: 'discountAmount',
            name: 'Discount Amount',
            formatter: ({ row }) =>
                row.type === 'FIXED' ? moneyFormatter(row.discountAmount) : `${fromBasisPoint(row.discountAmount, 'percent')}%`,
        },
        {
            key: 'expiresAt',
            name: 'Expires At',
        },
        {
            key: 'metadata.createdAt',
            name: 'Created At',
        },
    ];

    return (
        <div className="container mx-auto flex flex-1 flex-col gap-10 px-3 pb-10 pt-7">
            <div className="mb-6 flex justify-between">
                <h1 className="text-2xl font-semibold">Coupons</h1>
                <Button className="btn-primary" onClick={() => setShowCreateCouponDialog(true)}>
                    Create New
                </Button>
            </div>
            <DataGrid
                columns={columns}
                rows={coupons}
                onRowClick={(row) => {
                    setSelectedCoupon(row);
                    setShowCreateCouponDialog(true);
                }}
                loading={isLoading}
            />
            {/* Dialogs */}
            <CreateCouponDialog
                open={showCreateCouponDialog}
                onClose={(updated) => {
                    if (updated) handleFetchCoupons();
                    setShowCreateCouponDialog(false);
                    setSelectedCoupon(undefined);
                }}
                coupon={selectedCoupon}
            />
        </div>
    );
};

export default Coupons;

type DialogProps = {
    open: boolean;
    onClose: (updated?: boolean) => void;
    coupon?: Invoice.CouponTransport;
};

type CreateFormProps = {
    code: string;
    type: 'PERCENTAGE' | 'FIXED';
    discountAmount: number | '';
    expiresAt: Date | undefined;
};

const CreateCouponDialog = ({ open, onClose, coupon }: DialogProps) => {
    const client = useWaysteClient();
    const { control, handleSubmit, reset, setValue, watch } = useForm<CreateFormProps>({
        mode: 'all',
        defaultValues: {
            code: '',
            type: 'FIXED',
            discountAmount: '',
            expiresAt: undefined,
        },
    });

    const watchType = watch('type');

    useEffect(() => {
        if (coupon) {
            setValue('code', coupon.code);
            setValue('type', coupon.type);
            setValue('discountAmount', coupon.discountAmount);
            setValue('expiresAt', coupon.expiresAt ? new Date(coupon.expiresAt) : undefined);
        }
    }, [coupon]);

    const createCoupon = async (values: CreateFormProps) => {
        const data: Invoice.CouponCreate = {
            code: values.code,
            type: values.type,
            discountAmount: values.discountAmount as number,
            expiresAt: values.expiresAt ? values.expiresAt.toISOString() : undefined,
        };
        try {
            await client.invoice().adminPortal.coupon.create(data);
            onClose(true);
            reset();
        } catch (error) {
            console.warn('createCoupon error: ', error);
            alert('Error occurred');
        }
    };

    const updateCoupon = async (values: CreateFormProps) => {
        if (!coupon?.id) return;
        const data: Invoice.CouponUpdate = {
            type: values.type,
            discountAmount: values.discountAmount as number,
            expiresAt: values.expiresAt ? values.expiresAt.toISOString() : null,
        };
        try {
            await client.invoice().adminPortal.coupon.update(coupon.id, data);
            onClose(true);
            reset();
        } catch (error) {
            console.warn('createCoupon error: ', error);
            alert('Error occurred');
        }
    };

    const handleFormSubmit = async (values: CreateFormProps) => {
        if (coupon) {
            updateCoupon(values);
        } else {
            createCoupon(values);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose();
                reset();
            }}
        >
            <form className="flex flex-col gap-y-6" onSubmit={handleSubmit(handleFormSubmit)}>
                <Controller
                    name="code"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Code is required',
                        },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Code"
                            inputProps={{
                                ...field,
                                disabled: Boolean(coupon),
                            }}
                            error={fieldState.error}
                        />
                    )}
                />
                <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                        <Select
                            value={field.value}
                            onSelect={(value) => {
                                field.onChange(value);
                                setValue('discountAmount', '');
                            }}
                        >
                            <SelectOption value="FIXED">Fixed</SelectOption>
                            <SelectOption value="PERCENTAGE">Percentage</SelectOption>
                        </Select>
                    )}
                />
                {watchType === 'FIXED' ? (
                    <Controller
                        name="discountAmount"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Discount Amount is required',
                            },
                        }}
                        render={({ field }) => (
                            <CurrencyTextField
                                label="Discount Amount"
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                                required
                                useCents
                            />
                        )}
                    />
                ) : (
                    <Controller
                        name="discountAmount"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Discount Amount is required',
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                label="Discount Amount"
                                type="number"
                                inputProps={{
                                    ...field,
                                    value: field.value === '' ? '' : fromBasisPoint(field.value, 'percent'),
                                    onChange: (e) => {
                                        const value = e.target.value;
                                        field.onChange(value === '' ? '' : toBasisPoint(Number(value), 'percent'));
                                    },
                                }}
                                required
                                endAdornment="%"
                            />
                        )}
                    />
                )}
                <Controller
                    name="expiresAt"
                    control={control}
                    render={({ field }) => (
                        <DatePicker label="Expires At" value={field.value} onChange={(value) => field.onChange(value)} />
                    )}
                />
                <div className="flex justify-end gap-x-4">
                    <Button
                        className="btn-dark-grey-outlined"
                        onClick={() => {
                            onClose();
                            reset();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button className="btn-primary" type="submit">
                        Submit
                    </Button>
                </div>
            </form>
        </Dialog>
    );
};
