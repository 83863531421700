import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Invoice, Order, UniversalService } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';
import { centsToDollars } from '@wayste/utils';
import { useHistory } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import { useFlash } from '../../../../hooks/useFlash';
import { paymentMethodsEnums, routes } from '../../../../utils';
import BillPayment, { PaymentProp } from './BillPayment';

export interface BillPaymentContainerProps {
    open: boolean;
    onCancel: () => void;
    hauler: Hauler.HaulerWithAapTransport | null;
    onSave: () => void;
}

const BillPaymentContainer = ({ open, onCancel, hauler, onSave }: BillPaymentContainerProps) => {
    const client = useWaysteClient();
    const history = useHistory();
    const { showFlash } = useFlash();
    const [payments, setPayments] = useState<PaymentProp[]>([]);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [totalPayment, setTotalPayment] = useState(0);
    const [billTotals, setBillTotals] = useState({ total: 0, totalDue: 0 });
    const [isLoading, setIsLoading] = useState(false);

    const prepareUIData = async (payables: Invoice.PayableTransport[]) => {
        const paymentsArray: PaymentProp[] = [];
        // Filter out payables with a remaining balance of 0
        const filteredPayables = payables.filter((payable) => payable.invoiceDetails.remainingBalance !== 0);

        try {
            await Promise.all(
                filteredPayables.map(async (bill) => {
                    let order: Order.AllianceOrderTransport | UniversalService.ServiceOrder | undefined;
                    // we just filtered them above, so we know this is true
                    if (bill.invoiceDetails.serviceGroupingID && bill.invoiceDetails.serviceOrderID) {
                        // payable.invoiceDetails.serviceGroupingID is exists when the invoice is not associated a universal service order
                        order = await client.universalService().serviceOrder.fetch(bill.invoiceDetails.serviceOrderID!);
                    } else if (bill.invoiceDetails.orderID) {
                        // payable.invoiceDetails.orderID is null when the invoice is not associated with an a roll off order
                        order = await client.order().adminPortal.fetch(bill.invoiceDetails.orderID!);
                    }

                    if (order !== undefined) {
                        const payment: PaymentProp = {
                            orderId: bill.invoiceDetails.serviceOrderID || '',
                            orderNumber:
                                (order as Order.AllianceOrderTransport).orderNumber ||
                                (order as UniversalService.ServiceOrder).fullOrderNumber ||
                                '',
                            orderObj: order,
                            billObj: bill,
                            invoiceNumber: bill.invoiceDetails.invoiceNumber?.toString() || '',
                            invoiceID: bill.id,
                            readyForPayment: bill.readyForPayment,
                            total: centsToDollars(bill.invoiceDetails.total),
                            paid: bill.invoiceDetails.total - bill.invoiceDetails.remainingBalance,
                            value: '',
                            orderType: bill.invoiceDetails.serviceGroupingID ? 'universal-service' : 'roll-off',
                        };

                        paymentsArray.push(payment);
                    }
                }),
            );
        } catch (error) {
            // Handle any errors that occurred during the asynchronous operations.
            showFlash('There was an error fetching payables.', 'warning');
            console.error('Error fetching and processing payables:', error);
        } finally {
            // Stop loading, regardless of whether the promise resolves or rejects.
            handleSetPayments(paymentsArray);
            setIsLoading(false);
        }
    };

    const handleGetOpenPayables = async (haulerID: string) => {
        const response = await client.invoice().adminPortal.payable.query({
            haulerID: haulerID,
            paidInFull: false,
            limit: 200,
            // readyForPayment: true, --- Removed per @jdinar request
        });
        // SPLIT THIS UP FOR REVIEW PAYABLES ... OR BOTH? IF BILL TOTAL IS 0 IGNORE IT
        prepareUIData(response.results);
    };

    useEffect(() => {
        if (!hauler) return;
        setIsLoading(true);
        setPaymentMethod(hauler.defaultPaymentMethod || '');
        handleGetOpenPayables(hauler.id);
    }, [hauler]);

    const handleCancel = () => {
        setPaymentMethod('');
        onCancel();
    };

    const handleOrderNumberClick = (selectedOrder: PaymentProp) => {
        if ((selectedOrder.orderObj as UniversalService.ServiceOrder).serviceGroupingID) {
            history.push(
                routes.billingProduct.details(
                    (selectedOrder.orderObj as UniversalService.ServiceOrder).serviceGroupingID,
                    selectedOrder.orderObj.id,
                ),
            );
        } else {
            history.push(routes.billing.details(selectedOrder.orderObj.id));
        }
        handleCancel();
    };

    const handleSetPayments = (newPayments: PaymentProp[]) => {
        let total = 0;
        newPayments.forEach((payment) => {
            total = total + +payment.value;
        });
        setTotalPayment(total);
        getTotalBills();
        setPayments(newPayments);
    };

    const getTotalBills = () => {
        let total = 0;
        let totalDue = 0;
        payments.forEach((payment) => {
            total = total + payment.total;
            totalDue = totalDue + (payment.total - (payment.paid || 0));
        });
        setBillTotals({
            total: total,
            totalDue: totalDue,
        });
    };

    const handleUpdatePayment = (value: number | '', index: number) => {
        const newPayments = [...payments];
        newPayments[index] = {
            ...newPayments[index],
            value: String(value),
        };
        handleSetPayments(newPayments);
    };

    const handleUpdateCheckAmount = (value: string) => {
        if (paymentMethod === paymentMethodsEnums.check) {
            let checkTotal = +value;
            const newPayments: PaymentProp[] = [];

            payments.forEach((payment) => {
                const amtDue = payment.total - (payment.paid || 0);
                let newValue = 0;
                if (amtDue > 0) {
                    if (Math.sign(checkTotal - amtDue) > 0) {
                        newValue = amtDue;
                        checkTotal = checkTotal - amtDue;
                    } else if (checkTotal !== 0) {
                        newValue = checkTotal;
                        checkTotal = checkTotal - checkTotal;
                    }
                }

                newPayments.push({
                    ...payment,
                    value: String(newValue),
                });
            });
            handleSetPayments(newPayments);
        }
    };

    const handleSave = async (cleanedPayments: Invoice.PaymentCreateTransport[]) => {
        setIsLoading(true);
        try {
            await Promise.all(
                cleanedPayments.map((payment) => {
                    if (!payment.invoiceID) throw new Error('Invoice ID is required');
                    client.invoice().adminPortal.payment.create(payment.invoiceID, payment);
                }),
            );
            showFlash('Payables Successfully Updated', 'success');
            onSave();
        } catch (error) {
            console.error(error);
            showFlash('There was an error creating payments.', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const getView = () => {
        if (!hauler || isLoading) return <Loading />;
        return (
            <BillPayment
                onUpdatePayment={handleUpdatePayment}
                onCancel={handleCancel}
                billTotals={billTotals}
                onUpdateCheckAmount={handleUpdateCheckAmount}
                paymentMethod={paymentMethod}
                onSetPaymentMethod={setPaymentMethod}
                payments={payments}
                totalPayment={totalPayment}
                onSave={handleSave}
                onOrderSelect={handleOrderNumberClick}
            />
        );
    };

    if (!open) return null;
    if (open && !hauler) return <Loading />;

    return (
        <Dialog styledTitle={`Manage Payments for ${hauler?.name}`} open={open} fullScreen onClose={handleCancel}>
            {getView()}
        </Dialog>
    );
};

export default BillPaymentContainer;
