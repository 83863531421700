import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice } from '@alliance-disposal/transport-types';
import { Button, DatePicker, Dialog, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { Controller, useForm } from 'react-hook-form';
import { UIContext } from '../../../../contexts';
import { paymentMethods, paymentTerms } from '../../../../utils/shared-types';
import { BillingContext } from '../../context';

export interface EditInvoiceFormProps {
    paymentMethod: string;
    paymentTerm: string;
    issueDate: Date | '';
    memo?: string;
    internalNotes?: string;
}

interface Props {
    invoice: Invoice.ReceivableTransport | null;
    open: boolean;
    onCancel: () => void;
}

const EditModal = ({ invoice, open, onCancel }: Props) => {
    const client = useWaysteClient();
    const { selectedOrder, refetchOrder, selectedOrderType } = useContext(BillingContext);
    const { showFlash } = useContext(UIContext);
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: EditInvoiceFormProps) => {
        if (!invoice || !selectedOrder) return;
        setIsLoading(true);
        const orderUpdateData = {
            paymentMethod: data.paymentMethod || undefined,
            paymentTerm: data.paymentTerm || undefined,
        };
        try {
            if (selectedOrderType === 'roll-off') {
                await client.order().adminPortal.update(selectedOrder.id, orderUpdateData);
            } else if (selectedOrderType === 'universal-service' && selectedOrder.serviceOrder?.serviceGroupingID) {
                await client.universalService().serviceGrouping.update(selectedOrder.serviceOrder?.serviceGroupingID, orderUpdateData);
            }
        } catch (error) {
            console.error(error);
            showFlash('Failed to update order', 'warning');
            return;
        }

        const receivableUpdateData = {
            internalNotes: data.internalNotes || invoice.invoiceDetails.internalNotes,
            memo: data.memo || invoice.invoiceDetails.memo,
            issueDate: data.issueDate ? data.issueDate.toISOString() : undefined,
            // TODO add due data here
        };

        try {
            await client.invoice().adminPortal.receivable.update(invoice.id, { invoiceDetails: receivableUpdateData });
        } catch (error) {
            console.error(error);
            showFlash('Failed to update invoice', 'warning');
            return;
        }

        refetchOrder();
        onCancel();
        setIsLoading(false);
    };

    const {
        control,
        handleSubmit,
        register,
        formState: { isDirty, isValid },
        reset,
    } = useForm<EditInvoiceFormProps>({
        mode: 'all',
        defaultValues: {
            issueDate: invoice?.invoiceDetails.issueDate ? new Date(invoice.invoiceDetails.issueDate) : '',
            paymentMethod: selectedOrder?.paymentMethod || '',
            paymentTerm: selectedOrder?.paymentTerm || '',
        },
    });

    useEffect(() => {
        reset({
            issueDate: invoice?.invoiceDetails.issueDate ? new Date(invoice.invoiceDetails.issueDate) : '',
            paymentMethod: selectedOrder?.paymentMethod || '',
            paymentTerm: selectedOrder?.paymentTerm || '',
            memo: invoice?.invoiceDetails.memo || '',
            internalNotes: invoice?.invoiceDetails.internalNotes || '',
        });
    }, [invoice, selectedOrder]);

    return (
        <Dialog onClose={onCancel} styledTitle="Edit Invoice" open={open}>
            <form className="flex flex-col gap-4 overflow-y-auto pt-2.5" onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full">
                    <Controller
                        name="paymentTerm"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Payment Term is Required',
                            },
                        }}
                        render={({ field }) => (
                            <Select label="Payment Term" value={field.value} onSelect={(value) => field.onChange(value)} required={true}>
                                {Object.entries(paymentTerms).map((item) => (
                                    <SelectOption key={`paymentTerm-${item[0]}`} value={item[0]}>
                                        {item[1]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                </div>
                <div className="w-full">
                    <Controller
                        name="paymentMethod"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Payment Method is Required',
                            },
                        }}
                        render={({ field }) => (
                            <Select label="Payment Method" value={field.value} onSelect={(value) => field.onChange(value)} required={true}>
                                {Object.entries(paymentMethods).map((item) => (
                                    <SelectOption key={`paymentMethod-${item[0]}`} value={item[0]}>
                                        {item[1]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                </div>
                <div className="w-full">
                    <Controller
                        name="issueDate"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                label="Invoice date"
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                                format="keyboardDate"
                            />
                        )}
                    />
                </div>
                <div className="w-full">
                    <TextField label="Memo" inputProps={{ ...register('memo') }} />
                </div>
                <div className="w-full">
                    <TextField label="Internal Notes" inputProps={{ ...register('internalNotes') }} />
                </div>

                <div className="flex justify-end gap-4 pt-4">
                    <button className="btn-dark-grey-outlined" onClick={onCancel} type="button">
                        Cancel
                    </button>
                    <Button className="btn-primary" type="submit" disabled={!isValid || !isDirty} loading={isLoading}>
                        Update
                    </Button>
                </div>
            </form>
        </Dialog>
    );
};

export default EditModal;
