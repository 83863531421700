import { Customer, Pricing } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';
import CustomMaterialPricingForm from './CustomMaterialPricingForm';

const CustomMaterialPricingModal = ({
    handleClose,
    onSubmit,
    selectedCustomerDiscount,
    selectedDiscountData,
    customer,
}: {
    handleClose: () => void;
    onSubmit: () => void;
    selectedCustomerDiscount?: Pricing.CustomerDiscountTransport;
    selectedDiscountData?: Pricing.DiscountDataTransport;
    customer?: Customer.AllianceCustomerTransport;
}) => {
    return (
        <Dialog
            open={Boolean(selectedCustomerDiscount?.id)}
            onClose={handleClose}
            styledTitle={'Create Material Pricing'}
            className="!max-w-4xl"
        >
            {selectedCustomerDiscount && (
                <CustomMaterialPricingForm
                    handleClose={() => handleClose()}
                    onSubmit={onSubmit}
                    pricingZone={selectedCustomerDiscount?.pricingZone}
                    selectedDiscount={selectedCustomerDiscount}
                    selectedDiscountData={selectedDiscountData}
                    customer={customer}
                />
            )}
        </Dialog>
    );
};

export default CustomMaterialPricingModal;
