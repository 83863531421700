import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button } from '@wayste/sour-ui';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { isAxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { routes } from '../../utils';
import { VendorForm } from '../HaulerForm';

const HaulerCreate = () => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const handleOnCancel = () => {
        history.goBack();
    };

    const handleSubmit = async (data: Hauler.HaulerWithAapDataUpdate, contactData?: Hauler.HaulerSapContactCreate) => {
        setIsLoading(true);
        try {
            const vendor = await client.vendorService().adminPortal.create(data);
            if (contactData) {
                await client.vendorService().adminPortal.contacts.create({
                    ...contactData,
                    haulerId: vendor.id,
                });
            }
            setShowToast({
                message: 'Hauler Successfully Created',
                severity: 'success',
            });
            history.push(routes.haulers.details(vendor.id));
        } catch (error) {
            let message = 'An Error Occurred while creating the vendor';

            if (isAxiosError(error) && error.response?.data?.additionalInfo) {
                message = error.response?.data?.additionalInfo;
            }

            console.warn(' createHauler error: ', error);
            setShowToast({
                severity: 'error',
                message,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="mx-auto w-full max-w-screen-sm bg-white">
            <div className="my-6 flex flex-row">
                <Button
                    className="btn-dark-grey-outlined"
                    onClick={handleOnCancel}
                    disabled={isLoading}
                    startIcon={<ChevronLeftIcon className="size-6" />}
                >
                    Back
                </Button>
                <div className="ml-4 w-full text-xl">Create new vendor</div>
            </div>
            <VendorForm onSubmit={handleSubmit} />
            <div className="my-6 flex justify-end gap-4">
                <button className="btn-dark-grey-outlined" onClick={handleOnCancel} disabled={isLoading} type="button">
                    Cancel
                </button>
                <Button className="btn-primary" type="submit" disabled={isLoading} loading={isLoading} form="VendorForm">
                    Create
                </Button>
            </div>
        </div>
    );
};

export default HaulerCreate;
