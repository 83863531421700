import { Material } from '@alliance-disposal/transport-types';
import { round } from '@wayste/utils';

export const ccRate = 0.03;

// IF CHANGING STRIPE RATE HAVE TO RECORD WHAT IT WAS UP TO DATE CHANGE IN ORDER TO PRESERVE OLDER ORDERS
export const stripeRate = {
    percent: 0.029,
    fixed: 0.3,
};

export const salesTaxExemptMaterials: Material[] = [
    'bNB',
    'bNL',
    'bNS',
    'concrete',
    'comix',
    'concreteRebar',
    'asphalt',
    'dirt',
    'scrap',
    'cardboard',
];

/**
 * Build invoice item pricing (breaks out tax and cc)
 * @param {Number} qty - Quantity
 * @param {Number} rate - Unedited rate inclusive of tax and cc
 * @param {Number} ccRate - Credit card processing rate
 * @param {Boolean} taxable - Is the item taxable
 * @param {Number} taxRate - Sales tax rate
 * @return {Object} {total, cc, taxAmount, rate, qty}
 */
export const pricingBuild = (qty: number, rate: number, ccRate: number, taxable: boolean, taxRate: number) => {
    const tax = taxable ? round(rate * qty * taxRate, 5) : 0;
    const total = round((rate * qty + tax) / (1 - ccRate));
    const cc = round(total * ccRate, 5);

    return {
        total: total,
        cc: cc,
        taxAmount: tax,
        rate: rate,
        qty: qty,
    };
};
