import { Customer } from '@alliance-disposal/transport-types';

export type ROLeadFormType = {
    firstName: string;
    lastName: string;
    company?: string;
    phoneNumber: string;
    email: string;
    serviceLocation: Customer.AllianceLeadUpdateTransport['serviceLocation'];
    requestedDeliveryDate: string | null;
    followUpDate: string | null;
    customerNotes: string;
    requestedSizeString?: string;
    notes?: string;
};

export const prepareROLeadSubForm = (lead?: Customer.AllianceLeadTransport): ROLeadFormType => {
    if (lead) {
        return {
            firstName: lead.firstName || '',
            lastName: lead.lastName || '',
            company: lead.company || '',
            phoneNumber: lead.phoneNumber || '',
            email: lead.email || '',
            serviceLocation: {
                address: lead.serviceLocation?.address || {
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    zip: '',
                },
                county: lead.serviceLocation?.county || '',
                coordinates: lead.serviceLocation?.coordinates || null,
            },
            requestedDeliveryDate: lead.requestedDeliveryDate || null,
            followUpDate: lead.followUpDate || null,
            customerNotes: lead.customerNotes || '',
            requestedSizeString: lead.requestedSize?.size?.toString() || '',
        };
    } else {
        return {
            firstName: '',
            lastName: '',
            company: '',
            phoneNumber: '',
            email: '',
            serviceLocation: {
                address: {
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    zip: '',
                },
                county: '',
                coordinates: null,
            },
            requestedDeliveryDate: null,
            followUpDate: null,
            customerNotes: '',
            notes: '',
        };
    }
};

export type USLeadFormType = {
    firstName: string;
    lastName: string;
    company?: string;
    phoneNumber: string;
    email: string;
    serviceLocation: Customer.AllianceLeadUpdateTransport['serviceLocation'];
    requestedStartDate: string | null;
    followUpDate: string | null;
    locationType?: string;
    currentHauler?: string;
    customerNotes: string;
    notes?: string;
};

export const prepareUSLeadSubForm = (lead?: Customer.AllianceLeadTransport): USLeadFormType => {
    if (lead) {
        return {
            firstName: lead.firstName || '',
            lastName: lead.lastName || '',
            company: lead.company || '',
            phoneNumber: lead.phoneNumber || '',
            email: lead.email || '',
            serviceLocation: {
                address: lead.serviceLocation?.address || {
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    zip: '',
                },
                county: lead.serviceLocation?.county || '',
                coordinates: lead.serviceLocation?.coordinates || null,
            },
            requestedStartDate: lead.requestedDeliveryDate || null,
            followUpDate: lead.followUpDate || null,
            locationType: lead.locationType || '',
            currentHauler: lead.currentHauler || '',
            customerNotes: lead.customerNotes || '',
        };
    } else {
        return {
            firstName: '',
            lastName: '',
            company: '',
            phoneNumber: '',
            email: '',
            serviceLocation: {
                address: {
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    zip: '',
                },
                county: '',
                coordinates: null,
            },
            requestedStartDate: null,
            followUpDate: null,
            locationType: '',
            currentHauler: '',
            customerNotes: '',
            notes: '',
        };
    }
};

export type ROQuoteFormType = { quotes: (Customer.AllianceLeadQuoteCreateTransport | Customer.AllianceLeadQuote)[] };

export const prepareROQuoteSubForm = (lead?: Customer.AllianceLeadTransport): ROQuoteFormType => {
    if (lead) {
        return {
            quotes: lead.quotes || [],
        };
    } else {
        return {
            quotes: [],
        };
    }
};

export type USQuoteFormType = { quotes: (Customer.AllianceLeadQuoteCreateTransport | Customer.AllianceLeadQuote)[] };

export const prepareUSQuoteSubForm = (lead?: Customer.AllianceLeadTransport): USQuoteFormType => {
    if (lead) {
        return {
            quotes: lead.quotes || [],
        };
    } else {
        return {
            quotes: [],
        };
    }
};

export type USVendorQuoteFormType = {
    haulerQuotes: (Customer.AllianceLeadHaulerQuoteCreateTransport | Customer.AllianceLeadHaulerQuote)[];
};

export const prepareUSVendorQuoteSubForm = (lead?: Customer.AllianceLeadTransport): USVendorQuoteFormType => {
    if (lead) {
        return {
            haulerQuotes: lead.haulerQuotes || [],
        };
    } else {
        return {
            haulerQuotes: [],
        };
    }
};

export const prepareContactEvent = (): Customer.AllianceContactEventCreateTransport => {
    return {
        medium: 'phone',
        date: new Date().toISOString(),
    };
};

export type MarketingSubFormType = {
    channel?: string;
    formUrl?: string;
    searchParams?: string;
    customerType?: Customer.AllianceLeadCreateTransport['customerType'];
    accountRepID?: string;
    accountRepName?: string;
};

export const prepareMarketingSubForm = () => {
    return {
        channel: '',
        formUrl: '',
        searchParams: '',
        customerType: undefined,
        accountRepID: '',
        accountRepName: '',
    };
};
