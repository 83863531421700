import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Dialog } from '@wayste/sour-ui';

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onDismiss: () => void;
    cancelText?: string;
    confirmText?: string;
}

type GetConfirmationOptions = Omit<ConfirmationDialogProps, 'onConfirm' | 'onDismiss' | 'open'> & {
    actionCallback?: (confirmed: boolean) => void;
};

const shakeAnimation = `
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-3deg); }
    20% { transform: translate(-3px, 0px) rotate(3deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(3deg); }
    50% { transform: translate(-1px, 2px) rotate(-3deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-3deg); }
    80% { transform: translate(-1px, -1px) rotate(3deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-3deg); }
  }
`;

const useWidth = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isDesktop;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, title, message, onConfirm, onDismiss, cancelText, confirmText }) => {
    return (
        <Dialog
            open={open}
            onClose={(xClick) => {
                if (xClick) onDismiss();
            }}
            styledTitle={title}
            variant="underlined-header"
        >
            {message}
            <div className="mt-2 flex justify-end gap-4">
                <button className="btn-dark-grey-outlined" onClick={onDismiss}>
                    {cancelText || 'Cancel'}
                </button>
                <button className="btn-primary" onClick={onConfirm}>
                    {confirmText || 'Confirm'}
                </button>
            </div>
        </Dialog>
    );
};

interface AlertDialogProps {
    open: boolean;
    onClose: () => void;
    message: string | React.ReactNode;
    severity?: 'success' | 'info' | 'warning' | 'error';
}

const AlertDialog = ({ open, onClose, message, severity }: AlertDialogProps) => {
    const getAlertBorderColor = (severity?: 'success' | 'info' | 'warning' | 'error') => {
        switch (severity) {
            case 'success':
                return 'border-green-500';
            case 'info':
                return 'border-blue-500';
            case 'warning':
                return 'border-yellow-500';
            case 'error':
                return 'border-red-500';
            default:
                return 'border-gray-500';
        }
    };
    const getAlertTextColor = (severity?: 'success' | 'info' | 'warning' | 'error') => {
        switch (severity) {
            case 'success':
                return 'text-green-500';
            case 'info':
                return 'text-blue-500';
            case 'warning':
                return 'text-yellow-500';
            case 'error':
                return 'text-red-500';
            default:
                return 'text-gray-500';
        }
    };
    return (
        <Dialog open={open} onClose={onClose} className="max-w-screen-sm">
            {severity ? (
                <div className={`rounded-md border p-4 ${getAlertBorderColor(severity)} text-${getAlertTextColor(severity)}`}>
                    {message}
                </div>
            ) : (
                message
            )}
            <div className="mt-4 flex justify-end">
                <button className="btn-secondary-text-only" onClick={onClose}>
                    Ok
                </button>
            </div>
        </Dialog>
    );
};

type UIContextType = {
    showFlash: (message: string, style?: 'error' | 'info' | 'success' | 'warning' | null) => void;
    openConfirmDialog: (options: GetConfirmationOptions) => void;
    openAlert: (options: { message: string | React.ReactNode; severity?: 'success' | 'info' | 'warning' | 'error' }) => void;
    /**
     * ONLY USE THIS WHERE ABSOLUTELY NECESSARY - use tailwind breakpoints instead
     */
    isDesktop: boolean;
    flash: {
        showFlash: boolean;
        flashMessageText: string;
        flashStyle?: 'error' | 'info' | 'success' | 'warning' | null;
    };
    isAppAdmin: boolean;
    setGodModeActive: (active: boolean) => void;
    godModeActive: boolean;
};

const initialValue: UIContextType = {
    showFlash: () => null,
    openConfirmDialog: () => null,
    openAlert: () => null,
    isDesktop: true,
    flash: {
        showFlash: false,
        flashMessageText: '',
        flashStyle: null,
    },
    isAppAdmin: false,
    setGodModeActive: () => null,
    godModeActive: false,
};

/**
 * @deprecated use @wayste/use-sour-context instead...move over anything that is missing
 */
export const UIContext = createContext(initialValue);

const UIProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const client = useWaysteClient();
    const sequence = useRef<string[]>([]);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationConfig, setConfirmationConfig] = useState<GetConfirmationOptions | null>(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertConfig, setAlertConfig] = useState<Partial<AlertDialogProps>>({});
    const [isAppAdmin, setIsAppAdmin] = useState(false);
    const [godModeAnimation, setGodModeAnimation] = useState(false);
    const [godModeActive, setGodModeActive] = useState(false);
    const [flash, setFlash] = useState<{
        showFlash: boolean;
        flashMessageText: string;
        flashStyle?: 'error' | 'info' | 'success' | 'warning' | null;
    }>({
        showFlash: false,
        flashMessageText: '',
        flashStyle: null,
    });

    useEffect(() => {
        const sub = client.auth().appAdmin.subscribe((admin) => setIsAppAdmin(Boolean(admin)));

        return () => sub.unsubscribe();
    }, []);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            sequence.current.push(event.key);

            if (
                sequence.current.length === 8 &&
                sequence.current[0] === 'ArrowUp' &&
                sequence.current[1] === 'ArrowDown' &&
                sequence.current[2] === 'ArrowUp' &&
                sequence.current[3] === 'ArrowDown' &&
                sequence.current[4] === 'a' &&
                sequence.current[5] === 'b' &&
                sequence.current[6] === 'a' &&
                sequence.current[7] === 'b'
            ) {
                if (isAppAdmin) {
                    setGodModeAnimation(true);
                    setGodModeActive(true);
                    setTimeout(() => {
                        setGodModeAnimation(false);
                    }, 2000);
                } else {
                    document.body.style.animation = 'spin 2s linear infinite';
                }
                sequence.current = [];
            }

            if (sequence.current.length > 8) {
                sequence.current = [];
            }
        };

        document.body.addEventListener('keydown', handleKeyDown);

        return () => {
            document.body.removeEventListener('keydown', handleKeyDown);
        };
    }, [isAppAdmin]);

    const isDesktop = useWidth();

    /**
     * Sets the FlashMessage component to show for 2500 ms
     * @param {String} message Message to show to user
     * @param {String} style Style of message oneOf warning || success
     */
    const showFlash = (message: string, style?: 'error' | 'info' | 'success' | 'warning' | null) => {
        setFlash({
            showFlash: true,
            flashMessageText: message,
            flashStyle: style,
        });
        setTimeout(() => {
            setFlash({
                showFlash: false,
                flashMessageText: '',
                flashStyle: null,
            });
        }, 2500);
    };

    const openConfirmDialog = ({ title, message, actionCallback, confirmText, cancelText }: GetConfirmationOptions) => {
        setConfirmationOpen(true);
        setConfirmationConfig({
            title,
            message,
            actionCallback,
            confirmText,
            cancelText,
        });
    };

    const resetDialog = () => {
        setConfirmationOpen(false);
        setConfirmationConfig(null);
    };

    const onConfirm = () => {
        resetDialog();
        if (confirmationConfig?.actionCallback) confirmationConfig?.actionCallback(true);
    };

    const onDismiss = () => {
        resetDialog();
        if (confirmationConfig?.actionCallback) confirmationConfig?.actionCallback(false);
    };

    const openAlert = ({
        message,
        severity,
    }: {
        message: string | React.ReactNode;
        severity?: 'success' | 'info' | 'warning' | 'error';
    }) => {
        setAlertOpen(true);
        setAlertConfig({ message, severity });
    };

    const onAlertClose = () => {
        setAlertOpen(false);
        setAlertConfig({});
    };

    const values = useMemo(
        () => ({
            showFlash,
            openConfirmDialog,
            openAlert,
            isDesktop,
            flash,
            isAppAdmin,
            setGodModeActive,
            godModeActive,
        }),
        [isDesktop, flash, isAppAdmin, setGodModeActive, godModeActive],
    );

    return (
        <UIContext.Provider value={values}>
            {godModeAnimation && (
                <div className="fixed bottom-0 left-0 right-0 top-0 z-[9999] flex items-center justify-center bg-black bg-opacity-70 text-5xl">
                    <div
                        style={{
                            animation: 'shake 0.5s cubic-bezier(.36,.07,.19,.97) both',
                            animationIterationCount: '100',
                            color: '#FF4500',
                        }}
                    >
                        <style>{shakeAnimation}</style>
                        God Mode Activated
                    </div>
                </div>
            )}
            <ConfirmationDialog
                open={confirmationOpen}
                title={confirmationConfig?.title || ''}
                message={confirmationConfig?.message || ''}
                cancelText={confirmationConfig?.cancelText}
                confirmText={confirmationConfig?.confirmText}
                onConfirm={onConfirm}
                onDismiss={onDismiss}
            />
            <AlertDialog open={alertOpen} onClose={onAlertClose} message={alertConfig?.message} severity={alertConfig?.severity} />
            {godModeActive && (
                <div
                    className="absolute bottom-2.5 left-[70px] z-[9998] cursor-pointer rounded bg-black bg-opacity-20 px-2.5 py-1 text-[#FF4500]"
                    onClick={() => setGodModeActive(false)}
                >
                    Deactivate God Mode
                </div>
            )}
            {children}
        </UIContext.Provider>
    );
};

export default UIProvider;

/**
 * @deprecated use the one from @wayste/sour-context instead
 */
const useConfirmationDialog = () => {
    const { openConfirmDialog } = useContext(UIContext);
    const getConfirmation = ({ ...options }: GetConfirmationOptions) =>
        new Promise((res) => {
            openConfirmDialog({ ...options, actionCallback: res });
        });
    return { getConfirmation };
};

export { useConfirmationDialog };

const useAlertDialog = () => {
    const { openAlert } = useContext(UIContext);
    const getAlert = ({ ...options }: { message: string; severity?: 'success' | 'info' | 'warning' | 'error' }) =>
        openAlert({ ...options });
    return { getAlert };
};

export { useAlertDialog };
