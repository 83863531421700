import React, { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Order } from '@alliance-disposal/transport-types';
import { Button, Checkbox, Dialog, Textarea } from '@wayste/sour-ui';
import { format } from 'date-fns';
import { needsAttentionStrings } from '../utils/shared-types';

const dateFormat = 'EEE MM/dd/yy hh:mm';

type Props = {
    order: Order.AllianceOrderTransport;
    hideAddButton?: boolean;
    collapsible?: boolean;
};

const InternalOrderNotes = ({ order, hideAddButton, collapsible }: Props) => {
    const client = useWaysteClient();
    const userIDToNameMap = client.profile().adminPortal.getRosterFromMemory().idToProfileMap;
    const [open, setOpen] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [newNote, setNewNote] = useState('');
    const [needsAttention, setNeedsAttention] = useState(order.needsAttention);
    const [isIssue, setIsIssue] = useState(false);
    const [issueParty, setIssueParty] = useState('');
    const [responsibleUserID, setResponsibleUserID] = useState('');
    const [monetaryLoss, setMonetaryLoss] = useState<'' | number>('');
    const [collapsed, setCollapsed] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, [order]);

    const handleClose = () => {
        setOpen(false);
        setNewNote('');
        setShowAdd(false);
        setIsIssue(false);
        setIssueParty('');
        setResponsibleUserID('');
        setMonetaryLoss('');
    };

    const handleNewNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewNote(event.target.value);
    };

    const handleCollapseToggle = () => {
        setCollapsed(!collapsed);
    };

    const handleSubmit = async () => {
        if (needsAttention !== order.needsAttention) {
            const needsAttentionItem = {
                note: needsAttention ? needsAttentionStrings[0] : needsAttentionStrings[1],
                isIssue: false,
                issueParty: '',
            };

            await client.order().adminPortal.update(order.id, { needsAttention });

            await client.order().adminPortal.notes.create(order.id, needsAttentionItem);

            if (!needsAttention && !newNote) {
                handleClose();
                return;
            }
        }
        const newItem: Order.AllianceOrderInternalNoteInput = {
            note: newNote,
            isIssue: isIssue,
            issueParty: isIssue ? issueParty : '',
            responsibleUserID: responsibleUserID || undefined,
            monetaryLoss: Number(monetaryLoss) || undefined,
        };

        try {
            await client.order().adminPortal.notes.create(order.id, newItem);
        } catch (error) {
            console.error('Error creating internal note', error);
        }

        handleClose();
    };

    const handleNeedsAttentionChange = (value: boolean) => {
        if (value !== order.needsAttention) {
            setNeedsAttention(value);
        } else {
            setNeedsAttention(order.needsAttention);
        }
    };

    if (collapsed)
        return (
            <button className="btn-primary-text-only" onClick={handleCollapseToggle} type="button">
                Expand Internal Notes
            </button>
        );

    return (
        <>
            {collapsible ? (
                <button className="btn-primary-text-only" onClick={handleCollapseToggle} type="button">
                    Hide Internal Notes
                </button>
            ) : null}
            <div>
                {order.internalNotes.map((note, index) => (
                    <div
                        key={`${note.timestamp}`}
                        style={{
                            marginBottom: index !== order.internalNotes.length - 1 ? 7 : 0,
                        }}
                    >
                        <div style={{ marginBottom: 3 }}>
                            {note.timestamp ? format(new Date(note.timestamp), dateFormat) : null}
                            {' - '}
                            {userIDToNameMap[note.userID]?.fullName}
                        </div>
                        {note.isIssue ? (
                            <div style={{ marginBottom: 3 }}>
                                <span style={{ fontWeight: 'bold', color: 'red' }}>Issue</span>
                                {' - '}
                                {note.issueParty}
                                {note.responsibleUserID
                                    ? ` ${userIDToNameMap[note?.responsibleUserID ? note?.responsibleUserID : '']?.fullName} $${
                                          note.monetaryLoss || '0'
                                      }`
                                    : null}
                            </div>
                        ) : null}
                        <span
                            style={{
                                color: needsAttentionStrings.includes(note.note) ? '#CC5500' : undefined,
                            }}
                        >
                            {note.note}
                        </span>
                    </div>
                ))}
                {hideAddButton ? null : (
                    <button className="btn-primary-text-only px-2.5 py-1" onClick={() => setOpen(true)}>
                        Add New Note
                    </button>
                )}
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                className="max-w-screen-lg"
                styledTitle="Internal Order Notes"
                variant="underlined-header"
            >
                <div className="w-full overflow-x-auto">
                    <table className="w-full border-collapse border-spacing-0 text-sm">
                        <tbody>
                            <tr className="border-b">
                                <td className="w-44 px-4 py-1.5">Date</td>
                                <td className="w-48 px-4 py-1.5">User</td>
                                <td className="px-4 py-1.5">Note</td>
                            </tr>
                            {order.internalNotes.map((note) => (
                                <tr className="border-b" key={`${note.timestamp}`}>
                                    <td className="w-44 px-4 py-1.5">
                                        {note.timestamp ? format(new Date(note.timestamp), dateFormat) : null}
                                    </td>
                                    <td className="w-48 px-4 py-1.5">{userIDToNameMap[note.userID]?.fullName}</td>
                                    <td className="px-4 py-1.5">{note.note}</td>
                                </tr>
                            ))}
                            {showAdd && (
                                <>
                                    <tr>
                                        <td className="w-44 px-4 py-1.5 text-center">
                                            <Checkbox
                                                label="Needs Attention"
                                                inputProps={{
                                                    checked: needsAttention,
                                                    onChange: (event) => handleNeedsAttentionChange(event.target.checked),
                                                    disabled: !order.needsAttention,
                                                }}
                                            />
                                        </td>
                                        <td className="px-4 py-1.5" colSpan={2}>
                                            <Textarea
                                                label="New Note"
                                                inputProps={{
                                                    value: newNote,
                                                    onChange: (e) => handleNewNoteChange(e),
                                                    disabled: !order.needsAttention,
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="border-b">
                                        <td className={`px-4 pb-1.5 ${!order.needsAttention ? '' : 'text-right'}`} colSpan={3}>
                                            {!order.needsAttention ? (
                                                <p className="text-red-600">
                                                    Internal Order Notes is no longer in use. Use the Internal Tickets section. If you need
                                                    to add an order to "Needs Attention" simply add the Needs Attention <i>tag</i> to the
                                                    ticket you create.
                                                </p>
                                            ) : (
                                                <Button
                                                    className="btn-primary-text-only"
                                                    onClick={handleSubmit}
                                                    disabled={
                                                        (needsAttention === order.needsAttention && !newNote) ||
                                                        (needsAttention !== order.needsAttention && needsAttention && !newNote) ||
                                                        (isIssue && !issueParty) ||
                                                        (issueParty === 'Sourgum' && !responsibleUserID)
                                                    }
                                                    loading={loading}
                                                >
                                                    Save
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
                <Button className="btn-primary mt-4" onClick={() => setShowAdd(true)} loading={loading}>
                    Add New Note
                </Button>
                <div className="flex justify-end">
                    <button className="btn-dark-grey-outlined" onClick={handleClose}>
                        Close
                    </button>
                </div>
            </Dialog>
        </>
    );
};

export default InternalOrderNotes;
