import React, { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { SourFiltersOld, SourSearchOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { Button, Loading } from '@wayste/sour-ui';
import { useFormContext } from 'react-hook-form';
import { customerStartData } from './service-create-form-props';

const SelectCustomerSubForm = () => {
    const client = useWaysteClient();
    const [isLoading, setIsLoading] = useState(false);

    const { setValue, getValues, watch } = useFormContext();
    const watchCustomerID = watch('customer.id');
    const watchFirstName = watch('customer.contacts.0.firstName');
    const watchLastName = watch('customer.contacts.0.lastName');
    const watchCompanyName = watch('customer.companyName');

    const onCustomerSelect = async (recordID?: string) => {
        if (!recordID) {
            setValue('customer', customerStartData);
            return;
        }
        setIsLoading(true);
        client
            .customer()
            .adminPortal.fetch(recordID)
            .then((response) => {
                setValue('customer', response);
                const formData = getValues();
                // onDump does not apply to non-roll-off services. Making onDump switch to default to before delivery
                const cleanPaymentTerm =
                    response.defaultPaymentSettings.paymentTerm === 'onDump' ? 'onCharge' : response.defaultPaymentSettings.paymentTerm;
                setValue('serviceGrouping', {
                    ...formData.serviceGrouping,
                    paymentTerm: cleanPaymentTerm,
                    paymentMethod: response.defaultPaymentSettings.paymentMethod,
                });
            })
            .catch((error) => {
                console.log('Error fetching customer', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="flex flex-col items-end md:flex-row md:items-center">
            <div className="w-[75%]">
                {isLoading && <Loading fullScreen />}
                {watchCustomerID ? (
                    <div className="text-xl">
                        <span className="opacity-70">Customer: </span>
                        {watchCompanyName || `${watchFirstName} ${watchLastName}`}
                    </div>
                ) : (
                    <SourSearchWrapperOld
                        onNavigate={(_, id) => onCustomerSelect(id)}
                        defaultFilters={{
                            query: {
                                type: 'sourgum-customer',
                            },
                        }}
                        options={{
                            application: 'aap',
                            apiKey: import.meta.env.VITE_ELASTIC_KEY,
                            environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                        }}
                    >
                        <div className="flex w-full flex-row justify-between space-x-4">
                            <SourSearchOld
                                options={{
                                    searchPopoverFixed: true,
                                    showTips: false,
                                    showMessages: false,
                                    resultSize: 'w-[600px]',
                                    placeholder: 'Search for a customer',
                                }}
                            />
                            <div className="hidden">
                                <SourFiltersOld />
                            </div>
                        </div>
                    </SourSearchWrapperOld>
                )}
            </div>
            <Button className="btn-dark-grey-outlined mt-5 md:ml-7 md:mt-0" onClick={() => onCustomerSelect(undefined)} type="button">
                Create New Customer
            </Button>
        </div>
    );
};

export default SelectCustomerSubForm;
