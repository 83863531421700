import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { CurrencyTextField, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { SiteServiceBillingFrequencyLabels, SiteServiceServiceFrequencyLabels } from '../../utils';

const USVendorQuoteSubForm = ({ index }: { index: number }) => {
    const client = useWaysteClient();
    const [serviceSkusLoading, setServiceSkusLoading] = useState(false);
    const [serviceFamilyOptions, setServiceFamilyOptions] = useState<UniversalService.ServiceTypeFamily[]>([]);
    const [selectedServiceFamily, setSelectedServiceFamily] = useState('');
    const [serviceTypeOptions, setServiceTypeOptions] = useState<UniversalService.ServiceType[]>([]);

    const { control } = useFormContext();

    const handleGetServiceFamilies = async () => {
        setServiceSkusLoading(true);
        try {
            const response = await client.universalService().serviceFamily.query();
            setServiceFamilyOptions(response.results);
        } catch (error) {
            console.warn('error: ', error);
        } finally {
            setServiceSkusLoading(false);
        }
    };

    useEffect(() => {
        handleGetServiceFamilies();
    }, []);

    const handleSelectServiceFamily = (family: UniversalService.ServiceTypeFamily) => {
        setSelectedServiceFamily(family.id);
        setServiceTypeOptions(family.serviceTypes);
    };

    return (
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
            <Controller
                name={`haulerQuotes[${index}].haulerName`}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'Hauler name is required',
                    },
                }}
                render={({ field, fieldState }) => (
                    <TextField
                        label="Hauler name"
                        required
                        error={fieldState.error}
                        inputProps={{
                            ...field,
                        }}
                    />
                )}
            />
            <Controller
                name={`haulerQuotes[${index}].serviceFamilyID`}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'A service family is required',
                    },
                }}
                render={({ field, fieldState }) => (
                    <Select
                        required
                        error={fieldState.error}
                        value={field.value}
                        onSelect={() => {}}
                        label="Service family"
                        loading={serviceSkusLoading}
                    >
                        {serviceFamilyOptions.map((item) => (
                            <SelectOption
                                key={item.id}
                                value={item.id}
                                onClick={() => {
                                    field.onChange(item.id);
                                    handleSelectServiceFamily(item);
                                }}
                            >
                                {item.name}
                            </SelectOption>
                        ))}
                    </Select>
                )}
            />
            <Controller
                name={`haulerQuotes[${index}].serviceTypeID`}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'A service type is required',
                    },
                }}
                render={({ field, fieldState }) => (
                    <Select
                        required
                        error={fieldState.error}
                        value={field.value}
                        onSelect={(value) => field.onChange(value)}
                        label="Service sku"
                        disabled={!selectedServiceFamily}
                    >
                        {serviceTypeOptions.map((item) => (
                            <SelectOption key={item.id} value={item.id}>
                                {item.name}
                            </SelectOption>
                        ))}
                    </Select>
                )}
            />
            <Controller
                name={`haulerQuotes[${index}].quantity`}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'A quantity is required',
                    },
                }}
                render={({ field, fieldState }) => (
                    <TextField
                        error={fieldState.error}
                        required
                        type="number"
                        label="Quantity"
                        inputProps={{
                            ...field,
                        }}
                    />
                )}
            />
            <Controller
                name={`haulerQuotes[${index}].frequency`}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'A service frequency is required',
                    },
                }}
                render={({ field, fieldState }) => (
                    <Select
                        required
                        error={fieldState.error}
                        value={field.value}
                        onSelect={(value) => field.onChange(value)}
                        label="Service frequency"
                    >
                        {Object.entries(SiteServiceServiceFrequencyLabels).map((item) => (
                            <SelectOption key={`quote-site-service-frequency-${item[0]}`} value={item[0]}>
                                {item[1]}
                            </SelectOption>
                        ))}
                    </Select>
                )}
            />
            <Controller
                name={`haulerQuotes[${index}].billingFrequency`}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'A billing frequency is required',
                    },
                }}
                render={({ field, fieldState }) => (
                    <Select
                        required
                        error={fieldState.error}
                        value={field.value}
                        onSelect={(value) => field.onChange(value)}
                        label="Billing frequency"
                    >
                        {Object.entries(SiteServiceBillingFrequencyLabels).map((item) => (
                            <SelectOption key={`quote-site-service-billing-frequency-${item[0]}`} value={item[0]}>
                                {item[1]}
                            </SelectOption>
                        ))}
                    </Select>
                )}
            />
            <Controller
                name={`haulerQuotes[${index}].price`}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'A quoted price is required',
                    },
                }}
                render={({ field, fieldState }) => (
                    <CurrencyTextField
                        useCents
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                        error={fieldState.error}
                        required
                        helperText="Use 0 if not yet quoted"
                    />
                )}
            />
            {/* QP */}
        </div>
    );
};

export default USVendorQuoteSubForm;
