import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Invoice } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { SourSearchOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { Button, Dialog, TextField } from '@wayste/sour-ui';
import { generateLineItemFromServiceEvent } from '@wayste/utils';
import { useForm } from 'react-hook-form';
import { BillingContext } from '../../context';

export interface EditPayableFormProps {
    invoiceNumber: string;
    haulerID: string;
    haulerName: string;
    internalNotes?: string;
}

interface Props {
    open: boolean;
    onClose: () => void;
    payable?: Invoice.PayableTransport;
    payableIndex?: number;
}

const EditPayableModal = ({ open, onClose, payable, payableIndex }: Props) => {
    const client = useWaysteClient();
    const { useConfirmationDialog } = useSourContext();
    const { getConfirmation } = useConfirmationDialog();
    const { selectedOrder, handleCreateNewPayable, handlePayableChanges, selectedOrderType } = useContext(BillingContext);
    const [searchForHaulers, setSearchForHaulers] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const checkIfVendorPayableAlreadyExists = async (vendorID: string, invoiceNumber: string) => {
        const payablesQuery = await client.invoice().adminPortal.payable.query({ haulerID: vendorID, invoiceNumber });
        if (payablesQuery.results.length === 0) return true;
        const foundSameOrder = payablesQuery.results.find(
            (payable) =>
                payable.invoiceDetails.orderID === selectedOrder?.id || payable.invoiceDetails.serviceOrderID === selectedOrder?.id,
        );
        if (foundSameOrder) return true;
        const confirmed = await getConfirmation({
            title: 'Duplicate Invoice',
            message:
                'This Invoice Number for this Vendor already exists on another order. Would you still like to create a payable for this order for the same Vendor and Invoice Number?',
            confirmText: 'Create Payable',
            cancelText: 'Cancel',
        });
        return confirmed;
    };

    const onFormSubmit = async (values: EditPayableFormProps) => {
        setIsLoading(true);
        const data = { ...values };
        data.invoiceNumber = data.invoiceNumber.trim();
        if (!payable) {
            const confirmed = await checkIfVendorPayableAlreadyExists(data.haulerID, data.invoiceNumber);
            if (!confirmed) {
                setIsLoading(false);
                return;
            }
            if (selectedOrderType === 'roll-off') {
                handleCreateNewPayable(data);
            } else if (selectedOrderType === 'universal-service') {
                handleCreateNewPayable({
                    ...data,
                    lineItems: selectedOrder?.serviceOrder?.events
                        .filter((event) => event.unitCost)
                        .map((event) => {
                            return generateLineItemFromServiceEvent(
                                event,
                                {
                                    mode: 'payable',
                                },
                                selectedOrder.serviceOrder,
                            );
                        }),
                });
            }
        } else {
            if (payableIndex !== undefined) {
                payable.invoiceDetails.invoiceNumber = data.invoiceNumber;
                payable.invoiceDetails.internalNotes = data.internalNotes || payable.invoiceDetails.internalNotes;
                payable.haulerID = data.haulerID || payable.haulerID;
                payable.vendorName = data.haulerName || payable.vendorName;
                handlePayableChanges(payable, payableIndex);
            }
        }
        setIsLoading(false);
        onClose();
    };

    const onSearchForHauler = async () => {
        setSearchForHaulers(true);
    };

    const {
        setValue,
        watch,
        formState: { isValid },
        register,
        handleSubmit,
        reset,
    } = useForm<EditPayableFormProps>({
        mode: 'all',
        defaultValues: {
            invoiceNumber: '',
            haulerID: '',
            haulerName: '',
            internalNotes: '',
        },
    });

    const watchHaulerName = watch('haulerName');
    const watchHaulerID = watch('haulerID');

    useEffect(() => {
        if (selectedOrder || payable) {
            const thisHaulerID = payable?.haulerID || selectedOrder?.vendorID || '';
            reset({
                invoiceNumber: payable?.invoiceDetails.invoiceNumber || '',
                haulerID: thisHaulerID,
                haulerName: payable?.vendorName ?? '',
                internalNotes: payable?.invoiceDetails.internalNotes || '',
            });
        } else {
            reset({
                invoiceNumber: '',
                haulerID: '',
                haulerName: '',
                internalNotes: '',
            });
        }
    }, [payable, selectedOrder, reset]);

    const handleHaulerClick = (hauler: Hauler.HaulerWithAapTransport, haulerID: string) => {
        setValue('haulerID', haulerID);
        setValue('haulerName', hauler.name || payable?.vendorName || '');
        setSearchForHaulers(false);
    };

    // DEFINE ON NAVIGATE LOGIC
    const onNavigate = (hauler: Hauler.HaulerWithAapTransport, recordID: string) => {
        handleHaulerClick(hauler, recordID);
    };

    if (!open) return null;

    return (
        <Dialog
            className="w-full overflow-visible"
            styledTitle={`${payable ? 'Edit' : 'Add'} Hauler Payable`}
            onClose={onClose}
            open={open}
        >
            <form className="flex flex-col gap-4" onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                    <div>
                        <span style={{ color: 'rgba(0, 0, 0, 0.68)' }}>For Hauler:</span>
                        <b>{watchHaulerName}</b>
                    </div>
                </div>
                <div>
                    {!searchForHaulers ? (
                        <button className="btn-primary-text-only" color="secondary" onClick={onSearchForHauler}>
                            Change Hauler
                        </button>
                    ) : (
                        <div>
                            <SourSearchWrapperOld
                                onNavigate={onNavigate}
                                highlight={true}
                                defaultFilters={{
                                    query: {
                                        type: 'sourgum-vendor',
                                    },
                                }}
                                options={{
                                    application: 'aap',
                                    apiKey: import.meta.env.VITE_ELASTIC_KEY,
                                    environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                                }}
                            >
                                <div className="flex w-full flex-row justify-between space-x-4">
                                    <SourSearchOld
                                        options={{
                                            searchPopoverFixed: false,
                                            showTips: false,
                                            showMessages: false,
                                            resultSize: 'w-[700px]',
                                            searchPositionFixed: true,
                                            placeholder: 'Search vendor',
                                        }}
                                    />
                                </div>
                            </SourSearchWrapperOld>
                        </div>
                    )}
                </div>
                <div>
                    <TextField
                        label="Invoice number"
                        required={true}
                        inputProps={{
                            ...register('invoiceNumber', { required: 'Invoice number is required' }),
                        }}
                    />
                </div>
                <div className="w-full">
                    <TextField label="Internal Notes" inputProps={{ ...register('internalNotes') }} />
                </div>

                <div className="flex justify-end gap-4 border-t pt-4">
                    <Button className="btn-dark-grey-outlined" onClick={onClose} disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button className="btn-primary" type="submit" disabled={!isValid || !watchHaulerID} loading={isLoading}>
                        Save
                    </Button>
                </div>
            </form>
        </Dialog>
    );
};

export default EditPayableModal;
