import React, { useEffect, useRef, useState } from 'react';
import {
    AddressTransport,
    Customer,
    Hauler,
    Invoice,
    Material,
    MaterialLabels,
    Order,
    UniversalService,
} from '@alliance-disposal/transport-types';
import { SourFiltersOld, SourSearchOld, SourSearchResponseOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { formatE164ToUSPhoneNumber, formatISODateString, getGroupingServiceDateRange, moneyFormatter } from '@wayste/utils';
import { formatServiceAddress } from '@wayste/utils';
import { getRouterPath } from '@wayste/utils';
import { useHistory } from 'react-router-dom';
import { GridCellStatus } from '../../pages/billing/components/renderCellReceivablesStatus';
import UniversalServicesStatusPopper from '../../pages/site-services/components/UniversalServicesStatusPopper';
import { routes } from '../../utils';
import OrderStatusChanger from '../OrderStatusChanger';

const GlobalSearch = () => {
    const searchRef = useRef<HTMLInputElement>(null);
    const history = useHistory();
    const [searchResults, setSearchResults] = useState<SourSearchResponseOld['results']['hits']['hits'][0]['_source'][]>([]);

    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.focus();
        }
    }, []);

    // DEFINE ON NAVIGATE LOGIC
    const onNavigate = (entity: any, recordID: string, name: string) => {
        const path = getRouterPath(name, recordID, routes);
        history.push(path);
    };

    // DEFINE ON RESULTS LOGIC
    const onResults = (response: SourSearchResponseOld) => {
        setSearchResults(response?.results?.hits?.hits || []);
    };

    return (
        <div className="mx-auto w-full max-w-7xl px-6 pt-5">
            <div className="shadow-dark mb-6 rounded bg-white p-4">
                <div className="w-full md:p-10">
                    <p className="mb-4">Use this search box for ALL searches.</p>
                    <div className="item-center flex w-full flex-row justify-between">
                        <SourSearchWrapperOld
                            options={{
                                application: 'aap',
                                apiKey: import.meta.env.VITE_ELASTIC_KEY,
                                environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                            }}
                            onNavigate={onNavigate}
                            onResults={onResults}
                            createQueryParams={{ method: 'all' }}
                            highlight={true}
                        >
                            <div className="flex w-full flex-row justify-between space-x-4">
                                <SourSearchOld
                                    ref={searchRef}
                                    options={{
                                        searchPopoverFixed: false,
                                        showTips: true,
                                        showMessages: true,
                                        placeholder: 'search for anything',
                                        showResults: false,
                                    }}
                                />
                                <SourFiltersOld />
                            </div>
                        </SourSearchWrapperOld>
                    </div>
                </div>
            </div>
            <NewSearchList results={searchResults} />
        </div>
    );
};

export default GlobalSearch;

const NewSearchList = ({ results }: { results: SourSearchResponseOld['results']['hits']['hits'][0]['_source'][] }) => {
    const history = useHistory();
    const [orderResults, setOrderResults] = useState<Order.OrderTransport[]>([]);
    const [customerResults, setCustomerResults] = useState<Customer.AllianceCustomerTransport[]>([]);
    const [haulerResults, setHaulerResults] = useState<Hauler.HaulerWithAapTransport[]>([]);
    const [leadResults, setLeadResults] = useState<Customer.AllianceLeadTransport[]>([]);
    const [receivableResults, setReceivableResults] = useState<Invoice.ReceivableTransport[]>([]);
    const [payableResults, setPayableResults] = useState<Invoice.PayableTransport[]>([]);
    const [serviceGroupingResults, setServiceGroupingResults] = useState<UniversalService.ServiceGrouping[]>([]);

    useEffect(() => {
        /* The above code is filtering the results from the Algolia call into three different arrays baed on type. */
        if (results) {
            const orders: SourSearchResponseOld['results']['hits']['hits'][0]['_source'][] = results.filter(
                (item: any) => item._index === 'sourgum-order-production' || item._index === 'sourgum-order-staging',
            );
            const customers: SourSearchResponseOld['results']['hits']['hits'][0]['_source'][] = results.filter(
                (item: any) => item._index === 'sourgum-customer-production' || item._index === 'sourgum-customer-staging',
            );
            const haulers: SourSearchResponseOld['results']['hits']['hits'][0]['_source'][] = results.filter(
                (item: any) => item._index === 'sourgum-vendor-production' || item._index === 'sourgum-vendor-staging',
            );
            const leads: SourSearchResponseOld['results']['hits']['hits'][0]['_source'][] = results.filter(
                (item: any) => item._index === 'sourgum-lead-production' || item._index === 'sourgum-lead-staging',
            );
            const receivables: SourSearchResponseOld['results']['hits']['hits'][0]['_source'][] = results.filter(
                (item: any) => item._index === 'sourgum-receivable-production' || item._index === 'sourgum-receivable-staging',
            );
            const payables: SourSearchResponseOld['results']['hits']['hits'][0]['_source'][] = results.filter(
                (item: any) => item._index === 'sourgum-payable-production' || item._index === 'sourgum-payable-staging',
            );
            const serviceGroupings: SourSearchResponseOld['results']['hits']['hits'][0]['_source'][] = results.filter(
                (item: any) => item._index === 'sourgum-service-grouping-production' || item._index === 'sourgum-service-grouping-staging',
            );

            setOrderResults(orders.map((item: any) => item?._source));
            setCustomerResults(customers.map((item: any) => item._source));
            setHaulerResults(haulers.map((item: any) => item._source));
            setLeadResults(leads.map((item: any) => item._source));
            setReceivableResults(receivables.map((item: any) => item._source));
            setPayableResults(payables.map((item: any) => item._source));
            setServiceGroupingResults(serviceGroupings.map((item: any) => item._source));
        }
    }, [results]);

    const handleHaulerRowClick = (haulerId: string) => {
        history.push(routes.haulers.details(haulerId));
    };

    const handleCustomerRowClick = (id: string) => {
        history.push(routes.customers.details(id));
    };

    const handleOrderRowClick = (id: string) => {
        history.push(routes.orders.details(id), { modal: true });
    };

    const handleServiceOrderRowClick = (id: string) => {
        history.push(routes.universalServices.serviceGrouping.details(id));
    };

    const handleLeadRowClick = (id: string) => {
        history.push(routes.leads.details(id), { modal: true });
    };

    const handleReceivableRowClick = (id: string) => {
        history.push(routes.billing.details(id));
    };

    const findCorrectContactHit = (hit: any, contacts: any) => {
        if (!hit || !hit._highlightResult || !hit._highlightResult.contacts) return '';
        if (hit._highlightResult.contacts.length <= 0) {
            return '';
        }
        if (contacts.length === 0) return null;
        if (contacts.length === 1) return contacts[0];
        let name = null;

        hit._highlightResult.contacts.forEach((contact: any) => {
            if (contact?.firstName?.matchLevel === 'full' || contact?.lastName?.matchLevel === 'full') {
                //search contacts array for matching id
                contacts.forEach((c: any) => {
                    if (contact && contact.id && contact.id.value === c.id) {
                        name = c;
                    }
                });
            }
        });

        if (name === null) {
            name = contacts.find((contact: any) => contact.primaryContact);
        }
        return name;
    };

    if (!results && orderResults.length === 0) return <div />;

    if (results?.length === 0 && orderResults.length === 0) {
        return (
            <div className="shadow-dark rounded bg-white p-4">
                <p>No results found.</p>
            </div>
        );
    }

    ////////////////////////////////////////////
    // ORDER TABLE
    ////////////////////////////////////////////

    const OrderTableFull = ({ orders }: { orders: SourSearchResponseOld['results']['hits']['hits'][0]['_source'][] }) => (
        <div className="mb-6 w-full overflow-x-auto">
            <table className="w-full border-collapse border-spacing-0 text-sm">
                <thead>
                    <tr className="whitespace-nowrap border-b text-left [&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal">
                        {['Order #', 'Address', 'Status', 'County', 'Material', 'Hauler', 'DEL Date', 'PU Date', 'Name', 'Company'].map(
                            (item) => (
                                <th key={item}>{item}</th>
                            ),
                        )}
                    </tr>
                </thead>
                <tbody>
                    {orders.map((item: any, index: number) => (
                        <tr
                            key={item.id + index}
                            onClick={() => handleOrderRowClick(item.id)}
                            className="cursor-pointer border-b odd:bg-gray-100 [&>*]:px-4 [&>*]:py-1.5"
                        >
                            <td>{item.orderNumber}</td>
                            <td>{formatServiceAddress(item?.serviceLocation?.address)}</td>
                            <td>
                                <OrderStatusChanger order={item} navigateOnly={true} />
                            </td>
                            <td>{item.serviceLocation.county}</td>
                            <td>{MaterialLabels[item.material as Material]}</td>
                            <td>{item.vendorName || 'No Hauler Assigned'}</td>
                            <td>{item.expectedDeliveryDate ? formatISODateString(item.expectedDeliveryDate, 'EEE MM/dd/yy') : ''}</td>
                            <td>{item.expectedPickupDate ? formatISODateString(item.expectedPickupDate, 'EEE MM/dd/yy') : 'None'}</td>
                            <td>{item?.customerName}</td>
                            <td>{item?.customerCompanyName}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    ////////////////////////////////////////////
    // ORDER TABLE
    ////////////////////////////////////////////

    const ServiceGroupingTable = ({ groupings }: { groupings: SourSearchResponseOld['results']['hits']['hits'][0]['_source'][] }) => (
        <div className="mb-6 w-full overflow-x-auto">
            <table className="w-full border-collapse border-spacing-0 text-sm">
                <thead>
                    <tr className="whitespace-nowrap border-b text-left [&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal">
                        {['Order #', 'Address', 'Status', 'Service', 'Type', 'Customer', 'Vendor', 'Service Range'].map((item) => (
                            <th key={item}>{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {(groupings as UniversalService.ServiceGrouping[]).map((grouping, index: number) => {
                        const status = grouping.serviceOrders.every((serviceOrder) => serviceOrder.cancellationDetails)
                            ? 'CANCELLED'
                            : grouping.serviceOrders.every((serviceOrder) => serviceOrder.status)
                              ? grouping.serviceOrders[0].status
                              : 'MIXED';
                        const order = {
                            id: grouping.id,
                            serviceGrouping: grouping,
                            status: status,
                            serviceRange: getGroupingServiceDateRange(grouping).string,
                            subscription: grouping.type !== 'single' ? 'Subscription' : 'One Time',
                            serviceSKU: [
                                ...new Set(grouping.serviceOrders.map((serviceOrder) => serviceOrder.serviceType.family.name)),
                            ].join(', '),
                            address: grouping.serviceOrders.every((serviceOrder) => serviceOrder.serviceLocation)
                                ? formatServiceAddress(grouping.serviceOrders[0].serviceLocation?.address)
                                : 'Multiple',
                            orderNumber: grouping.orderNumber,
                            customer: grouping.customerCompanyName || grouping.customerName,
                            vendor: grouping.serviceOrders.every((serviceOrder) => !serviceOrder.vendorName)
                                ? ''
                                : grouping.serviceOrders.every((serviceOrder) => serviceOrder.vendorName)
                                  ? grouping.serviceOrders[0].vendorName
                                  : 'Multiple',
                        };

                        return (
                            <tr
                                key={order.id + index}
                                onClick={() => handleServiceOrderRowClick(order.id)}
                                className="cursor-pointer border-b odd:bg-gray-100 [&>*]:px-4 [&>*]:py-1.5"
                            >
                                <td>{order.orderNumber}</td>
                                <td>{order.address}</td>
                                <td>
                                    <UniversalServicesStatusPopper
                                        display="label"
                                        serviceOrder={
                                            {
                                                status: order.status,
                                                cancellationDetails: order.status === 'CANCELLED' ? {} : undefined,
                                            } as UniversalService.ServiceOrder
                                        }
                                    />
                                </td>
                                <td>{order.serviceSKU}</td>
                                <td>{order.subscription}</td>
                                <td>{order.customer}</td>
                                <td className={!order.vendor ? 'text-gray-500' : ''}>{order.vendor || 'Not Assigned'}</td>
                                <td>{order.serviceRange ? order.serviceRange : 'None'}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

    ////////////////////////////////////////////
    // CUSTOMER TABLE
    ////////////////////////////////////////////

    const CustomerTable = ({ customers }: { customers: Customer.AllianceCustomerTransport[] }) => (
        <div className="mb-6 w-full overflow-x-auto">
            <table className="w-full border-collapse border-spacing-0 text-sm">
                <thead>
                    <tr className="border-b text-left [&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal">
                        {['Customer Name', 'Company', 'Email', 'Phone', 'Account #'].map((item) => (
                            <th key={item}>{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {customers.map((item: Customer.AllianceCustomerTransport, index: number) => {
                        const matchedContact = findCorrectContactHit(item, item.contacts) || item.contacts[0];
                        return (
                            <tr
                                key={item.id + index}
                                className="cursor-pointer border-b odd:bg-gray-100 [&>*]:px-4 [&>*]:py-1.5"
                                onClick={() => handleCustomerRowClick(item.id)}
                            >
                                <td>{matchedContact?.firstName + ' ' + matchedContact?.lastName}</td>
                                <td>{item.companyName}</td>
                                <td>{matchedContact?.email}</td>
                                <td>{formatE164ToUSPhoneNumber(matchedContact?.phoneNumber)}</td>
                                <td>{item?.customerNumber}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

    ////////////////////////////////////////////
    // RECEIVABLE TABLE
    ////////////////////////////////////////////

    const ReceivableTable = ({ receivables }: { receivables: Invoice.ReceivableTransport[] }) => (
        <div className="mb-6 w-full overflow-x-auto">
            <table className="w-full border-collapse border-spacing-0 text-sm">
                <thead>
                    <tr className="border-b text-left [&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal">
                        {['Invoice #', 'Amount', 'Status', 'Customer Name', 'Company'].map((item) => (
                            <th key={item}>{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {receivables.map((item: Invoice.ReceivableTransport, index: number) => {
                        return (
                            <tr
                                key={item.id + index}
                                className="cursor-pointer border-b odd:bg-gray-100 [&>*]:px-4 [&>*]:py-1.5"
                                onClick={() => handleReceivableRowClick(item?.invoiceDetails?.orderID || '')}
                            >
                                <td>
                                    {item.invoiceDetails.orderNumber}-{item.invoiceDetails.invoiceNumber}
                                </td>
                                <td>{moneyFormatter(item.invoiceDetails.total)}</td>
                                <td>
                                    <GridCellStatus value={item.invoiceDetails.status} />
                                </td>
                                <td>{item?.customerName}</td>
                                <td>{item?.customerCompanyName}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

    ////////////////////////////////////////////
    // PAYABLES TABLE
    ////////////////////////////////////////////

    const PayablesTable = ({ payables }: { payables: Invoice.PayableTransport[] }) => (
        <div className="mb-6 w-full overflow-x-auto">
            <table className="w-full border-collapse border-spacing-0 text-sm">
                <thead>
                    <tr className="border-b text-left [&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal">
                        {['Invoice #', 'Amount', 'Status', 'Vendor Name'].map((item) => (
                            <th key={item}>{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {payables.map((item: Invoice.PayableTransport, index: number) => {
                        return (
                            <tr
                                key={item.id + index}
                                className="cursor-pointer border-b odd:bg-gray-100 [&>*]:px-4 [&>*]:py-1.5"
                                onClick={() => handleReceivableRowClick(item?.invoiceDetails?.orderID || '')}
                            >
                                <td>
                                    {item.invoiceDetails.orderNumber}-{item.invoiceDetails.invoiceNumber}
                                </td>
                                <td>{moneyFormatter(item.invoiceDetails.total)}</td>
                                <td>
                                    <GridCellStatus value={item.invoiceDetails.status} />
                                </td>
                                <td>{item.vendorName}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

    ////////////////////////////////////////////
    // HAULER TABLE
    ////////////////////////////////////////////
    const HaulerTable = ({ haulers }: { haulers: Hauler.HaulerWithAapTransport[] }) => (
        <div className="mb-6 w-full overflow-x-auto">
            <table className="w-full border-collapse border-spacing-0 text-sm">
                <thead>
                    <tr className="border-b text-left [&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal">
                        {['Hauler Name', 'Contact Name', 'Contact Email', 'Contact Phone'].map((item) => (
                            <th key={item}>{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {haulers.map((item: Hauler.HaulerWithAapTransport, index: number) => {
                        const matchedContact = findCorrectContactHit(item, item.contacts) || item.contacts[0];
                        return (
                            <tr
                                key={item.id + index}
                                className="cursor-pointer border-b odd:bg-gray-100 [&>*]:px-4 [&>*]:py-1.5"
                                onClick={() => handleHaulerRowClick(item.id)}
                            >
                                <td>{item?.name}</td>
                                <td>{matchedContact?.firstName + ' ' + matchedContact?.lastName}</td>
                                <td>{matchedContact?.email}</td>
                                <td>{formatE164ToUSPhoneNumber(matchedContact?.phoneNumber)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

    ////////////////////////////////////////////
    // LEAD TABLE
    ////////////////////////////////////////////

    const LeadsTable = ({ leads }: { leads: Customer.AllianceLeadTransport[] }) => (
        <div className="mb-6 w-full overflow-x-auto">
            <table className="w-full border-collapse border-spacing-0 text-sm">
                <thead>
                    <tr className="border-b text-left [&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal">
                        {['Lead Name', 'Company', 'Email', 'Phone', 'Address'].map((item) => (
                            <th key={item}>{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {leads.map((item: Customer.AllianceLeadTransport, index: number) => {
                        return (
                            <tr
                                key={item.id + index}
                                className="cursor-pointer border-b odd:bg-gray-100 [&>*]:px-4 [&>*]:py-1.5"
                                onClick={() => handleLeadRowClick(item.id)}
                            >
                                <td>{item?.firstName + ' ' + (item?.lastName ? item.lastName : '')}</td>
                                <td>{item.company}</td>
                                <td>{item?.email}</td>
                                {item.phoneNumber && <td>{formatE164ToUSPhoneNumber(item?.phoneNumber)}</td>}
                                {item?.serviceLocation?.address && (
                                    <td>{formatServiceAddress(item?.serviceLocation?.address as AddressTransport) || ''}</td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="w-full">
            <p className="mb-2.5 text-xl">Search Results</p>
            <div className="shadow-dark mb-4 rounded bg-white p-4">
                <p className="mb-1 text-lg">Orders</p>
                {orderResults.length === 0 ? <p className="mb-1">No Order Results</p> : null}
                {orderResults.length > 0 ? <OrderTableFull orders={orderResults} /> : null}
            </div>
            <div className="shadow-dark mb-4 rounded bg-white p-4">
                <p className="mb-1 text-lg">Service Groupings</p>
                {serviceGroupingResults.length === 0 ? (
                    <p className="mb-1">No Service Grouping Results</p>
                ) : (
                    <ServiceGroupingTable groupings={serviceGroupingResults} />
                )}
            </div>
            <div className="shadow-dark mb-4 rounded bg-white p-4">
                <p className="mb-1 text-lg">Customers</p>
                {customerResults.length === 0 ? (
                    <p className="mb-1">No Customers Results</p>
                ) : (
                    <CustomerTable customers={customerResults} />
                )}
            </div>
            <div className="shadow-dark mb-4 rounded bg-white p-4">
                <p className="mb-1 text-lg">Leads</p>
                {leadResults.length === 0 ? <p className="mb-1">No Leads Results</p> : <LeadsTable leads={leadResults} />}
            </div>
            <div className="shadow-dark mb-4 rounded bg-white p-4">
                <p className="mb-1 text-lg">Haulers</p>
                {haulerResults.length === 0 ? <p className="mb-1">No Hauler Results</p> : <HaulerTable haulers={haulerResults} />}
            </div>

            <div className="shadow-dark mb-4 rounded bg-white p-4">
                <p className="mb-1 text-lg">Receivables</p>
                {receivableResults.length === 0 ? (
                    <p className="mb-1">No Receivable Results</p>
                ) : (
                    <ReceivableTable receivables={receivableResults} />
                )}
            </div>

            <div className="shadow-dark mb-4 rounded bg-white p-4">
                <p className="mb-1 text-lg">Payables</p>
                {payableResults.length === 0 ? <p className="mb-1">No Payables Results</p> : <PayablesTable payables={payableResults} />}
            </div>
        </div>
    );
};
