import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Pricing } from '@alliance-disposal/transport-types';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import routes from '../../utils/routes';
import PricingCreate from './PricingCreate';

/**
 * Container to Create an hauler county/material pricing
 */
const PricingCreateContainer = () => {
    const { showFlash } = useContext(UIContext);
    const client = useWaysteClient();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const handleBackButtonClick = () => {
        history.goBack();
    };

    // HANDLE COPY PRICING FROM EXISTING ZONE
    const handleCopyPricing = async (newZoneID: string, zoneToCopyID: string, pricingZones: Pricing.PricingTransport[]) => {
        const existingZone = pricingZones?.find((zone) => zone.id === zoneToCopyID);

        const payload: (Pricing.SGMutateMaterial & { expirationDate?: string | null | undefined })[] | undefined =
            existingZone?.pricingData.map((material) => {
                return {
                    expirationDate: undefined,
                    effectiveDate: new Date().toISOString(),
                    details: {
                        allowOnlineCheckout: material.allowOnlineCheckout,
                        allowForLowerTonLimit: material.allowForLowerTonLimit,
                        doesNotService: material.doesNotService,
                        lastUpdatedAt: new Date().toISOString(),
                        haulerApproved: material.haulerApproved,
                        sourgumApproved: material.sourgumApproved,
                        effectiveDate: undefined,
                        expirationDate: undefined,
                        material: material.material,
                        sizes: material.sizes,
                        tax: material.tax,
                        type: material.type,
                    },
                };
            });

        if (!payload) {
            return;
        }

        try {
            await client.pricing().adminPortal.material.createMany(newZoneID, payload || []);
        } catch (error) {
            console.warn('error', error);
            showFlash('Error Copying Material Pricing', 'warning');
        }
    };

    // HANDLE SUBMIT NEW PRICING ZONE
    const handleSubmit = async (
        data: Pricing.PricingCreateTransport,
        pricingZones?: Pricing.PricingTransport[],
        copyExistingZone?: string,
    ) => {
        setIsLoading(true);
        data.pricingData = [];
        let newPricingZone;
        try {
            newPricingZone = await client.pricing().adminPortal.pricing.create(data);

            showFlash('Pricing Successfully Created', 'success');
        } catch (error) {
            showFlash('An Error Occurred', 'warning');
        }

        if (copyExistingZone && newPricingZone?.id && pricingZones) {
            await handleCopyPricing(newPricingZone.id, copyExistingZone, pricingZones);
        }

        history.replace(`${routes.pricing.list}?state=${data.state}`);

        setIsLoading(false);
    };

    return <PricingCreate onBackButtonClick={handleBackButtonClick} onSubmit={handleSubmit} isLoading={isLoading} />;
};

export default PricingCreateContainer;
