import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Order } from '@alliance-disposal/transport-types';
import { DatePicker, Dialog, Loading, TextField } from '@wayste/sour-ui';
import { formatISODateString } from '@wayste/utils';
import { Disclosure, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import _ from 'lodash';
import { useAlertDialog } from '../../contexts';
import OrderReadyPickUp from '../OrderStatusChanger/OrderReadyPickup';
import SendEmailDialog from '../OrderUpdate/SendEmailDialog';

export const fieldNameMap: Record<Order.ChangeTransport['field'], string> = {
    expectedDeliveryDate: 'Delivery Date',
    expectedPickupDate: 'Pickup Date',
    sharedPickupNotes: 'Pickup Notes',
    sharedDeliveryNotes: 'Delivery Notes',
    expectedSize: 'Size',
    material: 'Material',
    status: 'Status',
};

export const timeAgo = (date: Date) => {
    const minutesAgo = Math.floor((new Date().getTime() - new Date(date).getTime()) / 60000);
    return new Intl.RelativeTimeFormat('en').format(-minutesAgo, 'minute');
};
interface OrderRequestDialogProps {
    orderID: string;
    open: boolean;
    onClose?: () => void;
    order: Order.AllianceOrderTransport;
    customer: any;
}

const ChangeTableRow = ({
    request,
    onSubmitted,
}: {
    request: Order.ChangeRequestTransport;
    onSubmitted?: (update: Order.UpdateChangeRequest, res: Order.ChangeRequestTransport) => void;
}) => {
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const client = useWaysteClient();
    const alert = useAlertDialog();

    const [changeRequestUpdate, setChangeRequestUpdate] = useState<Order.UpdateChangeRequest>({
        id: request.id,
        changes: request.changes.map((change) => ({
            id: change.id,
            status: change.status,
            notes: '',
            amendment: change.newValue,
        })),
    });

    const submit = async () => {
        setSubmitting(true);

        if (!changeRequestUpdate.changes.every((change) => change.status !== 'PENDING')) {
            setSubmitting(false);
            return;
        }

        const update = changeRequestUpdate;

        update.changes.map((change) => {
            // find matching change in request.changes
            const originalChange = request.changes.find((c) => c.id === change.id) as Order.ChangeTransport;

            // make sure the amendment is different from the original value
            if (originalChange.newValue === change.amendment) {
                delete change.amendment;
            } else {
                change.status = 'APPROVED_WITH_CHANGES';
            }

            if (change.notes === '') {
                delete change.notes;
            }

            return change;
        });

        try {
            const res = await client.order().adminPortal.changeRequest.update(update);
            if (onSubmitted) {
                onSubmitted(update, res);
            }
        } catch (error) {
            alert.getAlert({
                message:
                    'Error updating change request, please try again. If the error persists, please contact support. If you are submitting an IT ticket, please include the following error message: ' +
                    JSON.stringify(error),
                severity: 'error',
            });
        }

        setSubmitting(false);
    };

    const rejectChange = (id: string) => {
        setChangeRequestUpdate({
            ...changeRequestUpdate,
            changes: changeRequestUpdate.changes.map((change) => (change.id === id ? { ...change, status: 'REJECTED' } : change)),
        });
    };

    const acceptChange = (id: string) => {
        setChangeRequestUpdate({
            ...changeRequestUpdate,
            changes: changeRequestUpdate.changes.map((change) => (change.id === id ? { ...change, status: 'APPROVED' } : change)),
        });
    };

    function renderChangeValues(change: Order.ChangeTransport) {
        if (change.field === 'expectedDeliveryDate' || change.field === 'expectedPickupDate') {
            return (
                <>
                    <td>{change.oldValue ? format(new Date(change.oldValue?.replace(/-/g, '/')), 'MM/dd/yyyy') : 'N/A'}</td>
                    <td>
                        {edit ? (
                            <div className="max-w-[150px]">
                                <DatePicker
                                    value={
                                        changeRequestUpdate.changes.find((c) => c.id === change.id)?.amendment
                                            ? new Date(
                                                  formatISODateString(
                                                      changeRequestUpdate.changes.find((c) => c.id === change.id)?.amendment,
                                                  ),
                                              )
                                            : ''
                                    }
                                    format="keyboardDate"
                                    onChange={(value) => {
                                        setChangeRequestUpdate({
                                            ...changeRequestUpdate,
                                            changes: changeRequestUpdate.changes.map((c) =>
                                                c.id === change.id
                                                    ? {
                                                          ...c,
                                                          amendment: value ? value.toDateString() : value,
                                                          status: 'PENDING',
                                                      }
                                                    : change,
                                            ),
                                        });
                                    }}
                                    minDate={new Date()}
                                />
                            </div>
                        ) : (
                            formatISODateString(change.newValue)
                        )}
                    </td>
                </>
            );
        }

        return (
            <>
                <td>{change.oldValue}</td>
                <td>
                    {edit ? (
                        <TextField
                            inputProps={{
                                value: changeRequestUpdate.changes.find((c) => c.id === change.id)?.amendment || '',
                                onChange: (e) => {
                                    setChangeRequestUpdate({
                                        ...changeRequestUpdate,
                                        changes: changeRequestUpdate.changes.map((c) =>
                                            c.id === change.id ? { ...c, amendment: e.target.value, status: 'PENDING' } : change,
                                        ),
                                    });
                                },
                                disabled: !edit,
                            }}
                        />
                    ) : (
                        change.newValue
                    )}
                </td>
            </>
        );
    }

    return (
        <>
            <Disclosure>
                <tr key={request.id} className="[&>*]:border-b [&>*]:px-4 [&>*]:py-1.5">
                    <td>
                        <Disclosure.Button className="btn-icon" onClick={() => setOpen(!open)}>
                            {open ? <ChevronUpIcon className="h-6 w-6" /> : <ChevronDownIcon className="h-6 w-6" />}
                        </Disclosure.Button>
                    </td>
                    <td>{timeAgo(new Date(request.metadata.createdAt))}</td>
                    <td>{request.changes.some((change) => change.field === 'status') ? 'Pickup Request' : 'Order Change'}</td>
                    <td></td>
                    <td>
                        {/* Submit Button */}

                        {open ? (
                            <button
                                className="btn-primary w-full"
                                type="submit"
                                disabled={!changeRequestUpdate.changes.every((change) => change.status !== 'PENDING')}
                                onClick={submit}
                            >
                                Submit
                                {submitting && <Loading className="text-white" size="h-4 w-4 ml-2" />}
                            </button>
                        ) : null}
                    </td>
                    {/* Collapsible section that shows all changes */}
                </tr>
                <tr key={request.id + 'changes'}>
                    <td colSpan={7} className={`pb-0 pt-0 ${!open ? 'none' : ''}`}>
                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Disclosure.Panel className="p-0.5">
                                <table className="w-full border-collapse border-spacing-0">
                                    <thead>
                                        <tr className="border-b [&>*]:px-4 [&>*]:py-1.5">
                                            <td>Status</td>
                                            <td>Change</td>
                                            <td>Current</td>
                                            <td>Requested</td>
                                            <td>Actions</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {request.changes.map((change) => (
                                            <tr className="border-b [&>*]:px-4 [&>*]:py-1.5">
                                                <td> {_.startCase(changeRequestUpdate.changes.find((c) => c.id === change.id)?.status)}</td>
                                                <td>{fieldNameMap[change.field]}</td>
                                                {renderChangeValues(change)}
                                                <td className="whitespace-nowrap">
                                                    <>
                                                        <button
                                                            type="button"
                                                            className="btn-icon"
                                                            onClick={() => {
                                                                rejectChange(change.id);
                                                            }}
                                                        >
                                                            <XMarkIcon className="h-6 w-6 text-red-500" />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn-icon"
                                                            onClick={() => {
                                                                setEdit(!edit);
                                                            }}
                                                        >
                                                            <PencilIcon className="text-edit h-6 w-6" />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn-icon"
                                                            onClick={() => {
                                                                acceptChange(change.id);
                                                            }}
                                                        >
                                                            <CheckIcon className="text-success h-6 w-6" />
                                                        </button>{' '}
                                                    </>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Disclosure.Panel>
                        </Transition>
                    </td>
                </tr>
            </Disclosure>
        </>
    );
};

export const ChangeRequestResponse = ({ orderID, open, onClose, order, customer }: OrderRequestDialogProps) => {
    // prettier-ignore
    const [requests, setRequests] = useState<Order.ChangeRequestTransport[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showSendEmails, setShowSendEmails] = useState<boolean>(false);
    const [emailChanges, setEmailChanges] = useState<any[]>([]);
    const client = useWaysteClient();

    const [showPickupDialog, setShowPickupDialog] = useState<string | false>(false);

    useEffect(() => {
        const sub = client.order().adminPortal.changeRequest.subscription.query({ status: ['PENDING'], orderID });

        const observe = sub.receive.subscribe({
            next: async (value) => {
                setRequests(value.results);
                setLoading(false);
                if (value.results.length === 0 && open && onClose) {
                    onClose();
                }
            },
            error: (error) => {
                console.warn('error', error);
                alert('An Error Occurred Getting Change Requests');
                setLoading(false);
            },
        });

        return () => {
            if (sub) {
                observe.unsubscribe();
                sub.unsubscribe();
            }
        };
    }, [open, orderID]);

    const schedulePickup = (id: string) => {
        setShowPickupDialog(id);
    };

    const removeChange = (id: string) => {
        setRequests(requests.filter((r) => r.id !== id));
    };

    const changeSubmitted = (update: Order.UpdateChangeRequest, request: Order.ChangeRequestTransport) => {
        setEmailChanges(
            request.changes.map((change) => {
                const updatePayload = update.changes.find((c) => c.id === change.id);

                return {
                    label: fieldNameMap[change.field],
                    oldValue: change.oldValue,
                    newValue: updatePayload?.amendment ? updatePayload.amendment : change.newValue,
                };
            }),
        );

        setShowSendEmails(true);

        setRequests(requests.filter((r) => r.id !== request.id));
    };

    return (
        <Dialog open={open} onClose={() => onClose && onClose()} styledTitle="Change Requests" className="max-w-screen-xl">
            <SendEmailDialog
                open={showSendEmails}
                onClose={() => setShowSendEmails(false)}
                changes={emailChanges}
                customer={customer}
                order={order}
            />
            <hr />
            <div className="max-h-[50vh] w-full overflow-x-auto">
                <table className="w-full min-w-[45vw] border-separate border-spacing-0 text-sm">
                    <thead>
                        <tr className="[&>*]:sticky [&>*]:top-0 [&>*]:z-[2] [&>*]:border-b [&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal">
                            <th />
                            <th>Requested</th>
                            <th>Type</th>
                            <th />
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading
                            ? null
                            : [...Array(5)].map(() => {
                                  return (
                                      <tr>
                                          <td colSpan={6} className="text-center [&>*]:border-b [&>*]:px-4 [&>*]:py-1.5">
                                              <Loading />
                                          </td>
                                      </tr>
                                  );
                              })}

                        {requests.length === 0 && !loading ? (
                            <tr>
                                <td colSpan={6} className="text-center [&>*]:border-b [&>*]:px-4 [&>*]:py-1.5">
                                    Nothing to see here! 👏🏼
                                </td>
                            </tr>
                        ) : (
                            requests.map((request) =>
                                request.changes.some((c) => c.field === 'status') ? (
                                    <tr className="[&>*]:border-b [&>*]:px-4 [&>*]:py-1.5">
                                        <td />
                                        <td>{timeAgo(new Date(request.metadata.createdAt))}</td>
                                        <td>Pick Up Request</td>
                                        <td />
                                        <td>
                                            <button
                                                className="btn-primary"
                                                onClick={() => {
                                                    schedulePickup(request.id);
                                                }}
                                            >
                                                Schedule Pick Up
                                            </button>

                                            {showPickupDialog === request.id ? (
                                                <OrderReadyPickUp
                                                    open={true}
                                                    order={order}
                                                    onCancel={() => {
                                                        setShowPickupDialog(false);
                                                    }}
                                                    changeRequest={request}
                                                    onChangeRequestUpdated={removeChange}
                                                />
                                            ) : null}
                                        </td>
                                    </tr>
                                ) : (
                                    <ChangeTableRow request={request} onSubmitted={changeSubmitted} key={request.id + 'wrapper'} />
                                ),
                            )
                        )}
                    </tbody>
                </table>
            </div>
            <div className="mt-4 flex justify-end gap-4">
                <button className="btn-primary" onClick={() => onClose && onClose()}>
                    Close
                </button>
            </div>
        </Dialog>
    );
};
