import { useEffect, useMemo, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Select, SelectOption } from '@wayste/sour-ui';
import { getPrimaryCustomerContact } from '@wayste/utils';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import debounce from 'lodash/debounce';
import Stripe from 'stripe';
import { twMerge } from 'tailwind-merge';
import SelectCard from './SelectCard';
import StripeInput2 from './StripeInput2';

const chargeOptions = {
    now: 'Now',
    save: 'Later - save card now',
    none: 'Later - do not enter card now',
};

export type ChargeOption = keyof typeof chargeOptions;

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_CLIENT_KEY);

interface ChargeCard2Props {
    customer: Customer.AllianceCustomerTransport;
    /**
     * Total amount to charge in cents
     */
    total: number;
    disabled?: boolean;
    showChargeOption?: boolean;
    onChargeSuccessful?: (stripeChargeID: string, last4: string, stripeId: string) => void;
    onSaveSuccessful?: (stripeId: string) => void;
    onStripeCustomerFetched?: (value: Stripe.Customer | null) => void;
    onChargeOptionChange?: (option: ChargeOption) => void;
    className?: string;
}

const ChargeCard2 = ({
    customer,
    total,
    disabled,
    showChargeOption,
    onChargeSuccessful,
    onSaveSuccessful,
    onStripeCustomerFetched,
    onChargeOptionChange,
    className,
}: ChargeCard2Props) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [selectedCardId, setSelectedCardId] = useState<string | undefined>(undefined);
    const [chargeOption, setChargeOption] = useState<ChargeOption>('now');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [saveSuccessful, setSaveSuccessful] = useState<boolean>(false);
    const [stripeCustomer, setStripeCustomer] = useState<Stripe.Customer | null>(null);

    const fetch = useMemo(
        () =>
            debounce(async (request, callback) => {
                const searchEmail = request.input.toLowerCase().replace(/\s+/g, '');
                handleFindingCustomer(searchEmail);
                callback();
            }, 500),
        [],
    );

    useEffect(() => {
        if (!customer) {
            return;
        }
        if (customer.stripeId && stripeCustomer?.id !== customer.stripeId) {
            setIsLoading(true);
            handleRetrieveStripeCustomer(customer.stripeId);
            return;
        }
        if (customer.stripeId) return;
        if (customer.contacts && customer.contacts.length !== 0) {
            const primaryContact = getPrimaryCustomerContact(customer) ?? customer.contacts[0];
            const stripeEmail = primaryContact?.email?.toLowerCase().trim() ?? undefined;

            if (stripeEmail) {
                fetch({ input: stripeEmail }, () => undefined);
            } else {
                handleSetStripeCustomer(null);
            }
        }
        setIsLoading(false);
    }, [customer.stripeId, customer.contacts]);

    const handleRetrieveStripeCustomer = async (stripeId: string) => {
        try {
            const customer = await client.stripe().adminPortal.customer.fetch(stripeId);
            handleSetStripeCustomer(customer);
        } catch (error) {
            console.warn('handleRetrieveStripeCustomer Error: ', error);
            setShowToast({ message: 'An error occurred while looking fetching Stripe Customer. ', severity: 'warning' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleFindingCustomer = async (email: string) => {
        try {
            const { data: stripeCustomers } = await client.stripe().adminPortal.customer.query({ email });
            if (stripeCustomers.length === 0) {
                handleSetStripeCustomer(null);
            } else if (stripeCustomers.length > 1) {
                setShowToast({
                    message: `Multiple customers with email: ${email}. Please check stripe for duplicates`,
                    severity: 'warning',
                });
            } else {
                handleSetStripeCustomer(stripeCustomers[0]);
            }
        } catch (error) {
            console.warn('handleFindingCustomer Error: ', error);
            setShowToast({ message: 'An error occurred while looking for Stripe Customer', severity: 'warning' });
        }
        setIsLoading(false);
    };

    const handleSetStripeCustomer = (value: Stripe.Customer | null) => {
        setStripeCustomer(value);
        if (onStripeCustomerFetched) onStripeCustomerFetched(value);
    };

    const handleChargeOptionChange = (option: ChargeOption) => {
        setChargeOption(option);
        onChargeOptionChange?.(option);
    };

    return (
        <div className={twMerge('w-full', className)}>
            <h5 className="mb-2 w-full border-b text-lg">Charge Card</h5>
            <div className="flex w-full flex-col items-start gap-2 lg:flex-row">
                {showChargeOption && (
                    <div className="w-full lg:w-auto">
                        <Select
                            label={'Charge'}
                            onSelect={(value) => handleChargeOptionChange(value as ChargeOption)}
                            defaultValue={''}
                            required
                            disabled={disabled}
                            value={chargeOption}
                        >
                            {Object.entries(chargeOptions).map((item, index) => (
                                <SelectOption key={'chargeOption' + index} value={item[0]}>
                                    {item[1]}
                                </SelectOption>
                            ))}
                        </Select>
                    </div>
                )}
                {chargeOption !== 'none' && (
                    <SelectCard stripeCustomer={stripeCustomer} disabled={disabled} onCardSelected={setSelectedCardId} />
                )}

                {chargeOption !== 'none' && (
                    <div className={`flex-column flex md:flex-row`}>
                        <div className="align-middle">
                            {customer && (
                                <div className="flex flex-col text-xs">
                                    <span>
                                        Customer:{' '}
                                        {`${getPrimaryCustomerContact(customer).firstName} ${getPrimaryCustomerContact(customer).lastName}`}
                                    </span>
                                    <span>Billing Zip: {customer.billingAddress.zip}</span>
                                </div>
                            )}
                            <Elements stripe={stripePromise}>
                                <StripeInput2
                                    customer={customer}
                                    stripeCustomer={stripeCustomer || undefined}
                                    saveSuccessful={saveSuccessful}
                                    chargeOption={chargeOption}
                                    setIsLoading={(loading: boolean) => setIsLoading(loading)}
                                    isLoading={isLoading}
                                    selectedCard={selectedCardId}
                                    total={total}
                                    disabled={disabled}
                                    onSaveSuccessful={(stripeId: string) => {
                                        if (stripeId) onSaveSuccessful?.(stripeId);
                                        setIsLoading(false);
                                        setSaveSuccessful(true);
                                        setShowToast({ message: 'Card Saved', severity: 'success' });
                                    }}
                                    onChargeSuccessful={(stripeChargeID: string, last4: string, newCustId: string) => {
                                        setSaveSuccessful(true);
                                        setIsLoading(false);
                                        setShowToast({ message: 'Charge Successful', severity: 'success' });
                                        onChargeSuccessful?.(stripeChargeID, last4, stripeCustomer ? stripeCustomer.id : newCustId);
                                    }}
                                    onChargeFailed={(hideFlash) => {
                                        setIsLoading(false);
                                        if (!hideFlash) setShowToast({ message: 'Charge Failed', severity: 'error' });
                                    }}
                                />
                            </Elements>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default ChargeCard2;
