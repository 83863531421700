import { useEffect } from 'react';
import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button, Loading, TanDataGrid } from '@wayste/sour-ui';
import { PencilIcon } from '@heroicons/react/24/solid';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useHistory } from 'react-router-dom';
import routes from '../../../../utils/routes';

const ServicesList = () => {
    const history = useHistory();
    const client = useWaysteClient();
    // STATE
    const [isLoading, setIsLoading] = useState(true);
    const [services, setServices] = useState<UniversalService.ServiceTypeFamily[]>([]);

    ////////////////////////////////////////////
    // TABLE SECTION
    ////////////////////////////////////////////

    const columnHelper = createColumnHelper<UniversalService.ServiceTypeFamily>();

    const columns: ColumnDef<UniversalService.ServiceTypeFamily, any>[] = [
        columnHelper.accessor('name', {
            header: 'Service Family',
            cell: (values) => <div className="w-[150px]">{values.getValue()}</div>,
        }),

        columnHelper.accessor('serviceTypes', {
            header: 'Service Types',
            cell: (values) => (
                <div>
                    {values
                        .getValue()
                        .map((sku: UniversalService.ServiceType) => <div key={sku.name}>{sku.name}</div>)
                        .map((divElement: JSX.Element, index: number, array: JSX.Element[]) => {
                            const comma = index !== array.length - 1 ? ', ' : '';
                            return divElement.props.children + comma;
                        })}
                </div>
            ),
        }),
        columnHelper.accessor('id', {
            header: 'Actions',
            cell: (values) => (
                <button className="btn-icon m-1">
                    <PencilIcon
                        className="text-sourgum-green-600 h-4 w-4"
                        onClick={() => {
                            handleEditService(values.getValue());
                        }}
                    />
                </button>
            ),
        }),
    ];

    ////////////////////////////////////////////
    // FUNCTIONS SECTION
    ////////////////////////////////////////////

    // NAVIGATE TO EDIT SERVICE
    const handleEditService = (id: string) => {
        history.push(`${routes.universalServices.services.details(id)}`);
    };

    // NAVIGATE TO CREATE SERVICE
    const handleCreateService = () => {
        history.push(routes.universalServices.services.new);
    };

    ////////////////////////////////////////////
    // HOOKS SECTION
    ////////////////////////////////////////////

    useEffect(() => {
        const fetchServices = async () => {
            setIsLoading(true);
            try {
                const response = await client.universalService().serviceFamily.query({ orderBy: 'name' });
                setServices(response.results);
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };
        fetchServices();
    }, []);

    ////////////////////////////////////////////
    // RENDER SECTION
    ////////////////////////////////////////////

    if (isLoading) {
        return (
            <div className="container flex h-full items-center justify-center  ">
                <Loading />
            </div>
        );
    }
    return (
        <div className="container mx-auto flex flex-col gap-10 px-3 pb-10 pt-7">
            <div className="flex flex-row items-center justify-between">
                <div className="w-[2/3] text-xl ">Service Families</div>
                <div>
                    <Button className="btn-primary" onClick={handleCreateService}>
                        Create New Service Family
                    </Button>
                </div>
            </div>
            <div className=" flex w-full flex-col items-center justify-between">
                <TanDataGrid className="w-full" data={services} columns={columns} />
            </div>
        </div>
    );
};

export default ServicesList;
