import {
    AddressTransport,
    Duration,
    DurationUnitLabels,
    ServiceLocationTransport,
    UniversalService,
} from '@alliance-disposal/transport-types';
import { AddressSubForm } from '@wayste/sour-components';
import { Checkbox, CurrencyTextField, DatePicker, Select, SelectOption, TextField, Textarea, TimePicker } from '@wayste/sour-ui';
import { formatServiceAddress } from '@wayste/utils';
import { setHours, setMinutes, setSeconds } from 'date-fns';
import { Controller, FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useFlash } from '../../../hooks/useFlash';

export type RFPUpdateFromProps = {
    serviceLocation: ServiceLocationTransport;
    noteToVendor: string;
    deadlineDate: Date | undefined | '';
    deadlineTime: Date | '' | 'error';
    billingFrequency: Duration;
    recurring: boolean;
    serviceStartDate?: Date | undefined | '';
    businessType: string;
    targetProposalAmount: number | '';
};

type RFPFormProps = {
    onSubmit: (data: UniversalService.RFPCreate | UniversalService.RFPUpdate) => void;
    rfp: UniversalService.RFPTransport;
};

const RFPForm = ({ onSubmit, rfp }: RFPFormProps) => {
    const { showFlash } = useFlash();

    const methods = useForm<RFPUpdateFromProps>({
        mode: 'all',
        defaultValues: {
            serviceLocation: rfp.serviceLocation,
            noteToVendor: rfp.noteToVendor,
            deadlineDate: new Date(rfp.deadlineDate),
            deadlineTime: new Date(rfp.deadlineDate),
            serviceStartDate: rfp.serviceStartDate ? new Date(rfp.serviceStartDate) : undefined,
            recurring: rfp.rfpSkus[0]?.billingFrequency ? true : false,
            billingFrequency: rfp.rfpSkus[0]?.billingFrequency || { value: 1, unit: 'MONTHS' },
            businessType: rfp.businessType || '',
            targetProposalAmount: rfp.targetProposalAmount || '',
        },
    });

    const { handleSubmit, control, watch, setValue, getValues } = methods;

    const watchServiceLocationAddress = watch('serviceLocation.address');
    const watchRecurring = watch('recurring');

    const getAddressWithoutNumber = (address: AddressTransport) => {
        const formattedAddress = formatServiceAddress({ ...address });
        const match = formattedAddress.match(/\s*\d+\w*\s*(.*)/);
        return match ? match[1].trim() : '';
    };

    const handleDeadlineDateChange = (date: Date | undefined | '') => {
        setValue('deadlineDate', date);
        const deadlineTime = getValues('deadlineTime');
        if (date && (deadlineTime === 'error' || !deadlineTime)) {
            setValue('deadlineTime', date);
        }
    };

    const onFormSubmit = (values: RFPUpdateFromProps) => {
        const combinedDeadline = setHours(
            setMinutes(setSeconds(values.deadlineDate as Date, 0), (values.deadlineTime as Date).getMinutes()),
            (values.deadlineTime as Date).getHours(),
        );
        const data: UniversalService.RFPUpdate = {
            deadlineDate: combinedDeadline.toISOString(),
            serviceLocation: values.serviceLocation,
            status: 'OPEN',
            noteToVendor: values.noteToVendor,
            serviceStartDate: values.serviceStartDate ? values.serviceStartDate?.toISOString() : undefined,
            businessType: values.businessType,
            targetProposalAmount: values.targetProposalAmount || null,
        };
        onSubmit(data);
    };

    const handleInvalidForm = (info: FieldErrors<RFPUpdateFromProps>) => {
        showFlash(`Invalid Form - ${Object.values(info)[0].message}`, 'error');
    };

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={handleSubmit(onFormSubmit, handleInvalidForm)}
                className="grid grid-cols-1 gap-x-4 gap-y-6"
                id="rfp-update-form"
            >
                <div className="italic">
                    <AddressSubForm apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY} fieldName="serviceLocation" type="location" required />
                    Site Address Visible to Vendor:
                    <br />
                    {getAddressWithoutNumber(watchServiceLocationAddress)}
                </div>
                <Controller
                    control={control}
                    name="targetProposalAmount"
                    render={({ field, fieldState }) => (
                        <CurrencyTextField
                            useCents
                            label="Target Proposal Rate"
                            error={fieldState.error}
                            helperText="If you want to give the hauler a rate to beat."
                            value={field.value}
                            onChange={(value) => field.onChange(value)}
                        />
                    )}
                />
                <Controller
                    name="businessType"
                    control={control}
                    rules={{
                        required: 'Business Type is required',
                    }}
                    render={({ field }) => (
                        <TextField
                            label="Type of Business"
                            required
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />
                <Controller
                    name="noteToVendor"
                    control={control}
                    render={({ field }) => <Textarea inputProps={{ ...field }} label="Overall stop notes" />}
                />
                <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2">
                    <Controller
                        name="deadlineDate"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'A deadline is required',
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <DatePicker
                                label="Deadline"
                                value={field.value}
                                onChange={handleDeadlineDateChange}
                                error={fieldState.error}
                                required
                                closeOnSelect
                            />
                        )}
                    />
                    <Controller
                        name="deadlineTime"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'A deadline time is required',
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <TimePicker
                                label="Deadline time"
                                required
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                                error={fieldState.error}
                            />
                        )}
                    />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <Controller
                        name="serviceStartDate"
                        control={control}
                        render={({ field, fieldState }) => (
                            <DatePicker
                                label="Service start date"
                                value={field.value || ''}
                                onChange={field.onChange}
                                error={fieldState.error}
                                closeOnSelect
                            />
                        )}
                    />
                </div>
                <div>
                    <Controller
                        control={control}
                        name={'recurring'}
                        render={({ field }) => (
                            <Checkbox
                                label="Recurring service"
                                inputProps={{
                                    checked: field.value,
                                    onChange: field.onChange,
                                }}
                            />
                        )}
                    />
                </div>
                {watchRecurring && (
                    <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2">
                        <div>
                            <Controller
                                control={control}
                                name="billingFrequency.value"
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Billing frequency is required',
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        type="number"
                                        label="Billing frequency value"
                                        error={fieldState.error}
                                        required
                                        inputProps={{
                                            ...field,
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name="billingFrequency.unit"
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Billing frequency unit is required',
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <Select
                                        required
                                        error={fieldState.error}
                                        value={field.value}
                                        onSelect={(value) => field.onChange(value)}
                                        label="Billing frequency unit"
                                    >
                                        {Object.entries(DurationUnitLabels).map(([key, value]) => (
                                            <SelectOption key={key} value={key}>
                                                {value.singular}
                                            </SelectOption>
                                        ))}
                                    </Select>
                                )}
                            />
                        </div>
                    </div>
                )}
            </form>
        </FormProvider>
    );
};

export default RFPForm;
