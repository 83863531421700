import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Notification, Pricing, UniversalService } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Checkbox, Loading, TextField } from '@wayste/sour-ui';
import { formatEmailDestination, formatServiceAddress } from '@wayste/utils';
import { format } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import { InternalTicket } from '../../components/InternalTicket';
import { routes } from '../../utils';

interface VendorOption {
    checked: boolean;
    vendorPricing: Pricing.ProductPricingZoneTransport;
    previouslySent?: UniversalService.RFPVendorResponseTransport;
    skuMatchCount: number;
    skuMatches: { name: string; id: string; matching: boolean }[];
    noteToVendor: string;
    vendorRfpEmails: { email: string; firstName: string }[];
    vendorName: string;
    vendor: Hauler.HaulerWithAapTransport;
}

const countCommonElements = (arrayA: string[], arrayB: string[]) => {
    // Convert arrayB into a Set for efficient lookup
    const setB = new Set(arrayB);

    // Filter elements in arrayA that exist in setB, then get the length
    const commonElementsCount = arrayA.filter((element) => setB.has(element)).length;

    return commonElementsCount;
};

const RFPVendorSelect = () => {
    const client = useWaysteClient();
    const { id }: { id: string } = useParams();
    const history = useHistory();
    const { setShowToast } = useSourContext();
    const [rfp, setRFP] = useState<UniversalService.RFPTransport | null>(null);
    const [vendorPricingZones, setVendorPricingZones] = useState<VendorOption[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [rfpSkuIDs, setRfpSkuIDs] = useState<{ [key: string]: string }>({});

    const handleSetup = async () => {
        setIsLoading(true);
        if (id) {
            try {
                // Fetch the RFP based on the url id
                const rfpResponse = await client.universalService().adminPortal.rfp.fetch(id);
                setRFP(rfpResponse);
                // const skuIDs = [...new Set(rfpResponse.rfpSkus.map((sku) => sku.serviceType.id))];
                const skuIDs = rfpResponse.rfpSkus.reduce((acc: { [key: string]: string }, sku) => {
                    acc[sku.serviceType.id] = sku.serviceType.name;
                    return acc;
                }, {});
                setRfpSkuIDs(skuIDs);
                // Fetch Vendor pricing zones based on the RFP's location and family
                const pricingResponse = await client.universalService().pricingZone.query({
                    lat: rfpResponse.serviceLocation.coordinates.lat,
                    lng: rfpResponse.serviceLocation.coordinates.lng,
                    familyID: rfpResponse.familyID,
                });
                const dataArray: VendorOption[] = [];
                for (const vendorPricing of pricingResponse.results) {
                    if (!vendorPricing.vendorID) continue;
                    const haulerResponse = await client.vendorService().adminPortal.fetch(vendorPricing.vendorID);
                    const previouslySent = rfpResponse.rfpVendorResponses.find((response) => response.vendorID === vendorPricing.vendorID);
                    // Get a list of the unique skus
                    const vendorSkuIds = vendorPricing.SKUs.map((sku) => sku.skuID);
                    // Compare the skus from the RFP to the vendor's skus so Vendor Relations can see how many match
                    const skuMatchCount = countCommonElements(Object.keys(skuIDs), vendorSkuIds);
                    const skuMatches = Object.keys(skuIDs).map((key) => ({
                        name: skuIDs[key],
                        id: key,
                        matching: vendorSkuIds.includes(key),
                    }));
                    dataArray.push({
                        vendorPricing: { ...vendorPricing },
                        checked: Boolean(previouslySent) || skuMatchCount === 0 ? false : true,
                        previouslySent,
                        skuMatchCount,
                        skuMatches,
                        noteToVendor: '',
                        vendorRfpEmails: haulerResponse.contacts
                            .filter((contact) => contact.email && contact.sendRFPEmails)
                            .map((contact) => ({ email: contact.email as string, firstName: contact.firstName || '' })),
                        vendorName: haulerResponse.name,
                        vendor: haulerResponse,
                    });
                }
                setVendorPricingZones(dataArray);
                setIsLoading(false);
            } catch (error) {
                alert('Error fetching RFP or Pricing, get a SAP dev');
            } finally {
                setIsLoading(false);
            }
        } else {
            alert('Missing RFP ID');
            setIsLoading(false);
        }
        // BONUS - Show other vendors in the area that don't have family && others with family that are relatively close
    };

    useEffect(() => {
        handleSetup();
    }, [id]);

    const handleVendorChecked = (index: number, newValue: boolean) => {
        const copy = [...vendorPricingZones];
        copy[index] = { ...copy[index], checked: newValue };
        setVendorPricingZones(copy);
    };

    const handleVendorMessage = (index: number, newValue: string) => {
        const copy = [...vendorPricingZones];
        copy[index] = { ...copy[index], noteToVendor: newValue };
        setVendorPricingZones(copy);
    };

    const handleSaveRFPVendorResponse = async (vendorOption: VendorOption) => {
        if (!rfp) return;
        const createObject: UniversalService.RFPVendorResponseCreate = {
            rfpID: rfp.id,
            accessLinkExpirationDate: rfp.deadlineDate,
            rfpSkus: rfp.rfpSkus.map((sku) => ({
                serviceType: sku.serviceType,
                quantity: sku.quantity,
                serviceFrequency: sku.serviceFrequency,
                serviceCycleLength: sku.serviceCycleLength,
                serviceTypeID: sku.serviceType.id,
                billingFrequency: sku.billingFrequency,
                noteToVendor: sku.noteToVendor,
                rfpSkuExtras: sku.rfpSkuExtras
                    ? sku.rfpSkuExtras.map((extra) => ({
                          noteToVendor: extra.noteToVendor,
                          billingFrequency: extra.billingFrequency,
                          serviceFrequency: extra.serviceFrequency,
                          quantity: extra.quantity,
                          requiredInRfp: extra.requiredInRfp,
                          serviceLineItemTypeID: extra.serviceLineItemType.id,
                      }))
                    : [],
            })),
            vendorID: vendorOption.vendorPricing.vendorID as string,
            status: 'OPEN',
            vendorName: vendorOption.vendorPricing.vendorName as string,
            noteToVendor: vendorOption.noteToVendor,
        };
        try {
            const response = await client.universalService().adminPortal.rfpVendorResponse.create(createObject);
            return response;
        } catch (error) {
            console.warn('Error sending RFP to vendor', error);
            return null;
        }
    };

    const handleEmailSend = async (vendorOption: VendorOption, accessLink: string) => {
        if (!vendorOption.vendorPricing.vendorID || !rfp) return { error: 'No vendor ID' };
        const rfpContacts = vendorOption.vendorRfpEmails;
        if (rfpContacts.length === 0) {
            console.warn('No emails to send to');
            return { error: 'Hauler missing contacts with Receives RFP' };
        }
        const ccEmails = rfpContacts.filter((contact) => contact.email !== rfpContacts[0].email).map((contact) => contact.email as string);
        try {
            const destination = formatEmailDestination(rfpContacts[0].email as string, rfpContacts[0]?.firstName || '', ccEmails, []);
            const clean_location = formatServiceAddress({
                addressLine1: rfp.serviceLocation.address?.addressLine1
                    ? rfp.serviceLocation.address.addressLine1.match(/\s*\d+\w*\s*(.*)/)?.[1].trim() || '' // remove street number
                    : '',
                addressLine2: '',
                city: rfp.serviceLocation.address?.city || '',
                state: rfp.serviceLocation.address?.state || '',
                zip: rfp.serviceLocation.address?.zip || '',
            });
            const emailData: Notification.SendGrid.VendorRFP = {
                vendor_name: vendorOption.vendorName,
                access_link: accessLink,
                first_name: rfpContacts[0]?.firstName || '',
                start_date: rfp.serviceStartDate ? format(new Date(rfp.serviceStartDate), 'EEE MM/dd/yyyy') : '',
                deadline: format(new Date(rfp.deadlineDate), 'EEE MM/dd/yyyy hh:mm a'),
                clean_location,
                proposal_number: rfp.proposalNumber.toString(),
            };
            await client.notification().adminPortal.createInstantNotification({
                handler: 'sendgrid',
                topic: 'vendor-rfp',
                destination,
                body: JSON.stringify(emailData),
            });
            return {};
        } catch (error) {
            console.warn('Error sending email to vendor', error);
            return { error: 'Error sending email to vendor' };
        }
    };

    const handleSaveAndSend = async () => {
        if (!rfp) return;
        const selectedVendors = vendorPricingZones.filter((vendor) => vendor.checked);
        if (selectedVendors.length === 0) {
            alert('Select at least one vendor to send the RFP');
            return;
        }
        const failedTransactions: { vendorName: string; failedEmail?: string; failedDB?: string }[] = [];
        setIsLoading(true);
        for (const vendorOption of selectedVendors) {
            if (vendorOption.previouslySent) {
                const onlyEmailResponse = await handleEmailSend(vendorOption, vendorOption.previouslySent?.accessLink as string);
                if (onlyEmailResponse.error) {
                    failedTransactions.push({
                        vendorName: vendorOption.vendorPricing.vendorName as string,
                        failedEmail: onlyEmailResponse.error,
                    });
                }
                continue;
            }
            const dbResponse = await handleSaveRFPVendorResponse(vendorOption);
            if (!dbResponse) {
                failedTransactions.push({
                    vendorName: vendorOption.vendorPricing.vendorName as string,
                    failedEmail: undefined,
                    failedDB: 'true',
                });
                continue;
            }
            const emailResponse = await handleEmailSend(vendorOption, dbResponse.accessLink);
            if (emailResponse.error) {
                failedTransactions.push({ vendorName: vendorOption.vendorPricing.vendorName as string, failedEmail: emailResponse.error });
                continue;
            }
        }
        if (failedTransactions.length > 0) {
            alert(
                'Failed to send RFP to the following vendors. Get a SAP dev: ' +
                    failedTransactions
                        .map(
                            (vendor) =>
                                `Name: ${vendor.vendorName} - ${vendor.failedDB ? 'Failed to write to database and no email sent' : `Failed to send email because: ${vendor.failedEmail}`} `,
                        )
                        .join(', '),
            );
        } else {
            setShowToast({ severity: 'success', message: 'RFP sent to vendors successfully' });
        }
        history.push(routes.requestForProposal.details(rfp.id));
    };

    return (
        <div className="mx-auto w-full max-w-screen-md px-5 py-6">
            {isLoading && <Loading fullScreen />}
            <h1 className="mb-6 text-2xl">Select Vendors for RFP {rfp?.proposalNumber}</h1>
            <div className="mb-6 flex flex-col gap-y-4">
                {rfp?.id && <InternalTicket entityID={rfp.id} entityType="sourgum-rfp" readOnly defaultOpen={false} />}
                <div>Current vendor on site: {rfp?.currentVendor}</div>
                <hr />
            </div>
            {vendorPricingZones.map((vendorOption, index) => (
                <div
                    className={`flex flex-col gap-y-1 ${!vendorOption.vendor.active || !vendorOption.vendor.isSourgumPartner ? 'rounded-lg border border-red-500' : ''}`}
                    key={vendorOption.vendorPricing.vendorID}
                >
                    <div className="flex flex-row items-center justify-between gap-x-10">
                        <div className="whitespace-nowrap">
                            <Checkbox
                                label={vendorOption.vendorPricing.vendorName}
                                inputProps={{
                                    checked: vendorOption.checked,
                                    onChange: (e) => handleVendorChecked(index, e.target.checked),
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <TextField
                                label="Custom note to Vendor"
                                inputProps={{
                                    value: vendorOption.noteToVendor,
                                    onChange: (e) => handleVendorMessage(index, e.target.value),
                                }}
                            />
                        </div>
                    </div>

                    <div className="flex flex-col pl-8 text-xs">
                        {(!vendorOption.vendor.active || !vendorOption.vendor.isSourgumPartner) && (
                            <div className="font-semibold text-red-500">WARNING THIS VENDOR IS NOT ACTIVE</div>
                        )}
                        <div className="text-sm text-gray-600">
                            Emailing to: {vendorOption.vendorRfpEmails.map((item) => item.email).join(', ')}
                        </div>
                        <div className="text-warning">{vendorOption.previouslySent ? 'Previously sent vendor this RFP' : ''}</div>
                        <div className="flex gap-x-1.5">
                            {vendorOption.skuMatches
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((sku, skuMatchIndex) => (
                                    <div key={sku.id} className={sku.matching ? 'text-green-500' : 'text-red-500'}>
                                        <span>
                                            {sku.name}
                                            {vendorOption.skuMatches.length - 1 === skuMatchIndex ? '' : ','}
                                        </span>
                                    </div>
                                ))}
                        </div>
                        <div>
                            {vendorOption.skuMatchCount} of {Object.keys(rfpSkuIDs).length} SKUs match
                        </div>
                    </div>
                </div>
            ))}
            <div className="mt-6 flex justify-end">
                <Button className="btn-primary" onClick={handleSaveAndSend}>
                    Send to RFP Emails
                </Button>
            </div>
        </div>
    );
};

export default RFPVendorSelect;
