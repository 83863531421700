import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { SourFiltersOld, SourSearchOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { Loading, Select, SelectOption } from '@wayste/sour-ui';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../utils';
import RFPForm from './rfp-form';

const RFPCreate = () => {
    const history = useHistory();
    const client = useWaysteClient();
    const [isLoading, setIsLoading] = useState(false);
    const [linkTo, setLinkTo] = useState<'sourgum-lead' | 'sourgum-customer' | undefined>();
    const [linkToValue, setLinkToValue] = useState<string | undefined>();
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

    useEffect(() => {
        if (parsed.forType) {
            setLinkTo(parsed.forType as 'sourgum-lead' | 'sourgum-customer');
            setLinkToValue(parsed.id as string);
        }
    }, []);

    const handleSubmit = async (data: UniversalService.RFPCreate) => {
        setIsLoading(true);
        try {
            if (linkTo && linkToValue) {
                if (linkTo === 'sourgum-lead') data.forLeadID = linkToValue;
                if (linkTo === 'sourgum-customer') data.forCustomerID = linkToValue;
            }
            const response = await client.universalService().adminPortal.rfp.create(data);
            history.push(routes.requestForProposal.vendorSelect(response.id));
        } catch (error) {
            console.warn('Error creating RFP', error);
            alert('Error creating RFP, get a SAP dev');
        } finally {
            setIsLoading(false);
        }
    };

    // DEFINE ON NAVIGATE LOGIC
    const onNavigate = (_: unknown, recordID: string) => {
        setLinkToValue(recordID);
    };

    return (
        <div className="mx-auto w-full max-w-screen-md px-5 py-6">
            {isLoading && <Loading fullScreen />}
            <h1 className="mb-6 text-2xl">Create a New RFP</h1>
            <div className="mb-6 flex flex-row items-center gap-x-4">
                <div className="whitespace-nowrap">Link to</div>
                {parsed.forType ? (
                    <div>{parsed.forType === 'sourgum-lead' ? 'Lead' : 'Customer'}</div>
                ) : (
                    <>
                        <Select
                            label="Lead or Customer"
                            value={linkTo}
                            onSelect={(value) => {
                                setLinkTo(value as 'sourgum-lead' | 'sourgum-customer' | undefined);
                                if (!value) setLinkToValue(undefined);
                            }}
                        >
                            <SelectOption value={undefined}>None</SelectOption>
                            <SelectOption value="sourgum-lead">Lead</SelectOption>
                            <SelectOption value="sourgum-customer">Customer</SelectOption>
                        </Select>
                        <SourSearchWrapperOld
                            onNavigate={onNavigate}
                            defaultFilters={{
                                query: {
                                    type: linkTo || 'sourgum-customer',
                                },
                            }}
                            options={{
                                application: 'aap',
                                apiKey: import.meta.env.VITE_ELASTIC_KEY,
                                environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                            }}
                        >
                            <div className="flex w-full flex-row justify-between space-x-4">
                                <SourSearchOld
                                    options={{
                                        searchPopoverFixed: true,
                                        showTips: false,
                                        showMessages: false,
                                        resultSize: 'w-[600px]',
                                        placeholder: 'Search',
                                    }}
                                />
                                <div className="hidden">
                                    <SourFiltersOld />
                                </div>
                            </div>
                        </SourSearchWrapperOld>
                    </>
                )}
            </div>
            <RFPForm onSubmit={(data) => handleSubmit(data as UniversalService.RFPCreate)} />
        </div>
    );
};

export default RFPCreate;
