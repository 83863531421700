import { useEffect, useState } from 'react';
import { Customer, Material, MaterialLabels } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { formatE164ToUSPhoneNumber, formatServiceAddress } from '@wayste/utils';
import { PencilIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { leadReasonsLost } from '../../utils';
import routes from '../../utils/routes';
import CardInfoList from '../CardInfoList';
import LeadUpdate from '../LeadUpdate';
import ImageDialogButton from './ImageDialogButton';
import InternalNotes from './InternalNotes';
import StatusChangeButton from './StatusChangeButton';

export interface LeadRODetailsProps {
    rawLead: Customer.AllianceLeadTransport;
}

const LeadRODetails = ({ rawLead }: LeadRODetailsProps) => {
    const [lead, setLead] = useState<Customer.AllianceLeadTransport>(rawLead);
    const [showUpdateForm, setShowUpdateForm] = useState(false);

    //////////////////////////////////////////////
    // HOOKS SECTION
    //////////////////////////////////////////////

    useEffect(() => {
        if (!rawLead) return;
        setLead(rawLead);
    }, [rawLead]);

    //////////////////////////////////////////////
    // RENDER SECTION
    //////////////////////////////////////////////

    const showPreferredContact = (value: 'EMAIL' | 'PHONE' | 'TEXT' | null | undefined) => {
        if (value === 'EMAIL') return 'Email';
        if (value === 'PHONE') return 'Phone';

        return null;
    };

    const leadColOne = [
        { label: 'Name', value: `${lead.firstName || ''} ${lead.lastName || ''}` },
        { label: 'Company', value: lead.company },
        { label: 'Email', value: lead.email },
        {
            label: 'Phone',
            value: lead.phoneNumber ? formatE164ToUSPhoneNumber(lead.phoneNumber) : null,
        },
        {
            label: 'Preferred Method',
            value: showPreferredContact(lead?.preferredContactMethod),
        },
    ];

    const leadColTwo = [
        {
            label: 'Location',
            value: lead?.serviceLocation?.address ? formatServiceAddress(lead.serviceLocation.address) : null,
        },
        { label: 'County', value: lead?.serviceLocation?.county },
        { label: 'Size', value: lead.requestedSize?.size || null },
        {
            label: 'Material',
            value: lead.requestedMaterial ? MaterialLabels[lead.requestedMaterial as Material] : '',
        },
    ];

    const leadColThree = [
        {
            label: 'Delivery Date',
            value: lead.requestedDeliveryDate ? format(new Date(lead.requestedDeliveryDate), 'MM/dd/yy') : '',
        },
        {
            label: 'Follow up Date',
            value: lead.followUpDate ? format(new Date(lead.followUpDate), 'MM/dd/yy') : '',
        },
        {
            label: 'Existing Cus',
            value: lead.allianceCustomer ? (
                <Link to={routes.customers.details(lead.allianceCustomer.id)}>Go to customer record</Link>
            ) : null,
        },
        { label: 'Cus Notes', value: lead.customerNotes },
        {
            label: 'Reason Lost',
            value: lead.reasonLost ? leadReasonsLost[lead.reasonLost as keyof typeof leadReasonsLost] : '',
        },
    ];

    return (
        <div>
            <div className="shadow-dark rounded bg-white px-4 py-1.5">
                <div className="flex items-center justify-between">
                    <h6 className="text-xl font-medium">Lead Details</h6>
                    <div className="flex gap-4">
                        <ImageDialogButton lead={lead} />
                        <StatusChangeButton lead={lead} />
                        <Button
                            className="btn-primary"
                            onClick={() => setShowUpdateForm(true)}
                            startIcon={<PencilIcon className="size-4" />}
                        >
                            Edit
                        </Button>
                    </div>
                </div>
                <hr className="-mx-4 my-2" />
                <div className={`grid grid-cols-1 gap-4 lg:grid-cols-3`}>
                    <CardInfoList data={leadColOne} containerSize={4} border />
                    <CardInfoList data={leadColTwo} containerSize={4} border />
                    <CardInfoList data={leadColThree} containerSize={4} />
                    <div className="flex border-t pt-4 lg:col-span-3">
                        <div className="mr-5 text-gray-500">Old Notes</div>
                        <div>
                            <InternalNotes notesInternal={lead.notesInternal} />
                        </div>
                    </div>
                </div>
            </div>
            {/* Dialogs */}
            <LeadUpdate lead={lead} open={showUpdateForm} onClose={() => setShowUpdateForm(false)} />
        </div>
    );
};

export default LeadRODetails;
