import React, { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { SourSearchOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { Menu, MenuItem } from '@wayste/sour-ui';
import { getRouterPath } from '@wayste/utils';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { ArrowLeftOnRectangleIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { FullLogoNoWaste } from '../../assets/icons/logos';
import routes from '../../utils/routes';
import InAppNotifications from '../Notifications/InAppNotifications';

export const DesktopNavBar = ({
    onLogout,
    toolBarOpen,
    setToolBarOpen,
    navigateToSearch,
    isDesktop,
}: {
    onLogout: () => void;
    toolBarOpen: boolean;
    setToolBarOpen: () => void;
    navigateToSearch?: () => void;
    isDesktop: boolean;
}) => {
    const client = useWaysteClient();
    const currentUser = client.user().get();
    const history = useHistory();
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const searchRef = React.useRef<HTMLDivElement>(null);

    const onNavigate = (_: unknown, recordID: string, name: string) => {
        const path = getRouterPath(name, recordID, routes);
        history.push(path);
    };

    return (
        <div className="z-10 grid grid-cols-3 flex-row items-center justify-between bg-[#FCFCFD] px-4 py-2 shadow transition-all md:flex md:px-8">
            <div className="flex flex-row items-center justify-start gap-4">
                <button
                    className={`menu ${toolBarOpen ? 'opened' : ''} fill-black focus:border-none focus:outline-none md:hidden`}
                    onClick={() => setToolBarOpen()}
                    aria-label="Main Menu"
                >
                    <svg width="100" height="100" className="size-6" viewBox="0 0 100 100">
                        <path
                            className="line line1"
                            d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                        />
                        <path className="line line2" d="M 20,50 H 80" />
                        <path
                            className="line line3"
                            d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                        />
                    </svg>
                </button>
                {/*  <div className="btn-icon-dense hidden md:flex" onClick={setToolBarOpen}>
                    <ChevronDoubleLeftIcon
                        className={`text-sourgum-greyblue-900 h-4 w-4 transform transition-transform duration-500 ease-out ${
                            toolBarOpen ? '' : 'rotate-180'
                        }`}
                    />
                </div> */}
            </div>
            <div className="flex flex-row items-center justify-center gap-4">
                <NavLink to={'/'} exact={true}>
                    <FullLogoNoWaste className="h-6 w-auto" />
                </NavLink>
            </div>
            <div className="flex flex-row items-center justify-end gap-x-3 md:flex-1 md:gap-x-8">
                <div className="flex flex-1 flex-row items-center justify-end gap-1">
                    <div className={`transition-all duration-300 ${isSearchOpen ? 'w-full max-w-lg' : 'w-0 max-w-0 overflow-hidden'}`}>
                        <SourSearchWrapperOld
                            options={{
                                application: 'aap',
                                apiKey: import.meta.env.VITE_ELASTIC_KEY,
                                environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                            }}
                            onNavigate={onNavigate}
                        >
                            <div ref={searchRef}>
                                <SourSearchOld
                                    options={{
                                        placeholder: 'Search For Anything',
                                        label: '',
                                        onMagnifyingGlassClick: () => {
                                            history.push(routes.search);
                                        },
                                        textFieldStyle: { background: 'transparent', border: 'none' },
                                        textFieldDense: true,
                                        hideClearButton: true,
                                    }}
                                />
                            </div>
                        </SourSearchWrapperOld>
                    </div>
                    <div
                        className={`text-sourgum-greyblue-900 h-5 w-5 transform cursor-pointer transition-all duration-500 ease-in-out ${
                            isSearchOpen ? '' : 'rotate-[360deg]'
                        }`}
                        onClick={() => {
                            if (!isDesktop) {
                                navigateToSearch?.();
                                if (toolBarOpen) setToolBarOpen();
                            } else {
                                if (searchRef.current) {
                                    searchRef.current.querySelector('input')?.focus();
                                }

                                setIsSearchOpen(!isSearchOpen);
                            }
                        }}
                    >
                        {isSearchOpen ? <XMarkIcon className="h-6 w-6" /> : <MagnifyingGlassIcon className="h-5 w-5" />}
                    </div>
                </div>
                <InAppNotifications />
                <Menu
                    button={
                        <div className="bg-sourgum-greyblue-400 flex h-6 w-6 items-center justify-center rounded-full text-xs text-white">
                            {currentUser?.firstName?.[0]}
                            {currentUser?.lastName?.[0]}
                        </div>
                    }
                >
                    <MenuItem notClickable className="px-0 py-0">
                        <div className="flex flex-row items-center gap-4 px-4 py-2">
                            <div className="bg-sourgum-greyblue-400 flex h-8 w-8 items-center justify-center rounded-full text-white">
                                {currentUser?.firstName?.[0]}
                                {currentUser?.lastName?.[0]}
                            </div>
                            <div className="flex flex-col">
                                <b>{`${currentUser?.firstName} ${currentUser?.lastName}`}</b>
                                <div className="text-sourgum-greyblue-300 font-light">Sourgum</div>
                            </div>
                        </div>
                        <hr />
                    </MenuItem>
                    <MenuItem onClick={() => history.push(routes.accountSettings.list)} className="flex flex-row justify-between gap-4">
                        Company Settings <Cog6ToothIcon className="h-5 w-5 text-gray-700" />
                    </MenuItem>
                    <MenuItem onClick={onLogout} className="flex flex-row justify-between">
                        Sign Out
                        <ArrowLeftOnRectangleIcon className="h-5 w-5 text-gray-700" />
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
};

export default DesktopNavBar;
