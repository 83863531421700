import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { AddressTransport, UniversalService } from '@alliance-disposal/transport-types';
import { AddressSubForm } from '@wayste/sour-components';
import { Button, Checkbox, Dialog, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { sanitizeFromPayload } from '@wayste/utils';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { UIContext } from '../../../contexts';
import { paymentMethods, paymentTerms } from '../../../utils';

type EditServiceGroupingForm = {
    name: string;
    billingAddress: AddressTransport;
    overrideCustomerBillingAddress: boolean;
    billingDay: number | '';
    paymentTerm: string;
    paymentMethod: string;
};

export interface UpdateServiceGroupingDialog {
    open: boolean;
    serviceGrouping: UniversalService.ServiceGrouping;
    onClose: () => void;
}
const UpdateServiceGroupingModal = ({ open, serviceGrouping, onClose }: UpdateServiceGroupingDialog) => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<EditServiceGroupingForm>();
    const { handleSubmit, setValue, watch, control, reset } = methods;

    useEffect(() => {
        if (serviceGrouping) {
            reset({
                name: serviceGrouping.name,
                billingAddress: serviceGrouping.billingAddress,
                overrideCustomerBillingAddress: Boolean(serviceGrouping.billingAddress),
                billingDay: serviceGrouping.serviceOrders[0].subscriptionDetails?.billingDay || '',
                paymentTerm: serviceGrouping.paymentTerm,
                paymentMethod: serviceGrouping.paymentMethod,
            });
        }
    }, [serviceGrouping]);

    const submit = async (values: EditServiceGroupingForm) => {
        setIsLoading(true);
        try {
            const payload: UniversalService.ServiceGroupingUpdate = {
                billingAddress: values.overrideCustomerBillingAddress ? values.billingAddress : null,
                name: values.name,
                paymentMethod: values.paymentMethod,
                paymentTerm: values.paymentTerm,
            };

            await client.universalService().serviceGrouping.update(serviceGrouping.id, payload);

            if (values.billingDay !== '' && values.billingDay !== serviceGrouping.serviceOrders[0].subscriptionDetails?.billingDay) {
                // reduce this subscription details to ids

                const subscriptionDetailsUpdates = serviceGrouping.serviceOrders
                    .map((serviceOrder) => {
                        return {
                            id: serviceOrder.id,
                            subscriptionDetails: serviceOrder.subscriptionDetails,
                        };
                    })
                    .map(async (serviceOrder) => {
                        await client.universalService().serviceGrouping.serviceOrder.update(serviceGrouping.id, serviceOrder.id, {
                            subscriptionDetails: {
                                ...serviceOrder.subscriptionDetails,
                                endDate: undefined,
                                //@ts-expect-error - we filter out the "" value above
                                billingDay: values.billingDay,
                            },
                        });
                    });

                await Promise.all(subscriptionDetailsUpdates);

                showFlash(
                    'The billing day has been updated. You must manually pro-rate the next invoice once it is automatically generated.',
                    'success',
                );
            }

            onClose();
        } catch (error) {
            console.warn('error: ', error);
            showFlash('Failed to update service grouping', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} className="max-w-4xl" styledTitle={'Edit Service Grouping'} onClose={onClose} showX>
            <FormProvider {...methods}>
                <form className="flex flex-col gap-2 px-4" onSubmit={handleSubmit(submit)}>
                    <div className="text-lg">Service Grouping Name</div>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Service Grouping Name"
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                    <div className="text-lg">Payment Term</div>
                    <Controller
                        name="paymentTerm"
                        control={control}
                        render={({ field: { value, onChange }, fieldState }) => (
                            <Select required label="Payment Term" value={value} onSelect={onChange} error={fieldState.error}>
                                {Object.entries(paymentTerms).map((item) => (
                                    <SelectOption key={`paymentTerm-${item[0]}`} value={item[0]}>
                                        {item[1]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                    <div className="text-lg">Payment Method</div>
                    <Controller
                        name="paymentMethod"
                        control={control}
                        render={({ field: { value, onChange }, fieldState }) => (
                            <Select required label="Payment Method" value={value} onSelect={onChange} error={fieldState.error}>
                                {Object.entries(paymentMethods).map((item) => (
                                    <SelectOption key={`paymentMethod-${item[0]}`} value={item[0]}>
                                        {item[1]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                    <div className="text-lg">Billing Day</div>
                    <Controller
                        name="billingDay"
                        control={control}
                        rules={{
                            max: { value: 28, message: 'Billing day must be between 1 and 28' },
                            min: { value: 1, message: 'Billing day must be between 1 and 31' },
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="Billing Day"
                                // helperText="The day of the month the customer will be billed. If you change this you must manually pro-rate the next invoice once it is automatically generated."
                                error={fieldState.error}
                                inputProps={{
                                    ...field,
                                    type: 'number',
                                }}
                            />
                        )}
                    />

                    <div className="text-lg">Billing Address</div>
                    <Controller
                        name="overrideCustomerBillingAddress"
                        control={control}
                        render={({ field }) => (
                            <Checkbox
                                label="Override Customer Billing Address"
                                inputProps={{
                                    onChange: (e) => {
                                        field.onChange(e.target.checked);
                                    },
                                    checked: field.value,
                                }}
                            />
                        )}
                    />
                    {watch('overrideCustomerBillingAddress') && (
                        <>
                            {' '}
                            <span className="text-red-400">
                                This will only be used for this service grouping. The customer's billing address will not be changed.
                            </span>
                            <AddressSubForm
                                apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
                                fieldName={'billingAddress'}
                                type="address"
                                required
                                disabled={!watch('overrideCustomerBillingAddress')}
                                handleSelect={(value) => {
                                    setValue('billingAddress', value.address);
                                }}
                            />
                        </>
                    )}
                    <Button className="btn-primary" type="submit" loading={isLoading}>
                        Submit
                    </Button>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default UpdateServiceGroupingModal;
