import { useEffect, useMemo, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Order, UniversalService } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { FilterTabs, Select, SelectOption } from '@wayste/sour-ui';
import { formatServiceAddress } from '@wayste/utils';
import { accountManagers } from '../../utils/shared-types';
import CustomerBalanceBoard from './components/customer-balance-board';
import RollOffBoard from './components/roll-off-board';
import SiteServiceBoard from './components/site-service-board';

export type Project = {
    allianceCustomerID: string;
    customerName: string;
    customerCompanyName: string;
    serviceAddress: string;
    orders: Order.AllianceOrderTransport[];
};

export type SiteServiceProject = {
    customerID: string;
    customerDisplayName: string;
    serviceAddress: string;
    serviceOrders: UniversalService.ServiceOrder[];
};

const addressSort = (a: Project | SiteServiceProject, b: Project | SiteServiceProject) => {
    // Extract the number part from the address
    const match = a.serviceAddress.match(/^\d+/);
    const numA = match ? parseInt(match[0], 10) : 0;
    const matchB = b.serviceAddress.match(/^\d+/);
    const numB = matchB ? parseInt(matchB[0], 10) : 0;

    // If the numbers are different, sort by number
    if (numA !== numB) {
        return numA - numB;
    }

    // If the numbers are the same, compare the street names lexicographically
    const streetA = a.serviceAddress.replace(/^\d+\s+/, ''); // Remove the number part from the address
    const streetB = b.serviceAddress.replace(/^\d+\s+/, '');

    return streetA.localeCompare(streetB);
};

const AccountManagerProjectBoard = () => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [accountManagerID, setAccountManagerID] = useState('e0dd4894-5442-4d41-96c6-a325a1395bb5');
    const [activeTab, setActiveTab] = useState('rolloffProjectBoard');
    const [projects, setProjects] = useState<Project[]>([]);
    const [siteServiceProjects, setSiteServiceProjects] = useState<SiteServiceProject[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleQueryRollOffProjects = async () => {
        setIsLoading(true);
        try {
            const orders = await client.order().adminPortal.query({
                limit: 1000,
                accountManagerID,
                status: 'DELIVERED,NEEDS_REVIEW,UNASSIGNED,ON_HOLD,ASSIGNED,READY_FULL_PICKUP,DUMPED',
            });
            if (orders.total > 1000) {
                alert('Get a SAP dev. There are more than 1000 orders and this function needs to be updated.');
            }
            const orderMap = new Map();
            orders.results.forEach((order) => {
                const { allianceCustomerID, serviceLocation } = order;
                const locationKey = formatServiceAddress(serviceLocation.address);
                // Create a unique key by combining customerId and locationKey
                const mapKey = `${allianceCustomerID}-${locationKey}`;
                if (!orderMap.has(mapKey)) {
                    // If the mapKey doesn't exist, create a new entry
                    orderMap.set(mapKey, {
                        allianceCustomerID,
                        serviceLocation,
                        customerName: order.customerName,
                        customerCompanyName: order.customerCompanyName,
                        serviceAddress: locationKey,
                        orders: [],
                    });
                }

                // Push the current order to the appropriate group
                orderMap.get(mapKey).orders.push(order);
            });
            setProjects(Array.from(orderMap.values()).sort((a, b) => addressSort(a, b)));
        } catch (error) {
            console.error(error);
            setShowToast({ message: 'Failed to fetch roll-off projects', severity: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleQuerySiteServiceProjects = async () => {
        setIsLoading(true);
        try {
            const serviceOrders = await client.universalService().adminPortal.serviceOrder.query({
                accountManagerID,
                statuses:
                    'UNASSIGNED,ASSIGNED,ON_HOLD,DELIVERED,READY_FULL_PICKUP,READY_PICKUP,PICKED_UP_FULL,PICKED_UP_EMPTY,PICKED_UP,DUMPED,ON_SITE,IN_PROGRESS',
                limit: 100,
            });
            if (serviceOrders.total > 1000) {
                alert('Get a SAP dev. There are more than 1000 service orders and this function needs to be updated.');
            }
            const serviceOrderMap = new Map();
            serviceOrders.results.forEach((serviceOrder) => {
                const { customerID, serviceLocation } = serviceOrder;
                const locationKey = formatServiceAddress(serviceLocation.address);
                // Create a unique key by combining customerId and locationKey
                const mapKey = `${customerID}-${locationKey}`;
                if (!serviceOrderMap.has(mapKey)) {
                    // If the mapKey doesn't exist, create a new entry
                    serviceOrderMap.set(mapKey, {
                        customerID,
                        customerDisplayName: serviceOrder.customerCompanyName || serviceOrder.customerName,
                        serviceAddress: locationKey,
                        serviceOrders: [],
                    });
                }

                // Push the current order to the appropriate group
                serviceOrderMap.get(mapKey).serviceOrders.push(serviceOrder);
            });
            setSiteServiceProjects(Array.from(serviceOrderMap.values()).sort((a, b) => addressSort(a, b)));
        } catch (error) {
            console.error(error);
            setShowToast({ message: 'Failed to fetch site service projects', severity: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (accountManagerID) {
            handleQueryRollOffProjects();
            handleQuerySiteServiceProjects();
        }
    }, [accountManagerID]);

    const getView = useMemo(() => {
        // if (activeTab === 'profile' && profile) return <UserProfile user={profile} />;
        // if (activeTab === 'users' && hauler) return <UserList account={hauler} />;
        if (activeTab === 'siteServiceProjectBoard')
            return <SiteServiceBoard siteServiceProjects={siteServiceProjects} loading={isLoading} />;
        if (activeTab === 'customersWithBalance') return <CustomerBalanceBoard accountManagerID={accountManagerID} />;
        return <RollOffBoard projects={projects} loading={isLoading} />;
    }, [activeTab, projects, accountManagerID, isLoading, siteServiceProjects]);

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    return (
        <div className="flex flex-col pb-6">
            <div className="bg-main-sidebar flex items-end justify-between border-b px-5 pt-8">
                <div className="pb-4 text-2xl font-semibold leading-normal tracking-wide text-zinc-700">AM Project Board</div>
                <div className="w-fit min-w-56">
                    <FilterTabs
                        activeTab={activeTab}
                        onTabChange={handleTabChange}
                        scrollButtons
                        tabOptions={{
                            rolloffProjectBoard: 'Roll-off Projects',
                            siteServiceProjectBoard: 'Site Service Projects',
                            customersWithBalance: 'Customer Balances',
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-col gap-y-4 px-5 pt-6">
                <div className="w-52">
                    <Select
                        label="Account Manager"
                        onSelect={(value) => {
                            if (value) setAccountManagerID(value);
                        }}
                        value={accountManagerID}
                    >
                        {accountManagers.map((profile) => (
                            <SelectOption key={'accountManagerID' + profile.id} value={profile.id}>
                                {profile.firstName + ' ' + profile.lastName}
                            </SelectOption>
                        ))}
                    </Select>
                </div>
                {getView}
            </div>
            {/* Show list of "projects" */}
            {/* Put AM on service orders */}
        </div>
    );
};

export default AccountManagerProjectBoard;
