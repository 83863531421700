import { useEffect } from 'react';
import { AddressTransport, Customer } from '@alliance-disposal/transport-types';
import { formatServiceAddress } from '@wayste/utils';
import { useFormContext, useWatch } from 'react-hook-form';
import { CustomerForm2Fields } from '../CustomerForm/CustomerForm2';
import { OrderCreateFormProps } from './order-create-form-props';

export interface CustomerSubFormProps {
    disabled: boolean;
    existingCustomer: Customer.AllianceCustomerTransport | null;
    onUseExistingCustomer: (customer: Customer.AllianceCustomerTransport) => void;
}

const CustomerSubForm = ({ disabled, existingCustomer, onUseExistingCustomer }: CustomerSubFormProps) => {
    const { setValue, watch, control } = useFormContext<OrderCreateFormProps>();
    const watchSameAsService = watch('customerSubForm.sameAsService');
    const watchTaxExempt = watch('customerSubForm.taxExempt');
    const watchBillingAddress = watch('customerSubForm.billingAddress');
    const watchServiceLocationAddress = useWatch({ control, name: 'pricingSubForm.serviceLocation.address' });

    useEffect(() => {
        if (
            watchServiceLocationAddress.addressLine1 &&
            formatServiceAddress(watchServiceLocationAddress) === formatServiceAddress(watchBillingAddress)
        ) {
            setValue('customerSubForm.sameAsService', true);
        }
    }, [watchServiceLocationAddress]);

    useEffect(() => {
        if (watchSameAsService) {
            setValue('customerSubForm.billingAddress', watchServiceLocationAddress as AddressTransport);
        }
    }, [watchSameAsService]);

    useEffect(() => {
        if (watchTaxExempt) {
            setValue('pricingSubForm.tax', false);
        }
    }, [watchTaxExempt]);

    return (
        <CustomerForm2Fields
            customer={existingCustomer || undefined} // this was missing before and was not causing an error
            showSameAs
            subForm
            disabled={disabled}
            fieldName="customerSubForm."
            onUseExistingCustomer={(value) => {
                if (value) onUseExistingCustomer(value);
            }}
        />
    );
};

export default CustomerSubForm;
