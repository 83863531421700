import React from 'react';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button, Checkbox, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { RFPFormFromProps } from './rfp-form';
import { SkuExtraPFormProps } from './rfp-form';

const blankSkuExtra: SkuExtraPFormProps = {
    quantity: 1,
    requiredInRfp: true,
    serviceLineItemTypeID: '',
    noteToVendor: '',
};

const RFPSkuExtraSubForm = ({
    skuIndex,
    serviceLineItemTypeOptions,
}: {
    skuIndex: number;
    serviceLineItemTypeOptions: UniversalService.ServiceLineItemType[];
}) => {
    const { control, watch } = useFormContext<RFPFormFromProps>();

    const { fields, append, remove } = useFieldArray({
        name: `skus.${skuIndex}.skuExtras`,
        control,
    });

    const watchServiceFamilyID = watch('serviceFamilyID');

    const handleAddSku = () => {
        append({ ...blankSkuExtra });
    };

    const handleRemoveExtra = (index: number) => {
        remove(index);
    };

    return (
        <div className="flex flex-col gap-y-4 pl-8">
            {fields.map((fieldItem, index) => {
                return (
                    <div className="grid grid-cols-1 gap-y-6 rounded border px-4 py-2">
                        <div className="flex items-center justify-between">
                            <div>Sku Addon {index + 1}</div>
                            <Button className="btn-icon" onClick={() => handleRemoveExtra(index)}>
                                <TrashIcon className="h-6 w-6 text-red-500" />
                            </Button>
                        </div>
                        <div className="text-sm italic text-gray-600">
                            Note: Addons cannot have a service frequency and vendors are only asked for a one time fee for them. If a vendor
                            has a recurring fee for an addon, they should include it in the main service fee for the sku.
                        </div>
                        <div className="flex flex-row gap-x-6">
                            <div>
                                <Controller
                                    name={`skus.${skuIndex}.skuExtras.${index}.requiredInRfp`}
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            label="Required in RFP"
                                            inputProps={{
                                                checked: field.value,
                                                onChange: (e) => field.onChange(e.target.checked),
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            {fieldItem.requiredInRfp && (
                                <div className="text-sm italic text-gray-600">Vendor has to submit a separate price for this addon</div>
                            )}
                        </div>
                        <div>
                            <Controller
                                name={`skus.${skuIndex}.skuExtras.${index}.serviceLineItemTypeID`}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'A service type is required',
                                    },
                                }}
                                render={({ field, fieldState }) => {
                                    let helperText = '';
                                    if (field.value) {
                                        const found = serviceLineItemTypeOptions.find((item) => item.id === field.value);
                                        if (found) {
                                            helperText = found.rfpHelperText || '';
                                        }
                                    }
                                    return (
                                        <Select
                                            required
                                            error={fieldState.error}
                                            value={field.value}
                                            onSelect={(value) => field.onChange(value)}
                                            label="Add on"
                                            disabled={!watchServiceFamilyID}
                                            helperText={helperText}
                                        >
                                            {serviceLineItemTypeOptions
                                                .sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
                                                .map((item) => (
                                                    <SelectOption key={item.id} value={item.id}>
                                                        {item.displayName}
                                                    </SelectOption>
                                                ))}
                                        </Select>
                                    );
                                }}
                            />
                        </div>
                        <div>
                            <Controller
                                key={fieldItem.id + index + 'quantity'}
                                control={control}
                                name={`skus.${skuIndex}.skuExtras.${index}.quantity`}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Quantity is required',
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        type="number"
                                        label="Quantity"
                                        error={fieldState.error}
                                        required
                                        inputProps={{
                                            ...field,
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                key={fieldItem.id + index + 'note'}
                                control={control}
                                name={`skus.${skuIndex}.skuExtras.${index}.noteToVendor`}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label="Addon note"
                                        error={fieldState.error}
                                        inputProps={{
                                            ...field,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                );
            })}
            <div className="flex justify-end">
                <Button className="btn-secondary-outlined" onClick={handleAddSku}>
                    Add Addon (lock bar, wheels, etc)
                </Button>
            </div>
        </div>
    );
};

export default RFPSkuExtraSubForm;
