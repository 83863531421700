import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Dialog, Loading, TextField } from '@wayste/sour-ui';
import { getPrimaryCustomerContact, moneyFormatter } from '@wayste/utils';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { CreateSourceData } from '@stripe/stripe-js';
import { isAxiosError } from 'axios';
import type { Stripe } from 'stripe';

interface StripeInput2Props {
    chargeOption: 'now' | 'save' | 'none';
    customer: Customer.AllianceCustomerTransport;
    isLoading: boolean;
    saveSuccessful: boolean;
    /** Total amount to charge in *CENTS* */
    total: number;
    disabled?: boolean;
    selectedCard?: string;
    stripeCustomer?: Stripe.Customer;
    onChargeFailed: (hideFlash?: boolean) => void;
    onChargeSuccessful: (stripeChargeID: string, last4: string, newCustId: string) => void;
    onSaveSuccessful: (stripeId: string) => void;
    setIsLoading: (isLoading: boolean) => void;
}

const StripeInput2 = ({
    chargeOption,
    customer,
    isLoading,
    saveSuccessful,
    total,
    disabled,
    selectedCard,
    stripeCustomer,
    onChargeFailed,
    onChargeSuccessful,
    onSaveSuccessful,
    setIsLoading,
}: StripeInput2Props) => {
    const client = useWaysteClient();
    const { setShowAlert } = useSourContext();
    const [showConfirm, setShowConfirm] = useState(false);
    const [cardComplete, setCardComplete] = useState(false);
    const [cardholderName, setCardholderName] = useState('');

    const stripe = useStripe();
    const elements = useElements();

    const chargeCardHandler = async (onlySave: boolean) => {
        try {
            if (!stripe || !elements) {
                throw new Error('Stripe is not loaded.');
            }
            setIsLoading(true);

            const primaryContact = getPrimaryCustomerContact(customer) ?? customer.contacts[0];
            const stripeEmail = primaryContact?.email?.toLowerCase().trim() ?? undefined;

            // Set the sourceId to either an existing source
            let sourceId = selectedCard && chargeOption === 'now' ? selectedCard : undefined;

            // Set the sourceId to a newly created one
            if (!selectedCard) {
                const cardElement = elements.getElement(CardElement);
                if (!cardElement) {
                    throw new Error('Card Element is not loaded.');
                }

                const tokenData: CreateSourceData = {
                    type: 'card',
                    usage: 'reusable',
                    owner: {
                        name: cardholderName,
                        email: stripeEmail || undefined,
                        address: customer.billingAddress
                            ? {
                                  line1: customer.billingAddress.addressLine1,
                                  line2: customer.billingAddress.addressLine2,
                                  city: customer.billingAddress.city,
                                  state: customer.billingAddress.state,
                                  postal_code: customer.billingAddress.zip,
                              }
                            : undefined,
                    },
                };

                const sourceCreated = await stripe.createSource(cardElement, tokenData);

                if (sourceCreated && sourceCreated.source) {
                    sourceId = sourceCreated.source.id;
                }
            }

            // If a stripe customer already exists attach it
            let stripeCustomerId = stripeCustomer ? stripeCustomer.id : undefined;

            // If a stripe customer does not exist create one
            if (!stripeCustomer) {
                try {
                    const customer = await client.stripe().adminPortal.customer.create({
                        name: cardholderName,
                        email: stripeEmail,
                        source: sourceId,
                        phone: primaryContact?.phoneNumber ?? undefined,
                    });

                    stripeCustomerId = customer.id;
                } catch (error) {
                    throw new Error('There was an error creating the Stripe Customer');
                }
            }

            // If there is no sourceId or stripeCustomerId, something went wrong
            if (!sourceId || !stripeCustomerId) {
                console.warn('No sourceId or stripeCustomerId');
                return;
            }

            // If it is an existing customer adding a new card, add the card to their account
            if (!selectedCard && stripeCustomer) {
                const source = await client.stripe().adminPortal.customer.sources.create(stripeCustomerId, { sourceId });

                // if it's prepaid throw an error
                if (source.object === 'source' && source?.card?.funding === 'prepaid') {
                    throw new Error('Prepaid cards are not accepted.');
                }
            }

            // If the card is only to be saved, save it. Otherwise charge the card
            if (onlySave) {
                onSaveSuccessful(stripeCustomerId);
            } else {
                const charge = await client.stripe().adminPortal.charge.create({
                    source: sourceId,
                    customer: stripeCustomerId,
                    amount: Math.round(total),
                });

                onChargeSuccessful(charge.id, charge.payment_method_details?.card?.last4 || '', stripeCustomerId);
            }
        } catch (error) {
            console.warn(error);
            let errorMessage = 'There was an error charging the card.';

            // if there is an error.message use that
            if ((error as Error).message) {
                errorMessage = (error as Error).message;
            }

            if (isAxiosError(error)) {
                // if it's a 402 error, the card was declined
                if (error.response?.status === 402) {
                    console.warn(error.response);
                    errorMessage = error.response.data.message || 'The card was declined.';
                }
            }

            setShowConfirm(false);
            setShowAlert({
                severity: 'error',
                message: errorMessage,
            });
            onChargeFailed(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {!selectedCard && (
                <div className="min-w-[300px] rounded bg-white px-3 py-2 shadow shadow-gray-300">
                    <div className="mb-2.5">
                        <TextField
                            label="Cardholder Name"
                            inputProps={{
                                value: cardholderName,
                                onChange: (e) => {
                                    setCardholderName(e.target.value);
                                },
                            }}
                        />
                    </div>
                    <CardElement
                        onChange={(event) => {
                            setCardComplete(event.complete);
                        }}
                    />
                </div>
            )}

            <button
                type="button"
                className={`btn-primary align-middle md:whitespace-nowrap ${!selectedCard ? 'mt-5' : 'mt-0'}`}
                disabled={
                    isLoading ||
                    saveSuccessful ||
                    (chargeOption === 'now' && !total) ||
                    ((!cardComplete || cardholderName.length <= 2) && !selectedCard) ||
                    disabled
                }
                onClick={() => {
                    chargeOption === 'now' ? setShowConfirm(true) : chargeCardHandler(true);
                }}
            >
                {chargeOption === 'now' ? `charge card${total ? ` ${moneyFormatter(total)}` : ''}` : 'save and charge card'}
                {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
            </button>

            {/* Confirm Charge Card Modal */}
            <Dialog open={showConfirm} styledTitle="Confirm Charge" onClose={() => undefined}>
                <p className="pb-6 pt-3">Are you sure you want to charge this card for {moneyFormatter(total)}</p>
                <div className="flex justify-end gap-4">
                    <button className="btn-dark-grey-outlined" onClick={() => setShowConfirm(false)} type="button" disabled={isLoading}>
                        Cancel
                    </button>
                    <button
                        className="btn-primary"
                        onClick={() => {
                            setShowConfirm(false);
                            chargeCardHandler(false);
                        }}
                        type="button"
                        disabled={isLoading}
                    >
                        Confirm
                        {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
                    </button>
                </div>
            </Dialog>
        </div>
    );
};

export default StripeInput2;
