import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice } from '@alliance-disposal/transport-types';
import { AccountCreditTypeLabels } from '@alliance-disposal/transport-types/dist/invoice';
import { DataGrid, DataGridProps } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { format } from 'date-fns';

const CustomerAccountCreditTable = ({ customerId }: { customerId: string }) => {
    const client = useWaysteClient();
    const userIDToNameMap = client.profile().adminPortal.getRosterFromMemory().idToProfileMap;
    const [isLoading, setIsLoading] = useState(true);
    const [accountCredits, setAccountCredits] = useState<Invoice.AccountCreditTransport[]>([]);

    const handleQueryAccountCredit = async () => {
        setIsLoading(true);
        try {
            const data = await client.invoice().adminPortal.accountCredit.query({ customerID: customerId });
            setAccountCredits(data.results.sort((a, b) => (new Date(b.date) < new Date(a.date) ? 1 : -1)));
        } catch (error) {
            console.warn('Error querying account credit', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (customerId) handleQueryAccountCredit();
    }, [customerId]);

    const columns: DataGridProps<Invoice.AccountCreditTransport>['columns'] = [
        {
            key: 'date',
            name: 'Date',
            formatter: ({ row }) => format(new Date(row.date), 'MM/dd/yyyy'),
        },
        {
            key: 'amount',
            name: 'Amount',
            formatter: ({ row }) => moneyFormatter(row.amount),
        },
        {
            key: 'accountCreditType',
            name: 'Type',
            formatter: ({ row }) => AccountCreditTypeLabels[row.accountCreditType],
        },
        {
            key: 'note',
            name: 'Note',
        },
        {
            key: 'metadata.createdByUserID',
            name: 'Created At',
            formatter: ({ row }) => (row.metadata?.createdByUserID ? userIDToNameMap[row.metadata.createdByUserID]?.fullName : ''),
        },
    ];

    return <DataGrid columns={columns} rows={accountCredits} loading={isLoading} />;
};

export default CustomerAccountCreditTable;
