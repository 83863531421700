import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { Button, Dialog } from '@wayste/sour-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { UIContext } from '../../contexts';
import ROLeadSubForm from '../LeadForms/ROLeadSubForm';
import USLeadSubForm from '../LeadForms/USLeadSubForm';
import { ROLeadFormType, USLeadFormType, prepareROLeadSubForm, prepareUSLeadSubForm } from '../LeadForms/utils/prepare-lead-form';

export interface LeadUpdateProps {
    onClose: () => void;
    open: boolean;
    lead: Customer.AllianceLeadTransport;
}

const LeadUpdate = ({ onClose, lead, open }: LeadUpdateProps) => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<ROLeadFormType | USLeadFormType>({
        mode: 'all',
        defaultValues: lead.type === 'rollOff' ? prepareROLeadSubForm(lead) : prepareUSLeadSubForm(lead),
    });
    const {
        handleSubmit,
        reset,
        formState: { isValid },
    } = methods;

    useEffect(() => {
        lead.type === 'rollOff' ? reset(prepareROLeadSubForm(lead)) : reset(prepareUSLeadSubForm(lead));
    }, [open]);

    const submitForm = async (data: ROLeadFormType | USLeadFormType) => {
        console.log('data: ', data);
        setIsLoading(true);
        const updateData: Customer.AllianceLeadUpdateTransport = {
            ...data,
            phoneNumber: data.phoneNumber || null,
            email: data.email || null,
            customerNotes: data.customerNotes || null,
        };
        if ('requestedSizeString' in data && data.requestedSizeString) {
            updateData.requestedSize = {
                size: Number(data.requestedSizeString),
                type: '',
            };
        }
        try {
            await client.customer().adminPortal.leads.update(lead.id, updateData);
            showFlash('Lead Successfully Updated', 'success');
            onClose();
        } catch (error) {
            showFlash('Error Updating Lead', 'warning');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} styledTitle="Update Lead" className="max-w-3xl">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submitForm)}>
                    {lead.type === 'rollOff' ? <ROLeadSubForm lead={lead} /> : <USLeadSubForm lead={lead} />}
                    <div className="flex justify-end gap-4 pt-2">
                        <Button className="btn-dark-grey-outlined" onClick={onClose} disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button className="btn-primary" type="submit" disabled={!isValid} loading={isLoading}>
                            Save
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default LeadUpdate;
