import { useEffect, useState } from 'react';
import { Customer, Pricing, Profile } from '@alliance-disposal/transport-types';
import { Button, RadioGroup, Select, SelectOption, Toggle } from '@wayste/sour-ui';
import { getPrimaryCustomerContact, round } from '@wayste/utils';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { createOrderConfirmationEmailPreview } from '../../utils/email-utils';
import { paymentMethods, paymentMethodsEnums, paymentTerms, paymentTermsEnums } from '../../utils/shared-types';
import ChargeCard2 from '../card-payment/ChargeCard2';
import { OrderCreateFormProps } from './order-create-form-props';

export interface PaymentSubFormProps {
    user: Profile.ProfileTransport;
    isLoading: boolean;
    disabled: boolean;
    zonePricing: Pricing.PricingTransport | null;
}

const PaymentSubForm = ({ user, isLoading, disabled, zonePricing }: PaymentSubFormProps) => {
    const [chargeOption, setChargeOption] = useState('now');
    const [cardSuccessful, setCardSuccessful] = useState(false);
    const [primaryContact, setPrimaryContact] = useState<Customer.AllianceCustomerContactTransport | null>(null);

    const {
        control,
        setValue,
        watch,
        formState: { isValid },
    } = useFormContext<OrderCreateFormProps>();
    const watchPrice = watch('pricingSubForm.price');
    const watchOtherLineItems = watch('pricingSubForm.otherLineItems');
    const watchFinalPrice = watch('pricingSubForm.finalPrice');
    const watchCustomer = useWatch({ name: 'customerSubForm', control });
    const watchValues = useWatch({ name: 'paymentSubForm', control });
    const watchPricingSubForm = useWatch({ name: 'pricingSubForm', control });
    const watchDispatchSubForm = useWatch({ name: 'dispatchSubForm', control });

    useEffect(() => {
        let total = Number(watchPrice);
        watchOtherLineItems.forEach((item) => {
            total = total + Number(item.totalPrice);
        });
        setValue('pricingSubForm.finalPrice', round(total));
    }, [watchPrice, watchOtherLineItems]);

    useEffect(() => {
        const primaryContactFound = getPrimaryCustomerContact(watchCustomer as Customer.AllianceCustomerTransport);
        setPrimaryContact(primaryContactFound);
        setValue('paymentSubForm.sendEmail', primaryContactFound.email ? true : false);
    }, [watchCustomer.contacts]);

    const handleChargeSuccessful = (stripeChargeID: string, last4: string, stripeId: string) => {
        const paymentInfo = {
            paymentReceivedDate: new Date(),
            amount: watchFinalPrice,
            paymentMethod: watchValues.paymentMethod,
            paymentIdentifier: last4,
            stripeChargeID: stripeChargeID,
        };
        setValue('customerSubForm.stripeId', stripeId);
        setValue('paymentSubForm.paymentInfo', paymentInfo);
        setCardSuccessful(true);
    };

    const handleSaveSuccessful = (stripeId: string) => {
        setValue('customerSubForm.stripeId', stripeId);
        setCardSuccessful(true);
    };

    return (
        <div className="flex flex-col gap-6">
            <h6 className="text-xl">Payment Info</h6>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <Controller
                    name="paymentSubForm.paymentTerm"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Payment term"
                            disabled={Boolean(watchValues.paymentInfo) || disabled}
                            value={field.value}
                            onSelect={field.onChange}
                            helperText="You can set default payment settings in the customer details screen"
                        >
                            {Object.entries(paymentTerms).map((item) => (
                                <SelectOption key={`paymentTerm-${item[0]}`} value={item[0]}>
                                    {item[1]}
                                </SelectOption>
                            ))}
                        </Select>
                    )}
                />
                <Controller
                    name="paymentSubForm.paymentMethod"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Payment method"
                            disabled={Boolean(watchValues.paymentInfo) || disabled}
                            value={field.value}
                            onSelect={field.onChange}
                            helperText="You can set default payment settings in the customer details screen"
                        >
                            {Object.entries(paymentMethods).map((item) => (
                                <SelectOption key={`paymentMethod-${item[0]}`} value={item[0]}>
                                    {item[1]}
                                </SelectOption>
                            ))}
                        </Select>
                    )}
                />
            </div>
            <div className="mt-4 flex flex-col gap-6">
                {watchValues.paymentTerm !== paymentTermsEnums.onCharge && (
                    <>
                        <div className="flex flex-col gap-1">
                            <b>Issue Invoice</b>
                            <i>
                                If you want to make edits to this orders invoice before sending it to the customer, click <b>Draft</b>
                            </i>
                        </div>
                        <div className="flex">
                            <Controller
                                name="paymentSubForm.issueInvoice"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        options={[
                                            { value: 'NOW', label: 'Now' },
                                            { value: 'DRAFT', label: 'Draft' },
                                        ]}
                                        value={field.value}
                                        onChange={field.onChange}
                                        name="paymentSubForm.issueInvoice"
                                        className="flex flex-col gap-1"
                                    />
                                )}
                            />
                        </div>
                    </>
                )}
                <div>
                    <b>Read This to Customer:</b> So you are aware there is a $50 cancellation fee if you cancel at any time before your
                    delivery date. If you cancel on your delivery date, of if we are unable to deliver the dumpster, a trip charge may also
                    be applied. We do not pickup your dumpster automatically, we wait to hear from you.
                </div>
            </div>
            {watchValues.paymentMethod === paymentMethodsEnums.creditCard && (
                <ChargeCard2
                    total={watchFinalPrice}
                    showChargeOption
                    customer={{ ...watchCustomer } as Customer.AllianceCustomerTransport}
                    disabled={disabled}
                    onChargeSuccessful={handleChargeSuccessful}
                    onSaveSuccessful={handleSaveSuccessful}
                    onChargeOptionChange={setChargeOption}
                />
            )}
            <h6 className="text-xl">Email Order Summary</h6>
            <Controller
                name="paymentSubForm.sendEmail"
                control={control}
                render={({ field }) => (
                    <Toggle
                        value={field.value}
                        label="Send email"
                        onChange={(value) => {
                            setValue('paymentSubForm.sendEmail', value);
                        }}
                        disabled={!primaryContact?.email}
                    />
                )}
            />

            <div className="flex w-full flex-col">
                {watchValues.sendEmail ? (
                    <div className="mb-2 opacity-70">Email preview</div>
                ) : (
                    <div className="mb-2 text-red-600">YOU MUST READ THE BELOW TO THE CUSTOMER OVER THE PHONE OR SEND THEM AN EMAIL</div>
                )}
                <div className="rounded border bg-white px-3.5 py-2.5 text-sm">
                    <div className="mb-2.5">
                        Hi {primaryContact?.firstName},
                        <br />
                        <br />
                        This is {user?.firstName} with Sourgum Waste confirming your roll-dumpster rental. If you need anything you can give
                        us a call at (732) 366-9355.
                    </div>
                    <textarea
                        rows={4}
                        className="w-full rounded border p-2 text-sm"
                        value={watchValues.emailMessage}
                        onChange={(event) => setValue('paymentSubForm.emailMessage', event.target.value)}
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: createOrderConfirmationEmailPreview(
                                {
                                    ...watchPricingSubForm,
                                    ...watchDispatchSubForm,
                                    emailMessage: watchValues.emailMessage,
                                },
                                watchFinalPrice,
                                user,
                                zonePricing?.prohibitedItems ? zonePricing.prohibitedItems.join(', ') : undefined,
                            ),
                        }}
                    />
                </div>
            </div>
            <div className="mb-2.5 flex justify-end">
                <Button
                    className="btn-primary"
                    disabled={
                        isLoading ||
                        !isValid ||
                        disabled ||
                        (watchValues.paymentMethod === 'creditCard' &&
                            watchValues.paymentTerm === 'onCharge' &&
                            chargeOption !== 'none' &&
                            !cardSuccessful)
                    }
                    type="submit"
                    loading={isLoading}
                    disableEnterClick
                >
                    Create Order
                </Button>
            </div>
        </div>
    );
};

export default PaymentSubForm;
