import React, { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { SourFiltersOld, SourSearchOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { Button, Dialog, TextField } from '@wayste/sour-ui';
import { formatServiceAddress, moneyFormatter } from '@wayste/utils';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';

const RemittanceSummaryModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const client = useWaysteClient();
    const [transactionID, setTransactionID] = useState('');
    const [customerID, setCustomerID] = useState<string>();
    const [loading, setIsLoading] = useState(false);
    const [message, setMessage] = useState<string>();
    const [search, setSearch] = useState<string>('');

    // DEFINE ON NAVIGATE LOGIC
    const onNavigate = (entity: Customer.AllianceCustomerTransport, recordID: string) => {
        const primaryContact = entity?.contacts?.find((contact) => contact.primaryContact);
        setSearch(`${primaryContact?.firstName} ${primaryContact?.lastName}${entity?.companyName ? ` (${entity.companyName})` : ''}`);
        setCustomerID(recordID);
    };

    const sanitizeSheetName = (name: string) => {
        let sanitizedName = name.replace(/[:\\/?*[\]]/g, ''); // replace invalid characters
        sanitizedName = sanitizedName.slice(0, 31); // truncate to maximum length
        return sanitizedName;
    };

    const handleDownloadPayoutReport = (payoutSummary: { [key: string]: string[][] }) => {
        // create a new workbook
        const wb = XLSX.utils.book_new();
        const sheets = [];
        for (const vendorName in payoutSummary) {
            // convert the data to a worksheet && add the worksheet to the workbook
            sheets.push({ ws: XLSX.utils.aoa_to_sheet(payoutSummary[vendorName]), name: sanitizeSheetName(vendorName) });
        }
        sheets.forEach((item) => {
            XLSX.utils.book_append_sheet(wb, item.ws, item.name);
        });
        // write the workbook and download it
        XLSX.writeFile(wb, 'payout_summary.xlsx');
    };

    const handleOnClose = () => {
        onClose();
        setTransactionID('');
        setCustomerID('');
        setIsLoading(false);
        setSearch('');
        setMessage('');
    };

    const sanitizeStringForObjKey = (str: string) => {
        // Step 1: Remove leading and trailing whitespace
        const trimmedStr = str.trim();
        // Step 2: Replace special characters with a safe alternative
        const sanitizedStr = trimmedStr.replace(/[^\w]/g, '-');
        // Step 3: Convert to lowercase
        const lowercaseStr = sanitizedStr.toLowerCase();
        return lowercaseStr;
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setMessage(undefined);
        if (!transactionID) return;
        try {
            setIsLoading(true);

            const response = await client.invoice().adminPortal.payment.query({
                paymentIdentifer: transactionID,
                customerID: customerID,
            });

            if (!response) {
                throw new Error('Query order error');
            }
            const payments = response?.results;

            if (!payments || payments?.length === 0) {
                setMessage('No payments found for provided filters.');
                setIsLoading(false);
                return;
            }

            const paymentsObj: { [key: string]: string[][] } = {};

            for (const payment of payments) {
                if (!payment.invoiceParentID) return;
                const receivableResponse = await client.invoice().adminPortal.receivable.fetch(payment.invoiceParentID);
                const receivable = receivableResponse;
                if (!receivable) {
                    alert('Missing receivable get SAP dev');
                    return;
                }

                if (!receivable.invoiceDetails.orderServiceLocation?.address && receivable.invoiceDetails.orderID) {
                    const order = await client.order().adminPortal.fetch(receivable.invoiceDetails.orderID);
                    receivable.invoiceDetails.orderServiceLocation = order?.serviceLocation;
                } else if (!receivable.invoiceDetails.orderServiceLocation?.address && receivable.invoiceDetails.serviceOrderID) {
                    const serviceOrder = await client.universalService().serviceOrder.fetch(receivable.invoiceDetails.serviceOrderID);
                    receivable.invoiceDetails.orderServiceLocation = serviceOrder?.serviceLocation;
                }

                const newArray = [
                    receivable.customerCompanyName || receivable.customerName || search || receivable.customerID,
                    format(new Date(payment.date), 'MM/dd/yy'),
                    payment.paymentMethod || '',
                    payment.paymentIdentifier || '',
                    receivable.invoiceDetails.invoiceNumber || '',
                    moneyFormatter(payment.amount) || '',
                    formatServiceAddress(receivable.invoiceDetails.orderServiceLocation?.address) || '',
                    receivable.invoiceDetails.orderNumber || '',
                ];
                if (paymentsObj[sanitizeStringForObjKey(receivable.customerName || search || receivable.customerID)]) {
                    paymentsObj[sanitizeStringForObjKey(receivable.customerName || search || receivable.customerID)].push(newArray);
                } else {
                    paymentsObj[sanitizeStringForObjKey(receivable.customerName || search || receivable.customerID)] = [
                        [
                            'Customer Name',
                            'Payment Date',
                            'Paid Via',
                            'Transaction ID',
                            'Invoice Number',
                            'Amount',
                            'Service Location',
                            'Order Number',
                        ],
                        newArray,
                    ];
                }
            }
            handleDownloadPayoutReport(paymentsObj);
        } catch (error) {
            console.warn('submit error: ', error);
            alert('An error has occurred, please try again or contact dev.');
        }
        setIsLoading(false);
    };

    return (
        <Dialog styledTitle="Payout Summary" open={open} onClose={() => handleOnClose()} className="w-full overflow-visible">
            <form onSubmit={onSubmit} className="flex flex-col gap-3">
                {search ? (
                    <div className="flex h-6 flex-row items-center justify-between p-1">
                        <div>{search}</div>
                        <Button
                            onClick={() => {
                                setSearch('');
                                setCustomerID('');
                            }}
                        >
                            <XMarkIcon className="size-4" />
                        </Button>
                    </div>
                ) : (
                    <div>
                        <SourSearchWrapperOld
                            options={{
                                application: 'aap',
                                apiKey: import.meta.env.VITE_ELASTIC_KEY,
                                environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                            }}
                            onNavigate={onNavigate}
                            highlight={true}
                            defaultFilters={{
                                query: {
                                    type: 'sourgum-customer',
                                },
                            }}
                            size={10}
                        >
                            <div className="flex w-full flex-row justify-between space-x-4">
                                <SourSearchOld
                                    options={{
                                        searchPopoverFixed: false,
                                        showTips: false,
                                        showMessages: false,
                                        resultSize: 'w-[550px]',
                                        searchPositionFixed: true,
                                        placeholder: 'Search customers',
                                    }}
                                />
                                <div className="hidden">
                                    <SourFiltersOld />
                                </div>
                            </div>
                        </SourSearchWrapperOld>
                    </div>
                )}
                <TextField
                    label="Transaction ID"
                    required
                    inputProps={{
                        value: transactionID,
                        onChange: (e) => setTransactionID(e.target.value),
                    }}
                />
                <div>{message}</div>
                <Button className="btn-primary w-full" type="submit" loading={loading} disabled={!transactionID}>
                    Download
                </Button>
            </form>
        </Dialog>
    );
};

export default RemittanceSummaryModal;
