import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import ContactsListCard from '../ContactsListCard/ContactsListCard';
import CustomerDetailsCard from '../CustomerDetailsCard';
import { InternalTicket } from '../InternalTicket';
import Loading from '../Loading';

type CustomerDetailsProps = {
    customer?: Customer.AllianceCustomerTransport;
    id?: string;
    edit?: boolean;
};

const CustomerDetails = ({ customer, id, edit = false }: CustomerDetailsProps) => {
    const client = useWaysteClient();
    const [isLoading, setIsLoading] = useState(false);
    const [customerData, setCustomerData] = useState<Customer.AllianceCustomerTransport | null>(null);

    const fetchCustomer = async (passedID: string) => {
        setIsLoading(true);
        const response = await client.customer().adminPortal.fetch(passedID);
        setCustomerData(response);
        setIsLoading(false);
    };

    useEffect(() => {
        if (customer) {
            setCustomerData(customer);
            return;
        }
        if (id) {
            fetchCustomer(id);
        }
    }, [customer, id]);

    if (isLoading || !customerData) return <Loading fullScreen />;

    return (
        <>
            <CustomerDetailsCard customer={customerData} edit={edit} />
            <ContactsListCard customer={customerData} />
            <InternalTicket entityID={customerData.id} entityType="sourgum-customer" />
        </>
    );
};

export default CustomerDetails;
