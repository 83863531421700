import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Loading, Toggle } from '@wayste/sour-ui';
import { endOfCurrentPeriod } from '@wayste/utils';
import { format } from 'date-fns';
import type Stripe from 'stripe';
import SelectCard from '../../../components/card-payment/SelectCard';
import { UIContext } from '../../../contexts';

interface AutoPaySelectionProps {
    stripeCustomerID: string;
    onSelection: (autopayEnabled: boolean, sourceID?: string) => void;
    autopayEnabled?: boolean;
    autopaySourceID?: string;
    subscriptionInfo?: UniversalService.SubscriptionDetails;
    refetchKey?: number;
}

const AutoPaySelection = ({
    stripeCustomerID,
    onSelection,
    autopayEnabled,
    autopaySourceID,
    subscriptionInfo,
    refetchKey,
}: AutoPaySelectionProps) => {
    const [stripeCustomer, setStripeCustomer] = useState<Stripe.Customer>();
    const [stripeLoading, setStripeLoading] = useState(false);
    const [isEnabled, setIsEnabled] = useState<boolean>(autopayEnabled || false);
    const [selectedCard, setSelectedCard] = useState<string | null>(autopaySourceID || null);
    const [disabled, setDisabled] = useState<boolean>(false);

    const { showFlash } = useContext(UIContext);

    const client = useWaysteClient();

    const getStripeData = async (stripeID: string) => {
        setStripeLoading(true);
        try {
            const data = await client.stripe().adminPortal.customer.fetch(stripeID);
            setStripeCustomer(data);
            setStripeLoading(false);
            return data;
        } catch (error) {
            console.error('Stripe Data Error: ', error);
            showFlash('An Error Occurred', 'warning');
            setStripeLoading(false);
            return null;
        }
    };

    const handleAutoPayEnabled = (enabled: boolean) => {
        setIsEnabled(enabled);
        if (!enabled) {
            setSelectedCard(null);
            onSelection(enabled);
        }
    };

    const handleCardSelection = (sourceID: string) => {
        if (!sourceID) return;
        setSelectedCard(sourceID);
        onSelection(true, sourceID);
    };

    const nextBillingDate = () => {
        if (!subscriptionInfo) return;
        const startDate = new Date(subscriptionInfo.startDate);
        try {
            const nextBillingDate = endOfCurrentPeriod({
                billingDay: subscriptionInfo.billingDay,
                billingFrequency: subscriptionInfo.billingFrequency,
                billingFrequencyUnit: subscriptionInfo.billingFrequencyUnit,
                now: startDate,
            });
            return format(nextBillingDate, 'MM/dd/yyyy');
        } catch (error) {
            console.error('nextBillingDate Error: ', error);
        }
    };

    useEffect(() => {
        if (stripeCustomerID) {
            getStripeData(stripeCustomerID).then((data) => {
                if (data) setDisabled(data.sources?.data.length === 0);
            });
        }
    }, [stripeCustomerID, refetchKey]);

    return (
        <div>
            {stripeLoading ? (
                <div className="flex w-full items-center justify-center">
                    <Loading />
                </div>
            ) : (
                <div>
                    <h5 className="mb-2 w-full border-b pb-2 text-lg">Autopay</h5>
                    {subscriptionInfo?.startDate && (
                        <div>
                            <p>First Autopay Date: {nextBillingDate()}</p>
                        </div>
                    )}
                    <div className="my-4">
                        <Toggle
                            label={`Autopay ${isEnabled ? 'enabled' : 'disabled'} `}
                            value={isEnabled}
                            onChange={handleAutoPayEnabled}
                            disabled={disabled}
                        />
                    </div>
                    <div className="my-4">
                        <SelectCard
                            disableAddCard={true}
                            stripeCustomer={stripeCustomer || null}
                            disabled={!isEnabled}
                            onCardSelected={handleCardSelection}
                            selectedCard={selectedCard || ''}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AutoPaySelection;
