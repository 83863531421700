import { useContext, useEffect } from 'react';
import type { InternalTicket as InternalTicketTypes } from '@alliance-disposal/transport-types';
import { Disclosure } from '@headlessui/react';
import { InternalTicketContent } from './InternalTicketContent';
import { InternalTicketContext, InternalTicketProvider } from './context';

type InternalTicketProps = {
    entityID: string;
    entityType: InternalTicketTypes.InternalTicketRelatedEntity;
    readOnly?: boolean;
    defaultOpen?: boolean;
};

const InternalTicketBody = ({ entityID, entityType, readOnly, defaultOpen }: InternalTicketProps) => {
    const { setEntityID, setEntityType } = useContext(InternalTicketContext);

    useEffect(() => {
        setEntityID(entityID);
        setEntityType(entityType);
    }, [entityID, entityType]);

    return (
        <Disclosure defaultOpen={defaultOpen !== undefined ? defaultOpen : true}>
            {({ open }) => {
                return <InternalTicketContent open={open} readOnly={readOnly} />;
            }}
        </Disclosure>
    );
};

export const InternalTicket = ({ entityID, entityType, readOnly, defaultOpen }: InternalTicketProps) => {
    return (
        <InternalTicketProvider>
            <InternalTicketBody entityID={entityID} entityType={entityType} readOnly={readOnly} defaultOpen={defaultOpen} />
        </InternalTicketProvider>
    );
};
