import React from 'react';
import { Loading as SourLoading } from '@wayste/sour-ui';

const Loading = ({ fullScreen }: { fullScreen?: boolean }) => {
    return (
        <div
            className={
                fullScreen
                    ? 'fixed bottom-0 left-0 right-0 top-0 z-[1300] bg-black bg-opacity-50 backdrop-blur-sm'
                    : 'container mx-auto p-6'
            }
        >
            <SourLoading size="h-12 w-12" className="absolute bottom-0 left-0 right-0 top-0 m-auto" />
        </div>
    );
};

export default Loading;
