import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice } from '@alliance-disposal/transport-types';
import { Button, CurrencyTextField, Dialog, RadioGroup, Select, SelectOption, Textarea } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import axios from 'axios';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import type Stripe from 'stripe';
import { UIContext } from '../../../../contexts';
import { useAuthToken } from '../../../../hooks/authhook';
import { BillingContext } from '../../context';

const refundMethods = {
    stripe: 'Stripe',
    accountCredit: 'Account Credit',
    check: 'Check',
    other: 'Other',
};
const refundReason = {
    duplicate: 'Duplicate',
    requested_by_customer: 'Requested by Customer',
    other: 'Other',
};

interface FormProps {
    paymentRefundOptionID: string;
    amount: number | '';
    paymentMethod: keyof typeof refundMethods | '';
    reason: keyof typeof refundReason | '';
    notes: string;
    methodNotes: string;
    refundType: 'DISCOUNT_INVOICE' | 'OPEN_INVOICE';
}

interface Props {
    open: boolean;
    onClose: () => void;
    invoice: Invoice.ReceivableTransport;
}

const waysteStripeEndpoint = import.meta.env.VITE_BASE_API_URL + '/stripe';

const createStripeRefund = async (
    stripeChargeID: string,
    totalCents: number,
    token: string,
    reason?: null | 'duplicate' | 'fraudulent' | 'requested_by_customer',
): Promise<Stripe.Response<Stripe.Refund>> => {
    return new Promise(async (resolve, reject) => {
        const amount = totalCents;
        const data = {
            charge: stripeChargeID,
            amount: amount,
            reason: reason,
        };
        const url = waysteStripeEndpoint + '/refund/';
        try {
            const res = await axios({
                method: 'POST',
                url,
                headers: { Authorization: token },
                data,
            });
            resolve(res.data);
        } catch (error) {
            console.warn('axios Stripe createStripeRefund Error: ', error);
            reject(error);
        }
    });
};

const RefundDialog = ({ open, onClose, invoice }: Props) => {
    const client = useWaysteClient();
    const { token } = useAuthToken();
    const { showFlash } = useContext(UIContext);
    const { refetchOrder, selectedOrder, selectedOrderType } = useContext(BillingContext);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentRefundOption] = useState<Invoice.PaymentTransport | undefined>(invoice?.invoiceDetails.payments[0]);

    const handleDiscountRefund = async (updatedReceivable: Invoice.ReceivableTransport) => {
        if (!selectedOrder) throw new Error('Order is not loaded');
        // if the update has an id then it is an update otherwise it is a create
        if (selectedOrderType === 'roll-off') {
            updatedReceivable.invoiceDetails.orderID = selectedOrder.id; // make sure this is always set
        } else if (selectedOrderType === 'universal-service') {
            updatedReceivable.invoiceDetails.serviceOrderID = selectedOrder.id; // make sure this is always set
        }

        // if it is an update we need to update the invoice
        await client.invoice().adminPortal.receivable.update(updatedReceivable.id, updatedReceivable as Invoice.ReceivableUpdateTransport);
    };

    const {
        control,
        reset,
        watch,
        register,
        handleSubmit,
        formState: { isDirty, isValid },
    } = useForm<FormProps>({
        mode: 'all',
        defaultValues: {
            paymentRefundOptionID: invoice.invoiceDetails.payments[0].id,
            amount: '',
            paymentMethod: '',
            reason: '',
            notes: '',
            methodNotes: '',
            refundType: 'DISCOUNT_INVOICE',
        },
    });

    const watchPaymentMethod = watch('paymentMethod');

    const handleStripeRefund = async (values: FormProps): Promise<'error' | { id: string }> => {
        if (!paymentRefundOption?.stripeChargeID) throw new Error('Stripe charge id is not available');
        try {
            return (await createStripeRefund(
                paymentRefundOption?.stripeChargeID,
                Number(values.amount),
                token,
                values.reason === 'other' ? 'requested_by_customer' : values.reason || undefined,
            )) as { id: string };
        } catch (error) {
            console.warn('handleStripeRefund error: ', error);
            return 'error';
        }
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    const onSubmit = async (values: FormProps) => {
        setIsLoading(true);
        const notes = values.notes + ' Method Notes: ' + values.methodNotes;
        const transactionObject: Invoice.RefundCreateTransport = {
            // TODO needs an invoice id to associate it to
            date: new Date().toISOString(),
            amount: Math.abs(Number(values.amount)),
            paymentMethod: values.paymentMethod,
            refundIdentifier: values.paymentMethod === 'check' ? values.methodNotes : '',
            reason: values.reason,
            notes: notes,
            stripeChargeID: undefined,
            stripeRefundID: undefined,
        };

        if (values.paymentMethod === 'stripe') {
            const stripeResults = await handleStripeRefund(values);
            transactionObject.refundIdentifier = paymentRefundOption?.paymentIdentifier;
            if (stripeResults === 'error') {
                alert('An error occurred creating the Stripe refund ...  get SAP Dev');
                return;
            }
            transactionObject.stripeChargeID = paymentRefundOption?.stripeChargeID;
            transactionObject.stripeRefundID = stripeResults.id;
        }
        // newLineItem is needed to keep invoice total in sync with change...TODO handle edge case of overpayment
        const newLineItem: Invoice.LineItemInputTransport = {
            itemName: 'Refund',
            description: format(new Date(), 'MM/dd/yy'),
            quantity: 1,
            unitPrice: -Math.abs(Number(values.amount)),
            totalPrice: -Math.abs(Number(values.amount)),
            taxable: false,
        };

        let invoiceResponse;
        try {
            invoiceResponse = await client.invoice().adminPortal.refund.create(invoice.invoiceDetails.id, transactionObject);
            const invoiceToUpdate: Invoice.ReceivableUpdateTransport = { ...invoice };
            if (invoice.invoiceDetails) {
                invoiceToUpdate.invoiceDetails = {
                    ...invoice,
                    ...invoice.invoiceDetails,
                    lineItems: [...invoice.invoiceDetails.lineItems, newLineItem],
                };
            }
            if (values.refundType === 'DISCOUNT_INVOICE') await handleDiscountRefund(invoiceToUpdate as Invoice.ReceivableTransport);
            refetchOrder();
            showFlash('Refund successfully created', 'success');
        } catch (error) {
            showFlash('An error occurred updating the database', 'warning');

            return;
        }

        if (values.paymentMethod === 'accountCredit') {
            try {
                await client.invoice().adminPortal.accountCredit.create({
                    amount: Number(values.amount),
                    date: new Date().toISOString(),
                    note: values.notes,
                    invoiceID: invoice.invoiceDetails.id,
                    invoicePaymentID: values.paymentRefundOptionID,
                    invoiceRefundID: invoiceResponse.refund.id,
                    customerID: invoice.customerID,
                    accountCreditType: 'PAYMENT_REVERSAL',
                });
            } catch (error) {
                console.warn('account credit error: ', error);
                showFlash('An error occurred updating the database', 'warning');
            }
        }

        setIsLoading(false);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} styledTitle="Refund Payment">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-4">
                    <div className="w-full">
                        <Controller
                            name="paymentRefundOptionID"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'A payment is required',
                                },
                            }}
                            render={({ field }) => (
                                <Select
                                    label="Payment to Refund"
                                    value={field.value}
                                    onSelect={(value) => field.onChange(value)}
                                    required={true}
                                >
                                    {invoice.invoiceDetails.payments.map((item) => (
                                        <SelectOption key={`paymentRefundOption-${item.id}`} value={item.id}>
                                            Payment for {moneyFormatter(item.amount)}
                                        </SelectOption>
                                    ))}
                                </Select>
                            )}
                        />
                    </div>
                    <div className="w-full">
                        <Controller
                            name="amount"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'A refund amount is required',
                                },
                            }}
                            render={({ field }) => (
                                <CurrencyTextField
                                    label="Refund amount"
                                    value={field.value}
                                    onChange={(value) => field.onChange(value)}
                                    useCents
                                    required
                                />
                            )}
                        />
                    </div>
                    <div className="w-full">
                        <Controller
                            name="paymentMethod"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Refund method is required',
                                },
                            }}
                            render={({ field }) => (
                                <Select label="Method" value={field.value} onSelect={(value) => field.onChange(value)} required={true}>
                                    {Object.entries(refundMethods).map((item) => (
                                        <SelectOption
                                            key={`method-${item[0]}`}
                                            value={item[0]}
                                            disabled={item[0] === 'stripe' && !paymentRefundOption?.stripeChargeID}
                                        >
                                            {item[1]}
                                        </SelectOption>
                                    ))}
                                </Select>
                            )}
                        />
                        {watchPaymentMethod === 'stripe' && (
                            <div className="text-xs opacity-50">Refunds take 5-10 days to appear on a customer's statement.</div>
                        )}
                    </div>
                    {watchPaymentMethod === 'check' || watchPaymentMethod === 'other' ? (
                        <div className="w-full">
                            <Textarea
                                placeholder={watchPaymentMethod === 'check' ? 'Check number' : 'How did you issue the refund?'}
                                required={true}
                                inputProps={{
                                    ...register('methodNotes', { required: 'Method notes are required' }),
                                }}
                            />
                        </div>
                    ) : null}
                    <div className="w-full">
                        <Controller
                            name="reason"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'A refund reason is required',
                                },
                            }}
                            render={({ field }) => (
                                <Select label="Reason" value={field.value} onSelect={(value) => field.onChange(value)} required={true}>
                                    {Object.entries(refundReason).map((item) => (
                                        <SelectOption key={`reason-${item[0]}`} value={item[0]}>
                                            {item[1]}
                                        </SelectOption>
                                    ))}
                                </Select>
                            )}
                        />
                    </div>
                    <div className="w-full">
                        <Controller
                            control={control}
                            name="refundType"
                            rules={{
                                required: {
                                    value: true,
                                    message: 'A refund type is required',
                                },
                            }}
                            render={({ field }) => (
                                <RadioGroup
                                    name="refundType"
                                    required
                                    defaultValue={field.value}
                                    options={[
                                        { value: 'DISCOUNT_INVOICE', label: 'Discount invoice' },
                                        { value: 'OPEN_INVOICE', label: 'Re-Open invoice' },
                                    ]}
                                    onChange={(value) => {
                                        field.onChange(value);
                                    }}
                                    className="flex gap-2"
                                />
                            )}
                        />
                    </div>
                    <div className="w-full">
                        <Textarea
                            placeholder="Add more details about this refund"
                            required={watchPaymentMethod === 'other' || watchPaymentMethod === 'accountCredit'}
                            inputProps={{
                                ...register('notes', { required: watchPaymentMethod === 'other' ? 'Notes are required' : false }),
                            }}
                        />
                    </div>
                </div>
                <div className="mt-2 flex justify-end gap-4 border-t px-2 pt-4">
                    <button className="btn-dark-grey-outlined mr-2" onClick={handleClose} type="button" disabled={isLoading}>
                        Cancel
                    </button>
                    <Button className="btn-primary" type="submit" disabled={!isValid || !isDirty} loading={isLoading}>
                        Refund
                    </Button>
                </div>
            </form>
        </Dialog>
    );
};

export default RefundDialog;
