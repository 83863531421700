import { useParams } from 'react-router-dom';
import ServiceGroupingDetails from '.';

const ServiceGroupingDetailsPage = () => {
    const { serviceGroupingId } = useParams<{ serviceGroupingId: string }>();

    return (
        <div className="flex-1 bg-slate-50">
            <div className="container mx-auto p-6 pt-5">
                <div className="flex items-center">
                    <div className="mb-4 text-2xl">Service Grouping Details</div>
                </div>
                <ServiceGroupingDetails serviceGroupingId={serviceGroupingId} />
            </div>
        </div>
    );
};

export default ServiceGroupingDetailsPage;
