import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { useSourContext } from '@wayste/sour-context';
import { Button, Dialog, Textarea } from '@wayste/sour-ui';

type Props = {
    open: boolean;
    onClose: (submitted?: boolean) => void;
    rfpVendorResponseId: string;
};

const DeclineToBidDialog = ({ open, onClose, rfpVendorResponseId }: Props) => {
    const client = useWaysteClient();
    const { setShowToast, setIsLoading } = useSourContext();
    const [declineMessage, setDeclineMessage] = useState('');

    const handleCloseClick = (submitted?: boolean) => {
        setDeclineMessage('');
        onClose(submitted);
    };

    const handleSubmitClick = async () => {
        // declineMessage
        setIsLoading(true);
        try {
            await client
                .universalService()
                .adminPortal.rfpVendorResponse.update(rfpVendorResponseId, { status: 'DECLINED', declinedMessage: declineMessage });
            setShowToast({
                message: 'Successfully declined to bid.',
                severity: 'success',
            });
        } catch (error) {
            console.error('Error declining to bid', error);
            setShowToast({
                message: 'Error occurred declining to bid. Please try again.',
                severity: 'error',
            });
        } finally {
            handleCloseClick(true);
            setIsLoading(false);
        }
    };

    return (
        <Dialog variant="skinny-pullout" open={open} onClose={handleCloseClick}>
            <div className="flex h-screen flex-col gap-y-6 px-4 pb-6 pt-2">
                <div className="border-b py-2 text-xl font-semibold leading-normal tracking-wide text-zinc-700">
                    Hauler Decline to Bid on this RFP
                </div>
                <div className="px-4 text-sm text-gray-600">
                    Please note once you mark that the hauler declined to bid on this proposal it cannot be undone. You'll have to create a
                    new RFP to resend it to them.
                </div>
                <Textarea
                    label="Reason for declining"
                    inputProps={{
                        value: declineMessage,
                        onChange: (e) => setDeclineMessage(e.target.value),
                    }}
                />
                <div className="mt-auto flex flex-col gap-y-4">
                    <Button className="btn-error-outlined w-full py-2 text-base" onClick={handleSubmitClick}>
                        Mark as Declined
                    </Button>
                    <Button className="btn-dark-grey-outlined w-full py-2 text-base" onClick={() => handleCloseClick()}>
                        Cancel
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default DeclineToBidDialog;
