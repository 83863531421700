import React from 'react';
import { Notification } from '@alliance-disposal/transport-types';
import { Dialog, Tooltip } from '@wayste/sour-ui';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';

type NotificationsDialogProps = {
    open: boolean;
    onClose: () => void;
    inAppNotifications: Notification.InAppNotification[];
    onMarkAsRead: (id: string) => void;
};

const NotificationsDialog = ({ open, onClose, inAppNotifications, onMarkAsRead }: NotificationsDialogProps) => {
    return (
        <Dialog styledTitle="Notifications" open={open} onClose={onClose} className="max-w-screen-xl" showX>
            <div className="flex max-h-[70vh]">
                <div className="w-full overflow-x-auto">
                    <table className="w-full border-collapse border-spacing-0 text-sm">
                        <thead>
                            <tr className="border-b border-gray-200 [&>*]:px-4 [&>*]:py-1.5 [&>*]:text-left [&>*]:font-normal">
                                <th>Date</th>
                                <th>Message</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {inAppNotifications.length > 0 ? (
                                inAppNotifications.map((notification) => (
                                    <tr className="border-b border-gray-200 [&>*]:px-4 [&>*]:py-1.5" key={notification.id}>
                                        <td className="whitespace-nowrap">
                                            {format(new Date(notification.deliveredAt), 'MM/dd/yy hh:mm a')}
                                        </td>
                                        <td>{notification.body}</td>
                                        <td>
                                            <div className="flex justify-end">
                                                <Tooltip text="Mark notification as read">
                                                    <button
                                                        type="button"
                                                        className="btn-icon-dense"
                                                        onClick={() => onMarkAsRead(notification.id)}
                                                    >
                                                        <CheckCircleIcon className="h-6 w-6 text-green-600" />
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={3} className="px-4 py-1.5 text-center">
                                        You have no new notifications! 🎉
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </Dialog>
    );
};

export default NotificationsDialog;
