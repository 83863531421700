import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, S3ItemReference } from '@alliance-disposal/transport-types';
import { ImageDialog } from '@wayste/sour-components';
import { useSourContext } from '@wayste/sour-context';
import { Button } from '@wayste/sour-ui';
import { PhotoIcon } from '@heroicons/react/24/solid';

const ImageDialogButton = ({ lead }: { lead: Customer.AllianceLeadTransport }) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [showImageDialog, setShowImageDialog] = useState(false);

    const handleSaveImages = async (images: S3ItemReference[]) => {
        try {
            await client.customer().adminPortal.leads.update(lead.id, { images });
        } catch (error) {
            console.warn(error);
            setShowToast({
                severity: 'error',
                message: 'Error saving images. Please try again.',
            });
        }
    };

    return (
        <>
            <Button
                className="btn-dark-grey-outlined"
                startIcon={<PhotoIcon className="size-5" />}
                onClick={() => setShowImageDialog(true)}
            >
                Images
            </Button>
            <ImageDialog
                open={showImageDialog}
                entityID={lead.id}
                imageReferences={lead.images}
                onClose={() => setShowImageDialog(false)}
                setImageReferences={handleSaveImages}
            />
        </>
    );
};

export default ImageDialogButton;
