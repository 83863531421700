import React from 'react';
import { moneyFormatter } from '@wayste/utils';
import { Menu, Transition } from '@headlessui/react';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';

const CustomerAccountCredit = ({ total }: { total: number }) => {
    return (
        <div>
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="btn-icon">
                        <CurrencyDollarIcon className={`size-6 ${total > 0 ? 'text-green-600' : ''}`} />
                    </Menu.Button>
                </div>
                <Transition
                    as={React.Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="shadow-dark absolute right-0 mt-2 w-fit origin-top-right whitespace-nowrap rounded bg-white focus:outline-none">
                        <div className="px-4 py-3.5">
                            <Menu.Item>
                                <span>Account Credit: {moneyFormatter(total)}</span>
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
};

export default CustomerAccountCredit;
