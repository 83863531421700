import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Order } from '@alliance-disposal/transport-types';
import { SocketError } from '@wayste/sour-socket';
import { Dialog, Loading } from '@wayste/sour-ui';
import ChangeRequestTable from './ChangeRequestTable';

interface OrderRequestDialogProps {
    open: boolean;
    onClose?: () => void;
}

const ChangeRequestDialog = ({ open, onClose }: OrderRequestDialogProps) => {
    const [requests, setRequests] = useState<Order.ChangeRequestTransport[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const client = useWaysteClient();

    useEffect(() => {
        if (open) {
            const sub = client.order().adminPortal.changeRequest.subscription.query({ status: ['PENDING'] });

            const observe = sub.receive.subscribe({
                next: async (value) => {
                    setRequests(value.results);
                    setLoading(false);
                },
                error: (error) => {
                    console.warn('error', error);
                    alert((error as SocketError).additionalInfo || 'An Error Occurred Getting Change Requests');
                    setLoading(false);
                },
            });

            return () => {
                if (sub) {
                    observe.unsubscribe();
                    sub.unsubscribe();
                }
            };
        }

        return () => {};
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose?.();
            }}
            showX
            styledTitle="Change Requests"
            className="max-w-screen-xl"
        >
            <div className="flex max-h-[50vh]">
                {loading ? (
                    <div className="flex items-center justify-center">
                        <Loading />
                    </div>
                ) : (
                    <ChangeRequestTable requests={requests} />
                )}
            </div>
            <div className="mt-2 flex justify-end">
                <button className="btn-primary" onClick={onClose}>
                    Done
                </button>
            </div>
        </Dialog>
    );
};

export default ChangeRequestDialog;
