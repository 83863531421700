import React, { useEffect, useState } from 'react';
import { Order } from '@alliance-disposal/transport-types';
import { CurrencyTextField, TextField } from '@wayste/sour-ui';
import { round } from '@wayste/utils';
import { differenceInCalendarDays } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { priceTypesEnums } from '../../../utils/shared-types';

interface CustomerInfoTableProps {
    order: Order.AllianceOrderTransport;
    watchActualWeightDumped?: number | '';
}

export const CustomerInfoTable = ({ order, watchActualWeightDumped }: CustomerInfoTableProps) => {
    const [showExtInputs, setShowExtInputs] = useState(false);
    const { setValue, register, watch } = useFormContext();

    const watchExtensionDays = watch('extensionDays');
    const watchRentExtensionFee = watch('rentExtensionFee');

    useEffect(() => {
        if (!order.rentalEndDate) return;
        const daysOver =
            differenceInCalendarDays(new Date(order.rentalEndDate), new Date(order.expectedDeliveryDate)) -
            order.adjustedRentalPeriod.value;
        if (daysOver > 0) {
            setShowExtInputs(true);
            setValue('extensionDays', daysOver);
            setValue('rentExtensionFee', order.rentExtensionFee);
        }
    }, [order.rentalEndDate, order.expectedDeliveryDate, order.adjustedRentalPeriod, order.rentExtensionFee]);

    return (
        <>
            <div className="w-full">
                <h6 className="text-xl font-medium">Info for Customer</h6>
            </div>
            {order.priceType === priceTypesEnums.ton &&
            watchActualWeightDumped &&
            order.weightLimit &&
            order.weightLimit?.value >= 0 &&
            +watchActualWeightDumped > +order.weightLimit.value ? (
                <div className="grid grid-cols-3 gap-4">
                    <div className="w-full">
                        <TextField
                            label={`Tons over ${order.weightLimit.value} ton limit`}
                            inputProps={{
                                value: round(+watchActualWeightDumped - +order.weightLimit.value),
                                onChange: () => null,
                                disabled: true,
                            }}
                        />
                    </div>
                    <div className="w-full">
                        <CurrencyTextField
                            label="Overage fee"
                            value={order?.overage || 0}
                            useCents
                            onChange={() => null}
                            inputProps={{
                                disabled: true,
                            }}
                        />
                    </div>
                    <div className="w-full">
                        <CurrencyTextField
                            label="Overage total"
                            value={round((+watchActualWeightDumped - +order.weightLimit.value) * (order.overage ? +order.overage : 0))}
                            useCents
                            onChange={() => null}
                            inputProps={{
                                disabled: true,
                            }}
                        />
                    </div>
                </div>
            ) : null}
            {showExtInputs ? (
                <div className="grid grid-cols-3 gap-4">
                    <div className="w-full">
                        <TextField
                            label="Rental extension days"
                            inputProps={{
                                ...register('extensionDays'),
                                disabled: true,
                            }}
                        />
                    </div>
                    <div className="w-full">
                        <CurrencyTextField
                            label="Extension daily fee"
                            value={watchRentExtensionFee}
                            useCents
                            onChange={() => null}
                            inputProps={{
                                disabled: true,
                            }}
                        />
                    </div>
                    <div className="w-full">
                        <CurrencyTextField
                            label="Extension fee total"
                            value={round(+watchExtensionDays * +watchRentExtensionFee)}
                            useCents
                            onChange={() => null}
                            inputProps={{
                                disabled: true,
                            }}
                        />
                    </div>
                </div>
            ) : null}
        </>
    );
};
