import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Dialog } from '@wayste/sour-ui';
import { VendorForm } from '../HaulerForm';

interface HaulerUpdateProps {
    open: boolean;
    onBackButtonClick: () => void;
    onSubmitSuccessful: () => void;
    hauler: Hauler.HaulerWithAapTransport;
}

const HaulerUpdate = ({ open, onBackButtonClick, onSubmitSuccessful, hauler }: HaulerUpdateProps) => {
    const waysteClient = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [showDelete, setShowDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleUpdate = async (data: Hauler.HaulerWithAapDataUpdate) => {
        setIsLoading(true);
        try {
            await waysteClient.vendorService().adminPortal.update(hauler.id, data);
            setShowToast({
                message: 'Hauler Successfully Updated',
                severity: 'success',
            });
            onSubmitSuccessful();
        } catch (error) {
            console.warn('Vendor update error', error);
            setShowToast({
                message: 'An Error Occurred',
                severity: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeactivate = async () => {
        setIsLoading(true);
        try {
            await waysteClient.vendorService().adminPortal.update(hauler.id, { active: false });
            setShowToast({
                message: 'Hauler Successfully Deactivated',
                severity: 'success',
            });
            setShowDelete(false);
            onSubmitSuccessful();
        } catch (error) {
            console.warn('error');
            setShowToast({
                message: 'An Error Occurred',
                severity: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleReactivate = async () => {
        setIsLoading(true);
        try {
            await waysteClient.vendorService().adminPortal.update(hauler.id, { active: true });
            setShowToast({
                message: 'Hauler Successfully Reactivated',
                severity: 'success',
            });
            onSubmitSuccessful();
        } catch (error) {
            console.warn('error');
            setShowToast({
                message: 'An Error Occurred',
                severity: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeactivateClicked = () => {
        setShowDelete(true);
    };

    return (
        <>
            <Dialog open={open} className="max-w-2xl" onClose={onBackButtonClick} styledTitle="Update Vendor">
                <VendorForm onSubmit={handleUpdate} hauler={hauler} />
                <div className="mt-6 flex justify-end gap-4">
                    {hauler &&
                        (hauler.active ? (
                            <Button
                                className="btn-delete mr-auto"
                                onClick={handleDeactivateClicked}
                                disabled={isLoading}
                                type="button"
                                loading={isLoading}
                            >
                                Deactivate Vendor
                            </Button>
                        ) : (
                            <Button
                                className="btn-primary-outlined mr-auto"
                                onClick={handleReactivate}
                                disabled={isLoading}
                                type="button"
                                loading={isLoading}
                            >
                                Re-Activate Vendor
                            </Button>
                        ))}
                    <button className="btn-dark-grey-outlined" onClick={onBackButtonClick} disabled={isLoading} type="button">
                        Cancel
                    </button>
                    <Button className="btn-primary" type="submit" loading={isLoading} form="VendorForm">
                        Update
                    </Button>
                </div>
            </Dialog>
            <Dialog
                open={showDelete}
                onClose={() => setShowDelete(false)}
                styledTitle="Deactivate Hauler"
                variant="delete"
                className="max-w-screen-sm"
            >
                <br />
                Are you sure you want to deactivate {<b>{hauler.name}</b>}? You will no longer be able to assign Orders to them.
                <br />
                <br />
                The hauler's data will not be deleted and you can reactivate them at any time.
                <div className="mt-4 flex justify-end gap-4">
                    <button className="btn-dark-grey-outlined" onClick={() => setShowDelete(false)} type="button" disabled={isLoading}>
                        Cancel
                    </button>
                    <Button className="btn-delete" onClick={handleDeactivate} type="button" loading={isLoading}>
                        Deactivate
                    </Button>
                </div>
            </Dialog>
        </>
    );
};

export default HaulerUpdate;
