///////////////////////////////////////////////////////
// INVOICE SUMMARY MODAL
//////////////////////////////////////////////////////
import React, { useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { SourFiltersOld, SourSearchOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { Button, Checkbox, Dialog } from '@wayste/sour-ui';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useAuthToken } from '../../../hooks/authhook';

const InvoiceSummaryModal = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
    ///////////////////////////////////////////////////////
    // STATE
    //////////////////////////////////////////////////////

    const { token } = useAuthToken();
    const [includeInvoices, setIncludeInvoices] = useState<boolean>(false);
    const [customerId, setCustomerId] = useState<string>();
    const [loading, setIsLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>();
    const [search, setSearch] = useState<string>('');

    // DEFINE ON NAVIGATE LOGIC
    const onNavigate = (entity: Customer.AllianceCustomerTransport, recordID: string) => {
        setSearch(entity?.contacts?.[0]?.firstName + ' ' + entity?.contacts?.[0]?.lastName);
        setCustomerId(recordID);
    };

    ////////////////////////////////////////////////////////
    // FUNCTION SECTION
    //////////////////////////////////////////////////////

    const fetchSummary = async (id: string, includeInvoices: boolean) => {
        const response = await fetch(
            `${import.meta.env.VITE_BASE_API_URL}/pdf/admin-portal/invoice-summary`,
            //`http://localhost:3000/pdf/admin-portal/invoice-summary`,
            {
                method: 'POST',
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customerID: id,
                    includeInvoices: includeInvoices,
                }),
            },
        );
        const blob = await response.blob();

        if (blob) {
            const downloadUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = includeInvoices ? 'invoice-summary.zip' : 'invoice-summary.pdf';
            document.body.appendChild(a);
            a.click();
        }

        setIsLoading(false);
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setMessage(undefined);
        if (!customerId) return;

        try {
            setIsLoading(true);
            await fetchSummary(customerId, includeInvoices);
        } catch (error) {
            alert('An error has occurred, please try again or contact dev.');
            setMessage('An error has occurred, please try again or contact dev.');
        }
        setIsLoading(false);
    };

    ///////////////////////////////////////////////////////
    // RENDER SECTION
    //////////////////////////////////////////////////////
    return (
        <Dialog
            className="w-[100%] overflow-visible"
            styledTitle="Download Outstanding Invoices Summary"
            open={open}
            onClose={() => handleClose()}
        >
            <form onSubmit={onSubmit} className="flex h-[90%] flex-col justify-between gap-2 pt-2">
                <div className="py-2">
                    {search ? (
                        <div className="flex h-6 flex-row items-center justify-between p-1">
                            <div>{search}</div>
                            <Button
                                onClick={() => {
                                    setSearch('');
                                }}
                            >
                                <XMarkIcon className="h-4 w-4" />
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <SourSearchWrapperOld
                                onNavigate={onNavigate}
                                highlight={true}
                                defaultFilters={{
                                    query: {
                                        type: 'sourgum-customer',
                                    },
                                }}
                                size={10}
                                options={{
                                    application: 'aap',
                                    apiKey: import.meta.env.VITE_ELASTIC_KEY,
                                    environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                                }}
                            >
                                <div className="flex w-full flex-row justify-between space-x-4">
                                    <SourSearchOld
                                        options={{
                                            searchPopoverFixed: true,
                                            showTips: false,
                                            showMessages: false,
                                            resultSize: 'w-[700px]',
                                            searchPositionFixed: false,
                                            placeholder: 'Search Customer',
                                        }}
                                    />
                                    <div className="hidden">
                                        <SourFiltersOld />
                                    </div>
                                </div>
                            </SourSearchWrapperOld>
                        </div>
                    )}
                </div>

                <div>
                    <div>
                        <Checkbox
                            label="Include Individual Invoices"
                            inputProps={{
                                checked: includeInvoices,
                                onChange: (e) => {
                                    setIncludeInvoices(e.currentTarget.checked);
                                },
                            }}
                        />
                    </div>
                    <br />
                    <div>{message}</div>
                    <div>
                        <Button className="btn-primary w-full" type="submit" loading={loading}>
                            Download
                        </Button>
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export default InvoiceSummaryModal;
