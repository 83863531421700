import { useContext, useState } from 'react';
import { UniversalService } from '@alliance-disposal/transport-types';
import { DataGrid, DataGridProps, Loading } from '@wayste/sour-ui';
import { formatISODateString, formatServiceAddress } from '@wayste/utils';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../utils';
import { BillingContext, type ServiceGroupingExtendedServiceOrders } from '../context';
import { GridCellStatus } from './renderCellReceivablesStatus';

const ServiceGroupingInvoiceList = () => {
    const history = useHistory();
    const { selectedServiceGrouping, isLoadingOrder } = useContext(BillingContext);
    const [page, setPage] = useState(0);

    const OrderStatusBadge = ({ status }: { status: UniversalService.ServiceOrderStatus }) => {
        switch (status) {
            case 'UNASSIGNED':
                return (
                    <div className="bg-statuses-UNASSIGNED-primary text-statuses-UNASSIGNED-text flex h-6 min-w-[125px] flex-row items-center justify-center whitespace-nowrap  rounded">
                        <span className="min-w-[125px] text-center"> Unassigned </span>
                    </div>
                );
            case 'ASSIGNED':
                return (
                    <div className="bg-statuses-ASSIGNED-primary text-statuses-ASSIGNED-text flex h-6 min-w-[125px] flex-row items-center justify-center whitespace-nowrap  rounded">
                        <span className="min-w-[125px] text-center"> Assigned </span>
                    </div>
                );
            case 'ON_HOLD':
                return (
                    <div className="bg-statuses-ON_HOLD-primary text-statuses-ON_HOLD-text flex h-6 min-w-[125px] flex-row items-center justify-center whitespace-nowrap  rounded">
                        <span className="min-w-[125px] text-center"> On Hold </span>
                    </div>
                );
            case 'DELIVERED':
                return (
                    <div className="bg-statuses-DELIVERED-primary text-statuses-DELIVERED-text flex h-6 min-w-[125px] flex-row items-center justify-center whitespace-nowrap  rounded">
                        <span className="min-w-[125px] text-center"> Delivered </span>
                    </div>
                );
            case 'READY_FULL_PICKUP':
                return (
                    <div className="bg-statuses-READY_FULL_PICKUP-primary text-statuses-READY_FULL_PICKUP-text flex h-6 min-w-[125px] flex-row items-center justify-center whitespace-nowrap  rounded">
                        <span className="min-w-[125px] text-center"> Ready Full Pickup </span>
                    </div>
                );
            case 'READY_PICKUP':
                return (
                    <div className="bg-statuses-READY_PICKUP-primary text-statuses-READY_PICKUP-text flex h-6 min-w-[125px] flex-row items-center justify-center whitespace-nowrap  rounded">
                        <span className="min-w-[125px] text-center"> Ready Pickup </span>
                    </div>
                );
            case 'PICKED_UP_FULL':
                return (
                    <div className="bg-statuses-PICKED_UP_FULL-primary text-statuses-PICKED_UP_FULL-text flex h-6 min-w-[125px] flex-row items-center justify-center whitespace-nowrap  rounded">
                        <span className="min-w-[125px] text-center"> Picked Up Full </span>
                    </div>
                );
            case 'PICKED_UP_EMPTY':
                return (
                    <div className="bg-statuses-PICKED_UP_EMPTY-primary text-statuses-PICKED_UP_EMPTY-text flex h-6 min-w-[125px] flex-row items-center justify-center whitespace-nowrap  rounded">
                        <span className="min-w-[125px] text-center"> Picked Up Empty </span>
                    </div>
                );
            case 'PICKED_UP':
                return (
                    <div className="bg-statuses-PICKED_UP-primary text-statuses-PICKED_UP-text flex h-6 min-w-[125px] flex-row items-center justify-center whitespace-nowrap  rounded">
                        <span className="min-w-[125px] text-center"> Picked Up </span>
                    </div>
                );
            case 'DUMPED':
                return (
                    <div className="bg-statuses-DUMPED-primary text-statuses-DUMPED-text flex h-6 min-w-[125px] flex-row items-center justify-center whitespace-nowrap  rounded">
                        <span className="min-w-[125px] text-center"> Dumped </span>
                    </div>
                );
            case 'ON_SITE':
                return (
                    <div className="bg-statuses-ON_SITE-primary text-statuses-ON_SITE-text flex h-6 min-w-[125px] flex-row items-center justify-center whitespace-nowrap  rounded">
                        <span> On Site </span>
                    </div>
                );
            case 'IN_PROGRESS':
                return (
                    <div className="bg-statuses-IN_PROGRESS-primary text-statuses-IN_PROGRESS-text flex h-6 min-w-[125px] flex-row items-center justify-center whitespace-nowrap  rounded">
                        <span className="min-w-[125px] text-center"> In Progress </span>
                    </div>
                );
            case 'COMPLETED':
                return (
                    <div className="bg-statuses-COMPLETED-primary text-statuses-COMPLETED-text flex h-6 min-w-[125px] flex-row items-center justify-center whitespace-nowrap  rounded">
                        <span className="min-w-[125px] text-center"> Completed </span>
                    </div>
                );

            default:
                return <span className="min-w-[125px] text-center">{'status not found'}</span>;
        }
    };

    const columns: DataGridProps<ServiceGroupingExtendedServiceOrders['serviceOrders'][0]>['columns'] = [
        {
            key: 'fullOrderNumber',
            name: 'Order #',
            align: 'center',
            width: 120,
        },
        {
            key: 'invoiceNumbers',
            name: 'Invoice #',
            align: 'center',
            width: 120,
            formatter: (value) => (
                <>
                    {value.row.receivables
                        .map((r) => `${selectedServiceGrouping?.orderNumber}-${r.invoiceDetails.invoiceNumber}`)
                        .join(', ')}
                </>
            ),
        },
        {
            key: 'status',
            name: 'Status',
            align: 'center',
            headerAlign: 'center',
            formatter: (value) => <OrderStatusBadge status={value.row.status} />,
            width: 130,
        },
        {
            key: 'receivablesStatus',
            name: 'Receivables',
            align: 'center',
            headerAlign: 'center',
            formatter: (value) => <GridCellStatus value={value.row.receivablesStatus} />,
            width: 110,
        },
        {
            key: 'payablesStatus',
            name: 'Payables',
            align: 'center',
            headerAlign: 'center',
            formatter: (value) => <GridCellStatus value={value.row.payablesStatus} />,
            width: 110,
        },
        {
            key: 'customerName',
            name: 'Customer',
            width: 200,
            formatter: (value) => <>{value.row.customerCompanyName || value.row.customerName}</>,
        },
        {
            key: 'serviceLocation',
            name: 'Address',
            width: 300,
            formatter: (value) => <>{formatServiceAddress(value.row.serviceLocation?.address)}</>,
        },
        {
            key: 'vendorName',
            name: 'Vendor',
            width: 200,
        },
        {
            key: 'servicePeriod',
            name: 'Service Period',
            align: 'center',
            headerAlign: 'center',
            formatter: (value) => (
                <>
                    {formatISODateString(value.row.startDate)} - {formatISODateString(value.row.endDate)}
                </>
            ),
            width: 150,
        },
        {
            key: 'sku',
            name: 'SKUS',
            align: 'center',
            headerAlign: 'center',
            formatter: (value) => (
                <>
                    {value.row.serviceType.family.shortCode} - {value.row.serviceType.name}
                </>
            ),
        },
    ];

    return (
        <div className="flex h-full max-h-screen flex-1 overflow-y-hidden">
            {isLoadingOrder && <Loading fullScreen />}
            <div className="relative inset-0 flex w-full flex-grow flex-col overflow-auto bg-gray-100">
                <div className="rounded-br-4 rounded-bl-4 sticky top-0 z-10 flex items-center justify-between bg-white px-5 py-1 shadow-md">
                    Service Grouping Invoices
                    <ArrowTopRightOnSquareIcon
                        className="text-secondary-400 h-5 w-5 cursor-pointer"
                        onClick={() => {
                            if (selectedServiceGrouping?.id) {
                                history.push(routes.universalServices.serviceGrouping.details(selectedServiceGrouping.id));
                            }
                        }}
                    />
                </div>
                <div className="flex flex-grow flex-col bg-white p-2 pb-5">
                    <DataGrid
                        rows={selectedServiceGrouping?.serviceOrders || []}
                        loading={isLoadingOrder}
                        columns={columns}
                        rowHeight={50}
                        onRowClick={(row, column) => {
                            if (column.key === 'status') {
                                return;
                            }
                            history.push({
                                pathname: routes.billingProduct.list,
                                search: qs.stringify({
                                    serviceGroupingID: row.serviceGroupingID,
                                    serviceOrderID: row.id,
                                }),
                            });
                        }}
                        pagination={{
                            itemCount: selectedServiceGrouping?.serviceOrders.length || 0,
                            page: page,
                            rowsPerPage: 25,
                            rowsPerPageOptions: [25],
                            onPageChange: (newPage) => {
                                setPage(newPage);
                            },
                            onRowsPerPageChange: () => {},
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ServiceGroupingInvoiceList;
