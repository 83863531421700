import React, { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import { routes } from '../../utils';
import ContactedSubForm from '../LeadForms/ContactedSubForm';
import MarketingForm from '../LeadForms/MarketingSubForm';
import USLeadSubForm from '../LeadForms/USLeadSubForm';
import USQuoteSubForm from '../LeadForms/USQuoteSubForm';
import {
    MarketingSubFormType,
    USLeadFormType,
    USQuoteFormType,
    prepareMarketingSubForm,
    prepareUSLeadSubForm,
    prepareUSQuoteSubForm,
} from '../LeadForms/utils/prepare-lead-form';

type USLeadCreateType = USLeadFormType & USQuoteFormType & MarketingSubFormType & { medium: string; date: string };

const USLeadCreate = ({
    leadType,
    startingValues,
}: {
    leadType: Customer.AllianceLeadTransport['type'];
    startingValues?: { phoneNumber?: string };
}) => {
    const client = useWaysteClient();
    const history = useHistory();
    const { showFlash } = useContext(UIContext);
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<USLeadCreateType>({
        mode: 'all',
        defaultValues: {
            medium: 'phone',
            date: new Date().toISOString(),
            ...prepareUSLeadSubForm(),
            ...prepareUSQuoteSubForm(),
            ...prepareMarketingSubForm(),
            phoneNumber: startingValues?.phoneNumber || '',
        },
    });

    const {
        handleSubmit,
        formState: { isDirty, isValid },
    } = methods;

    const submitForm = async (data: USLeadCreateType) => {
        setIsLoading(true);
        const leadSubmitData: Customer.AllianceLeadCreateTransport = {
            ...data,
            type: leadType,
            status: 'open',
            needsAttention: false,
            customerType: data.customerType || 'unknown',
        };

        const quotes: Customer.AllianceLeadQuoteCreateTransport[] = data?.quotes ? [...data.quotes] : [];

        try {
            const response = await client.customer().adminPortal.leads.create(leadSubmitData);

            const leadID = response?.id;
            for (const quote of quotes) {
                try {
                    await client.customer().adminPortal.leads.quote.create(leadID, {
                        ...quote,
                        size: { size: 0, type: '' },
                        material: '',
                        cc: false,
                    });
                } catch (error) {
                    showFlash("Quote Didn't Save", 'warning');
                    if (leadID) history.push(routes.leads.details(leadID));
                    throw error;
                }
            }

            try {
                await client.customer().adminPortal.leads.contactEvent.create(leadID, {
                    medium: data.medium,
                    date: data.date,
                });
            } catch (error) {
                showFlash("Contact Event Didn't Save", 'warning');
                if (leadID) history.push(routes.leads.details(leadID));
                throw error;
            }

            if (data.notes) {
                await client.internalTicket().adminPortal.create({
                    entityType: 'sourgum-lead',
                    entityID: leadID,
                    note: data.notes,
                    tags: [],
                });
            }

            showFlash('Lead Successfully Created', 'success');

            history.push(routes.leads.details(leadID));
        } catch (error) {
            showFlash('An Error Occurred', 'warning');
            console.log('error creating lead: ', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submitForm)} className="flex flex-col gap-4">
                <div className="shadow-dark rounded bg-white p-3">
                    <h6 className="mb-3 text-xl">Lead Details</h6>
                    <USLeadSubForm />
                </div>
                <div className="shadow-dark rounded bg-white p-3">
                    <h6 className="mb-3 text-xl">Add New Quote</h6>
                    <USQuoteSubForm index={0} />
                </div>
                <div className="shadow-dark rounded bg-white p-3">
                    <h6 className="mb-3 text-xl">Contact Details</h6>
                    <ContactedSubForm />
                </div>
                <div className="shadow-dark rounded bg-white p-3">
                    <h6 className="mb-3 text-xl">Marketing Details</h6>
                    <MarketingForm />
                </div>
                <div className="flex justify-end gap-4">
                    <Button className="btn-dark-grey-outlined" disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button type="submit" className="btn-primary" loading={isLoading} disabled={!isDirty || !isValid}>
                        Save
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default USLeadCreate;
