import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Dialog } from '@wayste/sour-ui';
import { PencilIcon } from '@heroicons/react/24/solid';
import { FormProvider, useForm } from 'react-hook-form';
import { channelTypes } from '../../utils/shared-types';
import CardInfoList from '../CardInfoList';
import MarketingSubForm from '../LeadForms/MarketingSubForm';

export interface MarketingDetailsProps {
    lead: Customer.AllianceLeadTransport;
}

const MarketingDetails = ({ lead }: MarketingDetailsProps) => {
    const [open, setOpen] = useState(false);

    const marketingCol = [
        { label: 'Form URL', value: lead.formUrl },
        { label: 'Referring URL', value: lead.referringUrl },
        { label: 'Channel', value: channelTypes[lead.channel as keyof typeof channelTypes] },
        {
            label: 'Customer Type',
            value: Customer.CustomerTypeLabels[lead.customerType as keyof typeof Customer.CustomerTypeLabels],
        },
        { label: 'Search Params', value: lead.searchParams },
        { label: 'Account Executive', value: lead.accountRepName },
    ];

    return (
        <div className="shadow-dark rounded-md bg-white px-4 py-1.5">
            <div className="flex items-center justify-between">
                <div className="text-xl">Marketing Details</div>
                <button className="btn-primary" onClick={() => setOpen(true)}>
                    <PencilIcon className="mr-1.5 size-4" />
                    Edit
                </button>
            </div>
            <hr className="-mx-4 my-2" />
            <div className="grid grid-cols-1">
                <CardInfoList data={marketingCol} containerSize={6} />
            </div>
            <MarketingDetailsUpdate lead={lead} open={open} onClose={() => setOpen(false)} />
        </div>
    );
};

export default MarketingDetails;

interface MarketingDetailsUpdateFormProps {
    channel: Customer.AllianceLeadUpdateTransport['channel'];
    customerType: Customer.AllianceLeadUpdateTransport['customerType'];
    formUrl: Customer.AllianceLeadUpdateTransport['formUrl'];
    searchParams: Customer.AllianceLeadUpdateTransport['searchParams'];
}

const MarketingDetailsUpdate = ({ lead, open, onClose }: { lead: Customer.AllianceLeadTransport; open: boolean; onClose: () => void }) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<MarketingDetailsUpdateFormProps>({
        defaultValues: {
            channel: lead.channel,
            customerType: lead.customerType,
            formUrl: lead.formUrl,
            searchParams: lead.searchParams,
        },
    });
    const { handleSubmit } = methods;

    const submitForm = async (data: MarketingDetailsUpdateFormProps) => {
        setIsLoading(true);
        try {
            await client.customer().adminPortal.leads.update(lead.id, data);
            setShowToast({
                severity: 'success',
                message: 'Lead Successfully Updated',
            });
            onClose();
        } catch (error) {
            console.warn(error);
            setShowToast({
                severity: 'error',
                message: 'An Error Occurred Updating Lead',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} styledTitle="Update Marketing Details" className="max-w-4xl">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <MarketingSubForm />
                    <div className="mt-4 flex justify-end gap-4">
                        <Button className="btn-dark-grey-outlined" onClick={onClose} disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button type="submit" className="btn-primary" loading={isLoading}>
                            Save
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Dialog>
    );
};
