import { useEffect, useState } from 'react';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { formatISODateString } from '@wayste/utils';
import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/24/solid';
import { addDays, isBefore, isSameDay, startOfDay, subDays } from 'date-fns';
import { differenceInCalendarDays } from 'date-fns';
import { GridCellStatus } from '../../pages/billing';
import { OrderStatus, paymentTermsEnums } from '../../utils';
import OrderStatusChanger from '../OrderStatusChanger';

type Props = {
    order: Order.AllianceOrderTransport & {
        receivables: Invoice.ReceivableTransport[];
        payables: Invoice.PayableTransport[];
    };
    usingSearchData: boolean;
    onOrderRowClicked: (
        order: Order.AllianceOrderTransport & {
            receivables: Invoice.ReceivableTransport[];
            payables: Invoice.PayableTransport[];
        },
    ) => void;
};

const OrderRow = ({ order, onOrderRowClicked }: Props) => {
    const getPickupDateStyle = () => {
        if (!order.expectedPickupDate) return {};
        if (
            isSameDay(addDays(new Date(order.expectedPickupDate), 14), new Date()) ||
            isBefore(addDays(new Date(order.expectedPickupDate), 14), startOfDay(new Date()))
        ) {
            return { backgroundColor: '#E74C3C', color: '#FFFFFF' };
        }
        if (
            isSameDay(addDays(new Date(order.expectedPickupDate), 7), new Date()) ||
            isBefore(addDays(new Date(order.expectedPickupDate), 7), startOfDay(new Date()))
        ) {
            return { backgroundColor: '#F0810F', color: '#FFFFFF' };
        }
        if (!order.haulerConfirmedPickup) {
            return { backgroundColor: '#F0810F', color: '#FFFFFF' };
        }
        return {};
    };

    const getExpiresDateStyle = () => {
        if (order.status !== OrderStatus.DELIVERED) return {};
        if (order.extendingRental) {
            return { backgroundColor: '#B19CD9', color: '#FFFFFF' };
        }
        if (
            isSameDay(new Date(addDays(new Date(order.expectedDeliveryDate), order.adjustedRentalPeriod.value)), new Date()) ||
            isBefore(new Date(addDays(new Date(order.expectedDeliveryDate), order.adjustedRentalPeriod.value)), startOfDay(new Date()))
        ) {
            return { backgroundColor: '#E74C3C', color: '#FFFFFF' };
        }

        if (
            isSameDay(addDays(new Date(order.expectedDeliveryDate), order.adjustedRentalPeriod.value - 2), new Date()) ||
            isSameDay(addDays(new Date(order.expectedDeliveryDate), order.adjustedRentalPeriod.value - 1), new Date()) ||
            (new Date().getDay() === 5 &&
                isSameDay(addDays(new Date(order.expectedDeliveryDate), order.adjustedRentalPeriod.value), addDays(new Date(), 3)))
        ) {
            return { backgroundColor: '#F0810F', color: '#FFFFFF' };
        }
        return {};
    };

    const getDeliveryDateStyle = () => {
        if (order.status === OrderStatus.UNASSIGNED) {
            if (
                isSameDay(new Date(order.expectedDeliveryDate), new Date()) ||
                isBefore(new Date(order.expectedDeliveryDate), startOfDay(new Date()))
            ) {
                return { backgroundColor: '#E74C3C', color: '#FFFFFF' };
            } else if (isSameDay(subDays(new Date(order.expectedDeliveryDate), 1), new Date())) {
                return { backgroundColor: '#F0810F', color: '#FFFFFF' };
            }
            return null;
        } else if (order.status === OrderStatus.ASSIGNED) {
            if (isBefore(new Date(order.expectedDeliveryDate), startOfDay(new Date()))) {
                return { backgroundColor: '#E74C3C', color: '#FFFFFF' };
            } else if (!order.haulerConfirmedDelivery) {
                return { backgroundColor: '#F0810F', color: '#FFFFFF' };
            }
            return {};
        }
        return {};
    };

    const getDaysAgo = (order: Order.AllianceOrderTransport) => {
        return differenceInCalendarDays(new Date(order.expectedDeliveryDate), new Date());
    };

    const [orderState, setOrderState] = useState<Order.AllianceOrderTransport>(order);
    useEffect(() => {
        setOrderState(order);
    }, [order]);

    return (
        <tr
            className="cursor-pointer text-sm hover:bg-gray-100 [&>*]:border-b [&>*]:p-4"
            key={order.id}
            onClick={() => onOrderRowClicked(order)}
        >
            {/* Status Popper */}
            <td onClick={(event) => event.stopPropagation()} className="cursor-default bg-white">
                <OrderStatusChanger order={orderState} />
            </td>
            {/* Delivery Date */}

            <td style={{ ...getDeliveryDateStyle(), position: 'relative' }}>
                {formatISODateString(order.expectedDeliveryDate)} <br />
                {order.expectedDeliveryDate && (
                    <span className="text-xs">{new Intl.RelativeTimeFormat('en').format(getDaysAgo(order) + 1, 'days')}</span>
                )}
                {order.receivables &&
                    order.paymentTerm === paymentTermsEnums.onCharge &&
                    !order.receivables?.every((item) => item?.invoiceDetails?.paidInFull) && (
                        <div className="absolute right-3 top-3 flex size-6 items-center justify-center rounded-full bg-white">
                            <div className="absolute top-3 h-0.5 w-4 rotate-45 bg-red-500" />
                            <CurrencyDollarIcon className="size-6 text-red-500" />
                        </div>
                    )}
                {order.receivables && order.receivables?.some((item) => item?.invoiceDetails?.status === 'DRAFT') && (
                    <div className="absolute right-3 top-9 flex size-6 items-center justify-center rounded-full bg-white">
                        <GridCellStatus value="DRAFT" />
                    </div>
                )}
                {order.needsAttention ? (
                    <div className="absolute -left-2 top-1 flex size-6 items-center justify-center rounded-full bg-[#72EFFF]">
                        <StarIcon className="size-4 text-white" />
                    </div>
                ) : null}
            </td>

            {/* Pick Up Date */}
            <td style={getPickupDateStyle()}>{formatISODateString(order.expectedPickupDate)}</td>
            {/* Expiration Date */}
            <td style={getExpiresDateStyle()}>{formatISODateString(order.expirationDate)}</td>
            {/* Service Address */}
            <td className="break-words">
                <span className="whitespace-nowrap">
                    {order.serviceLocation.address.addressLine1}
                    {order.serviceLocation.address.addressLine2 ? ` ${order.serviceLocation.address.addressLine2}` : null},
                </span>
                <span className="whitespace-nowrap">{` ${order.serviceLocation.address.city}, `}</span>
                <span className="whitespace-nowrap">
                    {order.serviceLocation.address.state} {order.serviceLocation.address.zip}
                </span>
            </td>
            {/* Order Number */}
            <td>{order.orderNumber}</td>
            {/* Customer Name */}

            <td>{order?.customerCompanyName ? order?.customerCompanyName : order?.customerName}</td>

            {/* Order Details */}
            <td>
                <div>{order?.vendorName}</div>
                <span className="whitespace-nowrap">
                    <>
                        {order.expectedSize.size} YD {order.material}
                    </>
                </span>
            </td>
        </tr>
    );
};

export default OrderRow;
