import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { useSourContext } from '@wayste/sour-context';
import { Button, Dialog, TextField, phoneNumberMasker } from '@wayste/sour-ui';
import { formatE164ToUSPhoneNumber, formatUSPhoneNumberToE164 } from '@wayste/utils';
import { Controller, useForm } from 'react-hook-form';

type CreateUserModalProps = {
    isOpen: boolean;
    onClose: (submitted: boolean) => void;
};

type CreateUserModalForm = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
};

const defaultUserFormValues: CreateUserModalForm = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
};

const CreateUserModal = ({ isOpen, onClose }: CreateUserModalProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const {
        control,
        formState: { isValid, isSubmitting },
        handleSubmit,
        setValue,
        reset,
    } = useForm<CreateUserModalForm>({
        mode: 'all',
        defaultValues: defaultUserFormValues,
    });

    const onSubmit = async (values: CreateUserModalForm) => {
        setIsLoading(true);
        try {
            await client.profile().adminPortal.create({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phoneNumber: values.phoneNumber,
            });
            setShowToast({
                message: 'User created successfully',
                severity: 'success',
            });
            onClose(true);
        } catch (error) {
            console.error(error);
            setShowToast({
                message: 'Failed to create user',
                severity: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        reset(defaultUserFormValues);
    }, [isOpen]);

    return (
        <Dialog open={isOpen} onClose={() => onClose(false)} styledTitle="Add User" disableCloseOnClickOutside>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-row space-x-2">
                        <Controller
                            control={control}
                            name="firstName"
                            rules={{
                                required: 'First Name is required',
                            }}
                            render={({ field, fieldState }) => (
                                <TextField required label="First Name" error={fieldState.error} inputProps={{ ...field }} />
                            )}
                        />
                        <Controller
                            control={control}
                            name="lastName"
                            rules={{
                                required: 'Last Name is required',
                            }}
                            render={({ field, fieldState }) => (
                                <TextField required label="Last Name" error={fieldState.error} inputProps={{ ...field }} />
                            )}
                        />
                    </div>
                    <div className="flex flex-row space-x-2">
                        <Controller
                            control={control}
                            name={`email`}
                            rules={{
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email address',
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <TextField type={'string'} label="Email" required error={fieldState.error} inputProps={{ ...field }} />
                            )}
                        />
                        <Controller
                            control={control}
                            name="phoneNumber"
                            render={({ field, fieldState }) => (
                                <TextField
                                    startAdornment="+1"
                                    label="Phone Number"
                                    error={fieldState.error}
                                    inputProps={{
                                        ...field,
                                        value: formatE164ToUSPhoneNumber(field.value || ''),
                                        onChange: (e) => {
                                            setValue(`phoneNumber`, formatUSPhoneNumberToE164(phoneNumberMasker(e.target.value)));
                                        },
                                    }}
                                    type="tel"
                                />
                            )}
                        />
                    </div>
                    <div className="flex items-center justify-end gap-4">
                        <Button className="btn-base text-red-600" onClick={() => onClose(false)}>
                            Cancel
                        </Button>
                        <Button type="submit" className="btn-primary" disabled={!isValid || isSubmitting} loading={isLoading}>
                            Create User
                        </Button>
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export default CreateUserModal;
