import { Duration, DurationUnitLabels, UniversalService } from '@alliance-disposal/transport-types';
import { Button, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import type { RFPFormFromProps, SkuFormProps } from './rfp-form';
import RFPSkuExtraSubForm from './rfp-sku-extra-sub-form';

const blankSku: SkuFormProps = {
    serviceTypeID: '',
    quantity: 1,
    serviceFrequency: { value: 1, unit: 'WEEKS' },
    skuExtras: [],
    note: '',
    serviceCycleLength: 1,
};

const RFPSkuSubForm = ({
    serviceTypeOptions,
    serviceLineItemTypeOptions,
}: {
    serviceTypeOptions: UniversalService.ServiceType[];
    serviceLineItemTypeOptions: UniversalService.ServiceLineItemType[];
}) => {
    const { control, watch, setValue } = useFormContext<RFPFormFromProps>();

    const { fields, append, remove } = useFieldArray({
        name: `skus`,
        control,
    });

    const watchServiceFamilyID = watch('serviceFamilyID');
    const watchRecurring = watch('recurring');
    const watchSkus = watch('skus');

    const handleAddSku = () => {
        append({ ...blankSku });
    };

    const handleRemoveSku = (index: number) => {
        remove(index);
    };

    const handleFrequencyUnitChange = (index: number, value: Duration & 'EOW') => {
        if (value === 'EOW') {
            setValue(`skus.${index}.serviceFrequency.unit`, 'WEEKS');
            setValue(`skus.${index}.serviceFrequency.value`, 1);
            setValue(`skus.${index}.serviceCycleLength`, 2);
        } else {
            setValue(`skus.${index}.serviceFrequency.unit`, value);
            setValue(`skus.${index}.serviceCycleLength`, 1);
        }
    };

    return (
        <div>
            {fields.map((fieldItem, index) => (
                <div className="grid grid-cols-1 gap-y-6 rounded p-2 even:bg-slate-50">
                    <div className="flex items-center justify-between">
                        <div>Sku {index + 1}</div>
                        <Button className="btn-icon" onClick={() => handleRemoveSku(index)}>
                            <TrashIcon className="size-6 text-red-500" />
                        </Button>
                    </div>
                    <div>
                        <Controller
                            name={`skus.${index}.serviceTypeID`}
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'A service type is required',
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <Select
                                    required
                                    error={fieldState.error}
                                    value={field.value}
                                    onSelect={(value) => field.onChange(value)}
                                    label="Service sku"
                                    disabled={!watchServiceFamilyID}
                                >
                                    {serviceTypeOptions
                                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                                        .map((item) => (
                                            <SelectOption key={item.id} value={item.id}>
                                                {item.name}
                                            </SelectOption>
                                        ))}
                                </Select>
                            )}
                        />
                    </div>
                    <div>
                        <Controller
                            key={fieldItem.id + index + 'quantity'}
                            control={control}
                            name={`skus.${index}.quantity`}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Quantity is required',
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    type="number"
                                    label="Quantity"
                                    error={fieldState.error}
                                    required
                                    inputProps={{
                                        ...field,
                                    }}
                                />
                            )}
                        />
                    </div>
                    {watchRecurring && (
                        <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2">
                            <div>
                                <Controller
                                    key={fieldItem.id + index + 'serviceFrequency.value'}
                                    control={control}
                                    name={`skus.${index}.serviceFrequency.value`}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Service frequency is required',
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            type="number"
                                            label="Service Frequency Value"
                                            error={fieldState.error}
                                            required
                                            inputProps={{
                                                ...field,
                                                disabled: watchSkus[index].serviceCycleLength !== 1,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    key={fieldItem.id + index + 'serviceFrequency.unit'}
                                    control={control}
                                    name={`skus.${index}.serviceFrequency.unit`}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Service frequency unit is required',
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Select
                                            required
                                            error={fieldState.error}
                                            value={watchSkus[index].serviceCycleLength === 2 ? 'EOW' : field.value}
                                            onSelect={(value) => handleFrequencyUnitChange(index, value as Duration & 'EOW')}
                                            label="Service Frequency Unit"
                                        >
                                            {Object.entries({ ...DurationUnitLabels, EOW: { singular: 'Every Other Week' } }).map(
                                                ([key, value]) => (
                                                    <SelectOption key={key} value={key}>
                                                        {value.singular}
                                                    </SelectOption>
                                                ),
                                            )}
                                        </Select>
                                    )}
                                />
                            </div>
                        </div>
                    )}
                    <div>
                        <Controller
                            key={fieldItem.id + index + 'note'}
                            control={control}
                            name={`skus.${index}.note`}
                            render={({ field, fieldState }) => (
                                <TextField
                                    label="Sku note"
                                    error={fieldState.error}
                                    inputProps={{
                                        ...field,
                                    }}
                                />
                            )}
                        />
                    </div>
                    <RFPSkuExtraSubForm serviceLineItemTypeOptions={serviceLineItemTypeOptions} skuIndex={index} />
                </div>
            ))}
            <div className="flex flex-row items-center justify-center">
                <hr className="w-full" />
                <div className="flex flex-row items-center justify-center space-x-2 px-2">
                    <Button className="btn-primary-text-only whitespace-nowrap" onClick={handleAddSku} startIcon={<PlusCircleIcon />}>
                        Add Sku
                    </Button>
                </div>
                <hr className="w-full" />
            </div>
        </div>
    );
};

export default RFPSkuSubForm;
