import React from 'react';
import { Pricing } from '@alliance-disposal/transport-types';
import PricingForm from '../PricingForm';

/**
 * Component to Create pricing for a county
 * @param {Function} onBackButtonClick Function on modal close
 * @param {Boolean} open Controls if modal is visible
 * @param {Function} onSubmit Function on create
 * @param {Boolean} isLoading Replaces submit and cancel with a loading indicator
 */

export interface PricingCreateProps {
    onBackButtonClick: () => void;
    onSubmit: (data: Pricing.PricingCreateTransport, pricingZones?: Pricing.PricingTransport[], copyExistingZone?: string) => void;
    isLoading: boolean;
}

const PricingCreate = ({ onBackButtonClick, onSubmit, isLoading }: PricingCreateProps) => {
    return (
        <div className="mx-auto max-w-screen-lg bg-white">
            <div className="bg-sourgum-greyblue-900 px-6 py-4 text-white">
                <h6 className="text-xl">Create Zone Pricing</h6>
            </div>
            <PricingForm mode="CREATE" onSubmit={onSubmit} onCancel={onBackButtonClick} isLoading={isLoading} />
        </div>
    );
};

export default PricingCreate;
