import { useContext, useEffect, useState } from 'react';
import { Invoice, UniversalService } from '@alliance-disposal/transport-types';
import { CurrencyTextField, Dialog, RadioButton, Select, SelectOption, TextField, Toggle } from '@wayste/sour-ui';
import { moneyFormatter, pricingBreakdownTotal, round } from '@wayste/utils';
import { Controller, useForm } from 'react-hook-form';
import { ccRate as defaultCCRate } from '../../../../utils';
import { receivableLineItemOptions } from '../../../../utils/invoice-utils';
import { BillingContext } from '../../context';

export type NewLineItemType = {
    itemName: string;
    description: string;
    quantity: number;
    unitPrice: number;
    taxable: boolean;
    totalPrice: number;
};

interface FormProps {
    itemName: string;
    description: string;
    quantity: string;
    unitPrice: number | '';
    totalPrice: number | '';
    taxable: boolean;
    addTax: boolean;
    addCc: boolean;
}

interface Props {
    lineItem: Partial<Invoice.LineItemTransport> | null;
    open: boolean;
    onCancel: () => void;
    selectedInvoice?: Invoice.ReceivableTransport | null;
    receivableIndex?: number;
    lineItemIndex?: number;
    serviceOrder?: UniversalService.ServiceOrder | undefined;
}

const taxOptions = [
    {
        value: 'false',
        label: 'Tax amount included in total',
    },
    {
        value: 'true',
        label: 'Add tax amount on top of total',
    },
];

const ccOptions = [
    {
        value: 'false',
        label: 'CC fee included in total',
    },
    {
        value: 'true',
        label: 'Add CC fee on top of total',
    },
];

const LineItemModal = ({ lineItem, open, onCancel, selectedInvoice, receivableIndex, lineItemIndex, serviceOrder }: Props) => {
    const { handleReceivableChanges, selectedOrder, selectedOrderType } = useContext(BillingContext);
    const [taxRate, setTaxRate] = useState(0);
    const [ccRate, setCCRate] = useState(defaultCCRate);
    const [lineItemOptions, setLineItemOptions] = useState<string[]>([]);

    const {
        control,
        register,
        formState: { errors, isDirty, isValid },
        setValue,
        reset,
        watch,
        handleSubmit,
        trigger,
    } = useForm<FormProps>({
        mode: 'all',
        defaultValues: {
            itemName: '',
            description: '',
            quantity: '',
            unitPrice: '',
            totalPrice: '',
            taxable: false,
            addTax: false,
            addCc: false,
        },
    });

    const watchAllValues = watch();

    useEffect(() => {
        if (!serviceOrder) {
            setLineItemOptions(receivableLineItemOptions);
        } else if (lineItemOptions.length === 0) {
            const lineItems = serviceOrder.serviceType.family.lineItemTypes.map((item) => item.displayName);
            setLineItemOptions(lineItems);
        }
    }, [serviceOrder, lineItemOptions]);

    useEffect(() => {
        if (!selectedInvoice) {
            setTaxRate(0);
        } else {
            setTaxRate(selectedInvoice.invoiceDetails.taxRate || 0);
            setCCRate(selectedInvoice.invoiceDetails.lineItems.find((item) => item.itemName === 'CC Fee') ? defaultCCRate : 0);
        }
    }, [selectedInvoice]);

    useEffect(() => {
        if (lineItem) {
            const newData = JSON.parse(JSON.stringify(lineItem));
            reset({
                itemName: newData.itemName,
                description: newData.description || '',
                quantity: newData.quantity,
                unitPrice: newData.unitPrice,
                totalPrice: +newData.quantity * +newData.unitPrice,
                taxable: newData.taxable,
                addTax: newData.taxable ? true : false,
                addCc: true,
            });
        } else {
            reset({
                itemName: '',
                description: `${selectedOrder?.poNumber ? `${selectedOrder?.poNumber} - ` : ''}${
                    selectedOrderType == 'universal-service'
                        ? `${selectedOrder?.serviceOrder?.serviceType?.family?.name} ${selectedOrder?.serviceOrder?.serviceType?.name}`
                        : ''
                }`,
                quantity: '',
                unitPrice: '',
                totalPrice: '',
                taxable: false,
                addTax: false,
                addCc: false,
            });
        }
    }, [lineItem, reset]);

    const handleQtyRateChange = (data: FormProps) => {
        if (data.unitPrice && data.quantity) {
            setValue('totalPrice', round(+data.unitPrice * +data.quantity));
            trigger('totalPrice');
        }
    };

    const handleAmountChange = (data: FormProps) => {
        if (!data.quantity) {
            setValue('quantity', '1');
            setValue('unitPrice', round(+data.totalPrice, 5));
            trigger(['unitPrice', 'quantity']);
        } else {
            setValue('unitPrice', round(+data.totalPrice / +data.quantity, 5));
            trigger('unitPrice');
        }
    };

    const getTotal = (data: FormProps) => {
        const tax = data.taxable && data.addTax ? round(+data.unitPrice * +data.quantity * taxRate, 5) : 0;
        const total = round((+data.unitPrice * +data.quantity + tax) / (1 - (ccRate && data.addCc ? ccRate : 0)));
        return total;
    };

    const handleItemNameChange = (value: string) => {
        setValue('itemName', value);
        if (value === 'Rental Extension') {
            setValue('unitPrice', selectedOrder?.rentExtensionFee ? selectedOrder?.rentExtensionFee : '');
            trigger('unitPrice');
        }
    };

    const handleCancel = () => {
        onCancel();
        reset();
    };

    const handleDeleteLineItem = () => {
        if (!selectedOrder || !selectedInvoice || receivableIndex === undefined || lineItemIndex === undefined) return;

        const existingLineItems = [...selectedInvoice.invoiceDetails.lineItems];
        // remove the line item by index
        existingLineItems.splice(lineItemIndex, 1);

        // If there is a CC Fee line item, update it
        const existingCCLineItemIndex = existingLineItems.findIndex((item) => item.itemName === 'CC Fee');

        if (existingCCLineItemIndex >= 0) {
            // Sum up the totalPrice of all line items, excluding the existing CC Fee item
            const preCCTotal = existingLineItems
                .filter((item) => item.itemName !== 'CC Fee')
                .reduce((total, lineItem) => (total += lineItem.totalPrice || (lineItem.unitPrice || 0) * (lineItem.quantity || 0)), 0);

            // Calculate the total including the ccRate credit card processing fee
            // To find the amount that, when increased by ccRate equals the original total,
            // For example you divide the total by 0.97 (since 100% - 3% = 97%, or 0.97 in decimal).
            const totalWithProcessing = preCCTotal / (1 - ccRate);
            const newCCTotal = totalWithProcessing - preCCTotal;

            const existingCCLineItem = {
                ...existingLineItems[existingCCLineItemIndex],
                unitPrice: newCCTotal,
                totalPrice: newCCTotal,
            };

            existingLineItems[existingCCLineItemIndex] = existingCCLineItem;
        }

        selectedInvoice.invoiceDetails.lineItems = existingLineItems;
        handleReceivableChanges(selectedInvoice, receivableIndex);

        handleReceivableChanges(selectedInvoice, receivableIndex);
        onCancel();
    };

    const handleLineItemSave = (newLineItem: Partial<Invoice.LineItemTransport>) => {
        if (!selectedOrder || !selectedInvoice) return;

        // let's grab the relevant invoice
        const receivable = { ...selectedInvoice };
        const existingLineItems = [...receivable.invoiceDetails.lineItems];

        // if we have a lineItemIndex we are editing an existing line item
        if (lineItemIndex || lineItemIndex === 0) {
            existingLineItems[lineItemIndex] = newLineItem as Invoice.LineItemTransport;
        } else {
            existingLineItems.push(newLineItem as Invoice.LineItemTransport);
        }

        // If there is a CC Fee line item, update it
        const existingCCLineItemIndex = existingLineItems.findIndex((item) => item.itemName === 'CC Fee');

        if (existingCCLineItemIndex >= 0) {
            // Sum up the totalPrice of all line items, excluding the existing CC Fee item
            const preCCTotal = existingLineItems
                .filter((item) => item.itemName !== 'CC Fee')
                .reduce((total, lineItem) => (total += lineItem.totalPrice || (lineItem.unitPrice || 0) * (lineItem.quantity || 0)), 0);

            // Calculate the total including the ccRate credit card processing fee
            // To find the amount that, when increased by ccRate equals the original total,
            // For example you divide the total by 0.97 (since 100% - 3% = 97%, or 0.97 in decimal).
            const totalWithProcessing = preCCTotal / (1 - ccRate);
            const newCCTotal = totalWithProcessing - preCCTotal;

            const existingCCLineItem = {
                ...existingLineItems[existingCCLineItemIndex],
                unitPrice: newCCTotal,
                totalPrice: newCCTotal,
            };

            existingLineItems[existingCCLineItemIndex] = existingCCLineItem;
        }

        receivable.invoiceDetails.lineItems = existingLineItems;
        handleReceivableChanges(receivable, receivableIndex);
        onCancel();
    };

    const onSubmit = (data: FormProps) => {
        const cleanRate = pricingBreakdownTotal(getTotal(data), +data.quantity, data.taxable ? taxRate : 0, ccRate);
        const newLineItem: Invoice.LineItemInputTransport = {
            itemName: data.itemName,
            description: data.description,
            quantity: +data.quantity,
            unitPrice: cleanRate.unitPriceDollars, // unitPriceDollars is actually cents
            taxable: data.taxable,
            totalPrice: round(+data.quantity * cleanRate.unitPriceDollars), // unitPriceDollars is actually cents
        };
        if (selectedOrderType === 'universal-service') newLineItem.serviceOrderID = selectedOrder?.id;

        handleLineItemSave(newLineItem);
        reset();
    };

    if (!open) return null;

    return (
        <Dialog open={open} onClose={handleCancel} styledTitle={`${lineItem ? 'Edit' : 'Add'} Charge`}>
            <form className="flex flex-col gap-6">
                <div>
                    {lineItem?.itemName && !lineItemOptions.includes(lineItem.itemName) ? (
                        <p className="opacity-70">{lineItem.itemName}</p>
                    ) : (
                        <Controller
                            name="itemName"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'An item name is required',
                                },
                            }}
                            render={({ field }) => (
                                <Select
                                    label="Item"
                                    value={field.value}
                                    onSelect={(value) => handleItemNameChange(value)}
                                    required
                                    error={errors.itemName}
                                >
                                    {lineItemOptions.sort().map((item) => (
                                        <SelectOption key={item} value={item}>
                                            {item}
                                        </SelectOption>
                                    ))}
                                </Select>
                            )}
                        />
                    )}
                </div>
                <div>
                    <TextField label="Description" inputProps={{ ...register('description') }} />
                </div>
                <div>
                    <TextField
                        label="Quantity"
                        inputProps={{
                            ...register('quantity', { required: 'Quantity is required' }),
                            step: '0.01',
                            onBlur: () => {
                                handleQtyRateChange(watchAllValues);
                            },
                        }}
                        required
                        type="number"
                    />
                </div>
                <div>
                    <Controller
                        name="unitPrice"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Rate is required',
                            },
                        }}
                        render={({ field }) => (
                            <CurrencyTextField
                                label="Rate"
                                required
                                useCents
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                                error={errors.unitPrice}
                                inputProps={{
                                    onBlur: () => {
                                        handleQtyRateChange(watchAllValues);
                                    },
                                }}
                                numericFormatProps={{ decimalScale: 5 }}
                            />
                        )}
                    />
                </div>
                <div>
                    <Controller
                        name="totalPrice"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Amount is required',
                            },
                        }}
                        render={({ field }) => (
                            <CurrencyTextField
                                label="Amount"
                                required
                                useCents
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                                error={errors.totalPrice}
                                inputProps={{
                                    onBlur: () => {
                                        handleAmountChange(watchAllValues);
                                    },
                                }}
                            />
                        )}
                    />
                </div>
                <div className="flex flex-col gap-3">
                    <Controller
                        name="taxable"
                        control={control}
                        render={({ field }) => (
                            <Toggle
                                label={`Taxable ${taxRate * 100}%`}
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                                disabled={!taxRate}
                            />
                        )}
                    />
                    {watchAllValues.taxable && (
                        <Controller
                            name="addTax"
                            control={control}
                            render={({ field }) => (
                                <RadioButton
                                    options={taxOptions.map((item) => ({
                                        ...item,
                                        inputProps: { checked: (field.value ? 'true' : 'false') === item.value },
                                    }))}
                                    inputProps={{
                                        value: field.value ? 'true' : 'false',
                                        onChange: (e) => field.onChange(e.target.value === 'true' ? true : false),
                                    }}
                                />
                            )}
                        />
                    )}
                </div>
                {ccRate && (
                    <div className="flex flex-col gap-3">
                        <p className="opacity-60">Charge CC Fee</p>
                        <Controller
                            name="addCc"
                            control={control}
                            render={({ field }) => (
                                <RadioButton
                                    options={ccOptions.map((item) => ({
                                        ...item,
                                        inputProps: { checked: (field.value ? 'true' : 'false') === item.value },
                                    }))}
                                    inputProps={{
                                        value: field.value ? 'true' : 'false',
                                        onChange: (e) => field.onChange(e.target.value === 'true' ? true : false),
                                    }}
                                />
                            )}
                        />
                    </div>
                )}
            </form>
            <div className="mt-6 text-lg">Total: {moneyFormatter(getTotal(watchAllValues))}</div>
            <div className="mt-6 flex justify-end gap-4 border-t pt-4">
                <button
                    className="btn-delete mr-auto"
                    onClick={handleDeleteLineItem}
                    disabled={!lineItem || watchAllValues.itemName === 'QP-Haul' || watchAllValues.itemName === 'QP-Dump'}
                >
                    delete
                </button>
                <button className="btn-dark-grey-outlined" onClick={() => handleCancel()}>
                    Cancel
                </button>
                <button className="btn-primary" type="button" disabled={!isValid || !isDirty} onClick={handleSubmit(onSubmit)}>
                    {lineItem ? 'Update' : 'Add'}
                </button>
            </div>
        </Dialog>
    );
};

export default LineItemModal;
