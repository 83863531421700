import { Customer, Pricing, Profile } from '@alliance-disposal/transport-types';
import { Step, VerticalStepper } from '@wayste/sour-ui';
import DispatchSubForm from '../OrderCreate/DispatchSubForm';
import PricingSubForm from '../OrderCreate/PricingSubForm';
import PaymentSummary from './PaymentSummary';

export interface OrderReviewProps {
    user: Profile.ProfileTransport;
    zonePricing: Pricing.PricingTransport | null;
    onGetPricing: (
        coords: {
            lat: number;
            lng: number;
            zip?: string;
            state: string;
        },
        customerID?: string,
    ) => void;
    isLoading: boolean;
    existingCustomer: Customer.AllianceCustomerTransport;
}

const OrderReview = ({ user, zonePricing, onGetPricing, isLoading, existingCustomer }: OrderReviewProps) => {
    return (
        <div className="mx-auto flex max-w-lg flex-1 flex-col p-5 lg:max-w-5xl">
            <div style={{ flex: 1 }}>
                <VerticalStepper activeStep={2}>
                    <Step label="Order Info">
                        <PricingSubForm
                            zonePricing={zonePricing}
                            onGetPricing={onGetPricing}
                            existingCustomer={existingCustomer}
                            needsReviewStatus={true}
                        />
                    </Step>
                    <Step label="Dispatch Info">
                        <DispatchSubForm needsReviewStatus={true} />
                    </Step>
                    <Step label="Payment &amp; Summary">
                        <PaymentSummary user={user} isLoading={isLoading} zonePricing={zonePricing} />
                    </Step>
                </VerticalStepper>
            </div>
        </div>
    );
};

export default OrderReview;
