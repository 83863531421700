import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Pricing } from '@alliance-disposal/transport-types';
import { Button, CurrencyTextField, Dialog, Loading, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { Controller, useForm } from 'react-hook-form';
import { calculateCustomDiscount } from '../utils/calculate-discount';

type AddCustomPricingProps = {
    open: boolean;
    existingCustomDiscounts?: Pricing.CustomerDiscountTransport[];
    selectedCustomDiscount?: Pricing.CustomerDiscountTransport;
    onClose: () => void;
    onSubmit: () => void;
    isTierPricing?: boolean;
    customerId?: string;
    pricingZone?: Pricing.PricingTransport;
};

interface FormProps {
    state: string;
    pricingZone: Pricing.PricingTransport | undefined | null;
    rentalPeriod: number | '';
    rentalExtensionRate: number | '';
    discountType: Pricing.CustomerDiscountType.DOLLAR | Pricing.CustomerDiscountType.FIXED | Pricing.CustomerDiscountType.PERCENTAGE;
    tierName?: string;
}

const CustomPricingZoneModal = ({
    open,
    existingCustomDiscounts,
    onClose,
    onSubmit,
    selectedCustomDiscount,
    isTierPricing,
    customerId,
    pricingZone,
}: AddCustomPricingProps) => {
    const client = useWaysteClient();
    const [loading, setLoading] = useState(false);
    const [pricingZoneOptions, setPricingZoneOptions] = useState<Pricing.PricingTransport[]>();

    const {
        setValue,
        handleSubmit: handleRHFSubmit,
        register,
        watch,
        reset,
        control,
    } = useForm<FormProps>({
        mode: 'all',
        shouldFocusError: false,
        defaultValues: {
            state: '',
            pricingZone: undefined,
            rentalPeriod: '',
            rentalExtensionRate: '',
            discountType: Pricing.CustomerDiscountType.FIXED,
            tierName: isTierPricing ? '' : undefined,
        },
    });

    const watchState = watch('state');
    const watchPricingZone = watch('pricingZone');
    const watchRentalPeriod = watch('rentalPeriod');
    const watchRentalExtensionRate = watch('rentalExtensionRate');
    const watchDiscountType = watch('discountType');

    useEffect(() => {
        setValue('state', selectedCustomDiscount?.pricingZone?.state || pricingZone?.state || '');
        if (selectedCustomDiscount?.pricingZone) {
            setValue('pricingZone', selectedCustomDiscount.pricingZone);
            setPricingZoneOptions([selectedCustomDiscount.pricingZone]);
        } else if (pricingZone) {
            setValue('pricingZone', pricingZone);
            setPricingZoneOptions([pricingZone]);
        }
        if (selectedCustomDiscount) {
            setValue('rentalExtensionRate', selectedCustomDiscount.rentExtensionFee || '');
            setValue(
                'rentalPeriod',
                // TODO requires to check if fixed or not
                selectedCustomDiscount.pricingZone?.rentalPeriod?.value && selectedCustomDiscount.extraRentalPeriod
                    ? selectedCustomDiscount.pricingZone?.rentalPeriod.value + selectedCustomDiscount.extraRentalPeriod
                    : '',
            );
            setValue('discountType', selectedCustomDiscount.discountType);

            if (isTierPricing) setValue('tierName', selectedCustomDiscount.tierName);
        }
    }, [selectedCustomDiscount, pricingZone]);

    const handleClose = () => {
        reset();
        setPricingZoneOptions(undefined);
        onClose();
    };

    const onFormSubmit = async (data: FormProps) => {
        if (!data.pricingZone?.id) {
            reset();
            return alert('An error has occurred, please try again');
        }

        const rentalExtensionFee = calculateCustomDiscount(
            data.discountType,
            watchPricingZone?.rentExtensionFee || undefined,
            data.rentalExtensionRate,
        );
        const extraRentalPeriod = calculateCustomDiscount(data.discountType, watchPricingZone?.rentalPeriod?.value, data.rentalPeriod);
        try {
            if (selectedCustomDiscount?.id) {
                await client.pricing().adminPortal.customerDiscount.update(selectedCustomDiscount.id, {
                    rentExtensionFee: rentalExtensionFee,
                    extraRentalPeriod: extraRentalPeriod,
                });
            } else {
                // Customers can only have 1 override per pricing zone but tiers can have many
                if (customerId && existingCustomDiscounts?.find((x) => x.pricingZoneID === data.pricingZone?.id)) {
                    return alert('This pricing zone already exist. Please delete it or edit it instead.');
                }
                const payload: Pricing.CustomerDiscountCreateTransport = {
                    customerID: customerId,
                    pricingZoneID: data.pricingZone?.id,
                    rentExtensionFee: rentalExtensionFee,
                    extraRentalPeriod: extraRentalPeriod,
                    discountData: [],
                    discountType: data.discountType,
                    tierName: data.tierName,
                };
                await client.pricing().adminPortal.customerDiscount.create(payload);
            }
            onSubmit();
            handleClose();
        } catch (error) {
            console.warn('handleCreateUpdatePricingZone error: ', error);
            alert('An error has occurred, please contact a SAP dev.');
        }
    };

    const fetchPricingZones = async (value: Pricing.UsStateAbbreviation) => {
        try {
            setLoading(true);

            const pricing = await client.pricing().adminPortal.pricing.query({ state: value });

            if (pricing.length === 0) {
                setLoading(false);
                return alert('No pricing zones are available for the selected state. If you think this is an error please contact a dev.');
            }
            const pricingZoneData = pricing as Pricing.PricingTransport[];
            const existingPricingZones: Record<string, boolean> = {};
            existingCustomDiscounts?.forEach((x) => {
                if (x.pricingZoneID) {
                    existingPricingZones[x.pricingZoneID] = true;
                }
            });
            const filtered = pricingZoneData
                .filter((x) => x.pricingData.length !== 0 && !existingPricingZones[x.id as keyof typeof existingPricingZones] && x.public)
                .sort((a, b) => ((a?.zoneName || '') > (b?.zoneName || '') ? 1 : -1));
            setPricingZoneOptions(filtered);
            setLoading(false);
        } catch (error) {
            setValue('state', '');
            alert('An error has occurred while fetching pricing zones, please try again or contact dev.');
        }
        setLoading(false);
    };

    const handleStateSelect = (value: Pricing.UsStateAbbreviation) => {
        setPricingZoneOptions(undefined);
        setValue('pricingZone', null);
        setValue('rentalPeriod', '');
        setValue('rentalExtensionRate', '');
        setValue('state', value);
        if (value && Pricing.USStateAbbreviations.includes(value)) {
            fetchPricingZones(value);
        }
    };

    const displayNewRentalPeriod = () => {
        const newRate = calculateCustomDiscount(watchDiscountType, watchPricingZone?.rentalPeriod?.value, watchRentalPeriod);
        return (newRate || watchPricingZone?.rentalPeriod?.value) + ' days';
    };

    const displayNewExtensionRate = () => {
        const newRate = calculateCustomDiscount(
            watchDiscountType,
            watchPricingZone?.rentExtensionFee || undefined,
            watchRentalExtensionRate,
        );
        return `${moneyFormatter(newRate) || moneyFormatter(watchPricingZone?.rentExtensionFee)} / day`;
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            styledTitle={`${selectedCustomDiscount ? 'Update' : 'Add New'} Custom Pricing Zone`}
            className="!max-w-4xl"
        >
            <form onSubmit={handleRHFSubmit(onFormSubmit)}>
                <div className="mx-20 my-2.5 flex flex-col gap-2">
                    <Controller
                        name="state"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label="State"
                                required
                                onSelect={(value) => handleStateSelect(value as Pricing.UsStateAbbreviation)}
                                value={field.value}
                                disabled={Boolean(selectedCustomDiscount) || Boolean(pricingZone)}
                            >
                                {Pricing.USStateAbbreviations.map((item, index) => (
                                    <SelectOption key={item + index} value={item}>
                                        {item}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                    {!loading ? (
                        <Controller
                            name="pricingZone"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label="Pricing zone"
                                    required
                                    onSelect={(value) =>
                                        setValue(
                                            'pricingZone',
                                            pricingZoneOptions?.find((item) => item.zoneName === value),
                                        )
                                    }
                                    value={field.value?.zoneName || undefined}
                                    disabled={Boolean(selectedCustomDiscount) || Boolean(pricingZone)}
                                >
                                    {pricingZoneOptions?.map((item, index) => (
                                        <SelectOption key={index} value={item.zoneName || ''}>
                                            {item.zoneName}
                                        </SelectOption>
                                    ))}
                                </Select>
                            )}
                        />
                    ) : (
                        <div className="mt-1 flex flex-col items-center gap-1">
                            <Loading size="h-8 w-8" />
                            <p>Fetching Zones..</p>
                        </div>
                    )}
                    {isTierPricing && (
                        <Controller
                            name="tierName"
                            control={control}
                            rules={{ required: 'Tier name is required' }}
                            render={({ field }) => (
                                <Select value={field.value} onSelect={field.onChange} label="Tier Name" required>
                                    {Pricing.SourgumTierNames.map((tier) => (
                                        <SelectOption
                                            key={tier}
                                            value={tier}
                                            disabled={
                                                existingCustomDiscounts?.find((existingTier) => existingTier.tierName === tier) !==
                                                undefined
                                            }
                                        >
                                            {tier}
                                        </SelectOption>
                                    ))}
                                </Select>
                            )}
                        />
                    )}

                    <Controller
                        name="discountType"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label="Discount Type"
                                onSelect={(value) => field.onChange(value)}
                                value={field.value}
                                disabled={Boolean(selectedCustomDiscount)}
                            >
                                <SelectOption value={Pricing.CustomerDiscountType.DOLLAR}>Discount off of public</SelectOption>
                                <SelectOption value={Pricing.CustomerDiscountType.FIXED}>Fixed price</SelectOption>
                            </Select>
                        )}
                    />
                </div>

                {watchPricingZone && (
                    <div className="flex flex-col items-center justify-center gap-4">
                        <p className="text-blue-gray-400 text-sm italic">
                            If you do not add a custom rate, it will not override the standard rate.
                        </p>
                        <div className="grid w-full grid-cols-3 items-center gap-4">
                            <p className="mb-2 font-bold underline">Standard rate</p>
                            <p className="mb-2 font-bold underline">Custom rate</p>
                            <p className="mb-2 font-bold underline">Discount amount</p>
                            {/* Rental Period */}
                            <p>{(watchPricingZone?.rentalPeriod?.value || 0) + ' days'}</p>
                            <div className="w-40">
                                <TextField
                                    label="Rental Period"
                                    type="number"
                                    inputProps={{
                                        ...register('rentalPeriod'),
                                    }}
                                />
                            </div>
                            <p>{displayNewRentalPeriod()}</p>
                            {/* Rental Extension Fee */}
                            <p>{moneyFormatter(watchPricingZone?.rentExtensionFee) + ' / day'}</p>
                            <div className="w-40">
                                <CurrencyTextField
                                    label="Rental Extension Fee"
                                    onChange={(value) => setValue('rentalExtensionRate', value)}
                                    value={watchRentalExtensionRate}
                                    useCents
                                />
                            </div>
                            <p>{displayNewExtensionRate()}</p>
                        </div>
                    </div>
                )}
                <hr className="mt-4" />
                <div className="mt-4 flex justify-end gap-4">
                    <Button className="btn-dark-grey-outlined" onClick={handleClose} disabled={loading}>
                        Cancel
                    </Button>
                    <Button className="btn-primary" type="submit" loading={loading}>
                        Save
                    </Button>
                </div>
            </form>
        </Dialog>
    );
};

export default CustomPricingZoneModal;
