import { useEffect, useState } from 'react';
import { UniversalService } from '@alliance-disposal/transport-types';
import { UniversalService as Product } from '@alliance-disposal/transport-types';
import { StatusType } from '@wayste/utils';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { PlusIcon } from '@heroicons/react/24/solid';
import { FieldArrayWithId, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { DeleteStatusModal } from '../../pages/ServiceUpdate/DeleteStatusModal';
import { PermanentItemComponent } from './PermanentItemComp';
import { SortableItemComponent } from './SortableItemComp';

export const excludedStatuses = ['COMPLETED', 'ASSIGNED', 'UNASSIGNED'];
export interface SortableListProps {
    mode: 'edit' | 'create';
    serviceFamilyID?: string;
    activeStatuses?: string[];
}

const SortableList = ({ mode, serviceFamilyID, activeStatuses }: SortableListProps) => {
    // STATE
    const [itemIDs, setItemIDs] = useState<string[]>([]);
    const [availableStatuses, setAvailableStatuses] = useState<string[]>([]);

    // MODAL
    const [deleteStatusDialogOpen, setDeleteStatusDialogOpen] = useState<boolean>(false);
    const [deleteStatus, setDeleteStatus] = useState<Product.ServiceOrderStatus | undefined>(undefined);

    // REACT HOOK FORM HOOKS
    const { clearErrors, getValues, control } = useFormContext();

    // FIELD ARRAY HOOKS
    const { fields, append, move, remove } = useFieldArray({
        control,
        name: 'statuses',
    });

    // WATCH CONTACTS FIELD ARRAY
    const data = useWatch({
        control,
    });

    ////////////////////////////////////////
    // FUNCTIONS
    ////////////////////////////////////////

    // PROCESS DATA ON DRAG END REARRANGEMENT
    const handleDragEnd = (event: DragEndEvent) => {
        if (event.over === null) {
            return;
        }
        clearErrors();
        const { active, over } = event;
        if (active.id !== over.id) {
            console.log(active.id, over.id);
            const oldIndex = fields.findIndex((item) => item.id === active.id);
            const newIndex = fields.findIndex((item) => item.id === over.id);
            move(oldIndex, newIndex);
            const ids = fields.map((item) => item.id);
            setItemIDs(ids);
            clearErrors();
        }
    };

    const handleAddStatus = () => {
        append({});
    };
    const handleRemoveStatus = (status: string) => {
        const fieldItems = getValues('statuses');
        const index = fieldItems.findIndex((field: StatusType) => field.status === status);
        remove(index);
        const ids = fields.map((item) => item.id);
        setItemIDs(ids);
    };

    // TOGGLE REMOVE STATUS DIALOG
    const toggleRemoveStatusDialog = (status: Product.ServiceOrderStatus) => {
        setDeleteStatus(status);
        setDeleteStatusDialogOpen(true);
    };

    ////////////////////////////////////////
    // HOOKS
    ////////////////////////////////////////

    // GET ITEM IDS NEEDED FOR SORTING DND
    useEffect(() => {
        if (data) {
            const ids = fields.map((item) => item.id);
            setItemIDs(ids);
        }
    }, [data]);

    // GET REMAINING AVAILABLE STATUSES
    useEffect(() => {
        const filteredStatuses = UniversalService.ServiceOrderStatuses.filter((status) => !excludedStatuses.includes(status));
        const statuses = getValues('statuses');
        if (statuses) {
            const usedStatuses = statuses.map((item: StatusType) => item.status);
            const availableStatuses = filteredStatuses.filter((status) => !usedStatuses.includes(status));
            setAvailableStatuses(availableStatuses);
        }
    }, [data]);

    ////////////////////////////////////////
    // RENDER
    ////////////////////////////////////////
    return (
        <div>
            {deleteStatus && serviceFamilyID && (
                <DeleteStatusModal
                    show={deleteStatusDialogOpen}
                    serviceFamilyID={serviceFamilyID}
                    setShow={(show) => {
                        setDeleteStatusDialogOpen(show);
                    }}
                    status={deleteStatus}
                    handleDelete={handleRemoveStatus}
                />
            )}
            <PermanentItemComponent id={0} index={0} status={'UNASSIGNED'} field={'preStatuses'} onRemove={handleRemoveStatus} />
            <PermanentItemComponent id={1} index={1} status={'ASSIGNED'} field={'preStatuses'} onRemove={handleRemoveStatus} />
            <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={itemIDs} strategy={verticalListSortingStrategy}>
                    <div className="w-full">
                        {fields.map((field: FieldArrayWithId<Record<'id', StatusType>>, index: number) => (
                            <SortableItemComponent
                                key={field.id}
                                id={field.id}
                                status={getValues(`statuses.${index}`)}
                                position={index}
                                mode={mode}
                                availableStatuses={availableStatuses}
                                onRemove={
                                    mode == 'edit'
                                        ? () => {
                                              const fieldItems = getValues(`statuses.${index}`);
                                              console.log(activeStatuses, 'ACTIVE STATUS');
                                              if (activeStatuses?.includes(fieldItems.status)) {
                                                  console.log('toggleRemoveStatusDialog', fieldItems);
                                                  toggleRemoveStatusDialog(fieldItems.status);
                                              } else {
                                                  handleRemoveStatus(fieldItems.status);
                                              }
                                          }
                                        : handleRemoveStatus
                                }
                            />
                        ))}
                    </div>
                </SortableContext>
            </DndContext>
            <PermanentItemComponent id={0} index={0} status={'COMPLETED'} field={'postStatuses'} onRemove={handleRemoveStatus} />

            <div className="cursor-pointer py-2" onClick={handleAddStatus}>
                <div className="text-sourgum-green-400 flex flex-row items-center space-x-2">
                    <PlusIcon className="h-5 w-5" />
                    <span> Add another status</span>
                </div>
            </div>
        </div>
    );
};

export default SortableList;
