import { UniversalService } from '@alliance-disposal/transport-types';
import { moneyFormatter } from '@wayste/utils';
import { ArrowTopRightOnSquareIcon, PencilIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import CardInfoList, { CardInfoListData } from '../../../../components/CardInfoList';
import DetailsCardWrapper from '../../../../components/DetailsCardWrapper';
import { useFlash } from '../../../../hooks/useFlash';
import { routes } from '../../../../utils';

interface OneTimeBillingDetailsCardProps {
    serviceOrder: UniversalService.ServiceOrder;
    serviceGrouping: UniversalService.ServiceGrouping;
}

const OneTimeBillingDetailsCard = ({ serviceGrouping, serviceOrder }: OneTimeBillingDetailsCardProps) => {
    const { showFlash } = useFlash();
    const history = useHistory();

    const handleEditPricingClick = () => {
        showFlash('Edit pricing not implemented yet', 'success');
    };

    const delivery = serviceOrder.events.find((event) => event.lineItemType.description === 'delivery');
    const removal = serviceOrder.events.find((event) => event.lineItemType.description === 'removal');
    const otherCharges = serviceOrder.events
        .filter(
            (event) =>
                event.lineItemType.description !== 'delivery' &&
                event.lineItemType.description !== 'removal' &&
                event.lineItemType.description !== 'recurring',
        )
        .reduce((total, event) => total + event.unitPrice * event.priceQuantity, 0);
    const colOne: CardInfoListData[] = [
        {
            label: 'Price Quoted',
            value: '',
        },
        {
            label: 'Order Total',
            value: '',
        },
        {
            label: 'Paid',
            value: serviceOrder.invoiceStatus === UniversalService.ServiceOrderInvoiceStatus.Paid ? 'Yes' : 'No',
        },
        {
            label: 'Due',
            value: '',
        },
    ];
    const colTwo: CardInfoListData[] = [
        {
            label: 'Delivery Fee',
            value: moneyFormatter((delivery?.unitPrice || 0) * (delivery?.priceQuantity || 1)) || '',
        },
        {
            label: 'Removal Fee',
            value: moneyFormatter((removal?.unitPrice || 0) * (removal?.priceQuantity || 1)) || '',
        },
        {
            label: 'Other Charges',
            value: moneyFormatter(otherCharges) || '',
        },
    ];
    const colThree: CardInfoListData[] = [
        {
            label: 'Payment Terms',
            value: serviceGrouping.paymentTerm,
        },
        {
            label: 'Payment Method',
            value: serviceGrouping.paymentMethod,
        },
    ];
    const colFour: CardInfoListData[] = [
        {
            label: 'Vendor Price',
            value: '',
        },
        {
            label: 'Vendor Delivery Fee',
            value: '',
        },
        {
            label: 'Vendor Removal Fee',
            value: '',
        },
        {
            label: 'Vendor Other Charges',
            value: '',
        },
    ];

    return (
        <DetailsCardWrapper
            heading="Billing Details"
            buttons={[
                {
                    label: (
                        <>
                            <PencilIcon className="mr-1 h-5 w-5" /> Edit Pricing
                        </>
                    ),
                    onClick: handleEditPricingClick,
                },
                {
                    label: (
                        <>
                            <ArrowTopRightOnSquareIcon className="mr-1 h-5 w-5" /> Go to Invoice
                        </>
                    ),
                    onClick: () => history.push(routes.billingProduct.details(serviceGrouping.id, serviceOrder.id)),
                },
            ]}
        >
            <div className={'grid grid-cols-1 gap-3 lg:grid-cols-4'}>
                <CardInfoList data={colOne} border />
                <CardInfoList data={colTwo} border />
                <CardInfoList data={colThree} border />
                <CardInfoList data={colFour} />
            </div>
        </DetailsCardWrapper>
    );
};

export default OneTimeBillingDetailsCard;
