import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button, Checkbox, CurrencyTextField, Dialog, Loading, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { createBillingEvent, formatTimestamp } from '@wayste/utils';
import { Controller, useForm } from 'react-hook-form';
import { useFlash } from '../../../hooks/useFlash';

/*

  status: "pending" | "scheduled" | "cancelled" | "done" | "exception";
    description: string;
    unitPrice: number;
    taxable: boolean;
    dateExpected: string;
    eventType: "initial-delivery" | "visit" | "synthetic" | "final-pickup" | "subscription-recurring";
    basePrice: number;
    baseCost: number;
    unitCost: number;
    unitType: "ton" | "hour" | "day" | "week" | "month" | "each";
    priceQuantity: number;
    costQuantity: number;
    invoiceGroupingID?: string | null | undefined;
    lineItemTypeID: string;
*/

type ServiceEventCreateFrom = UniversalService.ServiceEventCreate & {
    isPeriodic: boolean;
};

export type AddServiceEventDialogProps = {
    open: boolean;
    onClose: (xClicked?: boolean) => void;
    serviceGrouping: UniversalService.ServiceGrouping;
    serviceOrder: UniversalService.ServiceOrder;
};

export const AddServiceEventDialog = ({ open, onClose, serviceOrder, serviceGrouping }: AddServiceEventDialogProps) => {
    const { handleSubmit, control } = useForm<ServiceEventCreateFrom>();
    const { showFlash } = useFlash();
    const [loading, setLoading] = useState(false);
    const client = useWaysteClient();

    const onSubmit = async (data: ServiceEventCreateFrom) => {
        setLoading(true);
        const event = createBillingEvent(data, data.lineItemTypeID);

        try {
            await client
                .universalService()
                .serviceGrouping.serviceOrder.serviceOrderEvent.create(serviceGrouping.id, serviceOrder.id, event);

            if (data.isPeriodic && serviceOrder.subscriptionDetails && serviceOrder.subscriptionDetails.periodicEvents) {
                serviceOrder.subscriptionDetails?.periodicEvents.push(event);
                await client.universalService().serviceGrouping.serviceOrder.update(serviceGrouping.id, serviceOrder.id, {
                    subscriptionDetails: {
                        ...serviceOrder.subscriptionDetails,
                        id: serviceOrder.subscriptionDetails.id,
                        periodicEvents: serviceOrder.subscriptionDetails.periodicEvents,
                        endDate: serviceOrder.subscriptionDetails.endDate || undefined,
                    },
                });
            }
            onClose();
        } catch (error) {
            showFlash((error as Error).message, 'warning');
        } finally {
            setLoading(false);
        }
    };
    return (
        <Dialog open={open} onClose={onClose} styledTitle="Add Service Event" showX>
            <div className="flex w-full justify-center">
                {loading ? (
                    <Loading />
                ) : (
                    <div className="flex w-full flex-col gap-4">
                        <Controller
                            name="status"
                            control={control}
                            defaultValue="pending"
                            render={({ field }) => (
                                <Select onSelect={field.onChange} value={field.value} label="Status">
                                    {UniversalService.ServiceEventStatuses.map((status) => (
                                        <SelectOption key={status} value={status}>
                                            {status}
                                        </SelectOption>
                                    ))}
                                </Select>
                            )}
                        />
                        <Controller
                            name="eventType"
                            control={control}
                            defaultValue="synthetic"
                            render={({ field }) => (
                                <Select onSelect={field.onChange} value={field.value} label="Status">
                                    {UniversalService.ServiceEventTypes.map((eventType) => (
                                        <SelectOption key={eventType} value={eventType}>
                                            {eventType}
                                        </SelectOption>
                                    ))}
                                </Select>
                            )}
                        />
                        <Controller
                            name="lineItemTypeID"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <Select onSelect={field.onChange} value={field.value} label="Line Item Type">
                                    {serviceOrder.serviceType.family.lineItemTypes.map((lineItemType) => (
                                        <SelectOption key={lineItemType.id} value={lineItemType.id}>
                                            {serviceOrder.serviceType.family.name + ' - ' + lineItemType.description}
                                        </SelectOption>
                                    ))}
                                </Select>
                            )}
                        />
                        <Controller
                            name="invoiceGroupingID"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <Select onSelect={field.onChange} value={field.value ?? undefined} label="Invoice Grouping">
                                    {serviceGrouping.invoiceGroupings.map((grouping) => (
                                        <SelectOption key={grouping.id} value={grouping.id}>
                                            {formatTimestamp(grouping.metadata.createdAt) +
                                                ' - ' +
                                                (grouping.serviceEvents.length > 0
                                                    ? grouping.serviceEvents.map((event) => event.lineItemType.description).join(', ')
                                                    : 'Empty Grouping')}
                                        </SelectOption>
                                    ))}
                                </Select>
                            )}
                        />
                        <Controller
                            name="unitPrice"
                            control={control}
                            defaultValue={0}
                            render={({ field }) => (
                                <CurrencyTextField label="Unit Price" value={+field.value} onChange={field.onChange} useCents />
                            )}
                        />
                        <Controller
                            name="priceQuantity"
                            control={control}
                            defaultValue={0}
                            render={({ field }) => (
                                <TextField
                                    label="Price Quantity"
                                    type="number"
                                    inputProps={{
                                        value: field.value,
                                        onChange: (val) => field.onChange(+val.target.value),
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="isPeriodic"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                                <Checkbox
                                    label="Is Periodic"
                                    inputProps={{ checked: field.value, onChange: (val) => field.onChange(val.target.checked) }}
                                />
                            )}
                        />
                        <div className="flex justify-end">
                            <Button className="btn-primary" onClick={handleSubmit((data) => onSubmit(data))}>
                                Submit
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Dialog>
    );
};
