import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Dialog, Textarea } from '@wayste/sour-ui';
import { Controller, useForm } from 'react-hook-form';

interface ReopenPartnerPayableFormProps {
    haulerNote: string;
}

interface ReopenPartnerPayableModalProps {
    open: boolean;
    onClose: (submitted: boolean) => void;
    payable: Invoice.PayableTransport | null;
}

const ReopenPartnerPayableModal = ({ open, onClose, payable }: ReopenPartnerPayableModalProps) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: ReopenPartnerPayableFormProps) => {
        if (!payable) return;
        setIsLoading(true);
        try {
            await client.invoice().adminPortal.payable.review(payable.id, {
                action: 'RESET',
                note: data.haulerNote,
            });
            onClose(true);
        } catch (error) {
            console.error(error);
            setShowToast({ severity: 'error', message: 'Failed to reopen payable' });
        } finally {
            setIsLoading(false);
        }
    };

    const {
        control,
        handleSubmit,
        formState: { isValid },
        reset,
    } = useForm<ReopenPartnerPayableFormProps>({
        mode: 'all',
        defaultValues: {
            haulerNote: '',
        },
    });

    useEffect(() => {
        reset({
            haulerNote:
                'You submitted the wrong dump ticket for this order. Please ensure the date on the dump ticket matches the pickup date on the order.',
        });
    }, [payable]);

    return (
        <Dialog open={open} onClose={() => onClose(false)} styledTitle="Reopen Invoice" disableCloseOnClickOutside>
            <form className="flex flex-col gap-4 overflow-y-auto pt-2.5" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="haulerNote"
                    control={control}
                    render={({ field }) => <Textarea label="Note for hauler" inputProps={{ ...field }} />}
                />
                <div className="flex justify-end gap-4 pt-4">
                    <Button className="btn-dark-grey-outlined" onClick={() => onClose(false)} type="button">
                        Cancel
                    </Button>
                    <Button className="btn-primary" type="submit" disabled={!isValid} loading={isLoading}>
                        Reopen
                    </Button>
                </div>
            </form>
        </Dialog>
    );
};

export default ReopenPartnerPayableModal;
