import React, { useState } from 'react';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { formatServiceAddress, moneyFormatter } from '@wayste/utils';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useHistory } from 'react-router-dom';
import { haulerChargeItems, routes } from '../../utils';
import Loading from '../Loading';
import OrderImageHandler from '../OrderImageHandler';
import type { PayableWithOrder } from './PayablesReview';

type Props = {
    payables: PayableWithOrder[];
    onBackButtonClick: () => void;
    onBillUpdate: (updatedPayables: PayableWithOrder, onSuccess: () => boolean) => void;
};

const ReviewPayables = ({ payables, onBackButtonClick, onBillUpdate }: Props) => {
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const onReviewed = async (status: 'ready' | 'not') => {
        setIsLoading(true);
        if (status === 'ready') {
            const onSuccess = () => (payables[activeIndex].readyForPayment = true);
            onBillUpdate({ ...payables[activeIndex], readyForPayment: true }, onSuccess);
        }
        const nextIndex = activeIndex + 1;
        if (nextIndex === payables.length) {
            setTimeout(() => {
                onBackButtonClick();
            }, 3000);
        }
        setActiveIndex(nextIndex);
        setIsLoading(false);
    };

    const handleGoToOrder = (order: Order.AllianceOrderTransport) => {
        history.push(routes.orders.details(order.id), { modal: true });
    };

    if (isLoading) return <Loading />;

    if (activeIndex === payables.length) {
        return (
            <div className="container flex flex-col items-center justify-center p-4">
                <h1 className="text-5xl">Completed</h1>
                <div className="mt-4">
                    <button className="btn-primary" onClick={() => onBackButtonClick()} type="button">
                        Go Back
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="container mx-auto h-full px-4 pt-6">
            <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2 flex">
                    <button className="btn-dark-grey-outlined mr-5" onClick={() => onBackButtonClick()}>
                        <ChevronLeftIcon className="mr-1 h-4 w-4" />
                        Go Back
                    </button>
                    <h6 className="text-xl font-medium">Review Bills</h6>
                </div>
                <div>
                    <OrderImageHandler order={payables[activeIndex].order} inline />
                </div>
                <div className="flex flex-col gap-4">
                    <div className="col-span-2">
                        {activeIndex + 1} of {payables.length}
                    </div>
                    <div className="col-span-2">
                        <button className="btn-secondary-text-only" onClick={() => handleGoToOrder(payables[activeIndex].order)}>
                            {`Go to Order - ${payables[activeIndex].order.orderNumber}`}
                        </button>
                    </div>
                    <div className="col-span-2">
                        <p>{`Address: ${formatServiceAddress(payables[activeIndex].order.serviceLocation.address)}`}</p>
                    </div>
                    <div className="col-span-2">
                        <p>{`Vendor: ${payables[activeIndex].order.vendorName}`}</p>
                    </div>
                    <div className="col-span-2">
                        <p>{`Order Status: ${Order.SourgumOrderStatusLabels[payables[activeIndex].order.status]}`}</p>
                    </div>
                    <div className="col-span-2">
                        <p>{`Bill Number: ${payables[activeIndex].invoiceDetails.invoiceNumber}`}</p>
                    </div>
                    <div className="col-span-2">
                        <p>{`Tons Dumped: ${payables[activeIndex].order.actualWeightDumped?.value}`}</p>
                    </div>
                    <div className="col-span-2">
                        <p>Bill Items</p>
                    </div>

                    <div className="grid max-w-xs grid-cols-2 gap-4">
                        {payables[activeIndex]?.invoiceDetails.lineItems.map((item: Invoice.LineItemTransport) => (
                            <React.Fragment key={item.id}>
                                <div>
                                    <p className="text-right">
                                        {Object.keys(haulerChargeItems).includes(item.itemName)
                                            ? haulerChargeItems[item.itemName]
                                            : item.itemName}
                                        :
                                    </p>
                                </div>
                                <div>
                                    <p>{item?.totalPrice ? moneyFormatter(item?.totalPrice) : 0}</p>
                                </div>
                            </React.Fragment>
                        ))}
                        <div className="text-right">
                            <p>Total Due:</p>
                        </div>
                        <div>
                            <p>{moneyFormatter(payables[activeIndex].invoiceDetails.total)}</p>
                        </div>
                    </div>

                    <div className="col-span-2 mt-auto flex justify-end text-right">
                        <button className="btn-dark-grey-outlined mr-5" onClick={() => onReviewed('not')}>
                            NOT Ready
                        </button>
                        <button className="btn-primary" onClick={() => onReviewed('ready')}>
                            Ready for Payment
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewPayables;
