import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Order } from '@alliance-disposal/transport-types';
import { DatePicker, Dialog, Loading, Select, SelectOption, Tooltip } from '@wayste/sour-ui';
import { formatDateToYYYYMMDD, formatServiceAddress } from '@wayste/utils';
import { Combobox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { isAxiosError } from 'axios';
import { addDays, format } from 'date-fns';
import { UIContext } from '../../../contexts';
import { OrderStatus, OrderStatusText, SwitchType } from '../../../utils/shared-types';

const CreateManualSwitchForm = ({ selectedOrder, handleClose }: CreateManualSwitchModalProps) => {
    const { showFlash, godModeActive } = useContext(UIContext);
    const selectedOrderType =
        selectedOrder.status === OrderStatus.ON_HOLD ||
        selectedOrder.status === 'UNASSIGNED' ||
        selectedOrder.status === 'ASSIGNED' ||
        selectedOrder.status === 'DELIVERED'
            ? 'DELIVERY'
            : 'PICKUP';
    const [orders, setOrders] = useState<Order.AllianceOrderTransport[]>([]);
    const [loading, setLoading] = useState(false);
    const [pickupOrder, setPickupOrder] = useState<Order.AllianceOrderTransport>();
    const [deliveryOrder, setDeliveryOrder] = useState<Order.AllianceOrderTransport>();
    const [switchDate, setSwitchDate] = useState<Date>(new Date());
    const [switchType, setSwitchType] = useState<keyof typeof SwitchType | ''>('');
    const client = useWaysteClient();

    useEffect(() => {
        const queryOrders = async () => {
            setLoading(true);
            if (selectedOrderType === 'DELIVERY') {
                setDeliveryOrder(selectedOrder);
            } else {
                setPickupOrder(selectedOrder);
                if (selectedOrder.expectedPickupDate) setSwitchDate(new Date(selectedOrder.expectedPickupDate));
            }

            const orders = await client.order().adminPortal.query({
                allianceCustomerID: selectedOrder.allianceCustomerID,
                status: [
                    'UNASSIGNED',
                    'ON_HOLD',
                    'ASSIGNED',
                    'DELIVERED',
                    'READY_FULL_PICKUP',
                    'READY_EMPTY_PICKUP',
                    'PICKED_UP_FULL',
                    'PICKED_UP_EMPTY',
                    'DUMPED',
                    'COMPLETED',
                ].join(','),
                limit: 100,
                offset: 0,
                sortBy: 'orderNumber',
                sortDescending: true,
            });

            const oderData = orders.results as Order.AllianceOrderTransport[];

            setOrders(
                oderData.filter(
                    (x) => formatServiceAddress(x.serviceLocation.address) === formatServiceAddress(selectedOrder.serviceLocation.address),
                ),
            );

            setLoading(false);
        };
        queryOrders();
    }, []);

    const pickupOptions = useMemo(() => {
        return godModeActive
            ? orders.filter(
                  (order) =>
                      selectedOrder.id !== order.id &&
                      (!order.switches ||
                          order.switches.length === 0 ||
                          order.switches.length >= 2 ||
                          !!order.switches.find((y) => y.fromOrder === y.id)),
              )
            : orders.filter(
                  (order) =>
                      selectedOrder.id !== order.id &&
                      order.status !== 'UNASSIGNED' &&
                      order.status !== 'ASSIGNED' &&
                      order.status !== 'DELIVERED' &&
                      (!order.switches ||
                          order.switches.length === 0 ||
                          order.switches.length >= 2 ||
                          !!order.switches.find((y) => y.fromOrder === y.id)),
              );
    }, [orders, godModeActive]);

    const deliveryOptions = useMemo(() => {
        return godModeActive
            ? orders.filter(
                  (order) =>
                      selectedOrder.id !== order.id &&
                      (order.status === 'UNASSIGNED' || order.status === 'ASSIGNED' || order.status === 'DELIVERED') &&
                      (!order.switches ||
                          order.switches.length === 0 ||
                          order.switches.length >= 2 ||
                          !!order.switches.find((y) => y.fromOrder === y.id)),
              )
            : orders.filter(
                  (order) =>
                      selectedOrder.id !== order.id &&
                      (!order.switches ||
                          order.switches.length === 0 ||
                          order.switches.length >= 2 ||
                          !!order.switches.find((y) => y.fromOrder === y.id)),
              );
    }, [orders, godModeActive]);

    return (
        <form
            className="grid grid-cols-1 gap-2"
            onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();

                setLoading(true);
                if (!deliveryOrder?.haulerID && !pickupOrder?.haulerID) return;

                if (!deliveryOrder?.haulerID && pickupOrder?.haulerID) {
                    try {
                        if (!deliveryOrder?.id) {
                            throw new Error('No delivery order ID');
                        }
                        const res = await client.order().adminPortal.update(deliveryOrder?.id, {
                            status: OrderStatus.ASSIGNED,
                            haulerID: pickupOrder.haulerID,
                            expectedDeliveryDate: formatDateToYYYYMMDD(switchDate),
                        });
                        console.log('res deliv', res);
                    } catch (error) {
                        alert('An error has occurred while updating the delivery order. Try again or contact dev.');
                        setLoading(false);
                        return;
                    }
                }
                try {
                    if (!pickupOrder?.id) {
                        throw new Error('No pickup order ID');
                    }
                    const res = await client.order().adminPortal.update(pickupOrder?.id, {
                        expectedPickupDate: formatDateToYYYYMMDD(switchDate),
                    });

                    console.log('res pickup', res);
                } catch (error) {
                    alert('An error has occurred while updating the pickup order. Try again or contact dev.');
                    setLoading(false);
                    return;
                }
                if (pickupOrder?.haulerID && deliveryOrder?.haulerID) {
                    try {
                        await client.order().adminPortal.addSwitch({
                            fromOrder: pickupOrder?.id,
                            toOrder: deliveryOrder?.id,
                            type: SwitchType.DUMP_AND_RETURN,
                        });
                    } catch (error) {
                        if (isAxiosError(error) && error.response) {
                            showFlash(error.response.data.additionalInfo || error.response.data.message, 'warning');
                        } else {
                            showFlash('An error has occurred trying to create manual switch please try again.', 'warning');
                        }
                        setLoading(false);
                        return;
                    }
                    setLoading(false);
                    showFlash('Manual link successfully created.', 'success');
                    handleClose();
                }

                setLoading(false);
                showFlash('Manual link successfully created.', 'success');
                handleClose();
            }}
        >
            <div>
                <div>Delivery order</div>
                <Combobox
                    as="div"
                    className="relative"
                    value={deliveryOrder}
                    onChange={setDeliveryOrder}
                    disabled={!godModeActive && selectedOrderType === 'DELIVERY'}
                >
                    <Combobox.Input
                        className="focus:ring-sourgum-green-400 focus:border-sourgum-green-400 w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus:ring-1 sm:text-sm"
                        displayValue={(order: Order.AllianceOrderTransport) => (order ? `Order# ${order.orderNumber}` : '')}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Combobox.Button>
                    <Transition as={React.Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {deliveryOptions.map((option) =>
                                !option.haulerID || !option.haulerID || option.haulerID === selectedOrder.haulerID ? (
                                    <Combobox.Option
                                        key={option.id}
                                        value={option}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                active ? 'bg-sourgum-green-400 text-white' : 'text-gray-900'
                                            }`
                                        }
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                    Order# {option.orderNumber}
                                                </span>
                                                {selected ? (
                                                    <span
                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                            active ? 'text-white' : 'text-sourgum-green-400'
                                                        }`}
                                                    >
                                                        {/* Icon or checkmark for selected item */}
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ) : (
                                    <Combobox.Option
                                        key={option.id}
                                        value={option}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                active ? 'bg-sourgum-green-400 text-white' : 'text-gray-900'
                                            }`
                                        }
                                    >
                                        <>
                                            <Tooltip
                                                text={
                                                    <h1 style={{ fontSize: '0.96rem' }}>
                                                        {
                                                            'You can only link with same hauler / no hauler for now. You can remove the hauler from one of the orders or assign both orders to the same hauler and try again.'
                                                        }
                                                    </h1>
                                                }
                                            >
                                                <p style={{ width: '100vh' }}>
                                                    {'Order# ' + option?.orderNumber}
                                                    <br />
                                                    {'Status: ' + OrderStatusText[option?.status as keyof typeof OrderStatusText]}
                                                    <br />
                                                    {option.expectedPickupDate && (
                                                        <>{'Delivery Date: ' + format(new Date(option.expectedPickupDate), 'MM/dd/yy')}</>
                                                    )}
                                                </p>
                                            </Tooltip>
                                        </>
                                    </Combobox.Option>
                                ),
                            )}
                        </Combobox.Options>
                    </Transition>
                </Combobox>
            </div>
            <div>
                <div>Pickup order</div>
                <Combobox
                    as="div"
                    className="relative"
                    value={pickupOrder}
                    onChange={setPickupOrder}
                    disabled={!godModeActive && selectedOrderType === 'PICKUP'}
                >
                    <Combobox.Input
                        className="focus:ring-sourgum-green-400 focus:border-sourgum-green-400 w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus:ring-1 sm:text-sm"
                        displayValue={(order: Order.AllianceOrderTransport) => (order ? `Order# ${order.orderNumber}` : '')}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Combobox.Button>
                    <Transition
                        as={React.Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => {
                            /* Handle after leave if necessary */
                        }}
                    >
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {pickupOptions.map((option) => (
                                <Combobox.Option
                                    key={option.id}
                                    value={option}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active ? 'bg-sourgum-green-400 text-white' : 'text-gray-900'
                                        }`
                                    }
                                >
                                    {({ selected, active }) => (
                                        <>
                                            <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                Order# {option.orderNumber}
                                            </span>
                                            {selected ? (
                                                <span
                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                        active ? 'text-white' : 'text-sourgum-green-400'
                                                    }`}
                                                >
                                                    {/* Icon or checkmark for selected item */}
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    </Transition>
                </Combobox>
            </div>
            <div>
                <Select value={switchType} label="Switch Type" onSelect={(e) => setSwitchType((e as keyof typeof SwitchType) || '')}>
                    {[SwitchType.DUMP_AND_RETURN].map((x) => {
                        return (
                            <SelectOption key={x} value={x}>
                                {x === 'DUMP_AND_RETURN' ? 'Dump and Return' : x === 'SWAP' ? 'Swap' : x}
                            </SelectOption>
                        );
                    })}
                </Select>
            </div>
            <div>
                <DatePicker
                    value={switchDate}
                    onChange={(e) => {
                        if (!e) return;
                        setSwitchDate(e);
                    }}
                    minDate={godModeActive ? undefined : addDays(new Date(), -7)}
                />
                <p className="text-sm text-gray-500">
                    You can only create switches for up to 7 days in the past. If you need to create a switch for a date further in the
                    past, please contact an Admin.
                </p>
            </div>
            <div>
                <button className="btn-primary w-full" type="submit" disabled={loading}>
                    Create Switch
                    {loading && <Loading className="text-white" size="h-4 w-4 ml-2" />}
                </button>
            </div>
        </form>
    );
};
export type CreateManualSwitchModalProps = {
    open: boolean;
    selectedOrder: Order.AllianceOrderTransport;
    handleClose: () => void;
};
const CreateManualSwitchModal = (props: CreateManualSwitchModalProps) => {
    return (
        <Dialog open={props.open} onClose={props.handleClose} styledTitle="Create Manual Switch">
            <CreateManualSwitchForm {...props} />
        </Dialog>
    );
};
export default CreateManualSwitchModal;
