import { useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { CurrencyTextField, DatePicker, Dialog, Loading, Select, SelectOption, TextField, Toggle, Tooltip } from '@wayste/sour-ui';
import { getPrimaryContact, moneyFormatter, round } from '@wayste/utils';
import { Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { NumericFormat } from 'react-number-format';
import ChargeCard2 from '../../../../components/card-payment/ChargeCard2';
import { everyPaymentMethods, paymentMethodsEnums } from '../../../../utils/shared-types';
import CustomerAccountCredit from './CustomerAccountCredit';

export interface TransactionPaymentsType {
    orderId: string;
    orderNumber: string;
    invoice: string;
    invoiceID: string;
    total: number;
    paid: number;
    credit: number;
    value: number | '';
    paymentMethod?: string;
    serviceAddress: string;
}

export interface CleanTransactionPaymentsType {
    item: {
        paymentMethod: string;
        amount: number;
        paymentReceivedDate: Date;
        paymentIdentifier?: string | null;
        stripeChargeID?: string | null;
    };
    orderId: string;
    invoice: string;
    invoiceID: string;
    serviceAddress: string;
    orderNumber: string;
}

const tableHeaders = [
    {
        heading: <CheckIcon className="size-4" />,
        className: 'px-2.5 max-w-[18px]',
    },
    { heading: 'Order - Invoice' },
    { heading: 'Orig. Amt.' },
    { heading: 'Amt. Due' },
    { heading: 'Payment Amt.' },
];

interface Props {
    customer: Customer.AllianceCustomerTransport;
    onCancel: () => void;
    open: boolean;
    isLoading: boolean;
    totalPaymentCents: number;
    invoiceTotals: {
        total: number;
        totalDue: number;
        totalCredit: number;
    };
    paymentMethod: string;
    payments: TransactionPaymentsType[];
    onSetPaymentMethod: (value: string) => void;
    onUpdatePayment: (value: '' | number, index: number) => void;
    onSave: (cleanPayments: CleanTransactionPaymentsType[], sendRemittanceSummary: boolean, stripeId?: string) => void;
}

const TransactionCreate = ({
    customer,
    onSave,
    onCancel,
    open,
    totalPaymentCents,
    onUpdatePayment,
    invoiceTotals,
    paymentMethod,
    onSetPaymentMethod,
    payments,
    isLoading,
}: Props) => {
    const { useConfirmationDialog, setShowToast } = useSourContext();
    const { getConfirmation } = useConfirmationDialog();
    const [paymentDate, setPaymentDate] = useState<'' | Date>('');
    const [transactionID, setTransactionID] = useState('');
    const [achAmount, setAchAmount] = useState<number | ''>('');
    const [checkAmount, setCheckAmount] = useState<number | ''>('');
    const [accountCreditAmount, setAccountCreditAmount] = useState<number | ''>('');
    const [sendRemittanceSummary, setSendRemittanceSummary] = useState(false);

    const handleSave = async (stripeChargeID?: string, last4?: string, stripeId?: string) => {
        const checkAmountCents = Number(checkAmount);
        const accountCreditAmountCents = Number(accountCreditAmount);
        const achAmountCents = round(Number(achAmount));

        if (paymentMethod === paymentMethodsEnums.check && (checkAmountCents || 0) > totalPaymentCents) {
            const confirmed = await getConfirmation({
                title: 'Issue Credit',
                message:
                    'Issue Credit - save to customer object - Open confirmation window first to see if user wants to save and do that or change payments.',
            });
            if (!confirmed) {
                return;
            }
        } else if (
            (paymentMethod === paymentMethodsEnums.check && (checkAmountCents || 0) < totalPaymentCents) ||
            (paymentMethod === paymentMethodsEnums.accountCredit && (accountCreditAmountCents || 0) < totalPaymentCents) ||
            (paymentMethod === paymentMethodsEnums.ach && achAmountCents < totalPaymentCents)
        ) {
            setShowToast({
                severity: 'warning',
                message: 'Total Payments cannot be greater than Check/Credit/ACH Amount. Please check payment amounts.',
            });
            return;
        }
        const cleanedPayments: CleanTransactionPaymentsType[] = [];
        payments.forEach((payment) => {
            if (payment.value) {
                switch (payment.paymentMethod) {
                    case paymentMethodsEnums.accountCredit: {
                        cleanedPayments.push({
                            item: {
                                paymentMethod: paymentMethodsEnums.accountCredit,
                                amount: payment.value,
                                paymentReceivedDate: new Date(paymentDate),
                                paymentIdentifier: 'CREDIT',
                                stripeChargeID: null,
                            },
                            orderId: payment.orderId,
                            invoice: payment.invoice,
                            invoiceID: payment.invoiceID,
                            serviceAddress: payment.serviceAddress,
                            orderNumber: payment.orderNumber,
                        });
                        break;
                    }
                    case paymentMethodsEnums.ach: {
                        cleanedPayments.push({
                            item: {
                                paymentMethod: paymentMethodsEnums.ach,
                                amount: payment.value,
                                paymentReceivedDate: new Date(paymentDate),
                                paymentIdentifier: transactionID,
                                stripeChargeID: null,
                            },
                            orderId: payment.orderId,
                            invoice: payment.invoice,
                            invoiceID: payment.invoiceID,
                            serviceAddress: payment.serviceAddress,
                            orderNumber: payment.orderNumber,
                        });
                        break;
                    }
                    default:
                        {
                            const paymentIdentifier =
                                paymentMethod === paymentMethodsEnums.check || paymentMethod === paymentMethodsEnums.ach
                                    ? transactionID
                                    : last4
                                      ? last4
                                      : null;

                            const newPayment = {
                                stripeChargeID: stripeChargeID || null,
                                paymentReceivedDate: stripeChargeID ? new Date() : new Date(paymentDate),
                                amount: payment.value,
                                paymentMethod: paymentMethod,
                                paymentIdentifier,
                            };

                            if (payment.credit > 0) {
                                const creditPayment = {
                                    paymentReceivedDate: stripeChargeID ? new Date() : new Date(paymentDate),
                                    amount: payment.credit,
                                    paymentMethod: 'credit',
                                };

                                cleanedPayments.push({
                                    item: creditPayment,
                                    orderId: payment.orderId,
                                    invoice: payment.invoice,
                                    invoiceID: payment.invoiceID,
                                    serviceAddress: payment.serviceAddress,
                                    orderNumber: payment.orderNumber,
                                });
                            }

                            cleanedPayments.push({
                                item: newPayment,
                                orderId: payment.orderId,
                                invoice: payment.invoice,
                                invoiceID: payment.invoiceID,
                                serviceAddress: payment.serviceAddress,
                                orderNumber: payment.orderNumber,
                            });
                        }
                        break;
                }
            }
        });
        onSave(cleanedPayments, sendRemittanceSummary, stripeId);
    };

    const handleUpdatePayment = (value: number | '', index: number, payment: TransactionPaymentsType) => {
        if ((value || 0) > payment.total - payment.paid - payment.credit) {
            setShowToast({
                severity: 'warning',
                message: 'Cannot enter Payment greater than Due',
            });
        } else {
            onUpdatePayment(value, index);
        }
    };

    return (
        <Dialog
            open={open}
            styledTitle={`Manage Payments for ${
                customer?.companyName || getPrimaryContact(customer)?.firstName + ' ' + getPrimaryContact(customer)?.lastName
            }`}
            fullScreen
            onClose={onCancel}
        >
            <div className="flex justify-between">
                <div className="flex flex-1 gap-7">
                    <div className="max-w-[200px]">
                        <DatePicker label="Payment date" value={paymentDate} onChange={setPaymentDate} />
                    </div>
                    <div className="max-w-[200px]">
                        <Select
                            value={paymentMethod}
                            label="Payment Method"
                            onSelect={(value) => {
                                onSetPaymentMethod(value);
                                value === paymentMethodsEnums.creditCard ? setSendRemittanceSummary(false) : setSendRemittanceSummary(true);
                            }}
                        >
                            {Object.keys(paymentMethodsEnums).map((item, index) => (
                                <SelectOption key={item + index} value={item}>
                                    {everyPaymentMethods[item as keyof typeof everyPaymentMethods]}
                                </SelectOption>
                            ))}
                        </Select>
                    </div>
                    {(paymentMethod === paymentMethodsEnums.check || paymentMethod === paymentMethodsEnums.ach) && (
                        <div className="w-[200px]">
                            <TextField
                                label={paymentMethod === paymentMethodsEnums.ach ? 'Transaction ID' : 'Check number'}
                                inputProps={{
                                    value: transactionID,
                                    onChange: (e) => setTransactionID(e.target.value),
                                }}
                            />
                        </div>
                    )}
                    {paymentMethod === paymentMethodsEnums.check && (
                        <div className="w-[200px]">
                            <CurrencyTextField
                                value={checkAmount}
                                onChange={(value) => setCheckAmount(value)}
                                label="Check amount"
                                useCents
                            />
                        </div>
                    )}
                    {paymentMethod === paymentMethodsEnums.accountCredit && (
                        <div className="w-[200px]">
                            <CurrencyTextField
                                value={accountCreditAmount}
                                useCents
                                onChange={(value) => {
                                    if ((value as number) > customer.accountCredit) {
                                        setShowToast({
                                            severity: 'warning',
                                            message: 'Cannot enter Payment greater than Account Credit',
                                        });
                                    } else if ((value as number) < 0) {
                                        setShowToast({
                                            severity: 'warning',
                                            message: 'Cannot enter negative value',
                                        });
                                    } else {
                                        setAccountCreditAmount(value);
                                    }
                                }}
                                label="Credit amount"
                                helperText={`Available Balance ${moneyFormatter(customer.accountCredit)}`}
                            />
                        </div>
                    )}

                    {paymentMethod === paymentMethodsEnums.ach && (
                        <div className="w-[200px]">
                            <CurrencyTextField useCents value={achAmount} onChange={(value) => setAchAmount(value)} label="ACH amount" />
                        </div>
                    )}
                    <Tooltip
                        text="You still need to manually send the payment receipt. This sends an additional remittance summary to the customer via email in an Excel sheet attachment. This should NOT be used for most Credit Card payments."
                        delay={10}
                    >
                        <Toggle
                            label="Send remittance summary"
                            value={sendRemittanceSummary}
                            onChange={() => setSendRemittanceSummary(!sendRemittanceSummary)}
                        />
                    </Tooltip>
                </div>
                {/* Account Credit Handler */}
                <CustomerAccountCredit total={customer.accountCredit} />
            </div>
            <div className="w-full overflow-x-auto">
                <table className="w-full border-collapse border-spacing-0 text-sm">
                    <thead>
                        <tr className="align-middle [&>*]:px-4 [&>*]:py-1.5">
                            {tableHeaders.map((heading) => (
                                <td
                                    key={typeof heading.heading === 'string' ? heading.heading : 'check'}
                                    className={heading.className || ''}
                                >
                                    {heading.heading}
                                </td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading && (
                            <tr className="[&>*]:border [&>*]:py-1.5 [&>*]:pl-1.5 [&>*]:pr-2.5">
                                <td colSpan={5} className="text-center">
                                    <div className="flex w-full justify-center">
                                        <Loading size="h-10 w-10" />
                                    </div>
                                </td>
                            </tr>
                        )}
                        {payments.length === 0 && !isLoading ? (
                            <tr className="[&>*]:border [&>*]:py-1.5 [&>*]:pl-1.5 [&>*]:pr-2.5">
                                <td colSpan={5} className="text-center">
                                    No payments to display
                                </td>
                            </tr>
                        ) : null}
                        {payments.map((payment, index) => (
                            <tr
                                key={payment.orderNumber + '-' + payment.invoice}
                                className="[&>*]:border [&>*]:py-1.5 [&>*]:pl-1.5 [&>*]:pr-2.5"
                                style={{
                                    backgroundColor: index % 2 ? '#F5F5F5' : '#FFFFFF',
                                }}
                            >
                                <td
                                    className="max-w-[18px] px-2.5 text-center"
                                    onClick={() => {
                                        if (payment.total - payment.paid - payment.credit !== 0) {
                                            payment.value
                                                ? handleUpdatePayment('', index, payment)
                                                : handleUpdatePayment(payment.total - payment.paid - payment.credit, index, payment);
                                        }
                                    }}
                                >
                                    {payment.value ? <CheckIcon className="size-4" /> : null}
                                </td>
                                <td className="!pl-4">
                                    {payment.orderNumber} - {payment.invoice}
                                </td>
                                <td className="max-w-40">
                                    <div className="flex justify-between">
                                        <div>$</div>
                                        {moneyFormatter(payment.total, { hideDollarSign: true })}
                                    </div>
                                </td>
                                <td className="max-w-40">
                                    <div className="flex justify-between">
                                        <div>$</div>
                                        {moneyFormatter(payment.total - payment.paid, { hideDollarSign: true })}
                                        {payment.credit > 0 ? (
                                            <div> - {moneyFormatter(payment.credit, { hideDollarSign: true })}</div>
                                        ) : null}
                                    </div>
                                </td>
                                <td className="max-w-40">
                                    <div className="flex justify-between">
                                        <div>$</div>
                                        <NumericFormat
                                            value={+payment.value / 100}
                                            thousandSeparator={true}
                                            onValueChange={(values) => {
                                                const { value } = values;
                                                handleUpdatePayment(+value * 100, index, payment);
                                            }}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            className="w-full bg-transparent text-right outline-none"
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                        <tr className="[&>*]:py-1.5 [&>*]:pl-1.5 [&>*]:pr-2.5">
                            <td colSpan={2} className="text-right">
                                Totals
                            </td>
                            <td className="max-w-40">
                                <div className="flex justify-between">
                                    <div>$</div>
                                    {moneyFormatter(invoiceTotals.total, { hideDollarSign: true })}
                                </div>
                            </td>
                            <td className="max-w-40">
                                <div className="flex justify-between">
                                    <div>$</div>
                                    {moneyFormatter(invoiceTotals.totalDue, { hideDollarSign: true })}
                                </div>
                            </td>
                            <td className="max-w-40">
                                <div className="flex justify-between">
                                    <div>$</div>
                                    {moneyFormatter(totalPaymentCents, { hideDollarSign: true })}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="mt-5">
                <Transition
                    show={paymentMethod === paymentMethodsEnums.creditCard}
                    enter="transition ease-in-out duration-500 transform"
                    enterFrom="opacity-0 translate-y-4"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in-out duration-500 transform"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-4"
                >
                    <ChargeCard2
                        total={totalPaymentCents}
                        customer={customer}
                        onChargeSuccessful={(stripeChargeID, last4, stripeId) => handleSave(stripeChargeID, last4, stripeId)}
                        className="min-w-fit max-w-screen-md rounded p-2 shadow"
                    />
                </Transition>
            </div>
            <div className="pt-14">
                <button className="btn-dark-grey-outlined mr-4" onClick={onCancel} disabled={isLoading} type="button">
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn-primary"
                    onClick={() => handleSave()}
                    disabled={
                        isLoading ||
                        !paymentDate ||
                        totalPaymentCents <= 0 ||
                        (paymentMethod === paymentMethodsEnums.check && (!transactionID || !checkAmount)) ||
                        (paymentMethod === paymentMethodsEnums.accountCredit && !accountCreditAmount) ||
                        (paymentMethod === paymentMethodsEnums.ach && (!transactionID || !achAmount))
                    }
                >
                    Save
                    {isLoading && <Loading className="text-sourgum-greyblue-900 ml-1" size="h-4 w-4" />}
                </button>
            </div>
        </Dialog>
    );
};

export default TransactionCreate;
