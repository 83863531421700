import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Hauler, Invoice, UniversalService } from '@alliance-disposal/transport-types';
import { Loading } from '@wayste/sour-ui';
import { generatePayablesAndReceivable, sanitizeFromPayload } from '@wayste/utils';
import { formatDateToYYYYMMDD } from '@wayste/utils/dist/formatter/date-formatter';
import { ChevronLeftIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import CustomerDetailsCard from '../../../components/CustomerDetailsCard';
import DetailsCardWrapper from '../../../components/DetailsCardWrapper';
import PayablesTableCard from '../../../components/PayablesTableCard/PayablesTableCard';
import { UIContext, useConfirmationDialog } from '../../../contexts';
import { routes } from '../../../utils';
import { repList } from '../../../utils/shared-types';
import UniversalServicesCancelDialog from '../components/UniversalServicesCancelDialog/UniversalServicesCancelDialog';
import { AddServiceEventDialog } from './AddEventDialog';
import OneTimeBillingDetailsCard from './OneTime/OneTimeBillingDetailsCard';
import OneTimeDetailsCard from './OneTime/OneTimeDetailsCard';
import OneTimeServiceDetailsCard from './OneTime/OneTimeServiceDetailsCard';
import SubscriptionBillingDetailsCard from './Subscription/SubscriptionBillingDetailsCard';
import SubscriptionDetailsCard from './Subscription/SubscriptionDetailsCard';
import SubscriptionServiceDetailsCard from './Subscription/SubscriptionServiceDetailsCard';

const ServiceOrderDetails = () => {
    const client = useWaysteClient();
    const history = useHistory();
    const { getConfirmation } = useConfirmationDialog();
    const { showFlash } = useContext(UIContext);
    const { serviceGroupingId, serviceOrderId } = useParams<{ serviceGroupingId: string; serviceOrderId: string }>();
    const [cancelOpen, setCancelOpen] = useState<boolean>(false);
    const [showAddServiceEventDialog, setAddServiceEventDialog] = useState<boolean>(false);
    const [customer, setCustomer] = useState<Customer.AllianceCustomerTransport | undefined>(undefined);
    const [vendor, setVendor] = useState<Hauler.HaulerWithAapTransport | undefined>(undefined);
    const [serviceGrouping, setServiceGrouping] = useState<UniversalService.ServiceGrouping | undefined>();
    const [serviceOrder, setServiceOrder] = useState<UniversalService.ServiceOrder | undefined>();
    const [receivables, setReceivables] = useState<Invoice.ReceivableTransport[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [lastUpdatedBy, setLastUpdatedBy] = useState<string>('');
    const [createdBy, setCreatedBy] = useState<string>('');
    const [salesRep, setSalesRep] = useState<string>('');

    useEffect(() => {
        fetchServiceOrder(serviceGroupingId);
    }, [serviceGroupingId]);

    const fetchServiceOrder = async (serviceGroupingId: string) => {
        setLoading(true);
        try {
            const serviceGroupingResponse = await client.universalService().serviceGrouping.fetch(serviceGroupingId);

            // find the service order
            const serviceOrderResponse = serviceGroupingResponse.serviceOrders.find((order) => order.id === serviceOrderId);

            if (!serviceOrderResponse) {
                throw new Error('Service order not found');
            }

            if (serviceOrderResponse.vendorID) {
                const vendor = await client.vendorService().fetchById(serviceOrderResponse.vendorID);
                setVendor(vendor.data);
            }
            fetchCustomer(serviceOrderResponse.customerID);
            setServiceGrouping(serviceGroupingResponse);
            setServiceOrder(serviceOrderResponse);
            const lastUpdatedByUser = repList.find((user) => user.id === serviceGroupingResponse?.metadata.lastUpdatedByUserID);
            const createdByUser = repList.find((user) => user.id === serviceGroupingResponse?.metadata.createdByUserID);
            const salesRepUser = repList.find((user) => user.id === serviceGroupingResponse?.contractDetails?.salesRepID);
            if (lastUpdatedByUser !== undefined) {
                setLastUpdatedBy(lastUpdatedByUser?.firstName + ' ' + lastUpdatedByUser?.lastName);
            }
            if (createdByUser !== undefined) {
                setCreatedBy(createdByUser?.firstName + ' ' + createdByUser?.lastName);
            }
            if (salesRepUser !== undefined) {
                setSalesRep(salesRepUser?.firstName + ' ' + salesRepUser?.lastName);
            }
            const receivables = await client.invoice().adminPortal.receivable.query({
                receivableWithLineItemWithServiceOrderID: serviceOrderId,
            });
            setReceivables(receivables.results);
        } catch (err) {
            console.warn(err);
            showFlash('Error fetching service order', 'warning');
        } finally {
            setLoading(false);
        }
    };

    const fetchCustomer = async (customerId: string) => {
        try {
            const customerResponse = await client.customer().adminPortal.fetch(customerId);
            setCustomer(customerResponse);
        } catch (error) {
            console.warn(error);
            showFlash('Error fetching customer', 'warning');
        }
    };

    const handleCancel = async (cancelServiceOrderPayload: UniversalService.CancellationDetails) => {
        if (
            receivables.some((item: Invoice.ReceivableTransport) => item?.invoiceDetails.total - item?.invoiceDetails.remainingBalance > 0)
        ) {
            const confirmed = await getConfirmation({
                title: 'Order has Paid Invoices',
                message:
                    'This order has invoices that were paid. If you already issued any necessary refunds and the order is ready to be canceled click Cancel Order. If you did NOT already issue any refunds or are unsure if any should be issued click Go to Invoice',
                confirmText: 'Cancel Order',
                cancelText: 'Go to Invoice',
            });
            if (!confirmed) {
                history.push({
                    pathname: routes.billingProduct.list,
                    search: `?serviceGroupingID=${serviceGroupingId}&serviceOrderID=${serviceOrderId}`,
                });
                return;
            }
        } else if (
            receivables.every(
                (item: Invoice.ReceivableTransport) =>
                    !(item?.invoiceDetails.total - item?.invoiceDetails.remainingBalance) ||
                    item?.invoiceDetails.total - item?.invoiceDetails.remainingBalance === 0,
            )
        ) {
            const confirmedVoid = await getConfirmation({
                title: 'Void All Order Invoices?',
                message:
                    "Do you want to void every invoice for this order? If you are unsure or only want to void some of them go to the order's invoices and confirm before cancelling the order.",
                confirmText: 'Void All & Cancel Order',
                cancelText: 'Cancel & Not Void',
            });
            if (!confirmedVoid) {
                history.push({
                    pathname: routes.billingProduct.list,
                    search: `?serviceGroupingID=${serviceGroupingId}&serviceOrderID=${serviceOrderId}`,
                });
                return;
            }
            if (confirmedVoid) {
                for (const receivable of receivables) {
                    if (!receivable.invoiceDetails.payments || receivable.invoiceDetails.payments.length === 0) {
                        await client.invoice().adminPortal.receivable.update(receivable.id, { invoiceDetails: { void: true } });
                    }
                }
            }
        }

        const updatePayload: UniversalService.ServiceOrderUpdate = {
            ...serviceOrder,
            vendorID: serviceOrder?.vendorID || undefined, // vendorID must match format 'UUID' or undefined, not ''
            cancellationDetails: cancelServiceOrderPayload,
        };

        if (serviceOrder?.subscriptionDetails) {
            updatePayload.subscriptionDetails = {
                ...serviceOrder.subscriptionDetails,
                active: false,
                endDate: formatDateToYYYYMMDD(new Date()),
                periodicEvents: serviceOrder.subscriptionDetails.periodicEvents.map((event) => {
                    return {
                        ...event,
                        status: 'cancelled',
                    };
                }),
            };
        }

        try {
            await client
                .universalService()
                .serviceGrouping.serviceOrder.update(
                    serviceGroupingId,
                    serviceOrderId,
                    sanitizeFromPayload(updatePayload, 'accountManagerID'),
                );

            showFlash(
                serviceOrder?.subscriptionDetails ? 'Successfully Cancelled Subscription' : 'Successfully Cancelled Service Order',
                'success',
            );
            fetchServiceOrder(serviceGroupingId);
            setCancelOpen(false);
        } catch (error) {
            console.warn(error);
            showFlash('Error Cancelling' + serviceOrder?.subscriptionDetails ? ' Subscription' : ' Service Order', 'warning');
        }
    };

    const handleGenerateInvoice = async () => {
        if (!serviceGrouping?.invoiceGroupings[0] || !serviceOrder) return;

        const latestInvoiceGrouping = serviceGrouping.invoiceGroupings.reduce((prev, current) => {
            return prev.metadata.createdAt > current.metadata.createdAt ? prev : current;
        }, serviceGrouping.invoiceGroupings[0]);

        const invoice = generatePayablesAndReceivable(latestInvoiceGrouping, serviceGrouping, {
            customer: customer,
        });

        if (!invoice.receivable) {
            showFlash('No Invoice to Generate.', 'warning');
            return;
        }

        try {
            setLoading(true);
            await client.invoice().adminPortal.receivable.create(invoice.receivable);
        } catch (error) {
            showFlash('Error generating invoice', 'warning');
        } finally {
            setLoading(false);
        }
    };

    const oneTime =
        serviceGrouping?.type === 'single' && serviceGrouping && serviceOrder ? (
            <div className="mt-25 space-y-2">
                <DetailsCardWrapper
                    heading={'Order # ' + serviceOrder?.fullOrderNumber}
                    buttons={[
                        {
                            label: 'Cancel Order',
                            onClick: () => setCancelOpen(true),
                            disabled: serviceOrder?.cancellationDetails || serviceOrder.status == 'COMPLETED' ? true : false,
                            props: {
                                className: 'bg-delete hover:bg-red-700',
                            },
                        },
                    ]}
                />

                <UniversalServicesCancelDialog
                    variant="delete"
                    type="oneTime"
                    cancelOpen={cancelOpen}
                    setCancelOpen={setCancelOpen}
                    onCancel={handleCancel}
                />
                <OneTimeServiceDetailsCard
                    serviceOrder={serviceOrder}
                    serviceGrouping={serviceGrouping}
                    vendor={vendor ? vendor.name : undefined}
                    lastUpdatedBy={lastUpdatedBy}
                    createdBy={createdBy}
                />
                <OneTimeDetailsCard serviceOrder={serviceOrder} />
                {customer && <CustomerDetailsCard customer={customer} />}
                <PayablesTableCard serviceOrderIds={[serviceOrder.id]} serviceGrouping={serviceGrouping} />
                <OneTimeBillingDetailsCard serviceOrder={serviceOrder} serviceGrouping={serviceGrouping} />
            </div>
        ) : null;

    const subscription =
        serviceGrouping?.type !== 'single' && serviceGrouping && serviceOrder ? (
            <div className="space-y-2">
                <AddServiceEventDialog
                    open={showAddServiceEventDialog}
                    onClose={() => {
                        setAddServiceEventDialog(false);
                    }}
                    serviceGrouping={serviceGrouping}
                    serviceOrder={serviceOrder}
                />
                <DetailsCardWrapper
                    heading={'Order # ' + serviceOrder?.fullOrderNumber}
                    buttons={[
                        {
                            label: <>Cancel Subscription</>,
                            onClick: () => setCancelOpen(true),
                            disabled: serviceOrder?.subscriptionDetails ? !serviceOrder.subscriptionDetails.active : true,
                            props: {
                                className: 'bg-error-dark',
                            },
                        },
                        {
                            label: 'Generate Invoice',
                            onClick: () => {
                                handleGenerateInvoice();
                            },
                        },
                        {
                            label: 'Add Service Events',
                            onClick: () => {
                                setAddServiceEventDialog(true);
                            },
                        },
                    ]}
                />

                <UniversalServicesCancelDialog
                    variant="delete"
                    type="subscription"
                    cancelOpen={cancelOpen}
                    setCancelOpen={setCancelOpen}
                    onCancel={handleCancel}
                />
                <SubscriptionServiceDetailsCard
                    serviceOrder={serviceOrder}
                    serviceGrouping={serviceGrouping}
                    vendor={vendor ? vendor.name : undefined}
                    lastUpdatedBy={lastUpdatedBy}
                    createdBy={createdBy}
                />
                <SubscriptionDetailsCard
                    serviceOrder={serviceOrder}
                    serviceGrouping={serviceGrouping}
                    contractDetails={serviceGrouping.contractDetails}
                    salesRep={salesRep ? salesRep : ''}
                />
                {customer && <CustomerDetailsCard customer={customer} />}
                <SubscriptionBillingDetailsCard serviceOrder={serviceOrder} />
            </div>
        ) : (
            <div>
                <div className="flex flex-col items-center justify-center rounded-md p-8 shadow-md">
                    <ExclamationCircleIcon className="text-error-dark size-12" />
                    <div className="text-xl">
                        Service Grouping is missing contract details.
                        <br />
                        Please contact your administrator.
                    </div>
                </div>
            </div>
        );

    if (!serviceGrouping && !loading) {
        return (
            <div className="size-full p-6 pt-5">
                <div className="flex justify-start">
                    <div className="mb-4 text-2xl">Service Order Details</div>
                </div>
                <div className="flex h-full flex-col items-center justify-center">
                    <div className="flex flex-col items-center justify-center rounded-md p-8 shadow-md">
                        <ExclamationCircleIcon className="text-error-dark size-12" />
                        <div className="text-xl">Service Order not found</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex-1 bg-slate-50">
            <div className="container mx-auto p-6 pt-5">
                <div className="flex items-center justify-start">
                    <NavLink to={routes.universalServices.serviceGrouping.details(serviceGroupingId)} className="text-primary-dark">
                        <button className="btn-icon">
                            <ChevronLeftIcon className="size-5" />
                        </button>
                    </NavLink>
                    <div className="text-2xl leading-none">
                        {serviceGrouping?.type === 'single' ? 'Grouping' : 'Subscription'} <span className="text-gray-400">/</span> Service
                        Order Details
                    </div>
                </div>
                {/* if loading show loading */}
                {loading ? (
                    <div className="flex h-full flex-col items-center justify-center">
                        <Loading />
                    </div>
                ) : // if serviceGrouping is single show oneTime else show subscription
                serviceGrouping?.type === 'single' ? (
                    oneTime
                ) : (
                    subscription
                )}
            </div>
        </div>
    );
};

export default ServiceOrderDetails;
