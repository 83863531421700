import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { InternalTicket, Profile } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Dialog, Select, SelectOption, TextField, Textarea, Toggle } from '@wayste/sour-ui';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Controller, useForm } from 'react-hook-form';

type CreateUpdateDialogProps = {
    open: boolean;
    onClose: () => void;
    tag?: InternalTicket.TagTransport;
    onSuccess: () => void;
};

const CreateUpdateDialog = ({ open, onClose, tag, onSuccess }: CreateUpdateDialogProps) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [isLoading, setIsLoading] = useState(false);

    const { control, handleSubmit, setValue, reset, watch } = useForm<InternalTicket.TagUpdate>({
        mode: 'all',
        defaultValues: {
            name: '',
            category: undefined,
            defaultAssignedTeam: undefined,
            description: '',
            backgroundColor: '',
            textColor: '',
            showToCustomer: false,
        },
    });

    const watchBackgroundColor = watch('backgroundColor');
    const watchTextColor = watch('textColor');

    useEffect(() => {
        if (tag) {
            reset({
                ...tag,
            });
        }
    }, [tag]);

    const handleClose = () => {
        reset();
        onClose();
    };

    const onFormSubmit = async (data: InternalTicket.TagUpdate) => {
        setIsLoading(true);
        const backendData = { ...data };
        backendData.backgroundColor = backendData.backgroundColor || undefined;
        backendData.textColor = backendData.textColor || undefined;
        if (!tag) {
            try {
                await client.internalTicket().adminPortal.tag.create(backendData as InternalTicket.TagCreate);
                onSuccess();
                setShowToast({ message: 'Tag created successfully', severity: 'success' });
            } catch (error) {
                console.warn('Tag create error: ', error);
                setShowToast({ message: 'Error creating tag', severity: 'error' });
            }
        } else {
            try {
                await client.internalTicket().adminPortal.tag.update(tag.id, backendData);
                onSuccess();
                setShowToast({ message: 'Tag updated successfully', severity: 'success' });
            } catch (error) {
                console.warn('Tag update error: ', error);
                setShowToast({ message: 'Error updating tag', severity: 'error' });
            }
        }
        setIsLoading(false);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} styledTitle={`${tag ? 'Update' : 'Create New'} Tag`}>
            <form onSubmit={handleSubmit(onFormSubmit)} className="flex flex-col gap-y-4">
                <Controller
                    control={control}
                    name="name"
                    rules={{
                        required: {
                            value: true,
                            message: 'A name is required',
                        },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Name"
                            required
                            error={fieldState.error}
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="category"
                    rules={{
                        required: {
                            value: true,
                            message: 'A category is required',
                        },
                    }}
                    render={({ field, fieldState }) => (
                        <Select
                            label="Category"
                            required
                            error={fieldState.error}
                            value={field.value}
                            onSelect={(value) => setValue('category', value as keyof typeof InternalTicket.TagCategoryLabels)}
                        >
                            {Object.entries(InternalTicket.TagCategoryLabels).map((item) => (
                                <SelectOption value={item[0]} key={item[0]}>
                                    {item[1]}
                                </SelectOption>
                            ))}
                        </Select>
                    )}
                />
                <Controller
                    control={control}
                    name="description"
                    render={({ field, fieldState }) => (
                        <Textarea
                            label="Description"
                            error={fieldState.error}
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="defaultAssignedTeam"
                    render={({ field, fieldState }) => (
                        <Select
                            label="Default assigned team"
                            error={fieldState.error}
                            value={field.value}
                            onSelect={(value) => setValue('defaultAssignedTeam', value as keyof typeof Profile.SourgumTeamLabels)}
                        >
                            {Object.entries(Profile.SourgumTeamLabels).map((item) => (
                                <SelectOption value={item[0]} key={item[0]}>
                                    {item[1]}
                                </SelectOption>
                            ))}
                        </Select>
                    )}
                />
                <div className="flex gap-2">
                    <Controller
                        control={control}
                        name="backgroundColor"
                        rules={{
                            pattern: {
                                value: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
                                message: 'Must be a six digit hex code',
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="Background color"
                                error={fieldState.error}
                                inputProps={{
                                    ...field,
                                    maxLength: 7,
                                }}
                                helperText="Leaving blank defaults to white"
                            />
                        )}
                    />
                    <div className="size-10 border border-black" style={{ backgroundColor: watchBackgroundColor }} />
                </div>
                <div className="flex gap-2">
                    <Controller
                        control={control}
                        name="textColor"
                        rules={{
                            pattern: {
                                value: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
                                message: 'Must be a six digit hex code',
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="Text color"
                                error={fieldState.error}
                                inputProps={{
                                    ...field,
                                    maxLength: 7,
                                }}
                                helperText="Leaving blank defaults to black"
                            />
                        )}
                    />
                    <div className="size-10 border border-black" style={{ backgroundColor: watchTextColor }} />
                </div>
                <div>
                    <div className="flex items-center gap-4">
                        <ExclamationTriangleIcon className="size-6 min-w-6 text-amber-600" />
                        <Controller
                            control={control}
                            name="showToCustomer"
                            render={({ field }) => (
                                <div>
                                    <Toggle
                                        label="Show to customer"
                                        value={field.value || false}
                                        onChange={(value) => setValue('showToCustomer', value)}
                                    />
                                    {field.value && (
                                        <div className="pt-1 text-xs text-red-600">
                                            If this is on, any ticket tagged with this tag will be visible to the customer via the Customer
                                            Portal
                                        </div>
                                    )}
                                </div>
                            )}
                        />
                    </div>
                </div>
                <div className="flex justify-end gap-4">
                    <Button className="btn-dark-grey-outlined" onClick={onClose} loading={isLoading}>
                        Cancel
                    </Button>
                    <Button className="btn-primary" type="submit" loading={isLoading}>
                        Submit
                    </Button>
                </div>
            </form>
        </Dialog>
    );
};

export default CreateUpdateDialog;
