import { useEffect } from 'react';
import { useContext, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Loading } from '@wayste/sour-ui';
import { useHistory, useParams } from 'react-router-dom';
import { UIContext } from '../../../../contexts';
import { ServicesForm } from '../../components/ServicesForm/ServicesForm';

const ServicesUpdate = () => {
    const client = useWaysteClient();
    const history = useHistory();
    const { serviceFamilyId } = useParams<{ serviceFamilyId: string }>();
    const { showFlash } = useContext(UIContext);
    const [isLoading, setIsLoading] = useState(false);
    const [serviceFamily, setServiceFamily] = useState<UniversalService.ServiceTypeFamily | undefined>(undefined);

    const handleSubmit = async (data: UniversalService.ServiceTypeFamilyUpsert, id?: string) => {
        if (id) {
            try {
                setIsLoading(true);
                await client.universalService().serviceFamily.update(id, data);
                showFlash('Service Successfully Updated', 'success');
                history.goBack();
            } catch (error) {
                showFlash('An Error Occurred Updating Service', 'warning');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleFetchByID = async (id: string) => {
        setIsLoading(true);
        try {
            const data = await client.universalService().serviceFamily.fetch(id);
            setServiceFamily(data);
        } catch (error) {
            showFlash('An Error Occurred Fetching Service', 'warning');
        }
        setIsLoading(false);
    };

    useEffect(() => {
        handleFetchByID(serviceFamilyId);
    }, []);

    if (isLoading) {
        return (
            <div className="container flex h-full items-center justify-center p-4">
                <Loading />
            </div>
        );
    }

    return (
        <div className="container mx-auto flex flex-col gap-10 px-3 pb-10 pt-7">
            <div className="w-full text-xl ">Update Service Family</div>
            <ServicesForm
                serviceFamily={serviceFamily}
                serviceFamilyID={serviceFamilyId}
                onCancel={() => history.goBack()}
                onSubmit={handleSubmit}
                isLoading={isLoading}
                onModified={() => handleFetchByID(serviceFamilyId)}
            />
        </div>
    );
};

export default ServicesUpdate;
