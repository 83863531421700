import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Hauler, Material, Order, Pricing } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Checkbox, Dialog, Radio } from '@wayste/sour-ui';
import { formatEmailDestination, getDispatchEmailsString, getPrimaryContact, getPrimaryCustomerContact } from '@wayste/utils';
import { sendEmail } from '../../../axios/ses';
import { getPublicLinkShortCode } from '../../../axios/wayste-backend';
import { UIContext } from '../../../contexts';
import { useAuthToken } from '../../../hooks/authhook';
import {
    createHaulerOrderCancellationEmail,
    createHaulerOrderConfirmationEmail,
    createHaulerPickupEmail,
    createOrderCancellationEmailSendGrid,
    createOrderConfirmationEmailSendGrid,
    createOrderReadyPickupEmailSendGrid,
    getCustomerCCAddresses,
    getCustomerToAndCCEmails,
    paymentMethodsEnums,
} from '../../../utils';

const StatusEnum: { [x in keyof typeof Order.SourgumOrderStatusLabels]: number } = {
    CANCELLED: -1,
    NEEDS_REVIEW: 0,
    UNASSIGNED: 0,
    ON_HOLD: 0,
    ASSIGNED: 2,
    DELIVERED: 3,
    READY_FULL_PICKUP: 4,
    READY_EMPTY_PICKUP: 4,
    PICKED_UP_FULL: 5,
    PICKED_UP_EMPTY: 5,
    DUMPED: 6,
    COMPLETED: 7,
};

export type ResendEmailModalProps = {
    open: boolean;
    handleClose: () => void;
    selectedOrder: Order.AllianceOrderTransport;
};

const ResendEmailModal = ({ selectedOrder, open, handleClose }: ResendEmailModalProps) => {
    const { token } = useAuthToken();
    const waysteClient = useWaysteClient();
    const client = useWaysteClient();
    const userProfile = client.user().get();
    const { showFlash } = useContext(UIContext);
    const { setShowAlert } = useSourContext();
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState<Customer.AllianceCustomerTransport>();
    const [hauler, setHauler] = useState<Hauler.HaulerWithAapTransport | undefined>();
    const [zonePricing, setZonePricing] = useState<Pricing.PricingTransport | undefined>();
    const [emailsToSend, setEmailsToSend] = useState<{
        customer: {
            orderConfirmation: boolean;
            confirmationWithPayment: boolean;
            deliveryTomorrowConfirmation: boolean;
            pickupConfirmation: boolean;
            readyPickupSwapConfirmation: boolean;
            cancellation: boolean;
        };
        hauler: {
            deliveryConfirmation: boolean;
            pickupConfirmation: boolean;
            switchConfirmation: boolean;
            cancellation?: {
                type: 'delay_pickup' | 'final_removal';
            };
        };
    }>({
        customer: {
            orderConfirmation: false,
            confirmationWithPayment: false,
            deliveryTomorrowConfirmation: false,
            pickupConfirmation: false,
            readyPickupSwapConfirmation: false,
            cancellation: false,
        },
        hauler: {
            deliveryConfirmation: false,
            pickupConfirmation: false,
            switchConfirmation: false,
            cancellation: undefined,
        },
    });
    const orderStatus = StatusEnum[selectedOrder.status as keyof typeof StatusEnum] || 0;
    const isSwitch = selectedOrder.switches && selectedOrder.switches.length >= 1 ? true : false;

    const handleGetPricing = async () => {
        const location = {
            ...selectedOrder.serviceLocation.coordinates,
            zip: selectedOrder.serviceLocation.address.zip,
            state: selectedOrder.serviceLocation.address.state,
        };
        try {
            const results = await waysteClient.pricing().adminPortal.location.query(location);
            if (!results || results.length === 0) {
                alert('There is no pricing zone for this location, no prohibited items string will be sent');
                return;
            }
            const publicPricing = results.filter((item) => item.type === 'PUBLIC');
            if (publicPricing.length > 1) {
                alert('Get an AAP Dev pricing overlap');
                return;
            }
            setZonePricing(publicPricing[0]);
        } catch (error) {
            console.warn('handleGetPricing Error: ', error);
            alert('An error occurred, get an AAP dev, touch nothing');
        }
    };

    const prepareData = async () => {
        try {
            const customerResponse = await waysteClient.customer().adminPortal.fetch(selectedOrder.allianceCustomerID);
            setCustomer(customerResponse);
        } catch (error) {
            console.warn('prepareData error: ', error);
            showFlash('An error occurred getting the customer, refresh and try again or get a SAP dev', 'warning');
            return;
        }
        if (selectedOrder.haulerID) {
            try {
                const haulerResponse = await waysteClient.vendorService().adminPortal.fetch(selectedOrder.haulerID);
                setHauler(haulerResponse);
            } catch (error) {
                console.warn('prepareData error: ', error);
                showFlash('An error occurred getting the hauler, refresh and try again or get a SAP dev', 'warning');
                return;
            }
        }
        handleGetPricing();
    };

    useEffect(() => {
        prepareData();
    }, [selectedOrder]);

    const generatePaymentLink = async (values: Order.AllianceOrderTransport, receivableID: string) => {
        if (values.paymentMethod !== paymentMethodsEnums.check) {
            const response = await getPublicLinkShortCode(receivableID, token);
            if (response.status === 'error') return null;
            return response?.data?.key;
        }
        return null;
    };

    const handleSendOrderConfirmationEmail = async (contactEmails: {
        to: string;
        toContact: Customer.AllianceCustomerContactTransport;
        cc: string[];
    }) => {
        let receivables;
        try {
            receivables = await waysteClient.invoice().adminPortal.receivable.query({
                orderID: selectedOrder.id,
            });
        } catch (error) {
            console.warn('error: ', error);
            alert('Error sending customer order confirmation email, receivables failed to fetch');
            return;
        }
        const firstReceivable = receivables?.results?.find(
            (receivable) =>
                receivable.invoiceDetails.lineItems.some((item) => item.itemName === 'QP-Haul') && !receivable.invoiceDetails.void,
        );
        if (!firstReceivable?.id) {
            setLoading(false);
            alert('No receivable found');
            return;
        }
        const emailData = createOrderConfirmationEmailSendGrid(
            {
                contact: contactEmails.toContact,
                adjustedRentalPeriod: selectedOrder.adjustedRentalPeriod.value,
                expectedDeliveryDate: selectedOrder.expectedDeliveryDate,
                expectedSize: selectedOrder.expectedSize.size,
                material: selectedOrder.material as Material,
                rentExtensionFee: Number(selectedOrder.rentExtensionFee),
                serviceLocation: selectedOrder.serviceLocation,
                sharedDeliveryNotes: selectedOrder.sharedDeliveryNotes || '',
                weightLimit: selectedOrder.weightLimit?.value || '',
                emailMessage: '',
                expectedPickupDate: selectedOrder.expectedPickupDate || undefined,
                overage: selectedOrder.overage || undefined,
            },
            firstReceivable.invoiceDetails.total,
            userProfile,
            zonePricing?.prohibitedItems ? zonePricing.prohibitedItems.join(', ') : '',
            selectedOrder.orderNumber?.toString() || '',
        );
        if (firstReceivable.invoiceDetails.status !== 'PAID') {
            const payment_link = await generatePaymentLink(selectedOrder, firstReceivable.id);
            emailData.payment_link = payment_link || '';
        }

        const destination = formatEmailDestination(contactEmails.to, contactEmails.toContact.firstName || '', contactEmails.cc);
        try {
            await client.notification().adminPortal.createInstantNotification({
                handler: 'sendgrid',
                topic: 'order-confirmation-ro',
                destination,
                body: JSON.stringify(emailData),
            });
            showFlash('Successfully sent customer order confirmation email', 'success');
        } catch (error) {
            console.warn('error: ', error);
            showFlash('Error sending customer order confirmation email', 'warning');
        }
        return;
    };

    const handleSendPickupConfirmationEmail = async (contactEmails: {
        to: string;
        toContact: Customer.AllianceCustomerContactTransport;
        cc: string[];
    }) => {
        if (!selectedOrder.expectedPickupDate) {
            alert('No pickup date found, pickup email not sent');
            return;
        }
        const emailData = createOrderReadyPickupEmailSendGrid({
            contact: contactEmails.toContact,
            user: userProfile,
            expectedPickupDate: selectedOrder.expectedPickupDate,
            material: selectedOrder.material as Material,
            orderNumber: selectedOrder.orderNumber as number,
            serviceLocation: selectedOrder.serviceLocation,
            sharedPickupNotes: selectedOrder.sharedPickupNotes,
            prohibitedItemString: zonePricing?.prohibitedItems.join(', ') || undefined,
        });

        const destination = formatEmailDestination(contactEmails.to, contactEmails.toContact.firstName || '', contactEmails.cc);
        try {
            await client.notification().adminPortal.createInstantNotification({
                handler: 'sendgrid',
                topic: 'ro-ready-pickup',
                destination,
                body: JSON.stringify(emailData),
            });
            showFlash('Successfully sent customer pickup email', 'success');
        } catch (error) {
            console.warn('error: ', error);
            showFlash('Error sending customer pickup email email', 'warning');
        }
        return;
    };

    const handleSendOrderCancellationEmail = async (contactEmails: {
        to: string;
        toContact: Customer.AllianceCustomerContactTransport;
        cc: string[];
    }) => {
        const emailData = createOrderCancellationEmailSendGrid({
            contact: contactEmails.toContact,
            user: userProfile,
            orderNumber: selectedOrder.orderNumber as number,
            serviceLocation: selectedOrder.serviceLocation,
        });
        const destination = formatEmailDestination(contactEmails.to, contactEmails.toContact.firstName || '', contactEmails.cc);
        try {
            await client.notification().adminPortal.createInstantNotification({
                handler: 'sendgrid',
                topic: 'order-cancellation-ro',
                destination,
                body: JSON.stringify(emailData),
            });
            showFlash('Successfully sent customer cancellation email', 'success');
        } catch (error) {
            console.warn('error: ', error);
            showFlash('Error sending customer cancellation email email', 'warning');
        }
        return;
    };

    const handleSendHaulerConfirmationEmail = async (passedHauler: Hauler.HaulerWithAapTransport) => {
        const data = createHaulerOrderConfirmationEmail(selectedOrder, passedHauler);
        try {
            await sendEmail('send-email', data);
            showFlash('Successfully sent Hauler delivery confirmation email', 'success');
        } catch (error) {
            showFlash('Error Sending Hauler delivery confirmation Email', 'warning');
        }
        return;
    };

    const handleSendHaulerPickupOnlyEmail = async (passedHauler: Hauler.HaulerWithAapTransport) => {
        if (!selectedOrder.expectedPickupDate) {
            alert('No pickup date, hauler pickup email not sent');
            return;
        }
        const haulerEmailData = createHaulerPickupEmail(selectedOrder, passedHauler, false);
        try {
            await sendEmail('send-email', haulerEmailData);
            showFlash('Successfully sent Hauler pickup confirmation email', 'success');
        } catch (error) {
            showFlash('Error sending Hauler pickup confirmation email', 'warning');
        }
        return;
    };

    const handleSendHaulerSwitchEmail = async (passedHauler: Hauler.HaulerWithAapTransport) => {
        const selectedOrderType =
            selectedOrder.status === 'ASSIGNED' || selectedOrder.status === 'DELIVERED'
                ? 'DELIVERY'
                : selectedOrder.status === 'READY_FULL_PICKUP'
                  ? 'PICKUP'
                  : undefined;
        if (!selectedOrderType || !selectedOrder.switches || selectedOrder.switches.length === 0) {
            showFlash('Error sending Hauler switch confirmation email', 'warning');
            return;
        }

        const switchID =
            selectedOrder.switches[0].toOrder === selectedOrder.id
                ? selectedOrder.switches[0].fromOrder
                : selectedOrder.switches[0].toOrder;

        const fetchSwitch = await client.order().adminPortal.fetch(switchID);

        const switchOrder = fetchSwitch;
        const pickupOrder = selectedOrderType === 'PICKUP' ? selectedOrder : switchOrder;
        const deliveryOrder = selectedOrderType === 'DELIVERY' ? selectedOrder : switchOrder;

        const haulerEmailData = createHaulerPickupEmail(pickupOrder, passedHauler, true, {
            material: deliveryOrder.material as Material,
            newOrderNumber: deliveryOrder.orderNumber as number,
            newOrderID: deliveryOrder.id,
            newHaulerOnlyNote: deliveryOrder.sharedDeliveryNotesPrivate,
            newNoteDel: deliveryOrder.sharedDeliveryNotes,
            newSize: deliveryOrder.expectedSize.size,
        });
        try {
            await sendEmail('send-email', haulerEmailData);
            showFlash('Successfully sent Hauler switch confirmation email', 'success');
        } catch (error) {
            showFlash('Error sending Hauler switch confirmation email', 'warning');
        }
        return;
    };

    const handleSendHaulerCancellationEmail = async (
        passedHauler: Hauler.HaulerWithAapTransport,
        cancellationType: 'delay_pickup' | 'final_removal',
    ) => {
        const cancellationEmail = createHaulerOrderCancellationEmail(passedHauler, selectedOrder, isSwitch ? cancellationType : null);
        try {
            await sendEmail('send-email', cancellationEmail);
            showFlash('Successfully sent Hauler cancellation email', 'success');
        } catch (error) {
            showFlash('Error sending Hauler cancellation confirmation email', 'warning');
        }
        return;
    };

    const onSendEmail = async () => {
        setLoading(true);
        // Start Customer Emails
        if (!customer) {
            setShowAlert({
                severity: 'error',
                title: 'Error Sending Customer Email',
                message: 'No customer email found, email not sent.',
            });
            setLoading(false);
            return;
        }
        let contactEmailsDispatch;
        try {
            contactEmailsDispatch = getCustomerToAndCCEmails('dispatch', customer, selectedOrder.serviceLocation.address);
        } catch (error) {
            setShowAlert({
                severity: 'error',
                title: 'Error Sending Customer Email',
                message: (error as Error)?.message || 'No customer email found, email not sent.',
            });
            return;
        }
        if (emailsToSend.customer.orderConfirmation) {
            await handleSendOrderConfirmationEmail(contactEmailsDispatch);
        }
        if (emailsToSend.customer.pickupConfirmation) {
            await handleSendPickupConfirmationEmail(contactEmailsDispatch);
        }
        if (emailsToSend.customer.cancellation) {
            await handleSendOrderCancellationEmail(contactEmailsDispatch);
        }
        // End Customer Emails
        // Start Hauler Emails
        if (!selectedOrder.haulerID || Object.values(emailsToSend.hauler).every((value) => !value)) {
            setLoading(false);
            handleClose();
            return;
        }

        if (!hauler || !hauler.contacts?.find((contact) => contact.primaryContact)?.email) {
            alert('No hauler email found, hauler emails not sent');
            setLoading(false);
            return;
        }

        if (emailsToSend.hauler.deliveryConfirmation) {
            await handleSendHaulerConfirmationEmail(hauler);
        }
        if (emailsToSend.hauler.pickupConfirmation) {
            await handleSendHaulerPickupOnlyEmail(hauler);
        }
        if (emailsToSend.hauler.switchConfirmation) {
            await handleSendHaulerSwitchEmail(hauler);
        }
        if (emailsToSend.hauler.cancellation) {
            await handleSendHaulerCancellationEmail(hauler, emailsToSend.hauler.cancellation.type);
        }
        setLoading(false);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} styledTitle="Resend Email" className="max-w-screen-md">
            <div className="flex flex-col gap-2">
                {/* CUSTOMER EMAILS */}
                <div className="grid grid-cols-2">
                    <h6 className="col-span-2 text-lg font-bold underline">Customer Info</h6>
                    <div>Primary Email:</div>
                    <div>{customer && getPrimaryCustomerContact(customer)?.email}</div>
                    <div>Location CCs:</div>
                    <div>
                        {customer &&
                            getCustomerCCAddresses(getPrimaryCustomerContact(customer)?.email || '', customer)?.serviceCCAddresses?.join(
                                ', ',
                            )}
                    </div>
                </div>
                {/* CUSTOMER DELIVER OR JUST ORDER, ITS AMBIGUOUS ENOUGH TO JUST BE A GENERIC CONFIRMATION WITH PAYMENT LINK */}
                <div>
                    <Checkbox
                        label="Customer Order Confirmation"
                        inputProps={{
                            checked: emailsToSend.customer.orderConfirmation,
                            disabled: !(orderStatus >= StatusEnum.UNASSIGNED),
                            onChange: (e) => {
                                setEmailsToSend({
                                    ...emailsToSend,
                                    customer: {
                                        ...emailsToSend.customer,
                                        orderConfirmation: e.target.checked,
                                    },
                                });
                            },
                        }}
                    />
                    {emailsToSend.customer.orderConfirmation && (
                        <p className="mt-0.5 text-xs text-gray-400">
                            This includes payment link if its not yet paid in full. Payment will only be for the FIRST receivable associated
                            with the order.
                        </p>
                    )}
                </div>
                {/* CUSTOMER PICKUP */}
                <div>
                    <Checkbox
                        label="Customer Pickup Order Confirmation"
                        inputProps={{
                            checked: emailsToSend.customer.pickupConfirmation,
                            disabled: !(orderStatus >= StatusEnum.READY_FULL_PICKUP && orderStatus < StatusEnum.PICKED_UP_FULL),
                            onChange: (e) => {
                                setEmailsToSend({
                                    ...emailsToSend,
                                    customer: {
                                        ...emailsToSend.customer,
                                        pickupConfirmation: e.target.checked,
                                    },
                                });
                            },
                        }}
                    />
                    {emailsToSend.customer.pickupConfirmation && isSwitch && (
                        <p className="mt-0.5 text-xs text-gray-400">Pickup confirmation will not include payment link.</p>
                    )}
                </div>
                {/* CUSTOMER CANCEL */}
                <div>
                    <Checkbox
                        label="Order Cancellation"
                        inputProps={{
                            checked: emailsToSend.customer.cancellation,
                            disabled: orderStatus !== StatusEnum.CANCELLED,
                            onChange: (e) => {
                                setEmailsToSend({
                                    ...emailsToSend,
                                    customer: {
                                        ...emailsToSend.customer,
                                        cancellation: e.target.checked,
                                    },
                                });
                            },
                        }}
                    />
                </div>

                {/* HAULER EMAILS */}
                <div className="mt-4 grid grid-cols-2">
                    <h6 className="col-span-2 text-lg font-bold underline">Hauler Info</h6>
                    {selectedOrder.haulerID ? (
                        <>
                            <div>Primary Email:</div>
                            <div>{hauler && getPrimaryContact(hauler)?.email}</div>
                            <div>Dispatch CCs:</div>
                            <div>{hauler && getDispatchEmailsString(hauler.contacts)}</div>
                        </>
                    ) : (
                        <div>No hauler for this order</div>
                    )}
                </div>
                {/* HAULER DELIVERY */}
                <div>
                    <Checkbox
                        label="Hauler Delivery Confirmation"
                        inputProps={{
                            checked: emailsToSend.hauler.deliveryConfirmation,
                            disabled: !(orderStatus >= StatusEnum.ASSIGNED && orderStatus < StatusEnum.READY_FULL_PICKUP),
                            onChange: (e) => {
                                setEmailsToSend({
                                    ...emailsToSend,
                                    hauler: {
                                        ...emailsToSend.hauler,
                                        deliveryConfirmation: e.target.checked,
                                    },
                                });
                            },
                        }}
                    />
                </div>
                {/* HAULER PICKUP */}
                <div>
                    <Checkbox
                        label="Hauler Pickup Order Confirmation"
                        inputProps={{
                            checked: emailsToSend.hauler.pickupConfirmation,
                            disabled: isSwitch || !(orderStatus >= StatusEnum.READY_FULL_PICKUP && orderStatus < StatusEnum.PICKED_UP_FULL),
                            onChange: (e) => {
                                setEmailsToSend({
                                    ...emailsToSend,
                                    hauler: {
                                        ...emailsToSend.hauler,
                                        pickupConfirmation: e.target.checked,
                                    },
                                });
                            },
                        }}
                    />
                </div>
                {/* HAULER SWITCH */}
                <div>
                    <Checkbox
                        label="Hauler Switch Confirmation"
                        inputProps={{
                            checked: emailsToSend.hauler.switchConfirmation,
                            disabled: !isSwitch,
                            onChange: (e) => {
                                setEmailsToSend({
                                    ...emailsToSend,
                                    hauler: {
                                        ...emailsToSend.hauler,
                                        switchConfirmation: e.target.checked,
                                    },
                                });
                            },
                        }}
                    />
                </div>
                {/* HAULER CANCEL */}
                <div>
                    <Checkbox
                        label="Hauler Order Cancellation"
                        inputProps={{
                            checked: Boolean(emailsToSend.hauler.cancellation),
                            disabled: !isSwitch || orderStatus !== StatusEnum.CANCELLED,
                            onChange: (e) => {
                                setEmailsToSend({
                                    ...emailsToSend,
                                    hauler: {
                                        ...emailsToSend.hauler,
                                        cancellation: e.target.checked
                                            ? {
                                                  type: 'final_removal',
                                              }
                                            : undefined,
                                    },
                                });
                            },
                        }}
                    />
                </div>
                {emailsToSend.hauler.cancellation && !isSwitch && (
                    <div className="flex gap-2">
                        <Radio
                            label="Final Removal"
                            inputProps={{
                                disabled: orderStatus !== StatusEnum.CANCELLED,
                                checked: emailsToSend.hauler?.cancellation?.type === 'final_removal',
                                onChange: (e) => {
                                    setEmailsToSend({
                                        ...emailsToSend,
                                        hauler: {
                                            ...emailsToSend.hauler,
                                            cancellation: e.target.checked
                                                ? {
                                                      type: 'final_removal',
                                                  }
                                                : undefined,
                                        },
                                    });
                                },
                            }}
                        />
                        <Radio
                            label="Delay Pickup"
                            inputProps={{
                                disabled: orderStatus !== StatusEnum.CANCELLED,
                                checked: emailsToSend.hauler?.cancellation?.type === 'delay_pickup',
                                onChange: (e) => {
                                    setEmailsToSend({
                                        ...emailsToSend,
                                        hauler: {
                                            ...emailsToSend.hauler,
                                            cancellation: e.target.checked
                                                ? {
                                                      type: 'delay_pickup',
                                                  }
                                                : undefined,
                                        },
                                    });
                                },
                            }}
                        />
                    </div>
                )}
                <div className="mt-4">
                    <Button className="btn-primary float-right" type="button" onClick={onSendEmail} loading={loading}>
                        Send Emails
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default ResendEmailModal;
