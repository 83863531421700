import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';
import { UIContext } from '../../contexts';
import { allianceHaulerID } from '../../utils';
import Loading from '../Loading';
import ReviewPayables from './ReviewPayables';

type Props = {
    open: boolean;
    onClose: () => void;
};

export type PayableWithOrder = Invoice.PayableTransport & { order: Order.AllianceOrderTransport };

const PayablesReview = ({ open, onClose }: Props) => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const [isLoading, setIsLoading] = useState(false);
    const [payables, setPayables] = useState<PayableWithOrder[]>([]);

    const handleGetPayables = async () => {
        setIsLoading(true);

        const response = await client.invoice().adminPortal.payable.query({
            void: false,
            paidInFull: false,
            readyForPayment: false,
            limit: 10000,
        });
        const payableData = response.results;

        // Filter out payables with a total of 0
        const filteredPayableData = payableData.filter((item: Invoice.PayableTransport) => item.invoiceDetails.total !== 0);

        const uniqueOrderIDs = [...new Set(filteredPayableData.map((item) => item.invoiceDetails.orderID))];

        const uniqueOrders: Record<string, Order.AllianceOrderTransport> = {};

        for (const orderID of uniqueOrderIDs) {
            if (!orderID) continue;
            const orderResponse = await client.order().adminPortal.fetch(orderID);
            if (orderResponse) uniqueOrders[orderResponse.id] = orderResponse;
        }
        const payablesWithOrders: PayableWithOrder[] = filteredPayableData.map((item) => ({
            ...item,
            order: uniqueOrders[item.invoiceDetails.orderID ?? ''],
        }));
        const filteredPayables = payablesWithOrders
            .filter(
                (payable) =>
                    payable.order &&
                    payable.haulerID !== allianceHaulerID &&
                    (payable.order.status === 'COMPLETED' || payable.order.status === 'CANCELLED'),
            )
            .sort(
                (
                    { order: orderA, invoiceDetails: { invoiceNumber: invoiceNumberA = '' } },
                    { order: orderB, invoiceDetails: { invoiceNumber: invoiceNumberB = '' } },
                ) => {
                    if (orderA?.orderNumber !== orderB?.orderNumber) {
                        return (orderA?.orderNumber ?? 0) - (orderB?.orderNumber ?? 0);
                    }
                    return invoiceNumberA.localeCompare(invoiceNumberB);
                },
            );

        setPayables(filteredPayables);
        setIsLoading(false);
    };

    useEffect(() => {
        if (open) handleGetPayables();
    }, [open]);

    const onBillUpdate = async (payable: PayableWithOrder, onSuccess: () => boolean) => {
        try {
            await client.invoice().adminPortal.payable.update(payable.id, payable);
            onSuccess();
            showFlash('Payables Successfully Updated', 'success');
        } catch (error) {
            console.warn('PayablesReview update payable error: ', error);
            showFlash('An error occurred updating payable', 'warning');
        }
    };

    if (!open) return null;
    if (isLoading) return <Loading fullScreen />;

    return (
        <Dialog styledTitle="Bills Review" open={open} fullScreen onClose={onClose}>
            <div>
                <ReviewPayables payables={payables} onBackButtonClick={onClose} onBillUpdate={onBillUpdate} />
            </div>
        </Dialog>
    );
};

export default PayablesReview;
