import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Pricing } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { CreateUniversalPricingFormProps } from '../HaulerPricingCreate';
import { RollOffPricingFormProps } from '../HaulerPricingForm/HaulerPricingForm';
import HaulerPricingProductUpdate from './HaulerPricingProductUpdate';
import HaulerPricingUpdate from './HaulerPricingUpdate';

interface HaulerPricingUpdateContainerPropsBase {
    open: boolean;
    onBackButtonClick: (data: { status: string | number }) => void;
    hauler: Hauler.HaulerWithAapTransport;
    haulerPricingZones: Pricing.PricingTransport[];
}

interface RolloffHaulerPricingUpdateContainerProps extends HaulerPricingUpdateContainerPropsBase {
    mode: 'Rolloff';
    pricing: Pricing.PricingTransport;
}

interface UniversalHaulerPricingUpdateContainerProps extends HaulerPricingUpdateContainerPropsBase {
    mode: 'Universal';
    pricing: Pricing.ProductPricingZoneTransport;
}

type HaulerPricingUpdateContainerProps = RolloffHaulerPricingUpdateContainerProps | UniversalHaulerPricingUpdateContainerProps;

export interface UpdateRollOffPricingZoneFormProps {
    id?: string;
    haulerID: string;
    state: Pricing.UsStateAbbreviation;
    zoneName: string;
    tripCharge: number;
    haulerName: string;
    notes: string;
    copyExistingZone?: string;
}

const HaulerPricingUpdateContainer = ({
    pricing,
    open,
    onBackButtonClick,
    hauler,
    haulerPricingZones,
    mode,
}: HaulerPricingUpdateContainerProps) => {
    const { setShowToast } = useSourContext();
    const [isLoading, setIsLoading] = useState(false);
    const client = useWaysteClient();

    // HANDLE COPY PRICING FROM EXISTING ZONE
    const handleCopyPricing = async (newZoneID: string, zoneToCopyID: string) => {
        const existingZone = haulerPricingZones?.find((zone) => zone.id === zoneToCopyID);

        const payload: Array<Pricing.SGMutateMaterial & { expirationDate?: string | null | undefined }> | undefined =
            existingZone?.pricingData.map((material) => {
                return {
                    expirationDate: undefined,
                    effectiveDate: new Date().toISOString(),
                    details: {
                        allowOnlineCheckout: material.allowOnlineCheckout,
                        allowForLowerTonLimit: material.allowForLowerTonLimit,
                        doesNotService: material.doesNotService,
                        lastUpdatedAt: new Date().toISOString(),
                        haulerApproved: undefined,
                        sourgumApproved: true,
                        effectiveDate: undefined,
                        expirationDate: undefined,
                        material: material.material,
                        sizes: material.sizes,
                        tax: material.tax,
                        type: material.type,
                    },
                };
            });

        if (!payload) {
            return;
        }

        try {
            await client.pricing().adminPortal.material.createMany(newZoneID, payload || []);
        } catch (error) {
            console.warn('error', error);
            setShowToast({
                severity: 'error',
                message: 'Error Copying Material Pricing',
            });
        }
    };

    const handleRolloffSubmit = async (data: RollOffPricingFormProps) => {
        if (!data?.id) {
            return;
        }
        const payload: Pricing.PricingUpdateTransport = {
            ...data,
        };
        delete payload.currentGrowthStage;

        delete payload.pricingData;
        delete payload.rentalPeriod;
        setIsLoading(true);

        let updatedPricingZone;
        try {
            updatedPricingZone = await client.pricing().adminPortal.pricing.update(data.id, payload);
            setShowToast({
                severity: 'success',
                message: 'Pricing Successfully Updated',
            });
        } catch (error) {
            setShowToast({
                severity: 'error',
                message: 'Error Updating Pricing',
            });
        }

        if (updatedPricingZone && data.copyExistingZone) {
            await handleCopyPricing(updatedPricingZone.id, data.copyExistingZone);
        }
        onBackButtonClick({ status: 'success' });
        setIsLoading(false);
    };

    const handleUniversalSubmit = async (data: CreateUniversalPricingFormProps) => {
        if (!data?.id) {
            return;
        }
        const payload: Pricing.ProductPricingZoneUpdateTransport = {
            ...data,
        };
        setIsLoading(true);

        try {
            await client.universalService().pricingZone.update(data.id, payload);
            setShowToast({
                severity: 'success',
                message: 'Pricing Successfully Updated',
            });
        } catch (error) {
            setShowToast({
                severity: 'error',
                message: 'Error Updating Pricing',
            });
        }

        onBackButtonClick({ status: 'success' });
        setIsLoading(false);
    };

    if (mode === 'Universal') {
        return (
            <HaulerPricingProductUpdate
                pricing={pricing}
                open={open}
                onBackButtonClick={onBackButtonClick}
                onSubmit={handleUniversalSubmit}
                isLoading={isLoading}
                hauler={hauler}
            />
        );
    }
    if (mode === 'Rolloff') {
        return (
            <HaulerPricingUpdate
                haulerPricingZones={haulerPricingZones}
                pricing={pricing}
                onBackButtonClick={onBackButtonClick}
                open={open}
                onSubmit={handleRolloffSubmit}
                isLoading={isLoading}
                hauler={hauler}
            />
        );
    }

    throw new Error('Invalid mode');
};

export default HaulerPricingUpdateContainer;
