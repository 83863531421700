import React, { useEffect, useState } from 'react';
import { V1 as sourgumPricingFunctions } from '@alliance-disposal/pricing';
import { Material, MaterialLabels, Materials, Pricing } from '@alliance-disposal/transport-types';
import { Button, Checkbox, CurrencyTextField, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ccRate } from '../../utils/pricing-utils';
import { priceTypes, priceTypesEnums, rollOffSizes } from '../../utils/shared-types';

export type FrontendSize = {
    checked?: boolean;
    tonLimit: number | '';
    haul: number | '';
    size: string;
    dump: number | '';
    over: number | '';
    overage?: number | ''; // this gets injected when it goes through the normalization process
};

export type FormProps = {
    material: string[];
    type: 'ton' | 'yard' | 'flat';
    editAllHauls: boolean;
    editAllDumps: boolean;
    editAllOverages: boolean;
    tax: boolean;
    allowOnlineCheckout: boolean;
    allowForLowerTonLimit: boolean;
    doesNotService: boolean;
    haul: number | '';
    dump: number | '';
    over: number | '';
    sizes: FrontendSize[];
    isHauler: boolean;
};

export interface MaterialPricingFormProps {
    zone: Pricing.PricingTransport;
    material?: Pricing.PricingDataTransport;
    onCancel: () => void;
    onSubmit: (data: FormProps) => void;
    isLoading: boolean;
    isHauler: boolean;
    isCopying: boolean;
}

const MaterialPricingForm = ({ zone, material, onCancel, onSubmit, isLoading, isHauler, isCopying }: MaterialPricingFormProps) => {
    const [materialsUsed, setMaterialsUsed] = useState<Material[]>([]);

    const prepareFormData = (data?: Pricing.PricingDataTransport): FormProps => {
        if (!data) {
            return {
                material: [],
                type: 'ton',
                isHauler,
                tax: true,
                allowOnlineCheckout: false,
                allowForLowerTonLimit: false,
                doesNotService: false,
                editAllHauls: true,
                editAllDumps: true,
                editAllOverages: true,
                haul: '',
                dump: '',
                over: '',
                sizes: Object.values(rollOffSizes).map((size) => ({
                    size: size,
                    checked: true,
                    tonLimit: isHauler ? 0 : '',
                    haul: '',
                    dump: '',
                    over: '',
                })),
            };
        }

        const newData: Partial<FormProps> = {
            material: isCopying || !material ? undefined : [data.material],
            type: data.type as 'ton' | 'yard' | 'flat',
            isHauler,
            tax: data.tax,
            allowOnlineCheckout: data.allowOnlineCheckout || false,
            allowForLowerTonLimit: data.allowForLowerTonLimit || false,
            doesNotService: data.doesNotService || false,
            haul: '',
            dump: '',
        };

        const sizes = data.sizes
            ? Object.values(rollOffSizes).map((size) => {
                  const found = data.sizes.find((item) => +item.size === +size);
                  if (found !== undefined && found !== null) {
                      const tonLimit = found.tonLimit !== null && found.tonLimit !== undefined && found.tonLimit >= 0 ? found.tonLimit : '';
                      const haul = found.haul !== null && found.haul !== undefined ? found.haul : '';
                      const dump = found.dump !== null && found.dump !== undefined ? found.dump : '';
                      const over = found.over !== null && found.over !== undefined ? found.over : '';

                      return {
                          size,
                          tonLimit,
                          haul,
                          dump,
                          over,
                          checked: true,
                      } as FrontendSize;
                  }
                  return {
                      size,
                      tonLimit: '',
                      haul: '',
                      dump: '',
                      over: '',
                      checked: false,
                  } as FrontendSize;
              })
            : [];

        if (data.sizes.length > 0 && data.sizes.every((item) => item.haul === data.sizes[0].haul)) {
            newData.editAllHauls = true;
            newData.haul = Number(data.sizes[0].haul || 0);
        }
        if (data.sizes.length > 0 && data.sizes.every((item) => item.dump === data.sizes[0].dump)) {
            newData.editAllDumps = true;
            newData.dump = Number(data.sizes[0].dump || 0);
        }
        if (data.sizes.length > 0 && data.sizes.every((item) => item.over === data.sizes[0].over)) {
            newData.editAllOverages = true;
            newData.over = Number(data.sizes[0].over || 0);
        }
        newData.sizes = sizes;
        if (typeof newData.over !== 'number') newData.over = '';
        if (data.sizes.length === 0) {
            newData.editAllHauls = true;
            newData.editAllDumps = true;
            newData.editAllOverages = true;
            newData.haul = 0;
            newData.dump = 0;
            newData.over = 0;
        }

        return newData as FormProps;
    };

    const methods = useForm<FormProps>({
        mode: 'all',
        defaultValues: prepareFormData(material),
    });

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { isDirty, isValid },
    } = methods;

    const watchValues = watch();

    useEffect(() => {
        const materialsUsedArray = zone.pricingData.map((item: Pricing.PricingDataTransport) => item.material as Material);
        setMaterialsUsed(materialsUsedArray);
    }, []);

    const materialDisabled = (value: string) =>
        Boolean(
            materialsUsed.find((item) => {
                if (material && !isCopying) return item === value && item !== material.material;
                return item === value;
            }),
        );

    const onFormSubmit = (data: FormProps) => {
        const newData: FormProps = JSON.parse(JSON.stringify(data));

        if (!isCopying && material && data.material.length > 1) {
            throw new Error('You may only select one material when editing');
        }

        newData.dump = newData.type === priceTypesEnums.flat ? '' : newData.dump;
        newData.over = newData.type === priceTypesEnums.ton ? (newData.over ? newData.over : newData.dump) : '';
        newData.sizes = newData.sizes.reduce((filtered: FormProps['sizes'], size) => {
            if (size.checked) {
                filtered.push({
                    size: size.size,
                    tonLimit: newData.type === priceTypesEnums.ton ? size.tonLimit : 0,
                    haul: size.haul,
                    dump: size.dump,
                    over: size.over,
                });
            }
            return filtered;
        }, []);
        onSubmit(newData);
    };

    const handleDoesNotServiceClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setValue('type', 'ton');
            setValue('dump', '');
            setValue('over', '');
            setValue('tax', true);
            setValue('allowOnlineCheckout', false);
            setValue('allowForLowerTonLimit', false);
            const newSizes: FormProps['sizes'] = Object.values(rollOffSizes).map((size) => ({
                size: size,
                checked: false,
                tonLimit: '',
                haul: '',
                dump: '',
                over: '',
            }));
            setValue('sizes', newSizes);
        }
    };

    const finalPriceHelper = (sizeIndex: number, onlyOverage?: boolean) => {
        if (onlyOverage) {
            const overageValue = sourgumPricingFunctions.quotedPriceBuild(0, 0, 0, 0, ccRate, false, Number(watchValues.over)).overage;
            return (
                <div>
                    Overage with CC: <b>{moneyFormatter(overageValue)}</b>
                </div>
            );
        }
        const subtotalValue = sourgumPricingFunctions.quotedPriceBuild(
            watchValues.type === 'yard' ? Number(watchValues.sizes[sizeIndex].size) : Number(watchValues.sizes[sizeIndex].tonLimit),
            Number(watchValues.sizes[sizeIndex].dump),
            Number(watchValues.sizes[sizeIndex].haul),
            0,
            0,
            false,
            0,
        ).total;
        const subtotal = (
            <div>
                Subtotal: <b>{moneyFormatter(subtotalValue)}</b>
            </div>
        );
        if (isHauler) {
            return <div style={{ marginLeft: 15 }}>{subtotal}</div>;
        }
        const totalValue = sourgumPricingFunctions.quotedPriceBuild(
            watchValues.type === 'yard' ? Number(watchValues.sizes[sizeIndex].size) : Number(watchValues.sizes[sizeIndex].tonLimit),
            Number(watchValues.sizes[sizeIndex].dump),
            Number(watchValues.sizes[sizeIndex].haul),
            watchValues.tax ? zone.actualTaxRate || 0 : 0,
            ccRate,
            true,
            Number(watchValues.sizes[sizeIndex].over),
        );
        const total = (
            <div>
                With{watchValues.tax ? ' taxes & ' : ' '}CC: <b>{moneyFormatter(totalValue.total)}</b>
            </div>
        );
        return (
            <div style={{ marginLeft: 15 }}>
                {subtotal}
                {total}
                {!watchValues.editAllOverages ? (
                    <div>
                        Overage with CC: <b>{moneyFormatter(totalValue.overage)}</b>
                    </div>
                ) : null}
            </div>
        );
    };

    const handlePriceTypeChange = (value: 'ton' | 'flat' | 'yard') => {
        setValue('type', value);
        setValue('editAllHauls', value !== 'ton' ? false : true);
        if ((isHauler && value === 'ton') || value !== 'ton') {
            const newSizes = watchValues.sizes.map((item) => ({
                ...item,
                tonLimit: isHauler ? 0 : ('' as const),
                over: '' as const,
                dump: '' as const,
            }));
            setValue('sizes', newSizes);
        }
    };

    const handleSameSizeEveryClick = (
        e: React.ChangeEvent<HTMLInputElement>,
        key: 'haul' | 'dump' | 'over',
        field: 'editAllHauls' | 'editAllDumps' | 'editAllOverages',
    ) => {
        const newValue = e.target.checked;
        setValue(field, newValue);
        if (newValue === true) {
            const firstMatchingSize = watchValues.sizes.find((item) => item.checked) as FrontendSize;
            const newSizes = watchValues.sizes.map((item) => ({
                ...item,
                [key]: item.checked ? firstMatchingSize[key] : '',
            }));
            setValue('sizes', newSizes);
            setValue(key, firstMatchingSize[key]);
        }
    };

    const handleSizeRateChange = (
        value: number | string,
        sizeIndex: number,
        key: 'haul' | 'dump' | 'over',
        field: 'editAllHauls' | 'editAllDumps' | 'editAllOverages',
    ) => {
        const newValue = value === '' ? '' : Number(value);
        const newSizes = watchValues.sizes.map((size, index) => ({
            ...size,
            [key]: (sizeIndex === index || watchValues[field]) && size.checked ? newValue : size[key as keyof FrontendSize],
        }));
        setValue('sizes', [...newSizes]);
        if (watchValues[field]) watchValues[key] = newValue;
    };

    const handleSizeClicked = (e: React.ChangeEvent<HTMLInputElement>, sizeIndex: number) => {
        const newValue = e.target.checked;
        const newSizes = watchValues.sizes.map((size, index) => {
            if (index !== sizeIndex) return { ...size };
            return {
                ...size,
                checked: newValue,
                tonLimit: '' as const,
                haul: newValue && watchValues.editAllHauls ? watchValues.haul : '',
                dump: newValue && watchValues.editAllHauls ? watchValues.dump : '',
                over: newValue && watchValues.editAllHauls ? watchValues.over : '',
            };
        });
        setValue('sizes', [...newSizes]);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <Controller
                        name="material"
                        control={control}
                        rules={{ required: 'Material is required' }}
                        render={({ field, fieldState }) => (
                            <Select
                                label="Material"
                                onSelect={(values) => {
                                    field.onChange(values);
                                }}
                                value={field.value}
                                required
                                error={fieldState.error}
                                multiple
                                disabled={!isCopying && !!material}
                            >
                                {Materials.map((item) => (
                                    <SelectOption key={`material-${item}`} value={item} disabled={materialDisabled(item)}>
                                        {MaterialLabels[item]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                    <Controller
                        name="type"
                        control={control}
                        rules={{ required: 'Price type is required' }}
                        render={({ field, fieldState }) => (
                            <Select
                                label="Price type"
                                onSelect={(value) => handlePriceTypeChange(value as 'ton' | 'flat' | 'yard')}
                                value={field.value}
                                required
                                error={fieldState.error}
                            >
                                {Object.entries(priceTypes).map((item) => (
                                    <SelectOption key={`priceType-${item[0]}`} value={item[0]}>
                                        {item[1]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                    <Controller
                        name="haul"
                        control={control}
                        rules={{
                            required: {
                                value: watchValues.editAllHauls,
                                message: 'Haul rate is required',
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <CurrencyTextField
                                label="Haul rate"
                                value={field.value}
                                useCents
                                onChange={(value) => {
                                    field.onChange(value);
                                    handleSizeRateChange(value, 0, 'haul', 'editAllHauls');
                                }}
                                error={fieldState.error}
                                required={watchValues.editAllHauls}
                            />
                        )}
                    />
                    {watchValues.type !== 'flat' ? (
                        <Controller
                            name="dump"
                            control={control}
                            rules={{
                                required: {
                                    value: (watchValues.type === 'ton' || watchValues.type === 'yard') && watchValues.editAllDumps,
                                    message: 'Dump rate is required',
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <CurrencyTextField
                                    label="Dump rate"
                                    value={field.value}
                                    useCents
                                    onChange={(value) => {
                                        field.onChange(value);
                                        handleSizeRateChange(value, 0, 'dump', 'editAllDumps');
                                    }}
                                    error={fieldState.error}
                                    required={watchValues.editAllDumps}
                                />
                            )}
                        />
                    ) : (
                        <div />
                    )}
                    {!isHauler && watchValues.type === 'ton' && (
                        <div className="grid grid-cols-2 gap-4 md:col-span-2">
                            <Controller
                                name="over"
                                control={control}
                                rules={{
                                    required: {
                                        value: !isHauler && watchValues.editAllOverages && watchValues.type === 'ton',
                                        message: 'Overage is required',
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <CurrencyTextField
                                        label="Overage rate"
                                        useCents
                                        value={field.value}
                                        onChange={(value) => {
                                            field.onChange(value);
                                            handleSizeRateChange(value, 0, 'over', 'editAllOverages');
                                        }}
                                        error={fieldState.error}
                                        required={watchValues.editAllOverages}
                                    />
                                )}
                            />
                            <div className="flex items-center">{finalPriceHelper(0, true)}</div>
                        </div>
                    )}
                </div>
                {!isHauler && (
                    <div className="my-4 grid grid-cols-1 items-start gap-4 md:grid-cols-3">
                        <div>
                            <Controller
                                name="tax"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Checkbox
                                        error={fieldState.error}
                                        label={`Taxable ${
                                            zone.actualTaxRate ? ` (${(zone.actualTaxRate * 100).toFixed(3)}% ${zone.state} State Tax)` : ''
                                        }`}
                                        inputProps={{
                                            checked: field.value,
                                            onChange: (e) => field.onChange(e.target.checked),
                                        }}
                                    />
                                )}
                            />
                            <p className="text-xs">Taxes at checkout may be higher than the state tax rate due to local taxes.</p>
                        </div>
                        <Controller
                            name="allowOnlineCheckout"
                            control={control}
                            render={({ field, fieldState }) => (
                                <Checkbox
                                    error={fieldState.error}
                                    label="Allow Online Checkout"
                                    inputProps={{
                                        checked: field.value,
                                        onChange: (e) => field.onChange(e.target.checked),
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="allowForLowerTonLimit"
                            control={control}
                            render={({ field, fieldState }) => (
                                <Checkbox
                                    error={fieldState.error}
                                    label="Allow for Lower Ton Limit"
                                    inputProps={{
                                        checked: field.value,
                                        onChange: (e) => field.onChange(e.target.checked),
                                    }}
                                />
                            )}
                        />
                    </div>
                )}
                {isHauler && (
                    <div className="my-4 w-full">
                        <Controller
                            name="doesNotService"
                            control={control}
                            render={({ field, fieldState }) => (
                                <Checkbox
                                    error={fieldState.error}
                                    label="Does not service"
                                    inputProps={{
                                        checked: field.value,
                                        onChange: (e) => {
                                            field.onChange(e.target.checked);
                                            handleDoesNotServiceClick(e);
                                        },
                                    }}
                                />
                            )}
                        />
                    </div>
                )}
                <div className="flex flex-col gap-4">
                    <div className="text-lg text-gray-500">Available Sizes</div>
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                        <Controller
                            name="editAllHauls"
                            control={control}
                            render={({ field, fieldState }) => (
                                <Checkbox
                                    error={fieldState.error}
                                    label="Haul rate same for every size"
                                    inputProps={{
                                        checked: field.value,
                                        onChange: (e) => {
                                            field.onChange(e.target.checked);
                                            handleSameSizeEveryClick(e, 'haul', 'editAllHauls');
                                        },
                                    }}
                                />
                            )}
                        />
                        {watchValues.type !== 'flat' && (
                            <Controller
                                name="editAllDumps"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Checkbox
                                        error={fieldState.error}
                                        label="Dump rate same for every size"
                                        inputProps={{
                                            checked: field.value,
                                            onChange: (e) => {
                                                field.onChange(e.target.checked);
                                                handleSameSizeEveryClick(e, 'dump', 'editAllDumps');
                                            },
                                        }}
                                    />
                                )}
                            />
                        )}
                        {!isHauler && watchValues.type === 'ton' && (
                            <Controller
                                name="editAllOverages"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Checkbox
                                        error={fieldState.error}
                                        label="Overage rate same for every size"
                                        inputProps={{
                                            checked: field.value,
                                            onChange: (e) => {
                                                field.onChange(e.target.checked);
                                                handleSameSizeEveryClick(e, 'over', 'editAllOverages');
                                            },
                                        }}
                                    />
                                )}
                            />
                        )}
                    </div>
                    {watchValues.sizes.map((size, sizeIndex) => (
                        <div className="flex items-center gap-4" key={'sizes - ' + size.size}>
                            <div className="w-full max-w-[70px]">
                                <Controller
                                    name={`sizes.${sizeIndex}.checked`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Checkbox
                                            error={fieldState.error}
                                            label={size.size}
                                            inputProps={{
                                                checked: field.value,
                                                onChange: (e) => {
                                                    field.onChange(e.target.checked);
                                                    handleSizeClicked(e, sizeIndex);
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            {/* style={{ maxWidth: 100, marginRight: 10 }} */}
                            <div className="w-full max-w-[120px]">
                                <Controller
                                    name={`sizes.${sizeIndex}.haul`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <CurrencyTextField
                                            label="Haul rate"
                                            useCents
                                            value={field.value}
                                            onChange={(value) => {
                                                field.onChange(value);
                                                handleSizeRateChange(value, sizeIndex, 'haul', 'editAllHauls');
                                            }}
                                            error={fieldState.error}
                                            required={watchValues.sizes[sizeIndex].checked}
                                            inputProps={{
                                                disabled: !watchValues.sizes[sizeIndex].checked || watchValues.editAllHauls,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            {watchValues.type !== 'flat' && (
                                <div className="w-full max-w-[120px]">
                                    <Controller
                                        name={`sizes.${sizeIndex}.dump`}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <CurrencyTextField
                                                label="Dump rate"
                                                useCents
                                                value={field.value}
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                    handleSizeRateChange(value, sizeIndex, 'dump', 'editAllDumps');
                                                }}
                                                error={fieldState.error}
                                                required={watchValues.sizes[sizeIndex].checked}
                                                inputProps={{
                                                    disabled: !watchValues.sizes[sizeIndex].checked || watchValues.editAllDumps,
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            )}
                            {!isHauler && watchValues.type === 'ton' && (
                                <div className="w-full max-w-[120px]">
                                    <Controller
                                        name={`sizes.${sizeIndex}.over`}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <CurrencyTextField
                                                label="Overage rate"
                                                value={field.value}
                                                useCents
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                    handleSizeRateChange(value, sizeIndex, 'over', 'editAllOverages');
                                                }}
                                                error={fieldState.error}
                                                required={watchValues.sizes[sizeIndex].checked}
                                                inputProps={{
                                                    disabled: !watchValues.sizes[sizeIndex].checked || watchValues.editAllOverages,
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            )}
                            {watchValues.type === 'ton' && (
                                <div className="w-full max-w-[100px]">
                                    <Controller
                                        name={`sizes.${sizeIndex}.tonLimit`}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="Ton limit"
                                                error={fieldState.error}
                                                required={watchValues.sizes[sizeIndex].checked}
                                                inputProps={{
                                                    ...field,
                                                    disabled: !watchValues.sizes[sizeIndex].checked,
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            )}
                            <div className="whitespace-nowrap">{finalPriceHelper(sizeIndex)}</div>
                        </div>
                    ))}
                </div>
                <div className="flex justify-end gap-4 pt-4">
                    <Button className="btn-dark-grey-outlined" onClick={onCancel} disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button className="btn-primary" type="submit" disabled={!isValid || !isDirty} loading={isLoading}>
                        Save
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default MaterialPricingForm;
