import { type UniversalService } from '@alliance-disposal/transport-types';

export const defaultEvent: UniversalService.ServiceEventCreate = {
    basePrice: 0,
    baseCost: 0,
    unitPrice: 0, // Price field
    unitCost: 0,
    unitType: 'each',
    taxable: false,
    dateExpected: new Date().toISOString(),
    description: '',
    eventType: 'synthetic',
    status: 'pending',
    lineItemTypeID: '',
    priceQuantity: 1,
    costQuantity: 0,
} as const;

export const defaultServiceOrder: UniversalService.ServiceOrderCreate = {
    serviceFrequency: 0,
    serviceFrequencyUnit: 'week',
    serviceDays: [],
    serviceTypeID: '',
    needsAttention: false,
    status: 'UNASSIGNED',
    internalNotes: '',
    serviceLocation: {
        address: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zip: '',
            country: 'US',
        },
        county: '',
        coordinates: { lat: 0, lng: 0 },
    },
    serviceEvents: [{ ...defaultEvent }],
};

export const defaultServiceSubscriptionDetails: UniversalService.SubscriptionDetailsCreate = {
    active: true,
    startDate: '',
    billingFrequency: 1,
    billingFrequencyUnit: 'month',
    billingDay: 0,
    addToInvoiceWhen: 1,
    periodicEvents: [{ ...defaultEvent }],
};

export const defaultServiceOrderWithSubscriptionDetails = {
    ...defaultServiceOrder,
    serviceEvents: [{ ...defaultEvent }, { ...defaultEvent }],
    subscriptionDetails: defaultServiceSubscriptionDetails,
};

export const defaultServiceGrouping: UniversalService.ServiceGroupingRecursiveCreate = {
    status: 'pending',
    name: '', // Let's just make this address line 1 for now
    type: 'single', // Fill in
    customerID: '', // Fill in
    internalNotes: [],
    serviceOrders: [{ ...defaultServiceOrder }],
    paymentTerm: 'onCharge',
    paymentMethod: 'creditCard',
    autopayEnabled: false,
};
