import { Customer, Pricing, ServiceLocationTransport } from '@alliance-disposal/transport-types';
import { useFormContext } from 'react-hook-form';
import OrderPricingBlock from '../OrderPricingBlock';
import ServiceAddressSubForm from '../ServiceAddressSubForm';
import { OrderCreateFormProps } from './order-create-form-props';

export interface PricingSubForm {
    zonePricing: Pricing.PricingTransport | null;
    onGetPricing: (
        loc: {
            lat: number;
            lng: number;
            zip?: string;
            state: string;
        },
        customerID?: string,
    ) => void;
    disabled?: boolean;
    existingCustomer: Customer.AllianceCustomerTransport | null;
    needsReviewStatus?: boolean;
}

const PricingSubForm = ({ zonePricing, onGetPricing, disabled, existingCustomer, needsReviewStatus }: PricingSubForm) => {
    const { getValues, setValue } = useFormContext<OrderCreateFormProps>();

    const handleAddressChange = (serviceLocation: ServiceLocationTransport) => {
        const customerValues = getValues('customerSubForm');
        if (
            serviceLocation.address.zip &&
            ((serviceLocation.coordinates.lat === 0 && serviceLocation.coordinates.lng === 0) || !serviceLocation.coordinates)
        ) {
            alert('No coords get a SAP Dev');
        } else {
            onGetPricing(
                { ...serviceLocation.coordinates, zip: serviceLocation.address.zip, state: serviceLocation.address.state },
                customerValues?.id,
            );
        }
        setValue('pricingSubForm.serviceLocation', serviceLocation);
    };

    return (
        <div className="flex flex-col gap-6">
            <h6 className="text-xl">Service Address</h6>
            <ServiceAddressSubForm
                handleAddressChange={handleAddressChange}
                fieldName="pricingSubForm.serviceLocation"
                customer={existingCustomer || undefined}
                disabled={disabled}
            />
            <h6 className="text-xl">Pricing Info</h6>
            <OrderPricingBlock
                zonePricing={zonePricing}
                disabled={disabled}
                existingCustomer={existingCustomer}
                fieldName="pricingSubForm."
                fromNeedsReview={needsReviewStatus}
            />
            {needsReviewStatus && (
                <div>
                    To change Price, Overage fee, Ton limit, or Rental extension fee you must do so in the order details AFTER finishing
                    here. Be sure to notify the customer first, and to create a new invoice to make any necessary pricing adjustments.
                </div>
            )}
        </div>
    );
};

export default PricingSubForm;
