import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Pricing } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Dialog } from '@wayste/sour-ui';
import { HaulerPricingForm } from '../HaulerPricingForm/HaulerPricingForm';
import { RollOffPricingFormProps } from '../HaulerPricingForm/HaulerPricingForm';
import { HaulerPricingFormUniversal } from '../HaulerPricingForm/HaulerPricingFormUniversal';

export interface CreateUniversalPricingFormProps {
    state: Pricing.UsStateAbbreviation;
    zoneName: string;
    notes: string;
    haulerID: string;
    haulerName: string;
    id?: string;
    copyRollOffZoneID?: string;
}

enum DaysEnum {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    LIMITED = 'LIMITED',
    UNKNOWN = 'UNKNOWN',
}

interface HaulerPricingCreateProps {
    open: boolean;
    onBackButtonClick: (data: { status: string | number }) => void;
    hauler: Hauler.HaulerWithAapTransport;
    haulerPricingZones: Pricing.PricingTransport[];
    mode: 'Rolloff' | 'Universal';
}

const HaulerPricingCreate = ({ open, onBackButtonClick, hauler, mode, haulerPricingZones }: HaulerPricingCreateProps) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [isLoading, setIsLoading] = useState(false);

    // HANDLE COPY PRICING FROM EXISTING ZONE
    const handleCopyPricing = async (newZoneID: string, zoneToCopyID: string) => {
        const existingZone = haulerPricingZones?.find((zone) => zone.id === zoneToCopyID);

        const payload: (Pricing.SGMutateMaterial & { expirationDate?: string | null | undefined })[] | undefined =
            existingZone?.pricingData.map((material) => {
                return {
                    expirationDate: undefined,
                    effectiveDate: new Date().toISOString(),
                    details: {
                        allowOnlineCheckout: material.allowOnlineCheckout,
                        allowForLowerTonLimit: material.allowForLowerTonLimit,
                        doesNotService: material.doesNotService,
                        lastUpdatedAt: new Date().toISOString(),
                        haulerApproved: undefined,
                        sourgumApproved: true,
                        effectiveDate: undefined,
                        expirationDate: undefined,
                        material: material.material,
                        sizes: material.sizes,
                        tax: material.tax,
                        type: material.type,
                    },
                };
            });

        if (!payload) {
            return;
        }

        try {
            await client.pricing().adminPortal.material.createMany(newZoneID, payload || []);
        } catch (error) {
            console.warn('error', error);
            setShowToast({
                severity: 'error',
                message: 'Error Copying Material Pricing',
            });
        }
    };

    // CREATE PRICING FOR ROLLOFF SERVICE
    const handleSubmitRollOf = async (data: RollOffPricingFormProps) => {
        setIsLoading(true);

        const payload: Pricing.PricingCreateTransport = {
            ...data,
            pricingData: [],
            prohibitedItems: [],
            days: {
                mon: DaysEnum.UNKNOWN,
                tue: DaysEnum.UNKNOWN,
                wed: DaysEnum.UNKNOWN,
                thu: DaysEnum.UNKNOWN,
                fri: DaysEnum.UNKNOWN,
                sat: DaysEnum.UNKNOWN,
                sun: DaysEnum.UNKNOWN,
            },
            public: false,
        };

        let newPricingZone;
        try {
            newPricingZone = await client.pricing().adminPortal.pricing.create(payload);
            setShowToast({
                severity: 'success',
                message: 'Pricing Successfully Created',
            });
        } catch (error) {
            setShowToast({
                severity: 'error',
                message: 'Error Creating Pricing',
            });
        }

        if (newPricingZone && data.copyExistingZone) {
            await handleCopyPricing(newPricingZone.id, data.copyExistingZone);
        }
        onBackButtonClick({ status: 'success' });
        setIsLoading(false);
    };

    // CREATE PRICING FOR UNIVERSAL SERVICE
    const handleSubmitUniversal = async (data: CreateUniversalPricingFormProps) => {
        setIsLoading(true);

        const payload: Pricing.ProductPricingZoneCreateTransport = {
            vendorID: data.haulerID,
            vendorName: data.haulerName,
            state: data.state,
            notes: data.notes,
            zoneName: data.zoneName,
            type: 'SOURGUM_INTERNAL',
            skuIDs: [],
        };

        try {
            const newZone = await client.universalService().pricingZone.create(payload);
            if (data.copyRollOffZoneID) {
                await copyRollOffZone(newZone.id, data.copyRollOffZoneID);
            } else {
                setShowToast({
                    severity: 'success',
                    message: 'Pricing Successfully Created',
                });
            }
            onBackButtonClick({ status: 200 });
        } catch (error) {
            setShowToast({
                severity: 'error',
                message: 'Error Creating Pricing',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const copyRollOffZone = async (newZoneID: string, copyFromZoneID: string) => {
        const zone = haulerPricingZones.find((zone) => zone.id === copyFromZoneID);
        if (!zone) {
            setShowToast({
                message: 'Cannot copy pricing zone without zone data',
                severity: 'error',
            });
            return;
        }
        if (zone.serviceAreas.length === 0) {
            setShowToast({
                message: 'Cannot copy pricing zone without service areas',
                severity: 'error',
            });
            return;
        }
        const newServiceAreasPayload: Pricing.ServiceAreaMutate[] = zone.serviceAreas?.map((area) => {
            if (area.boundary.geoJSON) {
                const serviceArea: Pricing.ServiceAreaMutate = {
                    boundary: area.boundary.geoJSON,
                    boundaryFormat: 'GEOJSON',
                    areaName: area.areaName,
                    taxJurisdiction: area.taxJurisdiction,
                };
                return serviceArea;
            } else {
                const serviceArea: Pricing.ServiceAreaMutate = {
                    boundary: area.boundary.kml,
                    boundaryFormat: 'KML',
                    areaName: area.areaName,
                    taxJurisdiction: area.taxJurisdiction,
                };
                return serviceArea;
            }
        });
        try {
            await client.universalService().pricingZone.updateServiceArea(newZoneID, newServiceAreasPayload);
            setShowToast({
                message: 'Pricing zone copied successfully',
                severity: 'success',
            });
        } catch (error) {
            setShowToast({
                message: 'Error copying pricing zone',
                severity: 'error',
            });
        }
    };

    return (
        <Dialog
            styledTitle={`Create Vendor Pricing (${mode})`}
            open={open}
            className="max-w-xl"
            onClose={() => {
                onBackButtonClick({ status: 'cancel' });
            }}
        >
            {mode === 'Rolloff' ? (
                <HaulerPricingForm
                    haulerPricingZones={haulerPricingZones}
                    onSubmit={handleSubmitRollOf}
                    onCancel={onBackButtonClick}
                    isLoading={isLoading}
                    hauler={hauler}
                />
            ) : (
                <HaulerPricingFormUniversal
                    rollOffPricingZones={haulerPricingZones}
                    onSubmit={handleSubmitUniversal}
                    onCancel={onBackButtonClick}
                    isLoading={isLoading}
                    hauler={hauler}
                />
            )}
        </Dialog>
    );
};

export default HaulerPricingCreate;
