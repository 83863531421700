import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Notification } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import {
    commercialBillingFrequencies,
    commercialMaterials,
    commercialServiceFrequency,
    leadTypesEnums,
    mediumTypesEnums,
    routes,
} from '../../utils';
import { USQuoteUpdate } from '../LeadUpdate';
import EmailSendDialog, { type EmailSendDialogProps } from './EmailSendDialog';

const quoteHeadings = ['Sku', 'Service Frequency', 'Quantity', 'Billing Frequency', 'Price Quoted', ''];

const USQuoteDetails = ({ rawLead }: { rawLead: Customer.AllianceLeadTransport }) => {
    const client = useWaysteClient();
    const currentUser = client.user().get();
    const { showFlash } = useContext(UIContext);
    const history = useHistory();
    const [lead, setLead] = useState<Customer.AllianceLeadTransport>(rawLead);
    const [sendingRejection, setSendingRejection] = useState(false);
    const [showQuoteUpdate, setShowQuoteUpdate] = useState(false);
    const [serviceTypeMap, setServiceTypeMap] = useState<{ [key: string]: string }>({});
    const [emailSend, setEmailSend] = useState<{
        open: boolean;
        toEmail: string;
        message: string;
        preText?: React.ReactNode;
        data?: EmailSendDialogProps['emailData'];
    }>({
        open: false,
        toEmail: '',
        message: '',
        preText: null,
    });

    const handleGetServiceTypes = async (quotes: Customer.AllianceLeadQuote[]) => {
        const newMap: { [key: string]: string } = {};
        for (const quote of quotes) {
            if (quote.serviceTypeID && quote.serviceFamilyID) {
                if (!newMap[quote.serviceTypeID]) {
                    const serviceResponse = await client.universalService().serviceType.fetch(quote.serviceFamilyID, quote.serviceTypeID);
                    newMap[quote.serviceTypeID] = serviceResponse.name;
                }
            }
        }
        setServiceTypeMap(newMap);
    };

    useEffect(() => {
        if (!rawLead) return;
        handleGetServiceTypes(rawLead.quotes);
        setLead(rawLead);
    }, [rawLead]);

    const handleRemoveQuote = async (quoteID: string) => {
        try {
            await client.customer().adminPortal.leads.quote.delete(lead.id, quoteID);
            showFlash('Quote Successfully Deleted', 'success');
        } catch (error) {
            console.warn(error);
            showFlash('Error Deleting Quote', 'warning');
        }
    };

    const handleSendDoNotService = () => {
        if (!lead.email) {
            alert('No email sent. Lead does not have an email');
            return;
        }
        let serviceType = 'our service';
        switch (lead.type) {
            case leadTypesEnums.commercial:
                serviceType = 'commercial recurring pickup service';
                break;
            case leadTypesEnums.residential:
                serviceType = 'residential recurring pickup service';
                break;
            case leadTypesEnums.portableToilet:
                serviceType = 'portable toilets';
                break;
            default:
                break;
        }
        const areaText = lead?.serviceLocation?.address.city
            ? `${lead.serviceLocation.address.city}${lead.serviceLocation.address.state ? `, ${lead.serviceLocation.address.state}` : ''}`
            : 'your area';
        const body: Notification.SendGrid.DoNotServiceArea = {
            first_name: lead.firstName || '',
            csa_name: currentUser.firstName || '',
            service_location: areaText,
            service_type: serviceType,
            custom_message: '',
            last_order_date: lead.allianceCustomer ? '2024-01-01' : '',
        };
        setEmailSend({
            ...emailSend,
            open: true,
            toEmail: lead.email,
            data: {
                body,
                topic: 'do-not-service-area',
            },
            preText: (
                <span>
                    You can enter a custom message below. It is optional to add, you don't need to add one. If you enter a custom message it
                    will be inserted as below.
                    <br />
                    <br />
                    Hi {body.first_name},<br />
                    Unfortunately, we do not currently service {body.service_location} with {body.service_type}, but it is definitely in our
                    growth road map!
                    <br />
                    <br />
                    ENTER CUSTOM MESSAGE HERE.
                    <br />
                    <br />
                    If you want to be the first to know when we launch in your area, make sure to sign up to our (short and sweet)
                    newsletters.
                    <br />
                    <br />
                    ...more stuff.
                </span>
            ),
        });
    };

    const onDoNotServiceArea = () => {
        setSendingRejection(true);
        handleSendDoNotService();
    };

    const handleSendQuoteClose = () => {
        setEmailSend({
            open: false,
            toEmail: '',
            message: '',
        });
    };

    const handleEmailSent = async () => {
        handleSendQuoteClose();
        if (sendingRejection) {
            try {
                await client.customer().adminPortal.leads.update(lead.id, {
                    status: 'lost',
                    reasonLost: 'outOfArea',
                    reasonLostNote: '',
                });

                showFlash('Lead Successfully Updated', 'success');
            } catch (error) {
                showFlash('Error Updating Lead', 'warning');
            }
        }
        const event = {
            date: new Date().toISOString(),
            medium: mediumTypesEnums.email,
        };

        try {
            await client.customer().adminPortal.leads.contactEvent.create(lead.id, event);
            showFlash('Lead Successfully Updated', 'success');
        } catch (error) {
            showFlash('Error Updating Lead', 'warning');
        }

        setSendingRejection(false);
    };

    return (
        <div className="shadow-dark rounded bg-white py-1.5">
            <div className="px-4">
                <div className="flex items-center justify-between">
                    <div className="text-xl">Quote Details for Customer</div>
                    <div className="flex flex-row gap-x-4">
                        <Button
                            className="btn-secondary-outlined"
                            onClick={() => history.push(routes.requestForProposal.new + `?forType=sourgum-lead&id=${lead.id}`)}
                        >
                            Create RFP
                        </Button>
                        <button className="btn-dark-grey-outlined" onClick={() => onDoNotServiceArea()}>
                            Don't Service Area
                        </button>
                        <button className="btn-primary" type="button" onClick={() => setShowQuoteUpdate(true)}>
                            <PlusIcon className="mr-1.5 h-5 w-5" />
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <hr className="mt-2" />
            <div className="-mb-1.5 w-full overflow-x-auto">
                <table className="w-full border-collapse border-spacing-0 text-sm">
                    <thead>
                        <tr className="bg-gray-100 text-left [&>*]:whitespace-nowrap [&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal">
                            {quoteHeadings.map((item) => (
                                <th key={item}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {lead.quotes.map((item) => (
                            <tr key={item.id} className={`[&>*]:border-b [&>*]:px-4 [&>*]:py-1.5 ${item.active ? '' : 'bg-disabled'}`}>
                                <td>
                                    {
                                        // This is the old US lead implementation
                                        item.size?.size && item.material
                                            ? `${item.size?.size} ${
                                                  Number(item.size.size) === 96 || Number(item.size.size) === 64 ? 'Gal' : 'YD'
                                              } - ${commercialMaterials[item.material as keyof typeof commercialMaterials]}`
                                            : // Thi is the new US lead implementation
                                              serviceTypeMap[item.serviceTypeID as string]
                                    }
                                </td>
                                <td>{commercialServiceFrequency[item.frequency as keyof typeof commercialServiceFrequency]}</td>
                                <td>{item.quantity}</td>
                                <td>{commercialBillingFrequencies[item.billingFrequency as keyof typeof commercialBillingFrequencies]}</td>
                                <td>{moneyFormatter(item.price)}</td>
                                <td>
                                    {item.active ? (
                                        <button className="btn-icon" type="button" onClick={() => handleRemoveQuote(item.id)}>
                                            <XMarkIcon className="text-delete size-6" />
                                        </button>
                                    ) : null}
                                </td>
                            </tr>
                        ))}
                        <tr className="[&>*]:px-4 [&>*]:py-1.5">
                            <td colSpan={5} className="text-right">
                                <b>Total</b>
                            </td>
                            <td>{moneyFormatter(lead.quotes.reduce((a: number, b) => a + (b.price || 0), 0))}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {/* Dialogs */}
            <USQuoteUpdate lead={lead} open={showQuoteUpdate} onClose={() => setShowQuoteUpdate(false)} />
            <EmailSendDialog
                open={emailSend.open}
                message={emailSend.message}
                onClose={handleSendQuoteClose}
                onEmailSent={handleEmailSent}
                preText={emailSend.preText}
                emailData={emailSend.data}
                toEmail={emailSend.toEmail}
            />
        </div>
    );
};

export default USQuoteDetails;
