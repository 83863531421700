import type { Customer, Pricing } from '@alliance-disposal/transport-types';
import { Dialog } from '@wayste/sour-ui';

type DeletePricingZoneProps = {
    handleClose: () => void;
    handleDeletePricingZone: (customDiscountID: string) => Promise<void>;
    customDiscount?: Pricing.CustomerDiscountTransport;
    customer?: Customer.AllianceCustomerTransport;
};

const DeleteCustomPricingModal = ({ handleClose, handleDeletePricingZone, customDiscount, customer }: DeletePricingZoneProps) => {
    const onConfirmDelete = () => {
        if (!customDiscount?.id) return;
        handleDeletePricingZone(customDiscount.id);
        handleClose();
    };
    const RenderDialogContent = () => {
        if (!customDiscount?.id) return null;
        return (
            <div>
                <span>
                    Are you sure you want to delete the <span style={{ fontWeight: 'bold' }}>{customDiscount.pricingZone?.zoneName}</span>{' '}
                    custom pricing zone for{' '}
                    <span style={{ fontWeight: 'bold' }}>{customer?.contacts[0].firstName + ' ' + customer?.contacts[0].lastName}</span>?
                </span>
            </div>
        );
    };
    return (
        <Dialog open={Boolean(customDiscount?.id)} onClose={handleClose} showX variant="delete" styledTitle="Delete Pricing Zone">
            <RenderDialogContent />
            <div className="mt-4 flex justify-end gap-4">
                <button className="btn-dark-grey-outlined" onClick={handleClose}>
                    Cancel
                </button>
                <button onClick={onConfirmDelete} className="btn-delete">
                    Delete Pricing
                </button>
            </div>
        </Dialog>
    );
};

export default DeleteCustomPricingModal;
