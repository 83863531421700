import React, { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { V1 } from '@alliance-disposal/pricing';
import { Customer, Material, MaterialLabels, Pricing } from '@alliance-disposal/transport-types';
import { Button, Checkbox, CurrencyTextField, Loading, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { Controller, useForm } from 'react-hook-form';
import { ccRate, priceTypes, priceTypesEnums } from '../../../utils';
import { calculateCustomDiscount } from '../utils/calculate-discount';

type SizeDiscountInfo = {
    size: number;
    available: boolean;
    haulRateDiscount: number | '';
    tonLimitDiscount: number | '';
    dumpRateDiscount: number | '';
    overageDiscountRate: number | '';
};

interface FormProps {
    material?: string;
    globalHaulRateDiscount: number | '';
    globalDumpRateDiscount: number | '';
    globalOverageRateDiscount: number | '';
    sizeMap: Record<string, SizeDiscountInfo>;
}

const CustomMaterialPricingForm = ({
    pricingZone,
    selectedDiscount,
    selectedDiscountData,
    handleClose,
    onSubmit,
    customer,
}: {
    selectedDiscount: Pricing.CustomerDiscountTransport;
    pricingZone: Pricing.CustomerDiscountTransport['pricingZone'];
    selectedDiscountData?: Pricing.DiscountDataTransport;
    customer?: Customer.AllianceCustomerTransport;
    onSubmit: () => void;
    handleClose: () => void;
}) => {
    const client = useWaysteClient();
    const [selectedStandardMaterialData, setSelectedStandardMaterialData] = useState<Pricing.PricingDataTransport>();
    const [materialOptions, setMaterialOptions] = useState<string[]>([]);
    // standard/ default pricing data of specific pricing zone
    const [pricingDataMaterialsMap, setPricingDataMaterialsMap] = useState<Record<string, Pricing.PricingDataTransport>>();
    const [standardSizeMap, setStandardSizeMap] = useState<Record<string, Pricing.PricingSizeDataTransport>>();
    const [editAllControls, setEditAllControls] = useState({ haul: false, dump: false, overage: false });
    const [isLoading, setIsLoading] = useState(false);
    const [discountTypeLabel, setDiscountTypeLabel] = useState<'Fixed' | 'Discount'>(
        selectedDiscount.discountType === 'FIXED' ? 'Fixed' : 'Discount',
    );
    const [discountType, setDiscountType] = useState<
        Pricing.CustomerDiscountType.DOLLAR | Pricing.CustomerDiscountType.FIXED | Pricing.CustomerDiscountType.PERCENTAGE
    >(selectedDiscount.discountType);

    useEffect(() => {
        setDiscountType(selectedDiscount.discountType);
        setDiscountTypeLabel(selectedDiscount.discountType === 'FIXED' ? 'Fixed' : 'Discount');
    }, [selectedDiscount]);

    const {
        setValue,
        handleSubmit: handleRHFSubmit,
        watch,
        reset,
        control,
    } = useForm<FormProps>({
        mode: 'all',
        shouldFocusError: false,
        defaultValues: {
            material: '',
            globalHaulRateDiscount: '',
            globalDumpRateDiscount: '',
            globalOverageRateDiscount: '',
            sizeMap: {},
        },
    });

    const watchMaterial = watch('material');
    const watchSizeMap = watch('sizeMap');

    const handleSave = async (materialDiscount: Pricing.DiscountDataTransport) => {
        try {
            if (!pricingZone?.id) return;
            const payload: Pricing.DiscountDataTransport = {
                ...materialDiscount,
            };

            let updatedDiscountData = [...selectedDiscount.discountData];

            const materialExistIndex = updatedDiscountData.findIndex((material) => material.material === materialDiscount.material);
            // if exist, replace, else push...
            if (materialExistIndex === undefined || materialExistIndex === -1) {
                updatedDiscountData = updatedDiscountData.map((material) => {
                    return {
                        material: material.material,
                        sizes: material.sizes.map((size) => {
                            const sizeMapped: Pricing.PricingSizeDataTransport = {
                                haul: size.haul,
                                dump: size.dump,
                                over: size.over,
                                tonLimit: size.tonLimit,
                                size: size.size,
                            };
                            return sizeMapped;
                        }),
                    };
                });

                updatedDiscountData.push(payload);
            } else {
                updatedDiscountData = updatedDiscountData.map((material) => {
                    return {
                        material: material.material,
                        sizes: material.sizes.map((size) => {
                            const sizeMapped: Pricing.PricingSizeDataTransport = {
                                haul: size.haul,
                                dump: size.dump,
                                over: size.over,
                                tonLimit: size.tonLimit,
                                size: size.size,
                            };
                            return sizeMapped;
                        }),
                    };
                });
                updatedDiscountData[materialExistIndex] = payload;
            }
            await client.pricing().adminPortal.customerDiscount.update(selectedDiscount.id, {
                discountData: updatedDiscountData,
            });
            onSubmit();
        } catch (error) {
            console.warn('error', error);
            alert('An error has occurred, please contact an AAP dev.');
        }
    };

    const onFormSubmit = (data: FormProps) => {
        if (!watchMaterial || !pricingZone?.id) {
            return;
        }
        const sizeMap = data.sizeMap;
        const cleanedSize = Object.keys(sizeMap).map((key) => {
            const x = sizeMap[key];
            if (!x.available) {
                const updateSizePayload: Pricing.PricingSizeDataTransport = {
                    size: String(x.size),
                    dump: 0,
                    haul: 0,
                    over: 0,
                    // -420 === null | Luc said have to pass up -420 because that equals null in this fucked up world we live in
                    tonLimit: null,
                };
                return updateSizePayload;
            }
            const updateSizePayload: Pricing.PricingSizeDataTransport = {
                size: String(x.size),
                dump: x.dumpRateDiscount || 0,
                // -420 === null | Luc said have to pass up -420 because that equals null in this fucked up world we live in
                tonLimit: x.tonLimitDiscount || x.tonLimitDiscount === 0 ? x.tonLimitDiscount : null,
                haul: x.haulRateDiscount || 0,
                over: x.overageDiscountRate || 0,
            };
            return updateSizePayload;
        });
        handleSave({
            material: watchMaterial,
            sizes: cleanedSize ?? [],
        });
        handleClose();
    };

    const handleMaterialSelect = (material: string) => {
        if (selectedDiscountData) return;
        reset();
        const selectedMat = pricingDataMaterialsMap?.[material as keyof typeof pricingDataMaterialsMap];
        if (!selectedMat) return;
        setSelectedStandardMaterialData(selectedMat);
        setValue('material', material);
        setValue('globalHaulRateDiscount', 0);
        const standardSizeRecord: Record<string, Pricing.PricingSizeDataTransport> = {};
        const sizeDiscountMap: Record<string, SizeDiscountInfo> = {};
        selectedMat.sizes.forEach((x) => {
            standardSizeRecord[x.size] = {
                haul: x?.haul || 0,
                tonLimit: x.tonLimit,
                dump: x?.dump || 0,
                size: x.size,
                over: x?.over || 0,
            };
            sizeDiscountMap[x.size] = {
                available: true,
                dumpRateDiscount: '',
                haulRateDiscount: '',
                overageDiscountRate: '',
                size: +x.size,
                tonLimitDiscount: '',
            };
        });
        setStandardSizeMap(standardSizeRecord);
        setValue('sizeMap', sizeDiscountMap);
    };

    const fetchPricingDataForZone = async () => {
        if (!pricingZone?.id) {
            console.warn('pricing zone id is null');
            return;
        }
        setIsLoading(true);
        try {
            const pricingData = await client.pricing().adminPortal.pricing.fetch(pricingZone.id);
            const fullPricingDataForZone = pricingData;
            const pricingDataMaterialsMap: Record<string, Pricing.PricingDataTransport> = {};
            fullPricingDataForZone?.pricingData?.forEach((x) => {
                pricingDataMaterialsMap[x.material] = x;
            });
            setPricingDataMaterialsMap(pricingDataMaterialsMap);

            if (!selectedDiscountData) {
                // adding new material, filter out existing material from selection
                // we get standard size/mat information on material select
                const existingMaterialKeys: Record<string, boolean> = {};
                selectedDiscount.discountData.forEach((x) => {
                    existingMaterialKeys[x.material] = true;
                });
                const keys = Object.keys(pricingDataMaterialsMap).filter((x) => !existingMaterialKeys[x]);
                setMaterialOptions(keys.sort((a, b) => (MaterialLabels[a as Material] > MaterialLabels[b as Material] ? 1 : -1)));
            } else {
                const standardPricingForMaterial = pricingDataMaterialsMap[selectedDiscountData.material];
                if (!standardPricingForMaterial) {
                    return;
                }
                const discountSizeMap: Record<string, SizeDiscountInfo> = {};
                selectedDiscountData.sizes.forEach((x) => {
                    const discountSize: SizeDiscountInfo = {
                        haulRateDiscount: x?.haul || '',
                        tonLimitDiscount: x.tonLimit || x.tonLimit === 0 ? x.tonLimit : '',
                        dumpRateDiscount: x?.dump || '',
                        size: +x.size,
                        overageDiscountRate: x?.over || '',
                        available: true,
                    };
                    discountSizeMap[x.size] = discountSize;
                });
                const standardSizeRecord: Record<string, Pricing.PricingSizeDataTransport> = {};
                standardPricingForMaterial.sizes.forEach((x) => {
                    standardSizeRecord[x.size] = {
                        haul: x?.haul || 0,
                        tonLimit: x.tonLimit || 0,
                        dump: x?.dump || 0,
                        size: x.size,
                        over: x?.over || 0,
                    };
                    if (!discountSizeMap[x.size]) {
                        discountSizeMap[x.size] = {
                            available: true,
                            dumpRateDiscount: '',
                            haulRateDiscount: '',
                            overageDiscountRate: '',
                            size: +x.size,
                            tonLimitDiscount: '',
                        };
                    }
                });
                setMaterialOptions(
                    Object.keys(pricingDataMaterialsMap).sort((a, b) =>
                        MaterialLabels[a as Material] > MaterialLabels[b as Material] ? 1 : -1,
                    ),
                );
                setSelectedStandardMaterialData(standardPricingForMaterial);
                setStandardSizeMap(standardSizeRecord);
                setValue('material', selectedDiscountData.material);
                setValue('globalHaulRateDiscount', 0);
                setValue('globalDumpRateDiscount', 0);
                setValue('globalOverageRateDiscount', 0);
                setValue('sizeMap', discountSizeMap);
            }
        } catch (error) {
            console.warn('ERROR', error);
            alert('An error has occurred, please contact a SAP dev.');
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchPricingDataForZone();
    }, []);

    const standardPrice = (standardSizeInfo: Pricing.PricingSizeDataTransport) => {
        const price = V1.quotedPriceBuild(
            selectedStandardMaterialData?.type === priceTypesEnums.yard ? +standardSizeInfo.size : standardSizeInfo.tonLimit || 0,
            standardSizeInfo?.dump || 0,
            standardSizeInfo?.haul || 0,
            selectedStandardMaterialData?.tax && !customer?.taxExempt ? V1.findTaxRate(pricingZone?.state || '') : 0,
            !customer?.defaultPaymentSettings?.paymentMethod || customer?.defaultPaymentSettings?.paymentMethod === 'creditCard'
                ? ccRate
                : 0,
            true,
            0,
        );
        return V1.round(price.total);
    };

    const discountPrice = (standardSizeInfo: Pricing.PricingSizeDataTransport, discountSizeInfo: SizeDiscountInfo) => {
        const price = V1.quotedPriceBuild(
            selectedStandardMaterialData?.type === priceTypesEnums.yard
                ? +standardSizeInfo.size
                : (standardSizeInfo.tonLimit || 0) - -(discountSizeInfo.tonLimitDiscount || 0),
            (standardSizeInfo?.dump || 0) - (discountSizeInfo.dumpRateDiscount || 0),
            (standardSizeInfo?.haul || 0) - (discountSizeInfo.haulRateDiscount || 0),
            selectedStandardMaterialData?.tax && !customer?.taxExempt ? V1.findTaxRate(pricingZone?.state || '') : 0,
            !customer?.defaultPaymentSettings?.paymentMethod || customer?.defaultPaymentSettings?.paymentMethod === 'creditCard'
                ? ccRate
                : 0,
            true,
            0,
        );
        return price;
    };

    const discountPriceFixed = (standardSizeInfo: Pricing.PricingSizeDataTransport, discountSizeInfo: SizeDiscountInfo) => {
        const price = V1.quotedPriceBuild(
            selectedStandardMaterialData?.type === priceTypesEnums.yard
                ? +standardSizeInfo.size
                : discountSizeInfo.tonLimitDiscount || discountSizeInfo.tonLimitDiscount === 0
                  ? discountSizeInfo.tonLimitDiscount
                  : standardSizeInfo.tonLimit || 0,
            discountSizeInfo.dumpRateDiscount || standardSizeInfo?.dump || 0,
            discountSizeInfo.haulRateDiscount || standardSizeInfo?.haul || 0,
            selectedStandardMaterialData?.tax && !customer?.taxExempt ? V1.findTaxRate(pricingZone?.state || '') : 0,
            !customer?.defaultPaymentSettings?.paymentMethod || customer?.defaultPaymentSettings?.paymentMethod === 'creditCard'
                ? ccRate
                : 0,
            true,
            0,
        );
        return price;
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center">
                <Loading />
            </div>
        );
    }

    const displayNewHaulRate = (key: string) => {
        if (!standardSizeMap) return undefined;
        const newRate = calculateCustomDiscount(discountType, standardSizeMap[key]?.haul, watchSizeMap[key]?.haulRateDiscount);
        return newRate !== undefined ? moneyFormatter(newRate) : moneyFormatter(standardSizeMap[key]?.haul);
    };

    const displayNewDumpRate = (key: string) => {
        if (!standardSizeMap) return undefined;
        const newRate = calculateCustomDiscount(discountType, standardSizeMap[key]?.dump || undefined, watchSizeMap[key]?.dumpRateDiscount);
        return newRate !== undefined ? moneyFormatter(newRate) : moneyFormatter(standardSizeMap[key]?.dump);
    };

    const displayNewOverageRate = (key: string) => {
        if (!standardSizeMap) return undefined;
        const newRate = calculateCustomDiscount(
            discountType,
            standardSizeMap[key]?.over || undefined,
            watchSizeMap[key]?.overageDiscountRate,
        );
        return newRate !== undefined ? moneyFormatter(newRate) : moneyFormatter(standardSizeMap[key]?.over);
    };

    const displayTons = (key: string) => {
        if (!standardSizeMap) return undefined;
        const newRate = calculateCustomDiscount(
            discountType,
            standardSizeMap[key]?.tonLimit || undefined,
            discountType === 'FIXED' ? watchSizeMap[key]?.tonLimitDiscount : -watchSizeMap[key]?.tonLimitDiscount,
        );
        return newRate !== undefined ? newRate : standardSizeMap[key]?.tonLimit;
    };

    const displayNewPrice = (key: string) => {
        if (!standardSizeMap) return undefined;
        if (discountType === Pricing.CustomerDiscountType.FIXED) {
            return discountPriceFixed(standardSizeMap[key], watchSizeMap[key]).total;
        }
        return discountPrice(standardSizeMap[key], watchSizeMap[key]).total;
    };

    const handleGlobalDiscountChange = (value: number | '', keyName: string) => {
        const sizeMapClone = {
            ...watchSizeMap,
        };
        Object.keys(watchSizeMap).forEach((key) => {
            const oldValues = sizeMapClone[key];
            if (!oldValues.available) return;
            const sizeDiscountInfo: SizeDiscountInfo = {
                ...oldValues,
                [keyName]: value || 0,
            };
            sizeMapClone[key] = sizeDiscountInfo;
        });
        setValue('sizeMap', sizeMapClone);
    };

    return (
        <form onSubmit={handleRHFSubmit(onFormSubmit)} className="ml-3 flex flex-col gap-4">
            <div className="flex w-full justify-between gap-y-16 px-3">
                <div className="w-1/2">
                    <Select
                        label="Material"
                        onSelect={(value) => handleMaterialSelect(value)}
                        value={watchMaterial}
                        disabled={Boolean(selectedDiscountData)}
                    >
                        {materialOptions.map((material) => (
                            <SelectOption value={material}>{MaterialLabels[material as Material]}</SelectOption>
                        ))}
                    </Select>
                </div>
                <div>{discountType === 'FIXED' ? 'Fixed Price' : 'Dollars Off of Public'}</div>
                <div className="flex flex-col">
                    <span className="font-bold underline">Price Type</span>
                    <span className="text-blue-gray-500">
                        {selectedStandardMaterialData
                            ? (priceTypes[selectedStandardMaterialData.type as keyof typeof priceTypes] ??
                              selectedStandardMaterialData.type)
                            : 'Select a material'}
                    </span>
                </div>
            </div>
            {Boolean(watchMaterial && selectedStandardMaterialData) && (
                <>
                    <p className="text-blue-gray-500 text-center text-sm italic">
                        If you do not add a custom rate, it will not override the standard rate.
                        <br />
                        Discount off of public subtracts the amount you enter from the standard public pricing to calculate the customer's
                        price.
                        <br />
                        With fixed price the amount you enter is the amount the customer will pay, regardless if public pricing is changed.
                    </p>
                    {(selectedStandardMaterialData?.type === priceTypesEnums.ton ||
                        selectedStandardMaterialData?.type === priceTypesEnums.yard) && (
                        <div className="flex w-full flex-col">
                            <div className="flex">
                                <Checkbox
                                    label="Edit all hauls"
                                    inputProps={{
                                        onClick: () => setEditAllControls({ ...editAllControls, haul: !editAllControls.haul }),
                                        checked: editAllControls.haul,
                                    }}
                                />
                                <Checkbox
                                    label="Edit all dumps"
                                    inputProps={{
                                        onClick: () => setEditAllControls({ ...editAllControls, dump: !editAllControls.dump }),
                                        checked: editAllControls.dump,
                                    }}
                                />
                                {selectedStandardMaterialData?.type === priceTypesEnums.ton && (
                                    <Checkbox
                                        label="Edit all overages"
                                        inputProps={{
                                            onClick: () => setEditAllControls({ ...editAllControls, overage: !editAllControls.overage }),
                                            checked: editAllControls.overage,
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    <div className="grid w-full grid-cols-3 items-center gap-4">
                        <p className="mb-2 font-bold underline">Standard rate</p>
                        <p className="mb-2 font-bold underline">{discountTypeLabel} amount</p>
                        <p className="mb-2 font-bold underline">Custom rate</p>
                        {/* START Edit All Buttons */}
                        {editAllControls.haul && (
                            <>
                                <div />
                                <div className="w-40">
                                    <Controller
                                        name="globalHaulRateDiscount"
                                        control={control}
                                        rules={{ required: 'Haul rate is required' }}
                                        render={({ field, fieldState }) => (
                                            <CurrencyTextField
                                                label={`${discountTypeLabel} haul`}
                                                placeholder="Haul Rate"
                                                required
                                                value={field.value}
                                                useCents
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                    handleGlobalDiscountChange(value, 'haulRateDiscount');
                                                }}
                                                error={fieldState.error}
                                            />
                                        )}
                                    />
                                </div>
                                <div />
                            </>
                        )}
                        {editAllControls.dump && (
                            <>
                                <div />
                                <div className="w-40">
                                    <Controller
                                        name="globalDumpRateDiscount"
                                        control={control}
                                        rules={{ required: 'Dump rate is required' }}
                                        render={({ field, fieldState }) => (
                                            <CurrencyTextField
                                                label={`${discountTypeLabel} dump`}
                                                placeholder="Dump Rate"
                                                useCents
                                                required
                                                value={field.value}
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                    handleGlobalDiscountChange(value, 'dumpRateDiscount');
                                                }}
                                                error={fieldState.error}
                                            />
                                        )}
                                    />
                                </div>
                                <div />
                            </>
                        )}
                        {editAllControls.overage && (
                            <>
                                <div />
                                <div className="w-40">
                                    <Controller
                                        name="globalOverageRateDiscount"
                                        control={control}
                                        rules={{ required: 'Overage rate is required' }}
                                        render={({ field, fieldState }) => (
                                            <CurrencyTextField
                                                label={`${discountTypeLabel} overage`}
                                                placeholder="Overage Rate"
                                                required
                                                value={field.value}
                                                useCents
                                                error={fieldState.error}
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                    handleGlobalDiscountChange(value, 'overageDiscountRate');
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div />
                            </>
                        )}
                        {/* END Edit All Buttons */}
                        <p className="text-blue-gray-500 col-span-3 text-left">Available Sizes</p>
                        {standardSizeMap &&
                            Object.keys(standardSizeMap)?.map((key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <div className="flex items-center gap-2">
                                            <div>
                                                <Checkbox
                                                    label={standardSizeMap[key]?.size}
                                                    inputProps={{
                                                        onClick: () => setValue(`sizeMap.${key}.available`, !watchSizeMap[key].available),
                                                        checked: watchSizeMap[key].available,
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <p>{moneyFormatter(standardPrice(standardSizeMap[key]))}</p>
                                                <p>{`${standardSizeMap?.[String(standardSizeMap[key]?.size)]?.tonLimit ?? 0} tons`}</p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-4">
                                            <Controller
                                                name={`sizeMap.${[key]}.haulRateDiscount`}
                                                control={control}
                                                render={({ field }) => (
                                                    <CurrencyTextField
                                                        label={`${discountTypeLabel} haul`}
                                                        value={field.value}
                                                        useCents
                                                        onChange={(value) => field.onChange(value)}
                                                        inputProps={{
                                                            disabled: !watchSizeMap[key].available || editAllControls.haul,
                                                        }}
                                                    />
                                                )}
                                            />

                                            {(selectedStandardMaterialData?.type === priceTypesEnums.ton ||
                                                selectedStandardMaterialData?.type === priceTypesEnums.yard) && (
                                                <Controller
                                                    name={`sizeMap.${[key]}.dumpRateDiscount`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <CurrencyTextField
                                                            label={`${discountTypeLabel} dump`}
                                                            value={field.value}
                                                            useCents
                                                            onChange={(value) => field.onChange(value)}
                                                            inputProps={{
                                                                disabled: !watchSizeMap[key].available || editAllControls.dump,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                            {selectedStandardMaterialData?.type === priceTypesEnums.ton && (
                                                <>
                                                    <Controller
                                                        name={`sizeMap.${[key]}.overageDiscountRate`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <CurrencyTextField
                                                                label={`${discountTypeLabel} overage`}
                                                                value={field.value}
                                                                useCents
                                                                onChange={(value) => field.onChange(value)}
                                                                inputProps={{
                                                                    disabled: !watchSizeMap[key].available || editAllControls.overage,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        name={`sizeMap.${[key]}.tonLimitDiscount`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                label={
                                                                    discountType === Pricing.CustomerDiscountType.FIXED
                                                                        ? 'Included tons'
                                                                        : 'Add tons'
                                                                }
                                                                type="number"
                                                                inputProps={{
                                                                    ...field,
                                                                    disabled: !watchSizeMap[key].available,
                                                                    step: '0.01',
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            <div className="grid grid-cols-2 text-xs">
                                                <span>Haul rate:</span>
                                                <span>{displayNewHaulRate(key)}</span>
                                                {(selectedStandardMaterialData?.type === priceTypesEnums.ton ||
                                                    selectedStandardMaterialData?.type === priceTypesEnums.yard) && (
                                                    <>
                                                        <span>Dump rate:</span>
                                                        <span>{displayNewDumpRate(key)}</span>
                                                    </>
                                                )}
                                                {selectedStandardMaterialData?.type === priceTypesEnums.ton && (
                                                    <>
                                                        <span>Overage rate:</span>
                                                        <span>{displayNewOverageRate(key)}</span>
                                                        <span>Tons:</span>
                                                        <span>{displayTons(key)}</span>
                                                    </>
                                                )}
                                                <span>New price:</span>
                                                <div>{moneyFormatter(displayNewPrice(key))}</div>
                                            </div>
                                        </div>
                                        <hr className="col-span-3 mb-1" />
                                    </React.Fragment>
                                );
                            })}
                    </div>
                </>
            )}
            <div className="flex justify-end gap-4">
                <Button className="btn-dark-grey-outlined" onClick={handleClose}>
                    Cancel
                </Button>
                <Button className="btn-primary" type="submit">
                    Save
                </Button>
            </div>
        </form>
    );
};

export default CustomMaterialPricingForm;
