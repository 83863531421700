import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Notification, UniversalService } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Loading, Select, SelectOption, Textarea } from '@wayste/sour-ui';
import { formatEmailDestination } from '@wayste/utils';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useHistory, useParams } from 'react-router-dom';
import { routes } from '../../utils';

type ResponseToVendor = {
    message: string;
    status: UniversalService.RFPStatus;
    vendorResponse: UniversalService.RFPVendorResponseTransport;
};

type VendorResponses = {
    [key: string]: ResponseToVendor;
};

const AwardRFP = () => {
    const client = useWaysteClient();
    const { id }: { id: string } = useParams();
    const history = useHistory();
    const { setShowToast } = useSourContext();
    const [rfp, setRFP] = useState<UniversalService.RFPTransport | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSending, setIsSending] = useState(false);
    const [selectedWinner, setSelectedWinner] = useState<string>('');
    const [vendorResponses, setVendorResponses] = useState<VendorResponses>({});

    const handleFetchRFP = async (id: string) => {
        setIsLoading(true);
        try {
            const rfpResponse = await client.universalService().adminPortal.rfp.fetch(id);
            setRFP(rfpResponse);
            setVendorResponses(
                rfpResponse.rfpVendorResponses.reduce((acc: VendorResponses, response) => {
                    acc[response.id] = { message: '', status: 'LOST', vendorResponse: response };
                    return acc;
                }, {}),
            );
        } catch (error) {
            console.error('Error fetching RFP', error);
            alert('Error fetching RFP, get a SAP dev');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleFetchRFP(id);
    }, [id]);

    const handleWinnerSelected = (winnerID: string) => {
        const newVendorResponses = { ...vendorResponses };
        newVendorResponses[winnerID] = { ...newVendorResponses[winnerID], message: '', status: 'AWARD' };
        if (selectedWinner) {
            newVendorResponses[selectedWinner] = { ...newVendorResponses[selectedWinner], message: '', status: 'LOST' };
        }
        setVendorResponses(newVendorResponses);
        setSelectedWinner(winnerID);
    };

    const handleMessageChange = (id: string, message: string) => {
        setVendorResponses({
            ...vendorResponses,
            [id]: {
                ...vendorResponses[id],
                message,
            },
        });
    };

    const handleEmailSend = async (vendorResponse: ResponseToVendor) => {
        if (!vendorResponse.vendorResponse.vendorID || !rfp) return { error: 'No vendor ID' };
        let hauler;
        try {
            hauler = await client.vendorService().adminPortal.fetch(vendorResponse.vendorResponse.vendorID);
        } catch (error) {
            console.warn('Error fetching vendor', error);
            return { error: 'Error fetching vendor' };
        }
        const rfpContacts = hauler.contacts.filter((contact) => contact.email && contact.sendRFPEmails);
        if (rfpContacts.length === 0) {
            console.warn('No emails to send to');
            return { error: 'Hauler missing contacts with Receives RFP' };
        }
        const ccEmails = rfpContacts.filter((contact) => contact.email !== rfpContacts[0].email).map((contact) => contact.email as string);
        try {
            const destination = formatEmailDestination(rfpContacts[0].email as string, rfpContacts[0]?.firstName || '', ccEmails, []);
            if (vendorResponse.status === 'AWARD') {
                const emailDataAward: Notification.SendGrid.VendorRFPAwarded = {
                    vendor_name: hauler?.name || '',
                    first_name: rfpContacts[0]?.firstName || '',
                    award_message: vendorResponse.message,
                    vendor_response_id: vendorResponse.vendorResponse.id,
                    proposal_number: rfp.proposalNumber.toString(),
                };
                await client.notification().adminPortal.createInstantNotification({
                    handler: 'sendgrid',
                    topic: 'vendor-rfp-awarded',
                    destination,
                    body: JSON.stringify(emailDataAward),
                });
            } else if (vendorResponse.status === 'LOST') {
                const emailDataLost: Notification.SendGrid.VendorRFPLost = {
                    vendor_name: hauler?.name || '',
                    first_name: rfpContacts[0]?.firstName || '',
                    lost_message: vendorResponse.message,
                    proposal_number: rfp.proposalNumber.toString(),
                };
                await client.notification().adminPortal.createInstantNotification({
                    handler: 'sendgrid',
                    topic: 'vendor-rfp-lost',
                    destination,
                    body: JSON.stringify(emailDataLost),
                });
            } else {
                return { error: 'Invalid status' };
            }
            return {};
        } catch (error) {
            console.warn('Error sending email to vendor', error);
            return { error: 'Error sending email to vendor' };
        }
    };

    const handleSaveRFPVendorResponse = async (vendorResponse: ResponseToVendor) => {
        if (!rfp) return;
        const updateObject: UniversalService.RFPVendorResponseUpdate = {
            status: vendorResponse.status,
            accessLinkExpirationDate: new Date().toISOString(),
        };
        if (vendorResponse.status === 'AWARD') {
            updateObject.awardMessage = vendorResponse.message;
            updateObject.awardDate = new Date().toISOString();
        } else if (vendorResponse.status === 'LOST' && vendorResponse.message) {
            updateObject.lostMessage = vendorResponse.message;
        }
        try {
            const response = await client
                .universalService()
                .adminPortal.rfpVendorResponse.update(vendorResponse.vendorResponse.id, updateObject);
            return response;
        } catch (error) {
            console.warn('Error sending RFP to vendor', error);
            return null;
        }
    };

    const handleSaveAndSend = async () => {
        if (!rfp) return;
        setIsSending(true);
        // Update the RFP first
        try {
            await client.universalService().adminPortal.rfp.update(rfp.id, {
                status: 'AWARD',
                awardDate: new Date().toISOString(),
            });
        } catch (error) {
            console.warn('Error updating RFP', error);
            alert('Error updating RFP, nothing updated and no emails sent, get a SAP dev');
            setIsSending(false);
            return;
        }
        // Then loop through each vendor response, update the status and send the email
        const failedTransactions: { vendorName: string; failedEmail?: string; failedDB?: string }[] = [];
        const vendorResponsesArray = Object.values(vendorResponses).sort((a) => (a.status === 'AWARD' ? -1 : 1));
        setIsLoading(true);
        for (const vendorResponse of vendorResponsesArray) {
            const dbResponse = await handleSaveRFPVendorResponse(vendorResponse);
            if (!dbResponse) {
                failedTransactions.push({
                    vendorName: vendorResponse.vendorResponse.vendorName as string,
                    failedEmail: undefined,
                    failedDB: 'Failed to write to database and no email sent',
                });
                continue;
            }
            const emailResponse = await handleEmailSend(vendorResponse);
            if (emailResponse.error) {
                failedTransactions.push({
                    vendorName: vendorResponse.vendorResponse.vendorName as string,
                    failedEmail: emailResponse.error,
                });
                continue;
            }
        }
        if (failedTransactions.length > 0) {
            alert(
                'Failed to send RFP messages to the following vendors. Get a SAP dev: ' +
                    failedTransactions
                        .map(
                            (vendor) =>
                                `Name: ${vendor.vendorName} - ${vendor.failedDB || `Failed to send email because: ${vendor.failedEmail}`} `,
                        )
                        .join(', '),
            );
            if (
                failedTransactions.findIndex(
                    (failure) => failure?.vendorName === vendorResponses[selectedWinner]?.vendorResponse?.vendorName,
                ) > -1
            ) {
                alert('RFP DID NOT GET SENT TO WINNER');
            }
        } else {
            setShowToast({
                severity: 'success',
                message: 'RFP Successfully Awarded',
            });
        }
        setIsSending(false);
        history.push(routes.requestForProposal.details(rfp.id));
    };

    if (isLoading) {
        <Loading fullScreen />;
    }

    if (!rfp || rfp.status !== 'OPEN') {
        return (
            <div className="mx-auto flex w-full max-w-screen-lg flex-col items-center justify-center p-5">
                <div>Unable to Award RFP</div>
                {!rfp ? (
                    <div>RFP does not exist</div>
                ) : (
                    <div>RFP is not open, RFP is {UniversalService.RFPStatusLabels[rfp.status]}. Only open RFP's can be awarded</div>
                )}
            </div>
        );
    }

    return (
        <div className="mx-auto flex w-full max-w-screen-lg flex-col gap-y-6 p-5">
            <div className="flex items-center gap-x-4">
                <Button
                    className="btn-dark-grey-outlined"
                    onClick={() => history.push(routes.requestForProposal.details(id))}
                    startIcon={<ChevronLeftIcon className="h-5 w-5" />}
                    disabled={isSending}
                >
                    Back
                </Button>
                <h1 className="text-xl font-bold">Award RFP</h1>
            </div>
            <div>
                <Select label="Select winner" onSelect={(value) => handleWinnerSelected(value)} value={selectedWinner}>
                    {rfp.rfpVendorResponses.map((response) => (
                        <SelectOption key={response.id} value={response.id}>
                            {response.vendorName}
                        </SelectOption>
                    ))}
                </Select>
            </div>
            <div>
                <Textarea
                    label="Optional message to winner"
                    inputProps={{
                        value: selectedWinner ? vendorResponses[selectedWinner].message : '',
                        onChange: (e) => (selectedWinner ? handleMessageChange(selectedWinner, e.target.value) : undefined),
                    }}
                />
            </div>
            <div className="flex flex-col gap-y-3">
                <h2 className="text-lg font-bold">Send message to losing vendors</h2>
                {selectedWinner ? (
                    rfp.rfpVendorResponses.map((response) => {
                        if (response.id === selectedWinner) return null;
                        return (
                            <>
                                <div className="flex justify-between gap-x-4">
                                    <div className="flex flex-col gap-y-1">
                                        <div>{response.vendorName}</div>
                                        <div>Current status: {UniversalService.RFPStatusLabels[response.status]}</div>
                                    </div>
                                    <div className="w-full max-w-md">
                                        <Textarea
                                            label="Optional customer message"
                                            inputProps={{
                                                value: vendorResponses[response.id].message,
                                                onChange: (e) => handleMessageChange(response.id, e.target.value),
                                            }}
                                        />
                                    </div>
                                </div>
                                <hr />
                            </>
                        );
                    })
                ) : (
                    <div>Select a winner first</div>
                )}
            </div>
            <div className="flex justify-end gap-x-4">
                <Button
                    className="btn-dark-grey-outlined"
                    onClick={() => history.push(routes.requestForProposal.details(id))}
                    disabled={isSending}
                >
                    Cancel
                </Button>
                <Button
                    className="btn-primary"
                    onClick={handleSaveAndSend}
                    disabled={rfp.status !== 'OPEN' || !selectedWinner}
                    loading={isSending}
                >
                    Award RFP
                </Button>
            </div>
        </div>
    );
};

export default AwardRFP;
