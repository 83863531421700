import { useWaysteClient } from '@alliance-disposal/client';
import { InternalTicket } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Menu, MenuItem } from '@wayste/sour-ui';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

type StatusMenuProps = {
    status?: InternalTicket.InternalTicketStatus | null;
    /**
     * Passing in a ticketID will allow for status changed on the ticket via Wayste Client
     */
    ticketID?: string;
    onStatusChange?: (status?: InternalTicket.InternalTicketStatus | null) => void;
    disabled?: boolean;
};

const StatusMenu = ({ status, ticketID, onStatusChange, disabled }: StatusMenuProps) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();

    const getButtonColors = () => {
        switch (status) {
            case 'OPEN':
                return 'bg-gray-600 text-white';
            case 'IN_PROGRESS':
                return 'bg-blue-600 text-white';
            case 'SOLVED':
                return 'bg-green-600 text-white';
            default:
                return 'text-black bg-white border border-black';
        }
    };

    const handleStatusChange = async (newStatus?: InternalTicket.InternalTicketStatus | null) => {
        if (ticketID) {
            try {
                await client.internalTicket().adminPortal.update(ticketID, { status: newStatus });
            } catch (error) {
                setShowToast({
                    severity: 'error',
                    message: 'Error occurred updating status',
                });
                return;
            }
        }
        if (onStatusChange) {
            onStatusChange(newStatus);
        }
    };

    return (
        <Menu
            button={
                <Button className={`btn-base ${getButtonColors()}`} endIcon={<ChevronDownIcon className="size-5" />} disabled={disabled}>
                    {status ? InternalTicket.InternalTicketStatusLabels[status] : 'No Status'}
                </Button>
            }
        >
            <MenuItem onClick={() => handleStatusChange(null)}>No Status</MenuItem>
            {Object.entries(InternalTicket.InternalTicketStatusLabels).map((item) => (
                <MenuItem
                    key={item[0]}
                    onClick={() => handleStatusChange(item[0] as keyof typeof InternalTicket.InternalTicketStatusLabels)}
                >
                    {item[1]}
                </MenuItem>
            ))}
        </Menu>
    );
};

export default StatusMenu;
