import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Profile, UniversalService } from '@alliance-disposal/transport-types';
import { format } from 'date-fns';
import { needsAttentionStrings } from '../../../utils';

interface Props {
    serviceGrouping: UniversalService.ServiceGrouping;
    serviceOrderID?: string;
}

const InternalNotes = ({ serviceGrouping, serviceOrderID }: Props) => {
    // constants
    const client = useWaysteClient();
    const dateFormat = 'EEE MM/dd/yy hh:mm';
    const [roster, setRoster] = useState<Profile.ProfileTransport[]>([]);
    const notes =
        serviceOrderID && serviceGrouping?.internalNotes
            ? serviceGrouping.internalNotes.filter((note) => serviceOrderID && note.serviceOrderID === serviceOrderID)
            : serviceGrouping?.internalNotes;

    const noteSummary = (note: UniversalService.InternalNote, index: number) => {
        const user = roster.find((profile) => profile.id === note.userID);
        const responsibleUser = roster.find((profile) => profile.id === note.responsibleUserID);
        return (
            <div key={note.timestamp} className={index !== notes.length - 1 ? 'mb-5' : ''}>
                <div className="mb-2">
                    {note.serviceOrderID
                        ? serviceGrouping.serviceOrders.find((serviceOrder) => serviceOrder.id === note.serviceOrderID)?.fullOrderNumber +
                          ' - '
                        : null}
                    {note.timestamp ? format(new Date(note.timestamp), dateFormat) + ' - ' : null}
                    {user?.firstName} {user?.lastName}
                </div>
                {note.isIssue ? (
                    <div className="mb-2">
                        <span className="font-bold text-red-600">Issue</span>
                        {' - '}
                        {note.issueParty}
                        {Boolean(note.responsibleUserID)
                            ? `  ${responsibleUser?.firstName}${' '}${responsibleUser?.lastName} $${note.monetaryLoss || '0'}`
                            : null}
                    </div>
                ) : null}
                <div className="mb-2"></div>
                <span className={needsAttentionStrings.includes(note.note) ? 'bg-[#CC5500' : ''}>{note.note}</span>
            </div>
        );
    };

    // useEffects
    useEffect(() => {
        if (!notes || !roster) return;
    }, [roster]);

    const handleFetchRoster = async () => {
        const response = await client.profile().adminPortal.fetchRoster();
        setRoster(response.filter((profile) => profile.active && profile.firstName && profile.lastName));
    };

    useEffect(() => {
        handleFetchRoster();
    }, []);

    return <div>{notes && notes.map((note, index) => noteSummary(note, index))}</div>;
};

export default InternalNotes;
