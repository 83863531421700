import { useEffect, useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { SelectOption } from '@alliance-disposal/wql';
import { Dialog } from '@wayste/sour-ui';
import { formatServiceAddress } from '@wayste/utils';
import { StarIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { ContactSubForm } from '../ContactForm/ContactSubForm';
import UpdatePasswordDialog from './UpdatePasswordDialog';

const contactDefault: Partial<Customer.AllianceCustomerContactTransport> = {
    notes: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    locationPreferences: [],
};
const contactsDefault = {
    contacts: [contactDefault],
};

const ContactUpdateCreateDialog = ({
    customer,
    contact,
    open,
    onCancel,
    onDelete,
    onSubmit,
}: {
    customer: Customer.AllianceCustomerTransport;
    contact: Customer.AllianceCustomerContactTransport | null;
    open: boolean;
    onCancel: () => void;
    onDelete: () => void;
    onSubmit: (newContact: FieldValues) => void;
}) => {
    const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
    const methods = useForm();
    const { register, handleSubmit, reset } = methods;

    const submitForm = (data: FieldValues) => {
        onSubmit(data.contacts[0]);
    };

    // SET DEFAULT VALUES
    useEffect(() => {
        if (contact) {
            reset({
                contacts: [{ ...contact }],
            });
            return;
        }
        contactsDefault.contacts = [contactDefault];
        reset({
            ...contactsDefault,
        });
    }, [open]);

    // formatServiceAddress takes in an address with addressLine1 as required while ServiceLocation.addressLine1 is not required.
    const serviceLocationOptions: SelectOption[] = customer.serviceLocations?.map((location) => {
        const address = {
            addressLine1: location.address.addressLine1 ? location.address.addressLine1 : '',
            addressLine2: location.address.addressLine2,
            city: location.address.city,
            zip: location.address.zip,
            state: location.address.state,
        };
        const formattedAddress = formatServiceAddress(address);
        const selectOption: SelectOption = {
            label: formattedAddress,
            value: formattedAddress,
        };
        return selectOption;
    });
    serviceLocationOptions.unshift({ label: 'All', value: 'All' });
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            styledTitle={`${contact ? 'Update Contact' : 'Create Contact'}`}
            className="!max-w-2xl overflow-auto"
        >
            {contact && (
                <UpdatePasswordDialog
                    open={openUpdatePassword}
                    onClose={() => setOpenUpdatePassword(false)}
                    contact={contact}
                    customer={customer}
                />
            )}
            <FormProvider {...methods}>
                <input type="hidden" {...register(`active`, { value: true })} />
                <form onSubmit={handleSubmit(submitForm)}>
                    <div>
                        <div className="flex items-center justify-between pb-7">
                            <div className="flex text-xl">{customer.companyName}</div>
                            {contact?.primaryContact ? <StarIcon className="float-right flex h-5 w-5" color={'#EEDD00'} /> : <></>}
                        </div>
                        <div className="pb-6">
                            <ContactSubForm
                                hideButtons
                                staticPrimaryDisable
                                isEditing={contact !== null}
                                existingCustomer={customer}
                                locationOptions={serviceLocationOptions}
                                checkForDuplicateEmail="customer"
                            />
                        </div>
                        {contact && (
                            <button
                                className="btn-delete-outlined mr-5 px-5"
                                disabled={contact?.primaryContact ? true : false}
                                onClick={onDelete}
                                type="button"
                            >
                                <XMarkIcon className="mr-1 h-5 w-5" color={'red-600'} />
                                Remove
                            </button>
                        )}
                        {contact && (
                            <button className="btn-dark-grey-outlined mr-5 px-5" type="button" onClick={() => setOpenUpdatePassword(true)}>
                                Update Password
                            </button>
                        )}
                        <button className="btn-primary float-right px-10" type="submit">
                            {contact ? 'Update' : 'Create'}
                        </button>
                        <button className="btn-dark-grey-outlined float-right mr-5 px-10" onClick={onCancel} type="button">
                            Cancel
                        </button>
                    </div>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default ContactUpdateCreateDialog;
