import { useState } from 'react';
import { TriangleDownIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export const classItemBlock =
    'text-gray-600 bg-slate-900 bg-opacity-5 pl-8 pr-4 py-2 text-sm cursor-pointer hover:bg-opacity-10 h-10 flex flex-row items-center transition-all w-full justify-between';

const ExpandingBlock = ({
    heading,
    routeList,
    initiallyExpanded = false,
    onNavigate,
}: {
    heading: string;
    routeList: { text: string; path: string }[];
    initiallyExpanded?: boolean;
    onNavigate?: () => void;
}) => {
    const history = useHistory();
    const [expanded, setExpanded] = useState(initiallyExpanded);

    return (
        <div className="flex flex-col gap-y-[0.0625rem]">
            <div
                className="hover:bg-sourgum-greyblue-900 flex h-10 cursor-pointer flex-row items-center justify-between px-4 py-2 text-xs font-semibold capitalize text-[#656567] hover:bg-opacity-[0.06]"
                onClick={() => setExpanded(!expanded)}
            >
                <span className="whitespace-pre-line uppercase">{heading}</span>
                <TriangleDownIcon
                    className={twMerge(
                        `h-4 w-4 flex-shrink-0 rotate-0 transform transition-transform duration-500 ease-out ${
                            expanded ? '' : 'rotate-90'
                        }`,
                    )}
                />
            </div>
            <div
                className={twMerge(
                    'transition-max-h flex flex-col gap-y-[0.0625rem] overflow-hidden duration-500 ease-in-out',
                    expanded ? 'max-h-screen' : 'max-h-0',
                )}
            >
                {routeList.map((route) => (
                    <div
                        className={classItemBlock}
                        key={routeList.indexOf(route)}
                        onClick={() => {
                            onNavigate?.();
                            history.push(route.path);
                        }}
                    >
                        <span className="whitespace-pre-line text-sm font-normal leading-normal tracking-tight text-gray-600">
                            {route.text}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExpandingBlock;
