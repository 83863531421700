import { UniversalService } from '@alliance-disposal/transport-types';
import { Button, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/solid';
import { Controller, get, useFormContext } from 'react-hook-form';

export interface SortableItemProps {
    id: string;
    status: string;
    mode: 'edit' | 'create';
    availableStatuses: string[];
    position: number;
    onRemove: (id: string) => void;
}

export const SortableItemComponent = ({ id, position, availableStatuses, mode, status, onRemove }: SortableItemProps) => {
    // DND KIT HOOKS
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id,
    });
    // DND KIT ANIMATION
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    // REACT HOOK FORM HOOKS
    const {
        control,
        formState: { errors },
        getValues,
    } = useFormContext();

    // DISABLE STATUS INPUTS WHEN ALREADY IN USE
    const handleDisabled = (status: string) => {
        if (availableStatuses.includes(status)) {
            return false;
        }
        return true;
    };

    // CHECK IF STATUS IS ACTIVE
    const checkIfActive = (position: number) => {
        const status = getValues(`statuses`);
        if (status[position]?.id === undefined) {
            return true;
        }
        return false;
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className="flex h-14 w-full flex-row items-center justify-start space-x-6 border-b border-gray-400 px-2"
        >
            <div {...listeners} {...attributes}>
                <Bars3Icon className="h-6 w-6" />
            </div>
            <div>{position + 3}</div>

            {mode === 'create' || checkIfActive(position) ? (
                <div>
                    <Controller
                        control={control}
                        name={`statuses[${position}].status` as const}
                        defaultValue={''}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select
                                error={get(errors, `statuses[${position}].status`)}
                                label={'Status'}
                                required
                                onSelect={field.onChange}
                                defaultValue={''}
                                value={field.value}
                            >
                                {[...UniversalService.ServiceOrderStatuses].map((option: string, index: number) => (
                                    <SelectOption key={option + index} value={option} disabled={handleDisabled(option)}>
                                        {option}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                </div>
            ) : (
                <div>
                    <Controller
                        control={control}
                        name={`statuses[${position}].status` as const}
                        defaultValue={''}
                        rules={{ required: true }}
                        render={({ field }) => <div>{field.value}</div>}
                    />
                </div>
            )}

            <div>
                <Controller
                    control={control}
                    name={`statuses[${position}].VendorSendgridID` as const}
                    defaultValue={''}
                    render={({ field }) => (
                        <TextField
                            type={'string'}
                            label="Sendgrid ID to vendor"
                            error={get(errors, `defaultRentPeriod.value`)}
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />
            </div>
            <div>
                <Controller
                    control={control}
                    name={`statuses[${position}].CustomerSendgridID` as const}
                    defaultValue={''}
                    render={({ field }) => (
                        <TextField
                            type={'string'}
                            label="Sendgrid ID to customer"
                            error={get(errors, `defaultRentPeriod.value`)}
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />
            </div>

            <Button onClick={() => onRemove(status)} startIcon={<TrashIcon className="h-5 w-5 cursor-pointer text-red-600" />}>
                {null}
            </Button>
        </div>
    );
};
