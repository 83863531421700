import { useEffect } from 'react';
import { Order } from '@alliance-disposal/transport-types';
import { Button, Dialog, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { Controller, FormProvider, useForm } from 'react-hook-form';

interface FormProps {
    reasoning: Order.ReasoningType;
    otherReasoning: string | undefined;
}

interface OtherHaulerDialogProps {
    selectedHaulerID: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    onSubmit: (data: FormProps) => void;
}
/**
 * Shown when a vendor is selected that is not
 * the most profitable vendor
 */
export const OtherVendorDialog = ({ selectedHaulerID, open, setOpen, onSubmit }: OtherHaulerDialogProps) => {
    const methods = useForm<FormProps>();
    const { control, handleSubmit, watch, formState, reset } = methods;
    const reasoning = watch('reasoning');

    useEffect(() => {
        reset({
            reasoning: undefined,
            otherReasoning: undefined,
        });
    }, [selectedHaulerID]);

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            styledTitle="Other Hauler Chosen"
            variant="delete"
            className="max-w-3xl"
            disableCloseOnClickOutside
        >
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="gap-2 space-y-4">
                        <p>Please select why you are not picking the most profitable vendor.</p>
                        <Controller
                            control={control}
                            name="reasoning"
                            defaultValue={undefined}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => (
                                <Select label="Reasoning" value={field.value} onSelect={field.onChange} error={fieldState.error} required>
                                    {Object.entries(Order.ReasoningTypeLabels).map((option: [string, string], index: number) => (
                                        <SelectOption key={'reasoningLabel' + index} value={option[0]}>
                                            {option[1]}
                                        </SelectOption>
                                    ))}
                                </Select>
                            )}
                        />
                        {reasoning === 'OTHER' && (
                            <Controller
                                control={control}
                                name="otherReasoning"
                                defaultValue={undefined}
                                rules={{ required: true }}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label="Other Reasoning"
                                        error={fieldState.error}
                                        required
                                        inputProps={{
                                            ...field,
                                        }}
                                    />
                                )}
                            />
                        )}
                    </div>
                    <div className="space mt-4 flex justify-end space-x-4">
                        {/* <Button className="btn-dark-grey-outlined mt-2" onClick={() => setOpen(false)} type="button">
              Close
            </Button> */}
                        <Button className="btn-primary mt-2" type="submit" disabled={!formState.isValid}>
                            Save
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Dialog>
    );
};
