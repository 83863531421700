import React from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { format } from 'date-fns';

const dateFormat = 'EEE MM/dd/yy hh:mm aaa';

interface Props {
    notesInternal: Customer.AllianceLeadInternalNote[];
}

/**
 * @deprecated Use the InternalTickets component instead
 */
const InternalNotes = ({ notesInternal }: Props) => {
    const client = useWaysteClient();
    const { idToProfileMap } = client.profile().adminPortal.getRosterFromMemory();

    // const handleClose = () => {
    //   setOpen(false);
    //   setNewNote('');
    //   setShowAdd(false);
    // };

    // const handleNewNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    //   setNewNote(event.target.value);
    // };

    // const handleSubmit = async () => {
    //   setIsLoading(true);
    //   const newItem: Customer.AllianceLeadInternalNoteCreateTransport = {
    //     note: newNote,
    //   };
    //   try {
    //     await client.customer().adminPortal.leads.internalNote.create(leadID, newItem);
    //     showFlash('Note added', 'success');
    //   } catch (error) {
    //     showFlash('failed to add note', 'error');
    //   } finally {
    //     setIsLoading(false);
    //   }
    //   setShowAdd(false);
    //   setNewNote('');
    //   setIsLoading(false);
    // };

    return (
        <>
            <div>
                {notesInternal.map((note, index) => (
                    <div style={{ marginBottom: index !== notesInternal.length - 1 ? 7 : 0 }} key={note.timestamp}>
                        <div style={{ marginBottom: 3 }}>
                            {format(new Date(note.timestamp), dateFormat)}
                            {' - '}
                            {note?.userID ? `${idToProfileMap[note?.userID]?.firstName} ${idToProfileMap[note?.userID]?.lastName}` : ''}
                        </div>
                        {note.note}
                    </div>
                ))}
            </div>
        </>
    );
};

export default InternalNotes;
