import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { DurationUnitLabels, Notification, UniversalService } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button } from '@wayste/sour-ui';
import { formatEmailDestination, formatServiceAddress, moneyFormatter } from '@wayste/utils';
import { format } from 'date-fns';
import CardInfoList from '../../../components/CardInfoList';
import DeclineToBidDialog from './decline-to-bid-dialog';

const RFPVendorResponseCard = ({
    rfpVendorResponse,
    rfp,
    onRefetch,
}: {
    rfpVendorResponse: UniversalService.RFPVendorResponseTransport;
    rfp: UniversalService.RFPTransport;
    onRefetch: () => void;
}) => {
    const client = useWaysteClient();
    const userIDToNameMap = client.profile().adminPortal.getRosterFromMemory().idToProfileMap;
    const { setShowToast } = useSourContext();
    const [isSendingReminder, setIsSendingReminder] = useState(false);
    const [showDeclineToBid, setShowDeclineToBid] = useState(false);
    const [proposalTotal, setProposalTotal] = useState(0);

    const colOne = [
        {
            label: 'Status',
            value: (
                <div
                    className={
                        rfpVendorResponse.status === 'SUBMITTED'
                            ? 'text-green-500'
                            : rfpVendorResponse.status === 'AWARD'
                              ? 'rounded bg-green-500 px-2 py-1 text-white'
                              : ''
                    }
                >
                    {UniversalService.RFPStatusLabels[rfpVendorResponse.status]}
                </div>
            ),
        },
        {
            label: 'Note to Vendor',
            value: rfpVendorResponse.noteToVendor,
        },
        {
            label: 'Note from Vendor',
            value: rfpVendorResponse.noteFromVendor,
        },
        {
            label: 'Reason Declined to Bid',
            value: rfpVendorResponse.declinedMessage,
            hidden: !rfpVendorResponse.declinedMessage,
        },
    ];

    const colTwo = [
        { label: 'Created By', value: userIDToNameMap[rfpVendorResponse.metadata.createdByUserID]?.fullName },
        {
            label: 'Created',
            value: format(new Date(rfpVendorResponse.metadata.createdAt), 'EEE MM/dd/yy h:mm aaa'),
        },
        { label: 'Last Updated By', value: userIDToNameMap[rfpVendorResponse.metadata.lastUpdatedByUserID]?.fullName },
        {
            label: 'Updated',
            value: rfpVendorResponse.metadata.lastUpdatedAt
                ? format(new Date(rfpVendorResponse.metadata.lastUpdatedAt), 'EEE MM/dd/yy h:mm aaa')
                : '',
        },
    ];

    useEffect(() => {
        let total = 0;
        rfpVendorResponse.rfpSkus.forEach((sku) => {
            total += (sku.unitPrice || 0) * sku.quantity;
            if (sku.rfpSkuExtras) {
                sku.rfpSkuExtras.forEach((extra) => {
                    total += (extra.unitPrice || 0) * extra.quantity;
                });
            }
        });
        setProposalTotal(total);
    }, []);

    const handleSendReminder = async () => {
        let hauler;
        setIsSendingReminder(true);
        try {
            hauler = await client.vendorService().adminPortal.fetch(rfpVendorResponse.vendorID);
        } catch (error) {
            console.error('Error fetching vendor', error);
            alert('Error fetching vendor, get a SAP dev');
            setIsSendingReminder(false);
            return;
        }
        const rfpContacts = hauler.contacts.filter((contact) => contact.email && contact.sendRFPEmails);
        if (rfpContacts.length === 0) {
            alert('Hauler missing contacts with Receives RFP');
            setIsSendingReminder(false);
            return;
        }
        const ccEmails = rfpContacts.filter((contact) => contact.email !== rfpContacts[0].email).map((contact) => contact.email as string);
        try {
            const destination = formatEmailDestination(rfpContacts[0].email as string, rfpContacts[0]?.firstName || '', ccEmails, []);
            const clean_location = formatServiceAddress({
                addressLine1: rfp.serviceLocation.address?.addressLine1
                    ? rfp.serviceLocation.address.addressLine1.match(/\s*\d+\w*\s*(.*)/)?.[1].trim() || '' // remove street number
                    : '',
                addressLine2: '',
                city: rfp.serviceLocation.address?.city || '',
                state: rfp.serviceLocation.address?.state || '',
                zip: rfp.serviceLocation.address?.zip || '',
            });
            const emailData: Notification.SendGrid.VendorRFPReminder = {
                vendor_name: hauler?.name || '',
                access_link: rfpVendorResponse.accessLink,
                first_name: rfpContacts[0]?.firstName || '',
                start_date: rfp.serviceStartDate ? format(new Date(rfp.serviceStartDate), 'EEE MM/dd/yyyy') : '',
                deadline: format(new Date(rfp.deadlineDate), 'EEE MM/dd/yyyy hh:mm a'),
                proposal_number: rfp.proposalNumber.toString(),
                clean_location,
            };
            await client.notification().adminPortal.createInstantNotification({
                handler: 'sendgrid',
                topic: 'vendor-rfp-reminder',
                destination,
                body: JSON.stringify(emailData),
            });
            setShowToast({
                severity: 'success',
                message: 'Email sent to vendor',
            });
        } catch (error) {
            console.error('Error sending email to vendor', error);
            setShowToast({
                severity: 'error',
                message: 'Error sending email to vendor',
            });
        } finally {
            setIsSendingReminder(false);
        }
    };

    return (
        <>
            <div className="flex flex-col gap-y-4 rounded-lg border border-gray-300 bg-white p-4 shadow">
                <div className="flex items-center justify-between border-b pb-2">
                    <div className="text-xl font-semibold leading-7 tracking-tight text-gray-700">{rfpVendorResponse.vendorName}</div>
                    <div className="flex gap-4">
                        <Button
                            className="btn-error-outlined"
                            onClick={() => setShowDeclineToBid(true)}
                            disabled={
                                rfpVendorResponse.status !== 'OPEN' || new Date() > new Date(rfpVendorResponse.accessLinkExpirationDate)
                            }
                        >
                            Decline to Bid
                        </Button>
                        <Button
                            className="btn-primary"
                            onClick={handleSendReminder}
                            disabled={
                                rfpVendorResponse.status !== 'OPEN' || new Date() > new Date(rfpVendorResponse.accessLinkExpirationDate)
                            }
                            loading={isSendingReminder}
                        >
                            Send Reminder
                        </Button>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-4 text-sm lg:grid-cols-2">
                    <CardInfoList data={colOne} border />
                    <CardInfoList data={colTwo} />
                </div>
                <hr />
                <div className="flex flex-col gap-y-2">
                    <div className="text-lg font-semibold leading-7 tracking-tight text-gray-800">Bids</div>
                    <div className="py-1 text-sm italic">All bids should be all in rates.</div>
                    <div className="flex flex-col gap-y-3">
                        {rfpVendorResponse.rfpSkus.map((sku, index) => {
                            return (
                                <div key={sku.id} className="flex flex-col gap-y-1">
                                    <div className="text-base font-semibold leading-7 tracking-tight text-gray-800">
                                        Service #{index + 1}
                                    </div>
                                    <div className="flex">
                                        <div>
                                            <span className="text-gray-600">Quantity:</span> {sku.quantity}{' '}
                                            <span className="text-gray-300">|</span> {sku.serviceType.name}{' '}
                                            <span className="text-gray-300">|</span>{' '}
                                            {sku.serviceFrequency
                                                ? sku.serviceCycleLength === 2
                                                    ? '1x Every other week'
                                                    : `${sku.serviceFrequency.value} x ${DurationUnitLabels[sku.serviceFrequency.unit].singular}`
                                                : 'One time'}
                                        </div>
                                        <div className="px-2">=</div>
                                        <div>{rfpVendorResponse.status === 'OPEN' ? 'awaiting bid' : moneyFormatter(sku.totalPrice)}</div>
                                    </div>
                                    <div className="text-sm">
                                        <span className="text-gray-600">Note: </span>
                                        {sku.noteFromVendor}
                                    </div>
                                    <div className="flex flex-col gap-y-2 text-sm">
                                        {sku.rfpSkuExtras?.map((item) => (
                                            <div className="flex flex-col gap-y-0.5">
                                                <div>
                                                    <span className="text-gray-600">{item.serviceLineItemType.displayName}</span> ={' '}
                                                    {rfpVendorResponse.status === 'OPEN'
                                                        ? 'awaiting bid'
                                                        : item.totalPrice
                                                          ? moneyFormatter(item.totalPrice)
                                                          : 'Included'}
                                                </div>
                                                <div className="text-sm">
                                                    <span className="text-gray-600">Note: </span>
                                                    {item.noteFromVendor}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="flex w-full justify-end">
                    <div className="text-gray-600">Proposal Total</div>
                    <div className="h-4 min-w-28 border-b border-dotted" />
                    <div>{moneyFormatter(proposalTotal)}</div>
                </div>
            </div>
            {/* Dialogs */}
            <DeclineToBidDialog
                open={showDeclineToBid}
                onClose={(submitted) => {
                    setShowDeclineToBid(false);
                    if (submitted) {
                        onRefetch();
                    }
                }}
                rfpVendorResponseId={rfpVendorResponse.id}
            />
        </>
    );
};

export default RFPVendorResponseCard;
