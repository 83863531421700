import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Pricing } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Loading } from '@wayste/sour-ui';
import { PlusIcon } from '@heroicons/react/24/solid';
import {
    CustomMaterialPricingModal,
    CustomPricingTable,
    CustomPricingZoneModal,
    DeleteCustomMaterialPricingModal,
} from '../../../components/CustomPricing';

const TierPricingDetails = ({ pricing }: { pricing: Pricing.PricingTransport }) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [tieredPricing, setTieredPricing] = useState<Pricing.CustomerDiscountTransport[]>([]);
    const [customPricingModalProps, setCustomPricingModalProps] = useState<
        { selectedTier: Pricing.CustomerDiscountTransport; selectedDiscountData?: Pricing.DiscountDataTransport } | undefined
    >(undefined);
    const [deleteCustomPricingModalProps, setDeleteCustomPricingModalProps] = useState<
        { material: string; selectedTier: Pricing.CustomerDiscountTransport } | undefined
    >(undefined);
    const [showAddTieredPricing, setShowAddTieredPricing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getTieredPricing = async () => {
        setIsLoading(true);
        try {
            const tieredResponse = await client
                .pricing()
                .adminPortal.customerDiscount.query({ tieredPricing: true, pricingZoneID: pricing.id });

            setTieredPricing(tieredResponse.results);
        } catch (error) {
            console.error('Error fetching tiered pricing', error);
            setShowToast({ message: 'Error fetching tiered pricing', severity: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getTieredPricing();
    }, [pricing.id]);

    const handleDeleteTierMaterial = async ({ material, tier }: { material: string; tier?: Pricing.CustomerDiscountTransport }) => {
        if (!pricing?.id || !tier) return;
        try {
            const filteredData = tier.discountData.filter((x) => x.material !== material);
            await client.pricing().adminPortal.customerDiscount.update(tier.id, {
                discountData: filteredData,
            });
            await getTieredPricing();
        } catch (error) {
            console.warn('ERROR', error);
            alert('An error has occurred, please contact AAP dev.');
        }
    };

    if (isLoading)
        return (
            <>
                <div className="border-b pb-2 text-xl font-semibold leading-7 tracking-tight text-gray-800">Tiered Pricing</div>
                <div className="flex w-full items-center justify-center">
                    <Loading />
                </div>
            </>
        );

    return (
        <>
            <div className="border-b pb-2">
                <div className="flex items-end justify-between px-4">
                    <div className="text-xl font-semibold leading-7 tracking-tight text-gray-800">Tiered Pricing</div>
                    <Button onClick={() => setShowAddTieredPricing(true)} className="btn-primary" endIcon={<PlusIcon className="size-5" />}>
                        Create Pricing Tier
                    </Button>
                </div>
            </div>
            {tieredPricing
                ?.sort((a, b) => {
                    const indexA = Pricing.SourgumTierNames.findIndex((tier) => tier === a.tierName);
                    const indexB = Pricing.SourgumTierNames.findIndex((tier) => tier === b.tierName);
                    return indexA - indexB;
                })
                ?.map((tier) => (
                    <div className="flex flex-col gap-y-3">
                        <div className="flex items-end justify-between px-4">
                            <div className="text-xl font-semibold leading-7 tracking-tight text-gray-800">{tier.tierName}</div>
                            <div className="flex items-center gap-x-4">
                                <Button
                                    endIcon={<PlusIcon className="size-5" />}
                                    onClick={() => setCustomPricingModalProps({ selectedTier: tier })}
                                    className="btn-primary"
                                >
                                    Add New Material
                                </Button>
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-4 overflow-auto rounded-lg border border-gray-300 bg-[#fcfcfd] text-sm shadow">
                            <CustomPricingTable
                                customerDiscount={tier}
                                onDeleteClick={(materialDiscountInfo) => {
                                    setDeleteCustomPricingModalProps({
                                        material: materialDiscountInfo.material,
                                        selectedTier: tier,
                                    });
                                }}
                                onEditClick={(materialDiscountInfo) => {
                                    setCustomPricingModalProps({
                                        selectedTier: tier,
                                        selectedDiscountData: materialDiscountInfo,
                                    });
                                }}
                            />
                        </div>
                    </div>
                ))}
            {/* Dialogs */}
            <CustomMaterialPricingModal
                handleClose={() => setCustomPricingModalProps(undefined)}
                onSubmit={getTieredPricing}
                selectedCustomerDiscount={customPricingModalProps?.selectedTier}
                selectedDiscountData={customPricingModalProps?.selectedDiscountData}
            />
            <DeleteCustomMaterialPricingModal
                handleDeleteMaterial={({ material }) =>
                    handleDeleteTierMaterial({ material, tier: deleteCustomPricingModalProps?.selectedTier })
                }
                handleClose={() => setDeleteCustomPricingModalProps(undefined)}
                customerDiscount={deleteCustomPricingModalProps?.selectedTier}
                material={deleteCustomPricingModalProps?.material}
            />
            <CustomPricingZoneModal
                open={showAddTieredPricing}
                onClose={() => setShowAddTieredPricing(false)}
                pricingZone={pricing}
                onSubmit={() => {
                    getTieredPricing();
                    setShowAddTieredPricing(false);
                }}
                existingCustomDiscounts={tieredPricing}
                isTierPricing
            />
        </>
    );
};

export default TierPricingDetails;
