import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { Button, Dialog } from '@wayste/sour-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { UIContext } from '../../contexts';
import USVendorQuoteSubForm from '../LeadForms/USVendorQuoteSubForm';
import { type USVendorQuoteFormType, prepareUSVendorQuoteSubForm } from '../LeadForms/utils/prepare-lead-form';

export const USVendorQuoteUpdate = ({
    open,
    onClose,
    lead,
}: {
    open: boolean;
    onClose: () => void;
    lead: Customer.AllianceLeadTransport;
}) => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<USVendorQuoteFormType>({
        mode: 'all',
        defaultValues: {},
    });

    const { handleSubmit, reset } = methods;

    useEffect(() => {
        if (open) {
            const newFormValues = { ...prepareUSVendorQuoteSubForm(lead) };
            const newQuotes = [...newFormValues.haulerQuotes];
            newQuotes.push({
                quantity: 0,
                haulerName: '',
                billingFrequency: '',
                frequency: '',
                price: 0,
                serviceTypeID: '',
                serviceFamilyID: '',
                size: { size: 0, type: '' },
                material: '',
            } satisfies Customer.AllianceLeadHaulerQuoteCreateTransport);
            newFormValues.haulerQuotes = newQuotes;
            console.log('newFormValues: ', newFormValues);
            reset(newFormValues);
        } else {
            reset(prepareUSVendorQuoteSubForm(lead));
        }
    }, [open]);

    const submitForm = async (data: USVendorQuoteFormType) => {
        setIsLoading(true);
        for (const haulerQuote of data.haulerQuotes) {
            if (!Boolean('id' in haulerQuote)) {
                try {
                    await client.customer().adminPortal.leads.haulerQuote.create(lead.id, haulerQuote);
                    showFlash('Lead Successfully Updated', 'success');
                } catch (error) {
                    showFlash('Error Updating Lead', 'warning');
                }
            }
        }
        onClose();
        setIsLoading(false);
    };

    return (
        <Dialog open={open} className="max-w-6xl" styledTitle="Update Lead" onClose={onClose}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <USVendorQuoteSubForm index={lead.haulerQuotes.length} />
                    <div className="flex justify-end gap-4 pt-6">
                        <Button onClick={onClose} className="btn-dark-grey-outlined" disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button className="btn-primary" type="submit" loading={isLoading}>
                            Save
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Dialog>
    );
};
