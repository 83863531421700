import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { isAxiosError } from 'axios';
import { useAlertDialog } from '../../../contexts';

const GoogleAdsSettings = () => {
    const alert = useAlertDialog();
    const client = useWaysteClient();
    const [showGoogleConnectionNeeded, setShowGoogleConnectionNeeded] = useState(true);

    const handleAuthenticateGoogle = async () => {
        try {
            const baseURL = import.meta.env.DEV ? 'http://localhost:3000' : 'https://admin.sourgum.com/';
            const response = await client.customer().adminPortal.googleAds.authenticateGoogle();
            const googleAuthURL = response?.data;
            const windowFeatures = 'resizable=no,scrollbars=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=700';
            window.open(googleAuthURL, 'popup', windowFeatures);
            window.addEventListener('message', (event) => {
                if (event.origin === baseURL && event.data === 'google-connection-successful') {
                    setShowGoogleConnectionNeeded(false);
                }
            });
        } catch (error) {
            console.warn('authenticateGoogle error: ', error);

            if (isAxiosError(error) && error.response?.data?.message) {
                alert.getAlert({
                    message: 'An error occurred with the message: ' + JSON.stringify(error.response?.data?.message),
                    severity: 'error',
                });
            } else {
                alert.getAlert({
                    message: 'An error occurred with the message: ' + JSON.stringify(error),
                    severity: 'error',
                });
            }
        }
    };

    return (
        <div className="container mx-auto flex flex-1 flex-col items-center justify-center gap-10 px-3 pb-10 pt-7">
            <h1 className="text-2xl font-semibold">Google Ads Settings</h1>
            {showGoogleConnectionNeeded ? (
                <>
                    <p>If conversion syncing is not working, try using the button below to reconnect the Google Ads account.</p>
                    <button className="btn-primary" type="button" onClick={() => handleAuthenticateGoogle()}>
                        Connect to Google
                    </button>
                </>
            ) : (
                <p>Google account connected.</p>
            )}
        </div>
    );
};

export default GoogleAdsSettings;
