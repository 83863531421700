import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Menu, MenuItem, Tooltip } from '@wayste/sour-ui';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import AssignToVendorDialog from './AssignToVendorDialog';

interface UniversalServicesStatusPopperProps {
    serviceOrder: UniversalService.ServiceOrder;
    mixed?: boolean;
    serviceGrouping?: UniversalService.ServiceGrouping;
    shouldRefresh?: () => void;
    display?: 'button' | 'label';
}

const UniversalServicesStatusPopper = ({
    serviceOrder,
    mixed,
    shouldRefresh,
    display,
    serviceGrouping,
}: UniversalServicesStatusPopperProps) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [modalOpen, setModalOpen] = useState<'ASSIGNED_TO_VENDOR' | null>(null);

    const cancelledButStillOnSite = serviceOrder.cancellationDetails && serviceOrder.status !== 'COMPLETED';

    const getColor = (status: UniversalService.ServiceOrderStatus) => {
        if (mixed) {
            return `bg-statuses-MIXED-primary text-statuses-MIXED-text`;
        }
        if (serviceOrder.cancellationDetails && serviceOrder.status === 'COMPLETED') {
            return `bg-statuses-CANCELLED-primary text-statuses-CANCELLED-text`;
        }
        if (cancelledButStillOnSite) {
            return `bg-amber-600 text-white`;
        }
        return `bg-statuses-${status}-primary text-statuses-${status}-text`;
    };

    const getLabel = (status: UniversalService.ServiceOrderStatus) => {
        if (serviceOrder.cancellationDetails && serviceOrder.status === 'COMPLETED') {
            return 'Cancelled';
        } else if (mixed) {
            return 'Mixed';
        }
        return UniversalService.ServiceOrderStatusLabels[status];
    };

    const onStatusChange = async (status: UniversalService.ServiceOrderStatus) => {
        if (status === 'ASSIGNED') {
            setModalOpen('ASSIGNED_TO_VENDOR');
        } else {
            try {
                await client
                    .universalService()
                    .serviceGrouping.serviceOrder.update(serviceOrder.serviceGroupingID, serviceOrder.id, { status });
                shouldRefresh?.();
                setShowToast({ message: 'Service Order Updated', severity: 'success' });
            } catch (error) {
                console.error(error);
                setShowToast({ message: 'An Error Occurred updating the service order.', severity: 'error' });
            }
        }
    };

    if (display === 'label') {
        return (
            <Tooltip
                text={cancelledButStillOnSite ? "Service order cancelled but it's still on site or not completed" : undefined}
                delay={100}
            >
                <div className={twMerge('btn-base h-8 w-32 cursor-auto gap-2 whitespace-nowrap rounded', getColor(serviceOrder.status))}>
                    {cancelledButStillOnSite && <ExclamationTriangleIcon className="size-5 min-w-5" />}
                    {getLabel(serviceOrder.status)}
                </div>
            </Tooltip>
        );
    }
    return (
        <>
            <AssignToVendorDialog
                open={modalOpen === 'ASSIGNED_TO_VENDOR'}
                serviceOrder={serviceOrder}
                serviceGrouping={serviceGrouping}
                onClose={() => {
                    setModalOpen(null);
                    shouldRefresh?.();
                }}
            />

            <Menu
                button={
                    <Tooltip text={cancelledButStillOnSite ? "Service order cancelled but it's still on site" : undefined} delay={100}>
                        <Button
                            className={`btn-base h-8 w-32 whitespace-nowrap rounded ${getColor(serviceOrder.status)}`}
                            startIcon={cancelledButStillOnSite ? <ExclamationTriangleIcon className="size-5 min-w-5" /> : undefined}
                        >
                            {getLabel(serviceOrder.status)}
                        </Button>
                    </Tooltip>
                }
            >
                {UniversalService.ServiceOrderStatuses.filter((status) =>
                    serviceOrder.serviceType.family.allowedStatuses.includes(status),
                ).map((status) => (
                    <MenuItem
                        key={status}
                        disabled={
                            serviceOrder.status === status ||
                            (UniversalService.ServiceOrderStatuses.indexOf(status) <
                                UniversalService.ServiceOrderStatuses.indexOf(serviceOrder.status) &&
                                status === 'ON_HOLD') ||
                            (serviceOrder.cancellationDetails && serviceOrder.status === 'COMPLETED')
                        }
                        onClick={() => onStatusChange(status)}
                    >
                        {UniversalService.ServiceOrderStatusLabels[status]}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default UniversalServicesStatusPopper;
