import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, UniversalService } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { createBillingEvent, daysRemainingInBillingPeriod, formatDateToYYYYMMDD } from '@wayste/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { UIContext } from '../../../contexts';
import { UniversalServiceFormData } from '../UniversalServiceCreate/UniversalServiceCreate';
import SubscriptionServiceOrderAddSubForm from '../components/SubscriptionServiceOrderAddSubForm';
import {
    defaultServiceGrouping,
    defaultServiceOrderWithSubscriptionDetails,
    defaultServiceSubscriptionDetails,
} from '../components/site-service-defaults';

const SubscriptionServiceOrderAdd = () => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const history = useHistory();
    const params: { serviceGroupingId: string } = useParams();
    const methods = useForm<UniversalServiceFormData>();
    const { formState, handleSubmit, reset } = methods;
    const [serviceGrouping, setServiceGrouping] = useState<UniversalService.ServiceGrouping | undefined>(undefined);
    const [customer, setCustomer] = useState<Customer.AllianceCustomerTransport | undefined>(undefined);

    const fetchServiceGrouping = async () => {
        try {
            const serviceGrouping = await client.universalService().serviceGrouping.fetch(params.serviceGroupingId);
            setServiceGrouping(serviceGrouping);
            const customer = await client.customer().adminPortal.fetch(serviceGrouping.customerID);
            setCustomer(customer);
        } catch (error) {
            console.warn('fetchServiceGrouping for ID: ', error);
            showFlash('Error fetching service grouping.', 'warning');
        }
    };

    const onSubmit = async (data: UniversalServiceFormData) => {
        console.log('data: ', data);
        if (!serviceGrouping) {
            showFlash('Error Adding Service Order, Missing Service Grouping', 'warning');
            return;
        }
        try {
            // CREATE SERVICE ORDERS
            const promises = data.serviceGrouping.serviceOrders.map((order) => {
                order.status = 'UNASSIGNED';
                order.accountManagerID = customer?.accountManagerID || undefined;
                order.serviceDays = order.serviceDays ?? [];
                order.internalNotes = ''; // remove this bullshit
                order.needsAttention = false;
                order.serviceLocation = serviceGrouping.serviceOrders[0].serviceLocation; // service location shared across all service orders.
                if (serviceGrouping?.serviceOrders[0].subscriptionDetails && typeof order.subscriptionDetails !== 'string') {
                    if (!order.subscriptionDetails || !order.startDate) {
                        showFlash('Error Adding Service Order, Missing Subscription Details', 'warning');
                        return;
                    }
                    const formattedStartDate = formatDateToYYYYMMDD(new Date(order.startDate));

                    // YOU CANNOT SPREAD THE SUBSCRIPTION DETAILS OBJECT, AND PASS UP THE ID, IT WILL NOT CREATE A NEW SUBSCRIPTION DETAILS OBJECT
                    order.subscriptionDetails = {
                        ...order.subscriptionDetails,
                        billingDay: serviceGrouping?.serviceOrders[0]?.subscriptionDetails.billingDay,
                        billingFrequency: serviceGrouping?.serviceOrders[0]?.subscriptionDetails.billingFrequency,
                        billingFrequencyUnit: serviceGrouping?.serviceOrders[0]?.subscriptionDetails.billingFrequencyUnit,
                        addToInvoiceWhen: serviceGrouping?.serviceOrders[0]?.subscriptionDetails.addToInvoiceWhen,
                        periodicEvents: order.subscriptionDetails?.periodicEvents ?? [],
                        startDate: formattedStartDate!, // IDK why it thinks this is a string, it's a date
                        active: true,
                    };

                    if (order.subscriptionDetails?.endDate === '') {
                        order.subscriptionDetails.endDate = undefined;
                    }
                    order.subscriptionDetails.periodicEvents = [
                        ...order.subscriptionDetails.periodicEvents.map((event) => createBillingEvent(event, event.lineItemTypeID)),
                    ];
                    // grab periodic events from subscription details and add them to service events.
                    order.subscriptionDetails.periodicEvents.forEach((periodicEvent) => {
                        if (!order.serviceEvents) {
                            order.serviceEvents = [];
                        } else {
                            order.serviceEvents = order.serviceEvents.map((event) => createBillingEvent(event, event.lineItemTypeID));
                        }
                        const newEvent = createBillingEvent(
                            {
                                ...periodicEvent,
                                dateExpected: new Date().toISOString(),
                            },
                            periodicEvent.lineItemTypeID,
                        );
                        if (data.prorate) {
                            if (!order.subscriptionDetails) {
                                showFlash('Error Adding Service Order, Missing Subscription Details', 'warning');
                                return;
                            }
                            const d = daysRemainingInBillingPeriod({
                                // @ts-expect-error idk why the fuck this thinks it could be a string
                                billingFrequency: order.subscriptionDetails.billingFrequency,
                                // @ts-expect-error idk why the fuck this thinks it could be a string
                                billingFrequencyUnit: order.subscriptionDetails.billingFrequencyUnit,
                                // @ts-expect-error idk why the fuck this thinks it could be a string
                                billingDay: order.subscriptionDetails.billingDay,
                            });
                            newEvent.unitCost = (d.daysRemaining / d.totalDays) * newEvent.unitCost;
                        }
                        order.serviceEvents.push(newEvent);
                    });
                }

                return client.universalService().serviceGrouping.serviceOrder.create(params.serviceGroupingId, order);
            });

            await Promise.all(promises);

            showFlash('Service Orders Successfully Added', 'success');
            console.log('Service Orders Successfully Added', promises);
            history.goBack();
        } catch (error) {
            console.warn('error: ', error);
            showFlash('Error Adding Service Order', 'warning');
        }
    };

    useEffect(() => {
        reset({
            serviceGrouping: {
                ...defaultServiceGrouping,
                type: 'open-ended',
                contractDetails: {
                    active: true,
                },
                serviceOrders: [{ ...defaultServiceOrderWithSubscriptionDetails }],
            },
            subscriptionDetails: defaultServiceSubscriptionDetails,
        });
        fetchServiceGrouping();
    }, []);

    useEffect(() => {
        if (!customer) return;
        console.log('customer', customer);
        reset({
            ...formState,
            customer: customer,
        });
    }, [customer]);

    return (
        <div className="mx-auto flex w-full max-w-5xl flex-1 flex-col p-6 pb-16">
            <FormProvider {...methods}>
                <form>
                    <div className="mb-6 pb-2 text-2xl">{`Add Service Order to Service Grouping ${
                        serviceGrouping?.description ?? ''
                    }`}</div>
                    <div className="space-y-6">
                        <SubscriptionServiceOrderAddSubForm methods={methods} />
                    </div>
                    <div className="float-right mt-2">
                        <Button className="btn-dark-grey-outlined mr-4" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                        <Button className="btn-primary" onClick={handleSubmit(onSubmit)}>
                            Add Service Orders
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};
export default SubscriptionServiceOrderAdd;
