import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Pricing, Profile } from '@alliance-disposal/transport-types';
import { Chip, Step, VerticalStepper } from '@wayste/sour-ui';
import { formatUSPhoneNumberToE164 } from '@wayste/utils';
import structuredClone from '@ungap/structured-clone';
import { useFormContext, useWatch } from 'react-hook-form';
import { paymentMethodsEnums, paymentTermsEnums } from '../../utils/shared-types';
import Loading from '../Loading';
import CustomerSubForm from './CustomerSubForm';
import DispatchSubForm from './DispatchSubForm';
import PaymentSubForm from './PaymentSubForm';
import PricingSubForm from './PricingSubForm';
import SelectCustomerSubForm from './SelectCustomerSubForm';
import { OrderCreateFormProps, OrderCreateFormStartingValues, customerStartData, initialValues } from './order-create-form-props';

export interface OrderCreateProps {
    user: Profile.ProfileTransport;
    onGetPricing: (
        coords: {
            lat: number;
            lng: number;
            zip?: string;
            state: string;
        },
        customerID?: string,
    ) => void;
    onResetLead: () => void;
    zonePricing: Pricing.PricingTransport | null;
    isLoading: boolean;
    startingValues: OrderCreateFormStartingValues | null;
    setOldCustomer: (customer: Customer.AllianceCustomerTransport | null) => void;
}

const OrderCreateForm = ({ user, zonePricing, onGetPricing, isLoading, startingValues, onResetLead, setOldCustomer }: OrderCreateProps) => {
    const client = useWaysteClient();
    const [activeStep, setActiveStep] = useState(0);
    const [existingCustomer, setExistingCustomer] = useState<Customer.AllianceCustomerTransport | null>(null);
    const [isSearchingForStartingCustomer, setIsSearchingForStartingCustomer] = useState(false);

    const { setValue, watch, trigger, control, getValues, reset, clearErrors } = useFormContext<OrderCreateFormProps>();
    const watchPaymentInfo = watch('paymentSubForm.paymentInfo');

    const watchPricingSubForm = useWatch({ control, name: 'pricingSubForm' });
    const watchDispatchSubForm = useWatch({ control, name: 'dispatchSubForm' });
    const watchCustomerSubForm = useWatch({ control, name: 'customerSubForm' });
    const watchPaymentSubForm = useWatch({ control, name: 'paymentSubForm' });

    const handleNext = () => {
        const nextStep = Math.min(activeStep + 1, 4);
        setActiveStep(nextStep);
    };

    const handleCustomerSelect = (customer: Customer.AllianceCustomerTransport | null) => {
        setOldCustomer(customer ? structuredClone(customer) : null);
        setExistingCustomer(customer || null);
        if (customer) {
            setValue('customerSubForm', { ...customer, sameAsService: false });
            setValue(
                'paymentSubForm.paymentTerm',
                (customer?.defaultPaymentSettings?.paymentTerm || 'onCharge') as keyof typeof paymentTermsEnums,
            );
            setValue(
                'paymentSubForm.paymentMethod',
                (customer?.defaultPaymentSettings?.paymentMethod || 'creditCard') as keyof typeof paymentMethodsEnums,
            );
            setValue(
                'pricingSubForm.cc',
                !customer?.defaultPaymentSettings?.paymentMethod || customer?.defaultPaymentSettings?.paymentMethod === 'creditCard'
                    ? true
                    : false,
            );
            if (customer.taxExempt) {
                setValue('pricingSubForm.tax', false);
            }
            onResetLead();
        } else {
            setValue('customerSubForm', customerStartData);
        }
        if (activeStep === 0) handleNext();
    };

    const handleStartingValues = async () => {
        if (!startingValues) return;
        const cleanStartingValues = { ...startingValues };
        if (cleanStartingValues.pricingSubForm) {
            cleanStartingValues.pricingSubForm.serviceLocation =
                cleanStartingValues.pricingSubForm?.serviceLocation?.address?.addressLine1 &&
                cleanStartingValues.pricingSubForm?.serviceLocation?.coordinates?.lat
                    ? cleanStartingValues.pricingSubForm.serviceLocation
                    : initialValues.pricingSubForm.serviceLocation;
        }
        const getValuesResult = getValues();
        reset({
            customerSubForm: { ...getValuesResult.customerSubForm, ...cleanStartingValues.customerSubForm },
            dispatchSubForm: {
                ...getValuesResult.dispatchSubForm,
                ...cleanStartingValues.dispatchSubForm,
            } as OrderCreateFormProps['dispatchSubForm'],
            paymentSubForm: {
                ...getValuesResult.paymentSubForm,
                ...cleanStartingValues.paymentSubForm,
            } as OrderCreateFormProps['paymentSubForm'],
            pricingSubForm: { ...getValuesResult.pricingSubForm, ...cleanStartingValues.pricingSubForm },
        });
        if (
            startingValues.customerSubForm &&
            'id' in startingValues.customerSubForm &&
            startingValues.customerSubForm.id &&
            startingValues.customerSubForm.customerNumber
        ) {
            handleCustomerSelect({
                ...customerStartData,
                ...startingValues.customerSubForm,
            } as Customer.AllianceCustomerTransport);
        } else if (
            startingValues.customerSubForm &&
            startingValues.customerSubForm.contacts &&
            startingValues.customerSubForm.contacts?.length > 0
        ) {
            setIsSearchingForStartingCustomer(true);
            let customerFound = null;
            if (startingValues.customerSubForm.contacts[0]?.email) {
                const customerEmailResponse = await client
                    .customer()
                    .adminPortal.query({ email: startingValues.customerSubForm.contacts[0].email });
                if (customerEmailResponse) {
                    const customerSearch = customerEmailResponse;
                    if (customerSearch !== null) {
                        if (customerSearch.length > 0) {
                            customerFound = customerSearch[0];
                        }
                    }
                }
            }
            if (!customerFound && startingValues.customerSubForm.contacts[0]?.phoneNumber) {
                const customerPhoneResponse = await client.customer().adminPortal.query({
                    phoneNumber: formatUSPhoneNumberToE164(startingValues.customerSubForm.contacts[0].phoneNumber),
                });
                if (customerPhoneResponse) {
                    const customerSearch = customerPhoneResponse;
                    if (customerSearch !== null) {
                        if (customerSearch.length > 0) {
                            customerFound = customerSearch[0];
                        }
                    }
                }
            }
            if (customerFound) {
                handleCustomerSelect({ ...customerStartData, ...customerFound } as Customer.AllianceCustomerTransport);
                setOldCustomer(customerFound);
            }
            setIsSearchingForStartingCustomer(false);
        }
        handleNext();
    };

    useEffect(() => {
        if (startingValues) {
            handleStartingValues();
        }
    }, [startingValues]);

    const validateFormSubsections = async () => {
        let isValid = true;

        if (activeStep === 0) {
            isValid = 'id' in watchCustomerSubForm;
        } else if (activeStep === 1) {
            isValid = await trigger('pricingSubForm');
            clearErrors('pricingSubForm');
        } else if (activeStep === 2) {
            isValid = await trigger('dispatchSubForm');
            clearErrors('dispatchSubForm');
        } else if (activeStep === 3) {
            isValid = await trigger('customerSubForm');
            clearErrors('customerSubForm');
        } else if (activeStep === 4) {
            isValid = await trigger('paymentSubForm');
            clearErrors('paymentSubForm');
        }
        if (isValid) handleNext();
    };

    useEffect(() => {
        validateFormSubsections();
    }, [watchPricingSubForm, watchDispatchSubForm, watchCustomerSubForm, watchPaymentSubForm]);

    return (
        <div className="mx-auto flex max-w-lg flex-1 flex-col p-5 lg:max-w-5xl">
            {isSearchingForStartingCustomer && <Loading fullScreen />}
            <div className="flex">
                <VerticalStepper activeStep={activeStep}>
                    <Step label="Select Customer">
                        <div className={`flex`}>
                            <SelectCustomerSubForm onSelect={handleCustomerSelect} />
                            <div className={`ml-2`}>
                                {existingCustomer?.doNotService ? (
                                    <Chip label="Do Not Service" primaryColor="error-light" textColor="error-dark" size="medium" />
                                ) : null}
                            </div>
                        </div>
                    </Step>
                    <Step label="Order Info">
                        <PricingSubForm
                            zonePricing={zonePricing}
                            onGetPricing={onGetPricing}
                            disabled={activeStep < 1 || Boolean(watchPaymentInfo)}
                            existingCustomer={existingCustomer}
                        />
                    </Step>
                    <Step label="Dispatch Info">
                        <DispatchSubForm disabled={activeStep < 2} />
                    </Step>
                    <Step label="Customer Info">
                        <CustomerSubForm
                            disabled={activeStep < 3}
                            existingCustomer={existingCustomer}
                            onUseExistingCustomer={handleCustomerSelect}
                        />
                    </Step>
                    <Step label="Payment &amp; Summary">
                        <PaymentSubForm
                            user={user}
                            isLoading={isLoading}
                            disabled={activeStep < 4 || existingCustomer?.doNotService || false}
                            zonePricing={zonePricing}
                        />
                    </Step>
                </VerticalStepper>
            </div>
        </div>
    );
};

export default OrderCreateForm;
