import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { formatDateToYYYYMMDD, sanitizeFromPayload } from '@wayste/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { UIContext } from '../../../contexts';
import { UniversalServiceFormData } from '../UniversalServiceCreate/UniversalServiceCreate';
import SubscriptionDetailsSubForm from '../components/SubscriptionDetailsSubForm';
import { defaultServiceSubscriptionDetails } from '../components/site-service-defaults';

const SubscriptionDetailsUpdate = () => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const history = useHistory();
    const params: { serviceGroupingId: string; serviceOrderId: string } = useParams();
    const methods = useForm<UniversalServiceFormData>();
    const { handleSubmit, reset } = methods;
    const [serviceGrouping, setServiceGrouping] = useState<UniversalService.ServiceGrouping | undefined>(undefined);
    const [serviceOrder, setServiceOrder] = useState<UniversalService.ServiceOrder | undefined>(undefined);
    const [index, setIndex] = useState<number>(0);

    const fetchServiceGrouping = async () => {
        try {
            const serviceGrouping = await client.universalService().serviceGrouping.fetch(params.serviceGroupingId);
            setServiceGrouping(serviceGrouping);
            serviceGrouping.serviceOrders.map((order, index) => {
                if (order.id === params.serviceOrderId) {
                    setIndex(index);
                    setServiceOrder(order);
                }
            });
        } catch (error) {
            console.warn('fetchServiceOrder error for ID: ', error);
            return { status: 'error', data: null };
        }
    };

    useEffect(() => {
        fetchServiceGrouping();
    }, []);

    useEffect(() => {
        if (!serviceGrouping) {
            reset({
                subscriptionDetails: defaultServiceSubscriptionDetails,
            });
        } else {
            reset({
                serviceGrouping: serviceGrouping,
                subscriptionDetails: serviceGrouping.serviceOrders[index].subscriptionDetails,
            });
        }
    }, [serviceGrouping]);

    const onSubmit = async (data: UniversalServiceFormData) => {
        try {
            // UPDATE SERVICE GROUPING
            const serviceGroupingUpdatePayload: UniversalService.ServiceGroupingUpdate = {
                ...data.serviceGrouping,
            };
            await client.universalService().serviceGrouping.update(params.serviceGroupingId, serviceGroupingUpdatePayload);

            // UPDATE SERVICE ORDER
            const serviceOrderUpdatePayload: UniversalService.ServiceOrderUpdate = {
                ...data.serviceGrouping.serviceOrders[index],
                serviceDays: serviceOrder?.serviceDays.map((day) => Number(day)),
                vendorID: serviceOrder?.vendorID || undefined,
                subscriptionDetails: {
                    ...data.subscriptionDetails,
                    startDate: formatDateToYYYYMMDD(new Date(data.subscriptionDetails.startDate)) ?? '',
                    endDate: data.subscriptionDetails.endDate
                        ? formatDateToYYYYMMDD(new Date(data.subscriptionDetails.endDate))
                        : undefined,
                },
            };

            await client
                .universalService()
                .serviceGrouping.serviceOrder.update(
                    params.serviceGroupingId,
                    params.serviceOrderId,
                    sanitizeFromPayload(serviceOrderUpdatePayload, 'accountManagerID'),
                );

            showFlash('Service Order Successfully Updated', 'success');
            history.goBack();
        } catch (error) {
            console.error('Service Order Update error ', error);
            showFlash('Error Updating Service Order', 'warning');
        }
    };

    return (
        <div className="flex max-w-[960px] flex-1 flex-col p-16">
            <FormProvider {...methods}>
                <form>
                    <div className="mb-6 pb-2 text-2xl">Update Subscription</div>
                    <div className="space-y-6">{serviceGrouping && <SubscriptionDetailsSubForm />}</div>
                    <div className="float-right mt-2">
                        <Button className="btn-dark-grey-outlined mr-4" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                        <Button className="btn-primary" onClick={handleSubmit(onSubmit)}>
                            Update
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};
export default SubscriptionDetailsUpdate;
