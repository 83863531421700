import { useMemo } from 'react';
import { Dialog } from '@wayste/sour-ui';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import type { Stripe } from 'stripe';
import StripeElement from './StripeElement';

export type AddCardDialogProps = {
    open: boolean;
    onSuccess: () => void;
    onClose: () => void;
    stripeCustomer: Stripe.Customer | undefined;
};

/**
 * AddCardDialog component displays a dialog for adding a card, mainly serves as a wrapper for StripeElement.
 *
 * @component
 */
const AddCardDialog = ({ open, onSuccess, onClose, stripeCustomer }: AddCardDialogProps) => {
    // Load the Stripe client key using memoization so that Elements only gets loaded once
    const stripePromise = useMemo(() => {
        return loadStripe(import.meta.env.VITE_STRIPE_CLIENT_KEY);
    }, []);

    const handleCompletion = (status: string) => {
        if (status === 'success') onSuccess();
        else onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} styledTitle="Add Card">
            {stripeCustomer ? (
                <Elements stripe={stripePromise}>
                    <StripeElement stripeCustomer={stripeCustomer} onCompletion={handleCompletion} />
                </Elements>
            ) : (
                <span>No Stripe Customer Found</span>
            )}
        </Dialog>
    );
};

export default AddCardDialog;
