import React, { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button, Loading } from '@wayste/sour-ui';
import { useHistory, useParams } from 'react-router-dom';
import { routes } from '../../../utils';
import RFPForm from './rfp-form';

const RFPUpdate = () => {
    const client = useWaysteClient();
    const { id }: { id: string } = useParams();
    const history = useHistory();
    const [rfp, setRFP] = useState<UniversalService.RFPTransport | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const handleFetchRFP = async (id: string) => {
        try {
            const rfpResponse = await client.universalService().adminPortal.rfp.fetch(id);
            setRFP(rfpResponse);
        } catch (error) {
            console.error('Error fetching RFP', error);
            alert('Error fetching RFP, get a SAP dev');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            handleFetchRFP(id);
        } else {
            setIsLoading(false);
        }
    }, [id]);

    const handleSubmit = async (data: UniversalService.RFPUpdate) => {
        setIsLoading(true);
        try {
            await client.universalService().adminPortal.rfp.update(id, data);
            history.push(routes.requestForProposal.details(id));
        } catch (error) {
            console.warn('Error updating RFP', error);
            alert('Error updating RFP, get a SAP dev');
            setIsLoading(false);
        }
    };

    return (
        <div className="mx-auto flex w-full max-w-screen-md flex-col px-5 py-6">
            <h1 className="mb-6 text-2xl">Update RFP #{rfp?.proposalNumber}</h1>
            {(isLoading || !rfp) && <Loading fullScreen />}
            {rfp && <RFPForm rfp={rfp} onSubmit={handleSubmit} />}
            <div className="mt-6 flex justify-end">
                <Button type="submit" className="btn-primary" form="rfp-update-form">
                    Save
                </Button>
            </div>
        </div>
    );
};

export default RFPUpdate;
