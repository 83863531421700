import React, { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Order } from '@alliance-disposal/transport-types';
import { DatePicker, Loading, RadioButton, Select, SelectOption } from '@wayste/sour-ui';
import { format } from 'date-fns';
import queryString from 'query-string';
import { CSVLink } from 'react-csv';
import { OrderStatus } from '../../utils';

const categoryOptions = ['Customers Only', 'Orders Only', 'Leads Only', 'Haulers Only', 'Orders with Customers', 'SendGrid List'];
const CategoryMenuItems = categoryOptions.map((item) => (
    <SelectOption key={`category-${item}`} value={item}>
        {item}
    </SelectOption>
));

const dumpTypes = ['Clean', 'All'];
// const DumpTypeRadioItems = dumpTypes.map((item) => (
//   <FormControlLabel value={item} control={<Radio />} label={item} disabled />
// ));

const traverseAndFlatten = (currentNode: any, target: any, flattenedKey?: any) => {
    for (const key in currentNode) {
        if (currentNode.hasOwnProperty(key)) {
            let newKey;
            if (flattenedKey === undefined) {
                newKey = key;
            } else {
                newKey = flattenedKey + '.' + key;
            }

            const value = currentNode[key];
            if (typeof value === 'object') {
                traverseAndFlatten(value, target, newKey);
            } else {
                target[newKey] = value;
            }
        }
    }
};

const flatten = (obj: any) => {
    const flattenedObject = {};
    traverseAndFlatten(obj, flattenedObject);
    return flattenedObject;
};

const DataDumper = () => {
    const waysteClient = useWaysteClient();

    const [startDate, setStartDate] = useState<'' | Date>('');
    const [endDate, setEndDate] = useState<'' | Date>('');
    const [category, setCategory] = useState('');
    const [dumpType, setDumpType] = useState('All');
    const [downloadableData, setDownloadableData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleCategoryChange = (value: string) => {
        setCategory(value);
    };

    const handleDumpTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDumpType(event.target.value);
    };

    const handleGetCustomers = async (start: string, end: string) => {
        const response = await waysteClient.customer().adminPortal.query({
            createdAt_gte: start,
            createdAt_lte: end,
        });

        return response;
    };

    const handleGetOrders = async (start: string, end: string) => {
        const response = await waysteClient.order().adminPortal.query({
            createdAfter: start,
            createdBefore: end,
        });
        return response.results;
    };

    const handleGetLeads = async (start: string, end: string) => {
        const response = await waysteClient.customer().adminPortal.leads.query({
            createdBefore: end,
            createdAfter: start,
            limit: 10000,
        });

        const cleanData: any[] = [];
        response?.results.forEach((lead: any) => {
            lead.campaign = null;
            const searchParams = queryString.parse(lead.searchParams);
            const formUrl = queryString.parse(lead.formUrl);
            if (formUrl?.utm_campaign) {
                lead.campaign = formUrl.utm_campaign;
            } else if (searchParams?.utm_campaign) {
                lead.campaign = searchParams.utm_campaign;
            }
            cleanData.push(lead);
        });
        return cleanData;
    };

    const filterOutOrderFields = (order: Order.AllianceOrderTransport[]) => {
        const filteredOrders = order.map((order) => {
            const data = {
                id: order.id,
                allianceCustomerID: order.allianceCustomerID,
                status: order.status,
                serviceLocation: order.serviceLocation,
                material: order.material,
                metadata: {
                    createdAt: order.metadata.createdAt,
                },
            };
            return data;
        });
        return filteredOrders;
    };

    const handleGetSendGridList = async () => {
        const customerResponse = await waysteClient.customer().adminPortal.query({});
        const dataSet = [];
        for (const customer of customerResponse) {
            const orderResponse = await waysteClient.order().adminPortal.query({
                allianceCustomerID: customer.id,
                limit: 3,
                offset: 0,
                sortBy: 'orderNumber',
                sortDescending: true,
            });

            const orders = filterOutOrderFields(orderResponse.results);
            console.log(customer, orders);
            const primaryContact = customer.contacts?.find((contact: any) => contact.primaryContact);
            const allCancelled = orders.every((order: any) => order.status === OrderStatus.CANCELLED);
            const lastOrder = orders[0];
            if (!allCancelled && lastOrder) {
                dataSet.push({
                    email: primaryContact?.email,
                    first_name: primaryContact?.firstName,
                    last_name: primaryContact?.lastName,
                    city: customer.billingAddress.city,
                    state_province_region: customer.billingAddress.state,
                    postal_code: customer.billingAddress.zip,
                    cust_type: customer.type,
                    company_name: customer.companyName || '',
                    cust_motivation: customer.motivation,
                    cust_experience: customer.overallExperience,
                    last_order_date: format(new Date(lastOrder.metadata.createdAt), 'MM/dd/yyyy'),
                    last_order_city: lastOrder.serviceLocation.address.city,
                    last_order_state: lastOrder.serviceLocation.address.state,
                    last_order_zip: `${lastOrder.serviceLocation.address.zip}`,
                    last_order_material: lastOrder.material,
                });
            }
        }
        setDownloadableData(dataSet);
    };

    const handleGetHaulers = async () => {
        const response = await waysteClient.vendorService().fetch();
        return response.data;
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const start = new Date(new Date(startDate).setHours(0, 0, 0, 0)).toISOString();
        const end = new Date(new Date(endDate).setHours(24, 0, 0, 0)).toISOString();
        if (!start || !end) return;
        if (category === 'Customers Only') {
            const customers = await handleGetCustomers(start, end);
            if (dumpType === 'All') setDownloadableData(customers?.map((item: any) => flatten(item)) || []);
        }
        if (category === 'Orders Only') {
            const orders = await handleGetOrders(start, end);
            if (dumpType === 'All') setDownloadableData(orders.map((item: any) => flatten(item)));
        }
        if (category === 'Haulers Only') {
            const haulers = await handleGetHaulers();
            if (dumpType === 'All') {
                setDownloadableData(haulers.map((item: any) => flatten(item)));
            }
        }
        if (category === 'Orders with Customers') {
            const orders = await handleGetOrders(start, end);
            const combinedOrders = [];
            for (const order of orders) {
                const response = await waysteClient.customer().adminPortal.fetch(order?.allianceCustomerID);

                combinedOrders.push({ ...order, customer: response });
            }
            if (dumpType === 'All') setDownloadableData(combinedOrders.map((item) => flatten(item)));
        }
        if (category === 'Leads Only') {
            const leads = await handleGetLeads(start, end);
            if (dumpType === 'All') setDownloadableData(leads.map((item) => flatten(item)));
        }
        if (category === 'SendGrid List') {
            handleGetSendGridList();
        }
        setIsLoading(false);
    };

    return (
        <div className="container mx-auto p-4">
            <div className="flex flex-col gap-4">
                <div className="flex items-center gap-4">
                    <div>
                        <Select label="Select Category" value={category} onSelect={handleCategoryChange}>
                            {CategoryMenuItems}
                        </Select>
                    </div>
                    <div className="flex flex-col gap-1">
                        <label>Dump Fields</label>
                        <div className="flex flex-row gap-2">
                            <RadioButton
                                options={dumpTypes.map((item) => ({
                                    value: item,
                                    label: item,
                                    inputProps: { checked: dumpType === item },
                                }))}
                                inputProps={{ onChange: (e) => handleDumpTypeChange(e), value: dumpType }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row gap-4">
                    <div className="max-w-[200px]">
                        <DatePicker label="Created After" value={startDate} onChange={(value) => setStartDate(value)} />
                    </div>
                    <div className="max-w-[200]">
                        <DatePicker label="Created before" value={endDate} onChange={(value) => setEndDate(value)} />
                    </div>
                </div>
            </div>
            <div className="mt-8">
                {isLoading ? (
                    <Loading />
                ) : (
                    <div>
                        <button
                            className="btn-primary mr-7"
                            onClick={handleSubmit}
                            disabled={!category || !startDate || !endDate}
                            type="button"
                        >
                            Get Data
                        </button>
                        {downloadableData.length === 0 ? 'Get Data First' : <CSVLink data={downloadableData}>Download Data</CSVLink>}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DataDumper;
