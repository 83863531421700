import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { InternalTicket, Profile } from '@alliance-disposal/transport-types';
import { Button, Checkbox, CurrencyTextField, DatePicker, Select, SelectOption, Textarea } from '@wayste/sour-ui';
import { subDays } from 'date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ManageTags } from './ManageTags';
import StatusMenu from './StatusMenu';

type InternalTicketFormProps = {
    onSubmit: (data: InternalTicketFormFormProps) => void;
    ticket?: InternalTicket.InternalTicketTransport;
};

export type InternalTicketFormFormProps = {
    note: string;
    issueCausedByProfileID?: string;
    monetaryLoss?: number;
    status?: InternalTicket.InternalTicketStatus | null;
    assignedToProfileID?: string | null;
    assignedTeam?: Profile.SourgumTeam | null;
    tags: InternalTicket.TagTransport[];
    showSourgumIssue: boolean;
    dueDate?: Date | '';
};

const InternalTicketForm = ({ ticket, onSubmit }: InternalTicketFormProps) => {
    const client = useWaysteClient();
    const currentUser = client.user().get();
    const [rosterMap, setRosterMap] = useState<{ [key: string]: Profile.ProfileTransport }>({});

    const methods = useForm<InternalTicketFormFormProps>({
        mode: 'all',
        defaultValues: ticket
            ? {
                  note: ticket.note,
                  issueCausedByProfileID: ticket.issueCausedByProfileID,
                  monetaryLoss: ticket.monetaryLoss,
                  status: ticket.status,
                  assignedToProfileID: ticket.assignedToProfileID,
                  assignedTeam: ticket.assignedTeam,
                  tags: ticket.tags,
                  showSourgumIssue: ticket.issueCausedByProfileID ? true : false,
                  dueDate: ticket.dueDate ? new Date(ticket.dueDate) : '',
              }
            : {
                  note: '',
                  issueCausedByProfileID: undefined,
                  monetaryLoss: undefined,
                  status: null,
                  assignedToProfileID: null,
                  assignedTeam: null,
                  tags: [],
                  showSourgumIssue: false,
                  dueDate: '',
              },
    });

    const { control, watch, setValue, handleSubmit } = methods;

    useEffect(() => {
        const roster = client.profile().adminPortal.getRosterFromMemory().idToProfileMap;
        setRosterMap(roster);
    }, []);

    const watchTags = watch('tags');
    const watchShowSourgumIssue = watch('showSourgumIssue');
    const watchUser = watch('assignedToProfileID');
    const watchTeam = watch('assignedTeam');
    const watchStatus = watch('status');

    const handleOnAddTag = (tag: InternalTicket.TagTransport) => {
        if (!watchTeam && tag.defaultAssignedTeam) {
            setValue('assignedTeam', tag.defaultAssignedTeam);
            if (!watchStatus) setValue('status', 'OPEN');
        }
        const updatedTags = [...watchTags, tag];
        setValue('tags', updatedTags);
    };

    const handleRemoveTag = (tag: InternalTicket.TagTransport) => {
        const updatedTags = watchTags.filter((existingTag) => existingTag.id !== tag.id);
        setValue('tags', updatedTags);
    };

    const onFormSubmit = (data: InternalTicketFormFormProps) => {
        onSubmit(data);
    };

    return (
        <FormProvider {...methods}>
            {/* To trigger a form from parent pass form="internal-ticket-form" to submit button */}
            <form onSubmit={handleSubmit(onFormSubmit)} className="flex flex-col gap-y-4" id="internal-ticket-form">
                <Controller
                    control={control}
                    name="note"
                    rules={{
                        required: {
                            value: true,
                            message: 'Note is required',
                        },
                    }}
                    render={({ field, fieldState }) => (
                        <Textarea
                            label="Note"
                            error={fieldState.error}
                            required
                            inputProps={{
                                ...field,
                                disabled: Boolean(ticket),
                            }}
                        />
                    )}
                />
                <div>
                    <ManageTags tags={watchTags} onAddTag={handleOnAddTag} onRemoveTag={handleRemoveTag} />
                </div>
                <div className="flex items-start gap-2">
                    {/* assigned profile */}
                    <div className="flex flex-col gap-1">
                        <Controller
                            control={control}
                            name="assignedToProfileID"
                            render={({ field, fieldState }) => (
                                <Select
                                    label="Assign a user"
                                    value={field.value}
                                    onSelect={(value) => {
                                        setValue('assignedToProfileID', value);
                                        if (!watchStatus) setValue('status', 'OPEN');
                                    }}
                                    error={fieldState.error}
                                    renderLabel={(value) =>
                                        !value ? '' : rosterMap[value as string].firstName + ' ' + rosterMap[value as string].lastName
                                    }
                                >
                                    <SelectOption
                                        value={null}
                                        onClick={() => {
                                            setValue('assignedToProfileID', null);
                                            if (!watchTeam) setValue('status', null);
                                            if (!watchTeam) setValue('dueDate', '');
                                        }}
                                    >
                                        None
                                    </SelectOption>
                                    {Object.values(rosterMap)
                                        .filter((item) => item.active)
                                        .sort((a, b) => ((a?.firstName || '') > (b?.firstName || '') ? 1 : -1))
                                        .map((item) => (
                                            <SelectOption value={item.id} key={item.id}>
                                                {item.firstName} {item.lastName}
                                            </SelectOption>
                                        ))}
                                </Select>
                            )}
                        />
                        <Button
                            className="btn-primary-text-only"
                            onClick={() => {
                                setValue('assignedToProfileID', currentUser.id);
                                setValue('status', 'OPEN');
                            }}
                        >
                            Assign to me
                        </Button>
                    </div>
                    {/* assigned team */}
                    <Controller
                        control={control}
                        name="assignedTeam"
                        render={({ field, fieldState }) => (
                            <Select
                                label="Assign a team"
                                value={field.value}
                                onSelect={(value) => {
                                    setValue('assignedTeam', value as keyof typeof Profile.SourgumTeamLabels);
                                    if (!watchStatus) setValue('status', 'OPEN');
                                }}
                                error={fieldState.error}
                                renderLabel={(value) =>
                                    !value ? '' : Profile.SourgumTeamLabels[value as keyof typeof Profile.SourgumTeamLabels]
                                }
                            >
                                <SelectOption
                                    value={null}
                                    onClick={() => {
                                        setValue('assignedTeam', null);
                                        if (!watchUser) setValue('status', null);
                                        if (!watchUser) setValue('dueDate', '');
                                    }}
                                >
                                    None
                                </SelectOption>
                                {Object.entries(Profile.SourgumTeamLabels).map((item) => (
                                    <SelectOption value={item[0]} key={item[0]}>
                                        {item[1]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                    {/* status */}
                    <div className="min-w-fit">
                        <Controller
                            control={control}
                            name="status"
                            render={({ field }) => (
                                <StatusMenu
                                    status={field.value}
                                    disabled={!watchUser && !watchTeam}
                                    onStatusChange={(value) => {
                                        setValue('status', value);
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    {/* checkbox for issue caused by sourgum */}
                    <Controller
                        control={control}
                        name="showSourgumIssue"
                        render={({ field, fieldState }) => (
                            <Checkbox
                                error={fieldState.error}
                                label="Issue caused by Sourgum"
                                inputProps={{
                                    checked: field.value,
                                    onChange: (e) => setValue('showSourgumIssue', e.target.checked),
                                }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="dueDate"
                        render={({ field, fieldState }) => (
                            <DatePicker
                                error={fieldState.error}
                                label="Due date"
                                value={field.value}
                                onChange={(value) => setValue('dueDate', value)}
                                minDate={subDays(new Date(), 1)}
                                closeOnSelect
                                clearable
                                inputProps={{
                                    disabled: !watchUser && !watchTeam,
                                }}
                            />
                        )}
                    />
                </div>
                {watchShowSourgumIssue && (
                    <div className="flex gap-2">
                        {/* issue id */}
                        <Controller
                            control={control}
                            name="issueCausedByProfileID"
                            render={({ field, fieldState }) => (
                                <Select
                                    label="Issue caused by"
                                    value={field.value}
                                    onSelect={(value) => setValue('issueCausedByProfileID', value)}
                                    error={fieldState.error}
                                    renderLabel={(value) =>
                                        !value ? '' : rosterMap[value as string].firstName + ' ' + rosterMap[value as string].lastName
                                    }
                                >
                                    {Object.values(rosterMap)
                                        .filter((item) => item.active)
                                        .sort((a, b) => ((a?.firstName || '') > (b?.firstName || '') ? 1 : -1))
                                        .map((item) => (
                                            <SelectOption value={item.id} key={item.id}>
                                                {item.firstName} {item.lastName}
                                            </SelectOption>
                                        ))}
                                </Select>
                            )}
                        />
                        {/* monetary loss */}
                        <Controller
                            control={control}
                            name="monetaryLoss"
                            render={({ field, fieldState }) => (
                                <CurrencyTextField
                                    label="Monetary loss"
                                    value={field.value ? field.value : ''}
                                    onChange={(value) => setValue('monetaryLoss', value || undefined)}
                                    useCents
                                    error={fieldState.error}
                                />
                            )}
                        />
                    </div>
                )}
            </form>
        </FormProvider>
    );
};

export default InternalTicketForm;
