import axios from 'axios';

export const getPublicLinkShortCode = async (receivableID: string, token: string) => {
    console.log('receivableID: ', receivableID);
    try {
        const response = await axios({
            method: 'POST',
            url: `${import.meta.env.VITE_BASE_API_URL}/invoice/receivable/customer-access-token`,
            data: {
                receivableID: receivableID,
            },
            headers: {
                Authorization: token,
            },
        });
        if (response.status !== 200) {
            console.warn('wayste-backend getPublicLinkShortCode error: ', response);
            return { status: 'error' };
        }
        return { status: 'success', data: response.data };
    } catch (error) {
        console.warn('wayste-backend getPublicLinkShortCode error: ', error);
        return { status: 'error' };
    }
};
